import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { history } from "../store/configureStore";
import scrollToTop from "../helpers/scrollToTop";
import MetaTagsComponent from "../components/meta_tags_component";
import HomeTvSignupBanner from "../components/home_tv_signup_banner";
import ButtonWithIcon from "../components/button_with_icon";
import arrowBlack from "../../../assets/images/icons/arrow_right_black.svg";
import arrowWhite from "../../../assets/images/icons/arrow_right_white.svg";
import VideoThumb from "../components/video_thumb";
import HomePageSlider from "./home_page_slider";
import TeacherThumb from "./teacher_thumb";

class HomePageContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      screenWidth: window.innerWidth
    };
  }

  componentDidMount() {
    scrollToTop();
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({
      screenWidth: window.innerWidth
    });
  };

  renderCascadeBlock = block => {
    const { isAuthenticated } = this.props;

    const imgClass = `cascade-img-container ${block.narrow_image ? "" : "wide"} ${block.round_image ? "round-img" : ""}`;

    return (
      <div
        className="cascade-block"
        key={block.id}
        style={{ marginTop: block.top_indent }}
      >
        <Link to={block.url}>
          <div className={imgClass}>
            {(block.video_url || block.image_url) && (
              <Fragment>
                {block.video_url ? (
                  <video
                    className="cascade-block-image"
                    autoPlay
                    muted
                    poster={block.image_url}
                    playsInline
                    loop
                  >
                    <source
                      src={block.video_url}
                      type={
                        block.video_type === "video/quicktime"
                          ? "video/mp4"
                          : block.video_type
                      }
                    />
                    Your browser does not support HTML video.
                  </video>
                ) : (
                  <img
                    src={block.image_url}
                    className="cascade-block-image"
                  />
                )}
              </Fragment>
            )}
          </div>
        </Link>
        <div className="content">
          {block.header_black && (
            <div className="header">
              {block.header_black}{" "}
              <span className="gray">{block.header_gray}</span>
            </div>
          )}
          {block.text && <div className="text">{block.text}</div>}
          {(block.button_text || block.button_text_logged_in) && (
            <ButtonWithIcon
              classNamesProp={`Button ${block.button_white ? "Button--secondary" : ""}`}
              iconSrc={block.button_white ? arrowBlack : arrowWhite}
              text={
                isAuthenticated
                  ? block.button_text_logged_in
                  : block.button_text
              }
              onClick={() => history.push(block.url)}
            />
          )}
        </div>
      </div>
    );
  };

  render() {
    const { screenWidth } = this.state;

    const {
      content,
      cascadeBlocks,
      latestVideos,
      videosCount,
      subscribed
    } = this.props;

    return (
      <Fragment>
        <MetaTagsComponent
          title="SKY TING | Practice anytime. Anywhere."
          desc="SKY TING is a community driven yoga studio in NYC, and a subscription-based online yoga streaming platform for anyone, anywhere, anytime. We offer a variety of styles, with a focus on the functionality of the body giving students the tools and techniques to enhance their lives and surround themselves with beauty, community, and joy!"
        />

        <div className="home-page">
          <div className="home-page-content">
            <HomePageSlider />

            {content.presses && (
              <div className="press-container">
                <div className="text-with-line">
                  <div className="line" />
                  <span className="text">FEATURED IN</span>
                  <div className="line" />
                </div>
                <div className="press-row">
                  {content.presses.map(p => (
                    <a href={p.url} className="press-el" target="_blank" key={p.id}>
                      <img src={p.logo} />
                    </a>
                  ))}
                </div>
              </div>
            )}

            {cascadeBlocks.length > 0 && (
              <Fragment>
                {screenWidth > 640 ? (
                  <div className="cascade-blocks-container max-width-1800 ml-auto mr-auto">
                    <div className="left-column">
                      {cascadeBlocks
                        .filter(b => b.column === 1)
                        .map(block => this.renderCascadeBlock(block))}
                    </div>
                    <div className="right-column">
                      {cascadeBlocks
                        .filter(b => b.column === 2)
                        .map(block => this.renderCascadeBlock(block))}
                    </div>
                  </div>
                ) : (
                  <div className="cascade-blocks-container padding-lr for-small">
                    {cascadeBlocks.map(block => this.renderCascadeBlock(block))}
                  </div>
                )}
              </Fragment>
            )}
            { !subscribed &&
              <HomeTvSignupBanner
                small
                header={content.tv_signup_header_2}
                subheader={content.tv_signup_subheader_2}
                subscribed={subscribed}
              />
            }

            {latestVideos && latestVideos.length > 0 && (
              <div className="latest-videos-container padding-lr">
                <h4 className="home-page-subheader">{content.videos_header || "latest videos"}</h4>
                <div className="latest-videos-row">
                  {latestVideos.map(video => (
                    <VideoThumb video={video} />
                  ))}
                </div>
                <Link to="/tv/videos" className="Button transparent-button m-auto">
                  see all {videosCount} videos
                </Link>
              </div>
            )}
            <div className="home-page-teachers">
              <h4 className="home-page-subheader padding-lr">Browse by instructor</h4>
              <div className="teachers-grid mb-4 mt-4 ml-0 mr-0 padding-lr">
                {content.teachers.map(teacher => (
                  <TeacherThumb teacher={teacher} />
                ))}
              </div>
              <Link to="/teachers" className="Button transparent-button m-auto">
                MEET ALL OF OUR TEACHERS
              </Link>
            </div>

            <div className="explore-block">
              {content.explore_block_background_video ? (
                <video
                  className="bkg"
                  autoPlay
                  muted
                  poster={content.explore_block_background}
                  playsInline
                  loop
                >
                  <source
                    src={content.explore_block_background_video}
                    type={
                      content.explore_block_bckg_video_type ===
                      "video/quicktime"
                        ? "video/mp4"
                        : content.explore_block_bckg_video_type
                    }
                  />
                  Your browser does not support HTML video.
                </video>
              ) : (
                <img src={content.explore_block_background} className="bkg" />
              )}

              <div className="explore-block-content padding-lr max-width-1800 ml-auto mr-auto">
                <div className="left-block">
                  <div className="header">
                    {content.explore_block_header || "explore our tings"}
                  </div>
                  <div className="text">
                    {content.explore_block_subheader}
                  </div>
                  <Link
                    to={{
                      pathname: "/pricing",
                      state: { cameFrom: "/tv" }
                    }}
                    className="Button signup hide-for-small"
                  >
                    SIGN UP
                  </Link>
                </div>
                <div className="right-block">
                  <img src={content.explore_block_image} />
                </div>
                <Link
                  to="/pricing"
                  className="Button signup show-for-small"
                >
                  SIGN UP
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default HomePageContent;
