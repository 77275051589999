import React, { Component, Fragment } from "react";
import superagent from "superagent";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { history } from "../../store/configureStore";
import { setInnerBalance } from "../../actions/balances";
import { isAuthenticated } from "../../reducers/user";
import RenderLoading from "../render_loading.jsx";
import Popup from "../popup";
import GiftCardInner from "../../../../assets/images/gift_card_inner.svg";
import SadFace from "../../../../assets/images/sad_face.svg";
import { Link } from "react-router-dom";

class GiftCardRedeem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isFetchingCardInfo: false,
      error: null,
      blockPopupClose: false,
      giftCard: null,
      step: "redeem" // 'redeem', 'login', 'register'
    };
  }

  componentWillMount() {
    document.body.classList.add("overflow-hidden");
    this.fetchGiftCardInfo();
  }

  componentWillUnmount() {
    document.body.classList.remove("overflow-hidden");
  }

  fetchGiftCardInfo = () => {
    const { giftCardToken } = this.props;

    this.setState({ isFetchingCardInfo: true });
    return superagent
      .get(`/users/gift_cards/${giftCardToken}`)
      .set({
        Accept: "application/json",
        "X-CSRF-Token": document
          .getElementsByName("csrf-token")[0]
          .getAttribute("content")
      })
      .send()
      .then(res => {
        this.setUserInnerBalance(res.body.gift_card);
        this.setState({
          giftCard: res.body.gift_card,
          isFetchingCardInfo: false
        });
      })
      .catch(err => {
        this.setState({
          isFetchingCardInfo: false,
          error: err.response.body.message || "Can't find the card"
        });
      });
  };

  redeemGiftCard = () => {
    const { giftCardToken } = this.props;

    this.setState({ isLoading: true, error: null, blockPopupClose: true });
    return superagent
      .post("/users/gift_cards")
      .set({
        Accept: "application/json",
        "X-CSRF-Token": document
          .getElementsByName("csrf-token")[0]
          .getAttribute("content")
      })
      .send({ token: giftCardToken })
      .then(res => {
        this.setUserInnerBalance(res.body.inner_balance);
        this.setState({
          isLoading: false,
          error: null,
          blockPopupClose: false
        });

        if (res.body.after_redeem_redirect_to) {
          history.push(res.body.after_redeem_redirect_to);
        } else if (res.body.is_for_skyting_tv) {
          history.push("/pricing");
        } else {
          history.push("/account");
        }
      })
      .catch(err => {
        this.setState({
          isLoading: false,
          blockPopupClose: false,
          error:
            err.response.body && err.response.body.message
              ? err.response.body.message
              : "Something went wrong, try again"
        });
      });
  };

  setUserInnerBalance = balance => {
    this.props.setInnerBalance(balance);
  };

  onPopupClose = () => {
    const { blockPopupClose } = this.state;
    const { onClose } = this.props;

    if (blockPopupClose) return;

    onClose();
  };

  renderRedeemContent = () => {
    const { giftCard, isLoading, isFetchingCardInfo, error } = this.state;

    const { isAuthenticated, user } = this.props;

    return (
      <Fragment>
        <div className="redeem-header font-weight-bold">
          {error ? "INVALID GIFT CARD" : "REDEEM YOUR GIFT CARD!"}
        </div>
        {isFetchingCardInfo || (user && user.isFetching) ? (
          <Fragment>
            <div className="pt-5" />
            <RenderLoading />
          </Fragment>
        ) : (
          <Fragment>
            <div className="redeem-img-wrapper">
              <img src={GiftCardInner} width={168} alt="gift card" />
              { error ? (
                <img src={SadFace} width={32} alt="&#128533;" className="redeem-img-inner" />
              ) : (
                <div className="redeem-img-inner">
                  ${giftCard.amount}
                </div>
              )}
            </div>
            <div className="redeem-text">
              { error || `You received a gift card from ${giftCard.purchaser_first_name} ${giftCard.purchaser_last_name} for $${giftCard.amount}`}
            </div>

            {isAuthenticated ? (
              <Fragment>
                {!error && (
                  <Fragment>
                    {isLoading ? (
                      <RenderLoading />
                    ) : (
                      <button
                        className="Button w-100 high-button no-hover rounded"
                        onClick={() => this.redeemGiftCard()}
                      >
                        redeem now
                      </button>
                    )}
                  </Fragment>
                )}
              </Fragment>
            ) : (
              <div className="d-flex">
                <Link
                  className="Button mr-1 high-button no-hover white rounded"
                  to={{ pathname: "/login", state: { cameFrom: window.location.pathname + window.location.search } }}
                >
                  Login
                </Link>
                <Link
                  className="Button ml-1 high-button no-hover blue rounded transparent-btn"
                  to={{ pathname: "/signup", state: { cameFrom: window.location.pathname + window.location.search } }}
                >
                  SIGNUP
                </Link>
              </div>
            )}
          </Fragment>
        )}
      </Fragment>
    );
  };

  render() {
    const { step } = this.state;

    return (
      <Popup
        id="redeem-card"
        isOpen
        classNameProp={`gift-card-redeem-popup ${
          step === "redeem" ? "orange text-center" : ""
        }`}
        key="redeem-card"
        onClose={() => this.onPopupClose()}
      >
        {this.renderRedeemContent()}
      </Popup>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: isAuthenticated(state),
  user: state.user
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setInnerBalance
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GiftCardRedeem);
