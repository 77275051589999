import React, {Component, Fragment} from 'react'
import superagent from 'superagent'
import RenderError from '../components/render_error.jsx';
import RenderLoading from '../components/render_loading.jsx';
import scrollToTop from '../helpers/scrollToTop';
import MetaTagsComponent from "../components/meta_tags_component";

export default class FriendsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  setBackgroundColor(index, colors) {
    if (colors.length >= index + 1) return (colors[index])

    const i = ((index + 1) % colors.length)
    return (colors[i - 1])
  }

  componentDidMount() {
    scrollToTop();
    return superagent
      .get('/friends')
      .set('Accept', 'application/json')
      .send().then(res =>
        this.setState({
          friends: res.body,
          isLoading: false
        }))
      .catch((error) => {
        this.setState({ error: error });
      });
  }

  render() {
    const colors = [
      '#3FA151',
      '#0067c4',
      '#FAA338',
      '#001D3F',
      '#D80E18',
      '#E1B724',
      '#F4BABA',
      '#B0DD92',
      '#E87868',
      '#92172F',
      '#9B928E',
      '#75AEDE',
    ];

    const { error, isLoading, friends } = this.state;

    if (error) return <RenderError error={error} />;

    return (
      <Fragment>
        <MetaTagsComponent
          title="SKY TING | About | Friends"
          desc="SKY TING friends"
          url="friends"
        />
        <div className="Page Friends">
          <div className="Page-content">
            <h2 className="Page-header">FRIENDS</h2>
            { isLoading ? (
              <RenderLoading />
            ) : (
              <div className="Friend-cards row">
                { friends.map((friend, index) => (
                  <div className="column medium-4 small-12" key={friend.id}>
                    <a
                      href={friend.url}
                      className="Friend-card"
                      style={{ backgroundColor: this.setBackgroundColor(index, colors) }}
                      target="_blank"
                    >
                      {friend.title}
                    </a>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}
