import {
  OPEN_LESSON_POPUP,
  CLOSE_LESSON_POPUP
} from '../actions/lesson_popup';

const INITIAL_STATE = {
  show: false
};

const lessonPopup = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OPEN_LESSON_POPUP:
      return {
        ...state,
        show: true
      };
    case CLOSE_LESSON_POPUP:
      return {
        ...state,
        show: false
      };
    default:
      return state;
  }
};

export default lessonPopup;
