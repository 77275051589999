import superagent from 'superagent';

const API_ENDPOINT = '/users/memberships';

const fetchUserMemberships = () => {
  return superagent
    .get(API_ENDPOINT)
    .set('Accept', 'application/json')
};

export default { fetchUserMemberships };
