import moment from 'moment';
import {formattedLocationName} from "../helpers/formatted_location_name";

const sanitize = string => {
  if (!string || !string.length)
    return null;

  return string.replace(/(<([^>]+)>)/ig,"")
};

export default class Lesson {
  constructor(data) {
    this.data = data;
  }

  get id() {
    const { mindbody_identifier: id } = this.data;

    return id;
  }

  get slug() {
    const { slug } = this.data;

    return slug;
  }

  get staff() {
    const { staff } = this.data;

    return staff;
  }

  get location() {
    const { location } = this.data;

    return location;
  }

  get session_type() {
    const { session_type } = this.data;

    return session_type;
  }

  get name() {
    const { name } = this.data;

    return name;
  }

  get address() {
    const { location: { address, address2 } } = this.data;

    return `${address}, ${address2}`;
  }

  get description() {
    const { description } = this.data;

    return sanitize(description);
  }

  get startDay() {
    const { start_date_time } = this.data;

    return moment.utc(start_date_time).format('MMM D YYYY');
  }

  get startTime() {
    const { start_date_time } = this.data;

    return moment.utc(start_date_time).format('hh:mm');
  }

  startTimeWithFormat(timeFormat = 'hh:mm') {
    const { start_date_time } = this.data;

    return moment.utc(start_date_time).format(timeFormat);
  }

  get durationMinutes() {
    const { end_date_time, start_date_time } = this.data;

    return moment(end_date_time).diff(moment(start_date_time), 'minutes');
  }

  get endTime() {
    const { end_date_time } = this.data;

    return moment.utc(end_date_time).format('hh:mm A');
  }

  get locationName() {
    const { location: { name } } = this.data;

    return formattedLocationName(name)
  }

  get locationPhone() {
    const { location: { phone } } = this.data;

    return phone;
  }

  get locationImage() {
    const { location: { image_url } } = this.data;

    return image_url;
  }

  get waitlistAvailable() {
    const { is_waitlist_available } = this.data;

    return is_waitlist_available;
  }

  get start_date_time() {
    return this.data.start_date_time;
  }

  get end_date_time() {
    return this.data.end_date_time
  }

  get isEnrolled() {
    return this.data.is_enrolled;
  }

  getLessonType = () => {
    const { program: { name } } = this.data;

    switch(name) {
      case 'SKY TING Workshops':
        return 'workshops'
      case 'SKY TING Retreats':
        return 'retreats'
      case 'SKY TING Teacher Training':
        return 'trainings'
      case 'SKY TING Mentorship':
        return 'mentorship'
      default:
        return name
    }
  }

  get lessonType() {
    return this.getLessonType()
  }

  get programName() {
    const { program: { name } } = this.data;

    return name;
  }

  get isProgram() {
    return ['workshops', 'retreats', 'trainings', 'mentorship'].includes(this.getLessonType())
  }

  get teacherIsActive() {
    const { teacher } = this.data;

    return !!(teacher && teacher.active);
  }

  get teacherSlug() {
    const { teacher } = this.data;
    return teacher.slug;
  }

  get staffName() {
    const { staff: { name } } = this.data;

    return name;
  }

  get staffImg() {
    const { staff: { image_url }, teacher } = this.data;

    if (teacher && teacher.photo_url) return teacher.photo_url

    return image_url;
  }

  get staffSecondImg() {
    const { teacher } = this.data;

    if (teacher && teacher.multiple) {
      return teacher.hover_photo_url;
    }
    return null;
  }
}