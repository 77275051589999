import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Avatars from '../data/Avatars.js';

class AvatarPicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      avatar: Avatars[0].image,
      description: Avatars[0].description,
      text: Avatars[0].text,
      index: 0,
    };
  }

  componentWillMount() {
    const { value } = this.props
    const avatar = Avatars.find(el => el.description === value)

    this.setState({
      avatar: avatar ? avatar.image : Avatars[0].image,
      description: avatar ? avatar.description : Avatars[0].description,
      text: avatar ? avatar.text : Avatars[0].text,
      index: Avatars.findIndex(el => el.description === value)
    })
  }

  nextAvatar = (index) => {
    if (Avatars.length === index) {
      this.setState({
        index: 0,
        avatar: Avatars[0].image,
        description: Avatars[0].description,
        text: Avatars[0].text,
      })
      this.onChange(Avatars[0].description)
    } else {
      this.setState({
        index: index,
        avatar: Avatars[index].image,
        description: Avatars[index].description,
        text: Avatars[index].text
      })
      this.onChange(Avatars[index].description)
    }
  };

  onClick = () => {
    const index = this.state.index + 1;
    this.nextAvatar(index);
  };

  setAvatar(index) {
    this.setState({
      index: index,
      avatar: Avatars[index].image,
      description: Avatars[index].description,
      text: Avatars[index].text
    });
    this.onChange(Avatars[index].description)
  }

  onChange = (description) => {
    const { onChange } = this.props
    onChange({
      target: {
        type: 'text',
        name: 'photo_url',
        value: description,
      }
    })
  }

  classFor = (index) => {
    return classNames({
      'AvatarPicker-mini-circle': true,
      'AvatarPicker-mini-circle-active': this.state.index === index
    });
  };

  render() {
    return (
      <div>
        <div className="AvatarPicker">
          <ul className="AvatarPicker-circle-container">
            {Avatars.map((_, index) =>
              <li
                key={index}
                className={this.classFor(index)}
                onMouseOver={() => this.setAvatar(index)}
              />
            )}
          </ul>

          <img
            onClick={this.onClick}
            src={this.state.avatar}
            alt={this.state.text}
            className="AvatarPicker-img"
          />

          <div className="AvatarPicker-subtitle">{this.state.text}</div>
        </div>
        <input
          type="hidden"
          name="user[photo_url]"
          id="photo_url"
          value={this.state.avatar}
        />
      </div>
    )
  }
}

AvatarPicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
}

export default AvatarPicker
