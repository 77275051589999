import React, { Component, Fragment } from 'react'
import moment from 'moment/moment'
import Slider from 'react-slick'
import {Collapse} from 'react-collapse'

import ToggleCard from './toggle_card.jsx'
import BookLessonButton from '../components/book_lesson_button.jsx';

import mentorshipType from '../types/mentorship_type'
import { programTeachersNames } from "../helpers/program_teachers_names";

export default class MentorshipCard extends Component {
  renderApplyButton = () => {
    const { mentorship: { additional }, openLogin, isAuthenticated } = this.props

    const onClickAction = (!isAuthenticated) ? openLogin : null
    const hasLink = additional.mbo_link || additional.type_form

    if (!hasLink) return (<div />)
    const link = additional.mbo_link ? additional.mbo_link : `mentorship/${additional.slug}`
    const buttonLink = (isAuthenticated && link) ? link : null

    return (
      <BookLessonButton
        label="APPLY HERE"
        reservedLabelText="Reserved"
        link={buttonLink}
        lessonId={additional.mindbody_identifier}
        onClick={onClickAction}
        className="Button"
      />
    )
  }

  render() {
    const {
      mentorship: { main, additional, mentors, photos },
      mentorship,
      policy,
      activeProgram,
      selectProgram,
      deselectProgram,
    } = this.props

    const isActive = activeProgram ? activeProgram.main.mindbody_identifier === main.mindbody_identifier : false

    return (
      <div className="Program-card" key={main.id} id={additional.slug}>
        <div className="Visible-part">
          <div className="Image-block For-mobile">
            {((photos) && (photos.length > 0) && photos[0].image_url) &&
              <div
                className="Program-card-image-wrapper cursor-pointer"
                style={{ backgroundImage: `url(${photos[0].image_url})` }}
                onClick={() => selectProgram(mentorship)}
              />
            }
          </div>
          <div className="Block-wrapper">
            <div className="Left-block">
              <div>
                <h3
                  className="Program-card-header cursor-pointer"
                  onClick={() => selectProgram(mentorship)}
                >
                  {main.class_description.name}
                </h3>
                <div className="Info">
                  <p>
                    <span className="Bold">WHEN/&nbsp;</span>
                    <span>
                      {moment.utc(main.start_date_time).format('MMM')} - {moment.utc(main.end_date_time).format('MMM YYYY')}
                    </span>
                  </p>
                  <p>
                    <span className="Bold">W/&nbsp;</span>
                    { (additional && additional.teacher_header) ? (
                      <span>{additional.teacher_header}</span>
                    ) : (
                      <Fragment>
                        { mentors.length > 0 ? (
                          programTeachersNames(mentors).map(t => <span>{t}</span>)
                        ) : (
                          <span>{main.staff.name}</span>
                        )}
                      </Fragment>
                    )}
                  </p>
                </div>
                {this.renderApplyButton()}

                { isActive ? (
                  <div className="Button Button--secondary active-btn For-large" onClick={() => deselectProgram()}>LEARN LESS</div>
                ) : (
                  <div className="Button Button--secondary For-large" onClick={() => selectProgram(mentorship)}>LEARN MORE</div>
                )}
                <div className="Button Button--secondary For-small" onClick={() => selectProgram(mentorship)}>LEARN MORE</div>

              </div>
            </div>
            <div className="Right-block">
              {((photos) && (photos.length > 0) && photos[0].image_url) ? (
                <div
                  className="Program-card-image-wrapper cursor-pointer"
                  style={{ backgroundImage: `url(${photos[0].image_url})` }}
                  onClick={() => selectProgram(mentorship)}
                />
              ) : (
                <div className="Program-card-image-wrapper empty" />
              )}
            </div>
          </div>
        </div>
        <Collapse isOpened={isActive}>
          { isActive &&
            <div className="Expanded-part">
              {(photos && photos.length > 0) &&
                <div className="Program-image-slider Image-slider">
                  <Slider
                    dots={false}
                    speed={500}
                    cssEase="ease-in"
                    infinite
                    autoplay
                    fade
                    arrows
                  >
                    {photos.map((photo, index) => (
                      <div key={index} className="Slider-item-image">
                        <img src={photo.image_url} alt="SKY TING" />
                      </div>
                    ))}
                  </Slider>
                </div>
              }
              { additional.description &&
                <div
                  className="Description-text LargeBodyText"
                  dangerouslySetInnerHTML={{ __html: additional.description }}
                />
              }
              { additional &&
                <div className="Expanded-cards">
                  { additional.includes &&
                    <ToggleCard
                      key={`program-includes-${main.id}`}
                      header="PROGRAM INCLUDES"
                    >
                      <div
                        dangerouslySetInnerHTML={{ __html: additional.includes }}
                        className="Includes-container"
                      />
                    </ToggleCard>
                  }

                  { (mentors && mentors.length > 0) &&
                    <ToggleCard
                      key={`mentors-${main.id}`}
                      header="SKY TING MENTORS"
                    >
                      <Slider
                        dots={false}
                        speed={300}
                        cssEase="ease-in"
                        className="Program-block-slider Image-slider"
                        slidesToScroll={1}
                        infinite
                        arrows
                      >
                        { mentors.map(mentor => (
                          <div key={mentor.id} className="Card Mentor-card">
                            <div className="Card-image">
                              <div className="Image-wrapper">
                                <img
                                  src={mentor.photo.url ? mentor.photo.url : mentor.image_url}
                                  alt={mentor.name}
                                />
                              </div>
                            </div>
                            <div className="Mentor-card-info">
                              <a href={`/teachers?teacher=${mentor.slug}`}>
                                <div className="Button Button--secondary">{mentor.name}</div>
                              </a>
                              {mentor.short_info &&
                                <div className="info">{mentor.short_info}</div>
                              }
                              { mentor.instagram ? (
                                <a href={`https://www.instagram.com/${mentor.instagram.replace('@', '')}`} target="_blank">
                                  <div>@{mentor.instagram.replace('@', '')}</div>
                                </a>
                              ) : (
                                <Fragment>
                                  { mentor.website &&
                                    <div>
                                      <a href={mentor.website} target="_blank">{mentor.website}</a>
                                    </div>
                                  }
                                </Fragment>
                              )}
                            </div>
                          </div>
                        ))}
                      </Slider>
                    </ToggleCard>
                  }
                  { additional.dates &&
                    <ToggleCard
                      key={`workshop-dates-${main.id}`}
                      header="WORKSHOP DATES"
                    >
                      <div
                        dangerouslySetInnerHTML={{ __html: additional.dates }}
                        className="Mentorship-dates"
                      />
                    </ToggleCard>
                  }
                  { additional.prerequisites &&
                    <ToggleCard
                      key={`prerequisites-${main.id}`}
                      header="PREREQUISITES"
                    >
                      <div className="Prerequisites">
                        <div dangerouslySetInnerHTML={{ __html: additional.prerequisites }} />
                      </div>
                    </ToggleCard>
                  }
                  { additional.price &&
                    <ToggleCard
                      key={`price-${main.id}`}
                      header="PRICE"
                    >
                      <div dangerouslySetInnerHTML={{ __html: additional.price }} />
                    </ToggleCard>
                  }
                </div>
              }
              {this.renderApplyButton()}
              { policy &&
                <div className="Policy-text">
                  <div className="SubHeadline">DEPOSIT POLICY</div>
                  <div className="SmallBodyText">{policy.text}</div>
                </div>
              }
            </div>
          }
        </Collapse>
      </div>
    )
  }
}

MentorshipCard.propTypes = {
  mentorship: mentorshipType,
}
