import superagent from 'superagent';

const API_ENDPOINT = '/users/subscriptions';

const fetchUserSubscriptions = () => {
  return superagent
    .get(API_ENDPOINT)
    .set('Accept', 'application/json');
};

const deleteSubscription = (subscription_id) => {
  return superagent
    .delete(`${API_ENDPOINT}/${subscription_id}`)
    .set('Accept', 'application/json')
    .send({ subscription_id });
}

export default { fetchUserSubscriptions, deleteSubscription };
