import {
  REGISTER_POPUP_CLOSE,
  REGISTER_POPUP_OPEN,
} from '../actions/register_popup'

const INITIAL_STATE = {
  show: false,
  error: null
}

const registerPopup = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REGISTER_POPUP_OPEN:
      return {
        ...state,
        show: true
      }
    case REGISTER_POPUP_CLOSE:
      return INITIAL_STATE
    default:
      return state
  }
}

export default registerPopup
