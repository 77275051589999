import React from 'react';
import Loading from './../../../assets/images/icons/yinyang_icon.png';

const RenderLoading = (props) => {
  return (
    <div>
      <div className={`Loading-container ${props.className ? props.className : ''}`}>
        <img src={Loading} height="21" width="21" alt="Loading" />
        <div>LOADING...</div>
      </div>
    </div>
  )
}

export default RenderLoading;
