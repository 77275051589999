import {
  FETCH_RETREATS,
  FETCH_RETREATS_ERROR,
  FETCH_RETREATS_SUCCESS
} from '../actions/retreats';

const INITIAL_STATE = {
  retreats: [],
  error: null,
  isFetching: false
};

const retreats = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_RETREATS:
      return {
        ...state,
        isFetching: true
      };
    case FETCH_RETREATS_ERROR:
      return {
        ...state,
        error: action.error,
        isFetching: false
      };
    case FETCH_RETREATS_SUCCESS:
      return {
        ...state,
        retreats: action.retreats,
        error: null,
        isFetching: false
      };
    default:
      return state;
  }
};

export const isFetching = state => state.retreats.isFetching;

export default retreats;
