import {
  OPEN_SHIPPING,
  DESELECT_SHIPPING,
  CHANGE_SHIPPING_FORM,
  DROP_SHIPPING_FORM
} from '../actions/shipping';

const INITIAL_STATE = {
  isOpen: false,
  formData: {
    first_name: null,
    last_name: null,
    address_line1: null,
    address_line2: null,
    city: null,
    state: null,
    postal_code: null,
    mobile_phone: null,
    shipping_type: null
  }
};

const shipping = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OPEN_SHIPPING:
      return {
        ...state,
        isOpen: true
      };
    case DESELECT_SHIPPING:
      return {
        ...state,
        isOpen: false
      };
    case CHANGE_SHIPPING_FORM:
      return {
        ...state,
        formData: action.formData
      };
    case DROP_SHIPPING_FORM:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default shipping;
