import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";

import { openShipping } from "../actions/shipping";
import {
  updateProductQuantity,
  updateProductQtyUnauthorizedCart,
  removeOldUnauthorizedCart
} from "../actions/cart";
import { hasRequiredMboInfo, isAuthenticated } from "../reducers/user";
import { dropdownChange } from "../actions/dropdowns";
import BagItemQuantitySelector from "./bag_item_quantity_selector_new";
import hand from "../../../assets/images/icons/hand.svg";
import closeIcon from "../../../assets/images/icons/close_icon.svg";
import emptyCart from "../../../assets/images/icons/cart_empty.svg";
import {productPriceWithDiscount} from "../helpers/product_price_with_discount";

class Bag extends Component {
  componentDidMount() {
    const { removeOldUnauthorizedCart } = this.props;

    removeOldUnauthorizedCart();
  }

  renderEmptyCart = () => {
    return (
      <div className="bag empty">
        <img src={emptyCart} className="empty-cart-image" />
        <div className="text">YOUR CART IS EMPTY</div>
        <div
          className="subtext"
          onClick={() => this.closeCart()}
        >
          KEEP SHOPPING
        </div>
      </div>
    );
  };

  renderItems = items => {
    const { isAuthenticated, userMembership } = this.props;

    return (
      <Fragment>
        {items.length > 0 && (
          <Fragment>
            <div className="bag-row head">
              <div className="column-1">&nbsp;</div>
              <div className="column-2">ITEM</div>
              <div className="column-3">QTY</div>
              <div className="column-4">PRICE</div>
            </div>
            {items.map(item => (
              <div
                className="bag-row"
                key={item.id || `${item.group_identifier}-${item.size}`}
              >
                <Link
                  to={item.slug ? `/shop/${item.slug}` : "/shop"}
                  className="column-1 cursor-pointer"
                  onClick={() => this.closeCart()}
                >
                  <img
                    // src="http://placekitten.com/200/300"
                    src={item.image_url || (item.image && item.image.url)}
                    className="bag-product-image"
                  />
                </Link>
                <div>
                  <div className="d-flex">
                    <div className="column-2">
                      <div className="product-name">
                        <Link
                          to={item.slug ? `/shop/${item.slug}` : "/shop"}
                          onClick={() => this.closeCart()}
                        >
                          {item.name}
                        </Link>
                      </div>
                      {isAuthenticated ? (
                        <div className="d-flex align-items-center">
                          <div className="bag-column-show-for-mobile">
                            {!(item.is_carbon || item.is_shipment) && (
                              <BagItemQuantitySelector item={item} />
                            )}
                          </div>
                          {item.size && item.size.name !== "None" && (item.bundle_items.length === 0) && (
                            <div className="bordered-container mr-2">
                              {item.size.name}
                            </div>
                          )}
                          {item.color && item.color.name !== "None" && (item.bundle_items.length === 0) && (
                            <div className="bordered-container">
                              {item.color_hex && (
                                <div
                                  className="product-color-circle"
                                  style={{ backgroundColor: item.color_hex }}
                                />
                              )}
                              {item.color ? item.color.name : ""}
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="d-flex align-items-center">
                          {item.size && item.size !== "None" && (
                            <div className="bordered-container mr-2">
                              {item.size}
                            </div>
                          )}
                          <div className="bordered-container">
                            {item.color_hex && (
                              <div
                                className="product-color-circle"
                                style={{ backgroundColor: item.color_hex }}
                              />
                            )}
                            {item.color ? item.color : ""}
                          </div>
                        </div>
                      )}
                      <div className="bag-column-show-for-mobile product-price mt-2">
                        ${productPriceWithDiscount(item, userMembership)}
                      </div>
                    </div>
                    <div className="column-3">
                      {!(item.is_carbon || item.is_shipment) && (
                        <BagItemQuantitySelector item={item} />
                      )}
                    </div>
                  </div>
                  { item.bundle_items && item.bundle_items.length > 0 &&
                    <div className="bag-bundle-item-row mt-4">
                      { item.bundle_items.map(i => (
                        <div className="bag-bundle-item">
                          <div className="title">{i.title}</div>
                          <div className="d-flex align-items-center">
                            {item.size && item.size.name !== "None" && (i.size_required) && (
                              <div className="bordered-container mr-2">
                                {item.size.name}
                              </div>
                            )}

                            {item.color && item.color.name !== "None" && (i.size_required) && (
                              <div className="bordered-container mr-2">
                                {item.color.name}
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  }
                </div>
                <div className="column-4 product-price">${productPriceWithDiscount(item, userMembership)}</div>
                <img
                  src={closeIcon}
                  className="remove-btn"
                  onClick={() => this.deleteItemFromCart(item)}
                  alt="Close"
                />
              </div>
            ))}
          </Fragment>
        )}
      </Fragment>
    );
  };

  deleteItemFromCart = item => {
    const {
      isAuthenticated,
      updateProductQuantity,
      updateProductQtyUnauthorizedCart,
      items,
      unauthorizedCartItems
    } = this.props;
    const bagItems = isAuthenticated ? items : unauthorizedCartItems;

    if (isAuthenticated) {
      updateProductQuantity(item.id, 0);
    } else {
      updateProductQtyUnauthorizedCart(item.id, 0, item.size);
    }

    if (bagItems.length <= 1) {
      this.closeCart();
    }
  };

  goToShipping = () => {
    this.props.openShipping();
    this.closeCart();
  };

  closeCart = () => {
    this.props.dropdownChange({ cart_items: false });
  };

  getItemsSubtotal = bagItems => {
    const { userMembership } = this.props;
    const total = _.sumBy(bagItems, i => {
      const priceWithMembership = productPriceWithDiscount(i, userMembership)
      return priceWithMembership * i.quantity;
    });
    return total.toFixed(2);
  };

  render() {
    const { items, unauthorizedCartItems, isAuthenticated, hasRequiredMboInfo } = this.props;
    const bagItems = isAuthenticated ? items : unauthorizedCartItems;

    if (bagItems.length === 0) return this.renderEmptyCart();

    return (
      <div className="bag">
        <div className="bag-container">
          {this.renderItems(bagItems)}
          <div className="bag-row bottom">
            <div className="bag-subtotal">
              <span>SUBTOTAL</span>
              <span className="font-weight-bold">
                ${this.getItemsSubtotal(bagItems)}
              </span>
            </div>
            <div className="bag-actions">
              <div
                className="close-btn"
                onClick={() => this.closeCart()}
              >
                KEEP SHOPPING
              </div>
              { isAuthenticated && hasRequiredMboInfo ? (
                <button
                  onClick={() => this.goToShipping()}
                  className="Button checkout-button"
                >
                  Checkout
                  <img src={hand} width={21} height={19} alt="CHECKOUT" />
                </button>
                ) : (
                <Link
                  className="Button checkout-button"
                  to={{
                    pathname: isAuthenticated ? "/edit-profile" : "/login",
                    state: { cameFrom: window.location.pathname, isShopFlow: true }
                  }}
                  onClick={() => this.closeCart()}
                >
                  Checkout
                  <img src={hand} width={21} height={19} alt="CHECKOUT" />
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  items: state.cart.items,
  unauthorizedCartItems: state.unauthorizedCart.items,
  isAuthenticated: isAuthenticated(state),
  hasRequiredMboInfo: hasRequiredMboInfo(state),
  userMembership: state.userMembership.membership
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      openShipping,
      dropdownChange,
      updateProductQuantity,
      updateProductQtyUnauthorizedCart,
      removeOldUnauthorizedCart
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Bag);
