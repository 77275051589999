import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { history } from "../store/configureStore";
import superagent from "superagent";
import RenderError from "../components/render_error.jsx";
import RenderLoading from "../components/render_loading.jsx";
import { bindActionCreators } from "redux";

import PricingPurchasePopup from "../components/pricing/pricing_purchase_popup.jsx";
import { selectService } from "../actions/active_service";
import { checkoutTotal, dropCheckoutTotalCart } from "../actions/checkout_total";
import { openPurchasePopup } from "../actions/purchase_popup";
import { hasRequiredMboInfo, isAuthenticated } from "../reducers/user";
import scrollToTop from "../helpers/scrollToTop";
import MetaTagsComponent from "../components/meta_tags_component";
import { getParamByName } from "../helpers/get_param_by_name";
import PricingCard from "../components/pricing/pricing_card";
import PriceInfoPopup from "../components/pricing/price_info_popup"
import redSky from "../../../assets/images/red_sky.svg";
import blueSky from "../../../assets/images/blue_sky.svg";
import orangeSky from "../../../assets/images/orange_sky.svg";

const styleAttrs = [
  { type: 'class packages', color: 'red', background: redSky },
  { type: 'memberships', color: 'blue', background: blueSky },
  { type: 'specials', color: 'orange', background: orangeSky }
]

class PricingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      prices: [],
      error: null,
      activeService: null,
      isUpdating: false,
      showInfoForPrice: null,
      selectedCategory: null,
      policy: null,
      pricingPage: null,
      blockPopupClose: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isAuthenticated && !this.props.isAuthenticated) {
      this.fetchPrices(true);
    }
  }

  componentDidMount() {
    scrollToTop();
    this.fetchPrices();

    return superagent
      .get('/policy?policy_type=pricing')
      .set('Accept', 'application/json')
      .send().then(res =>
        this.setState({
          policy: res.body,
        }));
  }

  fetchPrices = (update = false) => {
    if (update) {
      this.setState({ isUpdating: true });
    } else {
      this.setState({ isLoading: true });
    }

    return superagent
      .get("/pricing")
      .set("Accept", "application/json")
      .send()
      .then(res => {
        this.setState({
          prices: res.body.prices,
          forNewUser: res.body.for_new_user,
          pricingPage: res.body.pricing_page,
          isLoading: false,
          isUpdating: false
        });

        const serviceId = getParamByName('service', 'string');
        if (serviceId) {
          const category = res.body.prices.find(p => p.services.find(s => s.slug === serviceId))
          const service = category.services.find(s => s.slug === serviceId)

          if (category && service) {
            this.selectPurchase(service, category);
          }
        }
      })
      .catch(error => {
        this.setState({
          isLoading: false,
          isUpdating: false,
          error: error.response.body
        });
      });
  };

  selectPurchase = (service, category) => {
    const { isAuthenticated, hasRequiredMboInfo } = this.props;

    const queryStr = `?service=${service.slug ? service.slug : service.id}`;

    if (!isAuthenticated || !hasRequiredMboInfo) {
      history.push({
        pathname: isAuthenticated ? "/edit-profile" : "/login",
        state: { cameFrom: window.location.pathname + queryStr }
      });
      return;
    }

    history.push(queryStr);
    this.setState({
      selectedCategory: category,
      activeService: service,
    })
  };

  closePurchasePopup = () => {
    if (this.state.blockPopupClose) return;

    history.push({
      pathname: window.location.pathname,
      search: ""
    });

    this.setState({
      activeService: null
    });
    this.props.dropCheckoutTotalCart();
  };

  render() {
    const {
      error,
      prices,
      isLoading,
      isUpdating,
      showInfoForPrice,
      selectedCategory,
      policy,
      pricingPage,
      activeService
    } = this.state;

    const { user, userMembership, isAuthenticated, } = this.props;

    if (isLoading || user.isFetching)
      return (
        <div className="Page Pricing">
          <div className="Page-content">
            <div className="Page-header">PRICING</div>
            <RenderLoading />
          </div>
        </div>
      );

    if (error) return <RenderError error={error} />

    return (
      <Fragment>
        <MetaTagsComponent
          title="SKY TING | Pricing"
          desc="SKY TING pricing"
          url="pricing"
        />
        <div className="Page Pricing">
          <h2 className="Page-header">PRICING</h2>
          {isUpdating || isLoading ? (
            <RenderLoading />
          ) : (
            <Fragment>
              { pricingPage && pricingPage.text &&
              <div
                className="pricing-page-text"
                dangerouslySetInnerHTML={{ __html: pricingPage.text }}
              />
              }
              <div className="Pricing-page">
                {prices.map(p => (
                  <PricingCard
                    key={p.type}
                    priceCategory={p}
                    selectedPrice={activeService}
                    selectPrice={this.selectPurchase}
                    selectedCategory={selectedCategory}
                    showInfo={price =>
                      this.setState({ showInfoForPrice: price })
                    }
                    styleAttrs={styleAttrs}
                  />
                ))}
              </div>
              { policy &&
              <div
                className="Discounts-container"
                dangerouslySetInnerHTML={{ __html: policy.text }}
              />
              }
            </Fragment>
          )}

          { activeService &&
          <PricingPurchasePopup
            user={user}
            onClose={() => this.closePurchasePopup()}
            pricingPage={pricingPage}
            isAuthenticated={isAuthenticated}
            activeService={activeService}
            blockPopupClose={val => this.setState({ blockPopupClose: val })}
          />
          }
          { showInfoForPrice &&
          <PriceInfoPopup
            close={() => this.setState({ showInfoForPrice: null })}
            priceCategory={showInfoForPrice}
            styleAttrs={styleAttrs}
          />
          }

        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: isAuthenticated(state),
  hasRequiredMboInfo: hasRequiredMboInfo(state),
  userMembership: state.userMembership.membership,
  user: state.user,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      selectService,
      checkoutTotal,
      openPurchasePopup,
      dropCheckoutTotalCart
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PricingPage);
