import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Loading from "./../../../assets/images/icons/yinyang_icon.png";

import Popup from "./popup.jsx";
import { closeOrderPopup } from "../actions/order";
import { checkoutShop } from "../actions/checkout_shop";
import { openShopPaymentPopup } from "../actions/shop_payment_popup";
import PromoCodeInput from "./promo_code_input.jsx";
import Recaptcha from "./recaptcha";

class OrderPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      promoCode: null,
      recaptchaValue: "",
      recaptcha: false
    };
    this.recaptchaRef = React.createRef();
  }

  componentDidMount() {
    document.body.classList.add("overflow-hidden");
  }

  componentWillUnmount() {
    document.body.classList.remove("overflow-hidden");
  }

  closeOrderPopup = () => {
    if (!this.props.waitingForCheckout) {
      this.props.closeOrderPopup();
    }
  };

  goToPrevStep = () => {
    if (!this.props.waitingForCheckout) {
      this.props.closeOrderPopup();
      this.props.openShopPaymentPopup();
    }
  };

  onRecaptchaChange = (value) => {
    const { recaptcha } = this.state;
    this.setState({recaptchaValue: value, recaptcha: !recaptcha})
  }

  placeOrder = () => {
    const { promoCode, recaptchaValue } = this.state;
    const { checkoutTotalCart } = this.props;

    const gift_card_amount = checkoutTotalCart.gift_card_amount_applied || null;
    const total = this.props.checkoutTotalCart.grand_total;

    this.props.checkoutShop(total, promoCode, gift_card_amount, recaptchaValue).then(res => {})
      .catch(err => {
      if (this.recaptchaRef.current) {
        const { recaptcha } = this.state;
        this.recaptchaRef.current.resetRecaptcha();
        this.setState({ recaptchaValue: "", recaptcha: !recaptcha });
      }
    })
  };

  formatPrice = price => {
    if (price % 1 !== 0) return "$" + Number(price).toFixed(2);
    return "$" + Number(price).toFixed(0);
  };

  render() {
    const {
      items,
      checkoutTotalCart,
      waitingForCheckout,
      checkoutTotalIsFetching,
      checkoutTotalError,
      error,
      show
    } = this.props;

    const { recaptcha } = this.state;

    if (!show) return <div></div>;

    const shippingProducts = items.filter(item => item.is_shipment);
    const shippingProductsMboIds = shippingProducts.map(p =>
      p.mindbody_identifier.toString()
    );
    const checkoutTotalItems =
      checkoutTotalIsFetching || checkoutTotalError || !checkoutTotalCart
        ? []
        : checkoutTotalCart.cart_items.cart_item;

    return (
      <Popup isOpen={show} id="order-popup" onClose={this.closeOrderPopup}>
        <div className="OrderPopup">
          <div className="arrow-left go-to-prev" onClick={this.goToPrevStep} />
          <div className="OrderPopup-title mb-5">Order Summary</div>
          {checkoutTotalIsFetching ? (
            <div className="inline-loading">
              <img src={Loading} height="21" width="21" alt="Loading" />
              <div>LOADING...</div>
            </div>
          ) : (
            <Fragment>
              {checkoutTotalError ? (
                <div className="OrderPopup-error with-margin-top">
                  Something was wrong. Please try again.
                </div>
              ) : (
                <Fragment>
                  <div className="OrderPopup-wrapper">
                    {checkoutTotalItems.map((i, index) => (
                      <div
                        className={`OrderPopup-row ${
                          index + 1 !== checkoutTotalItems.length
                            ? "bordered"
                            : ""
                        }`}
                      >
                        <div className="OrderPopup-text">
                          {shippingProductsMboIds.includes(i.item.id) ? (
                            <Fragment>
                              {i.item.name} Shipping{" "}
                              {Number(i.quantity) > 1 ? (
                                <span>(&times; {Number(i.quantity)})</span>
                              ) : (
                                ""
                              )}
                            </Fragment>
                          ) : (
                            <Fragment>
                              {i.item.name}{" "}
                              {Number(i.quantity) > 1 ? (
                                <span>(&times; {Number(i.quantity)})</span>
                              ) : (
                                ""
                              )}
                            </Fragment>
                          )}
                        </div>
                        <div className="OrderPopup-boldText">
                          {this.formatPrice(
                            Number(i.item.price) * Number(i.quantity)
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="OrderPopup-wrapper with-background mb-4 pt-4 pb-4">
                    <div className="blue-background" />
                    <div className="OrderPopup-row">
                      <div className="OrderPopup-text">
                        {checkoutTotalCart.gift_card_amount_applied &&
                        checkoutTotalCart.gift_card_amount_applied > 0
                          ? "Gift card amount applied"
                          : "Discount"}
                      </div>
                      <div className="OrderPopup-boldText">
                        {this.formatPrice(checkoutTotalCart.discount_total)}
                      </div>
                    </div>
                    <div className="OrderPopup-row">
                      <div className="OrderPopup-text">Total</div>
                      <div className="OrderPopup-boldText">
                        {this.formatPrice(
                          Number(checkoutTotalCart.grand_total) -
                            Number(checkoutTotalCart.tax_total)
                        )}
                      </div>
                    </div>
                    <div className="OrderPopup-row bordered">
                      <div className="OrderPopup-text">Tax</div>
                      <div className="OrderPopup-boldText">
                        {this.formatPrice(checkoutTotalCart.tax_total)}
                      </div>
                    </div>
                    <div className="OrderPopup-row">
                      <div className="OrderPopup-text black font-weight-bold">
                        TOTAL
                      </div>
                      <div className="OrderPopup-boldText">
                        {this.formatPrice(checkoutTotalCart.grand_total)}
                      </div>
                    </div>
                  </div>
                  {!checkoutTotalCart.gift_card_amount_applied && (
                    <PromoCodeInput
                      isShop
                      setPromoCode={promoCode => this.setState({ promoCode })}
                    />
                  )}
                  <Recaptcha
                    ref={this.recaptchaRef}
                    onChange={this.onRecaptchaChange}
                  />
                  {waitingForCheckout ? (
                    <div className="inline-loading">
                      <img src={Loading} height="21" width="21" alt="Loading" />
                      <div>LOADING...</div>
                    </div>
                  ) : (
                    <Fragment>
                      {error && (
                        <div className="OrderPopup-error">
                          {error.message
                            ? error.message
                            : "Something went wrong. Please try again."}
                        </div>
                      )}
                      <button
                        onClick={this.placeOrder}
                        className="OrderPopup-button Button ga-complete-purchase"
                        disabled={!recaptcha}
                      >
                        COMPLETE PURCHASE!
                      </button>
                    </Fragment>
                  )}
                </Fragment>
              )}
            </Fragment>
          )}
        </div>
      </Popup>
    );
  }
}

const mapStateToProps = state => ({
  items: state.cart.items,
  show: state.order.show,
  waitingForCheckout: state.checkoutShop.waitingForCheckout,
  success: state.checkoutShop.success,
  error: state.checkoutShop.error,
  checkoutTotalCart: state.checkoutTotal.cart,
  checkoutTotalIsFetching: state.checkoutTotal.isFetching,
  checkoutTotalError: state.checkoutTotal.error
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      closeOrderPopup,
      checkoutShop,
      openShopPaymentPopup
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderPopup);
