import React, { Component } from "react";
import { isAuthenticated } from "../../reducers/user";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import confirmEmail from "./../../../../assets/images/icons/confirm_email.svg";
import superagent from "superagent";

class ConfirmationBanner extends Component {
  state = {
    emailResent: false,
    loading: false,
    error: null
  };

  resendEmail = () => {
    this.setState({ loading: true, error: null });

    return superagent
      .post("/users/send_email_confirmation")
      .set("Accept", "application/json")
      .then(res => {
        this.setState({ emailResent: true, loading: false });
      })
      .catch(error => {
        const message = error.response.body.message || "Something went wrong";
        this.setState({ loading: false, error: message });
      });
  };

  render() {
    const { emailResent, error } = this.state;
    const { isAuthenticated, user, subscribed } = this.props;

    const showBanner =
      isAuthenticated &&
      user.is_new_signup_flow &&
      !user.email_confirmed_at &&
      subscribed &&
      !emailResent;

    if (!showBanner) return <div />;

    return (
      <div className="confirmation-banner">
        <div className="confirmation-banner-container">
          <img
            src={confirmEmail}
            alt="Confirm email"
            className="confirm-email-img"
          />
          <div className="title blue-color">CONFIRM YOUR EMAIL</div>
          <div className="text">
            Never miss a beat! Secure your account, receive daily updates,
            deals, and more!
          </div>
          <div className="bottom-text">
            SENT TO <span className="email">{user.email}</span>&nbsp;
            <span
              onClick={() => this.resendEmail()}
              className="blue-color cursor-pointer"
            >
              RESEND?
            </span>
          </div>
          {error && <div className="error">{error}</div>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: isAuthenticated(state),
  user: state.user
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmationBanner);
