import React, { Component } from 'react';
import { connect } from "react-redux";

class CreditCard extends Component {
  componentDidMount() {
    this.props.chooseCard();
  }

  render() {
    const { user, isChecked, chooseCard, hideTitle } = this.props

    if (!user || !user.card_last_four) return <div />

    return (
      <div className="CreditCard">
        { !hideTitle && <h2 className="CreditCard-title mb-3">USE AN EXISTING CARD</h2> }
        <div className="CreditCard-container">
          <div className="CreditCard-number">
            CREDIT CARD ({user.card_last_four})
          </div>
          <div className="CreditCard-btn">
            <div className="CreditCard-text">Use this card</div>
            <div>
              <div
                className={`CreditCard-checkbox ${isChecked ? 'is-checked' : ''}`}
                onClick={() => chooseCard()}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user,
})

export default connect(mapStateToProps)(CreditCard);
