import moment from "moment/moment";
import momentTimezone from "moment-timezone";
import Api from '../api/lesson';

import { isAuthenticated } from '../reducers/user';
import { openLoginPopup } from './login_popup';
import { getBalance } from './balances';
import { closeLessonPopup } from './lesson_popup';
import { fetchUserSchedule, deleteFromUserSchedule } from './user_schedule';
import { dropCheckoutTotalCart } from './checkout_total';
import { openCancelLessonPopup, closeCancelLessonPopup } from './cancel_lesson_popup';
import { deleteFromUserSchedules } from "./user_schedules";

export const BOOK_LESSON = 'BOOK_LESSON'
export const BOOK_LESSON_OVERDRAFT = 'BOOK_LESSON_OVERDRAFT'
export const BOOK_LESSON_UNAUTHORIZED = 'BOOK_LESSON_UNAUTHORIZED'
export const BOOK_LESSON_AUTHORIZED = 'BOOK_LESSON_AUTHORIZED'
export const BOOK_LESSON_ERROR = 'BOOK_LESSON_ERROR'
export const BOOK_LESSON_SUCCESS = 'BOOK_LESSON_SUCCESS'
export const BOOK_LESSON_CLEAR = 'BOOK_LESSON_CLEAR'
export const SEND_BOOKING_REQUEST = 'SEND_BOOKING_REQUEST'
export const CANCEL_LESSON = 'CANCEL_LESSON'
export const CANCEL_LESSON_SUCCESS = 'CANCEL_LESSON_SUCCESS'
export const CANCEL_LESSON_ERROR = 'CANCEL_LESSON_ERROR'

const bookLessonOverdraft = () => (dispatch) => {
  dispatch({ type: BOOK_LESSON_OVERDRAFT })
  dispatch(closeLessonPopup())
};

const bookLessonUnauthorized = lessonId => (dispatch) => {
  dispatch({ type: BOOK_LESSON_UNAUTHORIZED, lessonId })
  dispatch(closeLessonPopup())
  openLoginPopup()(dispatch);
};

export const bookLessonAuthorized = () => (dispatch) => {
  dispatch({ type: BOOK_LESSON_AUTHORIZED });
};

const bookLessonSuccess = (lessonId) => (dispatch) => {
  dispatch({ type: BOOK_LESSON_SUCCESS, lessonId })
  dispatch(fetchUserSchedule())
  dispatch(getBalance());

};

const bookLessonError = (lessonId, error) => (dispatch) => {
  if (error.response.body && error.response.body.message === 'no balance') {
    dispatch(bookLessonOverdraft());
  } else {
    dispatch({ type: BOOK_LESSON_ERROR, error, lessonId });
  }
};

export const bookLesson = (lessonId) => (dispatch, getState) => {
  const state = getState()
  dispatch({ type: BOOK_LESSON, lessonId })

  if (!isAuthenticated(state)) {
    return dispatch(bookLessonUnauthorized(lessonId));
  }

  dispatch({ type: SEND_BOOKING_REQUEST })

  Api.bookLesson(lessonId)
    .then(() => dispatch(bookLessonSuccess(lessonId)))
    .catch(err => dispatch(bookLessonError(lessonId, err)))
};

export const clearBookLesson = () => (dispatch) => {
  dispatch({ type: BOOK_LESSON_CLEAR });
  dropCheckoutTotalCart()(dispatch);
};

const cancelLessonSuccess = lessonId => (dispatch) => {
  dispatch({ type: CANCEL_LESSON_SUCCESS })
  deleteFromUserSchedule(lessonId)(dispatch);
  deleteFromUserSchedules()(dispatch);
  dispatch(getBalance());
  closeCancelLessonPopup()(dispatch);
};

const lateCancellation = (lesson) => {
  const type = lesson.type ? lesson.type : lesson.lessonType

  const lesson_time_string = moment(lesson.start_date_time).utc().format('YYYY-MM-DD HH:mm:ss')
  const lesson_time_in_nyc = momentTimezone.tz(lesson_time_string, 'America/New_York')
  const time_now_string = momentTimezone().tz('America/New_York').format()
  const time_now_in_nyc = momentTimezone.tz(time_now_string, 'America/New_York')

  if (type === 'SKY TING Workshops' || type === 'workshops' || type === 'Workshops') return (lesson_time_in_nyc.diff(time_now_in_nyc, 'hours') < 12)
  return (lesson_time_in_nyc.diff(time_now_in_nyc, 'hours') < 12)
};

export const cancelLesson = lesson => (dispatch, getState) => {
  const state = getState()
  const lessonId = lesson.class_id ? lesson.class_id : lesson.id

  const late_cancel = lateCancellation(lesson)
  if (late_cancel && !state.cancelLessonPopup.show) return openCancelLessonPopup(lesson)(dispatch)

  dispatch({ type: CANCEL_LESSON, lessonId });
  
  Api.cancelLesson(lessonId, late_cancel)
    .then(() => dispatch(cancelLessonSuccess(lessonId)))
    .catch(() => dispatch({ type: CANCEL_LESSON_ERROR }))
};