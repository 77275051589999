import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Calendar from 'react-calendar'
import moment from 'moment/moment'
import onClickOutside from 'react-onclickoutside'
import classNames from 'classnames'


class DatePicker extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpenCalendar: false,
    }
  }

  handleClickOutside = evt => {
    this.setState({ isOpenCalendar: false })
  }

  onChange = (date) => {
    const { onChange, name } = this.props

    onChange({
      target: {
        type: 'date',
        name: name,
        value: date,
      }
    })

    this.setState({ isOpenCalendar: false })
  }

  openBirthday = () =>
    this.setState({ isOpenCalendar: !this.state.isOpenCalendar })

  renderLabel(value) {
    const format = 'MM/DD/YY'
    return `${value.format(format)}`
  }

  render() {
    const { value } = this.props
    let { isOpenCalendar } = this.state

    const { icon, hoverIcon } = this.props

    const classes = classNames({
      Datepicker: true,
      'is-open': this.state.open
    })

    const iconClass = classNames({
      'Datepicker-icon': true,
      'Hover-hide': (hoverIcon ? true : false)
    })

    return(
      <div className={classes}>
        <label onClick={this.openBirthday} className="Datepicker-label" htmlFor="Datepicker">
          {this.renderLabel(value)}
          { icon &&
          <img src={icon} className={iconClass} height="19" alt="calendar" />
          }
          { hoverIcon &&
          <img src={hoverIcon} className="Hover-icon" height="19" alt="calendar" />
          }
        </label>

        <div className={isOpenCalendar ? 'Registration-calendar' : 'Registration-calendar-hide'}>
          <Calendar
            onChange={this.onChange}
            value={this.state.date}
            tileDisabled={({ date, view }) => (date && moment(date).format("YYYY-MM-DD") < moment().format("YYYY-MM-DD"))}
          />
        </div>
      </div>
    )
  }
}

DatePicker.propTypes = {
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
}

export default onClickOutside(DatePicker)