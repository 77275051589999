// import "babel-polyfill";
// import "core-js";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import TagManager from 'react-gtm-module'
import { PersistGate } from "redux-persist/integration/react";

import { Provider } from "react-redux";
import { ConnectedRouter } from "react-router-redux";
import { Route, Switch } from "react-router";
import superagent from "superagent";
import configureStore from "./store/configureStore";

import Account from "./containers/account";
import AuthPage from "./containers/authentication";
import BlogPage from "./containers/blog_page";
import BlogPostPage from "./containers/blog_post_page";
import Credits from "./containers/credits";
import Faq from "./containers/faq";
import Footer from "./containers/footer_new.jsx";
import Friends from "./containers/friends";
import GiftCardPage from "./containers/gift_card_page";
import Jobs from "./containers/jobs";
import Home from "./containers/home_new";
import Locations from "./containers/locations";
import Mentorship from "./containers/mentorship";
import NotFound from "./containers/404";
import Pricing from "./containers/pricing";
import InPersonPricing from "./containers/in_person_pricing";




import Press from "./containers/press";
import ProductPage from "./containers/product_page";
import Terms from "./containers/terms";
import Retreats from "./containers/retreats";
import Sauna from "./containers/sauna";
import Schedule from "./containers/schedule";
import Shop from "./containers/shop_new";
import Trainings from "./containers/trainings";
import TrainingScholarship from "./containers/training_scholarship";
import Teachers from "./containers/teachers";
import TeacherPage from "./containers/teacher_page";
import TvSignup from "./containers/tv_signup";
import TypeForm from "./components/type_form";
import ScholarshipTypeForm from "./components/scholarship_type_form";

import Workshops from "./containers/workshops";
import PasswordRecovery from "./containers/password_recovery";
import Tv from "./containers/tv_dashboard_new";
import TvVideos from "./containers/tv_categories/videos";
import FavoritedVideos from "./containers/tv_categories/favorited_videos";
import TvCollections from "./containers/tv_collections";
import TvCollectionPage from "./containers/tv_collection_page";

import VideoPage from "./containers/new_video_page";
import LiveStreamPage from "./containers/live_stream_page_new";
import LiveStreamVideoPage from "./containers/live_stream_video_page";
import PageLayout from "./containers/page_layout";

const store = configureStore();

ReactGA.initialize("UA-73828944-1");

const tagManagerArgs = {
  gtmId: 'GTM-5TLFDFV'
}

TagManager.initialize(tagManagerArgs)

store.history.listen((location, action) => {
  ReactGA.pageview(location.pathname + location.search);
});

const { hostname } = window.location;
const { pathname } = window.location;

if (hostname === "www.skytingyoga.com") {
  superagent
    .get(`/redirect_links?old_url=${pathname}`)
    .set("Accept", "application/json")
    .then(res => {
      if (res.body && res.body.new_url) {
        store.history.push(res.body.new_url);
      } else {
        store.history.push("/");
      }
    });
}

ReactDOM.render(
  <Provider store={store.store}>
    <PersistGate loading={null} persistor={store.persistor}>
      <ConnectedRouter history={store.history}>
        <PageLayout>
          <div className="Main-content">
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/schedule" component={Schedule} />
              <Route exact path="/pricing" component={Pricing} />
              <Route exact path="/in-person-pricing" component={InPersonPricing} />
              <Route exact path="/shop" component={Shop} />
              <Route exact path="/shop/gift_card" component={GiftCardPage} />
              <Route exact path="/shop/:id" component={ProductPage} />
              <Route exact path="/workshops" component={Workshops} />
              <Route exact path="/retreats" component={Retreats} />
              <Route exact path="/retreats/:id" component={TypeForm} />
              <Route
                exact
                path="/training/scholarships/apply/:id"
                component={ScholarshipTypeForm}
              />
              <Route
                exact
                path="/trainings/scholarship/:id"
                component={TrainingScholarship}
              />
              <Route exact path="/courses" component={Trainings} />
              <Route exact path="/courses/:id" component={TypeForm} />
              <Route exact path="/mentorship" component={Mentorship} />
              <Route exact path="/mentorship/:id" component={TypeForm} />
              <Route exact path="/sauna" component={Sauna} />
              <Route exact path="/teachers" component={Teachers} />
              <Route exact path="/teachers/:id" component={TeacherPage} />
              <Route exact path="/locations" component={Locations} />
              <Route exact path="/press" component={Press} />
              <Route exact path="/friends" component={Friends} />
              <Route exact path="/faq" component={Faq} />
              <Route exact path="/credits" component={Credits} />
              <Route exact path="/jobs" component={Jobs} />
              <Route exact path="/terms" component={Terms} />
              <Route exact path="/privacy" component={Terms} />
              <Route exact path="/account" component={Account} />
              <Route exact path="/tv" component={Tv} />
              <Route exact path="/tv/videos" component={TvVideos} />
              <Route exact path="/tv/favorited_videos" component={FavoritedVideos} />
              <Route exact path="/tv/collections" component={TvCollections} />
              <Route exact path="/tv/collections/:id" component={TvCollectionPage} />
              <Route
                exact
                path="/tv/live/:id"
                component={LiveStreamVideoPage}
              />
              <Route exact path="/tv/live" component={LiveStreamPage} />
              <Route exact path="/tv/:id" component={VideoPage} />
              <Route exact path="/get-started" component={AuthPage} />
              <Route exact path="/login" component={AuthPage} />
              <Route exact path="/signup" component={AuthPage} />
              <Route exact path="/forgot-password" component={AuthPage} />
              <Route exact path="/reset-password" component={AuthPage} />
              <Route exact path="/edit-profile" component={AuthPage} />          
              <Route exact path="/tv-signup/get-started" component={TvSignup} />
              <Route exact path="/tv-signup/login" component={TvSignup} />
              <Route exact path="/tv-signup/signup" component={TvSignup} />
              <Route exact path="/tv-signup/payment" component={TvSignup} />
              <Route
                exact
                path="/tv-signup/forgot-password"
                component={TvSignup}
              />
              <Route
                exact
                path="/tv-signup/reset-password"
                component={TvSignup}
              />
              <Route exact path="/tribune" component={BlogPage} />
              <Route exact path="/tribune/:id" component={BlogPostPage} />
              <Route
                exact
                path="/users/password/edit"
                component={PasswordRecovery}
              />
              <Route component={NotFound} />
            </Switch>
          </div>
          <Footer />
        </PageLayout>
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  document.body.appendChild(document.createElement("div"))
);
