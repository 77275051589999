import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ForgotPassword from '../forgot_password.jsx';
import EmailInput from "../authentication/email_input";
import PasswordInput from "../authentication/password_input";
import ButtonWithIcon from "../button_with_icon";
import ComponentWithLoading from "../component_with_loading";
import validateEmail from "../../helpers/validate_email";
import hand from "../../../../assets/images/icons/hand.svg";
import { authenticate } from "../../actions/authentication";

class LessonLoginForm extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      mode: 'login',
      error: null,
      email: "",
      password: "",
      confirm_password: "",
      confirmPasswordError: false,
      invalidPassword: false,
      authError: null,
      cameFromGetStarted: false
    };
  }

  login = () => {
    const {
      authenticate,
      changeStep
    } = this.props;
    const { email, password } = this.state;

    this.setState({ loading: true, authError: null });

    authenticate({ email: email.toLowerCase(), password })
      .then(res => {
        this.setState({ loading: false });
        changeStep('loading');
        this.props.continueBooking();
      })
      .catch(error => {
        if (error === "invalid mbo password") {
          this.setState({ loading: false });
        } else {
          this.setState({ loading: false, authError: error });
        }
      });
  };

  handleChange = event => {
    const { invalidPassword, confirmPasswordError, authError } = this.state;

    this.setState({ [event.target.name]: event.target.value });
    if (invalidPassword || confirmPasswordError || authError) {
      this.setState({
        invalidPassword: false,
        authError: false
      });
    }
  };

  checkEmail = () => {
    const { email } = this.state;
    this.setState({ loading: true });

    return superagent
      .get(`/users/check_email?email=${email.toLowerCase()}`)
      .set("Accept", "application/json")
      .then(res => {
        if (res.body.result === "Success") {
          this.props.changeStep("login");
          this.setState({ loading: false, cameFromGetStarted: true });
        } else {
          this.props.changeStep("register");
          this.setState({ loading: false, cameFromGetStarted: true });
        }
      })
      .catch(error => {
        this.setState({
          error: error.response.body,
          loading: false
        });
      });
  };

  renderLogin = () => {
    const {
      email,
      password,
      loading,
      authError,
      cameFromGetStarted
    } = this.state;
    const isValid = validateEmail(email);

    return (
      <div>
        <EmailInput
          handleChange={this.handleChange}
          value={email}
          onSubmit={() => this.checkEmail()}
          disabled={!!email && cameFromGetStarted}
          onInputClick={() => console.log("get-started")}
        />
        <PasswordInput
          handleChange={this.handleChange}
          value={password}
          onSubmit={() => this.login()}
        />
        {authError && (
          <div
            className="input-error-red"
            style={{ color: "#c01823", letterSpacing: "1px" }}
          >
            {authError}
          </div>
        )}
        <ComponentWithLoading isLoading={loading}>
          <ButtonWithIcon
            classNamesProp="high-75 confirm-button ga-tv-step-1"
            text="LOG IN"
            iconSrc={hand}
            disabled={!email || !isValid || !password}
            onClick={() => this.login()}
          />
        </ComponentWithLoading>
      </div>
    );
  };

  render() {
    const { changeStep } = this.props;
    const { mode } = this.state;

    return (
      <Fragment>
        {mode === 'password-recovery' ? (
          <ForgotPassword />
        ) : (
          <Fragment>
            <div className="signup-page login-popup">
              <div className="step">Step 1</div>
              <div className="signup-page-subtitle">LOG IN</div>
              <hr />
              <div className="mt-50 form-container">
                {this.renderLogin()}
              </div>
              <Fragment>
                <div className="bottom-links-row">
                  <div
                    onClick={() => this.setState({ mode: 'password-recovery' })}
                    className="link underline"
                  >
                    Forgot password?
                  </div>
                </div>
                <div className="bottom-links-row">
                  <div className="link">
                    DON'T HAVE AN ACCOUNT?&nbsp;
                    <span
                      onClick={() => changeStep("register")}
                      className="underline"
                    >
                      SIGN UP
                    </span>
                  </div>
                </div>
              </Fragment>
            </div>
          </Fragment>
        )}
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      authenticate
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(LessonLoginForm)