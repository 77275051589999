import { FETCH_BLOG_SUCCESS } from '../actions/blog';

const INITIAL_STATE = {
  posts: [],
  page: 1,
  isLastPage: false,
};

const blog = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_BLOG_SUCCESS:
      return {
        page: action.page,
        posts: state.posts.concat(action.posts),
        isLastPage: action.isLastPage,
      }
    default:
      return state;
  }
};

export default blog;
