import Api from '../api/user_schedules';

export const FETCH_USER_SCHEDULES = 'FETCH_USER_SCHEDULES';
export const FETCH_USER_SCHEDULES_ERROR = 'FETCH_USER_SCHEDULES_ERROR';
export const FETCH_USER_SCHEDULES_SUCCESS = 'FETCH_USER_SCHEDULES_SUCCESS';
export const DELETE_FROM_USER_SCHEDULES = 'DELETE_FROM_USER_SCHEDULES';

const fetchUserSchedulesSuccess = userSchedule => (dispatch) => {
  dispatch({ type: FETCH_USER_SCHEDULES_SUCCESS, userSchedule });
};

const fetchUserSchedulesError = error => (dispatch) => {
  dispatch({ type: FETCH_USER_SCHEDULES_ERROR, error });
};

export const fetchUserSchedules = period => (dispatch) => {
  dispatch({ type: FETCH_USER_SCHEDULES });

  const time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone

  Api.fetchUserSchedules(period, time_zone)
    .then(res => dispatch(fetchUserSchedulesSuccess(res.body)))
    .catch(error => dispatch(fetchUserSchedulesError(error)));
};

export const deleteFromUserSchedules = period => (dispatch) => {
  const time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone

  Api.fetchUserSchedules(period, time_zone)
    .then(res => dispatch(fetchUserSchedulesSuccess(res.body)))
    .catch(error => dispatch(fetchUserSchedulesError(error)));
}