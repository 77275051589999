import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {bindActionCreators} from "redux";
import { history } from "../store/configureStore";
import validateEmail from "../helpers/validate_email";
import superagent from "superagent";
import ButtonWithIcon from "./button_with_icon";
import { addUrl } from "../actions/redirect_url";

class HomeTvSignupBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      error: '',
    };
  }

  // checkEmail = () => {
  //   const { cameFrom } = this.props;
  //   const { email } = this.state;
  //   this.setState({ loading: true });

  //   return superagent
  //     .get(`/users/check_email?email=${email.toLowerCase()}`)
  //     .set("Accept", "application/json")
  //     .then(res => {
  //       if (res.body.result === "Success") {
  //         history.push({
  //           pathname: "/tv-signup/login",
  //           state: { cameFrom: cameFrom || '/tv', email },
  //         })
  //       } else {
  //         history.push({
  //           pathname: "/tv-signup/signup",
  //           state: { cameFrom: cameFrom || '/tv', email },
  //         })
  //       }
  //     })
  //     .catch(error => {
  //       this.setState({
  //         error: error.response.body,
  //         loading: false
  //       });
  //     });
  // };


  goToTvSignup = () => {
    const pathname = window.location.pathname;
    let page = '';
    if (pathname == "/tv/live") {
      page = 'TvLive';
    }
    this.props.addUrl(pathname, page);
    history.push({
      pathname: "/tv-signup/get-started"
    })
  //   const { email } = this.state;

  //   if (validateEmail(email)) {
  //     this.checkEmail()
  //   } else {
  //     this.setState({ error: 'Invalid email'});
  //   }
  }

  handleChange = event => {
    this.setState({
      error: null,
      email: event.target.value,
    })
  }

  render() {
    const { email, error } = this.state;
    const { blue, small, header, subheader, subscribed, hideHeader } = this.props;

    const hideForm = subscribed || subscribed === null;
    if (hideForm && blue) return <div />;

    const trialPeriodDays = Number(gon.tv_trial_period_days) > 0 ? Number(gon.tv_trial_period_days) - 1 : gon.tv_trial_period_days;

    return (
      <div className={`home-tv-signup-banner padding-lr ${blue ? 'blue' : ''} ${small ? 'small' : ''} ${hideHeader ? 'no-header' : ""}`}>
        { !hideHeader &&
          <h5
            className={`home-tv-signup-header ${hideForm ? 'mb-0' : ''}`}
            dangerouslySetInnerHTML={{ __html: header }}
          />
        }
        { !hideForm &&
          <Fragment>
            <p
              className="home-tv-signup-subheader"
              dangerouslySetInnerHTML={{ __html: subheader }}
            />
            <div className="form-container">
              {/* <div className="email-input-wrapper">
                <input
                  value={email}
                  onChange={(v) => this.handleChange(v)}
                  name="email"
                  type="email"
                  className="Input"
                  placeholder="ENTER YOUR EMAIL"
                  onKeyPress={e => {
                    if (e.key === "Enter") {
                      this.goToTvSignup();
                    }
                  }}
                />
                { error &&
                <div className="email-error">{error}</div>
                }
              </div> */}
              <ButtonWithIcon
                classNamesProp="Button"
                text={`TRY ${trialPeriodDays} DAYS FREE`}
                onClick={() => this.goToTvSignup()}
                withTriangle
              />
            </div>
          </Fragment>
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addUrl
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeTvSignupBanner);
