import React, {Component, Fragment} from 'react'
import moment from "moment/moment"

import ToggleCard from './toggle_card.jsx'
import Popup from './popup.jsx';

import Slider from 'react-slick'
import classNames from 'classnames'
import { programTeachersNames } from "../helpers/program_teachers_names";


export default class RetreatMobilePopup extends Component {
  constructor(props) {
    super(props)

    this.state = {
      descriptionIsOpen: false
    };
  }

  renderApplyButton = () => {
    const { retreat } = this.props;
    if (retreat.sold_out) return(
      <button className="Button" disabled>
        SOLD OUT
      </button>
    )

    if (!retreat.wetravel_link) return <div />

    return (
      <a href={retreat.wetravel_link} title="Reserve a spot" className="Button" target="_blank" >
        RESERVE A SPOT
      </a>
    )
  }

  render() {
    const {
      retreat,
      retreat: { photos, rooms, teachers },
      isOpen,
      policy,
      onClose
    } = this.props

    const DescriptionTextClass = classNames({
      'Description-text LargeBodyText': true,
      'is-open': this.state.descriptionIsOpen
    });

    return (
      <div className="Program-mobile-popup">
        <Popup
          key={retreat.id}
          id={retreat.slug}
          isOpen={isOpen}
          onClose={onClose}
        >
          <div className="Program-card Program-popup">
            <div className="Program-popup-header">{retreat.name}</div>
            <div className="Info Program-popup-info">
              <p>
                <span className="Bold">WHEN&nbsp;</span>
                <span>
                  {moment.utc(retreat.start_date_time).format('MMM. D')} - {moment.utc(retreat.end_date_time).format('MMM. D, YYYY')}
                </span>
              </p>
              { teachers.length > 0 &&
                <p>
                  <span className="Bold">W/&nbsp;</span>
                  {programTeachersNames(teachers).map(t => <span>{t}</span>)}
                </p>
              }
            </div>
            {this.renderApplyButton()}

            {(photos && photos.length > 0) &&
              <div className="Program-image-slider Image-slider">
                <Slider
                  dots={false}
                  infinite
                  speed={500}
                  fade
                  cssEase={'ease-in'}
                  arrows={photos.length > 1}
                >
                  {photos.map((photo) => (
                    <div key={`${photo.id}-photo`} className="Slider-item-image">
                      <img src={photo.image_url} alt="SKY TING" />
                    </div>
                  ))}
                  { retreat.video_url &&
                  <div key={`${retreat.id}-video`} className="Slider-item-video">
                    <iframe src={retreat.video_url}></iframe>
                  </div>
                  }

                </Slider>
              </div>
            }
            { retreat.description &&
              <Fragment>
                <div className={DescriptionTextClass} dangerouslySetInnerHTML={{ __html: retreat.description }} />
                {/*{this.state.descriptionIsOpen ? (*/}
                  {/*<div className="Toggle-description-btn" onClick={this.toggleFullDescription} >LESS...</div>*/}
                {/*) : (*/}
                  {/*<div className="Toggle-description-btn" onClick={this.toggleFullDescription} >MORE...</div>*/}
                {/*)}*/}
              </Fragment>
            }

            <div className="Expanded-cards">
              { retreat.includes &&
                <ToggleCard key={'includes-' + retreat.id} header="INCLUDES">
                  <div
                    className="Retreat-includes"
                    dangerouslySetInnerHTML={{__html: retreat.includes}}
                  />
                </ToggleCard>
              }
              { retreat.extras &&
                <ToggleCard key={'extras-' + retreat.id} header="EXTRAS">
                  <div
                    className="Extras-container"
                    dangerouslySetInnerHTML={{__html: retreat.extras}}
                  />
                </ToggleCard>
              }
              { retreat.transportation &&
                <ToggleCard key={'transportation-' + retreat.id} header="TRANSPORTATION">
                  <div
                    dangerouslySetInnerHTML={{__html: retreat.transportation}}
                    style={{textAlign: "left"}}
                  />
                </ToggleCard>
              }

              { (rooms && rooms.length > 0) &&
                <div className="Rooms-slider">
                  <ToggleCard key={'rooms-' + retreat.id} header="ROOMS & PRICING">
                    <Slider
                      dots={false}
                      infinite
                      speed={300}
                      cssEase={'ease-in'}
                      arrows={rooms.length > 1}
                      className="Program-block-slider Image-slider"
                      slidesToScroll={1}
                    >
                      {rooms.map(room => (
                        <div key={`${room.id}-room`} className="Card">
                          <div className="Card-image">
                            <img src={room.photo.url} alt="SKY TING" />
                          </div>
                          <div className="Rooms-card-bottom">
                            <div className="Price">{room.description} - ${room.price}</div>
                            { retreat.wetravel_link &&
                              <a href={retreat.wetravel_link} title="Reserve a spot" className="Button Button--secondary" target="_blank">RESERVE</a>
                            }
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </ToggleCard>
                </div>
              }
            </div>
            {this.renderApplyButton()}
            { policy &&
              <div className="Policy-text">
                <div className="SubHeadline">CANCELLATION POLICY</div>
                <div className="SmallBodyText">{policy.text}</div>
              </div>
            }
          </div>
        </Popup>
      </div>
    )
  }
}
