import { connect } from "react-redux";
import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import superagent from "superagent";
import moment from "moment/moment";
import Slider from "react-slick";
import { Link } from "react-router-dom";

import { isAuthenticated } from "../reducers/user";
import { openLoginPopup } from "../actions/login_popup";

import RenderLoading from "../components/render_loading.jsx";
import scrollToTop from "../helpers/scrollToTop";
import MetaTagsComponent from "../components/meta_tags_component";

import time from "../../../assets/images/icons/clock_icon.png";
import { programTeachersNames } from "../helpers/program_teachers_names";
import ToggleCard from "../components/toggle_card.jsx";

class TrainingScholarshipPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      training: null,
      error: null,
      isLoading: false,
      isMobile: false
    };
  }

  updateDimensions = () => {
    this.setState({
      isMobile: window.matchMedia("(max-width: 800px)").matches
    });
  };

  componentWillMount() {
    this.fetchScholarship();
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  componentDidMount() {
    scrollToTop();
    window.addEventListener("resize", this.updateDimensions);
  }

  fetchScholarship = () => {
    const id = this.props.match.params.id;

    this.setState({ isLoading: true, error: null });
    return superagent
      .get(`/trainings/scholarship/${id}`)
      .set("Accept", "application/json")
      .send()
      .then(res =>
        this.setState({
          training: res.body,
          isLoading: false
        })
      )
      .catch(err => {
        this.setState({
          error: err.response.body,
          isLoading: false
        });
      });
  };

  renderApplyBtn = () => {
    const { id } = this.props.match.params;

    return (
      <Link
        to={`/training/scholarships/apply/${id}`}
        className="Button w-100 with-max-width"
        target="_blank"
        title="Go to apply to training"
      >
        APPLY HERE
      </Link>
    );
  };

  renderTraining = () => {
    const {
      training,
      training: { photos, teachers, guests },
      isMobile,
    } = this.state;

    return (
      <div
        className={`Program-card scholarship ${
          isMobile ? "Program-popup mb-5" : ""
        }`}
        key={training.id}
      >
        <div className="Visible-part">
          <div className="Block-wrapper">
            <div className="Left-block">
              <div>
                <h3 className="Program-card-header">{training.name}</h3>
                <div className="Info">
                  {training.duration && (
                    <p className="Bold">
                      <img src={time} height="14" width="14" alt="duration" />
                      <span>&nbsp;&nbsp;{training.duration} HR</span>
                    </p>
                  )}
                  <p>
                    <span className="Bold">WHEN&nbsp;</span>
                    <span>
                      {moment.utc(training.start_date_time).format("MMM. D")} -{" "}
                      {moment
                        .utc(training.end_date_time)
                        .format("MMM. D, YYYY")}
                    </span>
                  </p>
                  <p>
                    {training.teacher_header ? (
                      <Fragment>
                        <span className="Bold">W/&nbsp;</span>
                        <span>{training.teacher_header}</span>
                      </Fragment>
                    ) : (
                      <Fragment>
                        {teachers.length > 0 &&
                          <Fragment>
                            <span className="Bold">W/&nbsp;</span>
                            {programTeachersNames(teachers).map(t => (
                              <span>{t}</span>
                            ))}
                          </Fragment>
                        }
                      </Fragment>
                    )}
                  </p>
                </div>
                {this.renderApplyBtn()}
              </div>
            </div>
            <div className="Right-block">
              {photos && photos.length > 0 && photos[0].image_url ? (
                <div
                  className="Program-card-image-wrapper"
                  style={{ backgroundImage: `url(${photos[0].image_url})` }}
                />
              ) : (
                <div
                  className="Program-card-image-wrapper empty"
                />
              )}
            </div>
          </div>
        </div>
        <div className="Expanded-part d-block">
          {photos && photos.length > 1 && (
            <div className="Program-image-slider Image-slider">
              <Slider
                dots={false}
                infinite
                speed={500}
                autoplay
                fade
                cssEase="ease-in"
                arrows={photos.length > 2}
              >
                {photos.slice(1).map((photo, index) => (
                  <div key={photo.id} className="Slider-item-image">
                    <img src={photo.image_url} alt="Training image" />
                  </div>
                ))}
              </Slider>
            </div>
          )}
          <div
            className="Description-text LargeBodyText"
            dangerouslySetInnerHTML={{ __html: training.description }}
          />
          <div className="Expanded-cards">
            {training.includes && (
              <ToggleCard
                key={"program-includes-" + training.id}
                header="PROGRAM INCLUDES"
              >
                <div
                  className="Includes-container"
                  dangerouslySetInnerHTML={{ __html: training.includes }}
                />
              </ToggleCard>
            )}
            {teachers && teachers.length > 0 && (
              <ToggleCard key={"mentors-" + training.id} header="MENTORS">
                <Slider
                  dots={false}
                  infinite
                  speed={300}
                  cssEase="ease-in"
                  arrows={teachers.length > 1}
                  className="Program-block-slider Image-slider"
                  slidesToScroll={1}
                >
                  {teachers.map(mentor => (
                    <div key={mentor.id} className="Card Mentor-card">
                      <div className="Card-image">
                        <div className="Image-wrapper">
                          <img src={mentor.photo_url ? mentor.photo_url : mentor.image_url} alt={mentor.name} />
                        </div>
                      </div>
                      <div className="Mentor-card-info">
                        <a
                          href={`/teachers?teacher=${mentor.slug}`}
                        >
                          <div className="Button Button--secondary">
                            {mentor.name}
                          </div>
                        </a>
                        {mentor.short_info && <div>{mentor.short_info}</div>}
                        {mentor.instagram ? (
                          <a
                            href={`https://www.instagram.com/${mentor.instagram.replace(
                              "@",
                              ""
                            )}`}
                            target="_blank"
                          >
                            <div>@{mentor.instagram.replace("@", "")}</div>
                          </a>
                        ) : (
                          <Fragment>
                            {mentor.website && (
                              <div>
                                <a href={mentor.website} target="_blank">
                                  {mentor.website}
                                </a>
                              </div>
                            )}
                          </Fragment>
                        )}
                      </div>
                    </div>
                  ))}
                </Slider>
              </ToggleCard>
            )}
            {guests && guests.length > 0 && (
              <ToggleCard
                key={`guests-${training.id}`}
                header="GUEST LECTURERS"
              >
                <Slider
                  dots={false}
                  infinite
                  speed={300}
                  cssEase="ease-in"
                  arrows={guests.length > 1}
                  className="Program-block-slider Image-slider"
                  slidesToScroll={1}
                >
                  {guests.map(guest => (
                    <div key={guest.id} className="Card Mentor-card">
                      <div className="Card-image">
                        <div className="Image-wrapper">
                          <img src={guest.photo_url ? guest.photo_url : ""} alt={guest.name} />
                        </div>
                      </div>
                      <div className="Mentor-card-info">
                        {guest.teacher ? (
                          <a
                            href={`/teachers?teacher=${guest.teacher.slug}`}
                          >
                            <div className="Button Button--secondary">
                              {guest.name}
                            </div>
                          </a>
                        ) : (
                          <div className="Guest-name">{guest.name}</div>
                        )}

                        <div>{guest.info}</div>
                        {guest.instagram && (
                          <a
                            href={`https://www.instagram.com/${guest.instagram.replace(
                              "@",
                              ""
                            )}`}
                            target="_blank"
                          >
                            <div>@{guest.instagram.replace("@", "")}</div>
                          </a>
                        )}
                      </div>
                    </div>
                  ))}
                </Slider>
              </ToggleCard>
            )}

            {training.schedule && (
              <ToggleCard key={`schedule-${training.id}`} header="SCHEDULE">
                <div
                  className="Left-align dates-container"
                  dangerouslySetInnerHTML={{ __html: training.schedule }}
                />
              </ToggleCard>
            )}
            {training.requirements && (
              <ToggleCard
                key={`requirements-${training.id}`}
                header="REQUIREMENTS"
              >
                <div
                  className="Left-align"
                  dangerouslySetInnerHTML={{ __html: training.requirements }}
                />
              </ToggleCard>
            )}
            {training.tuition && (
              <ToggleCard key={`tuition-${training.id}`} header="TUITION">
                <div
                  className="Left-align tuition-container"
                  dangerouslySetInnerHTML={{ __html: training.tuition }}
                />
              </ToggleCard>
            )}
          </div>
          {this.renderApplyBtn()}

          {training.deposit_policy && (
            <div className="Policy-text">
              <div className="SubHeadline">DEPOSIT POLICY</div>
              <div className="SmallBodyText">{training.deposit_policy}</div>
            </div>
          )}
        </div>
      </div>
    );
  };

  render() {
    const { isLoading, error } = this.state;

    return (
      <Fragment>
        <MetaTagsComponent
          title="SKY TING | Programs | Training Scholarship"
          desc="SKY TING trainings"
          url="trainings"
        />
        <div className="Page">
          <div className="Page-content">
            <div className="mt-5 mb-5 pb-1" />
            {isLoading ? (
              <RenderLoading />
            ) : (
              <Fragment>
                {error ? (
                  <div className="Empty-result">
                    {error.message || "Training scholarship not found"}
                  </div>
                ) : (
                  <Fragment>{this.renderTraining()}</Fragment>
                )}
              </Fragment>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: isAuthenticated(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      openLoginPopup
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TrainingScholarshipPage);
