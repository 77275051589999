import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import superagent from "superagent";
import ComponentWithLoading from "../component_with_loading";
import { Elements, StripeProvider } from "react-stripe-elements";
import StripeForm from "./stripe_form";
import Script from "react-load-script";
import Api from "../../api/live_video_subscription";
import { v4 as uuidv4 } from "uuid";
import { requestAvailable } from "../../helpers/request_available";
import play from "../../../../assets/images/icons/Play.svg";
import ButtonWithIcon from "../button_with_icon";
import GiftCardPayment from "./gift_card_payment";
import { updateUserInfo } from "../../actions/user_update";
import TagManager from "react-gtm-module";
import calcPriceWithCoupon from "../../helpers/calc_price_with_coupon";
import addTagManagerEvent from "../../helpers/add_tag_manager_event";
import Coupon from "./coupon";

class Payment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userBalance: null,
      loadingCards: true,
      selectedPayment: null,
      loading: false,
      cardsError: null,
      subscriptionError: null,
      idemp_key_customer: null,
      idemp_key_subscription: null,
      idemp_key_balance_increase: null,
      idemp_key_balance_decrease: null,
      requestSentAt: null,
      giftCardIsChosen: false,
      showNewCardForm: false,
      stripe: null,
      paymentOptions: [],
      coupon: null,
      couponPercentOff: null,
      couponAmountOff: null,
      couponDuration: null,
      couponDurationInMonths: null,
      couponApplied: false,
      updateSubscriptionPopupModal: false,
      purchaseVideo: true
    };
  }

  componentDidMount() {
    this.getUserCards();
    this.generateIdempKey();
    TagManager.dataLayer({ dataLayer: { "User-Status": "Step-3" } });
  }

  getUserCards = () => {
    return superagent
      .get(`/users/stripe_sources?check_user_balance=true`)
      .set("Accept", "application/json")
      .then(res => {
        this.setState({
          paymentOptions: this.paymentOptions(res.body.cards),
          userBalance: res.body.user_balance,
          loadingCards: false
        });

        if (res.body.cards.length === 0) {
          this.setState({ showNewCardForm: true });
        }
      })
      .catch(error => {
        this.setState({
          cardsError: error,
          loadingCards: false
        });
      });
  };

  paymentOptions = stripeCards => {
    if (stripeCards.length === 0) return [];

    const options = stripeCards.map(s => {
      return { id: s["id"], label: `XXXX XXXX XXXX ${s["last4"]}` };
    });

    options.push({ id: "new_card", label: "ADD A NEW CARD" });

    return options;
  };

  onPaymentSelect = event => {
    const { paymentOptions } = this.state;

    const option = paymentOptions.find(o => o.id === event.target.value);

    if (option.id === "new_card") {
      this.setState({
        selectedPayment: null,
        showNewCardForm: true,
        giftCardIsChosen: false
      });
    } else {
      this.setState({ selectedPayment: option, showNewCardForm: false });
    }
  };

  setStripeKey = () => {
    const strKey = gon.stripe_pub_key;
    this.setState({ stripe: window.Stripe(strKey) });
  };

  renderNewCardForm = () => {
    const { user, subscription: { status } } = this.props;

    const {
      loading,
      subscriptionError,
      giftCardIsChosen,
      showNewCardForm,
      coupon,
      couponPercentOff,
      couponDuration,
      couponDurationInMonths
    } = this.state;

    return (
      <div className="new-card-container">
        <div className="payment-subtitle">ADD A NEW CARD</div>
        <StripeProvider stripe={this.state.stripe}>
          <Elements>
            <StripeForm
              user={user}
              confirm={token => this.createSubscription(token)}
              creatingCard={loading}
              subscriptionError={subscriptionError}
              changeIdempKeys={() => this.generateIdempKey()}
              showBtn={showNewCardForm || status === "pending"}
              chooseGiftCard={val => this.chooseGiftCard(val)}
              giftCardIsChosen={giftCardIsChosen}
              coupon={coupon}
              couponPercentOff={couponPercentOff}
              couponDuration={couponDuration}
              couponDurationInMonths={couponDurationInMonths}
              availableBalance={this.calcAvailableGiftBalance()}
              subscriptionStatus={status}
            />
          </Elements>
        </StripeProvider>
      </div>
    );
  };

  chooseGiftCard = () => {
    const { giftCardIsChosen } = this.state;
    this.setState({ giftCardIsChosen: !giftCardIsChosen });
  };

  createSubscription = (stripe_tok = null) => {
    const { selectedPlan, updateUserInfo } = this.props;
    const { coupon } = this.state;

    const {
      idemp_key_customer,
      idemp_key_subscription,
      idemp_key_balance_increase,
      idemp_key_balance_decrease,
      requestSentAt,
      selectedPayment,
      giftCardIsChosen,
      purchaseVideo,
    } = this.state;

    if (!requestAvailable(requestSentAt)) return;

    const default_source =
      selectedPayment && selectedPayment.id !== "new_card"
        ? selectedPayment.id
        : null;

    this.setState({
      loading: true,
      subscriptionError: null,
      requestSentAt: new Date().getTime()
    });

    const giftCardAmount = giftCardIsChosen
      ? this.calcAvailableGiftBalance()
      : null;

    const video_slug = (window.location.search).replace("?", "");
    const isLiveVideo = true;

    Api.createSubscription(
      selectedPlan.id,
      idemp_key_customer,
      idemp_key_subscription,
      idemp_key_balance_increase,
      idemp_key_balance_decrease,
      stripe_tok,
      coupon,
      giftCardAmount,
      default_source,
      purchaseVideo,
      isLiveVideo,
      video_slug
    )
      .then(res => {
        this.setState({ loading: false });
        if (res.body.user) {
          updateUserInfo(res.body.user);
        }
        const userStatus =
          selectedPlan.interval === "year"
            ? "Completed-annualy"
            : "Completed-monthly";
        addTagManagerEvent(window.location.pathname, userStatus);
        document.cookie = `user_tv_subscription=${selectedPlan.interval}`;
        this.props.continueBooking();
      })
      .catch(err => {
        this.setState({
          subscriptionError: err.response.body,
          loading: false
        });
      });
  };

  generateIdempKey = () => {
    const idemp_key_customer = uuidv4();
    const idemp_key_subscription = uuidv4();
    const idemp_key_balance_increase = uuidv4();
    const idemp_key_balance_decrease = uuidv4();

    this.setState({
      idemp_key_customer,
      idemp_key_subscription,
      idemp_key_balance_increase,
      idemp_key_balance_decrease
    });
  };

  calcAvailableGiftBalance = () => {
    const { userBalance, couponPercentOff, couponAmountOff } = this.state;
    const { selectedPlan } = this.props;

    if (!userBalance || userBalance === 0) return 0;

    const price = calcPriceWithCoupon(
      couponPercentOff,
      couponAmountOff,
      selectedPlan.amount_in_cents
    );

    if (userBalance >= price) {
      return price;
    } else {
      return userBalance;
    }
  };

  renderCoupon = () => {
    const { selectedPlan, subscription: { status } } = this.props;
    const { coupon, couponApplied } = this.state;

    return (
      <>
        <Coupon
          setCoupon={coupon => this.setState({ coupon, couponApplied: true })}
          setCouponValues={(
            percentOff,
            amountOff,
            duration,
            months,
          ) =>
            this.setState({
              couponPercentOff: percentOff,
              couponAmountOff: amountOff,
              couponDuration: duration,
              couponDurationInMonths: months
            })
          }
          couponVal={coupon}
          couponApplied={couponApplied}
          subscriptionStatus={status}
          selectedPlan={selectedPlan}
        />
      </>
    )
  }

  renderSubscribeScreen = () => {
    const {
      paymentOptions,
      loading,
      selectedPayment,
      showNewCardForm,
      subscriptionError,
      couponPercentOff,
      couponAmountOff,
      coupon
    } = this.state;

    const { selectedPlan } = this.props;

    const noPaymentNeeded =
      (coupon && couponPercentOff && Number(couponPercentOff) === 100) ||
      (selectedPlan && selectedPlan.amount_in_cents === 0);

    return (
      <>
        {!noPaymentNeeded && (
          <Fragment>
            {paymentOptions.length > 0 && (
              <Fragment>{this.selectPaymentType()}</Fragment>
            )}
            {paymentOptions.length > 0 && showNewCardForm && (
              <div className="text-with-line">
                <div className="line" />
                <span className="text">OR</span>
                <div className="line" />
              </div>
            )}

            {showNewCardForm && this.renderNewCardForm()}
          </Fragment>
        )}
        {this.renderCoupon()}
        {(noPaymentNeeded || !showNewCardForm) && (
          <Fragment>
            {subscriptionError && (
              <div className="input-error-red">
                {subscriptionError.message || "Something went wrong"}
              </div>
            )}

            <div className="mb-2 pt-1" />
            <ComponentWithLoading isLoading={loading}>
              {coupon &&
                (couponPercentOff || couponAmountOff) &&
                (noPaymentNeeded || (!noPaymentNeeded && selectedPayment)) && (
                  <div className="coupon-applied">
                    {couponPercentOff
                      ? `${couponPercentOff}% OFF COUPON CODE APPLIED!`
                      : `$${Number(couponAmountOff) /
                      100} OFF COUPON CODE APPLIED!`}
                  </div>
                )}
              <ButtonWithIcon
                classNamesProp="high-75 confirm-button ga-tv-subscribe-end ga-tv-step-3 mt-3"
                text="START WATCHING"
                iconSrc={selectedPayment || noPaymentNeeded ? play : null}
                disabled={!noPaymentNeeded && !selectedPayment}
                onClick={() => this.createSubscription()}
              />
            </ComponentWithLoading>
          </Fragment>
        )}
      </>
    );
  };

  selectPaymentType = () => {
    const {
      paymentOptions,
      selectedPayment,
      showNewCardForm,
      giftCardIsChosen
    } = this.state;

    return (
      <div className={showNewCardForm ? "faded" : ""}>
        <div className="payment-subtitle">SELECT PAYMENT TYPE</div>
        <select
          className="payment-select"
          id="payment-select"
          onChange={event => this.onPaymentSelect(event)}
          value={selectedPayment ? selectedPayment.id : ""}
        >
          <option value="" disabled hidden>
            SELECT CARD
          </option>
          {paymentOptions.map(option => (
            <option key={option.id} value={option.id}>
              {option.label}
            </option>
          ))}
        </select>
        {!showNewCardForm && (
          <GiftCardPayment
            chooseGiftCard={() => this.chooseGiftCard()}
            isChecked={giftCardIsChosen}
            disabled={!selectedPayment}
            availableBalance={this.calcAvailableGiftBalance()}
          />
        )}
      </div>
    );
  };

  render() {
    const {
      loadingCards,
      couponPercentOff,
      couponAmountOff,
    } = this.state;

    const {
      pageContent,
      selectedPlan
    } = this.props;

    const newPrice = selectedPlan ? calcPriceWithCoupon(couponPercentOff, couponAmountOff, selectedPlan.amount_in_cents) : null;

    return (
      <Fragment>
        <Script
          url="https://js.stripe.com/v3/"
          attributes={{ id: "stripe-js" }}
          onLoad={() => this.setStripeKey()}
        />
        <ComponentWithLoading isLoading={loadingCards}>
          <div className="payment">
            <div className="step">
              {(pageContent && pageContent.payment_step) || "STEP 2"}
            </div>
            <div className="signup-page-subtitle">Add payment method</div>
            <div className='secondary-message'>
              <div>
                <span className="new-price">
                  {" "}
                  ${newPrice} {newPrice === 0 ? "" : `/ ${selectedPlan.interval} (+ $5 LIVE CLASS)`}
                </span>
              </div>
            </div>
            <hr />
            <div
              className="signup-page-text"
              dangerouslySetInnerHTML={{
                __html: pageContent && pageContent.payment_text
              }}
            />

            <div className="form-container mt-4">{this.renderSubscribeScreen()}</div>
          </div>
        </ComponentWithLoading>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  balance: state.balance,
  user: state.user
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateUserInfo
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Payment);
