import superagent from 'superagent'

const API_ENDPOINT = '/schedule'

const fetchSchedule = ({ start_date_time, end_date_time, time_zone }) => {
  return superagent
    .get(API_ENDPOINT)
    .set('Accept', 'application/json')
    .query({ start_date_time, end_date_time, time_zone })
}

export default { fetchSchedule }
