import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';

import { addBillingLastFour, dropBillingLastFour, closeBillingForm } from '../../actions/billing';
import Loading from './../../../../assets/images/icons/yinyang_icon.png';

import PromoCodeInput from '../promo_code_input.jsx';
import Billing from '../billing';
import CreditCard from '../credit_card';
import CheckoutApi from "../../api/checkout";

class LessonBilling extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardIsChosen: false,
      loading: false,
      error: null,
      promoCode: null,
    };
  }

  buyService = () => {
    const { promoCode } = this.state;

    const { checkoutTotalCart, activeService, continueBooking } = this.props;
    this.setState({ loading: true, error: null });

    CheckoutApi.checkoutService(activeService.id, checkoutTotalCart.grand_total, null, false, promoCode)
      .then(() => {
        this.setState({ loading: false });
        continueBooking();
      })
      .catch((error) => {
        this.setState({ loading: false, error: error });
      });
  }

  buyContract = (amount) => {
    const { promoCode } = this.state;

    const { activeService, continueBooking } = this.props
    this.setState({ loading: true, error: null });

    CheckoutApi.checkoutService(activeService.id, amount, null, true, promoCode)
      .then(() => {
        this.setState({ loading: false });
        continueBooking();
      })
      .catch((error) => {
        this.setState({ loading: false, error: error });
      });
  }

  chooseCard = () => {
    const { cardIsChosen } = this.state
    const {
      user: { card_last_four },
      addBillingLastFour,
      dropBillingLastFour,
      showBillingForm,
      closeBillingForm
    } = this.props

    this.setState({cardIsChosen: !cardIsChosen})
    if (cardIsChosen) {
      dropBillingLastFour()
    } else {
      addBillingLastFour(card_last_four)
    }

    if (showBillingForm) {
      closeBillingForm()
    }
  }

  contentForService = () => {
    const {
      activeService,
      checkoutTotalCart,
      checkoutTotalIsFetching,
    } = this.props;

    const { loading, error } = this.state;

    return (
      <Fragment>
        <h2 className="Purchase-popup-title">COMPLETE PURCHASE</h2>
        { checkoutTotalIsFetching ? (
          <div className="inline-loading">
            <img src={Loading} height="21" width="21"/>
            <div>LOADING...</div>
          </div>
        ) : (
          <Fragment>
            { checkoutTotalCart ? (
              <div className="Service-name">
                {activeService.name}: ${Number(checkoutTotalCart.grand_total).toFixed(2)}
              </div>
            ) : (
              <div className="Service-name">
                {activeService.name}: ${Number(activeService.price).toFixed(2)}
              </div>
            )}
          </Fragment>
        )}

        <CreditCard
          chooseCard={this.chooseCard}
          isChecked={this.state.cardIsChosen}
        />
        <div className="text-center mt-4 mb-3">OR</div>
        <Billing
          toggleCard={this.chooseCard}
          cardIsChosen={this.state.cardIsChosen}
        />
        { activeService &&
          <PromoCodeInput
            setPromoCode={promoCode => this.setState({ promoCode })}
            activeLessonService={activeService}
          />
        }
        { loading ? (
          <div className="inline-loading">
            <img src={Loading} height="21" width="21"/>
            <div>PLEASE WAIT, CHECKOUT IN PROGRESS...</div>
          </div>
        ) : (
          <Fragment>
            { error &&
            <div className="OrderPopup-error">
              The payment was unsuccessful. Please try again.
            </div>
            }
            <button
              className="SmallButton"
              onClick={() => this.buyService()}
              disabled={!activeService || !this.state.cardIsChosen || checkoutTotalIsFetching}
            >
              Confirm and reserve
            </button>
          </Fragment>
        )}
      </Fragment>
    )
  }

  contentForContract = () => {
    const {
      activeService,
      activeService: { name, contract },
    } = this.props

    const { loading, error } = this.state;

    return (
      <Fragment>
        <h2 className="Purchase-popup-title">COMPLETE PURCHASE</h2>
        <div className="Service-name">
          {name}: ${Number(contract.first_payment_amount_subtotal).toFixed(2)}
        </div>

        { error ? (
          <div className="OrderPopup-error">
            {error.response.body.message ? error.response.body.message : 'The payment was unsuccessful. Please try again.'}
          </div>
        ) : (
          <Fragment>
            <CreditCard
              chooseCard={this.chooseCard}
              isChecked={this.state.cardIsChosen}
            />
            <div className="text-center mt-4 mb-3">OR</div>
            <Billing
              toggleCard={this.chooseCard}
              cardIsChosen={this.state.cardIsChosen}
            />
            { loading ? (
              <div className="inline-loading">
                <img src={Loading} height="21" width="21"/>
                <div>PLEASE WAIT, CHECKOUT IN PROGRESS...</div>
              </div>
            ) : (
              <Fragment>
                <button
                  className="SmallButton"
                  onClick={() => this.buyContract(contract.first_payment_amount_subtotal)}
                  disabled={!activeService || !this.state.cardIsChosen}
                >
                  Confirm and reserve
                </button>
              </Fragment>
            )}
          </Fragment>
        )}
      </Fragment>
    )
  }

  render() {
    if (!this.props.activeService) return <div />
    const { activeService } = this.props

    const isContract = !!activeService.contract;

    return (
      <Fragment>
        <div className="Purchase-popup">
          { isContract ? this.contentForContract() : this.contentForService() }
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user,
  checkoutTotalCart: state.checkoutTotal.cart,
  checkoutTotalIsFetching: state.checkoutTotal.isFetching,
  showBillingForm: state.billing.showBillingForm,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addBillingLastFour,
      dropBillingLastFour,
      closeBillingForm,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(LessonBilling)
