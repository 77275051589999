import Api from '../api/credit_card';
import { FETCH_CURRENT_USER_SUCCESS } from "./user";

export const FETCH_CREDIT_CARD = 'FETCH_CREDIT_CARD';
export const FETCH_CREDIT_CARD_ERROR = 'FETCH_CREDIT_CARD_ERROR';
export const FETCH_CREDIT_CARD_SUCCESS = 'FETCH_CREDIT_CARD_SUCCESS';

export const getStoredCreditCard = () => dispatch => {
  dispatch({ type: FETCH_CREDIT_CARD });

  Api.getStoredCreditCard()
    .then(res =>
      dispatch({
        type: FETCH_CREDIT_CARD_SUCCESS,
        creditCard: res.body
      })
    )
    .catch(error =>
      dispatch({
        type: FETCH_CREDIT_CARD_ERROR,
        error: error
      })
    );
};

export const deleteCreditCard = () => (dispatch, getState) => {
  const { user } = getState();

  return new Promise(async(resolve, reject) => {
    await Api.deleteCard(user.id)
      .then(res => {
        dispatch({ type: FETCH_CURRENT_USER_SUCCESS, user: res.body })
        resolve(true);
      })

      .catch((err) => {
        reject(err.response.body);
      })
  });
};
