import _ from 'lodash';
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import superagent from "superagent";
import { connect } from "react-redux";

import RenderLoading from "./render_loading";
import RenderError from "./render_error"
import ProgramPopup from "./lesson_booking/program_popup";

import LessonApi from "../api/lesson";
import { fetchServicesFor } from "../actions/services";
import { fetchUserSchedule } from "../actions/user_schedule";
import { getBalance } from "../actions/balances";
import { isAuthenticated } from "../reducers/user";
import * as typeformEmbed from '@typeform/embed'


class TypeForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      allowedTypes: ['retreats', 'trainings', 'mentorship', 'courses'],
      error: false,
      lessonId: props.match.params.id,
      selectedService: null,
      step: null,
      bookingError: null,
      showBookingPopup: false
    }
  }

  getLessonType = () => {
    const url = this.props.match.url
    const type = url.substr(1).split("/")[0]

    return type
  }

  componentDidMount() {
    const mindbody_identifier = this.state.lessonId
    const type = this.getLessonType()

    if (_.isEmpty(mindbody_identifier) || !this.state.allowedTypes.includes(type)) {
      this.setState({
        isLoading: false,
        error: true
      })
    } else {
      return superagent
        .get(`/${type}/${mindbody_identifier}`)
        .set('Accept', 'application/json')
        .send().then(res => {

          if (res.body && (res.body.is_past)) {
            this.props.history.push('/courses');
          }

          this.setState({
            isLoading: false,
            lesson: res.body
          })
          if (!res.body.error) {
            this.renderTypeform(res.body)
          }
        }
      )
    }
  }

  onSubmitAction = () => {
    // const { lesson } = this.state;

    // this.bookLesson(lessonId);
  }

  renderTypeform = (lesson) => {
    const typeform_id = lesson.type_form
    let type_form = ''

    if (_.isEmpty(typeform_id)) {
      type_form = 'nz8wV6'
    } else {
      type_form = typeform_id
    }

    const elm = this.typeformElm

    typeformEmbed.makeWidget(
      elm,
      `https://skytingyoga.typeform.com/to/${type_form}`,
      { onSubmit: this.onSubmitAction }
    )
  }

  bookLesson = (lessonId, showWaitlist = false) => {
    const { isAuthenticated, fetchUserSchedule, getBalance } = this.props;
    this.setState({ bookingError: null });

    if (!isAuthenticated) {
      this.setState({ step: 'login', showBookingPopup: true });
    } else {
      this.setState({
        step: 'bookingProgress',
        showBookingPopup: true,
      });
      LessonApi.bookLesson(lessonId, showWaitlist)
        .then(res => {
          this.setState({
            step: showWaitlist ? "successWaitlist" : 'success',
            showBookingPopup: true
          });
          fetchUserSchedule();
          getBalance();
        })
        .catch(error => {
          if (error.response.body && error.response.body.message === 'no balance') {
            this.setState({ step: 'payment' });
          } else if (error.response.body && error.response.body.message === "full class") {
            this.setState({
              step: "error",
              showWaitlist: true,
            });
          } else if (error.response.body && error.response.body.message === "Client needs a service to be added to the waitlist.") {
            this.setState({ step: 'payment' });
          } else {
            this.setState({
              bookingError: error.response.body,
              step: 'error',
              showWaitlist: false,
            });
          }
          this.setState({ showBookingPopup: true })
        });
    }
  }

  closeBookingPopup = () => {
    this.setState({
      showBookingPopup: false,
      selectedService: null,
      step: null,
      bookingError: null,
      showWaitlist: false,
    })
  }

  render() {
    const {
      isLoading,
      error,
      lesson,
      selectedService,
      step,
      bookingError,
      showBookingPopup,
      lessonId,
      showWaitlist,
    } = this.state

    if (isLoading) return <div className="mt-5"><RenderLoading /></div>
    if (error) return <RenderError />

    if (!isLoading && lesson && lesson.error) return <div>{lesson.error}</div>
    return (
      <div>
        <div
          style={{ height: '500px' }}
          ref={ tf => this.typeformElm = tf }
        />
        { showBookingPopup &&
          <ProgramPopup
            lessonId={lessonId}
            bookLesson={(id, joinWaitlist) => this.bookLesson(id, joinWaitlist)}
            selectedService={selectedService}
            step={step}
            bookingError={bookingError}
            changeStep={s => this.setState({ step: s })}
            selectService={service => this.setState({ selectedService: service })}
            closePopup={() => this.closeBookingPopup()}
            lessonType={this.getLessonType()}
            showWaitlist={showWaitlist}
          />
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state.schedule,
  lesson: state.lesson,
  lessonPopup: state.lessonPopup,
  activeLesson: state.activeLesson,
  isAuthenticated: isAuthenticated(state),

})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchServicesFor,
      fetchUserSchedule,
      getBalance,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(TypeForm)