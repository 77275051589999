import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import scrollToTop from "../helpers/scrollToTop";
import MetaTagsComponent from "../components/meta_tags_component";
import AuthComponent from "../components/authentication/auth_component";
import superagent from "superagent";
import { isAuthenticated, hasRequiredMboInfo } from "../reducers/user";
import sky from "./../../../assets/images/sky_gray.jpg";
import { openShipping } from "../actions/shipping";

class AuthPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageContent: null,
      loadingPageContent: true,
      pageError: null,
      previousState: {},
    };
  }

  componentDidMount() {
    scrollToTop();
    if (this.props.location.state) {
      this.setState({ previousState: this.props.location.state })
    }
    this.getSignupPageContent();
  }

  getSignupPageContent = () => {
    return superagent
      .get("/signup_page")
      .set("Accept", "application/json")
      .send()
      .then(res =>
        this.setState({
          pageContent: res.body,
          loadingPageContent: false
        })
      )
      .catch(error => {
        this.setState({ pageError: error.response.body, loadingPageContent: false });
      });
  }

  getModeByPath = () => {
    const { location: { pathname } } = this.props;

    switch (pathname) {
      case "/login":
        return 'login';
      case "/signup":
        return 'signup';
      case "/forgot-password":
        return "forgot-password";
      case "/reset-password":
        return "reset-password";
      case "/edit-profile":
        return "edit-profile";
      default:
        return "get-started";
    }
  }

  afterAuth = () => {
    const { previousState: { cameFrom, isShopFlow } } = this.state;

    if (isShopFlow) {
      this.props.history.push(cameFrom || "/shop");
      this.props.openShipping();
    } else {
      this.props.history.push(cameFrom || "/");
    }
  }

  render() {
    const { hasRequiredMboInfo } = this.props;
    const { pageContent, loadingPageContent, previousState: { isShopFlow } } = this.state;

    const mode = this.getModeByPath()

    return (
      <Fragment>
        <MetaTagsComponent
          title="SKY TING | Practice anytime. Anywhere."
          desc="SKY TING | Practice anytime. Anywhere."
        />
        <div
          className="signup-page"
          style={{ backgroundImage: `url(${pageContent && pageContent.background_url ? pageContent.background_url : sky})`}}
        >
          <AuthComponent
            pageContent={pageContent}
            loadingPageContent={loadingPageContent}
            mode={mode}
            afterAuth={() => this.afterAuth()}
            location={this.props.location}
            hasRequiredMboInfo={hasRequiredMboInfo}
            isShopFlow={isShopFlow}
          />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: isAuthenticated(state),
  hasRequiredMboInfo: hasRequiredMboInfo(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      openShipping
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthPage);
