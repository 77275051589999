import React, { Component, Fragment } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { changeShippingForm } from "../actions/shipping";
import RenderLoading from "./render_loading.jsx";
import { addProductToCart, addShipmentToCart } from "../actions/cart";

import Earth from "./../../../assets/images/icons/earth.png";
import Carbon from "./../../../assets/images/icons/carbon.png";

const validate = values => {
  const errors = {};

  const {
    first_name,
    last_name,
    address_line1,
    city,
    state,
    postal_code,
    mobile_phone
  } = values;

  if (!first_name) {
    errors.first_name = "Required";
  } else if (first_name.length < 2) {
    errors.first_name = "First name should be at least 2 characters long";
  }

  if (!last_name) {
    errors.last_name = "Required";
  } else if (last_name.length < 2) {
    errors.last_name = "Last name should be at least 2 characters long";
  }

  if (!address_line1) {
    errors.address_line1 = "Required";
  } else if (address_line1.length < 2) {
    errors.address_line1 = "Address should be at least 2 characters long";
  }

  if (!city) {
    errors.city = "Required";
  } else if (city.length < 2) {
    errors.city = "City should be at least 2 characters long";
  }

  if (!state) {
    errors.state = "Required";
  } else if (state.length < 2) {
    errors.state = "State should be at least 2 characters long";
  }

  if (!postal_code) {
    errors.postal_code = "Required";
  } else if (postal_code.length < 2) {
    errors.postal_code = "Zip code should be at least 2 characters long";
  }

  if (!mobile_phone) {
    errors.mobile_phone = "Required";
  }

  return errors;
};

class ShippingForm extends Component {
  renderFormField({ input, label, type, meta: { touched, error, warning } }) {
    return (
      <div className="Input">
        <div>
          <input
            className="Input-input"
            name={input.name}
            value={input.value}
            onChange={input.onChange}
            placeholder={label}
            type={type || "text"}
          />
          {touched &&
            ((error && <span className="Input-error">{error}</span>) ||
              (warning && <span className="Input-error">{warning}</span>))}
        </div>
      </div>
    );
  }

  validateBundleInput = value => {
    const hasBundleGiftCard = this.props.cartItems.find(
      item => item.is_bundle && item.bundle_gift_card_amount > 0
    );

    if (hasBundleGiftCard) {
      return value ? undefined : "Required";
    } else {
      return undefined;
    }
  };

  onShippingFormChange = event => {
    const { name, value } = event.target;
    const { shippingFormData } = this.props;

    shippingFormData[name] = value;
    this.props.changeShippingForm(shippingFormData);
  };

  addCarbonToCart = () => {
    const { carbon, addProductToCart } = this.props;
    addProductToCart({ product: carbon, isShipping: true });
  };

  checkedShipmentCheckbox = product => {
    const el = this.props.cartItems.find(item => item.id === product.id);
    return !!el;
  };

  componentDidMount() {
    const {
      first_name,
      last_name,
      address_line1,
      address_line2,
      city,
      state,
      postal_code,
      mobile_phone
    } = this.props.initialValues;

    this.props.changeShippingForm({
      first_name,
      last_name,
      address_line1,
      address_line2,
      city,
      state,
      postal_code,
      mobile_phone
    });
  }

  renderBundleGiftCardForm = () => {
    const { cartItems } = this.props;
    const bundleProduct = cartItems.find(item => item.is_bundle);

    if (bundleProduct && bundleProduct.bundle_gift_card_amount) {
      return (
        <div>
          <div className="SubHeadline">GIFT CARD CUSTOMIZATION</div>
          <div className="BillingForm-row row">
            <div className="small-12 medium-6 column">
              <div className="BillingForm-col">
                <Field
                  component={this.renderFormField}
                  name="gift_card_first_name"
                  label="First name"
                  validate={[this.validateBundleInput]}
                />
              </div>
            </div>
            <div className="small-12 medium-6 column">
              <div className="BillingForm-col">
                <Field
                  component={this.renderFormField}
                  name="gift_card_last_name"
                  label="Last name"
                  validate={[this.validateBundleInput]}
                />
              </div>
            </div>
          </div>
          <div className="BillingForm-row row">
            <div className="BillingForm-col w-100">
              <Field
                component={this.renderFormField}
                name="gift_card_recipient_email"
                label="EMAIL"
                validate={[this.validateBundleInput]}
              />
            </div>
          </div>
          <div className="BillingForm-row row">
            <div className="BillingForm-col w-100">
              <Field
                component={this.renderFormField}
                name="gift_card_message"
                label="WRITE YOUR MESSAGE"
              />
            </div>
          </div>
        </div>
      );
    }
  };

  render() {
    const {
      shipmentProducts,
      isFetching,
      addShipmentToCart,
      carbon,
      cartItems,
      onSubmit,
      handleSubmit,
      pristine,
      reset,
      submitting
    } = this.props;

    const carbonInCart = cartItems.find(item => item.is_carbon === true);
    const shipmentInCart = cartItems.find(item => item.is_shipment === true);

    return (
      <Fragment>
        <form
          className="ShippingForm"
          onChange={this.onShippingFormChange}
          onSubmit={handleSubmit(onSubmit)}
        >
          {this.renderBundleGiftCardForm()}
          <div className="SubHeadline">SHIPPING INFORMATION</div>
          <div className="BillingForm-row row">
            <div className="small-12 medium-6 column">
              <div className="BillingForm-col">
                <Field
                  component={this.renderFormField}
                  name="first_name"
                  label="First name"
                />
              </div>
            </div>
            <div className="small-12 medium-6 column">
              <div className="BillingForm-col">
                <Field
                  component={this.renderFormField}
                  name="last_name"
                  label="Last name"
                />
              </div>
            </div>
          </div>
          <div className="BillingForm-row row">
            <div className="BillingForm-col w-100">
              <Field
                component={this.renderFormField}
                name="address_line1"
                label="Address 1"
              />
            </div>
          </div>
          <div className="BillingForm-row row">
            <div className="BillingForm-col w-100">
              <Field
                component={this.renderFormField}
                name="address_line2"
                label="Address 2"
              />
            </div>
          </div>
          <div className="BillingForm-row row">
            <div className="small-12 medium-6 column">
              <div className="BillingForm-col">
                <Field
                  component={this.renderFormField}
                  name="city"
                  label="City"
                />
              </div>
            </div>
            <div className="small-12 medium-6 column">
              <div className="BillingForm-col">
                <Field
                  component={this.renderFormField}
                  name="state"
                  label="State"
                />
              </div>
            </div>
          </div>
          <div className="BillingForm-row row">
            <div className="small-12 medium-6 column">
              <div className="BillingForm-col">
                <Field
                  component={this.renderFormField}
                  name="postal_code"
                  label="Zip Code"
                />
              </div>
            </div>
            <div className="small-12 medium-6 column">
              <div className="BillingForm-col">
                <Field
                  component={this.renderFormField}
                  name="mobile_phone"
                  label="Phone"
                  type="number"
                />
              </div>
            </div>
          </div>
          <div className="BillingForm-row row">
            <Fragment>
              {isFetching ? (
                <RenderLoading />
              ) : (
                <div className="Shipping-products row w-100">
                  <div className="Shipping-products-wrapper">
                    {shipmentProducts.map(product => (
                      <label
                        key={product.id}
                        className="Radio-button-label Shipment-service"
                      >
                        <Field
                          checked={this.checkedShipmentCheckbox(product)}
                          className="Radio-button"
                          name="shipping_type"
                          component="input"
                          type="radio"
                          onClick={() => addShipmentToCart(product)}
                          value={`${product.name}`}
                        />{" "}
                        {`${product.name}($${product.price.toFixed(2)})`}
                      </label>
                    ))}
                  </div>
                </div>
              )}
            </Fragment>
          </div>
          {carbon && !carbonInCart && (
            <div className="Carbon-button" onClick={this.addCarbonToCart}>
              <img src={Earth} alt="earth" />
              <span>GO CARBON NEUTRAL (+$5)</span>
              <img src={Carbon} alt="carbon" />
              <div className="Carbon-button overlay">
                GO CARBON NEUTRAL (+$5)
              </div>
            </div>
          )}
          <button
            type="submit"
            className="Button"
            disabled={submitting || !shipmentInCart}
          >
            Continue
          </button>
        </form>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    shippingFormData: state.shipping.formData,
    shipmentProducts: state.shipmentProducts.shipmentProducts,
    isFetching: state.shipmentProducts.isFetching,
    carbon: state.shipmentProducts.carbon,
    cartItems: state.cart.items,
    initialValues: state.user
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changeShippingForm,
      addProductToCart,
      addShipmentToCart
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "shippingForm", validate })(ShippingForm));
