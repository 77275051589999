import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types'

import { updateProductQuantity } from "../actions/cart";
import onClickOutside from "react-onclickoutside";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Collapse } from 'react-collapse';

class BagItemQuantitySelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listIsOpen: false,
    };
  }

  options = (i = 1, end = 6) => {
    const options = [];
    while (i < end) {
      options.push(
        { value: i, name: i }
      )
      i++;
    }
    return options;
  }

  toggleList = () => {
    this.setState({ listIsOpen: !this.state.listIsOpen });
  };

  selectQuantity = (option) => {
    const { item } = this.props
    this.props.updateProductQuantity(item.id, option.value)

    this.setState({ listIsOpen: false });
  };

  handleClickOutside = evt => {
    this.setState({ listIsOpen: false });
  };

  render() {
    const { item, cartItems } = this.props
    const { listIsOpen } = this.state

    const currentItem = cartItems.find(p => p.id === item.id)
    const options = this.options()

    if (!currentItem || currentItem.quantity <= 0) return <div />

    return (
      <div className="ItemQuantitySelector">
        <span>QTY:&nbsp;</span>
        { currentItem &&
          <Fragment>
            <div className="ItemQuantitySelector-container" onClick={this.toggleList}>
              <div className="Item-quantity">{currentItem.quantity}</div>
              <div className={`Arrow-down ${listIsOpen ? 'rotate' : ''}`} />
              <Collapse isOpened={listIsOpen}>
                <div className="ItemQuantitySelector-list">
                  { options.map(option => (
                    <div
                      key={`option-${option.value}`}
                      className="ItemQuantitySelector-option"
                      onClick={() => this.selectQuantity(option)}
                    >
                      {option.name}
                    </div>
                  ))}
                </div>
              </Collapse>
            </div>
          </Fragment>
        }
      </div>
    )
  }
}

BagItemQuantitySelector.propTypes = {
  item: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  cartItems: state.cart.items,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateProductQuantity,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps)(
  onClickOutside(BagItemQuantitySelector)
)