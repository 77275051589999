import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import superagent from "superagent";
import { bindActionCreators } from "redux";
import scrollToTop from "../helpers/scrollToTop";
import { isAuthenticated } from "../reducers/user";
import { logout } from "../actions/authentication";
import Payment from "../components/tv_signup/tv_live_payment";
import { fetchUserTvSubscription } from "../actions/user_subscriptions";
import { shouldUpdateSubscription } from "../reducers/user_tv_subscription";

class TvSignup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedPlan: this.props.selectedPlan,
      pageContent: null,
      loadingPageContent: null,
      pageError: null
    };
  }

  componentDidMount() {
    scrollToTop();
    if (this.props.shouldUpdateSubscription) {
      this.props.fetchUserTvSubscription();
    }
    this.getSignupPageContent();
  }

  componentWillReceiveProps(newProps) {
    if (!this.props.isAuthenticated && newProps.isAuthenticated) {
      this.props.fetchUserTvSubscription();
    }
  }

  getSignupPageContent = () => {
    return superagent
      .get("/signup_page")
      .set("Accept", "application/json")
      .send()
      .then(res =>
        this.setState({
          pageContent: res.body,
          loadingPageContent: false
        })
      )
      .catch(error => {
        this.setState({
          pageError: error.response.body,
          loadingPageContent: false
        });
      });
  };

  render() {
    const {
      selectedPlan,
      pageContent
    } = this.state;
    const { subscription, continueBooking } = this.props;
    return (
      <Fragment>
        <div className="signup-page payment-popup" >
          <div className="signup-page-content">
            <Payment
              selectedPlan={selectedPlan}
              pageContent={pageContent}
              subscription={subscription}
              continueBooking={continueBooking}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: isAuthenticated(state),
  user: state.user,
  subscription: state.userTvSubscription,
  shouldUpdateSubscription: shouldUpdateSubscription(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchUserTvSubscription,
      logout
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TvSignup);
