import React, {Component, Fragment} from 'react'
import moment from "moment/moment";

import ToggleCard from './toggle_card.jsx'
import BookLessonButton from '../components/book_lesson_button.jsx';

import time from './../../../assets/images/icons/clock_icon.png';
import Slider from 'react-slick';
import { Collapse } from 'react-collapse';
import { programTeachersNames } from "../helpers/program_teachers_names";
import {history} from "../store/configureStore";

export default class TrainingCard extends Component {
  renderApplyButton = () => {
    const { training, isAuthenticated } = this.props;

    if (training.is_past || (!training.is_available && !training.type_form)) return (
      <button className="Button" disabled >APPLY HERE</button>
    )

    const hasLink = training.type_form || training.mbo_link;

    if (!hasLink) return (<div />)
    const link = (training.mbo_link && !training.type_form) ? training.mbo_link : `courses/${training.slug}`;
    const buttonLink = (isAuthenticated && link) ? link : null

    return (
      <BookLessonButton
        label="APPLY HERE"
        reservedLabelText="Reserved"
        link={buttonLink}
        lessonId={training.mindbody_identifier}
        onClick={isAuthenticated ? null : () => this.redirectToAuth(link)}
        className="Button"
      />
    )
  }

  redirectToAuth = (link) => {
    history.push({ pathname: "/login", state: { cameFrom: link } })
  }

  render() {
    const {
      policy,
      training: { photos, guests, teachers },
      training,
      activeProgram,
      selectProgram,
      deselectProgram,
    } = this.props

    const deposit_policy = training.deposit_policy || (policy && policy.text ? policy.text : null);

    const isActive = activeProgram ? activeProgram.mindbody_identifier === training.mindbody_identifier : false

    return (
      <div className="Program-card" key={training.id} id={training.slug}>
        <div className="Visible-part">
          <div className="Image-block For-mobile">
            {((photos) && (photos.length > 0) && photos[0].image_url) &&
              <div
                className="Program-card-image-wrapper cursor-pointer"
                style={{ backgroundImage: `url(${photos[0].image_url })`}}
                onClick={() => selectProgram(training)}
              />
            }
          </div>
          <div className="Block-wrapper">
            <div className="Left-block">
              <div>
                <h3 className="Program-card-header cursor-pointer" onClick={() => selectProgram(training)}>
                  {training.class_description.name}
                </h3>
                <div className="Info">
                  { training.duration &&
                    <p className="Bold">
                      <img src={time} height="14" width="14" alt="duration" />
                      <span>&nbsp;&nbsp;{training.duration} HR</span>
                    </p>
                  }
                  <p>
                    <span className="Bold">WHEN&nbsp;</span>
                    <span>
                      {moment.utc(training.start_date_time).format('MMM. D')} - {moment.utc(training.end_date_time).format('MMM. D, YYYY')}
                    </span>
                  </p>
                  <p>
                    <span className="Bold">W/&nbsp;</span>
                    { training.teacher_header ? (
                      <span>{training.teacher_header}</span>
                    ) : (
                      <Fragment>
                        { teachers.length > 0 ? (
                          programTeachersNames(teachers).map(t => <span>{t}</span>)
                        ) : (
                          <span>{training.staff.name}</span>
                        )}
                      </Fragment>
                    )}
                  </p>
                </div>
                {this.renderApplyButton()}

                { isActive ? (
                  <div className="Button Button--secondary active-btn For-large" onClick={() => deselectProgram()}>LEARN LESS</div>
                ) : (
                  <div className="Button Button--secondary For-large" onClick={() => selectProgram(training)}>LEARN MORE</div>
                )}
                <div className="Button Button--secondary For-small" onClick={() => selectProgram(training)}>LEARN MORE</div>

              </div>
            </div>
            <div className="Right-block">
              {((photos) && (photos.length > 0) && photos[0].image_url) ? (
                <div
                  className="Program-card-image-wrapper cursor-pointer"
                  style={{ backgroundImage: `url(${photos[0].image_url})`}}
                  onClick={() => selectProgram(training)}
                />
              ) : (
                <div className="Program-card-image-wrapper empty" />
              )}
            </div>
          </div>
        </div>
        <Collapse isOpened={isActive}>
          {isActive &&
            <div className="Expanded-part">
              {(photos && photos.length > 1) &&
              <div className="Program-image-slider Image-slider">
                <Slider
                  dots={false}
                  infinite
                  speed={500}
                  autoplay
                  fade
                  cssEase="ease-in"
                  arrows={photos.length > 2}
                >
                  {photos.slice(1).map((photo, index) => (
                    <div key={index} className="Slider-item-image">
                      <img src={photo.image_url} alt="SKY TING" />
                    </div>
                  ))}
                </Slider>
              </div>
              }
              <div
                className="Description-text LargeBodyText"
                dangerouslySetInnerHTML={{__html: training.description}}
              />
              <div className="Expanded-cards">
                {training.includes &&
                <ToggleCard
                  key={'program-includes-' + training.id}
                  header="PROGRAM INCLUDES"
                >
                  <div
                    className="Includes-container"
                    dangerouslySetInnerHTML={{ __html: training.includes }}
                  />
                </ToggleCard>
                }
                {teachers && teachers.length > 0 &&
                <ToggleCard
                  key={'mentors-' + training.id}
                  header="MENTORS"
                >
                  <Slider
                    dots={false}
                    infinite
                    speed={300}
                    cssEase="ease-in"
                    arrows={teachers.length > 1}
                    className="Program-block-slider Image-slider"
                    slidesToScroll={1}
                  >
                    {teachers.map(mentor => (
                      <div key={mentor.id} className="Card Mentor-card">
                        <div className="Card-image">
                          <div className="Image-wrapper">
                            <img src={mentor.photo_url ? mentor.photo_url : mentor.image_url} alt={mentor.name} />
                          </div>
                        </div>
                        <div className="Mentor-card-info">
                          <a href={`/teachers?teacher=${mentor.slug}`}>
                            <div className="Button Button--secondary">{mentor.name}</div>
                          </a>
                          {mentor.short_info &&
                          <div>{mentor.short_info}</div>
                          }
                          {mentor.instagram ? (
                            <a href={`https://www.instagram.com/${mentor.instagram.replace('@', '')}`} target="_blank">
                              <div>@{mentor.instagram.replace('@', '')}</div>
                            </a>
                          ) : (
                            <Fragment>
                              {mentor.website &&
                              <div>
                                <a href={mentor.website} target="_blank">{mentor.website}</a>
                              </div>
                              }
                            </Fragment>
                          )}
                        </div>
                      </div>
                    ))}
                  </Slider>
                </ToggleCard>
                }
                {guests && guests.length > 0 &&
                <ToggleCard
                  key={`guests-${training.id}`}
                  header="GUEST LECTURERS"
                >
                  <Slider
                    dots={false}
                    infinite
                    speed={300}
                    cssEase="ease-in"
                    arrows={guests.length > 1}
                    className="Program-block-slider Image-slider"
                    slidesToScroll={1}
                  >
                    {guests.map(guest => (
                      <div key={guest.id} className="Card Mentor-card">
                        <div className="Card-image">
                          <div className="Image-wrapper">
                            <img src={guest.photo_url ? guest.photo_url : ''} alt={guest.name} />
                          </div>
                        </div>
                        <div className="Mentor-card-info">
                          {guest.teacher ? (
                            <a href={`/teachers?teacher=${guest.teacher.slug}`}>
                              <div className="Button Button--secondary">{guest.name}</div>
                            </a>
                          ) : (
                            <div className="Guest-name">{guest.name}</div>
                          )}

                          <div>{guest.info}</div>
                          {guest.instagram &&
                          <a href={`https://www.instagram.com/${guest.instagram.replace('@', '')}`} target="_blank">
                            <div>@{guest.instagram.replace('@', '')}</div>
                          </a>
                          }
                        </div>
                      </div>
                    ))}
                  </Slider>
                </ToggleCard>
                }

                {training.schedule &&
                <ToggleCard
                  key={`schedule-${training.id}`}
                  header="SCHEDULE"
                >
                  <div
                    className="Left-align dates-container"
                    dangerouslySetInnerHTML={{__html: training.schedule}}
                  />
                </ToggleCard>
                }
                {training.requirements &&
                <ToggleCard
                  key={`requirements-${training.id}`}
                  header="REQUIREMENTS"
                >
                  <div
                    className="Left-align"
                    dangerouslySetInnerHTML={{__html: training.requirements}}
                  />
                </ToggleCard>
                }
                {training.tuition &&
                <ToggleCard
                  key={`tuition-${training.id}`}
                  header="TUITION"
                >
                  <div
                    className="Left-align tuition-container"
                    dangerouslySetInnerHTML={{ __html: training.tuition }}
                  />
                </ToggleCard>
                }
              </div>
              {/*<div className="Discount-container">*/}
              {/*<div className="Discount-inner-container">*/}
              {/*<div className="SubHeadline">Early-Bird Discount</div>*/}
              {/*<div className="BodyText">$100 off if registered by August 15, 2016</div>*/}
              {/*</div>*/}
              {/*</div>*/}
              {this.renderApplyButton()}

              {deposit_policy &&
              <div className="Policy-text">
                <div className="SubHeadline">DEPOSIT POLICY</div>
                <div className="SmallBodyText">{deposit_policy}</div>
              </div>
              }
            </div>
          }
        </Collapse>
      </div>
    );
  }
}
