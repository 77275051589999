import { connect } from 'react-redux'
import React, { Component, Fragment } from 'react'
import { bindActionCreators } from 'redux'
import superagent from 'superagent'

import MentorshipCard from '../components/mentorship_card'
import { fetchMentorship } from '../actions/mentorship'
import RenderLoading from '../components/render_loading.jsx'
import RenderError from '../components/render_error.jsx'
import MentorshipMobilePopup from '../components/mentorship_mobile_popup.jsx'
import { isAuthenticated } from '../reducers/user';
import { openLoginPopup } from '../actions/login_popup';
import { selectProgram, deselectProgram } from '../actions/active_program'
import scrollToTop from "../helpers/scrollToTop";
import MetaTagsComponent from "../components/meta_tags_component";
import {getParamByName} from "../helpers/get_param_by_name";
import {animateScroll as scroll} from "react-scroll/modules";

var timeout;

class MentorshipPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      policy: null,
      isMobile: false,
    }
  }

  updateDimensions = () => {
    this.setState({ isMobile: window.matchMedia('(max-width: 800px)').matches })
  }

  componentWillMount() {
    this.props.fetchMentorship();

    const id = getParamByName('program', 'string');
    if (id) {
      this.waitForElementToDisplay(id);
    }

    this.updateDimensions();
  }

  waitForElementToDisplay = (id) => {
    const el = document.getElementById(id);
    if (el !== null) {
      const positionY = el.offsetTop;
      scroll.scrollTo(positionY - 87);
      clearTimeout(timeout);

      if (id) {
        const mentorship = this.props.mentorship.find(t => t.additional.slug === id);
        if (mentorship) {
          this.props.selectProgram(mentorship)
        }
      }
    }
    else {
      if (!this.props.isFetching && this.props.mentorship.length > 0) {
        const mentorship = this.props.mentorship.find(t => t.additional.slug === id);
        if (!mentorship) {
          clearTimeout(timeout);
        } else {
          timeout = setTimeout(() => {
            this.waitForElementToDisplay(id);
          }, 500);
        }
      } else {
        timeout = setTimeout(() => {
          this.waitForElementToDisplay(id);
        }, 500);
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
    this.props.deselectProgram();
    clearTimeout(timeout);
  }

  componentDidMount() {
    const id = getParamByName('program', 'string')

    if (!id) {
      scrollToTop();
    }
    window.addEventListener('resize', this.updateDimensions)

    return superagent
      .get('/policy?policy_type=mentorship')
      .set('Accept', 'application/json')
      .send().then(res =>
        this.setState({
          policy: res.body,
        }));
  }

  render() {
    const {
      error,
      mentorship,
      isFetching,
      isAuthenticated,
      openLoginPopup,
      selectProgram,
      deselectProgram,
      activeProgram,
    } = this.props

    const { isMobile, policy } = this.state

    if (error) return <RenderError error={error} />

    return (
      <Fragment>
        <MetaTagsComponent
          title="SKY TING | Programs | Mentorship"
          desc="SKY TING mentorship"
          url="mentorship"
        />
        <div className="Page">
          <div className="Page-content">
            <h2 className="Page-header">MENTORSHIP</h2>
            { isFetching ? (
              <RenderLoading />
            ) : (
              <Fragment>
                { _.isEmpty(mentorship) ? (
                  <div className="Empty-result">NO MENTORSHIPS</div>
                ) : (
                  <Fragment>
                    <div>
                      { mentorship.map(m => (
                        <MentorshipCard
                          mentorship={m}
                          key={m.main.id}
                          policy={policy}
                          isAuthenticated={isAuthenticated}
                          openLogin={() => openLoginPopup()}
                          selectProgram={selectProgram}
                          deselectProgram={deselectProgram}
                          activeProgram={activeProgram}
                        />
                      ))}
                    </div>
                    { activeProgram && isMobile &&
                      <MentorshipMobilePopup
                        isOpen
                        mentorship={activeProgram}
                        policy={policy}
                        onClose={() => deselectProgram()}
                        isAuthenticated={isAuthenticated}
                        openLogin={() => openLoginPopup()}
                      />
                    }
                  </Fragment>
                )}
              </Fragment>
            )}
          </div>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  mentorship: state.mentorship.mentorship,
  isFetching: state.mentorship.isFetching,
  error: state.mentorship.error,
  isAuthenticated: isAuthenticated(state),
  activeProgram: state.activeProgram,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchMentorship,
      openLoginPopup,
      selectProgram,
      deselectProgram,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(MentorshipPage)