import Api from '../api/user_register';
import { syncShoppingCart } from "./cart";
import { closeRegisterPopup } from "./register_popup";
import { openShipping } from "./shipping";
import { saveUseragent } from './useragent';

export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS'
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR'
export const REGISTER_USER_LOADING = 'REGISTER_USER_LOADING'


const userRegisterSuccess = user => (dispatch, getState) => {
  dispatch({ type: REGISTER_USER_SUCCESS, user })
  dispatch(syncShoppingCart());

  const state = getState();

  if (state.cart.waitingForAuth) {
    dispatch(openShipping());
  }

  dispatch(closeRegisterPopup());
  dispatch(saveUseragent());
}

export const userRegister = (user, is_tv_flow = false) => (dispatch) => {
  return new Promise(async(resolve, reject) => {
    dispatch({type: REGISTER_USER_LOADING})

    Api.userRegister(user, is_tv_flow)
      .then(res => {
        dispatch(userRegisterSuccess(res.body));
        resolve(true);
      })
      .catch(err => {
        dispatch({type: REGISTER_USER_ERROR, error: err.response.body});
        reject(err.response.body);
      });
  });
};