import {
  OPEN_CHECKOUT_SUCCESS_POPUP,
  CLOSE_CHECKOUT_SUCCESS_POPUP
} from '../actions/checkout_success_popup';

const INITIAL_STATE = {
  show: false,
  error: null
};

const checkoutSuccessPopup = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OPEN_CHECKOUT_SUCCESS_POPUP:
      return {
        ...state,
        show: true
      };
    case CLOSE_CHECKOUT_SUCCESS_POPUP:
      return {
        ...state,
        show: false
      };
    default:
      return state;
  }
};

export default checkoutSuccessPopup
