import React, { Component, Fragment } from "react";
import moment from "moment";

class PastClassesCard extends Component {
  render() {
    const { pastClass, border } = this.props;

    return (
      <Fragment>
        <div className="purchase-card row pt-3 pt-md-0">
          <div className="col-2 col-md-1 d-flex align-items-center mb-1 mb-md-0">
            <img src={pastClass.teacher_image} className="purchase-teacher-img-wrapper" width={50} height={50} alt="SKY TING" />
          </div>
          <div className="col-10 col-md-2 d-block d-md-flex justify-content-center mb-1 mb-md-0 flex-column">
            <b>{pastClass.teacher_name}</b>
            <div className="pt-2 mb-1 bordered d-md-none" />
          </div>
          <div className="col-2 d-flex d-md-none" />
          <div className="col-10 col-md-2 d-flex align-items-center mb-2 mb-md-0">
            <div>
              {pastClass.date}
            </div>
          </div>
          <div className="col-2 d-flex d-md-none" />
          <div className="col-10 col-md-2 d-flex align-items-center mb-2 mb-md-0">
            <div>
              <span></span>
            </div>
          </div>
          <div className="col-10 col-md-2 d-flex flex-column justify-content-center align-items-start align-items-md-center mb-3 mb-md-0">
            <div>
              <span></span>
            </div>
          </div>
          <div className="col-2 d-flex d-md-none" />
          <div className="col-2 d-flex d-md-none" />
          <div className="col-10 col-md-2 d-flex justify-content-start justify-content-md-center align-items-center mb-2 mb-md-0">
            <div>
              {pastClass.time}
            </div>
          </div>
          <div className="cancel-lesson-btn col-12 col-md-1 d-flex align-items-center justify-content-center">
            {pastClass.late_cancelled ? "Late Cancelled" : pastClass.signed_in ? "Signed In" : "Absent"}
          </div>
        </div>
        <div className="bordered-bottom d-none d-md-block" />
      </Fragment>
    );
  }
}

export default PastClassesCard;
