import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import registerReasons from '../data/register_reasons'
import RegisterPaymentForm from '../components/register_payment_form';

class RegisterFormStep2 extends Component {
  onClick = (paymentValues) => {
    const {
      card_number, exp_month, exp_year, cvv, card_holder
    } = paymentValues;

    const {
      registerUser,
      basicInfo
    } = this.props

    const { first_name, last_name, email, mobile_phone, password,
      photo_url, address_line1, address_line2, city, state, postal_code, country,
      referred_by, referred_by_others, join_newsletter, birth_date } = basicInfo;

    const reason = registerReasons.find(el => el.id === referred_by)
    const birthStr = moment(basicInfo.birth_date).format("DD/MM/YYYY")

    registerUser(
      {
        user: {
          first_name, last_name, email: email.toLowerCase(), mobile_phone, birth_date: birthStr,
          password, photo_url, address_line1, address_line2, city, state, postal_code, country,
          referred_by: reason && reason.id !== 10 ? reason.value : referred_by_others, join_newsletter,
          card_number, exp_month, exp_year, cvv, card_holder
        }
      }
    )
  }

  submitWithoutCard = () => {
    const {
      registerUser,
      basicInfo
    } = this.props;

    const { first_name, last_name, email, mobile_phone, password,
      photo_url, address_line1, address_line2, city, state, postal_code, country,
      referred_by, referred_by_others, join_newsletter, birth_date } = basicInfo;

    const reason = registerReasons.find(el => el.id === referred_by)
    const birthStr = moment(basicInfo.birth_date).format("DD/MM/YYYY")

    registerUser(
      {
        user: {
          first_name, last_name, email: email.toLowerCase(), mobile_phone, birth_date: birthStr,
          password, photo_url, address_line1, address_line2, city, state, postal_code, country,
          referred_by: reason && reason.id !== 10 ? reason.value : referred_by_others, join_newsletter
        }
      }
    )
  }

  render() {
    const { onClick, submitWithoutCard } = this;
    const { loading, error, title, goBack } = this.props;

    return (
      <RegisterPaymentForm
        paymentInfo={this.props.paymentInfo}
        onFormSubmit={values => onClick(values)}
        loading={loading}
        title={title}
        goBack={values => goBack(values)}
        submitWithoutCard={() => submitWithoutCard()}
        backendErrors={error}
      />
    )
  }
}

RegisterFormStep2.propTypes = {
  title: PropTypes.string.isRequired,
}

const mapStateToProps = state => ({
  error: state.user_register && state.user_register.error,
  loading: state.user_register.loading,
  showRegisterForm: state.register_popup.show
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {},
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(RegisterFormStep2);