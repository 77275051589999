import {
  LOGOUT_SUCCESS,
  AUTHENTICATE_SUCCESS
} from "../actions/authentication";

import {
  FETCH_CURRENT_USER,
  FETCH_CURRENT_USER_SUCCESS,
  FETCH_CURRENT_USER_ERROR
} from "../actions/user";
import { REGISTER_USER_SUCCESS } from "../actions/user_register";

import { UPDATE_USER_AVATAR } from "../actions/user_update";

const user = (state = null, action) => {
  switch (action.type) {
    case FETCH_CURRENT_USER:
      return {
        ...state,
        isFetching: true
      };
    case FETCH_CURRENT_USER_SUCCESS:
      return action.user;
    case FETCH_CURRENT_USER_ERROR:
      return {
        ...state,
        error: action.error,
        isFetching: false
      };
    case AUTHENTICATE_SUCCESS:
    case REGISTER_USER_SUCCESS:
      return action.user;
    case UPDATE_USER_AVATAR:
      return {
        ...state,
        photo_url: action.photo_url
      };
    case LOGOUT_SUCCESS:
      return null;
    default:
      return state;
  }
};

export const isAuthenticated = state => !!(state.user && state.user.id);

export const userId = state => (isAuthenticated(state) ? state.user.id : null);

export const hasRequiredMboInfo = state => {
  const { user } = state;

  if (user && user.id) {
    const {
      mindbody_identifier,
      first_name,
      last_name,
      address_line1,
      birth_date,
      city,
      country,
      mobile_phone,
      postal_code,
      referred_by,
      state
    } = user;

    const params = {
      mindbody_identifier,
      first_name,
      last_name,
      address_line1,
      birth_date,
      city,
      country,
      mobile_phone,
      postal_code,
      referred_by,
      state
    };

    // check if every parameter has value
    return Object.values(params).every(value => !!value);
  } else {
    return false;
  }
};

export default user;
