import { connect } from 'react-redux';
import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import superagent from 'superagent';
import { fetchRetreats } from '../actions/retreats';
import { openLoginPopup } from '../actions/login_popup';

import RetreatCard from '../components/retreat-card';
import RenderLoading from '../components/render_loading.jsx';
import RenderError from '../components/render_error.jsx';
import RetreatMobilePopup from '../components/retreat_mobile_popup';
import { isAuthenticated } from '../reducers/user';
import { selectProgram, deselectProgram } from '../actions/active_program'
import scrollToTop from "../helpers/scrollToTop";
import MetaTagsComponent from "../components/meta_tags_component";
import {getParamByName} from "../helpers/get_param_by_name";
import {animateScroll as scroll} from "react-scroll/modules";

var timeout;

class RetreatsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      policy: null,
      isMobile: false,
    };
  }

  updateDimensions = () => {
    this.setState({ isMobile: window.matchMedia('(max-width: 800px)').matches })
  }

  componentWillMount() {
    this.props.fetchRetreats()

    const id = getParamByName('program', 'string')
    if (id) {
      this.waitForElementToDisplay(id);
    }
    this.updateDimensions();
  }

  waitForElementToDisplay = (id) => {
    const el = document.getElementById(id);
    if (el !== null) {
      const positionY = el.offsetTop;
      scroll.scrollTo(positionY - 87);
      clearTimeout(timeout);

      if (id) {
        const retreat = this.props.retreats.find(t => t.slug === id);
        if (retreat) {
          this.props.selectProgram(retreat);
        }
      }
    }
    else {
      if (!this.props.isFetching && this.props.retreats.length > 0) {
        const retreat = this.props.retreats.find(t => t.slug === id);
        if (!retreat) {
          clearTimeout(timeout);
        } else {
          timeout = setTimeout(() => {
            this.waitForElementToDisplay(id);
          }, 500);
        }
      } else {
        timeout = setTimeout(() => {
          this.waitForElementToDisplay(id);
        }, 500);
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
    this.props.deselectProgram();
    clearTimeout(timeout);
  }

  componentDidMount() {
    const id = getParamByName('program', 'string')

    if (!id) {
      scrollToTop();
    }
    window.addEventListener('resize', this.updateDimensions)

    return superagent
      .get('/policy?policy_type=retreat')
      .set('Accept', 'application/json')
      .send().then(res =>
        this.setState({
          policy: res.body,
        }));
  }

  render() {
    const {
      retreats,
      error,
      isFetching,
      openLoginPopup,
      isAuthenticated,
      selectProgram,
      deselectProgram,
      activeProgram,
    } = this.props

    const { isMobile, policy } = this.state;

    if (error) return <RenderError error={error} />;

    return (
      <Fragment>
        <MetaTagsComponent
          title={activeProgram ? activeProgram.name : "SKY TING | Programs | Retreats"}
          desc={activeProgram ? activeProgram.description : "SKY TING retreats"}
          url={activeProgram ? `retreats?program=${activeProgram.slug}` : "retreats"}
          image={activeProgram && activeProgram.photos.length > 0 ? activeProgram.photos[0].image_url : null}
        />
        <div className="Page Retreats">
          <div className="Page-content">
            <h2 className="Page-header">RETREATS</h2>
            { isFetching ? (
              <RenderLoading />
            ) : (
              <Fragment>
                { _.isEmpty(retreats) ? (
                  <div className="Empty-result">COMING SOON</div>
                ) : (
                  <Fragment>
                    <div>
                      { retreats.map(retreat => (
                        <RetreatCard
                          retreat={retreat}
                          key={retreat.id}
                          policy={policy}
                          isAuthenticated={isAuthenticated}
                          openLogin={() => openLoginPopup()}
                          selectProgram={selectProgram}
                          deselectProgram={deselectProgram}
                          activeProgram={activeProgram}
                        />
                      ))};
                    </div>
                    { activeProgram && isMobile &&
                      <RetreatMobilePopup
                        isOpen
                        retreat={activeProgram}
                        policy={policy}
                        onClose={() => deselectProgram()}
                        isAuthenticated={isAuthenticated}
                        openLogin={() => openLoginPopup()}
                      />
                    }
                  </Fragment>
                )}
              </Fragment>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  error: state.retreats.error,
  retreats: state.retreats.retreats,
  isFetching: state.retreats.isFetching,
  isAuthenticated: isAuthenticated(state),
  activeProgram: state.activeProgram,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchRetreats,
      openLoginPopup,
      selectProgram,
      deselectProgram,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(RetreatsPage);