import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import arrowWhite from "../../../assets/images/icons/arrow_right_white.svg";
import ButtonWithIcon from "./button_with_icon";
import YinyangIcon from "../../../assets/images/icons/yinyang_icon.png";
import GlobeIcon from "../../../assets/images/icons/filter_location.png";
import SunIcon from "../../../assets/images/icons/workshop_icon.png";
import HeartIcon from "../../../assets/images/icons/heart_icon.png";
import KissPeaceIcon from "../../../assets/images/icons/kiss-peace-symbol.png";
import SmileIcon from "../../../assets/images/icons/smile.png";
import OkIcon from "../../../assets/images/icons/ok_icon.png";
import { history } from "../store/configureStore";

export default class TvSubscriptionSlider extends Component {
  constructor(props) {
    super(props);
    const monthPlans = props.tvPlans.filter(p => p.interval === "month");
    const selectedPlan =
      props.subscription &&
      props.tvPlans.find(p => p.id === props.subscription.item.plan_id);

    this.state = {
      mode: (props.subscription && props.subscription.item.interval) || "month",
      yearPlans: props.tvPlans.filter(p => p.interval === "year"),
      monthPlans,
      selectedPlan: selectedPlan || monthPlans[0],
      value: selectedPlan ? selectedPlan.amount : monthPlans[0].amount,
      warning: null
    };
  }

  handleChangeStart = () => {
    console.log("Change event started");
  };

  handleChange = value => {
    this.setState({ value });
  };

  handleChangeComplete = () => {
    this.setState({
      selectedPlan: this.props.tvPlans.find(p => p.amount === this.state.value)
    });
  };

  labels = values => {
    const hash = {};
    values.forEach(v => (hash[v.amount] = "|"));
    return hash;
  };

  switchMode = val => {
    const { mode, monthPlans, yearPlans } = this.state;

    if (val === mode) return;
    const selectedPlan = val === "month" ? monthPlans[0] : yearPlans[0];

    this.setState({ mode: val, selectedPlan, value: selectedPlan.amount });
  };

  switchButton = (mode, index) => {
    return (
      <div
        className={`switch-plan ${this.state.mode === mode ? "active" : ""}`}
        onClick={() => this.switchMode(mode)}
        key={index}
      >
        {mode === "month" ? "Monthly" : "Yearly"}
      </div>
    );
  };

  redirectToPayment = () => {
    const { selectedPlan } = this.state;
    const { isAuthenticated, subscription } = this.props;

    if (
      subscription &&
      subscription.status !== "pending" &&
      subscription.item.plan_id === selectedPlan.id
    ) {
      this.setState({
        warning:
          "You are already subscribed to this plan, please choose another plan"
      });
    } else {
      const redirectTo = isAuthenticated
        ? "/tv-signup/payment"
        : "/tv-signup/get-started";
      history.push({
        pathname: redirectTo,
        state: { selectedPlan }
      });
    }
  };

  render() {
    const {
      value,
      mode,
      monthPlans,
      yearPlans,
      selectedPlan,
      warning
    } = this.state;
    const { subscription, isAuthenticated } = this.props;

    const plans = mode === "month" ? monthPlans : yearPlans;
    const buttonText = subscription ? "upgrade plan" : "continue";

    return (
      <div className="tv-subscription-slider">
        <div className="text slider-above-text">
          SKY TING’s mission is to make wellbeing accessible to everybody, everywhere, so our subscriptions are priced on a sliding scale and pay it forward model.
        </div>
        <div className="selected-plan bullet-title">
          WHAT'S INCLUDED:
        </div>
        <div className="text slider-above-text">
          <ul className="slider-list">
            <li className="row">
              <img src={OkIcon} className="col" />
              <p className="col">Access to hundreds of prerecorded classes in our library, designed for any mood, level or duration!</p>
            </li>
            <li className="row">
              <img src={SmileIcon} className="col" />
              <p className="col">Weekly zoom classes with your favorite SKY TING teachers</p>
            </li>
            <li className="row">
              <img src={KissPeaceIcon} className="col" />
              <p className="col">$5 access to special Live Stream classes from our NY Studio</p>
            </li>
          </ul>
        </div>
        <div className="selected-plan">
          ${selectedPlan.amount}.00 / {selectedPlan.interval}
          {mode === "year" && (
            <span>Save 20% when you purchase a yearly subscription</span>
          )}
        </div>
        <div className="d-flex justify-content-center">
          {["month", "year"].map((i, index) => this.switchButton(i, index))}
        </div>
        <div className="slider">
          <div className="slider-values">
            <div className="start-price">${plans[0].amount}.00</div>
            <div className="end-price">${plans.slice(-1)[0].amount}.00</div>
          </div>
          <Slider
            min={plans[0].amount}
            max={plans.slice(-1)[0].amount}
            step={mode === "month" ? 1 : 10}
            value={value}
            labels={this.labels(plans)}
            onChangeStart={this.handleChangeStart}
            onChange={this.handleChange}
            onChangeComplete={this.handleChangeComplete}
          />
        </div>
        <div className="text">
          <ul className="slider-list">
            <li className="row">
              <img src={YinyangIcon} className="col" />
              <p className="col">All SKY TING TV subscriptions start with a free 7 day trial (if you cancel on or before the 7th day you won’t be charged)</p>
            </li>
            <li className="row">
              <img src={GlobeIcon} className="col" />
              <p className="col">Choose your own rate from our sliding scale model. Base rates start at $20/month or $200/year</p>
            </li>
            <li className="row">
              <img src={SunIcon} className="col" />
              <p className="col">For every dollar over our base rate, we will pay it forward to someone in need of wellness through our scholarship program</p>
            </li>
          </ul>
        </div>
        <ButtonWithIcon
          classNamesProp="ml-auto mr-auto"
          iconSrc={arrowWhite}
          text={buttonText}
          onClick={() => this.redirectToPayment()}
        />

        {!isAuthenticated &&
          <div className="text-center mt-4">
            <Link to="/tv-signup/login" className="login-link">
              ALREADY HAVE AN ACCOUNT?
            </Link>
          </div>
        }

        {warning && <div className="warning">{warning}</div>}
        <div className="bottom-message">
          We have the tools and the platform for well being. If the current cost of our platform is outside of what you can afford, please join our SKY TING TV scholarship by emailing&nbsp;
          <a
            href="mailto:scholarships@skyting.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            scholarships@skyting.com
          </a>{" "}
        </div>
      </div >
    );
  }
}
