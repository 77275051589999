import Api from "../api/checkout_total";

export const CHECKOUT_TOTAL = "CHECKOUT_TOTAL";
export const CHECKOUT_TOTAL_SUCCESS = "CHECKOUT_TOTAL_SUCCESS";
export const CHECKOUT_TOTAL_ERROR = "CHECKOUT_TOTAL_ERROR";
export const DROP_CHECKOUT_TOTAL_CART = "DROP_CHECKOUT_TOTAL_CART";

export const dropCheckoutTotalCart = () => dispatch => {
  dispatch({ type: DROP_CHECKOUT_TOTAL_CART });
};

export const checkoutTotal = (
  products,
  type,
  promoCode = null,
  gift_card_amount = null,
  no_discount = false
) => dispatch => {
  return new Promise(async (resolve, reject) => {
    dispatch({ type: CHECKOUT_TOTAL, promoCode });

    await Api.checkoutTotal(
      products,
      promoCode,
      type,
      false,
      gift_card_amount,
      no_discount,
    )
      .then(res => {
        dispatch({ type: CHECKOUT_TOTAL_SUCCESS, response: res.body });
        resolve(res.body);
      })

      .catch(err => {
        dispatch({ type: CHECKOUT_TOTAL_ERROR, error: err.response.body });
        reject(err.response.body);
      });
  });
};
