import superagent from 'superagent';

const API_ENDPOINT = '/lesson_bookings';

const bookLesson = (lessonId, joinWaitlist = false) => {
  const payload = {
    lesson: { id: lessonId },
    join_waitlist: joinWaitlist,
  };

  return superagent
    .post(API_ENDPOINT)
    .set('Accept', 'application/json')
    .send(payload);
}

const cancelLesson = (lessonId, late_cancel) => {
  const payload = {
    lesson: { id: lessonId, late_cancel : late_cancel}
  };

  return superagent
    .delete(`${API_ENDPOINT}/${lessonId}`)
    .set('Accept', 'application/json')
    .send(payload);
}

export default { bookLesson, cancelLesson }
