import React, { Component } from "react";
import scrollToTop from "../helpers/scrollToTop";
import superagent from "superagent";
import Loading from "../../../assets/images/icons/yinyang_icon.png";
import MetaTagsComponent from "../components/meta_tags_component";
import VideoPageTop from "../components/video_page/video_page_top";
import VideoPageContent from "../components/video_page/video_page_content";

class NewVideoPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      video: null,
      isLoading: true,
      error: null,
      userSubscribed: false,
      videoSaved: false,
      paidVideo: null,
      availableForUser: null,
      speed: 1,
      saveVideoError: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.id !== nextProps.match.params.id) {
      this.getVideo(nextProps.match.params.id);
    }
  }

  componentDidMount() {
    scrollToTop();
    this.makeApiCalls();
  }

  makeApiCalls = async () => {
    // await this.setInternetSpeed();
    this.getVideo(this.props.match.params.id);
  };

  getVideo = id => {
    this.setState({ isLoading: true });
    return superagent
      .get(`/tv/${id}`)
      .set("Accept", "application/json")
      .send()
      .then(res => {
        this.setState({
          isLoading: false,
          userSubscribed: res.body.subscribed,
          video: res.body.video,
          videoSaved: res.body.video_saved,
          paidVideo: res.body.paid_video,
          availableForUser: res.body.available_for_user,
        });
      })
      .catch(error => {
        this.setState({ error: error.response.body, isLoading: false });
        return false;
      });
  };

  // setInternetSpeed = async () => {
  //   const speed = await checkSpeed();
  //   if (speed) {
  //     this.setState({ speed });
  //   }
  // };

  saveVideo = () => {
    const { video } = this.state;

    return superagent
      .post("/saved_videos")
      .set("Accept", "application/json")
      .send({ video_id: video.id })
      .then(res => {
        this.setState({
          videoSaved: res.body.video_saved,
        });
      })
      .catch(error => {
        this.setState({ saveVideoError: error.response.body });
      });
  };

  propsString = () => {
    const { video } = this.state;

    let text = video.props;
    if (!text) return null;

    text = text.split(" ");
    const len = text.length;
    const result = [];

    for (let i = 0; i < len; i++) {
      if (text[i].substr(-1) === ",") {
        result[i] = `<span>${text[i].slice(0, -1)}</span>,`;
      } else {
        result[i] = `<span>${text[i]}</span>`;
      }
    }

    return result.join(' ');
  }

  render() {
    const {
      video,
      isLoading,
      userSubscribed,
      error,
      speed,
      videoSaved,
      paidVideo,
      availableForUser,
    } = this.state;

    if (isLoading)
      return (
        <div className="Page video-page">
          <MetaTagsComponent
            title="SKY TING | TV"
            desc="SKY TING TV"
            url="tv"
          />
          <div className="inline-loading absolute">
            <img src={Loading} height="21" width="21" />
            <div>LOADING...</div>
          </div>
        </div>
      );

    if (error)
      return (
        <div className="Page video-page">
          <MetaTagsComponent
            title="SKY TING | TV"
            desc="SKY TING TV"
            url="tv"
          />
          <div className="Empty-result">
            {error.message ? error.message : "Something went wrong"}
          </div>
        </div>
      );

    const propsString = this.propsString();

    return (
      <div className="Page video-page">
        <MetaTagsComponent
          title={`SKY TING TV | ${video.title}`}
          desc="SKY TING TV"
          url={`tv/${video.slug}`}
          image={video.thumbnail_url}
        />
        <VideoPageTop
          video={video}
          showVideo={paidVideo ? availableForUser : userSubscribed}
          speed={speed}
          videoSaved={videoSaved}
          saveVideo={() => this.saveVideo()}
          paidVideo={paidVideo}
          propsString={propsString}
        />
        <VideoPageContent
          video={video}
          userSubscribed={userSubscribed}
          videoSaved={videoSaved}
          saveVideo={() => this.saveVideo()}
          paidVideo={paidVideo}
          availableForUser={availableForUser}
          isLiveStream={false}
          propsString={propsString}
        />
      </div>
    );
  }
}

export default NewVideoPage;
