import { openLoginPopup } from "./login_popup";
import { removeProductsFromCart } from "./cart";
import { openCheckoutSuccessPopup } from "./checkout_success_popup";
import { closeOrderPopup } from "./order";
import { fetchProducts } from "./product";
import { getBalance } from "./balances";

import Api from "../api/checkout_shop";

import { isAuthenticated } from "../reducers/user";

export const CHECKOUT_SHOP = "CHECKOUT_SHOP";
export const CHECKOUT_SHOP_ERROR = "CHECKOUT_SHOP_ERROR";
export const CHECKOUT_SHOP_SUCCESS = "CHECKOUT_SHOP_SUCCESS";
export const CHECKOUT_SHOP_UNAUTHORIZED = "CHECKOUT_SHOP_UNAUTHORIZED";
export const OPEN_SHOP_PAYMENT_POPUP = "OPEN_SHOP_PAYMENT_POPUP";
export const CHECKOUT_SHOP_INITIAL = "CHECKOUT_SHOP_INITIAL";

const checkoutShopSuccess = dispatch => {
  dispatch({ type: CHECKOUT_SHOP_SUCCESS });

  openCheckoutSuccessPopup()(dispatch);
  closeOrderPopup()(dispatch);
  removeProductsFromCart()(dispatch);
  if (window.location.pathname === "/shop") {
    fetchProducts()(dispatch);
  }
};

const checkoutShopUnauthorized = () => dispatch => {
  dispatch({ type: CHECKOUT_SHOP_UNAUTHORIZED });
  openLoginPopup()(dispatch);
};

export const checkoutShop = (amount, promoCode = null, giftCardAmt = null, recaptchaValue) => (
  dispatch,
  getState
) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: CHECKOUT_SHOP });

    const state = getState();
    const {
      cart: { items },
      shipping: { formData },
      checkoutTotal: { cart },
    } = state;

    if (!isAuthenticated(state)) return dispatch(checkoutShopUnauthorized());

    const giftCardAmountPositive = giftCardAmt && giftCardAmt > 0;
    const shipmentItemInCart = items.find((item) => item.is_shipment === true);
    const products = giftCardAmountPositive ? cart.cart_items.cart_item : items;
    const giftCardAmount = giftCardAmt;

    if (shipmentItemInCart) {
      formData["shipping_type"] = shipmentItemInCart.name;
    }

    Api.checkoutShop(
      products,
      amount,
      formData,
      promoCode,
      giftCardAmount,
      recaptchaValue
    )
      .then(() => {
        if (giftCardAmountPositive) {
          dispatch(getBalance());
        }
        dispatch(checkoutShopSuccess);
        resolve(true)
      })
      .catch((error) => {
        dispatch({ type: CHECKOUT_SHOP_ERROR, error: error.response.body });
        reject(error.response.body);
      });
  })
};
