import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import superagent from "superagent";
import { Collapse } from 'react-collapse';
import { addProductToCart } from '../actions/cart';

import { isAuthenticated } from "../reducers/user";
import { productPriceWithDiscount } from "../helpers/product_price_with_discount";
import scrollToTop from "../helpers/scrollToTop";
import MetaTagsComponent from "../components/meta_tags_component";
import ProductCarouselNew from "../components/product_carousel_new";
import ShopPromoSpots from "../components/shop_promo_spots";
import hand from "../../../assets/images/icons/hand.svg";
import sizeChartIcon from "../../../assets/images/icons/size_chart.svg";

import { Link } from "react-router-dom";
import Slider from "react-slick";

import { history } from "../store/configureStore";
import Product from "../components/product_new";
import ProductSizeSelector from "../components/product_size_selector";
import ProductCategories from "../components/product_categories";

class ProductPage extends Component {
  constructor(props) {
    super(props);
    const product = this.findProduct(props.match.params.id);

    // on initial load find product from products props if they are present
    this.state = {
      isLoading: false,
      error: null,
      product: product,
      collectionProducts: product ? this.getCollectionProducts(product) : [],
      selectedSize: product && product.sizes.length === 1 ? product.sizes[0] : null,
      showSizes: false,
      showDescription: false,
      relatedTingsSubtitle: null,
      promoSliderIsLoading: true
    };
  }

  // if product wasn't set on initial load, get product from server
  componentDidMount() {
    scrollToTop();
    if (!this.state.product) {
      this.getProduct(this.props.match.params.id);
    }
    this.fetchShopPageContent();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.id !== nextProps.match.params.id) {
      scrollToTop()
      this.getProduct(nextProps.match.params.id);
      this.setState({ showSizes: false });
    }
  }

  findProduct = id => {
    const { products } = this.props;

    if (products.length === 0) return null;

    const product =
      products.find(p => p.slug === id) ||
      products.find(p => p.id.toString() === id);
    if (!product) return null;

    return product;
  };

  getCollectionProducts = product => {
    if (!product || !product.product_collection_id) return [];

    const { products } = this.props;
    const collection_id = product.product_collection_id;

    return products.filter(p =>
      p.product_collection_id
        ? p.product_collection_id.toString() === collection_id.toString()
        : false
    );
  };

  fetchShopPageContent = () => {
    return superagent
      .get("/shop_pages")
      .set('Accept', 'application/json')
      .send()
      .then(res =>
        this.setState({
          relatedTingsSubtitle: res.body.related_products_subtitle,
        }))
      .catch(error => {
        console.log('Shop page content load error')
      });
  }

  getProduct = id => {
    this.setState({ isLoading: true, error: null });

    return superagent
      .get(`/shop/${id}`)
      .set("Accept", "application/json")
      .send()
      .then(res => {
        const product =
          res.body.find(p => p.slug === id) ||
          res.body.find(p => p.id.toString() === id);

        this.setState({
          isLoading: false,
          product,
          collectionProducts: res.body,
          selectedSize: product.sizes.length === 1 ? product.sizes[0] : null,
        });
      })
      .catch(error => {
        this.setState({ error: error.response.body, isLoading: false });
      });
  };

  onSizeSelect = event => {
    const { product } = this.state;

    this.setState({
      selectedSize: product.sizes.find(s => s.name === event.target.value)
    });
  };

  switchProductColor = nextProduct => {
    this.setState({ product: nextProduct });

    const path = `/shop/${nextProduct.slug}`;
    history.push(path);
  };

  addProductToCart = product => {
    const { addProductToCart } = this.props;
    const { selectedSize } = this.state;

    const color = selectedSize.colors[0];
    const colorName = color ? color.name : "None";

    addProductToCart({
      product,
      quantity: 1,
      size: selectedSize.name,
      color: colorName
    });
  };

  bundleProductContent = () => {
    const {
      product,
      showSizes,
      collectionProducts,
      showDescription,
      relatedTingsSubtitle,
      selectedSize
    } = this.state;

    const { userMembership } = this.props;

    return (
      <Fragment>
        <div className="categories-row hide-for-mobile">
          <ProductCategories product={product} />
        </div>
        <h2 className="product-title hide-for-mobile">
          {product.name}
        </h2>
        <div className="product-price hide-for-mobile">
          ${productPriceWithDiscount(product, userMembership)}
        </div>
        <div className="line-bottom hide-for-mobile" />
        { product.bundle_items.map(item => (
          <div className="bundle-product">
            <div className="top-block d-flex justify-content-between">
              <div className="bundle-product-title">{item.title}</div>
              <div>
                { item.size_required &&
                  <ProductSizeSelector
                    product={product}
                    onSizeSelect={event => this.onSizeSelect(event)}
                    selectedSize={selectedSize}
                    addProductToCart={product => this.addProductToCart(product)}
                    hideButton
                  />
                }
              </div>
            </div>
            <p className="product-desc" dangerouslySetInnerHTML={{ __html: item.description }} />
          </div>
        ))}
        <button
          className="Button add-to-cart ga-add-to-cart"
          onClick={() => this.addProductToCart(product)}
          disabled={!selectedSize}
        >
          <span>{product.preorder ? 'PREORDER' : 'ADD TO CART'}</span>
          <img src={hand} width={21} height={19} alt="ADD TO CART" />
        </button>
      </Fragment>
    )
  }

  render() {
    const {
      product,
      showSizes,
      collectionProducts,
      showDescription,
      relatedTingsSubtitle,
      selectedSize
    } = this.state;

    const { userMembership } = this.props;

    return (
      <div className="Page product-page">
        <ShopPromoSpots
          onLoad={() => this.setState({ promoSliderIsLoading: false })}
          show
          pages={['both', 'product']}
        />
        {product && (
          <Fragment>
            <MetaTagsComponent
              title={`SKY TING | Shop | ${product.name}`}
              desc={product.seo_description || product.name}
              url={`shop/${product.slug}`}
              image={product.thumbnail_url}
            />
            <div className="product-info show-for-mobile mt-4">
              <div className="categories-row">
                <ProductCategories product={product} />
              </div>
              <h2 className="Page-header">{product.name}</h2>
              { !product.is_donation &&
                <div className="product-price">${productPriceWithDiscount(product, userMembership)}</div>
              }
            </div>
            <div className="product-page-top max-width-1800 m-auto padding-lr">
              <div className="product-carousel-container product-page-column">
                {product.product_group_images.length > 0 && (
                  <ProductCarouselNew
                    images={product.product_group_images}
                    productTitle={product.name}
                    productPrice={productPriceWithDiscount(product, userMembership)}
                    product={product}
                    selectedSize={selectedSize}
                    onSizeSelect={event => this.onSizeSelect(event)}
                    addProductToCart={product => this.addProductToCart(product)}
                    disableFullScreen={product.is_donation}
                  />
                )}
              </div>
              <div className="product-info-container product-page-column">
                <div className={`product-info ${product.product_group_images.length < 2 ? 'mt-small-for-mobile' : ''}`}>
                  { product.is_bundle ? (
                    <Fragment>{this.bundleProductContent()}</Fragment>
                  ) : (
                    <Fragment>
                      <div className="categories-row hide-for-mobile">
                        <ProductCategories product={product} />
                      </div>
                      <h2 className="product-title hide-for-mobile">
                        {product.name}
                      </h2>
                      {!product.is_donation &&
                      <div className="product-price hide-for-mobile">
                        ${productPriceWithDiscount(product, userMembership)}
                      </div>
                      }
                      <div className="line-bottom hide-for-mobile" />
                      <ProductSizeSelector
                        product={product}
                        onSizeSelect={event => this.onSizeSelect(event)}
                        selectedSize={selectedSize}
                        addProductToCart={product => this.addProductToCart(product)}
                      />
                      <div className="hide-for-mobile">
                        {product.long_desc && (
                          <Fragment>
                            <div
                              className="product-desc rich-text mt-4"
                              dangerouslySetInnerHTML={{
                                __html: product.long_desc
                              }}
                            />
                            <Collapse isOpened={showDescription}>
                              <div
                                className="product-desc rich-text"
                                dangerouslySetInnerHTML={{
                                  __html: product.long_desc_2
                                }}
                              />
                            </Collapse>
                            { product.long_desc_2 &&
                            <div
                              className={`description-collapse-btn ${showDescription ? 'opened' : ''}`}
                              onClick={() => this.setState({ showDescription: !showDescription })}
                            >
                              <span className="line" />
                              <div className="text">
                                {showDescription ? 'LESS INFO' : 'MORE INFO'}
                              </div>
                            </div>
                            }
                          </Fragment>
                        )}

                        { (product.sizes_image_url && !product.is_donation) && (
                          <Fragment>
                            <div
                              className="size-chart-btn"
                              onClick={() => this.setState({ showSizes: !showSizes })}
                            >
                              SIZE CHART
                              <img src={sizeChartIcon} alt="sizes" />
                            </div>
                            <Collapse isOpened={showSizes}>
                              <div className="product-sizes-image">
                                <img
                                  src={product.sizes_image_url}
                                  alt="Sizes chart"
                                />
                              </div>
                            </Collapse>
                          </Fragment>
                        )}
                        <div className="mt-4" />
                      </div>
                      <div className="show-for-mobile pt-3">
                        {product.long_desc && (
                          <Fragment>
                            <div
                              className="product-desc rich-text"
                              dangerouslySetInnerHTML={{
                                __html: product.long_desc
                              }}
                            />
                            <Collapse isOpened={showDescription}>
                              <div
                                className="product-desc rich-text"
                                dangerouslySetInnerHTML={{
                                  __html: product.long_desc_2
                                }}
                              />
                            </Collapse>
                            { product.long_desc_2 &&
                            <div
                              className={`description-collapse-btn ${showDescription ? 'opened' : ''}`}
                              onClick={() => this.setState({ showDescription: !showDescription })}
                            >
                              <span className="line" />
                              <div className="text">
                                {showDescription ? 'LESS INFO' : 'MORE INFO'}
                              </div>
                            </div>
                            }
                          </Fragment>
                        )}
                        { (product.sizes_image_url && !product.is_donation) && (
                          <Fragment>
                            <div
                              className="size-chart-btn"
                              onClick={() => this.setState({ showSizes: !showSizes })}
                            >
                              SIZE CHART
                              <img src={sizeChartIcon} alt="sizes" />
                            </div>
                            <Collapse isOpened={showSizes}>
                              <div className="product-sizes-image">
                                <img
                                  src={product.sizes_image_url}
                                  alt="Sizes chart"
                                />
                              </div>
                            </Collapse>
                            { showSizes &&
                            <div className="mt-3" />
                            }
                          </Fragment>
                        )}
                      </div>
                      { (!product.is_donation && collectionProducts.length > 1) && (
                        <div className="related-colors">
                          <div className="headline">OTHER COLORS</div>
                          <div className="colors-container">
                            {collectionProducts.map(p => (
                              <div
                                key={`related-product-${p.id}`}
                                className={`color-circle ${
                                  product.id === p.id ? "active" : ""
                                }`}
                                style={{ backgroundColor: p.color_hex || "#e3e3e3" }}
                                onClick={() => this.switchProductColor(p)}
                              />
                            ))}
                          </div>
                        </div>
                      )}
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
            { product.product_lifestyle_images.length > 0 ? (
              <div className="lifestyle-photos-container">
                {product.product_lifestyle_images.map(photo => (
                  <img
                    src={photo.url}
                    className="lifestyle-photo"
                    key={photo.id}
                  />
                ))}
              </div>
            ) : (
              <Fragment>
                { !product.is_donation && product.related_products.length > 0 &&
                  <div className="lifestyle-photos-container bordered" >
                    <hr/>
                  </div>
                }
              </Fragment>
            )}
            {!product.is_donation && product.related_products.length > 0 &&
              <div>
                <h4 className="Page-header padding-lr">RELATED TINGS</h4>
                { relatedTingsSubtitle &&
                  <div className="page-subtitle padding-lr">
                    {relatedTingsSubtitle}
                  </div>
                }
                <div className="related-products-row">
                  <div className="relate-products-carousel with-large-dots">
                    <Slider
                      dots
                      infinite={product.related_products.length > 3}
                      speed={500}
                      swipeToSlide
                      arrows={false}
                      slidesToShow={4}
                      slidesToScroll={1}
                      responsive={[
                        {
                          breakpoint: 1258,
                          settings: {
                            slidesToShow: 3,
                          }
                        },
                        {
                          breakpoint: 640,
                          settings: {
                            slidesToShow: 1,
                            centerPadding: "10%",
                            centerMode: true
                          }
                        }
                      ]}
                    >
                      {product.related_products.map(p => (
                        <Product
                          key={p.id}
                          product={p}
                          price={productPriceWithDiscount(product, userMembership)}
                        />
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            }
            <Link to="/shop" className="browse-btn theme-dark-bkg">
              BROWSE OUR SHOP
              <img src={hand} alt="shop" />
            </Link>
          </Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  products: state.products.products,
  isAuthenticated: isAuthenticated(state),
  userMembership: state.userMembership.membership
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addProductToCart,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductPage);
