import React, { Component, Fragment } from 'react';
import moment from 'moment'
import momentTimezone from "moment-timezone";
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { cancelLesson, clearBookLesson } from '../actions/lesson';
import location from "./../../../assets/images/icons/location.svg";
import teacherPlaceholder from "./../../../assets/images/teacher_placeholder.png";
import {renderStar} from "../helpers/render_star";
import {formattedLocationName} from "../helpers/formatted_location_name";

class AccountLessonCard extends Component {
  render() {
    const { lesson, cancelLesson, selectLesson } = this.props;
    const lessonDuration = moment(lesson.end_date_time).diff(moment(lesson.start_date_time), 'minutes')

    const lesson_time_string = moment(lesson.start_date_time).utc().format('YYYY-MM-DD HH:mm:ss')
    const lesson_time_in_nyc = momentTimezone.tz(lesson_time_string, 'America/New_York')
    const time_now_in_nyc = momentTimezone().tz('America/New_York')
    const isPastLesson = time_now_in_nyc >= lesson_time_in_nyc

    return (
      <Fragment>
        <div className="row account-card justify-content-sm-center pt-3 pt-md-0" >
          <div className="select-lesson-el" onClick={() => selectLesson(lesson)} />
          <div className="account-lesson-column staff-img-container d-flex align-items-start align-items-sm-center justify-content-sm-center mb-3 mb-sm-0" >
            { lesson.teacher_img && lesson.teacher_second_img ? (
              <Fragment>
                <div className="account-teacher-img-wrapper">
                  <img src={lesson.teacher_img} alt={lesson.staff_name} />
                </div>
                &nbsp;+&nbsp;
                <div className="account-teacher-img-wrapper">
                  <img src={lesson.teacher_second_img} alt={lesson.staff_name} />
                </div>
              </Fragment>
            ) : (
              <img src={lesson.teacher_img || teacherPlaceholder} className="staff-img" alt={lesson.staff_name} />
            )}
          </div>
          <div className="d-none d-sm-flex flex-80 account-lesson-column align-items-center mb-md-0" >
            {lesson.staff_name}
          </div>
          <div className="d-flex d-sm-none mb-3 mb-md-0 flex-column flex-grow-1 text-left">
            <div>{lesson.staff_name}</div>
            <div className="pt-2 mb-1 bordered" />
            <div style={{ maxWidth: '190px' }}>
              {moment.utc(lesson.start_date_time).format('ddd MM/DD/YYYY')}
               {/* - {moment.utc(lesson.start_date_time).format('h:mm A')} ({lessonDuration} MIN) */}
            </div>
            <div className="mt-3 text-left">
              <div style={{ maxWidth: '190px' }} className="d-inline-flex align-items-center mr-3 mb-3" >
                {/* <span className="mr-2">{renderStar(lesson.type)}</span>
                <span>{lesson.name}</span> */}
                {moment.utc(lesson.start_date_time).format('h:mm A')} - {moment.utc(lesson.end_date_time).format('h:mm A')}
              </div>
              <div>
                {/* <img src={location} height={20} alt="location" />&nbsp;&nbsp;
                {formattedLocationName(lesson.location_name)} */}
              </div>
            </div>
          </div>

          <div className="d-none d-sm-flex account-lesson-column align-items-center" >
            <div>
              <span className="d-block d-sm-inline-block">{moment.utc(lesson.start_date_time).format('ddd MM/DD/YYYY')}</span>&nbsp;&nbsp;
              {/* <span className="d-block d-sm-inline-block">{moment.utc(lesson.start_date_time).format('h:mm A')}&nbsp;&nbsp;</span>
              <span className="d-block d-sm-inline-block">({lessonDuration} MIN)</span> */}
            </div>
          </div>

          <div className="d-none account-lesson-column d-sm-flex flex-110 align-items-center" >
            {/* <span className="mr-2">{renderStar(lesson.type)}</span>
            <span className="text-center">{lesson.name}</span> */}
          </div>

          <div className="d-none account-lesson-column d-sm-flex flex-110 align-items-center" >
            {/* <img src={location} height={20} alt="location" />&nbsp;&nbsp; */}
            {/* {formattedLocationName(lesson.location_name)} */}
            <span className="d-block d-sm-inline-block">{moment.utc(lesson.start_date_time).format('h:mm A')} - {moment.utc(lesson.end_date_time).format('h:mm A')}&nbsp;&nbsp;</span>
          </div>
          { isPastLesson ? (
            <div className="col-12 col-md-1" style={{ zIndex: "-1" }}>
              &nbsp;
            </div>
          ) : (
            <div
              className="col-11 mt-3 mt-md-0 col-md-1 cancel-lesson-btn d-flex align-items-center"
              onClick={() => cancelLesson(lesson)}
            >
              CANCEL
            </div>
          )}
        </div>
        <div className="bordered-bottom d-none d-sm-block" />
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  cancelInProgress: state.lesson.cancelInProgress,
  cancelError: state.lesson.cancelError,
  cancellingLessonId: state.lesson.cancellingLesson,
  cancelLessonPopup: state.cancelLessonPopup,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      cancelLesson,
      clearBookLesson,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(AccountLessonCard);