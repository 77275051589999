import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { updateProductQuantity, updateProductQtyUnauthorizedCart } from "../actions/cart";
import { isAuthenticated } from "../reducers/user";

class BagItemQuantitySelectorNew extends Component {
  selectQuantity = event => {
    const {
      item,
      isAuthenticated,
      updateProductQuantity,
      updateProductQtyUnauthorizedCart
    } = this.props;
    if (isAuthenticated) {
      updateProductQuantity(item.id, event.target.value);
    } else {
      updateProductQtyUnauthorizedCart(item.id, event.target.value, item.size);
    }
  };

  render() {
    const {
      item,
      cartItems,
      unauthorizedCartItems,
      isAuthenticated
    } = this.props;
    const bagItems = isAuthenticated ? cartItems : unauthorizedCartItems;

    const currentItem = bagItems.find(p => p.id === item.id);
    if (!currentItem || currentItem.quantity <= 0) return <div />;

    const list = [];
    for (let i = 1; i <= 10; i++) {
      list.push(i);
    }
    return (
      <select
        className="bag-item-quantity"
        onChange={event => this.selectQuantity(event)}
        value={Number(currentItem.quantity) || 1}
      >
        {list.map(option => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    );
  }
}

const mapStateToProps = state => ({
  cartItems: state.cart.items,
  unauthorizedCartItems: state.unauthorizedCart.items,
  isAuthenticated: isAuthenticated(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateProductQuantity,
      updateProductQtyUnauthorizedCart
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BagItemQuantitySelectorNew);
