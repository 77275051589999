import placeholder from "../../../assets/images/teacher_placeholder.png";
import Avatars from "../data/Avatars";

export const getUserAvatar = (user) => {
  const { photo_url } = user;

  if (!photo_url) return placeholder;
  const avatarImg = Avatars.find(el => el.description === photo_url);
  if (avatarImg) return avatarImg.image;

  return photo_url;
}