import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import superagent from "superagent";
import { history } from "../../store/configureStore";
import { userRegister } from "../../actions/user_register";

import { authenticate } from "../../actions/authentication";
import arrowRightWhite from "./../../../../assets/images/icons/arrow_right_white.svg";
import hand from "../../../../assets/images/icons/hand.svg";
import checkboxChecked from "../../../../assets/images/icons/Checkbox_checked.svg";
import ButtonWithIcon from "../button_with_icon";
import ComponentWithLoading from "../component_with_loading";
import validateEmail from "../../helpers/validate_email";
import validatePassword from "../../helpers/validate_password";
import EmailInput from "./email_input";
import PasswordInput from "./password_input";
import AuthPageHeader from "./auth_page_header";
import ForgotPassword from "./forgot_password";
import ResetPassword from "./reset_password";
import EditProfile from "./edit-profile";
import addTagManagerEvent from "../../helpers/add_tag_manager_event";

class AuthComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: props.mode || "get-started", // login, signup, forgot-password, reset-password, edit-profile
      loading: false,
      email: "",
      password: "",
      confirm_password: "",
      termsChecked: false,
      confirmPasswordError: false,
      invalidPassword: false,
      authError: null,
      signupError: null,
      cameFromGetStarted: false
    };
  }

  componentDidMount() {
    if (this.props.isTvSignup) {
      addTagManagerEvent(window.location.pathname, "Step-1");
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.mode !== this.props.mode) {
      this.setState({ mode: newProps.mode });
    }
    if (newProps.previousState && newProps.previousState.email && (!this.props.previousState || !this.props.previousState.email)) {
      this.setState({ email: newProps.previousState.email });
    }
  }

  renderContent = () => {
    const { pageContent, loadingPageContent, afterAuth } = this.props;
    const { mode, email } = this.state;

    switch (mode) {
      case "forgot-password":
        return (
          <ForgotPassword
            loadingPageContent={loadingPageContent}
            pageContent={pageContent}
            switchMode={mode => this.switchMode(mode)}
            emailValue={email}
          />
        );
      case "reset-password":
        return (
          <ResetPassword
            loadingPageContent={loadingPageContent}
            pageContent={pageContent}
            location={this.props.location}
            switchMode={mode => this.switchMode(mode)}
          />
        );
      default:
        return (
          <EditProfile pageContent={pageContent} afterUserUpdate={afterAuth} />
        );
    }
  };

  renderComponent = () => {
    const { mode } = this.state;

    switch (mode) {
      case "get-started":
        return this.renderGetStarted();
      case "login":
        return this.renderLogin();
      case "signup":
        return this.renderSignup();
      default:
        return this.renderGetStarted();
    }
  };

  renderGetStarted = () => {
    const { email, loading } = this.state;

    const isValid = validateEmail(email);

    return (
      <Fragment>
        <EmailInput
          handleChange={this.handleChange}
          value={email}
          onSubmit={() => this.checkEmail()}
        />
        <input
          style={{ opacity: 0, height: '1px' }}
          name="password"
          type="password"
        />

        <ComponentWithLoading isLoading={loading}>
          <ButtonWithIcon
            classNamesProp="high-75 confirm-button with-negative-margin"
            text="CONTINUE"
            iconSrc={email && isValid ? arrowRightWhite : null}
            disabled={!email || !isValid}
            onClick={() => this.checkEmail()}
          />
        </ComponentWithLoading>
        <div className="mb-5 pt-2" />
      </Fragment>
    );
  };

  renderLogin = () => {
    const {
      email,
      password,
      loading,
      authError,
      cameFromGetStarted
    } = this.state;
    const isValid = validateEmail(email);

    return (
      <div>
        <EmailInput
          handleChange={this.handleChange}
          value={email}
          onSubmit={() => this.checkEmail()}
          disabled={!!email && cameFromGetStarted}
          onInputClick={() => this.switchMode("get-started")}
        />
        <PasswordInput
          handleChange={this.handleChange}
          value={password}
          onSubmit={() => this.login()}
        />
        {authError && (
          <div
            className="input-error-red"
            style={{ color: "#c01823", letterSpacing: "1px" }}
          >
            {authError}
          </div>
        )}
        <ComponentWithLoading isLoading={loading}>
          <ButtonWithIcon
            classNamesProp="high-75 confirm-button ga-tv-step-1"
            text="LOG IN"
            iconSrc={hand}
            disabled={!email || !isValid || !password}
            onClick={() => this.login()}
          />
        </ComponentWithLoading>
      </div>
    );
  };

  renderSignup = () => {
    const {
      password,
      email,
      confirm_password,
      termsChecked,
      loading,
      confirmPasswordError,
      invalidPassword,
      signupError,
      cameFromGetStarted
    } = this.state;

    const buttonDisabled =
      !email ||
      !password ||
      !confirm_password ||
      !termsChecked ||
      confirmPasswordError ||
      invalidPassword;

    if (this.props.isTvSignup) {
      addTagManagerEvent(window.location.pathname, "New");
    }

    return (
      <div>
        <EmailInput
          handleChange={this.handleChange}
          value={email}
          onSubmit={() => this.checkEmail()}
          disabled={!!email && cameFromGetStarted}
          onInputClick={() => this.switchMode("get-started")}
        />
        <PasswordInput
          handleChange={this.handleChange}
          value={password}
          placeholder="SET PASSWORD"
          name="password"
          error={invalidPassword}
          onBlur={() => this.matchPasswordConfirmation()}
        />
        <PasswordInput
          handleChange={this.handleChange}
          value={confirm_password}
          placeholder="CONFIRM PASSWORD"
          name="confirm_password"
          error={confirmPasswordError}
          onBlur={() => this.matchPasswordConfirmation()}
        />
        {signupError && (
          <div className="input-error-red">{signupError.message}</div>
        )}

        {invalidPassword && (
          <div className="input-error-red">
            Password must contain at least 8 characters and must include both
            letters and numbers
          </div>
        )}

        {confirmPasswordError && (
          <div className="input-error-red">
            Password confirmation does not match
          </div>
        )}

        <ComponentWithLoading isLoading={loading}>
          <ButtonWithIcon
            classNamesProp="high-75 confirm-button ga-tv-step-1"
            text="CONTINUE"
            iconSrc={
              email && password && confirm_password && termsChecked
                ? arrowRightWhite
                : null
            }
            disabled={buttonDisabled}
            onClick={() => this.signup()}
          />
        </ComponentWithLoading>
      </div>
    );
  };

  handleChange = event => {
    const { invalidPassword, confirmPasswordError, authError } = this.state;

    this.setState({ [event.target.name]: event.target.value });
    if (invalidPassword || confirmPasswordError || authError) {
      this.setState({
        invalidPassword: false,
        authError: false
      });
    }
  };

  matchPasswordConfirmation = () => {
    const { password, confirm_password, confirmPasswordError } = this.state;

    const validPassword = validatePassword(password);

    if (!validPassword) {
      this.setState({ invalidPassword: true });
    } else {
      if (confirm_password) {
        if (password !== confirm_password) {
          this.setState({ confirmPasswordError: true });
        } else {
          this.setState({ confirmPasswordError: false });
        }
      } else {
        if (!confirm_password && confirmPasswordError) {
          this.setState({ confirmPasswordError: false });
        }
      }
    }
  };

  checkEmail = () => {
    const { email } = this.state;
    this.setState({ loading: true });

    return superagent
      .get(`/users/check_email?email=${email.toLowerCase()}`)
      .set("Accept", "application/json")
      .then(res => {
        if (res.body.result === "Success") {
          this.switchMode("login");
          this.setState({ loading: false, cameFromGetStarted: true });
        } else {
          this.switchMode("signup");
          this.setState({ loading: false, cameFromGetStarted: true });
        }
      })
      .catch(error => {
        this.setState({
          error: error.response.body,
          loading: false
        });
      });
  };

  login = () => {
    const {
      authenticate,
      afterAuth,
      hasRequiredMboInfo,
      isShopFlow,
      isTvSignup,
      afterLogin
    } = this.props;
    const { email, password } = this.state;

    this.setState({ loading: true, authError: null });

    authenticate({ email: email.toLowerCase(), password })
      .then(res => {
        this.setState({ loading: false });

        if (afterLogin) {
          afterLogin();
        } else {
          if (!hasRequiredMboInfo && isShopFlow) {
            this.switchMode("edit-profile");
          } else {
            afterAuth();
          }
        }
      })
      .catch(error => {
        if (error === "invalid mbo password") {
          this.setState({ loading: false });
          history.push(`${isTvSignup ? "/tv-signup" : ""}/forgot-password`);
        } else {
          this.setState({ loading: false, authError: error });
        }
      });
  };

  signup = () => {
    const { email, password } = this.state;
    const {
      afterAuth,
      hasRequiredMboInfo,
      isShopFlow,
      afterSignup,
      isTvSignup
    } = this.props;

    this.setState({ loading: true, signupError: null });

    this.props
      .userRegister({
        user: { email: email.toLowerCase(), password },
        is_tv_flow: !!isTvSignup
      })
      .then(res => {
        this.setState({ loading: false });

        if (afterSignup) {
          afterSignup();
        } else {
          if (!hasRequiredMboInfo && isShopFlow) {
            this.switchMode("edit-profile");
          } else {
            afterAuth();
          }
        }
      })
      .catch(err => {
        if (err && err.message === "This email already exists") {
          this.setState({ loading: false });
          this.switchMode("login");
        } else {
          this.setState({ loading: false, signupError: err });
        }
      });
  };

  renderStep = () => {
    const { pageContent } = this.props;
    const { mode } = this.state;

    if (!pageContent) return "";

    switch (mode) {
      case "login":
        return pageContent.login_step || "STEP 1";
      case "signup":
        return pageContent.signup_step || "STEP 1";
      default:
        return pageContent.get_started_step || "STEP 1";
    }
  };

  renderTitle = () => {
    const { mode } = this.state;

    switch (mode) {
      case "login":
        return "LOG IN";
      case "signup":
        return "SIGN UP";
      default:
        return "GET STARTED";
    }
  };

  renderSecondaryMessage = () => {
    const { pageContent } = this.props;
    const { mode } = this.state;

    if (!pageContent) return <div />;

    switch (mode) {
      case "login":
        return pageContent.login_secondary_message || "";
      case "signup":
        return pageContent.signup_secondary_message || "";
      default:
        return pageContent.get_started_secondary_message || "";
    }
  };

  renderPageText = () => {
    const { pageContent } = this.props;
    const { mode } = this.state;

    if (!pageContent) return "";

    switch (mode) {
      case "login":
        return pageContent.login_text || "";
      case "signup":
        return pageContent.signup_text || "";
      default:
        return pageContent.get_started_text || "";
    }
  };

  switchMode = mode => {
    const { isTvSignup } = this.props;

    this.setState({ mode });

    history.push(`${isTvSignup ? "/tv-signup" : ""}/${mode}`);
  };

  render() {
    const { mode, termsChecked } = this.state;
    const { isTvSignup, pageContent, loadingPageContent } = this.props;

    return (
      <Fragment>
        <AuthPageHeader
          showTvIcon={isTvSignup}
          onButtonClick={() => this.switchMode("login")}
          buttonText={
            ["login", "edit-profile"].includes(mode) ? null : "LOG IN"
          }
          pathname={history.location.pathname}
        />
        <div className="signup-page-content">
          <ComponentWithLoading isLoading={loadingPageContent}>
            {["forgot-password", "reset-password", "edit-profile"].includes(
              mode
            ) ? (
              <Fragment>{this.renderContent()}</Fragment>
            ) : (
              <Fragment>
                <div className="step">{this.renderStep()}</div>
                <div className="signup-page-subtitle">{this.renderTitle()}</div>
                <div className="secondary-message">
                  {this.renderSecondaryMessage()}
                </div>
                <hr />
                <div
                  className="signup-page-text"
                  dangerouslySetInnerHTML={{ __html: this.renderPageText() }}
                />

                <div className="mt-50 form-container">
                  {this.renderComponent()}
                </div>

                {mode === "login" ? (
                  <Fragment>
                    <div className="bottom-links-row">
                      <div
                        onClick={() => this.switchMode("forgot-password")}
                        className="link underline"
                      >
                        Forgot password?
                      </div>
                    </div>
                    <div className="bottom-links-row">
                      <div className="link">
                        DON'T HAVE AN ACCOUNT?&nbsp;
                        <span
                          onClick={() => this.switchMode("signup")}
                          className="underline"
                        >
                          SIGN UP
                        </span>
                      </div>
                    </div>
                  </Fragment>
                ) : (
                  <div>
                    {mode === "signup" && pageContent && pageContent.terms && (
                      <div className="legal-text-container">
                        <div
                          className="checkbox-container"
                          onClick={() =>
                            this.setState({ termsChecked: !termsChecked })
                          }
                        >
                          {termsChecked ? (
                            <img src={checkboxChecked} className="checked" />
                          ) : (
                            <div className="unchecked">&nbsp;</div>
                          )}
                        </div>
                        <div
                          className="legal-text"
                          dangerouslySetInnerHTML={{
                            __html: pageContent.terms
                          }}
                        />
                      </div>
                    )}

                    <div className="bottom-links-row">
                      <div
                        className="link underline"
                        onClick={() => this.switchMode("login")}
                      >
                        Already have an account?
                      </div>
                    </div>
                  </div>
                )}
              </Fragment>
            )}
          </ComponentWithLoading>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      authenticate,
      userRegister
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthComponent);
