import React, { Component, Fragment } from "react";
import superagent from "superagent";
import arrowRightWhite from "./../../../../assets/images/icons/arrow_right_white.svg";
import ButtonWithIcon from "../button_with_icon";
import ComponentWithLoading from "../component_with_loading";
import PasswordInput from "./password_input";
import validatePassword from "../../helpers/validate_password";
import { getParamByName } from "../../helpers/get_param_by_name";

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      password: "",
      confirm_password: "",
      confirmPasswordError: null,
      error: null,
      invalidPassword: false,
      token: null,
      invalidToken: false,
      success: false
    };
  }

  componentDidMount() {
    this.checkToken();
  }

  checkToken = () => {
    const token = getParamByName("reset_password_token");
    if (token) {
      this.setState({ token });
    } else {
      this.setState({ invalidToken: true });
    }
  };

  handleChange = event => {
    const { invalidPassword } = this.state;

    this.setState({ [event.target.name]: event.target.value });

    if (invalidPassword) {
      this.setState({ invalidPassword: false });
    }
  };

  resetPassword = () => {
    const { password, token } = this.state;

    this.setState({ loading: true });

    return superagent
      .post("/users/reset_password")
      .set("Accept", "application/json")
      .send({ password, token })
      .then(res => {
        this.setState({ success: true, loading: false });
      })
      .catch(error => {
        this.setState({
          error: error.response.body,
          loading: false
        });
      });
  };

  matchPasswordConfirmation = () => {
    const { password, confirm_password, confirmPasswordError } = this.state;

    const validPassword = validatePassword(password);

    if (!validPassword) {
      this.setState({ invalidPassword: true });
    } else {
      if (confirm_password) {
        if (password !== confirm_password) {
          this.setState({ confirmPasswordError: true });
        } else {
          this.setState({ confirmPasswordError: false });
        }
      } else {
        if (!confirm_password && confirmPasswordError) {
          this.setState({ confirmPasswordError: false });
        }
      }
    }
  };

  renderSuccess = () => {
    const { pageContent, switchMode } = this.props;

    return (
      <Fragment>
        <div className="signup-page-subtitle mb-3">PASSWORD HAS BEEN RESET!</div>
        <div className="pb-1" />
        <hr />
        {/*{pageContent && (*/}
        {/*  <div*/}
        {/*    className="signup-page-text"*/}
        {/*    dangerouslySetInnerHTML={{*/}
        {/*      __html: pageContent.reset_password_success_text*/}
        {/*    }}*/}
        {/*  />*/}
        {/*)}*/}
        <ButtonWithIcon
          classNamesProp="high-75 confirm-button mt-5"
          text="RETURN TO LOGIN"
          iconSrc={arrowRightWhite}
          onClick={() => switchMode("login")}
        />
      </Fragment>
    );
  };

  render() {
    const {
      loading,
      password,
      confirm_password,
      confirmPasswordError,
      error,
      invalidPassword,
      invalidToken,
      success
    } = this.state;

    const { pageContent, switchMode } = this.props;

    const buttonDisabled =
      !password || !confirm_password || confirmPasswordError || invalidPassword;

    return (
      <Fragment>
        <div className="step cursor-pointer" onClick={() => switchMode("login")}>RETURN TO LOGIN</div>
        {success ? (
          <Fragment>{this.renderSuccess()}</Fragment>
        ) : (
          <Fragment>
            <div className="signup-page-subtitle">RESET YOUR PASSWORD</div>
            <hr />
            {pageContent && (
              <div
                className="signup-page-text"
                dangerouslySetInnerHTML={{
                  __html: pageContent.reset_password_text
                }}
              />
            )}
            <div className="mt-50 form-container">
              {invalidToken ? (
                <div className="input-error-red">
                  Link is not valid. Try generating a new link.
                </div>
              ) : (
                <Fragment>
                  <PasswordInput
                    handleChange={this.handleChange}
                    value={password}
                    placeholder="SET PASSWORD"
                    name="password"
                    onBlur={() => this.matchPasswordConfirmation()}
                    error={invalidPassword}
                  />
                  <PasswordInput
                    handleChange={this.handleChange}
                    value={confirm_password}
                    placeholder="CONFIRM PASSWORD"
                    name="confirm_password"
                    error={confirmPasswordError}
                    onBlur={() => this.matchPasswordConfirmation()}
                  />
                  {invalidPassword && (
                    <div className="input-error-red">
                      Password must contain at least 8 characters and must include both letters and numbers
                    </div>
                  )}
                  {error && (
                    <div className="input-error-red">
                      {error.message || "Something went wrong"}
                    </div>
                  )}
                  <ComponentWithLoading isLoading={loading}>
                    <ButtonWithIcon
                      classNamesProp="high-75 confirm-button"
                      text="CONTINUE"
                      iconSrc={buttonDisabled ? null : arrowRightWhite}
                      disabled={buttonDisabled}
                      onClick={() => this.resetPassword()}
                    />
                  </ComponentWithLoading>
                </Fragment>
              )}
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export default ResetPassword;
