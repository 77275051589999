import React, { Component, Fragment } from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { logout } from "../actions/authentication";
import logo from "./../../../assets/images/icons/lady.svg";
import Bag from "../components/bag";
import BagItemsCounter from "./bag_items_counter";
import NavbarDropdown from "./navbar_dropdown";
import { getUserAvatar } from "../helpers/get_user_avatar";

class NavbarMobile extends Component {
  constructor() {
    super();
    this.state = {
      menuIsOpen: false,
      programsIsOpen: false,
      aboutIsOpen: false,
      tvIsOpen: false,
      pricingIsOpen: false,
      showLessonCredits: false
    };
  }

  toggleMobileMenu = () =>
    this.setState({
      menuIsOpen: !this.state.menuIsOpen,
      programsIsOpen: false,
      aboutIsOpen: false,
      tvIsOpen: false,
      pricingIsOpen: false,
    });

  closeAll = () =>
    this.setState({
      menuIsOpen: false,
      programsIsOpen: false,
      aboutIsOpen: false,
      tvIsOpen: false,
      pricingIsOpen: false
    });

  toggleSection = section => {
    this.setState({
      [section]: !this.state[section],
      menuIsOpen: false
    });
  };

  goToMobileMenu = () =>
    this.setState({
      menuIsOpen: true,
      programsIsOpen: false,
      aboutIsOpen: false,
      tvIsOpen: false,
      pricingIsOpen: false
    });

  render() {
    const { user, colorTheme, emptyCart } = this.props;
    const {
      menuIsOpen,
      programsIsOpen,
      aboutIsOpen,
      tvIsOpen,
      pricingIsOpen
    } = this.state;
    const openMenu =
      menuIsOpen || programsIsOpen || aboutIsOpen || tvIsOpen || pricingIsOpen;

    const navbarClass = classNames(
      colorTheme,
      {
        "Navbar-mobile-component": true,
      }
    );

    return (
      <div className={navbarClass}>
        <div
          className={`Navbar-mobile ${openMenu ? 'position-fixed' : 'position-initial'}`}
        >
          { !menuIsOpen &&
          <div
            className={`Navbar-link-wrapper bag-element ${emptyCart ? 'empty-cart' : ''}`}
          >
            <NavbarDropdown
              name="cart_items"
              withArrow={false}
              className={`bag-dropdown-container ${emptyCart ? 'empty-cart' : ''}`}
            >
              <BagItemsCounter />
              <Bag />
            </NavbarDropdown>
          </div>
          }
          <Link to="/" className="Navbar-mobile-logoContainer" title="Visit the main page">
            <img src={logo} height="30" alt="Skyting" />
          </Link>
          {openMenu ? (
            <div className="Navbar-mobile-menu-close" onClick={this.closeAll}>
              &#10005;
            </div>
          ) : (
            <div
              className="Navbar-mobile-menu-icon"
              onClick={this.toggleMobileMenu}
            >
              <div className="Menu-icon-element" />
              <div className="Menu-icon-element" />
              <div className="Menu-icon-element" />
            </div>
          )}
        </div>
        {menuIsOpen && (
          <div
            className={`Navbar-mobile-menu`}
          >
            <div
              className="Navbar-mobile-link"
              onClick={() => this.toggleSection("tvIsOpen")}
            >
              TV
              <div className="arrow-right" />
            </div>
            <div
              className="Navbar-mobile-link"
              onClick={() => this.toggleSection("pricingIsOpen")}
            >
              IN PERSON
              <div className="arrow-right" />
            </div>

            {/*<Link*/}
            {/*  to="/schedule"*/}
            {/*  title="Visit schedule page"*/}
            {/*  className="Navbar-mobile-link"*/}
            {/*  onClick={this.toggleMobileMenu}*/}
            {/*>*/}
            {/*  SCHEDULE*/}
            {/*</Link>*/}
            {/*<div*/}
            {/*  className="Navbar-mobile-link"*/}
            {/*  onClick={() => this.toggleSection("programsIsOpen")}*/}
            {/*>*/}
            {/*  PROGRAMS*/}
            {/*  <div className="arrow-right" />*/}
            {/*</div>*/}
            <Link
              to="/courses"
              title="Visit Courses"
              className="Navbar-mobile-link"
              onClick={this.toggleMobileMenu}
            >
              COURSES
            </Link>
            <Link
              to="/retreats"
              title="Visit retreats"
              className="Navbar-mobile-link"
              onClick={this.toggleMobileMenu}
            >
              RETREATS
            </Link>
            <Link
              to="/shop"
              title="Visit shop"
              className="Navbar-mobile-link"
              onClick={this.toggleMobileMenu}
            >
              SHOP
            </Link>
            <Link
              to="/pricing"
              title="Visit pricing page"
              className="Navbar-mobile-link"
              onClick={this.toggleMobileMenu}
            >
              PRICING
            </Link>
            {/*<Link*/}
            {/*  to="/tribune"*/}
            {/*  title="Visit tribune"*/}
            {/*  className="Navbar-mobile-link"*/}
            {/*  onClick={this.toggleMobileMenu}*/}
            {/*>*/}
            {/*  TRIBUNE*/}
            {/*</Link>*/}
            <div
              className="Navbar-mobile-link"
              onClick={() => this.toggleSection("aboutIsOpen")}
            >
              ABOUT
              <div className="arrow-right" />
            </div>
            {user && user.id ? (
              <div
                className={`Navbar-mobile-link user-btn`}
              >
                <div className="d-flex w-100 top navbar-user-wrapper">
                  <div className="navbar-user-avatar">
                    <img src={getUserAvatar(user)} alt="Photo" />
                  </div>
                  <div className="user-name">{user.first_name}</div>
                </div>
                <div className="d-flex w-100 bottom">
                  <div className="logout-btn" onClick={this.props.logout}>
                    Log out
                  </div>
                  <Link
                    to="/account"
                    title="Visit your account"
                    className="account-btn"
                    onClick={this.closeAll}
                  >
                    MY ACCOUNT
                  </Link>
                </div>
              </div>
            ) : (
              <Fragment>
                <Link
                  to={{ pathname: "/login", state: { cameFrom: window.location.pathname } }}
                  title="Visit login"
                  className="Navbar-mobile-link Inverse"
                  onClick={this.toggleMobileMenu}
                >
                  LOG IN
                </Link>
                <Link
                  to={{ pathname: "/tv-signup/signup", state: { cameFrom: this.props.pathname, isNavbarFlow: true, withLive: true } }}
                  title="Visit login"
                  className="Navbar-mobile-link"
                  onClick={this.toggleMobileMenu}
                >
                  SIGN UP!
                </Link>
              </Fragment>
            )}
          </div>
        )}
        {/*{programsIsOpen && (*/}
        {/*  <div*/}
        {/*    className={`Navbar-mobile-menu`}*/}
        {/*  >*/}
        {/*    <div*/}
        {/*      className='Navbar-mobile-link Inverse'*/}
        {/*      onClick={this.goToMobileMenu}*/}
        {/*    >*/}
        {/*      PROGRAMS*/}
        {/*      <div className="arrow-left" />*/}
        {/*    </div>*/}
        {/*    {["workshops", "retreats", "trainings", "sauna"].map(*/}
        {/*      (route, index) => (*/}
        {/*        <Link*/}
        {/*          to={route}*/}
        {/*          title={`Visit ${route} section`}*/}
        {/*          className="Navbar-mobile-link"*/}
        {/*          onClick={() => this.toggleSection("programsIsOpen")}*/}
        {/*          key={`programs-${index}`}*/}
        {/*        >*/}
        {/*          {route}*/}
        {/*        </Link>*/}
        {/*      )*/}
        {/*    )}*/}
        {/*  </div>*/}
        {/*)}*/}
        {aboutIsOpen && (
          <div
            className={`Navbar-mobile-menu`}
          >
            <div
              className='Navbar-mobile-link Inverse'
              onClick={this.goToMobileMenu}
            >
              ABOUT
              <div className="arrow-left" />
            </div>
            {[
              "teachers",
              // "locations",
              "press",
              // "friends",
              "faq",
              // "credits"
            ].map((route, index) => (
              <Link
                to={`/${route}`}
                title={`Visit ${route} section`}
                className="Navbar-mobile-link"
                onClick={() => this.toggleSection("aboutIsOpen")}
                key={`about-${index}`}
              >
                {route}
              </Link>
            ))}
          </div>
        )}
        {pricingIsOpen && (
          <div
            className={`Navbar-mobile-menu`}
          >
            <div
              className='Navbar-mobile-link Inverse'
              onClick={this.goToMobileMenu}
            >
              IN PERSON
              <div className="arrow-left" />
            </div>
            {[
              { url: "/schedule", name: "Schedule" },
              { url: "/in-person-pricing", name: "Pricing" }
            ].map((route, index) => (
              <Link
                to={route.url}
                title={`Visit ${route.name} section`}
                className="Navbar-mobile-link"
                onClick={() => this.toggleSection("pricingIsOpen")}
                key={`tv-${index}`}
              >
                {route.name}
              </Link>
            ))}
          </div>
        )}
        {tvIsOpen && (
          <div
            className={`Navbar-mobile-menu`}
          >
            <div
              className="Navbar-mobile-link Inverse"
              onClick={this.goToMobileMenu}
            >
              TV
              <div className="arrow-left" />
            </div>
            {[
              { url: "/tv", name: "Dashboard" },
              { url: "/tv/videos", name: "Library" },
              { url: "/tv/live", name: "Zoom schedule" },
              { url: "/tv/collections", name: "Collections" }
            ].map((route, index) => (
              <Link
                to={route.url}
                title={`Visit ${route.name} section`}
                className="Navbar-mobile-link"
                onClick={() => this.toggleSection("tvIsOpen")}
                key={`tv-${index}`}
              >
                {route.name}
              </Link>
            ))}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loginPopup: state.loginPopup,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      logout,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavbarMobile);
