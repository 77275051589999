import Api from '../api/user_update';
import { FETCH_CURRENT_USER_SUCCESS } from '../actions/user';

export const UPDATE_USER_AVATAR = 'UPDATE_USER_AVATAR';

export const updateUserAvatar = photo_url => (dispatch) => {
  dispatch({ type: UPDATE_USER_AVATAR, photo_url });
}

export const updateUserInfo = user => (dispatch) => {
  dispatch({ type: FETCH_CURRENT_USER_SUCCESS, user });
}

export const updateCurrentUser = user => (dispatch) => {
  return new Promise(async(resolve, reject) => {
    await Api.updateCurrentUser(user)
      .then((res) => {
        dispatch(updateUserInfo(res.body))
        resolve(true);
      })
      .catch((err) => {
        reject(err.response.body);
      });
  });
};