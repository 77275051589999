import React, { Component, Fragment } from "react";
import superagent from "superagent";

export default class Coupon extends Component {
  constructor(props) {
    super(props);

    this.state = {
      coupon: "",
      isLoading: false,
      description: null,
      couponAccepted: null,
      couponError: null
    };

    this.handleChange = this.handleChange.bind(this);
  }


  handleChange(event) {
    const { couponAccepted } = this.state;
    if (couponAccepted !== null) {
      this.setState({ couponAccepted: null });
    }
    this.setState({ coupon: event.target.value.toUpperCase() });
  }

  checkCoupon = () => {
    const { setCouponValues } = this.props;
    const { coupon } = this.state;

    this.setState({ isLoading: true });

    return superagent
      .get("/tv_coupons")
      .set("Accept", "application/json")
      .query({ coupon })
      .then(res => {
        this.setState({
          couponAccepted: true,
          description: res.body.description,
          isLoading: false,
          couponError: null
        });
        this.props.setCoupon(coupon);
        if (setCouponValues) {
          setCouponValues(
            res.body.percent_off,
            res.body.amount_off,
            res.body.duration,
            res.body.duration_in_months
          );
        }
      })
      .catch(err => {
        this.setState({
          couponAccepted: false,
          isLoading: false,
          couponError: null
        });
      });
  };

  renderBtn = () => {
    const { couponAccepted, coupon, isLoading } = this.state;
    const { couponApplied } = this.props;

    if (couponApplied)
      return <button className="apply-discount-btn success">APPLIED!</button>;
    if (couponAccepted === false)
      return <button className="apply-discount-btn invalid">INVALID</button>;

    return (
      <button
        disabled={isLoading || !coupon}
        className="apply-discount-btn"
        onClick={this.checkCoupon}
      >
        APPLY
      </button>
    );
  };

  render() {
    const { coupon, couponAccepted, isLoading, couponError } = this.state;
    const { couponApplied, couponVal } = this.props;

    return (
      <div className="tv-signup-coupon-container text-center">
        <div className="tv-signup-coupon-title">HAVE A DISCOUNT CODE?</div>

        <div className="d-block d-sm-flex promo-code-container">
          <div className="tv-signup-coupon">
            <input
              disabled={couponApplied}
              value={couponVal || coupon}
              onChange={this.handleChange}
              placeholder="ENTER YOUR CODE"
            />
            {this.renderBtn()}
          </div>
        </div>
        {isLoading ? (
          <div className="bottom-text">WAIT PLEASE...</div>
        ) : (
          <Fragment>
            {couponAccepted === false && (
              <div className="bottom-text failed-discount">
                {couponError || "Coupon code is invalid"}
              </div>
            )}
          </Fragment>
        )}
      </div>
    );
  }
}
