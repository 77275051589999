const sizesOrder = (sizesArray) => {
  const sizeOrder = [
    { pattern: /^One Size$/ },
    { pattern: /^[X/S]+S$/i, },
    { pattern: /^S$/i },
    { pattern: /^M$/i },
    { pattern: /^L$/i },
    { pattern: /^[X]+L$/i },
    { pattern: /^[4-9]XL$/i },
    { pattern: /^[0-9,]+$/, sort: arr => arr.sort() }
  ];

  const patternMap = new Map(sizeOrder.map(({ pattern }) => [pattern, []]));
  sizesArray.forEach((s) => {
    const selectedPattern = sizeOrder.find(({ pattern }) => pattern.test(s.name));

    if (selectedPattern) {
      const { pattern } = sizeOrder.find(({ pattern }) => pattern.test(s.name));
      patternMap.get(pattern).push(s);
    }
  });

  const valuesArr = [...patternMap.values()];
  valuesArr.forEach((arr, i) => {
    const sort = sizeOrder[i].sort;
    if (sort) {
      sort(arr);
    } else {
      arr.sort((a, b) => (b.name).localeCompare(a.name));
    }
  });

  return valuesArr.flat()
}

export default sizesOrder;
