import React, { Component } from "react";
import { connect } from "react-redux";

class CreditCardPayment extends Component {
  selectGiftCard = () => {
    const { disabled, chooseGiftCard } = this.props;

    if (disabled) return;
    chooseGiftCard();
  }

  render() {
    const {
      availableBalance,
      isChecked,
      withoutTopBorder,
      disabled
    } = this.props;

    if (!availableBalance || availableBalance === 0) return <div />;

    return (
      <div className={`CreditCard ${disabled ? 'disabled' : ''}`}>
        <div className={`CreditCard-container ${withoutTopBorder ? 'top-border-none' : ''}`}>
          <div className="CreditCard-number">
            GIFT CARD
          </div>
          <div className="CreditCard-btn">
            <div className="CreditCard-text">USE ${availableBalance} BALANCE</div>
            <div>
              <div
                className={`CreditCard-checkbox ${
                  isChecked ? "is-checked" : ""
                }`}
                onClick={() => this.selectGiftCard()}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  // balance: state.balance
});

export default connect(mapStateToProps)(CreditCardPayment);
