import Api from "../api/workshops";

export const FETCH_WORKSHOPS = 'FETCH_WORKSHOPS';
export const FETCH_WORKSHOPS_ERROR = 'FETCH_WORKSHOPS_ERROR';
export const FETCH_WORKSHOPS_SUCCESS = 'FETCH_WORKSHOPS_SUCCESS';

  export const fetchWorkshops = () => dispatch => {
  dispatch({ type: FETCH_WORKSHOPS });

  Api.fetchWorkshops()
    .then(res =>
      dispatch({
        type: FETCH_WORKSHOPS_SUCCESS,
        workshops: res.body
      })
    )
    .catch(err =>
      dispatch({
        type: FETCH_WORKSHOPS_ERROR,
        error: err
      })
    );
};
