import { dropdownChange } from './dropdowns';
import { bookLessonAuthorized } from './lesson';
import { waitForAuth } from './cart';

export const LOGIN_POPUP_OPEN = 'LOGIN_POPUP_OPEN';
export const LOGIN_POPUP_CLOSE = 'LOGIN_POPUP_CLOSE';
export const LOGIN_POPUP_OPEN_WITH_ERROR = 'LOGIN_POPUP_OPEN_WITH_ERROR';

export const openLoginPopup = (mode = 'login') => (dispatch) => {
  dispatch({ type: LOGIN_POPUP_OPEN, mode });
  dispatch(dropdownChange({ login: false }));
}

export const closeLoginPopup = () => (dispatch, getState) => {
  dispatch({ type: LOGIN_POPUP_CLOSE });
  const state = getState()

  if (state.cart.waitingForAuth && !state.register_popup.show) {
    dispatch(waitForAuth(false));
  }

  if (state.lesson.waitingForAuthentication) {
    dispatch(bookLessonAuthorized());
  }
}

export const openLoginPopupWithError = error => dispatch =>
  dispatch({ type: LOGIN_POPUP_OPEN_WITH_ERROR, error });
