import React, { Fragment } from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import RenderLoading from "./render_loading";

const monthRegExp = /^(0?[1-9]|1[012])$/

const RegistrationCardSchema = Yup.object().shape({
  card_number: Yup.string()
    .required('Required'),
  exp_month: Yup.string()
    .length(2, 'Month must contain 2 numbers')
    .matches(monthRegExp, 'Invalid value')
    .required('Required'),
  exp_year: Yup.string()
    .length(4, 'Year must contain 4 numbers')
    .required('Required'),
  cvv: Yup.string()
    .required('Required'),
  card_holder: Yup.string()
    .required('Required'),
});

const RegisterPaymentForm = (props) => (
  <div>
    <Formik
      initialValues={{
        card_number: props.paymentInfo ? props.paymentInfo.card_number : '',
        exp_month: props.paymentInfo ? props.paymentInfo.exp_month : '',
        exp_year: props.paymentInfo ? props.paymentInfo.exp_year : '',
        cvv: props.paymentInfo ? props.paymentInfo.cvv : '',
        card_holder: props.paymentInfo ? props.paymentInfo.card_holder : '',
      }}

      validationSchema={RegistrationCardSchema}

      onSubmit={( values, { setSubmitting, setErrors }) => {
        props.onFormSubmit(values);
      }}

      render={({
                 values,
                 errors,
                 touched,
                 handleChange,
                 handleBlur,
                 handleSubmit
               }) => (
        <Fragment>
          <div className="arrow-left go-to-prev" onClick={() => props.goBack(values)} />
          <div className="Registration">
            <div className="Registration-content-popup">
              <div className="Registration-title">
                { props.title }
              </div>
              <form onSubmit={handleSubmit} className="Registration-form-popup">
                <div className="Registration-form-subheader">PAYMENT INFO</div>
                <div className="Registration-field">
                  <Field name="card_number" placeholder="Credit Card #" className="TextField" />
                  {touched.card_number && errors.card_number && <div className="Registration-error">{errors.card_number}</div>}
                  { (props.backendErrors && props.backendErrors.card_number) &&
                  <Fragment>
                    { props.backendErrors.card_number.map( msg =>
                      <div className="Registration-error">{ msg }</div>
                    )}
                  </Fragment>
                  }

                </div>
                <div className="Registration-twoFields">
                  <div className="Registration-field">
                    <Field name="exp_month" placeholder="Month (MM)" className="TextField" />
                    {touched.exp_month && errors.exp_month && <div className="Registration-error">{errors.exp_month}</div>}
                    { (props.backendErrors && props.backendErrors.exp_month) &&
                    <Fragment>
                      { props.backendErrors.exp_month.map( msg =>
                        <div className="Registration-error">{ msg }</div>
                      )}
                    </Fragment>
                    }
                  </div>
                  <div className="Registration-field">
                    <Field name="exp_year" placeholder="Year (YYYY)" className="TextField" />
                    {touched.exp_year && errors.exp_year && <div className="Registration-error">{errors.exp_year}</div>}
                    { (props.backendErrors && props.backendErrors.exp_year) &&
                    <Fragment>
                      { props.backendErrors.exp_year.map( msg =>
                        <div className="Registration-error">{ msg }</div>
                      )}
                    </Fragment>
                    }
                  </div>
                </div>

                <div className="Registration-twoFields">
                  <div className="Registration-field">
                    <Field name="cvv" placeholder="Security code" className="TextField" />
                    {touched.cvv && errors.cvv && <div className="Registration-error">{errors.cvv}</div>}
                    { (props.backendErrors && props.backendErrors.cvv) &&
                    <Fragment>
                      { props.backendErrors.cvv.map( msg =>
                        <div className="Registration-error">{ msg }</div>
                      )}
                    </Fragment>
                    }
                  </div>
                </div>

                <div className="Registration-field">
                  <Field name="card_holder" placeholder="Full name" className="TextField" />
                  {touched.card_holder && errors.card_holder && <div className="Registration-error">{errors.card_holder}</div>}
                  { (props.backendErrors && props.backendErrors.card_holder) &&
                  <Fragment>
                    { props.backendErrors.card_holder.map( msg =>
                      <div className="Registration-error">{ msg }</div>
                    )}
                  </Fragment>
                  }
                </div>

                <br />
                <br />
                { props.loading ? (
                  <RenderLoading />
                ) : (
                  <Fragment>
                    <div className="Registration-action-popup">
                      <button disabled={props.loading} type="submit" className="Button">Submit</button>
                    </div>
                    <div className="text-center mt-3">
                      <span
                        onClick={props.submitWithoutCard}
                        className="register-without-card"
                      >
                        I'll do it later
                      </span>
                    </div>
                  </Fragment>
                )}
              </form>

            </div>
          </div>
        </Fragment>
      )}
    />
  </div>
)

export default RegisterPaymentForm;