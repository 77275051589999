import superagent from "superagent";

const API_ENDPOINT = "/live_purchase_video";

const createPurchaseVideo = (
  idemp_key_customer,
  idemp_key_subscription,
  idemp_key_balance_increase,
  idemp_key_balance_decrease,
  stripe_tok,
  coupon = null,
  gift_card_amount = null,
  default_source = null,
  is_live_video = true,
  video_slug,
  amount
) => {
  return superagent
    .post(API_ENDPOINT)
    .set("Accept", "application/json")
    .send({
      stripe_tok,
      coupon,
      gift_card_amount,
      idemp_key_customer,
      idemp_key_subscription,
      idemp_key_balance_increase,
      idemp_key_balance_decrease,
      default_source,
      is_live_video,
      video_slug,
      amount,
    });
};

export default { createPurchaseVideo };
