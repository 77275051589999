import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import VideoPlayer from "./video_player";
import locked from "../../../../assets/images/icons/locked.svg";
import {Link} from "react-router-dom";
// import "react-html5video/dist/styles.css";
import play from "../../../../assets/images/icons/play_circle.svg";
import bookmark from "../../../../assets/images/icons/bookmark3.svg";
import bookmark_filled from "../../../../assets/images/icons/bookmark_filled.svg";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { addUrl } from "../../actions/redirect_url";
import { history } from "../../store/configureStore";
import { isAuthenticated } from "../../reducers/user";

class VideoPageTop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPlayBtn: true,
    };
  }

  // getVideoSrc = (video) => {
  //   const { speed } = this.props;
  //   const internetSpeed = speed ? speed : 1;
  //
  //   if (internetSpeed < 10 && internetSpeed >= 1) {
  //     return (video.mp4_480_url ? video.mp4_480_url : video.video_url);
  //   } else if (internetSpeed > 10) {
  //     return (video.video_url);
  //   } else {
  //     return (video.mp4_240_url ? video.mp4_240_url : (video.mp4_480_url || video.video_url))
  //   }
  // }

  getVideoSrcs = (video) => {
    const arr = [];

    if (video.video_url) {
      arr.push({
        // src: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4",
        src: video.video_url,
        type: 'video/mp4',
        size: 1080,
      })
    }

    if (video.mp4_720_url) {
      arr.push({
        // src: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
        src: video.mp4_720_url,
        type: 'video/mp4',
        size: 720,
      })
    }

    if (video.mp4_480_url) {
      arr.push({
        // src: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4",
        src: video.mp4_480_url,
        type: 'video/mp4',
        size: 480,
      })
    }

    if (video.mp4_240_url) {
      arr.push({
        // src: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4",
        src: video.mp4_240_url,
        type: 'video/mp4',
        size: 240,
      })
    }

    return arr;
  }

  render() {
    const { video, showVideo, videoSaved, paidVideo, propsString, isAuthenticated } = this.props;
    const { showPlayBtn } = this.state;

    return (
      <div className="video-page-top">
        { showVideo ? (
          <Fragment>
            <div className={`video-page-player ${showPlayBtn ? 'hide-controls' : ''}`}>
              <VideoPlayer
                showPlayBtn={(val) => this.setState({ showPlayBtn: val })}
                options={{
                  fullscreen: { enabled: true, fallback: true, iosNative: true },
                  quality: { default: 480, options: [1080, 720, 480, 240] }
                }}
                setClick={click => this.clickChild = click}
                sources={{
                  // poster: 'https://placekitten.com/500/300',
                  poster: video.poster_image_url || video.thumbnail_url,
                  type: 'video',
                  sources: this.getVideoSrcs(video),
                }}
              />
              { showPlayBtn &&
              <div className="content padding-lr" onClick={() => this.clickChild() }>
                <div className="gray-overlay" />
                <div className="tagline">{video.duration} MINUTES</div>
                <h2>{video.title}</h2>
                <img src={play} className="player-icon" />
              </div>
              }
            </div>
            <div className="video-attrs-container hide-for-small padding-lr">
              <div className="video-attr level">
                <div className="column-name">level</div>
                <div className="column-value">{video.level}</div>
              </div>
              { video.intensity &&
                <div className="video-attr intensity">
                  <div className="column-name">intensity</div>
                  <div className="column-value">{video.intensity}</div>
                </div>
              }
              <div className="video-attr props">
                <div className="column-name">props</div>
                <div
                  className="column-value"
                  dangerouslySetInnerHTML={{ __html: propsString }}
                />
              </div>
              <div
                className={`Button video-btn ${videoSaved ? 'saved' : ''}`}
                onClick={() => this.props.saveVideo()}
              >
                <img src={ videoSaved ? bookmark_filled : bookmark } />
                {videoSaved ? 'SAVED' : 'SAVE'}
              </div>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div className="video-page-player unsubscribed">
              {/*<img src="https://placekitten.com/300/200" className="bckg" />*/}
              <div className={paidVideo ? 'overlay green' : 'overlay blue'} />
              <LazyLoadImage
                className="bckg"
                effect="blur"
                placeholderSrc={video.thumbnail_url}
                src={video.poster_image_url}
              />
              {/*<img src={video.poster_image_url} className="bckg" />*/}
              <div className="content">
                <div className="tagline">
                  {paidVideo ? `BUY VIDEO FOR $${video.price}` : "MEMBERS ONLY" }
                </div>
                <h2 className="padding-lr">{video.title}</h2>
                <img src={locked} className="player-icon" />
              </div>
            </div>
            { paidVideo ? (
              <Link
                to={{ pathname: `/pricing` }}
                className="Button link-to-signup green"
              >
                <span className="padding-lr">purchase for ${video.price}</span>
              </Link>
            ) : (
              <div className="Button link-to-signup"
                onClick={() => {
                  this.props.addUrl(window.location.pathname, 'TvVideos');
                  history.push('/tv-signup/get-started')
                }}
              >
                <span className="padding-lr">
                  START YOUR FREE TRIAL TO UNLOCK THIS VIDEO
                  {/* <br />
                  {!isAuthenticated && <p className="video-page-link-text">Already a Subscriber? Log in</p>} */}
                </span>
              </div>
            )}
          </Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: isAuthenticated(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addUrl
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VideoPageTop);
