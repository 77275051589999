import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import MetaTagsComponent from "../../components/meta_tags_component";
import scrollToTop from "../../helpers/scrollToTop";
import RenderError from "../../components/render_error.jsx";
import CategoryPageLayout from "./category_page_layout_new";
import RenderLoading from "../../components/render_loading";
import { fetchTvDashboard } from "../../actions/tv_dashboard";

class Videos extends Component {
  state = {
    loadingDashboard: true,
    dashboardError: null
  };

  componentWillMount() {
    scrollToTop();
  }

  componentDidMount() {
    this.getTvDashboard();
  }

  getTvDashboard = () => {
    const { tvDashboard, fetchTvDashboard } = this.props;
    if (!tvDashboard) {
      this.setState({ loadingDashboard: true });
      fetchTvDashboard()
        .then(() => this.setState({ loadingDashboard: false }))
        .catch(err =>
          this.setState({ loadingDashboard: false, dashboardError: err })
        );
    } else {
      this.setState({ loadingDashboard: false });
    }
  };

  render() {
    const {
      loadingDashboard,
      dashboardError,
    } = this.state;

    const { tvDashboard } = this.props;

    if (loadingDashboard) return <RenderLoading className="centered" />;
    if (dashboardError) return <RenderError />;

    return (
      <Fragment>
        <MetaTagsComponent
          title="SKY TING | TV | Videos"
          desc="SKY TING TV Videos"
          url="tv/videos"
        />
        <div className="Page m-0 tv-category-page">
          <CategoryPageLayout
            type="library"
            category={tvDashboard.categories.find(
              c => c.type === "library"
            )}
            columnsCount={4}
            filtersNames={[
              "most recent",
              "category",
              "level",
              "teacher",
              "duration"
            ]}
          />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  tvDashboard: state.tvDashboard
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchTvDashboard
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Videos);
