import _ from "lodash";
import moment from "moment";
import { connect } from "react-redux";
import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import superagent from "superagent";
import Masonry from "react-masonry-component";

import { bookLesson } from "../actions/lesson";
import { fetchWorkshops } from "../actions/workshops";
import WorkshopPopup from "../components/lesson_booking/workshop_popup";
import RenderLoading from "../components/render_loading.jsx";
import RenderError from "../components/render_error.jsx";
import { formattedLocationName } from "../helpers/formatted_location_name";
import scrollToTop from "../helpers/scrollToTop";
import LessonApi from "../api/lesson";
import { isAuthenticated } from "../reducers/user";
import { fetchUserSchedule } from "../actions/user_schedule";
import { getBalance } from "../actions/balances";
import MetaTagsComponent from "../components/meta_tags_component";
import {history} from "../store/configureStore";

class WorkshopsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeWorkshop: null,
      policy: null,
      step: null,
      loadingLesson: null,
      bookingError: null,
      showWaitlist: false
    };
  }

  componentWillMount() {
    history.push(`/tv/workshops`);

    // const workshopId = getParamByName("workshop", "string");
    // if (workshopId) {
    //   this.getWorkshopById(workshopId);
    // }
  }

  componentDidMount() {
    scrollToTop();
    this.props.fetchWorkshops();

    return superagent
      .get("/policy?policy_type=workshop")
      .set("Accept", "application/json")
      .send()
      .then(res =>
        this.setState({
          policy: res.body
        })
      );
  }

  renderPopup = (workshop) => {
    const id = workshop.additional ? workshop.additional.slug : null;
    if (id) {
      this.props.history.push(`?workshop=${id}`);
    }

    this.setState({ activeWorkshop: workshop });
  };

  closePopup = () => {
    this.setState({
      activeWorkshop: null,
      step: null,
      bookingError: null,
      showWaitlist: false,
    });

    this.props.history.push({
      pathname: window.location.pathname,
      search: ""
    });
  };

  bookLesson = (workshop, joinWaitlist = false) => {
    const { isAuthenticated, fetchUserSchedule, getBalance } = this.props;
    this.setState({ bookingError: null });

    if (!isAuthenticated) {
      this.setState({ step: "login" });
      this.renderPopup(workshop);
    } else {
      this.setState({ loadingLesson: workshop.main.mindbody_identifier });
      LessonApi.bookLesson(workshop.main.mindbody_identifier, joinWaitlist)
        .then(res => {
          this.setState({
            loadingLesson: null,
            showWaitlist: false
          });

          fetchUserSchedule();
          getBalance();
        })
        .catch(error => {
          if (
            error.response.body &&
            error.response.body.message === "no balance"
          ) {
            this.setState({
              step: "payment",
              loadingLesson: null,
              showWaitlist: false,
            });
          } else if (
            error.response.body &&
            error.response.body.message === "full class"
          ) {
            this.setState({
              step: "lesson",
              showWaitlist: true,
              loadingLesson: null
            });
          } else {
            this.setState({
              step: "lesson",
              bookingError: error.response.body,
              loadingLesson: null,
              showWaitlist: false
            });
          }
          this.renderPopup(workshop);
        });
    }
  };

  render() {
    const { workshops, error, isFetching } = this.props;
    const {
      activeWorkshop,
      policy,
      step,
      loadingLesson,
      bookingError,
      showWaitlist
    } = this.state;

    const masonryOptions = {
      transitionDuration: 0
    };

    if (error) return <RenderError error={error} />;

    const childElements = workshops.map((workshop, index) => {
      return (
        <div
          className="Workshop-card-wrapper column small-12 medium-6 large-6"
          key={workshop.main.id}
        >
          <div className="Workshop-card" key={workshop.main.id}>
            <div className="Workshops-header">
              {moment.utc(workshop.main.start_date_time).format("dddd MMM D")}
            </div>
            {workshop.additional &&
              workshop.additional.image &&
              workshop.additional.image.url && (
                <div
                  className="Workshop-image-wrapper cursor-pointer"
                  id={`image-wrapper-${index}`}
                  onClick={() => this.renderPopup(workshop)}
                >
                  <img
                    src={workshop.additional.image.url}
                    className="Workshop-image"
                    alt="Workshop image"
                  />
                </div>
              )}
            <h3
              className="Workshops-header cursor-pointer"
              onClick={() => this.renderPopup(workshop)}
            >
              {workshop.main.class_description.name}
            </h3>
            <div className="Info">
              <p>
                <span className="Bold">W/&nbsp;</span>
                <span>{workshop.main.staff.name}</span>
              </p>
              <p>
                <span className="Bold">
                  &nbsp;@ {formattedLocationName(workshop.main.location.name)}
                </span>
              </p>
              <p>
                {moment.utc(workshop.main.start_date_time).format("h:mm A")} -{" "}
                {moment.utc(workshop.main.end_date_time).format("h:mm A")}
              </p>
            </div>

            {/*{(loadingLesson && (loadingLesson === workshop.main.mindbody_identifier)) ? (*/}
              {/*<button*/}
                {/*className="Button Bottom-btn"*/}
                {/*disabled*/}
              {/*>*/}
                {/*<span>{showWaitlist ? "Wait..." : "Booking..."}</span>*/}
              {/*</button>*/}
            {/*) : (*/}
              {/*<BookLessonButton*/}
                {/*label={<div>RESERVE A SPOT</div>}*/}
                {/*reservedLabelText="Reserved"*/}
                {/*onClick={() => this.bookLesson(workshop)}*/}
                {/*lessonId={workshop.main.mindbody_identifier}*/}
                {/*className="Button Bottom-btn"*/}
              {/*/>*/}
            {/*)}*/}

            <div
              className="Bottom-btn Button"
              onClick={() => this.renderPopup(workshop)}
            >
              LEARN MORE
            </div>
          </div>
        </div>
      );
    });

    return (
      <Fragment>
        <MetaTagsComponent
          title={activeWorkshop && activeWorkshop.main.name ? activeWorkshop.main.name : "SKY TING | Programs | Workshops"}
          desc={activeWorkshop && activeWorkshop.additional.description ? activeWorkshop.additional.description : "SKY TING workshops"}
          url={activeWorkshop ? `workshops?workshop=${activeWorkshop.additional.slug}` : "workshops"}
          image={activeWorkshop && activeWorkshop.additional.image ? activeWorkshop.additional.image.url : null}
        />
        <div className="Page Workshops">
          <div className="Page-content">
            <h2 className="Page-header">WORKSHOPS</h2>

            {isFetching ? (
              <RenderLoading />
            ) : (
              <Fragment>
                {_.isEmpty(workshops) ? (
                  <div className="Empty-result">NO WORKSHOPS</div>
                ) : (
                  <Fragment>
                    <Masonry
                      className="Workshops-columns"
                      elementType="div"
                      options={masonryOptions}
                      disableImagesLoaded={false}
                      updateOnEachImageLoad={false}
                    >
                      {childElements}
                    </Masonry>
                    {activeWorkshop && (
                      <WorkshopPopup
                        workshop={activeWorkshop}
                        policy={policy}
                        closeWorkshopPopup={() => this.closePopup()}
                        step={step}
                        bookingError={bookingError}
                        showWaitlist={showWaitlist}
                      />
                    )}
                  </Fragment>
                )}
              </Fragment>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  error: state.workshops.error,
  workshops: state.workshops.workshops,
  isFetching: state.workshops.isFetching,
  isAuthenticated: isAuthenticated(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchWorkshops,
      bookLesson,
      fetchUserSchedule,
      getBalance
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkshopsPage);
