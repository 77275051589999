import superagent from 'superagent';

const API_ENDPOINT = 'users/waitlist';

const removeFromWaitlist = (waitlist_id) => {
  return superagent
    .delete(`${API_ENDPOINT}/${waitlist_id}`)
    .set('Accept', 'application/json')
    .send();
};

export default { removeFromWaitlist };
