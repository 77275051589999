import React, { Component } from "react";
import { Link } from "react-router-dom";
import MetaTagsComponent from "../components/meta_tags_component";
import TvCollectionsGrid from "../components/tv_collections_grid";
import scrollToTop from "../helpers/scrollToTop";
import arrowLong from "../../../assets/images/icons/arrow_long.png";

export default class TvCollections extends Component {
  componentWillMount() {
    scrollToTop();
  }

  renderCollection = (collection) => {
    const count = collection.videos_count;
    return (
      <Link to={`/tv/collections/${collection.id}`} className="tv-collection-card">
        <div
          className="tv-collection-image"
          style={{ backgroundImage: `url(${collection.images[0].url})` }}
        />
        <div className="tv-collection-info">
          <div>{collection.name}</div>
          <div className="vid-count">{count} {count === 1 ? 'video' : 'videos'}</div>
        </div>
      </Link>
    );
  }

  render() {
    return (
      <div className="Page m-0 tv-category-page tv-collections padding-lr">
        <MetaTagsComponent
          title="SKY TING | TV | Collections"
          desc="SKY TING TV Collections"
          url="tv/collections"
        />

        <h2 className="Page-header font-weight-normal">
          <Link to="/tv">
            <img
              src={arrowLong}
              className="back-btn"
            />
          </Link>
          COLLECTIONS
        </h2>
        <h3 className="page-subtitle"></h3>
        <h4 className="tv-collections-grid-head">
          ALL COLLECTIONS
        </h4>
        <TvCollectionsGrid />
      </div>
    );
  }
}
