import { dropCheckoutTotalCart } from './checkout_total';

export const OPEN_ORDER_POPUP = 'OPEN_ORDER_POPUP'
export const CLOSE_ORDER_POPUP = 'CLOSE_ORDER_POPUP'
export const CHECKOUT_SHOP_INITIAL = 'CHECKOUT_SHOP_INITIAL'

export const openOrderPopup = () => (dispatch, getState) => {

  const { cart } = getState();

  if (cart.items.length === 0) return;

  dispatch({ type: OPEN_ORDER_POPUP });
}

export const closeOrderPopup = () => (dispatch) => {
  dispatch({ type: CLOSE_ORDER_POPUP })
  dispatch({ type: CHECKOUT_SHOP_INITIAL })
  dropCheckoutTotalCart()(dispatch)
}
