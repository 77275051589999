import React, { Component, Fragment } from 'react'
import moment from 'moment/moment'
import classNames from 'classnames'
import Slider from 'react-slick'
import ToggleCard from './toggle_card.jsx'
import Popup from './popup.jsx';
import BookLessonButton from '../components/book_lesson_button.jsx';
import { programTeachersNames } from "../helpers/program_teachers_names";

export default class MentorshipMobilePopup extends Component {
  constructor(props) {
    super(props)

    this.state = {
      descriptionIsOpen: false,
    };
  }

  toggleFullDescription = () => {
    this.setState({ descriptionIsOpen: !this.state.descriptionIsOpen })
  };

  renderApplyButton = () => {
    const { mentorship: { additional }, openLogin, isAuthenticated } = this.props

    const onClickAction = (!isAuthenticated) ? openLogin : null
    const hasLink = additional.mbo_link || additional.type_form

    if (!hasLink) return (<div />)
    const link = additional.mbo_link ? additional.mbo_link : `mentorship/${additional.slug}`
    const buttonLink = (isAuthenticated && link) ? link : null

    return (
      <BookLessonButton
        label="APPLY HERE"
        reservedLabelText="Reserved"
        link={buttonLink}
        lessonId={additional.mindbody_identifier}
        onClick={onClickAction}
        className="Button"
      />
    )
  }

  render() {
    const {
      mentorship: { main, additional, photos, mentors },
      policy,
      isOpen,
      onClose,
    } = this.props

    const DescriptionTextClass = classNames({
      'Description-text LargeBodyText': true,
      'is-open': this.state.descriptionIsOpen,
    });

    return (
      <div className="Program-mobile-popup">
        <Popup
          key={main.id}
          id={additional.slug}
          isOpen={isOpen}
          onClose={onClose}
        >
          <div className="Program-card Program-popup">
            <div className="Program-popup-header">{main.class_description.name}</div>
            <div className="Info Program-popup-info">
              <p>
                <span className="Bold">WHEN/&nbsp;</span>
                <span>
                  {moment.utc(main.start_date_time).format('MMM')} - {moment.utc(main.end_date_time).format('MMM YYYY')}
                </span>
              </p>
              <p>
                <span className="Bold">W/&nbsp;</span>
                { (additional && additional.teacher_header) ? (
                  <span>{additional.teacher_header}</span>
                ) : (
                  <Fragment>
                    { mentors.length > 0 ? (
                      programTeachersNames(mentors).map(t => <span>{t}</span>)
                    ) : (
                      <span>{main.staff.name}</span>
                    )}
                  </Fragment>
                )}
              </p>
            </div>
            {this.renderApplyButton()}

            {(photos && photos.length > 0) &&
              <div className="Program-image-slider Image-slider">
                <Slider
                  dots={false}
                  infinite={true}
                  speed={500}
                  fade={true}
                  cssEase="ease-in"
                  arrows={true}
                >
                  {photos.map((photo, index) => (
                    <div key={index} className="Slider-item-image">
                      <img src={photo.image_url} alt="SKY TING" />
                    </div>
                  ))}
                </Slider>
              </div>
            }
            { additional.description &&
              <Fragment>
                <div
                  className={DescriptionTextClass}
                  dangerouslySetInnerHTML={{ __html: additional.description }}
                />
                {this.state.descriptionIsOpen ? (
                  <div className="Toggle-description-btn" onClick={this.toggleFullDescription} >LESS...</div>
                ) : (
                  <div className="Toggle-description-btn" onClick={this.toggleFullDescription} >MORE...</div>
                )}
              </Fragment>
            }

            <div className="Expanded-cards">
              {additional.includes &&
                <ToggleCard
                  key={`includes-${main.id}`}
                  header="INCLUDES"
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: additional.includes }}
                    className="Includes-container"
                  />
                </ToggleCard>
              }
              { mentors && mentors.length > 0 &&
                <ToggleCard
                  key={`mentors-${main.id}`}
                  header="SKY TING MENTORS"
                >
                  <Slider
                    dots={false}
                    infinite={true}
                    speed={300}
                    cssEase={'ease-in'}
                    arrows={true}
                    className="Program-block-slider Image-slider"
                    slidesToScroll={1}
                  >
                    {mentors.map(mentor => (
                      <div key={mentor.id} className="Card Mentor-card">
                        <div className="Card-image">
                          <div className="Image-wrapper">
                            <img src={mentor.photo.url ? mentor.photo.url : mentor.image_url} alt={mentor.name} />
                          </div>
                        </div>
                        <div className="Mentor-card-info">
                          <a href={`/teachers?teacher=${mentor.slug}`} title="Visit teachers page">
                            <div className="Button Button--secondary">{mentor.name}</div>
                          </a>
                          {mentor.short_info &&
                            <div className="info">{mentor.short_info}</div>
                          }
                          { mentor.instagram ? (
                            <a href={`https://www.instagram.com/${mentor.instagram.replace('@', '')}`} target="_blank" title="Go to Instagram">
                              <div>@{mentor.instagram.replace('@', '')}</div>
                            </a>
                          ) : (
                            <Fragment>
                              { mentor.website &&
                              <div>
                                <a href={mentor.website} target="_blank" title="Visit mentor's website">{mentor.website}</a>
                              </div>
                              }
                            </Fragment>
                          )}
                        </div>
                      </div>
                    ))}
                  </Slider>
                </ToggleCard>
              }
              { additional.dates &&
                <ToggleCard
                  key={`workshop-dates-${main.id}`}
                  header="WORKSHOP DATES"
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: additional.dates }}
                    className="Mentorship-dates"
                  />
                </ToggleCard>
              }
              { additional.prerequisites &&
                <ToggleCard
                  key={`prerequisites-${main.id}`}
                  header="PREREQUISITES"
                >
                  <div className="Prerequisites">
                    <div dangerouslySetInnerHTML={{ __html: additional.prerequisites }} />
                  </div>
                </ToggleCard>
              }
              {additional.price &&
                <ToggleCard
                  key={`price-${main.id}`}
                  header="PRICE"
                >
                  <div dangerouslySetInnerHTML={{ __html: additional.price }} />
                </ToggleCard>
              }
            </div>

            {/*<div className="Discount-container">*/}
              {/*<div className="Discount-inner-container">*/}
                {/*<div className="SubHeadline">Early-Bird Discount</div>*/}
                {/*<div className="BodyText">$100 off if registered by August 15, 2016</div>*/}
              {/*</div>*/}
            {/*</div>*/}
            {this.renderApplyButton()}
            { policy &&
              <div className="Policy-text">
                <div className="SubHeadline">DEPOSIT POLICY</div>
                <div className="SmallBodyText">{policy.text}</div>
              </div>
            }
          </div>
        </Popup>
      </div>
    )
  }
}
