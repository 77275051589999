import superagent from "superagent";

export const FETCH_PROMO_SPOTS_SUCCESS = 'FETCH_PROMO_SPOTS_SUCCESS';

const shouldUpdatePromoSpots = (updated_at) => {
  if (!updated_at) return true;
  const seconds = (new Date().getTime() - updated_at.getTime()) / 1000;
  return (seconds > 3600);
}

export const fetchShopPromoSpots = () => (dispatch, getState) => {
  const state = getState();

  return new Promise(async(resolve, reject) => {
    if (shouldUpdatePromoSpots(state.shopPromoSpots.updated_at)) {
      await superagent
        .get("/shop_promo_spots")
        .set("Accept", "application/json")
        .send()
        .then((res) => {
          dispatch({
            type: FETCH_PROMO_SPOTS_SUCCESS,
            slides: res.body
          });
          resolve(true);
        })
        .catch((err) => {
          reject(err.response.body)
        });
    } else {
      resolve(true);
    }
  });
};