import React, { Component } from "react";
import scrollToTop from "../helpers/scrollToTop";
import MetaTagsComponent from "../components/meta_tags_component";
import superagent from "superagent";
import RenderLoading from "../components/render_loading";

export default class TermsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: null,
      loading: true
    };
  }

  componentDidMount() {
    scrollToTop();
    const url = this.props.match.path;
    this.getText(url);
  }

  getText = (url) => {
    this.setState({ loading: true });

    return superagent
      .get(url)
      .set("Accept", "application/json")
      .send()
      .then(res =>
        this.setState({
          text: res.body ? res.body.text : null,
          loading: false
        })
      )
      .catch(error => {
        this.setState({ loading: false });
      });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.path !== this.props.match.path) {
      this.getText(nextProps.match.path);
    }
  }

  render() {
    const { text, loading } = this.state;

    return (
      <div className="Page Privacy-page">
        <MetaTagsComponent
          title="SKY TING | Terms & Conditions"
          desc="SKY TING terms and conditions"
          url="terms"
        />
        <div className="Page-content">
          {loading ? (
            <div className="mt-5">
              <RenderLoading />
            </div>
          ) : (
            <div dangerouslySetInnerHTML={{ __html: text }} />
          )}
        </div>
      </div>
    );
  }
}
