import React, { Component } from "react";
import Checked from "./../../../assets/images/icons/checked.svg";
import CheckedWhite from "./../../../assets/images/icons/checked_white.svg";

class Checkbox extends Component {
  render() {
    const { label, checked, onClick, isWhite } = this.props;

    return (
      <div className="checkbox">
        <div className="box-container">
          {checked ? (
            <img
              src={isWhite ? CheckedWhite : Checked}
              onClick={onClick}
              width="20"
              alt="checked"
            />
          ) : (
            <div
              className={`unchecked-box ${isWhite ? "white" : ""}`}
              onClick={onClick}
            />
          )}
        </div>
        {label && <span className="label">{label}</span>}
      </div>
    );
  }
}

export default Checkbox;
