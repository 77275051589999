import {
	OPEN_MAILCHIMP,
	DESELECT_MAILCHIMP,
	FETCH_MAILCHIMP
} from '../actions/mail_chimp';

const INITIAL_STATE = {
	isMailChimpPopupOpen: true,
	isFetching: false
};

const mailChimp = (state = INITIAL_STATE, action) => {
	switch ("Action",action.type) {
		case FETCH_MAILCHIMP:
        return {
          ...state,
          isFetching: true,
        };
		case OPEN_MAILCHIMP:
			return {
				...state,
				isMailChimpPopupOpen: action.openMailChimpPopup,
				isFetching: false
			};
		case DESELECT_MAILCHIMP:
			return {
				...state,
				isMailChimpPopupOpen: false,
				isFetching: false
			};
		default:
			return state;
	}
};

export default mailChimp;