import { connect } from 'react-redux';
import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import superagent from 'superagent'

import { fetchTrainings } from '../actions/trainings';
import { isAuthenticated } from '../reducers/user';
import { selectProgram, deselectProgram } from '../actions/active_program'

import TrainingCard from '../components/training_card';
import RenderLoading from '../components/render_loading.jsx';
import RenderError from '../components/render_error.jsx';
import TrainingMobilePopup from '../components/training_mobile_popup.jsx'
import scrollToTop from "../helpers/scrollToTop";
import MetaTagsComponent from "../components/meta_tags_component";
import {getParamByName} from "../helpers/get_param_by_name";
import {animateScroll as scroll} from "react-scroll/modules";

var timeout;

class TrainingsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      policy: null,
      isMobile: false,
    };
  }

  updateDimensions = () => {
    this.setState({ isMobile: window.matchMedia('(max-width: 800px)').matches })
  }

  componentWillMount() {
    this.props.fetchTrainings();

    const trainingId = getParamByName('program', 'string')
    if (trainingId) {
      this.waitForElementToDisplay(trainingId);
    }
    this.updateDimensions()
  }

  waitForElementToDisplay = (id) => {
    const el = document.getElementById(id);
    if (el !== null) {
      const positionY = el.offsetTop;
      scroll.scrollTo(positionY - 87);
      clearTimeout(timeout);

      if (id) {
        const training = this.props.trainings.find(t => t.slug === id);
        if (training) {
          this.props.selectProgram(training)
        }
      }
    }
    else {
      if (!this.props.isFetching && this.props.trainings.length > 0) {
        const training = this.props.trainings.find(t => t.slug === id);
        if (!training) {
          clearTimeout(timeout);
        } else {
          timeout = setTimeout(() => {
            this.waitForElementToDisplay(id);
          }, 500);
        }
      } else {
        timeout = setTimeout(() => {
          this.waitForElementToDisplay(id);
        }, 500);
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions)
    this.props.deselectProgram();
    clearTimeout(timeout);
  }

  componentDidMount() {
    const trainingId = getParamByName('program', 'string')

    if (!trainingId) {
      scrollToTop();
    }
    window.addEventListener('resize', this.updateDimensions)

    return superagent
      .get('/policy?policy_type=training')
      .set('Accept', 'application/json')
      .send().then(res =>
        this.setState({
          policy: res.body,
        }));
  }

  render() {
    const {
      trainings,
      error,
      isFetching,
      isAuthenticated,
      selectProgram,
      deselectProgram,
      activeProgram,
    } = this.props

    const { isMobile, policy } = this.state

    if (error) return <RenderError error={error} />

    return (
        <Fragment>
          <MetaTagsComponent
            title={activeProgram ? activeProgram.class_description.name : "SKY TING | Programs | Courses"}
            desc={activeProgram ? activeProgram.description : "SKY TING courses"}
            url={activeProgram ? `courses?program=${activeProgram.slug}` : "courses"}
            image={activeProgram && activeProgram.photos.length > 0 ? activeProgram.photos[0].image_url : null}
          />
          <div className="Page">
            <div className="Page-content">
              <h2 className="Page-header">COURSES</h2>
              { isFetching ? (
                <RenderLoading />
              ) : (
                <Fragment>
                  { _.isEmpty(trainings) ? (
                    <div className="Empty-result">NO COURSES</div>
                  ) : (
                    <Fragment>
                      <div>
                        {trainings.map(training => (
                          <TrainingCard
                            training={training}
                            key={training.id}
                            policy={policy}
                            isAuthenticated={isAuthenticated}
                            selectProgram={selectProgram}
                            deselectProgram={deselectProgram}
                            activeProgram={activeProgram}
                          />
                        ))};
                      </div>
                      { activeProgram && isMobile &&
                        <TrainingMobilePopup
                          isOpen
                          training={activeProgram}
                          policy={policy}
                          onClose={() => deselectProgram()}
                          isAuthenticated={isAuthenticated}
                        />
                      }
                    </Fragment>
                  )}
                </Fragment>
              )}
            </div>
          </div>
        </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  error: state.trainings.error,
  trainings: state.trainings.trainings,
  isFetching: state.trainings.isFetching,
  isAuthenticated: isAuthenticated(state),
  activeProgram: state.activeProgram,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchTrainings,
      selectProgram,
      deselectProgram,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TrainingsPage);