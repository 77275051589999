import Api from '../api/balance';

import { isFetching } from '../reducers/balance';

export const GET_BALANCE = 'GET_BALANCE';
export const GET_BALANCE_ERROR = 'GET_BALANCE_ERROR';
export const GET_BALANCE_SUCCESS = 'GET_BALANCE_SUCCESS';
export const SET_INNER_BALANCE = 'SET_INNER_BALANCE';

export const setInnerBalance = amount => (dispatch) => {
  dispatch({ type: SET_INNER_BALANCE, amount });
};

const getBalanceSuccess = payload => (dispatch) => {
  dispatch({ type: GET_BALANCE_SUCCESS, payload });
};

export const getBalance = () => (dispatch, getState) => {
  if (isFetching(getState())) {
    return;
  }

  dispatch({ type: GET_BALANCE });

  Api.getBalance()
    .then(res => dispatch(getBalanceSuccess(res.body)))
    .catch(error => dispatch({ type: GET_BALANCE_ERROR, error }));
};
