import superagent from 'superagent';

const API_ENDPOINT = '/users/purchases';

const fetchUserPurchases = (period, time_zone) => {
  return superagent
    .get(API_ENDPOINT)
    .set('Accept', 'application/json')
    .query({ period: period, time_zone: time_zone });
};

export default { fetchUserPurchases };
