import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchServicesFor } from '../../actions/services';
import { checkoutTotal } from "../../actions/checkout_total";
import { servicePriceWithDiscount } from '../../helpers/service_price_with_discount';
import RenderError from '../render_error';
import DropdownSelect from '../dropdown_select.jsx';
import Loading from './../../../../assets/images/icons/yinyang_icon.png';

class LessonPaymentPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedService: null,
    }
  }

  componentWillMount() {
    const { lessonId, fetchServicesFor } = this.props;

    fetchServicesFor(lessonId);
  }

  openBilling = (service) => {
    if (!service.contract) {
      this.props.checkoutTotal([{
        mindbody_identifier: service.id,
        quantity: 1,
      }], 'Service')
    }
    this.props.changeStep('billing');
    this.props.backBtn(true);
    this.props.selectService(this.state.selectedService)
  }

  getValues = (service) => {
    const price = servicePriceWithDiscount(
      service.online_price, service.membership_discount, this.props.userMembership
    )
    return ({ name: `${service.name} - $${price}`, id: service.id })
  }

  onFiltersChange = (event) => {
    const { value } = event.target

    if (value) {
      this.setState({ selectedService: this.findService(value) });
    } else {
      this.setState({ selectedService: null });
    }
  }

  findService = (service_id) => {
    return this.props.services.services.find(s => s.id === service_id)
  }

  loading = () => {
    return (
      <div className="inline-loading">
        <img src={Loading} height="21" width="21" alt="Loading" />
        <div>LOADING...</div>
      </div>
    )
  }

  render() {
    const {
      services,
      servicesError,
    } = this.props;

    const { selectedService } = this.state;

    if (servicesError) return <RenderError error={servicesError} />;
    const tingsOptions = services.services.map(s => this.getValues(s))

    return (
      <Fragment>
        <div className="PaymentPopup">
          <h2 className="PaymentPopup-title">PURCHASE</h2>
          <div className="PaymentPopup-text">
            You're almost there!
          </div>
          <div className="PaymentPopup-input">
            { services.isFetching ? (
              <div className="inline-loading">
                <img src={Loading} height="21" width="21" alt="Loading" />
                <div>LOADING...</div>
              </div>
            ) : (
              <DropdownSelect
                options={tingsOptions}
                onChange={this.onFiltersChange}
                optionLabel="name"
                optionId="id"
                placeholder="select option"
                name="service"
                filters={selectedService ? { service: selectedService.id } : {}}
                isCollapse
              />
            )}
          </div>
          <button
            onClick={() => this.openBilling(selectedService)}
            className="SmallButton"
            disabled={!selectedService}
          >
            Confirm
          </button>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activeLesson: state.activeLesson,
    services: state.services,
    servicesError: state.services.error,
    userMembership: state.userMembership.membership,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchServicesFor,
      checkoutTotal,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(LessonPaymentPopup);
