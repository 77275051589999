import React, { Component } from "react";
import Select from "react-select";

export default class ReactSelectComponent extends Component {
  handleChange = selectedOption => {
    this.props.setValue(selectedOption);
  };

  render() {
    const {
      options,
      optionLabel,
      optionValue,
      selectedValue,
      disabled,
      placeholder,
      hideSearch
    } = this.props;

    return (
      <Select
        value={selectedValue}
        onChange={this.handleChange}
        options={options}
        getOptionLabel={option => `${option[optionLabel]}`}
        getOptionValue={option => `${option[optionValue]}`}
        isDisabled={disabled}
        placeholder={placeholder}
        className="custom-dropdown-select"
        classNamePrefix="custom-dropdown-select"
        isSearchable={!hideSearch}
        // menuIsOpen
      />
    );
  }
}
