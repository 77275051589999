import {
  FETCH_USER_PURCHASES,
  FETCH_USER_PURCHASES_ERROR,
  FETCH_USER_PURCHASES_SUCCESS,
} from '../actions/user_purchases';

const INITIAL_STATE = {
  purchases: [],
  error: null,
  isFetching: false,
};

const userPurchases = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_USER_PURCHASES:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_USER_PURCHASES_ERROR:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    case FETCH_USER_PURCHASES_SUCCESS:

      return {
        ...state,
        purchases: action.purchases,
        error: null,
        isFetching: false,
      };
    default:
      return state;
  }
};

export const isFetching = state => state.userPurchases.isFetching;

export default userPurchases;