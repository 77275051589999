import React, { Component, Fragment } from "react";
import Script from 'react-load-script';
import { StripeProvider, Elements } from "react-stripe-elements";
import StripeForm from "./stripe_form";

export default class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stripe: null,
    };
  }

  setStripeKey = () => {
    const strKey = gon.stripe_pub_key;
    this.setState({ stripe: window.Stripe(strKey) });
  }

  renderNewCardForm = () => {
    const {
      user,
      blockPopupClose,
      purchaseGiftCard,
      loading,
      error,
      disableSubmitBtn,
      checkEmailIsValid,
      pageContent
    } = this.props;

    return (
      <Fragment>
        <Script
          url="https://js.stripe.com/v3/"
          attributes={{ id: 'stripe-js' }}
          onLoad={() => this.setStripeKey()}
        />
        <StripeProvider stripe={this.state.stripe}>
          <Elements>
            <StripeForm
              user={user}
              onCardSubmit={token => purchaseGiftCard(token)}
              loading={loading}
              error={error}
              disabled={disableSubmitBtn}
              blockPopupClose={blockPopupClose}
              checkEmailIsValid={checkEmailIsValid}
              pageContent={pageContent}
              changeIdempKeys={this.props.changeIdempKeys}
            />
          </Elements>
        </StripeProvider>
      </Fragment>
    );
  };

  render() {
    const { pageContent } = this.props;

    return (
      <div className="">
        <div className="payment-bottom">
          {this.renderNewCardForm()}

          { pageContent && pageContent.terms_text &&
            <div
              className="gift-card-terms text-center"
              dangerouslySetInnerHTML={{ __html: pageContent.terms_text }}
            />
          }
        </div>
      </div>
    );
  }
}
