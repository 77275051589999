import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { checkPromoCode } from "../actions/promo_code";

class PromoCodeInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      promoCode: "",
      promoAccepted: null,
      isFetching: false
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const { promoAccepted } = this.state;
    if (promoAccepted !== null) {
      this.setState({ promoAccepted: null });
    }

    this.setState({ promoCode: event.target.value });
  }

  checkPromo = (promoCode, products, type) => {
    this.setState({ isFetching: true });

    const { isContract } = this.props;

    this.props
      .checkPromoCode(promoCode, products, type, isContract)
      .then(res => {
        this.setState({
          isFetching: false,
          promoAccepted: true
        });
        this.props.setPromoCode(promoCode);
      })
      .catch(err => {
        this.setState({
          isFetching: false,
          promoAccepted: false
        });
      });
  };

  sendPromoCode = () => {
    const { promoCode } = this.state;
    const {
      cartItems,
      activeService,
      isShop,
      activeLessonService,
      activePricingServise,
    } = this.props;

    const service = activeLessonService || activePricingServise || activeService;

    if (isShop) {
      const products = cartItems.map(item => ({
        mindbody_identifier: item.mindbody_identifier,
        quantity: item.quantity
      }));
      this.checkPromo(promoCode, products, "Product");
    } else {
      const products = [
        {
          mindbody_identifier: service.id,
          quantity: 1
        }
      ];

      this.checkPromo(promoCode, products, "Service");
    }
  };

  render() {
    const {
      promoCode,
      promoAccepted,
      isFetching,
    } = this.state;
    const { user, isShop } = this.props;

    if (!user) return <div />;
    if (!user.card_last_four) return <div />;

    return (
      <div className="pb-3">
        {!isShop && (
          <div
            className="Enter-promo-code"
          >
            ADD CODE
          </div>
        )}

        <div className="d-flex promo-code-container">
          <div className="Promo-code-input">
            <input
              disabled={promoAccepted}
              value={promoCode}
              onChange={this.handleChange}
              placeholder="ADD COUPON CODE..."
            />
            <span className="input-inner-el">&#127873;</span>
          </div>

          {promoAccepted ? (
            <div className="Button apply-promo-btn success">APPLIED</div>
          ) : (
            <button
              className="Button apply-promo-btn"
              onClick={this.sendPromoCode}
              disabled={promoCode === "" || isFetching}
            >
              APPLY
            </button>
          )}
        </div>
        {isFetching ? (
          <div className="checking-promo mt-2">WAIT PLEASE...</div>
        ) : (
          <Fragment>
            {promoAccepted === false ? (
              <div className="failed-promo mt-2">Coupon code is invalid</div>
            ) : (
              <div className="mt-2">&nbsp;</div>
            )}
          </Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  cartItems: state.cart.items,
  checkoutTotalCart: state.checkoutTotal.cart,
  activeService: state.activeService
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      checkPromoCode
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PromoCodeInput);
