import {
  LOGIN_POPUP_CLOSE,
  LOGIN_POPUP_OPEN,
  LOGIN_POPUP_OPEN_WITH_ERROR,
} from '../actions/login_popup'

const INITIAL_STATE = {
  show: false,
  error: null,
  mode: 'login',
};

const loginPopup = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_POPUP_OPEN:
      return {
        ...state,
        show: true,
        mode: action.mode,
      };
    case LOGIN_POPUP_CLOSE:
      return INITIAL_STATE;
    case LOGIN_POPUP_OPEN_WITH_ERROR:
      return {
        ...state,
        show: true,
        error: action.error,
      };
    default:
      return state;
  }
};

export default loginPopup;
