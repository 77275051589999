import superagent from 'superagent'

const API_ENDPOINT = '/sauna'

const fetchSaunas = (date, time_zone) => {
  return superagent
    .get(API_ENDPOINT)
    .set('Accept', 'application/json')
    .query({ date: date, time_zone: time_zone })
}

export default { fetchSaunas }