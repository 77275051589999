import React, { Component } from "react";
import superagent from "superagent";
import Slider from "react-slick";
import MetaTagsComponent from "../components/meta_tags_component";
import scrollToTop from "../helpers/scrollToTop";
import RenderLoading from "../components/render_loading";
import RenderError from "../components/render_error";
import VideosGrid from "../components/videos_grid";

export default class TvCollectionPage extends Component {
  state = {
    isLoading: true,
    collection: null,
    error: null,
  };

  componentWillMount() {
    scrollToTop();
  }

  componentDidMount() {
    if (!this.state.collection) {
      this.getTvCollection(this.props.match.params.id);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.id !== nextProps.match.params.id) {
      scrollToTop();
      this.getTvCollection(nextProps.match.params.id);
    }
  }

  getTvCollection = (id) => {
    this.setState({ isLoading: true });

    return superagent
      .get(`/tv/collections/${id}`)
      .set("Accept", "application/json")
      .send()
      .then(res => {
        this.setState({
          collection: res.body,
          isLoading: false
        });
      })
      .catch(error => {
        this.setState({ error: error.response.body, isLoading: false });
      });
  };

  onVideoPlay = () => {
    this.slider.slickPause();
  }

  onVideoEnd = () => {
    this.slider.slickPlay();
  };

  pauseVideo = sliderIndex => {
    let el = document.getElementById(`videoRef-${sliderIndex}`);
    if (el) {
      el.pause();
    }
  };

  playVideo = sliderIndex => {
    let el = document.getElementById(`videoRef-${sliderIndex}`);
    if (el) {
      el.play();
    }
  };

  render() {
    const { isLoading, collection, error } = this.state;

    if (isLoading) return <RenderLoading className="centered" />;
    if (error || !collection) return <RenderError />;

    return (
      <div className="Page m-0 tv-category-page tv-collection-page">
        <MetaTagsComponent
          title="SKY TING | TV | Collections"
          desc="SKY TING TV Collections"
          url="tv/collections"
        />
        <div className="tv-collection-top d-flex padding-lr">
          <div className="tv-collection-top-block">
            <div className="product-carousel with-large-dots">
              { collection.images.length > 0 ? (
                <Slider
                  dots
                  infinite
                  speed={1000}
                  swipeToSlide
                  arrows={false}
                  ref={slider => (this.slider = slider)}
                  beforeChange={currentIndex => this.pauseVideo(currentIndex)}
                  afterChange={currentIndex => this.playVideo(currentIndex)}
                >
                  {collection.images.map((image, index) => (
                    <div className="product-carousel-inner" key={image.id}>
                      {image.video_url ? (
                        <video
                          autoPlay={index === 0}
                          muted
                          poster={image.image_url}
                          playsInline
                          id={`videoRef-${index}`}
                          onPlay={() => this.onVideoPlay()}
                          onEnded={() => this.onVideoEnd()}
                          className="product-image"
                        >
                          <source src={image.video_url} type={image.video_type === 'video/quicktime' ? 'video/mp4' : image.video_type} />
                          <p>
                            Your user agent does not support the HTMLs Video element
                          </p>
                        </video>
                      ) : (
                        <img className="product-image" src={image.image_url} alt="SKY TING" />
                      )}
                    </div>
                  ))}
                </Slider>
              ) : (
                <div />
              )}
            </div>
          </div>
          <div className="tv-collection-top-block info">
            <h2>{collection.name}</h2>
            {collection.level && <div className="level"><b>LEVEL:</b> {collection.level}</div>}
            <div className="line-bottom" />
            <p>{collection.description}</p>
          </div>
        </div>
        <div className="tv-collection-videos-header">
          { collection.videos.length } {collection.videos.length === 1 ? 'video' : 'videos'}
        </div>
        <div className="padding-lr">
          { collection.videos.length > 0 ? (
            <VideosGrid
              videos={collection.videos}
              columnsCount={3}
              hideTeacher={false}
            />
          ) : (
            <div className="mt-5 pt-5 pb-5 Empty-result">
              NO CLASSES
            </div>
          )}
        </div>
      </div>
    );
  }
}
