import Api from "../api/locations";

export const FETCH_LOCATIONS = 'FETCH_LOCATIONS';
export const FETCH_LOCATIONS_ERROR = 'FETCH_LOCATIONS_ERROR';
export const FETCH_LOCATIONS_SUCCESS = 'FETCH_LOCATIONS_SUCCESS';

  export const fetchLocations = () => dispatch => {
  dispatch({ type: FETCH_LOCATIONS });

  Api.fetchLocations()
    .then(res =>
      dispatch({
        type: FETCH_LOCATIONS_SUCCESS,
        locations: res.body
      })
    )
    .catch(err =>
      dispatch({
        type: FETCH_LOCATIONS_ERROR,
        error: err
      })
    );
};
