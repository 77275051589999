import superagent from 'superagent';

const API_ENDPOINT = '/account_balances';

const getBalance = (lesson_id = null) => {
  return superagent
    .get(API_ENDPOINT)
    .set('Accept', 'application/json')
    .query({ lesson_id })
};

export default { getBalance };