import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import scrollToTop from "../helpers/scrollToTop";
import MetaTagsComponent from "../components/meta_tags_component";
import LiveScheduleShort from "../components/live_schedule_short";
import { fetchLiveSchedulePage } from "../actions/live_schedule_page";
import HomeTvSignupBanner from "../components/home_tv_signup_banner";
import { isAuthenticated } from "../reducers/user";
import RenderLoading from "../components/render_loading";
import superagent from "superagent";

class LiveStreamPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      error: null,
      purchaseVideos: []
    };
  }

  componentDidMount() {
    scrollToTop();
    this.getLiveSchedulePage();
    this.getPurchaseVideoList();
  }

  getPurchaseVideoList = () => {
    this.setState({ isLoading: true })
    return superagent
      .get(`/purchased_video`)
      .set("Accept", "application/json")
      .send()
      .then(res => {
        this.setState({ isLoading: false, purchaseVideos: res.body.purchased })
      })
      .catch(error => {
        console.log(error)
      });
  }

  getLiveSchedulePage = () => {
    this.props
      .fetchLiveSchedulePage()
      .then(res => this.setState({ isLoading: false }))
      .catch(error => {
        this.setState({ error, isLoading: false });
      });
  };

  render() {
    const {
      isAuthenticated,
      liveSchedulePage: { content, subscribed }
    } = this.props;
    const { isLoading, purchaseVideos } = this.state;

    return (
      <div className="Page video-page live-stream-page">
        <MetaTagsComponent
          title="SKY TING Live Stream"
          desc="SKY TING Live Stream"
          url="tv/live"
        />
        {isLoading ? (
          <RenderLoading className="centered" />
        ) : (
          <Fragment>
            <div className="live-stream-page-head padding-lr">
              {content && content.image_url && (
                <img src={content.image_url} className="bckg" />
              )}
              <div className="text">
                <h2 className="live-stream-schedule-header">
                  {content ? content.header : "ZOOM SCHEDULE"}
                </h2>
                {content && (
                  <h3 className="live-stream-page-subtitle">
                    {isAuthenticated
                      ? content.subheader_logged_in
                      : content.subheader_logged_out}
                  </h3>
                )}
              </div>
            </div>
            {!isAuthenticated && (
              <HomeTvSignupBanner
                subheader={
                  (content && content.cta_text) ||
                  "Your free trial awaits. Cancel online anytime."
                }
                subscribed={subscribed}
                cameFrom="/tv/live"
                hideHeader
              />
            )}
            <div className="live-schedule-container">
              <LiveScheduleShort full showDates subscribed={subscribed} fullWidth purchasedVideos={purchaseVideos} />
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: isAuthenticated(state),
  liveSchedulePage: state.liveSchedulePage
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchLiveSchedulePage
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LiveStreamPage);
