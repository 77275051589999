import {
  OPEN_PURCHASE_POPUP,
  CLOSE_PURCHASE_POPUP
} from '../actions/purchase_popup';

const INITIAL_STATE = {
  show: false
};

const purchasePopup = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OPEN_PURCHASE_POPUP:
      return {
        ...state,
        show: true
      };
    case CLOSE_PURCHASE_POPUP:
      return {
        ...state,
        show: false
      };
    default:
      return state;
  }
};

export default purchasePopup;
