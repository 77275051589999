import React, { Component, Fragment } from "react";
import moment from "moment";
import LadyBlue from "./../../../assets/images/icons/lady-blue-icon.svg";
import visa from "../../../assets/images/icons/visa.svg";
import { Collapse } from "react-collapse";
import classNames from "classnames";

class PurchaseCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };
  }

  openCard() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    const { purchase, border, active } = this.props;
    const { isOpen } = this.state;

    const iconSrc = LadyBlue;

    return (
      <div className="Purchase-card">
        <div className="account-card row pt-3 pt-md-0">
          <div className="col-2 col-md-1 d-flex align-items-center mb-1 mb-md-0">
            <img src={iconSrc} width={50} height={50} alt="SKY TING" />
          </div>
          <div className="col-10 col-md-2 d-block d-md-flex justify-content-center mb-1 mb-md-0 flex-column">
            <b>{purchase.product_name}</b>
            <div className="pt-2 mb-1 bordered d-md-none" />
          </div>
          <div className="col-2 d-flex d-md-none" />
          <div className="col-10 col-md-2 d-flex align-items-center mb-2 mb-md-0">
            <div>
              <b>STARTED:</b>{" "}
              {moment.utc(purchase.order_date).format("MM/DD/YY")}
            </div>
          </div>
          <div className="col-2 d-flex d-md-none" />
          <div className="col-10 col-md-2 d-flex align-items-center mb-2 mb-md-0" />
          <div className="col-2 d-flex d-md-none" />
          <div className="col-10 col-md-2 d-flex justify-content-start justify-content-md-center align-items-center mb-2 mb-md-0">
            <div>
              {purchase.payment_card_brand && purchase.payment_card_brand?.toLowerCase() === "visa/mc" ? (
                <img
                  src={visa}
                  width={50}
                  className="credit-card-brand mr-3"
                  alt="Visa"
                />
              ) : (
                purchase.payment_card_brand
              )}
              {purchase.payment_card}
            </div>
          </div>
          <div className="col-2 d-flex d-md-none" />
          <div className="col-10 col-md-2 d-flex flex-column justify-content-center align-items-start align-items-md-center mb-3 mb-md-0">
            <div>
              <div className={active ? "status-active" : "status-inactive"} />
              &nbsp;
              <span>
                {active ? "ACTIVE" : null}
              </span>
            </div>
          </div>
          <div className="cancel-lesson-btn col-12 col-md-1 d-flex align-items-center justify-content-center" onClick={() => this.openCard()}>
            VIEW
          </div>
        </div>
        <Collapse isOpened={isOpen}>
          <div className="purchase-card-content">
            <div className="purchase-card-content-inner text-left row">
              <div className="col-sm-1 d-none d-sm-block">&nbsp;</div>
              <div className="col-12 col-sm-5 col-md-5 mb-2 mb-sm-0">
                <b>QUANTITY</b>: {purchase.quantity}
                <div className="pt-4 d-none d-sm-block" />
                <div>
                  {purchase.color && purchase.color !== "None"
                    ? `${purchase.color} `
                    : ""}
                  {purchase.size && purchase.size !== "None"
                    ? `${purchase.size}`
                    : ""}
                </div>
              </div>
              <div className="d-none d-sm-flex col-4 col-sm-2 cell">
                <b>TOTAL:</b> ${purchase.amount}
              </div>
              <div className="col-12 col-sm-3 cell">
                <div className="mb-2 mb-sm-0">
                  <b className="left-aligned">ORDER DATE: </b>
                  {moment(purchase.order_date).format("MM/DD/YY")}
                </div>

                {purchase.order_number &&
                  <div className="mb-2 mb-sm-0">
                    <b className="left-aligned">ORDER #: </b>
                    {purchase.order_number}
                  </div>
                }

                {purchase.recipient_first_name &&
                  <div className="mb-2 mb-sm-0 text-uppercase">
                    <b className="left-aligned">RECIPIENT: </b>
                    {purchase.recipient_first_name} {purchase.recipient_last_name}
                  </div>
                }

                {purchase.recipient_email &&
                  <div className="mb-2 mb-sm-0 text-uppercase">
                    <b className="left-aligned">RECIPIENT EMAIL: </b>
                    {purchase.recipient_email}
                  </div>
                }
              </div>
              <div className="col-sm-1 d-none d-sm-block">&nbsp;</div>
            </div>
          </div>
        </Collapse>
        {border && <div className="bordered-bottom d-none d-md-block" />}
      </div>
    );
  }
}

export default PurchaseCard;
