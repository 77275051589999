import React, {Component} from 'react';
import hand from "../../../assets/images/icons/hand.svg";
import orderSizes from '../helpers/orderSizes';
import {Link} from "react-router-dom";

class ProductSizeSelector extends Component {
  sizeIsUnavailable = (size) => {
    const quantity = size.colors[0].quantity;
    return Number(quantity) < 1;
  }

  render() {
    const { product, selectedSize, onSizeSelect, addProductToCart, hideButton } = this.props;

    if (product.is_donation) return (
      <Link to={product.url} target="_blank" className="Button product-donation-btn w-100">
        { product.button_text || "SHOP" }
        <img src={hand} width={21} height={19} alt="Shop" />
      </Link>
    )

    if (product.soldout) return (
      <div className="product-soldout">
        SOLD OUT
      </div>
    );

    const orderedSizes = product.sizes.length > 1 ? orderSizes(product.sizes) : product.sizes

    return (
      <div className="size-select-container">
        <select
          className="product-sizes-select"
          id="product-sizes"
          onChange={onSizeSelect}
          value={selectedSize ? selectedSize.name : ""}
        >
          <option value="" selected disabled hidden>
            SIZE
          </option>
          {orderedSizes.map(size => (
            <option key={size.name} value={size.name} disabled={this.sizeIsUnavailable(size)}>
              {size.name}
            </option>
          ))}
        </select>
        { !hideButton &&
        <button
          className="Button add-to-cart ga-add-to-cart"
          onClick={() => addProductToCart(product)}
          disabled={!selectedSize}
        >
          <span>{product.preorder ? 'PREORDER' : 'ADD TO CART'}</span>
          <img src={hand} width={21} height={19} alt="ADD TO CART" />
        </button>
        }
      </div>
    );
  }
}

export default ProductSizeSelector;
