import {
  CHECKOUT_SERVICE,
  CHECKOUT_SERVICE_ERROR,
  CHECKOUT_SERVICE_SUCCESS,
  CHECKOUT_SERVICE_INITIAL,
  RESET_CHECKOUT_SERVICE_ERROR,
} from '../actions/checkout';

const INITIAL_STATE = {
  error: null,
  success: null,
  waitingForCheckout: false
};

const checkout = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHECKOUT_SERVICE:
      return {
        ...state,
        waitingForCheckout: true
      };
    case CHECKOUT_SERVICE_ERROR:
      return {
        ...state,
        error: action.error,
        waitingForCheckout: false
      };
    case CHECKOUT_SERVICE_SUCCESS:
      return {
        ...state,
        success: true,
        waitingForCheckout: false
      };
    case RESET_CHECKOUT_SERVICE_ERROR:
      return {
        ...state,
        error: null,
      };
    case CHECKOUT_SERVICE_INITIAL:
      return INITIAL_STATE
    default:
      return state;
  }
};

export default checkout
