import React, { Component } from 'react';
import PropTypes from 'prop-types'

export default class Table extends Component {
  get tableHeader() {
    const { header } = this.props;

    if (!header) return '';

    return <div className="Table-header">
      <div className="Headline">
        {header}
      </div>
    </div>
  }

  render() {
    return (
      <div className="Table">
          {this.tableHeader}
        <div className="Table-body">
          {this.props.rows}
        </div>
      </div>
    );
  }
}

Table.propTypes = {
  header: PropTypes.node,
  rows: PropTypes.node.isRequired
}
