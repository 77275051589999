import React, { Component } from "react";
import { Link } from "react-router-dom";
import skyTingTv from "../../../../assets/images/icons/sky_ting_tv.svg";
import skyTingText from "../../../../assets/images/icons/skyting_text.svg";
import arrowLong from "../../../../assets/images/icons/arrow_long.png";
import { history } from "../../store/configureStore";

class AuthPageHeader extends Component {
  state = {
    prevPath: null
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.pathname !== this.props.pathname) {
      this.setState({ prevPath: this.props.pathname });
    }
  }

  headerText = () => {
    if (this.props.showTvIcon) {
      return <img src={skyTingTv} className="tv-header-logo" />;
    } else {
      return <img src={skyTingText} />;
    }
  };

  goBack = () => {
    const { prevPath } = this.state;

    if (prevPath) {
      history.goBack();
    } else {
      history.push("/");
    }
  };

  render() {
    const { onButtonClick, buttonText } = this.props;
    return (
      <div className="signup-page-header">
        <img
          src={arrowLong}
          className="back-btn"
          onClick={() => this.goBack()}
        />

        <Link to="/">{this.headerText()}</Link>
        {buttonText && (
          <div onClick={onButtonClick} className="signup-page-header-btn">
            {buttonText}
          </div>
        )}
      </div>
    );
  }
}

export default AuthPageHeader;
