import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import superagent from "superagent";

import { isAuthenticated } from "../reducers/user";
import scrollToTop from "../helpers/scrollToTop";
import MetaTagsComponent from "../components/meta_tags_component";
import GiftCardPopup from "../components/shop/gift_card_popup_new";
import hand from "../../../assets/images/icons/hand.svg";
import Slider from "react-slick";
import giftCardImg from "./../../../assets/images/gift_card.png";
import RenderLoading from "../components/render_loading";
import ProductCategories from "../components/product_categories";
import { Collapse } from "react-collapse";
import { getParamByName } from "../helpers/get_param_by_name";

class GiftCardPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      error: null,
      product: null,
      giftCards: [],
      tvGiftCards: [],
      pageContent: null,
      selectedCard: null,
      selectedTvCard: null,
      showGiftCardPopup: false,
      showDescription: false
    };
  }

  componentDidMount() {
    scrollToTop();
    this.getGiftCards();
  }

  checkGiftCardPath = (cards, tvCards) => {
    const { isAuthenticated } = this.props;

    const gift_card_id = getParamByName("gift_card_id", "integer");
    const tv_gift_card_id = getParamByName("tv_gift_card_id", "integer");

    if (gift_card_id || tv_gift_card_id) {
      if (isAuthenticated) {
        if (tv_gift_card_id) {
          this.setState({
            showGiftCardPopup: true,
            selectedTvCard: tvCards.find(c => c.id === tv_gift_card_id),
          });
        } else {
          this.setState({
            showGiftCardPopup: true,
            selectedCard: cards.find(c => c.id === gift_card_id),
          });
        }
      } else {
        this.props.history.push({
          pathname: "/shop/gift_card",
          search: ""
        });
      }
    }
  };

  getGiftCards = () => {
    this.setState({ isLoading: true, error: null });

    return superagent
      .get(`/shop/gift_card`)
      .set("Accept", "application/json")
      .send()
      .then(res => {
        this.setState({
          isLoading: false,
          product: res.body.gift_card_product,
          giftCards: res.body.gift_cards,
          tvGiftCards: res.body.tv_gift_cards,
          pageContent: res.body.page_content
        });

        this.checkGiftCardPath(res.body.gift_cards, res.body.tv_gift_cards);
      })
      .catch(error => {
        this.setState({ error: error.response.body, isLoading: false });
      });
  };

  selectGiftCard = (event, isTv) => {
    const { giftCards, tvGiftCards } = this.state;

    if (isTv) {
      const giftCard = tvGiftCards.find(
        card => card.id.toString() === event.target.value
      );
      this.setState({ selectedCard: null, selectedTvCard: giftCard });
    } else {
      const giftCard = giftCards.find(
        card => card.id.toString() === event.target.value
      );
      this.setState({ selectedCard: giftCard, selectedTvCard: null });
    }
  };

  showGiftCardPopup = () => {
    const { isAuthenticated } = this.props;
    const { selectedCard, selectedTvCard } = this.state;

    if (isAuthenticated) {
      this.setState({ showGiftCardPopup: true });
      this.props.history.push(
        selectedTvCard
          ? `?tv_gift_card_id=${selectedTvCard.id}`
          : `?gift_card_id=${selectedCard.id}`
      );
    } else {
      this.props.history.push({
        pathname: "/login",
        state: {
          cameFrom: selectedTvCard
            ? `/shop/gift_card?tv_gift_card_id=${selectedTvCard.id}`
            : `/shop/gift_card?gift_card_id=${selectedCard.id}`
        }
      });
    }
  };

  renderAmountSelect = () => {
    const { giftCards, selectedCard } = this.state;

    return (
      <select
        className="gift-card-amount-select"
        onChange={event => this.selectGiftCard(event)}
        value={selectedCard ? selectedCard.id : ""}
      >
        <option value="" selected disabled hidden>
          CHOOSE GIFT CARD VALUE
        </option>
        {giftCards.map(card => (
          <option key={card.id} value={card.id}>
            ${card.amount}
          </option>
        ))}
      </select>
    );
  };

  renderTvAmountSelect = () => {
    const { tvGiftCards, selectedTvCard } = this.state;

    return (
      <select
        className="gift-card-amount-select"
        onChange={event => this.selectGiftCard(event, true)}
        value={selectedTvCard ? selectedTvCard.id : ""}
      >
        <option value="" selected disabled hidden>
          GIFT SKYTING TV
        </option>
        {tvGiftCards.map(card => (
          <option key={card.id} value={card.id}>
            {card.name} ($ {card.amount})
          </option>
        ))}
      </select>
    );
  };

  render() {
    const {
      pageContent,
      isLoading,
      selectedCard,
      selectedTvCard,
      showGiftCardPopup,
      product,
      error,
      showDescription,
      tvGiftCards,
    } = this.state;

    if (isLoading)
      return (
        <div className="Page product-page gift-card-page">
          <RenderLoading />
        </div>
      );

    return (
      <div className="Page product-page gift-card-page">
        {error ? (
          <div className="error">{error.message || "Product not found"}</div>
        ) : (
          <Fragment>
            <MetaTagsComponent
              title="SKY TING | Shop | Gift Card"
              desc={product.seo_description || "Gift Card"}
              url="shop/gift_card"
              image={product.thumbnail_url || giftCardImg}
            />
            <div className="product-info show-for-mobile mt-4">
              <div className="categories-row">
                <ProductCategories product={product} />
              </div>
              <h2 className="Page-header">{product.name}</h2>
            </div>
            <div className="product-page-top max-width-1800 m-auto padding-lr">
              <div className="product-carousel-container product-page-column">
                <div className="product-carousel with-large-dots">
                  {product.product_group_images.length > 0 ? (
                    <Slider
                      dots
                      infinite
                      speed={1000}
                      swipeToSlide
                      arrows={false}
                    >
                      {product.product_group_images.map((image, index) => (
                        <div
                          className="product-carousel-inner"
                          key={`gift-card-image-${index}`}
                        >
                          <img src={image.url} className="product-image" />
                        </div>
                      ))}
                    </Slider>
                  ) : (
                    <div className="product-carousel-inner">
                      <img src={giftCardImg} className="product-image" />
                    </div>
                  )}
                </div>
              </div>
              <div className="product-info-container product-page-column">
                <div
                  className={`product-info ${
                    product.product_group_images.length < 2
                      ? "mt-small-for-mobile"
                      : ""
                  }`}
                >
                  <div className="categories-row hide-for-mobile">
                    <ProductCategories product={product} />
                  </div>
                  <div className="product-title hide-for-mobile">GIFT CARD</div>
                  <div className="product-price hide-for-mobile">&nbsp;</div>
                  <div className="line-bottom hide-for-mobile" />
                  {this.renderAmountSelect()}
                  { tvGiftCards.length > 0 &&
                    <Fragment>
                      <div className="text-with-line">
                        <div className="line" />
                        <span className="text">OR</span>
                        <div className="line" />
                      </div>
                      {this.renderTvAmountSelect()}
                    </Fragment>
                  }
                  <button
                    className="Button gift-card-button"
                    onClick={() => this.showGiftCardPopup()}
                    disabled={!selectedCard && !selectedTvCard}
                  >
                    CUSTOMIZE GIFT CARD
                    <img src={hand} width={21} height={19} alt="open" />
                  </button>
                  {pageContent.terms_text && (
                    <div
                      className="gift-card-terms text-center"
                      dangerouslySetInnerHTML={{
                        __html: pageContent.terms_text
                      }}
                    />
                  )}

                  {product.long_desc && (
                    <Fragment>
                      <div
                        className="product-desc rich-text mt-4"
                        dangerouslySetInnerHTML={{
                          __html: product.long_desc
                        }}
                      />
                      <Collapse isOpened={showDescription}>
                        <div
                          className="product-desc rich-text"
                          dangerouslySetInnerHTML={{
                            __html: product.long_desc_2
                          }}
                        />
                      </Collapse>
                      {product.long_desc_2 && (
                        <div
                          className={`description-collapse-btn ${
                            showDescription ? "opened" : ""
                          }`}
                          onClick={() =>
                            this.setState({ showDescription: !showDescription })
                          }
                        >
                          <span className="line" />
                          <div className="text">
                            {showDescription ? "LESS INFO" : "MORE INFO"}
                          </div>
                        </div>
                      )}
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
            {showGiftCardPopup && (
              <GiftCardPopup
                selectedCard={selectedCard}
                selectedTvCard={selectedTvCard}
                amountSelect={
                  selectedTvCard
                    ? this.renderTvAmountSelect()
                    : this.renderAmountSelect()
                }
                pageContent={pageContent}
                onClose={() => {
                  this.setState({ showGiftCardPopup: false });
                  this.props.history.push({
                    pathname: "/shop/gift_card",
                    search: ""
                  });
                }}
              />
            )}
          </Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: isAuthenticated(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GiftCardPage);
