import React from 'react';
import openNewWindow from './../../../assets/images/icons/open-lightbox.svg';
import BookLessonButton from './book_lesson_button.jsx';
import teacherPlaceholder from "./../../../assets/images/teacher_placeholder.png";
import {renderStar} from "../helpers/render_star";

const ScheduleColumns = (lesson) => [
  <div className="schedule-staff">
    <div className="schedule-staff-img mr-2 mr-sm-4 mr-lg-5">
      { lesson.staffSecondImg ? (
        <div className="schedule-staff-two-images">
          <img src={lesson.staffImg || teacherPlaceholder} alt="teacher" />
          &nbsp;&nbsp;+&nbsp;
          <img src={lesson.staffSecondImg || teacherPlaceholder} alt="teacher" />
        </div>
      ) : (
        <div className="schedule-staff-img-wrapper">
          <img src={lesson.staffImg || teacherPlaceholder} alt="teacher" />
        </div>
      )}
    </div>
    <div className="text-left">{lesson.staffName}</div>
  </div>,
  <div className="schedule-startTime">
    <span>
      {lesson.startTimeWithFormat('h:mmA')}
    </span>
    <span>&nbsp;</span>
    <span className="schedule-duration">
      ({lesson.durationMinutes} MIN)
    </span>
  </div>,
  <div className="schedule-name d-flex align-items-center justify-content-center justify-content-md-start">
    <div className="star-icon mr-3 d-none d-md-block">
      {renderStar(lesson.programName)}
    </div>
    <span className="text-center text-md-left">{lesson.name}</span>
  </div>,
  <BookLessonButton
    label={<img src={openNewWindow} width={22} height={22} alt="Open" />}
    lessonId={lesson.id}
    className="Schedule-action"
  />
];

export default ScheduleColumns;
