import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import superagent from "superagent";
import { bindActionCreators } from "redux";

import Popup from "./popup.jsx";
import Billing from "./billing";
import CreditCard from "./credit_card";
import GiftCardPaymentMethod from "./gift_card_payment_method";

import { closeShopPaymentPopup } from "../actions/shop_payment_popup";
import { closeBillingForm } from "../actions/billing";
import { openOrderPopup } from "../actions/order";
import { checkoutTotal, dropCheckoutTotalCart } from "../actions/checkout_total";
import { openShipping } from "../actions/shipping";
import Loading from "../../../assets/images/icons/yinyang_icon.png";
import ShopRemainingBalance from "./shop_remaining_balance";

class ShopPaymentPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardIsChosen: false,
      giftCardIsChosen: false,
      checkGiftCardError: null,
      checkingBalance: false,
      remainingBalance: null
    };
  }

  componentDidMount() {
    document.body.classList.add("overflow-hidden");
    this.fetchCheckoutTotal();
  }

  componentWillUnmount() {
    document.body.classList.remove("overflow-hidden");
  }

  fetchCheckoutTotal = () => {
    const { checkoutTotal, cartItems } = this.props;

    const products = cartItems.map((item) => ({
      mindbody_identifier: item.mindbody_identifier,
      quantity: item.quantity
    }))
    checkoutTotal(products, 'Product', null, null, false);
  }

  closeShopPaymentPopup = () => {
    this.props.closeShopPaymentPopup();
    this.props.dropCheckoutTotalCart();
  };

  goToPrevStep = () => {
    this.props.closeShopPaymentPopup();
    this.props.openShipping();
  };

  goToOrderPopup = () => {
    this.props.openOrderPopup();
    this.props.closeShopPaymentPopup();
  };

  confirm = () => {
    const { giftCardIsChosen } = this.state;
    const { checkoutTotalCart } = this.props;

    if (giftCardIsChosen) {
      this.checkGiftCardBalance(checkoutTotalCart.grand_total);
    } else {
      this.fetchCheckoutTotal()
      this.goToOrderPopup();
    }
  };

  checkoutTotalWithGiftCard = (gift_card_amount) => {
    const { checkoutTotalCart, checkoutTotal } = this.props;
    const products = checkoutTotalCart.cart_items.cart_item;

    checkoutTotal(
      products,
      "Product",
      null,
      gift_card_amount
    ).then(res => {
      this.props.openOrderPopup();
      this.props.closeShopPaymentPopup();
    })
    .catch(err => {
      console.log('error!')
    })
  };

  checkGiftCardBalance = amount => {
    this.setState({ checkingBalance: true, checkGiftCardError: null });

    return superagent
      .get("/check_gift_card_balance")
      .set("Accept", "application/json")
      .query({ amount })
      .then(res => {
        if (res.body.remaining === 0) {
          this.setState({ checkingBalance: false });
          this.checkoutTotalWithGiftCard(res.body.gift_card_applied_amount);
        } else {
          this.setState({
            remainingBalance: res.body,
            checkingBalance: false
          });
        }
      })
      .catch(error => {
        this.setState({
          checkGiftCardError: error.response.body,
          checkingBalance: false
        });
      });
  };

  chooseCreditCard = () => {
    const { cardIsChosen } = this.state;
    const {
      user: { card_last_four },
      showBillingForm,
      closeBillingForm
    } = this.props;

    if (!card_last_four) return;

    this.setState({
      cardIsChosen: !cardIsChosen,
      giftCardIsChosen: false,
      checkGiftCardError: null
    });

    if (showBillingForm) {
      closeBillingForm();
    }
  };

  chooseGiftCard = () => {
    const { giftCardIsChosen } = this.state;

    this.setState({
      cardIsChosen: false,
      giftCardIsChosen: !giftCardIsChosen
    });
  };

  render() {
    const {
      shopPaymentPopup: { show },
      cartIsFetching,
      user: { card_last_four },
      checkoutTotalIsFetching,
      user,
      checkoutTotalError
    } = this.props;

    const {
      checkGiftCardError,
      giftCardIsChosen,
      cardIsChosen,
      remainingBalance,
      checkingBalance
    } = this.state;

    if (remainingBalance)
      return (
        <Popup
          id="shop-payment"
          isOpen={show}
          onClose={this.closeShopPaymentPopup}
        >
          <div className="Purchase-popup">
            <ShopRemainingBalance
              user={user}
              remainingBalance={remainingBalance}
              checkoutTotalIsFetching={checkoutTotalIsFetching}
              error={checkoutTotalError}
              goBack={() => this.setState({ remainingBalance: null })}
              checkoutTotalWithGiftCard={(amount) => this.checkoutTotalWithGiftCard(amount)}
            />
          </div>
        </Popup>
      );

    return (
      <Popup
        id="shop-payment"
        isOpen={show}
        onClose={this.closeShopPaymentPopup}
      >
        <div className="Purchase-popup">
          <div className="arrow-left go-to-prev" onClick={this.goToPrevStep} />
          <h2 className="Purchase-popup-title">PAYMENT</h2>
          <h2 className="CreditCard-title mb-3">SELECT A PAYMENT METHOD</h2>
          <CreditCard
            chooseCard={this.chooseCreditCard}
            isChecked={cardIsChosen}
            hideTitle
          />
          <GiftCardPaymentMethod
            chooseGiftCard={this.chooseGiftCard}
            isChecked={giftCardIsChosen}
            withoutTopBorder={!!user.card_last_four}
          />
          <div className="mb-4" />

          {!giftCardIsChosen && (
            <Fragment>
              {card_last_four && (
                <div className="text-center mt-4 mb-3">OR</div>
              )}
              <Billing
                toggleCard={this.chooseCreditCard}
                cardIsChosen={this.state.cardIsChosen}
              />
            </Fragment>
          )}

          {checkoutTotalIsFetching || checkingBalance ? (
            <div className="inline-loading">
              <img src={Loading} height="21" width="21" alt="Loading" />
            </div>
          ) : (
            <Fragment>
              {checkoutTotalError && (
                <div className="OrderPopup-error">
                  {checkoutTotalError.message ||
                  "Something went wrong. Please try again."}
                </div>
              )}
              {checkGiftCardError && (
                <div className="OrderPopup-error">
                  {checkGiftCardError.message ||
                    "Wasn't able to check gift card balance"}
                </div>
              )}
              <button
                onClick={this.confirm}
                className="SmallButton"
                disabled={
                  cartIsFetching ||
                  (!cardIsChosen && !giftCardIsChosen) ||
                  checkoutTotalIsFetching
                }
              >
                CONFIRM
              </button>
            </Fragment>
          )}
        </div>
      </Popup>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  shopPaymentPopup: state.shopPaymentPopup,
  checkoutTotalCart: state.checkoutTotal.cart,
  checkoutTotalIsFetching: state.checkoutTotal.isFetching,
  checkoutTotalError: state.checkoutTotal.error,
  cartItems: state.cart.items,
  cartIsFetching: state.cart.isFetching
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      closeBillingForm,
      closeShopPaymentPopup,
      openOrderPopup,
      dropCheckoutTotalCart,
      openShipping,
      checkoutTotal
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShopPaymentPopup);
