import superagent from "superagent";

const API_ENDPOINT = "/video_subscriptions";

const createSubscription = (
  plan_id,
  idemp_key_customer,
  idemp_key_subscription,
  idemp_key_balance_increase,
  idemp_key_balance_decrease,
  stripe_tok = null,
  coupon = null,
  gift_card_amount = null,
  default_source = null
) => {
  return superagent
    .post(API_ENDPOINT)
    .set("Accept", "application/json")
    .send({
      stripe_tok,
      coupon,
      gift_card_amount,
      plan_id,
      idemp_key_customer,
      idemp_key_subscription,
      idemp_key_balance_increase,
      idemp_key_balance_decrease,
      default_source
    });
};

const updateSubscription = (id, subscription_item_id, plan_id, coupon) => {
  return superagent
    .put(`/video_subscriptions/${id}`)
    .set("Accept", "application/json")
    .send({ subscription_item_id, plan_id, coupon });
};

export default { createSubscription, updateSubscription };
