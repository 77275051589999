import _ from 'lodash'
import Lesson from '../models/schedule'

const getStaff = lessons => _.chain(lessons)
  .uniqBy('staff.id')
  .map('staff')
  .value()

const getLocations = lessons => _.chain(lessons)
  .uniqBy('location.id')
  .map('location')
  .value()

const getSessionTypes = lessons => _.chain(lessons)
  .uniqBy('session_type.id')
  .map('session_type')
  .value()

const getClasses = lessons => {
  return lessons.filter((lesson) => lesson.programName === "SKY TING Yoga Classes")
}

const serialize = (data) => {

  const lessons = _.map(data, (lesson) => new Lesson(lesson))

  return {
    lessons,
    staff: getStaff(lessons),
    locations: getLocations(lessons),
    session_types: getSessionTypes(lessons)
  }
}

export default serialize
