import superagent from "superagent";
export const FETCH_TV_DASHBOARD = "FETCH_TV_DASHBOARD";

export const fetchTvDashboard = () => dispatch => {
  return new Promise(async (resolve, reject) => {
    await superagent
      .get("/tv_dashboard")
      .set("Accept", "application/json")
      .send()
      .then(res => {
        dispatch({
          type: FETCH_TV_DASHBOARD,
          content: res.body,
        });
        resolve(res.body);
      })
      .catch(error => {
        reject(error.response.body);
      });
  });
};
