import statue from '../../../assets/images/statue.jpg';
import squirrel from '../../../assets/images/squirrel.jpg';
import roll from '../../../assets/images/roll.jpg';
import man from '../../../assets/images/man.jpg';
import chair from '../../../assets/images/chair.jpg';

export default [
  {
    text: 'Bust of Artemis (Diana)',
    description: 'Statue',
    image: statue,
  },
  {
    text: 'Eurasian Red Squirrel, Sciurus vulgaris',
    description: 'Squirrel',
    image: squirrel,
  },
  {
    text: 'Salmon Avocado Maki',
    description: 'Roll',
    image: roll,
  },
  {
    text: 'Travis Bickle, Taxi Driver',
    description: 'Man',
    image: man,
  },
  {
    text: 'Franz West, Onkel Stuhl',
    description: 'Chair',
    image: chair,
  },
];
