import Api from '../api/user';
import { saveUseragent } from './useragent';
import getCookieVal from "../helpers/get_cookie_val";
import addTagManagerEvent from "../helpers/add_tag_manager_event";

export const FETCH_CURRENT_USER = 'FETCH_CURRENT_USER';
export const FETCH_CURRENT_USER_ERROR = 'FETCH_CURRENT_USER_ERROR';
export const FETCH_CURRENT_USER_SUCCESS = 'FETCH_CURRENT_USER_SUCCESS';

export const fetchCurrentUser = () => (dispatch) => {
  dispatch({ type: FETCH_CURRENT_USER });

  Api.fetchCurrentUser()
    .then(res => {
      dispatch({ type: FETCH_CURRENT_USER_SUCCESS, user: res.body })
      dispatch(saveUseragent())
    })
    .catch(err => {
      dispatch({ type: FETCH_CURRENT_USER_ERROR, error: err });

      // code for Google tag manager (to add User status event for logged out user)
      const tvSubscriptionPeriod = getCookieVal('user_tv_subscription');
      if (tvSubscriptionPeriod) {
        const period = tvSubscriptionPeriod === 'live' ? "live"
          : tvSubscriptionPeriod === 'year' ? "annually"
            : "monthly";

        addTagManagerEvent(window.location.pathname, `Logged-out-${period}`);
        document.cookie = "user_tv_subscription=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      }
    });
};