import React, { Component, Fragment } from "react";
import superagent from "superagent";

import ButtonWithIcon from "../button_with_icon";
import ComponentWithLoading from "../component_with_loading";
import validateEmail from "../../helpers/validate_email";
import EmailInput from "./email_input";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      email: props.emailValue || "",
      success: false,
      error: null
    };
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
      error: null
    });
  };

  renderPageText = () => {
    const { pageContent, loadingPageContent } = this.props;

    if (loadingPageContent) return "";

    return (pageContent && pageContent.forgot_password_text) || "Enter your email, and we'll send you instructions on how to reset your password."
  };

  sendInstructions = () => {
    const { email } = this.state;

    this.setState({ loading: true })

    return superagent
      .post("/users/forgot_password")
      .set("Accept", "application/json")
      .send({ email })
      .then(res => {
        this.setState({ success: true, loading: false });
      })
      .catch(error => {
        this.setState({
          error: error.response.body,
          loading: false
        });
      });
  }


  render() {
    const { switchMode } = this.props;
    const { loading, email, success, error } = this.state;

    const isValid = validateEmail(email);
    console.log('fdfdfd', email)

    return (
      <Fragment>
        <div className="step cursor-pointer" onClick={() => switchMode("login")}>RETURN TO LOGIN</div>
        { success ? (
          <Fragment>
            <div className="signup-page-subtitle">EMAIL SENT</div>
            <div className="secondary-message">&nbsp;</div>
            <hr />
            <div className="signup-page-text">
              An email with instructions on how to reset your password has been sent to <span className="bold-text">{email}</span>.
              Check your spam or junk folder if you don’t see the email in your inbox.
              <br />
              <br />
              If you no longer have access to this email account, please&nbsp;
              <a
                className="font-weight-bold bold-text no-hover"
                href="mailto:info@skytingyoga.com"
                target="_blank"
                rel="noopener noreferrer"
                title="Contact us"
              >
                contact us
              </a>
              .
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div className="signup-page-subtitle">FORGOT PASSWORD?</div>
            <div className="secondary-message">&nbsp;</div>
            <hr />
            <div
              className="signup-page-text"
              dangerouslySetInnerHTML={{ __html: this.renderPageText() }}
            />

            <div className="mt-50 form-container">
              <EmailInput
                handleChange={this.handleChange}
                value={email}
                onSubmit={() => this.sendInstructions()}
              />
              { error &&
                <div className="input-error-red">{error.message}</div>
              }

              <ComponentWithLoading isLoading={loading}>
                <ButtonWithIcon
                  classNamesProp="high-75 confirm-button"
                  text="SEND ME RESET INSTRUCTIONS"
                  disabled={!email || !isValid}
                  onClick={() => this.sendInstructions()}
                />
              </ComponentWithLoading>
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export default ForgotPassword;
