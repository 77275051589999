import { history } from '../store/configureStore';

export const SELECT_PROGRAM = 'SELECT_PROGRAM';
export const DESELECT_PROGRAM = 'DESELECT_PROGRAM';

export const selectProgram = program => (dispatch) => {
  if (program) {

    const id = program.additional && program.additional.slug ?
      program.main.mindbody_identifier : program.slug;

    history.push(`?program=${id}`);
  }

  dispatch({ type: SELECT_PROGRAM, program });
};

export const deselectProgram = () => (dispatch) => {
  history.push({
    pathname: window.location.pathname,
    search: '',
  })

  dispatch({ type: DESELECT_PROGRAM });
};