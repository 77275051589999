import React, { Component, Fragment } from "react";
import moment from "moment";
// const { detect } = require('detect-browser');
// import {ZoomMtg} from "@zoomus/websdk";
import skyLive from "../../../../assets/images/sky_live.jpg";
import LiveStreamIcon from "../../../../assets/images/icons/live_stream_schedule.svg";
import superagent from "superagent";
import ComponentWithLoading from "../component_with_loading";
import Countdown from "../countdown";

class ZoomVideo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLiveNow: false,
      openUpLink: false,
      signature: null,
      signatureLoading: false,
      signatureError: null,
      // browser: this.detectBrowser(), // if safari use external zoom link
    };
  }

  componentDidMount() {
    this.checkLiveIsStarted();
    // ZoomMtg.setZoomJSLib("https://source.zoom.us/1.9.1/lib", "/av");
    // ZoomMtg.preLoadWasm();
    // ZoomMtg.prepareJssdk();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  // detectBrowser = () => {
  //   const browser = detect();
  //   return browser && browser.name;
  // }

  checkLiveIsStarted = () => {
    // check every second
    const { video } = this.props;

    const date = moment.utc(video.start_time_ny_tz);

    this.interval = setInterval(() => {
      let diff = date.diff(moment.utc(), "seconds");
      // if there is less than 10 seconds left - show live

      if (diff <= 600) {
        this.setState({ openUpLink: true });
      }
      if (diff <= 10) {
        this.setState({ isLiveNow: true });
        clearInterval(this.interval);
      }
    }, 1000);
  };

  getSignature = (id) => {
    this.setState({ signatureLoading: true, signatureError: null });

    return superagent
      .get(`/tv/live/${id}/signature`)
      .set("Accept", "application/json")
      .send()
      .then(res => {
        this.joinMeeting(res.body.signature);
        this.setState({
          signature: res.body.signature,
          signatureLoading: false,
        })
      })
      .catch(error => {
        this.setState({
          signatureError: error.body.message,
          signatureLoading: false
        });
      });
  }

  joinMeeting = (signature) => {
    const { user, video } = this.props;

    // ZoomMtg.init({
    //   leaveUrl: `/tv/live/${video.slug}`,
    //   isSupportAV: true,
    //   success: (success) => {
    //     ZoomMtg.join({
    //       meetingNumber: video.zoom_meeting_number,
    //       userName: user.first_name ? user.first_name : user.email,
    //       signature,
    //       apiKey: gon.zoom_api_key,
    //       passWord: video.zoom_meeting_password,
    //
    //       success: (success) => {
    //         var elem = document.querySelector('#zmmtg-root');
    //         elem.style.display = 'block';
    //       },
    //
    //       error: (error) => {
    //         console.log(error);
    //       },
    //     });
    //   },
    // });
  }

  render() {
    const { video } = this.props;
    const { isLiveNow, openUpLink, signatureLoading } = this.state;

    return (
      <>
        <div className="video-page-player">
          <img src={skyLive} className="sky-bckg" />
          <div className="content">
            <div className="tagline">
              {isLiveNow ? (
                <>
                  <img src={LiveStreamIcon} />
                  <div>Class is live!</div>
                </>
              ) : (
                <>
                  {video.start_date_time && (
                    <Countdown
                      date={moment.utc(video.start_time_ny_tz)}
                      onComplete={() => this.setState({ showLive: true })}
                    />
                  )}
                </>
              )}
            </div>
            <h2 className="padding-lr">{video.title}</h2>
            <div className="tagline">Access to this class will start 10 minutes before class time</div>
            {/*{isLiveNow && <img src={play} className="player-icon" />}*/}
          </div>
        </div>
        <div className="video-attrs-container hide-for-small padding-lr">
          <div className="video-attr level">
            <div className="column-name">level</div>
            <div className="column-value">{video.level}</div>
          </div>
          <div className="video-attr props">
            <div className="column-name">duration</div>
            <div className="column-value">{video.duration}</div>
          </div>
          {isLiveNow || openUpLink ? (
            <a href={video.url} className="Button video-btn" target="_blank">
              JOIN CLASS!
            </a>
            // <>
            //   { browser === 'safari' && video.url ? (
            //     <a href={video.url} className="Button video-btn" target="_blank">
            //       JOIN CLASS!
            //     </a>
            //     ) : (
            //     <button
            //       className="Button video-btn"
            //       onClick={() => this.getSignature(video.id)}
            //       disabled={signatureLoading}
            //     >
            //       <ComponentWithLoading isLoading={signatureLoading}>
            //         JOIN CLASS!
            //       </ComponentWithLoading>
            //     </button>
            //   )}
            // </>
          ) : (
            <button className="Button video-btn" disabled>
              JOIN CLASS!
            </button>
          )}
        </div>
      </>
    );
  }
}

export default ZoomVideo;