import superagent from 'superagent';

const API_ENDPOINT = '/user_info';

const fetchCurrentUser = () => (
  superagent.get(API_ENDPOINT).set('Accept', 'application/json')
);

export default {
  fetchCurrentUser,
};
