import Api from '../api/authentication';
import { closeLoginPopup } from './login_popup';
import { dropdownChange } from './dropdowns';
import { bookLesson } from './lesson';
import { syncShoppingCart, clearUnauthorizedCart } from './cart';
import { openShipping } from "./shipping";
import { saveUseragent } from './useragent';

export const AUTHENTICATE = 'AUTHENTICATE';
export const AUTHENTICATE_ERROR = 'AUTHENTICATE_ERROR';
export const AUTHENTICATE_SUCCESS = 'AUTHENTICATE_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const authenticationSuccess = user => (dispatch, getState) => {
  dispatch({ type: AUTHENTICATE_SUCCESS, user });

  const state = getState()
  if (state.lesson.waitingForAuthentication) {
    dispatch(bookLesson(state.lesson.bookingLessonId))
  }

  dispatch(syncShoppingCart())

  if (state.cart.waitingForAuth) {
    dispatch(openShipping());
  }

  dispatch(closeLoginPopup());
  dispatch(saveUseragent());
};

const authenticationFailed = error => (dispatch) => {
  dispatch({ type: AUTHENTICATE_ERROR, error });
  dropdownChange({ login: false })(dispatch)
};

export const authenticate = ({ email, password }) => (dispatch) => {
  return new Promise(async(resolve, reject) => {
    dispatch({ type: AUTHENTICATE })

    await Api.authenticate({ email, password })
      .then(res => {
        dispatch(authenticationSuccess(res.body))
        resolve(true)
      })
      .catch((err) => {
        dispatch(authenticationFailed(err.response.body.error))
        reject(err.response.body.error)
      })
  });
};

const logoutSuccess = (dispatch) => {
  window.location.reload();
  dispatch({ type: LOGOUT_SUCCESS });
  dispatch(clearUnauthorizedCart());
};

const logoutFailed = error => (dispatch) => {
  dispatch({ type: LOGOUT_ERROR, error });
};

export const logout = () => (dispatch) => {
  dispatch({ type: LOGOUT });

  Api.logout()
    .then(() => dispatch(logoutSuccess))
    .catch(err => dispatch(logoutFailed(err)));
};
