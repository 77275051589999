import React, {Component, Fragment} from 'react';
import scrollToTop from "../helpers/scrollToTop";
import MetaTagsComponent from "../components/meta_tags_component";

export default class NotFound extends Component {
  componentDidMount() {
    scrollToTop();
  }

  render() {
    return (
      <Fragment>
        <MetaTagsComponent
          title="404 Not found"
          desc="404 Not found"
        />
        <div className="pb-5">
          <div className="pb-5">
            {/*<div className="Homepage-slider mb-5">*/}
            {/*  <img*/}
            {/*    className="w-100 h-100 not-fount-img"*/}
            {/*    src="https://media.giphy.com/media/Qw4X3FHCPlgDCDnMebe/giphy.gif"*/}
            {/*  />*/}
            {/*</div>*/}
            <h2 className="Page-header">
              Page not found
            </h2>
          </div>
        </div>
      </Fragment>
    )
  }
}