import React, { Component, Fragment } from "react";
import superagent from "superagent";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";

import scrollToTop from "../helpers/scrollToTop";
import MetaTagsComponent from "../components/meta_tags_component";
import locked from "../../../assets/images/icons/locked.svg";
import skyLive from "../../../assets/images/sky_live.jpg";
import { isAuthenticated } from "../reducers/user";
import RenderLoading from "../components/render_loading";
import ZoomVideo from "../components/live_video_page/zoom_video";
import LiveScheduleShort from "../components/live_schedule_short";
import hand from "../../../assets/images/icons/hand.svg";
import ButtonWithIcon from "../components/button_with_icon";
import Countdown from "../components/countdown";
import { history } from "../store/configureStore";

class LiveStreamVideoPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      video: null,
      isLoading: true,
      error: null,
      userSubscribed: false,
      purchaseVideos: []
    };
  }

  componentDidMount() {
    scrollToTop();
    this.getVideo(this.props.match.params.id);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.id !== nextProps.match.params.id) {
      scrollToTop();
      this.getVideo(nextProps.match.params.id);
      this.getPurchaseVideoList();
    }
  }

  getPurchaseVideoList = () => {
    this.setState({ isLoading: true })
    return superagent
      .get(`/purchased_video`)
      .set("Accept", "application/json")
      .send()
      .then(res => {
        this.setState({ isLoading: false, purchaseVideos: res.body.purchased })
      })
      .catch(error => {
        console.log(error)
      });
  }

  getVideo = id => {
    this.setState({ isLoading: true });

    return superagent
      .get(`/tv/live/${id}`)
      .set("Accept", "application/json")
      .send()
      .then(res => {
        this.setState({
          isLoading: false,
          video: res.body.video,
          userSubscribed: res.body.subscribed
        });
        if (!res.body.video.free && res.body.video.premium) {
          this.getLiveSchedulePage(res.body.video.premium);
        }
      })
      .catch(error => {
        this.setState({ error: error.response, isLoading: false });
      });
  };

  getLiveSchedulePage = (premium) => {
    const { isAuthenticated } = this.props;
    if (isAuthenticated) {
      const pathname = window.location.pathname;

      const video_id = pathname.substring(pathname.lastIndexOf('/') + 1);
      return superagent
        .get(`/live_schedule_page/${video_id}`)
        .set("Accept", "application/json")
        .send()
        .then(res => {
          if (res.body.paidForClass == true) {
            this.setState({ isLoading: false });
          }
          else {
            history.push({
              pathname: '/tv/live',
              state: { cameFrom: (pathname) }
            })
          }
        });
    }
    else {
      if (premium) {
        history.push({
          pathname: '/tv/live'
        })
      }
    }
  };

  renderVideoContent = () => {
    const { video, userSubscribed } = this.state;
    const { isAuthenticated, user } = this.props;

    const locked = !isAuthenticated || (!video.premium && !userSubscribed);

    if (locked) return this.renderLockedVideo();

    return (
      <div className="video-page-top">
        <ZoomVideo video={video} user={user} />
      </div>
    )
  };

  renderLockedVideo = () => {
    const { video } = this.state;
    const { isAuthenticated } = this.props;

    return (
      <div className="video-page-top">
        <div className="video-page-player unsubscribed">
          <img src={skyLive} className="sky-bckg" />
          <div className="content">
            {video.start_date_time && (
              <div className="tagline">
                <Countdown
                  date={moment.utc(video.start_time_ny_tz)}
                  onComplete={() => this.setState({ showLive: true })}
                />
              </div>
            )}
            <h2 className="padding-lr">{video.title}</h2>
            <div className="tagline mb-4">Access to this class will start 10minutes before class time</div>
            <img src={locked} className="player-icon" />
          </div>
        </div>
        <div className="video-attrs-container hide-for-small padding-lr">
          <div className="video-attr level">
            <div className="column-name">level</div>
            <div className="column-value">{video.level}</div>
          </div>
          <div className="video-attr props">
            <div className="column-name">duration</div>
            <div className="column-value">{video.duration}</div>
          </div>
          {!video.premium && !isAuthenticated ? (
            <Link to="/login" className="Button video-btn">
              SIGN UP TODAY
            </Link>
          ) : (
            <Link to="/pricing" className="Button video-btn">
              SUBSCRIBE FOR ACCESS
            </Link>
          )}
        </div>
      </div>
    );
  };

  render() {
    const { video, userSubscribed, isLoading, error, purchaseVideos } = this.state;

    return (
      <div className="Page video-page live-stream">
        <MetaTagsComponent
          title={`SKY TING Live Stream | ${video ? video.title : 'Live'}`}
          desc="SKY TING Live Stream"
          url={`tv/live/${this.props.match.params.id}`}
        />
        {isLoading ? (
          <RenderLoading className="centered" />
        ) : (
          <>
            {error ? (
              <div className="Empty-result">{error.message || "Something went wrong"}</div>
            ) : (
              <>
                {this.renderVideoContent()}

                <div className="video-desc padding-lr">
                  <h2 className="video-headline">{video.title}</h2>
                  <div className="video-teachers-row">
                    {video.teachers.map((teacher, index) => (
                      <Fragment key={index}>
                        <div className="teacher-container">
                          <div className="teacher-img-wrapper">
                            <img src={teacher.photo_url} />
                          </div>
                          <div className="teacher-name">
                            {teacher.first_name} {teacher.last_name}
                          </div>
                        </div>
                        {video.teachers.length > 1 &&
                          video.teachers.length > index + 1 && (
                            <span className="vertical-line" />
                          )}
                      </Fragment>
                    ))}
                  </div>
                  <div className="video-attrs-container for-small">
                    <div className="video-attr level">
                      <div className="column-name">level</div>
                      <div className="column-value">{video.level}</div>
                    </div>
                    {video.duration &&
                      <div className="video-attr intensity">
                        <div className="column-name">duration</div>
                        <div className="column-value">{video.duration}</div>
                      </div>
                    }
                  </div>

                  <p
                    className="video-description-text rich-text"
                    dangerouslySetInnerHTML={{ __html: video.description }}
                  />
                </div>

                <div className="live-schedule-container">
                  <LiveScheduleShort
                    showDates
                    subscribed={userSubscribed}
                    fullWidth
                    todayOnly
                    excludedVideoId={this.props.match.params.id}
                    purchasedVideos={purchaseVideos}
                  />
                  <div className="padding-lr">
                    <Link to="/tv/live">
                      <ButtonWithIcon
                        classNamesProp="w-100 mt-5"
                        text="FULL SCHEDULE"
                        iconSrc={hand}
                      />
                    </Link>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: isAuthenticated(state),
  user: state.user,
});

export default connect(mapStateToProps)(LiveStreamVideoPage);
