import superagent from 'superagent';

const FETCH_SHOPPING_CART = '/shopping_cart'

const fetchShoppingCart = () => {
  return superagent.get(FETCH_SHOPPING_CART).set('Accept', 'application/json');
};

const addProductToCart = (products) => {
  return superagent
    .post(FETCH_SHOPPING_CART)
    .set('Accept', 'application/json')
    .send({ products });
};

const updateProductQuantity = (productId, quantity) => {
  return superagent
    .post(FETCH_SHOPPING_CART)
    .set('Accept', 'application/json')
    .send({
      product_id: productId,
      quantity: quantity,
      type: 'update_quantity'
    });
};

const removeProductsFromCart = () => {
  return superagent
    .delete(FETCH_SHOPPING_CART)
    .set('Accept', 'application/json')
}

export default { fetchShoppingCart, addProductToCart, updateProductQuantity, removeProductsFromCart };
