import React, { Component, Fragment } from "react";
import { injectStripe, CardElement } from "react-stripe-elements";
import ReactTooltip from "react-tooltip";
import Loading from "../../../../assets/images/icons/yinyang_icon.png";
import hand from "../../../../assets/images/icons/hand.svg";

class StripeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      errorMessage: null,
      cardIsComplete: false,
    };
  }

  onCardSubmit = ev => {
    const { blockPopupClose, checkEmailIsValid } = this.props;
    ev.preventDefault();

    if (!checkEmailIsValid()) return;

    this.setState({ isSaving: true, errorMessage: null });

    blockPopupClose(true)

    this.props.stripe
      .createToken({
        name: `${this.props.user.first_name} ${this.props.user.last_name}`
      })
      .then(res => {
        if (res.token) {
          this.props.onCardSubmit(res.token.id);
          this.setState({ isSaving: false });
        } else if (res.error) {
          this.setState({ errorMessage: res.error.message, isSaving: false });
          blockPopupClose(false)
        } else {
          this.setState({
            errorMessage: "Something went wrong. Try again",
            isSaving: false
          });
          blockPopupClose(false)
        }
      });
  };

  onCardChange = (event) => {
    const { errorMessage, cardIsComplete } = this.state;
    if (errorMessage) {
      this.setState({ errorMessage: null });
    }

    if (event.complete !== cardIsComplete) {
      this.setState({ cardIsComplete: event.complete });
    }

    if (event.complete) {
      this.props.changeIdempKeys()
    }
  };

  render() {
    const { errorMessage, isSaving, cardIsComplete } = this.state;
    const { loading, error, disabled, user, pageContent } = this.props;

    return (
      <Fragment>
        <form onSubmit={this.onCardSubmit}>
          <div className="card-el-wrapper">
            <CardElement
              style={{
                base: {
                  backgroundColor: '#FFFFFF',
                  fontSize: "18px",
                  fontFamily: "'News Cycle', sans-serif",
                  letterSpacing: "2px",
                  fontWeight: "100",
                  '::placeholder': {
                    color: '#111111',
                  },
                },
              }}
              onChange={(e) => this.onCardChange(e)}
              onReady={c => {
                this._element = c;
                this._element.focus();
              }}
              hidePostalCode
            />
          </div>
          {user.card_last_four && pageContent.card_tooltip && (
            <div className="text-center">
              <ReactTooltip className="info-tooltip" place="bottom" />
              <p
                className="info-tooltip-tagline mt-2 cursor-pointer"
                data-tip={pageContent.card_tooltip}
              >
                Why do I need a new card?
              </p>
            </div>
          )}

          {isSaving || loading ? (
            <div className="inline-loading subscribe-modal-loading mt-4 mb-4">
              <img src={Loading} height="21" width="21" />
            </div>
          ) : (
            <Fragment>
              {(errorMessage || error) && (
                <div
                  className="color-red text-18 text-center mt-4 mb-4"
                >
                  {errorMessage || error.message}
                </div>
              )}

              <button
                className="Button w-100 high-button rounded gift-card-button"
                type="submit"
                disabled={disabled || !cardIsComplete}
              >
                PURCHASE AND SEND!
                <img src={hand} width={21} height={19} alt="open" />
              </button>
            </Fragment>
          )}
        </form>
      </Fragment>
    );
  }
}

export default injectStripe(StripeForm);
