import React from 'react';

const RenderError = () => {
  return (
    <div>
      <div className="Empty-result">Something went wrong on our end :( Please try again later</div>
    </div>
  )
}

export default RenderError;
