import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Popup from '../popup.jsx';
import { Link } from 'react-router-dom';

import { bookLesson, cancelLesson } from '../../actions/lesson';
import { closeLessonPopup } from '../../actions/lesson_popup';
import { getBalance } from '../../actions/balances';
import { deselectLesson } from '../../actions/active_lesson';

import BookLessonButton from '../book_lesson_button.jsx';
import Hoverable from '../hoverable';
import { isBooked, waitlistEntry } from '../../reducers/user_schedule';
import Star from '../../../../assets/images/icons/credit.png';
import LoginForm from '../lesson_booking/login_form';
import RegistrationForm from "../lesson_booking/register";
import PaymentForm from "../lesson_booking/payment_form";
import ServicePayment from "../service_payment";

import { isAuthenticated, hasRequiredMboInfo } from '../../reducers/user';
import LessonApi from '../../api/lesson';
import WaitlistApi from '../../api/waitlist';
import { fetchUserSchedule } from "../../actions/user_schedule";
import {history} from "../../store/configureStore";

import DropdownSelect from '../dropdown_select.jsx';
import RenderError from '../render_error';
import { fetchServicesFor } from '../../actions/services';
import { checkoutTotal } from "../../actions/checkout_total";
import { servicePriceWithDiscount } from '../../helpers/service_price_with_discount';

class LessonPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      location: null,
      loading: false,
      selectedService: null,
      step: 'lesson',
      error: null,
      showWaitlist: false,
      showdropdown: false,
      removingFromWaitlist: false,
      popUpBackBTn: false,
      prevStep: null
    };
  }

  componentWillMount() {
    const { locations, lesson, fetchServicesFor } = this.props;
    const selectedLocation = locations.find(l => lesson.location.id === l.mindbody_identifier)
    this.setState({ location: selectedLocation });

    fetchServicesFor(lesson.id);
    document.body.classList.add('overflow-hidden');
  }

  componentWillUnmount() {
    this.props.deselectLesson();
    document.body.classList.remove('overflow-hidden');
  }

  renderCurrentStep = () => {
    const { lesson } = this.props
    const { showWaitlist } = this.state;

    switch(this.state.step) {
      case 'login':
        return <LoginForm
          changeStep={(step) => this.changeStep(step)}
          continueBooking={() => this.bookLesson(lesson)}
        />
      case 'register':
        return <RegistrationForm
          changeStep={(step) => this.changeStep(step)}
          continueBooking={() => this.bookLesson(lesson)}
        />
      case 'payment':
        return <PaymentForm
          lessonId={lesson.id}
          changeStep={(step) => this.changeStep(step)}
          backBtn={(val)=> this.setState({popUpBackBTn: val})}
          selectService={(selectedService) => this.setState({ selectedService })}
        />
      case 'billing':
        return <ServicePayment
          activeService={this.state.selectedService}
          afterCheckout={() => this.bookLesson(lesson, showWaitlist)}
          hideCheckoutSuccessPopup
        />
      case 'success':
        return this.renderSuccess();
      case 'successWaitlist':
        return this.renderSuccessJoinwaitlist();
      default:
        return this.renderLesson()
    }
  }

  changeStep = (step) => {
    this.setState({ step })
  }

  bookLesson = (lesson, joinWaitlist = false) => {
    const { isAuthenticated, fetchUserSchedule, getBalance, hasRequiredMboInfo } = this.props;
    this.setState({ error: null });

    if (!isAuthenticated || !hasRequiredMboInfo) {
      history.push({
        pathname: isAuthenticated ? "/edit-profile" : "/login",
        state: { cameFrom: (window.location.pathname+window.location.search) }
      })
    } else {
      this.setState({ loading: true });
      LessonApi.bookLesson(lesson.id, joinWaitlist)
        .then(res => {
          this.setState({
            step: joinWaitlist ? 'successWaitlist' : 'success',
            loading: false,
            showWaitlist: false,
            popUpBackBTn: false
          });
          fetchUserSchedule();
          getBalance();
        })
        .catch((error) => {
          if (error.response.body && error.response.body.message === 'no balance') {
            this.setState({ step: 'payment', loading: false, showWaitlist: false, prevStep: 'payment' });
          } else if ((error.response.body && error.response.body.message === 'full class')) {
            this.setState({ showWaitlist: true, loading: false });
          } else {
            this.setState({ error: error.response.body, loading: false, showWaitlist: true, showdropdown: true })
          }
        });
    }
  }

  removeFromWaitlist = (waitlistId) => {
    const { fetchUserSchedule, getBalance } = this.props;

    this.setState({ removingFromWaitlist: true, error: null });
    WaitlistApi.removeFromWaitlist(waitlistId)
      .then((res) => {
        fetchUserSchedule();
        getBalance();
        this.setState({ removingFromWaitlist: false });
      })
      .catch((err) => {
        this.setState({
          removingFromWaitlist: false,
          error: err.response.body,
        });
      });
  }

  renderReserveButton = (lesson) => {
    const { isBooked, cancelLesson, waitlistEntry } = this.props;
    const { showWaitlist, loading, removingFromWaitlist } = this.state;

    const loadingText = removingFromWaitlist ? 'Wait...' : 'Booking...';

    if (loading || removingFromWaitlist) return (
      <button className="Button mb-4" disabled >
        <span>{loadingText}</span>
      </button>
    )

    if (waitlistEntry) return (
      <button
        className="Button Cancel-button"
        onClick={() => this.removeFromWaitlist(waitlistEntry.id)}
      >
        <span className="Overlay">
          Added to waitlist
        </span>
        <span className="Hover">REMOVE FROM WAITLIST</span>
      </button>
    )

    if (isBooked) return (
      <button
        className="Button Cancel-button"
        onClick={() => cancelLesson(lesson)}
      >
        <span className="Overlay">
          RESERVED
          <img className="Schedule-bookedImage" src={Star} alt="&#9733;" />
        </span>
        <span className="Hover">CANCEL</span>
      </button>
    )

    return (
      <BookLessonButton
        label={ showWaitlist ? "Join Waitlist" : "Reserve a spot" }
        reservedLabelText={ showWaitlist ? "Joined Waitlist" : "Reserved" }
        onClick={() => this.bookLesson(lesson, showWaitlist)}
        lessonId={lesson.id}
        className="Button Reserve-lesson-btn"
      />
    )
  }

  getValues = (service) => {
    const price = servicePriceWithDiscount(
      service.online_price, service.membership_discount, this.props.userMembership
    )
    return ({ name: `${service.name} - $${price}`, id: service.id })
  }

  onFiltersChange = (event) => {
    const { value } = event.target

    if (value) {
      this.setState({ selectedService: this.findService(value) });
    }
  }

  findService = (service_id) => {
    return this.props.services.services.find(s => s.id === service_id)
  }

  openBilling = (service) => {
    if (!service.contract) {
      this.props.checkoutTotal([{
        mindbody_identifier: service.id,
        quantity: 1,
      }], 'Service')
    }
    this.changeStep('billing');
    this.setState({popUpBackBTn: true, prevStep: 'lesson'})
  }

  renderLesson = () => {
    const { lesson, policy } = this.props
    const { location, error, showWaitlist, selectedService, showdropdown } = this.state
    const {services, servicesError} = this.props

    if (servicesError) return <RenderError error={servicesError} />;
    const tingsOptions = services.services.map(s => this.getValues(s))

    return (
      <div className="Lesson">
        <div className="Lesson-title">{lesson.name}</div>
        <div className="Lesson-subtitle">
          {`${lesson.startDay} @ ${lesson.startTime} - ${lesson.endTime}`}
        </div>
        { error &&
        <div className="error">
          { error.message ? error.message : "Something went wrong, please try later" }
        </div>
        }
        {showdropdown &&
          <div className="Lesson-dropdown">
            <div className="PaymentPopup-input">
              <DropdownSelect
                options={tingsOptions}
                onChange={this.onFiltersChange}
                optionLabel="name"
                optionId="id"
                placeholder="select option"
                name="service"
                filters={selectedService ? { service: selectedService.id } : {}}
                isCollapse
              />
            </div>
            <button
              onClick={() => this.openBilling(selectedService)}
              className="Button lesson-dropdown-btn"
              disabled={!selectedService}
            >
              Reserve a spot
            </button>
          </div>
        }
        { showWaitlist && !showdropdown && <div className="error">The class is full</div> }
        {!showdropdown && this.renderReserveButton(lesson)}
        {lesson.description &&
        <div className="Lesson-description" dangerouslySetInnerHTML={{__html: lesson.description}}/>
        }

        { lesson.staffImg &&
        <Fragment>
          { lesson.staffImg && lesson.staffSecondImg ? (
            <div className="Lesson-staff-two-images">
              <div className="lesson-staff-img-wrapper mr-2">
                <img src={lesson.staffImg} alt={lesson.staffName} />
              </div>
              <div className="lesson-staff-img-wrapper ml-2">
                <img src={lesson.staffSecondImg} alt={lesson.staffName} />
              </div>
            </div>
          ) : (
            <div className="Lesson-staff-image">
              <img src={lesson.staffImg} alt={lesson.staffName} />
            </div>
          )}
        </Fragment>
        }

        { lesson.teacherIsActive ? (
          <Link className="Button Hover-change" title="Visit teachers page" to={`/teachers?teacher=${lesson.teacherSlug}`}>
            <Hoverable hoverText="See teacher bio" key="1">
              {lesson.staffName}
            </Hoverable>
          </Link>
        ) : (
          <div className="Button inactive-teacher" >
            {lesson.staffName}
          </div>
        )}

        { (location && location.location_images.length > 0) &&
        <div className="Location-image-wrapper">
          <img src={location.location_images[0].image_url} alt={lesson.locationName} />
        </div>
        }
        {/*<Link to="/locations" title="Visit locations page" className="Button Hover-change" >*/}
        {/*  <Hoverable hoverText="See other locations" key="7">*/}
        {/*    {lesson.locationName}*/}
        {/*  </Hoverable>*/}
        {/*</Link>*/}

        <div className="Lesson-locationAddress">{lesson.address}</div>
        <div className="Lesson-locationAddress">{lesson.locationPhone}</div>
        { policy &&
        <div className="Policy-text">
          <div className="SubHeadline">CANCELLATION POLICY</div>
          <div className="SmallBodyText" dangerouslySetInnerHTML={{ __html: policy.text }}/>
        </div>
        }
      </div>
    )
  }

  renderSuccess = () => {
    return(
      <div className="Lesson">
        <div className="Lesson-title">{this.props.lesson.name}</div>
        <div className="mt-5 mb-5 text-uppercase">Lesson was successfully booked</div>
      </div>
    )
  }

  renderSuccessJoinwaitlist = () => {
    return(
      <div className="Lesson">
        <div className="Lesson-title">{this.props.lesson.name}</div>
        <div className="mt-5 mb-5 text-uppercase">You have been added to the wait list!</div>
      </div>
    )
  }

  gobackLessonPopup = () =>{
    const { prevStep } = this.state
    this.setState({step: prevStep, popUpBackBTn: false})
    history.goBack();
  }

  render() {
    const { lesson, isOpen, closeLessonPopup } = this.props;
    const { popUpBackBTn } = this.state;

    if (!lesson) return <div />
    return (
      <Popup
        key={lesson.id}
        id={lesson.id}
        isOpen={isOpen}
        onClose={() => closeLessonPopup()}
        showBackBtn={popUpBackBTn}
        onBack={() => this.gobackLessonPopup()}
      >
        {this.renderCurrentStep()}
      </Popup>
    )
  }
}

LessonPopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({
  isOpen: state.lessonPopup.show,
  lesson: state.activeLesson,
  locations: state.locations.locations,
  isBooked: state.activeLesson ? isBooked(state.activeLesson.id, state) : false,
  waitlistEntry: waitlistEntry(state.activeLesson.id, state),
  isAuthenticated: isAuthenticated(state),
  hasRequiredMboInfo: hasRequiredMboInfo(state),
  services: state.services,
  servicesError: state.services.error,
  userMembership: state.userMembership.membership,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      closeLessonPopup,
      bookLesson,
      cancelLesson,
      fetchUserSchedule,
      getBalance,
      deselectLesson,
      fetchServicesFor,
      checkoutTotal,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(LessonPopup);
