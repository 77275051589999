import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import superagent from "superagent";
import { history } from "../../store/configureStore";
import ComponentWithLoading from "../component_with_loading";
import { Elements, StripeProvider } from "react-stripe-elements";
import StripeForm from "./stripe_form";
import Script from "react-load-script";
import Api from "../../api/video_subscription";
import { v4 as uuidv4 } from "uuid";
import { requestAvailable } from "../../helpers/request_available";
import play from "../../../../assets/images/icons/Play.svg";
import ButtonWithIcon from "../button_with_icon";
import GiftCardPayment from "./gift_card_payment";
import { updateUserInfo } from "../../actions/user_update";
import TagManager from "react-gtm-module";
import RenderPlanPrice from "./render_plan_price";
import calcPriceWithCoupon from "../../helpers/calc_price_with_coupon";
import addTagManagerEvent from "../../helpers/add_tag_manager_event";
import Coupon from "./coupon";
import Popup from "../popup";

class Payment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userBalance: null,
      loadingCards: true,
      selectedPayment: null,
      loading: false,
      cardsError: null,
      subscriptionError: null,
      idemp_key_customer: null,
      idemp_key_subscription: null,
      idemp_key_balance_increase: null,
      idemp_key_balance_decrease: null,
      requestSentAt: null,
      giftCardIsChosen: false,
      showNewCardForm: false,
      stripe: null,
      paymentOptions: [],
      coupon: null,
      couponPercentOff: null,
      couponAmountOff: null,
      couponDuration: null,
      couponDurationInMonths: null,
      couponApplied: false,
      updateSubscriptionPopupModal: false
    };
  }

  componentDidMount() {
    this.getUserCards();
    this.generateIdempKey();
    TagManager.dataLayer({ dataLayer: { "User-Status": "Step-3" } });
  }

  getUserCards = () => {
    return superagent
      .get(`/users/stripe_sources?check_user_balance=true`)
      .set("Accept", "application/json")
      .then(res => {
        this.setState({
          paymentOptions: this.paymentOptions(res.body.cards),
          userBalance: res.body.user_balance,
          loadingCards: false
        });

        if (res.body.cards.length === 0) {
          this.setState({ showNewCardForm: true });
        }
      })
      .catch(error => {
        this.setState({
          cardsError: error.response.body,
          loadingCards: false
        });
      });
  };

  paymentOptions = stripeCards => {
    if (stripeCards.length === 0) return [];

    // option: { id: 'ID', label: 'LABEL' }
    const options = stripeCards.map(s => {
      return { id: s["id"], label: `XXXX XXXX XXXX ${s["last4"]}` };
    });

    options.push({ id: "new_card", label: "ADD A NEW CARD" });

    return options;
  };

  onPaymentSelect = event => {
    const { paymentOptions } = this.state;

    const option = paymentOptions.find(o => o.id === event.target.value);

    if (option.id === "new_card") {
      this.setState({
        selectedPayment: null,
        showNewCardForm: true,
        giftCardIsChosen: false
      });
    } else {
      this.setState({ selectedPayment: option, showNewCardForm: false });
    }
  };

  setStripeKey = () => {
    const strKey = gon.stripe_pub_key;
    this.setState({ stripe: window.Stripe(strKey) });
  };

  renderNewCardForm = () => {
    const { user, subscription: { status } } = this.props;

    const {
      loading,
      subscriptionError,
      giftCardIsChosen,
      showNewCardForm,
      coupon,
      couponPercentOff,
      couponDuration,
      couponDurationInMonths
    } = this.state;

    return (
      <div className="new-card-container">
        <div className="payment-subtitle">ADD A NEW CARD</div>
        <StripeProvider stripe={this.state.stripe}>
          <Elements>
            <StripeForm
              user={user}
              confirm={token => this.confirm(token)}
              creatingCard={loading}
              subscriptionError={subscriptionError}
              changeIdempKeys={() => this.generateIdempKey()}
              showBtn={showNewCardForm || status === "pending"}
              chooseGiftCard={val => this.chooseGiftCard(val)}
              giftCardIsChosen={giftCardIsChosen}
              coupon={coupon}
              couponPercentOff={couponPercentOff}
              couponDuration={couponDuration}
              couponDurationInMonths={couponDurationInMonths}
              availableBalance={this.calcAvailableGiftBalance()}
              subscriptionStatus={status}
            />
          </Elements>
        </StripeProvider>
      </div>
    );
  };

  chooseGiftCard = () => {
    const { giftCardIsChosen } = this.state;
    this.setState({ giftCardIsChosen: !giftCardIsChosen });
  };

  confirm = stripe_tok => {
    const {
      subscription: { status }
    } = this.props;

    if (status === "pending") {
      this.reactivateSubscription(stripe_tok);
    } else {
      this.createSubscription(stripe_tok);
    }
  };

  reactivateSubscription = stripe_tok => {
    const { subscription: { subscription } } = this.props;
    const plan = subscription.item;

    this.setState({
      loading: true,
      subscriptionError: null
    });

    return superagent
      .post("/reactivate_subscription")
      .set("Accept", "application/json")
      .send({ stripe_tok })
      .then(res => {
        this.setState({ loading: false });
        const userStatus =
          plan.interval === "year"
            ? "Reactivated-annualy"
            : "Reactivated-monthly";
        addTagManagerEvent(window.location.pathname, userStatus);
        document.cookie = `user_tv_subscription=${plan.interval}`;

        history.push("/tv?subscription_completed=true");
      })
      .catch(error => {
        this.setState({
          subscriptionError: error.response.body,
          loading: false
        });
      });
  };

  createSubscription = (stripe_tok = null) => {
    const { selectedPlan, updateUserInfo, comeFrom, page } = this.props;
    const { coupon } = this.state;

    const {
      idemp_key_customer,
      idemp_key_subscription,
      idemp_key_balance_increase,
      idemp_key_balance_decrease,
      requestSentAt,
      selectedPayment,
      giftCardIsChosen
    } = this.state;

    if (!requestAvailable(requestSentAt)) return;

    const default_source =
      selectedPayment && selectedPayment.id !== "new_card"
        ? selectedPayment.id
        : null;

    this.setState({
      loading: true,
      subscriptionError: null,
      requestSentAt: new Date().getTime()
    });

    const giftCardAmount = giftCardIsChosen
      ? this.calcAvailableGiftBalance()
      : null;

    Api.createSubscription(
      selectedPlan.id,
      idemp_key_customer,
      idemp_key_subscription,
      idemp_key_balance_increase,
      idemp_key_balance_decrease,
      stripe_tok,
      coupon,
      giftCardAmount,
      default_source
    )
      .then(res => {
        this.setState({ loading: false });
        if (res.body.user) {
          updateUserInfo(res.body.user);
        }
        const userStatus =
          selectedPlan.interval === "year"
            ? "Completed-annualy"
            : "Completed-monthly";
        addTagManagerEvent(window.location.pathname, userStatus);
        document.cookie = `user_tv_subscription=${selectedPlan.interval}`;
        if (page == 'TvVideos') {
          history.push(`${comeFrom}`);
        }
        else {
          history.push("/tv?subscription_completed=true");
        }
      })
      .catch(err => {
        this.setState({
          subscriptionError: err.response.body,
          loading: false
        });
      });
  };

  updateSubscription = () => {
    const {
      subscription: { subscription },
      selectedPlan,
    } = this.props;

    const { coupon } = this.state;

    this.setState({ loading: true, subscriptionError: null });

    Api.updateSubscription(
      subscription.id,
      subscription.item.id,
      selectedPlan.id,
      coupon,
    )
      .then(res => {
        this.setState({ loading: false });
        this.setState({updateSubscriptionPopupModal: true});
        setTimeout(()=>{
          this.closePopup()
        }, 3000)
      })
      .catch(err => {
        this.setState({
          subscriptionError: err.response.body,
          loading: false
        });
      });
  };

  closePopup = () => {
    this.setState({updateSubscriptionPopupModal: false});
    history.push("/account");
  }

  generateIdempKey = () => {
    const idemp_key_customer = uuidv4();
    const idemp_key_subscription = uuidv4();
    const idemp_key_balance_increase = uuidv4();
    const idemp_key_balance_decrease = uuidv4();

    this.setState({
      idemp_key_customer,
      idemp_key_subscription,
      idemp_key_balance_increase,
      idemp_key_balance_decrease
    });
  };

  calcAvailableGiftBalance = () => {
    const { userBalance, couponPercentOff, couponAmountOff } = this.state;
    const { selectedPlan } = this.props;

    if (!userBalance || userBalance === 0) return 0;

    const price = calcPriceWithCoupon(
      couponPercentOff,
      couponAmountOff,
      selectedPlan.amount_in_cents
    );

    if (userBalance >= price) {
      return price;
    } else {
      return userBalance;
    }
  };

  planName = () => {
    const { selectedPlan, subscription: { subscription, status } } = this.props;
    const interval = status === "pending" ? subscription.item.interval : selectedPlan.interval;

    return interval === "year" ? "yearly" : "monthly";
  };

  showContent = () => {
    const { subscription } = this.props;

    switch (subscription.status) {
      case "active":
        return this.renderUpdateScreen();
      case "pending":
        return this.renderReactivateScreen();
      default:
        return this.renderSubscribeScreen();
    }
  };

  renderCoupon = () => {
    const { selectedPlan, subscription: { status } } = this.props;
    const { coupon, couponApplied } = this.state;

    return (
      <>
        <Coupon
          setCoupon={coupon => this.setState({ coupon, couponApplied: true })}
          setCouponValues={(
            percentOff,
            amountOff,
            duration,
            months,
          ) =>
            this.setState({
              couponPercentOff: percentOff,
              couponAmountOff: amountOff,
              couponDuration: duration,
              couponDurationInMonths: months
            })
          }
          couponVal={coupon}
          couponApplied={couponApplied}
          subscriptionStatus={status}
          selectedPlan={selectedPlan}
        />
      </>
    )
  }

  renderSubscribeScreen = () => {
    const {
      paymentOptions,
      loading,
      selectedPayment,
      showNewCardForm,
      subscriptionError,
      couponPercentOff,
      couponAmountOff,
      coupon
    } = this.state;

    const { selectedPlan } = this.props;

    const noPaymentNeeded =
      (coupon && couponPercentOff && Number(couponPercentOff) === 100) ||
      (selectedPlan && selectedPlan.amount_in_cents === 0);

    return (
      <>
        {!noPaymentNeeded && (
          <Fragment>
            {paymentOptions.length > 0 && (
              <Fragment>{this.selectPaymentType()}</Fragment>
            )}
            {paymentOptions.length > 0 && showNewCardForm && (
              <div className="text-with-line">
                <div className="line" />
                <span className="text">OR</span>
                <div className="line" />
              </div>
            )}

            {showNewCardForm && this.renderNewCardForm()}
          </Fragment>
        )}
        {this.renderCoupon()}
        {(noPaymentNeeded || !showNewCardForm) && (
          <Fragment>
            {subscriptionError && (
              <div className="input-error-red">
                {subscriptionError.message || "Something went wrong"}
              </div>
            )}

            <div className="mb-2 pt-1" />
            <ComponentWithLoading isLoading={loading}>
              {coupon &&
                (couponPercentOff || couponAmountOff) &&
                (noPaymentNeeded || (!noPaymentNeeded && selectedPayment)) && (
                  <div className="coupon-applied">
                    {couponPercentOff
                      ? `${couponPercentOff}% OFF COUPON CODE APPLIED!`
                      : `$${Number(couponAmountOff) /
                          100} OFF COUPON CODE APPLIED!`}
                  </div>
                )}
              <ButtonWithIcon
                classNamesProp="high-75 confirm-button ga-tv-subscribe-end ga-tv-step-3 mt-3"
                text="START WATCHING"
                iconSrc={selectedPayment || noPaymentNeeded ? play : null}
                disabled={!noPaymentNeeded && !selectedPayment}
                onClick={() => this.createSubscription()}
              />
            </ComponentWithLoading>
          </Fragment>
        )}
      </>
    );
  };

  renderUpdateScreen = () => {
    const { loading } = this.state;

    const {
      subscription: { subscription },
      selectedPlan
    } = this.props;

    const { plan_id } = subscription.item;
    const samePlan = selectedPlan.id === plan_id;
    const {updateSubscriptionPopupModal} = this.state;

    if (samePlan)
      return (
        <>
          <div className="input-error-red mb-4">
            You already subscribed to this plan. Please choose another plan
          </div>
          <Link to="/pricing" className="Button high-75 confirm-button mt-3">
            Choose another plan
          </Link>
        </>
      );

    return (
      <>
        {this.renderCoupon()}
        <ComponentWithLoading isLoading={loading}>
          <ButtonWithIcon
            classNamesProp="high-75 confirm-button ga-tv-subscribe-end ga-tv-step-3 mt-3"
            text="UPGRADE PLAN"
            iconSrc={play}
            onClick={() => this.updateSubscription()}
          />
        </ComponentWithLoading>
        {updateSubscriptionPopupModal && <Popup key={1} id={1} isOpen={updateSubscriptionPopupModal} onClose={this.closePopup}>
          <div className="Lesson">
            <div className="Lesson-title">SKY TING YOGA</div>
            <div className="mt-5 mb-5 text-uppercase">Thank you! Your SKY TING subscription has been updated!</div>
          </div>
        </Popup>}
      </>
    );
  }

  renderReactivateScreen = () => {
    return <>{this.renderNewCardForm()}</>;
  };

  renderTitle = () => {
    const { subscription: { status } } = this.props;

    switch (status) {
      case "active":
        return "Update your subscription";
      case "pending":
        return "Reactivate subscription";
      default:
        return "Add payment method";
    }
  };

  selectPaymentType = () => {
    const {
      paymentOptions,
      selectedPayment,
      showNewCardForm,
      giftCardIsChosen
    } = this.state;

    return (
      <div className={showNewCardForm ? "faded" : ""}>
        <div className="payment-subtitle">SELECT PAYMENT TYPE</div>
        <select
          className="payment-select"
          id="payment-select"
          onChange={event => this.onPaymentSelect(event)}
          value={selectedPayment ? selectedPayment.id : ""}
        >
          <option value="" disabled hidden>
            SELECT CARD
          </option>
          {paymentOptions.map(option => (
            <option key={option.id} value={option.id}>
              {option.label}
            </option>
          ))}
        </select>
        {!showNewCardForm && (
          <GiftCardPayment
            chooseGiftCard={() => this.chooseGiftCard()}
            isChecked={giftCardIsChosen}
            disabled={!selectedPayment}
            availableBalance={this.calcAvailableGiftBalance()}
          />
        )}
      </div>
    );
  };

  render() {
    const {
      loadingCards,
      couponPercentOff,
      couponAmountOff,
      couponDuration,
      couponDurationInMonths,
    } = this.state;

    const {
      pageContent,
      selectedPlan,
      subscription: { subscription, status }
    } = this.props;

    return (
      <Fragment>
        <Script
          url="https://js.stripe.com/v3/"
          attributes={{ id: "stripe-js" }}
          onLoad={() => this.setStripeKey()}
        />
        <ComponentWithLoading isLoading={loadingCards}>
          <div className="payment">
            <div className="step">
              {(pageContent && pageContent.payment_step) || "STEP 2"}
            </div>
            <div className="signup-page-subtitle">{this.renderTitle()}</div>
            {status === 'active' &&
              <div className="secondary-message mt-2">
                { subscription.item && subscription.item.amount_in_cents &&
                  <>
                    <span>PREVIOUS PRICE:&nbsp;</span>
                    <div className="render-plan-price">
                      ${subscription.item.amount_in_cents / 100} / {subscription.item.interval}
                    </div>
                  </>
                }
              </div>
            }
            <div className={`secondary-message ${status === 'active' ? 'green' : ''}`}>
              {selectedPlan && (
                <Fragment>
                  { status === 'active' ? (
                    <span>NEW PLAN PRICE:&nbsp;</span>
                  ) : (
                    <>{this.planName()} /&nbsp; </>
                  )}
                  { status === 'pending' ? (
                    <div className="render-plan-price">
                      ${subscription.item.amount_in_cents / 100} / {subscription.item.interval}
                    </div>
                  ) : (
                    <RenderPlanPrice
                      selectedPlan={selectedPlan}
                      couponPercentOff={couponPercentOff}
                      couponAmountOff={couponAmountOff}
                      couponDuration={couponDuration}
                      couponDurationInMonths={couponDurationInMonths}
                    />
                  )}
                </Fragment>
              )}
            </div>
            <hr />
            { status === 'active' ? (
              <div className="signup-page-text">
                <div>Are you sure you want to update your Sky Ting Subscription?</div>
                <div className="font-weight-bold">Your updated billing will start on {moment().format("MM/DD/YYYY")}</div>
              </div>
            ) : (
              <div
                className="signup-page-text"
                dangerouslySetInnerHTML={{
                  __html: pageContent && pageContent.payment_text
                }}
              />
            )}

            <div className="form-container mt-4">{this.showContent()}</div>
          </div>
        </ComponentWithLoading>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  balance: state.balance,
  user: state.user,
  comeFrom: state.redirectUrl.comeFrom,
  page: state.redirectUrl.page
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateUserInfo
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Payment);
