import Api from "../api/cart";
import { isAuthenticated } from "../reducers/user";
import { dropdownChange } from './dropdowns';

export const UPDATE_SHOPPING_CART = "UPDATE_SHOPPING_CART";
export const UPDATE_SHOPPING_CART_ERROR = "UPDATE_SHOPPING_CART_ERROR";
export const UPDATE_SHOPPING_CART_SUCCESS = "UPDATE_SHOPPING_CART_SUCCESS";

// export const OPEN_CART = "OPEN_CART";
// export const CLOSE_CART = "CLOSE_CART";
export const ADD_TO_CART_UNAUTHORIZED = "ADD_TO_CART_UNAUTHORIZED";
export const UPDATE_CART_UNAUTHORIZED = "UPDATE_CART_UNAUTHORIZED";
export const WAIT_FOR_AUTHENTICATION = "WAIT_FOR_AUTHENTICATION";
export const CLEAR_UNAUTHORIZED_CART = "CLEAR_UNAUTHORIZED_CART";
export const UPDATE_UNAUTHORIZED_CART = "UPDATE_UNAUTHORIZED_CART";

// export const openCart = () => dispatch => {
//   dispatch({ type: OPEN_CART });
// };
// export const closeCart = () => dispatch => dispatch({ type: CLOSE_CART });

export const fetchShoppingCart = () => dispatch => {
  dispatch({ type: UPDATE_SHOPPING_CART });

  Api.fetchShoppingCart()
    .then(res =>
      dispatch({
        type: UPDATE_SHOPPING_CART_SUCCESS,
        items: res.body
      })
    )
    .catch(err =>
      dispatch({
        type: UPDATE_SHOPPING_CART_ERROR,
        error: err
      })
    );
};

const addToCartUnauthorized = params => (dispatch, getState) => {
  const state = getState();
  const { items } = state.unauthorizedCart;
  const { product, quantity, size, color } = params;

  const itemInCart = items.find(i => i.id === product.id && i.size === size)
  if (itemInCart) {
    const newQty = Number(itemInCart.quantity) + Number(quantity);
    dispatch(updateProductQtyUnauthorizedCart(product.id, newQty, size));
  } else {
    const reqParams = {};

    reqParams.id = product.id;
    reqParams.name = product.name;
    reqParams.online_price = product.online_price;
    reqParams.price = product.price;
    reqParams.membership_discount = product.membership_discount;
    reqParams.color_hex = product.color_hex;
    reqParams.group_identifier = product.group_identifier;
    reqParams.quantity = Number(quantity);
    reqParams.size = size || "None";
    reqParams.color = color || "None";
    reqParams.slug = product.slug;
    reqParams.image_url = product.thumbnail_url || null;

    dispatch({ type: ADD_TO_CART_UNAUTHORIZED, product: reqParams });
  }
  dispatch(dropdownChange({ cart_items: true }));
};

export const addProductToCart = params => (dispatch, getState) => {
  const state = getState();

  if (!isAuthenticated(state)) {
    return dispatch(addToCartUnauthorized(params));
  } else {
    dispatch({ type: UPDATE_SHOPPING_CART });

    const { product, isShipping, quantity, size, color } = params;
    const { cart: { itemQuantity } } = state;

    const productColor = isShipping ? null : color;
    const productSize = isShipping ? null : (size || "None");
    const productQuantity = isShipping ? 1 : quantity;

    const products = [
      {
        group_id: product.group_identifier,
        quantity: productQuantity,
        color: productColor,
        size: productSize,
        type: "add_item"
      }
    ];

    Api.addProductToCart(products)
      .then(res => {
        dispatch({
          type: UPDATE_SHOPPING_CART_SUCCESS,
          items: res.body
        });
        dispatch(dropdownChange({ cart_items: true }));
      })
      .catch(err =>
        dispatch({
          type: UPDATE_SHOPPING_CART_ERROR,
          error: err
        })
      );
  }
};

export const updateProductQuantity = (productId, quantity) => dispatch => {
  dispatch({ type: UPDATE_SHOPPING_CART });

  Api.updateProductQuantity(productId, quantity)
    .then(res =>
      dispatch({
        type: UPDATE_SHOPPING_CART_SUCCESS,
        items: res.body
      })
    )
    .catch(err =>
      dispatch({
        type: UPDATE_SHOPPING_CART_ERROR,
        error: err
      })
    );
};

export const removeProductsFromCart = () => dispatch => {
  dispatch({ type: UPDATE_SHOPPING_CART });

  Api.removeProductsFromCart()
    .then(res =>
      dispatch({
        type: UPDATE_SHOPPING_CART_SUCCESS,
        items: res.body
      })
    )
    .catch(err =>
      dispatch({
        type: UPDATE_SHOPPING_CART_ERROR,
        error: err
      })
    );
};

export const addShipmentToCart = product => (dispatch, getState) => {
  const {
    cart: { items }
  } = getState();

  const shipmentItem = items.find(item => item.is_shipment === true);
  const cartHasCurrentShipment = shipmentItem && shipmentItem.id === product.id;

  if (cartHasCurrentShipment) {
    return;
  }

  dispatch(addProductToCart({ product, isShipping: true }));
};

export const syncShoppingCart = () => (dispatch, getState) => {
  const state = getState();
  const { items } = state.unauthorizedCart;

  if (items.length > 0) {
    const products = items.map(item => {
      return {
        group_id: item.group_identifier,
        quantity: item.quantity,
        color: item.color,
        size: item.size,
        type: "add_item"
      };
    });

    Api.addProductToCart(products)
      .then(res => {
        dispatch({
          type: UPDATE_SHOPPING_CART_SUCCESS,
          items: res.body
        });
        dispatch(clearUnauthorizedCart());
      })
      .catch(err =>
        dispatch({
          type: UPDATE_SHOPPING_CART_ERROR,
          error: err
        })
      );
  }
};

export const waitForAuth = (value = true) => dispatch => {
  dispatch({ type: WAIT_FOR_AUTHENTICATION, value });
};

export const clearUnauthorizedCart = () => dispatch => {
  dispatch({ type: CLEAR_UNAUTHORIZED_CART });
};

export const updateProductQtyUnauthorizedCart = (productId, quantity, size) => (dispatch, getState) => {
  const state = getState();
  const { items } = state.unauthorizedCart;

  const newItemsArray = []
  items.forEach((i) => {
    if (i.id === productId && i.size === size) {
      if (Number(quantity) > 0) {
        i.quantity = quantity;
        newItemsArray.push(i)
      }
    } else {
      newItemsArray.push(i)
    }
  });

  dispatch({ type: UPDATE_UNAUTHORIZED_CART, items: newItemsArray  });
};

export const removeOldUnauthorizedCart = () => (dispatch, getState) => {
  const state = getState();
  const { items } = state.unauthorizedCart;

  const newItemsArray = [];
  items.forEach((i) => {
    if (i.id) {
      newItemsArray.push(i);
    }
  });

  dispatch({ type: UPDATE_UNAUTHORIZED_CART, items: newItemsArray });
};

