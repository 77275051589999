import superagent from 'superagent';

const API_ENDPOINT = '/users/sign_in';
const LOGOUT_ENDPOINT = '/users/sign_out';

const getAuthenticityToken = () => {
  const token = document.getElementsByName('csrf-token');

  if (token && token.length)
    return document.getElementsByName('csrf-token')[0].content;
};

const authenticate = ({ email, password }) => {
  const token = getAuthenticityToken()
  const lowerEmail = email.toLowerCase()

  return superagent
    .post(API_ENDPOINT)
    .set('Accept', 'application/json')
    .set('X-CSRF-Token', token)
    .send({ user: { email: lowerEmail, password } });
};

const logout = () => {
  const token = getAuthenticityToken()

  return superagent
    .delete(LOGOUT_ENDPOINT)
    .set('Accept', 'application/json')
    .set('X-CSRF-Token', token)
    .send();
};

export default { logout, authenticate };
