import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import superagent from "superagent";
import ComponentWithLoading from "../component_with_loading";
import { Elements, StripeProvider } from "react-stripe-elements";
import StripeForm from "./stripe_form";
import Script from "react-load-script";
import Api from "../../api/purchase_video_subscription";
import { v4 as uuidv4 } from "uuid";
import { requestAvailable } from "../../helpers/request_available";
import play from "../../../../assets/images/icons/Play.svg";
import ButtonWithIcon from "../button_with_icon";
import { updateUserInfo } from "../../actions/user_update";
import TagManager from "react-gtm-module";
import calcPriceWithCoupon from "../../helpers/calc_price_with_coupon";
import Coupon from "./coupon";

class Payment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userBalance: null,
      loadingCards: true,
      selectedPayment: null,
      loading: false,
      cardsError: null,
      subscriptionError: null,
      idemp_key_customer: null,
      idemp_key_subscription: null,
      idemp_key_balance_increase: null,
      idemp_key_balance_decrease: null,
      requestSentAt: null,
      giftCardIsChosen: false,
      showNewCardForm: false,
      stripe: null,
      paymentOptions: [],
      coupon: null,
      couponPercentOff: null,
      couponAmountOff: null,
      couponDuration: null,
      couponDurationInMonths: null,
      couponApplied: false,
      updateSubscriptionPopupModal: false,
      isChecked: true
    };
  }

  componentDidMount() {
    this.getUserCards();
    this.generateIdempKey();
    TagManager.dataLayer({ dataLayer: { "User-Status": "Step-3" } });
  }

  getUserCards = () => {
    return superagent
      .get(`/users/stripe_sources?check_user_balance=true`)
      .set("Accept", "application/json")
      .then(res => {
        this.setState({
          paymentOptions: this.paymentOptions(res.body.cards),
          userBalance: res.body.user_balance,
          loadingCards: false
        });

        if (res.body.cards.length === 0) {
          this.setState({ showNewCardForm: true });
        }
        else {
          let user_details = this.state.paymentOptions.find(opt => opt.id !== 'new_card')
          this.setState({ selectedPayment: user_details })
        }
      })
      .catch(error => {
        this.setState({
          cardsError: error.response.body,
          loadingCards: false
        });
      });
  };

  paymentOptions = stripeCards => {
    if (stripeCards.length === 0) return [];

    const options = stripeCards.map(s => {
      return { id: s["id"], label: `XXXX XXXX XXXX ${s["last4"]}` };
    });

    options.push({ id: "new_card", label: "ADD A NEW CARD" });

    return options;
  };

  onPaymentSelect = () => {
    this.setState({
      selectedPayment: null,
      showNewCardForm: true,
      giftCardIsChosen: false,
      isChecked: false
    });
  };

  setStripeKey = () => {
    const strKey = gon.stripe_pub_key;
    this.setState({ stripe: window.Stripe(strKey) });
  };

  renderNewCardForm = () => {
    const { user, subscription: { status } } = this.props;

    const {
      loading,
      subscriptionError,
      giftCardIsChosen,
      showNewCardForm,
      coupon,
      couponPercentOff,
      couponDuration,
      couponDurationInMonths
    } = this.state;

    return (
      <div className="new-card-container">
        <StripeProvider stripe={this.state.stripe}>
          <Elements>
            <StripeForm
              user={user}
              confirm={token => this.createSubscription(token)}
              creatingCard={loading}
              subscriptionError={subscriptionError}
              changeIdempKeys={() => this.generateIdempKey()}
              showBtn={showNewCardForm || status === "pending"}
              chooseGiftCard={val => this.chooseGiftCard(val)}
              giftCardIsChosen={giftCardIsChosen}
              coupon={coupon}
              couponPercentOff={couponPercentOff}
              couponDuration={couponDuration}
              couponDurationInMonths={couponDurationInMonths}
              availableBalance={this.calcAvailableGiftBalance()}
              subscriptionStatus={status}
            />
          </Elements>
        </StripeProvider>
      </div>
    );
  };

  chooseGiftCard = () => {
    const { giftCardIsChosen } = this.state;
    this.setState({ giftCardIsChosen: !giftCardIsChosen });
  };

  createSubscription = (stripe_tok) => {
    const { coupon } = this.state;

    const {
      idemp_key_customer,
      idemp_key_subscription,
      idemp_key_balance_increase,
      idemp_key_balance_decrease,
      requestSentAt,
      selectedPayment,
      giftCardIsChosen,
    } = this.state;

    const {
      activeService
    } = this.props;

    const amount = activeService[0] && activeService[0].id == '1' ? 15 : 5;

    if (!requestAvailable(requestSentAt)) return;

    const default_source =
      selectedPayment && selectedPayment.id !== "new_card"
        ? selectedPayment.id
        : null;

    this.setState({
      loading: true,
      subscriptionError: null,
      requestSentAt: new Date().getTime()
    });

    const giftCardAmount = giftCardIsChosen
      ? this.calcAvailableGiftBalance()
      : null;

    const video_slug = (window.location.search).replace("?", "");
    const isLiveVideo = true;

    Api.createPurchaseVideo(
      idemp_key_customer,
      idemp_key_subscription,
      idemp_key_balance_increase,
      idemp_key_balance_decrease,
      stripe_tok,
      coupon,
      giftCardAmount,
      default_source,
      isLiveVideo,
      video_slug,
      amount
    )
      .then(res => {
        this.setState({ loading: false });
        this.props.continueBooking();
      })
      .catch(err => {
        this.setState({
          subscriptionError: err.response.body,
          loading: false
        });
      });
  };

  generateIdempKey = () => {
    const idemp_key_customer = uuidv4();
    const idemp_key_subscription = uuidv4();
    const idemp_key_balance_increase = uuidv4();
    const idemp_key_balance_decrease = uuidv4();

    this.setState({
      idemp_key_customer,
      idemp_key_subscription,
      idemp_key_balance_increase,
      idemp_key_balance_decrease
    });
  };

  calcAvailableGiftBalance = () => {
    const { userBalance, couponPercentOff, couponAmountOff } = this.state;

    if (!userBalance || userBalance === 0) return 0;

    const price = calcPriceWithCoupon(
      couponPercentOff,
      couponAmountOff
    );

    if (userBalance >= price) {
      return price;
    } else {
      return userBalance;
    }
  };

  renderCoupon = () => {
    const { subscription: { status } } = this.props;
    const { coupon, couponApplied } = this.state;

    return (
      <>
        <Coupon
          setCoupon={coupon => this.setState({ coupon, couponApplied: true })}
          setCouponValues={(
            percentOff,
            amountOff,
            duration,
            months,
          ) =>
            this.setState({
              couponPercentOff: percentOff,
              couponAmountOff: amountOff,
              couponDuration: duration,
              couponDurationInMonths: months
            })
          }
          couponVal={coupon}
          couponApplied={couponApplied}
          subscriptionStatus={status}
        />
      </>
    )
  }

  renderSubscribeScreen = () => {
    const {
      paymentOptions,
      loading,
      selectedPayment,
      showNewCardForm,
      subscriptionError,
      couponPercentOff,
      couponAmountOff,
      coupon
    } = this.state;

    const noPaymentNeeded = (coupon && couponPercentOff && Number(couponPercentOff) === 100);

    return (
      <>
        <div className="CreditCard-title mb-3">SELECT A PAYMENT METHOD</div>
        {!noPaymentNeeded && (
          <Fragment>
            {paymentOptions.length > 0 && (
              <Fragment>{this.selectPaymentType()}</Fragment>
            )}
            <div className="text-center mt-4 mb-3">
              OR
            </div>

            <>
              {showNewCardForm ?
                <>
                  {this.renderNewCardForm()}
                </> :
                <>
                  <div className="Billing" onClick={() => this.onPaymentSelect()}>
                    <h2 className="Billing-title mt-0">
                      ENTER A NEW CARD
                    </h2>
                  </div>
                </>
              }
            </>
          </Fragment>
        )}
        {this.renderCoupon()}
        {(noPaymentNeeded || !showNewCardForm) && (
          <Fragment>
            {subscriptionError && (
              <div className="input-error-red">
                {subscriptionError.message || "Something went wrong"}
              </div>
            )}

            <div className="mb-2 pt-1" />
            <ComponentWithLoading isLoading={loading}>
              {coupon &&
                (couponPercentOff || couponAmountOff) &&
                (noPaymentNeeded || (!noPaymentNeeded && selectedPayment)) && (
                  <div className="coupon-applied">
                    {couponPercentOff
                      ? `${couponPercentOff}% OFF COUPON CODE APPLIED!`
                      : `$${Number(couponAmountOff) /
                      100} OFF COUPON CODE APPLIED!`}
                  </div>
                )}
              <ButtonWithIcon
                classNamesProp="high-75 confirm-button ga-tv-subscribe-end ga-tv-step-3 mt-3"
                text="START WATCHING"
                iconSrc={selectedPayment || noPaymentNeeded ? play : null}
                disabled={!noPaymentNeeded && !selectedPayment}
                onClick={() => this.createSubscription()}
              />
            </ComponentWithLoading>
          </Fragment>
        )}
      </>
    );
  };

  selectPaymentType = () => {
    const {
      paymentOptions,
      isChecked
    } = this.state;

    let user_details = paymentOptions.find(opt => opt.id !== 'new_card')

    return (
      <>
        <div className="CreditCard">
          <div className="CreditCard-container border-top border-dark">
            <div className="CreditCard-number">
              {`CREDIT CARD ${user_details.label.slice(-4)}`}
            </div>
            <div className="CreditCard-btn">
              <div className="CreditCard-text">Use this card</div>
              <div>
                <div
                  className={`CreditCard-checkbox ${isChecked ? 'is-checked' : ''}`}
                  onClick={() => this.updateValues(user_details)}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  updateValues = (option) => {
    const {
      isChecked
    } = this.state;

    if (isChecked) {
      this.setState({
        selectedPayment: null,
        showNewCardForm: true,
        giftCardIsChosen: false,
        isChecked: !isChecked
      });
    }
    else {
      this.setState({
        selectedPayment: option,
        showNewCardForm: false,
        isChecked: !isChecked
      });
    }
  }

  render() {
    const {
      loadingCards
    } = this.state;

    const {
      pageContent,
      activeService
    } = this.props;

    return (
      <Fragment>
        <Script
          url="https://js.stripe.com/v3/"
          attributes={{ id: "stripe-js" }}
          onLoad={() => this.setStripeKey()}
        />
        <ComponentWithLoading isLoading={loadingCards}>
          <div className="payment">
            <div className="step">
              {(pageContent && pageContent.payment_step) || "STEP 2"}
            </div>
            <div className="signup-page-subtitle">Payment</div>
            <div className="Purchase-popup">
              <div className="Service-name">
                {activeService[0] && activeService[0].id === '1' ? 'Single Live Class Only: $15' : 'LiveStream Single Class $5'}
              </div>
            </div>

            <div className="form-container mt-4">{this.renderSubscribeScreen()}</div>
          </div>
        </ComponentWithLoading>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  balance: state.balance,
  user: state.user
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateUserInfo
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Payment);
