import _ from 'lodash'
import { connect } from 'react-redux'
import React, {Component, Fragment} from 'react'
import { bindActionCreators } from 'redux'
import superagent from 'superagent';

import { clearBookLesson } from '../actions/lesson'
import { filterChange } from '../actions/filters'
import { fetchSchedule } from '../actions/schedule'
import { fetchLocations } from '../actions/locations';
import { selectLesson } from '../actions/active_lesson'
import { openLessonPopup } from '../actions/lesson_popup'

import LessonMatcher from '../services/lesson_matcher'

import Schedules from '../components/schedules.jsx'
import LessonPopup from '../components/lesson_booking/lesson_popup.jsx'
import ScheduleFilters from '../components/schedule_filters.jsx'
import RenderError from '../components/render_error'
import ToggleCard from '../components/toggle_card.jsx'
import CancelLessonPopup from '../components/cancel_lesson_popup.jsx';
import scrollToTop from "../helpers/scrollToTop";
import { getParamByName } from "../helpers/get_param_by_name";
import MetaTagsComponent from "../components/meta_tags_component";
import Lesson from '../models/schedule'

class SchedulePage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      policy: null,
    }
  }

  componentWillMount() {
    const lessonId = getParamByName('lesson', 'string');

    if (lessonId) {
      this.getLessonById(lessonId)
    }
  }

  async componentDidMount() {
    scrollToTop();
    await this.props.fetchSchedule()

    const locationId = getParamByName('location', 'integer')
    if (locationId) {
      this.props.filterChange({ location: locationId });
    }

    const staffId = getParamByName('staff', 'integer')
    if (staffId) {
      this.props.filterChange({ staff: staffId });
    }

    return superagent
      .get('/policy?policy_type=lesson')
      .set('Accept', 'application/json')
      .send().then(res =>
        this.setState({
          policy: res.body,
        }));
  }

  componentWillUnmount() {
    const { clearBookLesson } = this.props;
    clearBookLesson();
  }

  getLessonById = (lessonId) => {
    return superagent
      .get(`/schedule/${lessonId}`)
      .set('Accept', 'application/json')
      .send().then((res) => {
        const lesson = new Lesson(res.body);
        this.props.selectLesson(lesson);
        this.props.openLessonPopup();
      });
  }

  onFiltersChange = (event) => {
    const { filterChange } = this.props
    const { name, value } = event.target

    filterChange({ [name]: value })
  }

  onDateChange = (range) => {
    const { fetchSchedule } = this.props
    const { startDate, endDate } = range

    const end_date_time = endDate.endOf('day')
    const start_date_time = startDate.startOf('day')

    fetchSchedule({
      end_date_time: end_date_time.format(),
      start_date_time: start_date_time.format()
    })
  }

  lessons() {
    const { filters, lessons } = this.props

    if (_.isEmpty(filters)) return lessons

    return _.filter(lessons, LessonMatcher(filters))
  }

  render() {
    const {
      isFetching,
      error,
      staff,
      locations,
      session_types,
      selectLesson,
      openLessonPopup,
      filters,
      activeLesson,
      showCancelLesson
    } = this.props;

    if (error) return <RenderError error={error} />

    return (
      <Fragment>
        <MetaTagsComponent
          title={activeLesson ? activeLesson.name : "SKY TING | Schedule"}
          desc={activeLesson ? activeLesson.description : "SKY TING schedule"}
          url={activeLesson ? `schedule?lesson=${activeLesson.slug}` : "schedule"}
        />
        <div className="Page Schedule-page">
          <div className="Page-content">
            <h2 className="Page-header">SCHEDULE</h2>
            <section className="Schedule mb-5">
              <div className="Schedule-content">
                <div
                  className={`Schedule-filters ${isFetching ? 'border-bottom-0' : 'border-bottom border-dark' }`}
                >
                  <ToggleCard
                    header="FILTERS"
                  >
                    <ScheduleFilters
                      staff={staff}
                      locations={locations}
                      session_types={session_types}
                      onFiltersChange={this.onFiltersChange}
                      onDateChange={this.onDateChange}
                      filters={filters}
                    />
                  </ToggleCard>
                </div>
                <Schedules
                  lessons={this.lessons()}
                  selectLesson={selectLesson}
                  openLessonPopup={openLessonPopup}
                  isFetching={isFetching}
                  isToday={false}
                />

                { activeLesson &&
                  <LessonPopup policy={this.state.policy} />
                }
                { showCancelLesson &&
                  <CancelLessonPopup />
                }
              </div>
            </section>
          </div>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  ...state.schedule,
  lesson: state.lesson,
  lessonPopup: state.lessonPopup,
  filters: state.filters,
  activeLesson: state.activeLesson,
  isFetching: state.schedule.isFetching,
  showCancelLesson: state.cancelLessonPopup.show,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      filterChange,
      selectLesson,
      fetchSchedule,
      fetchLocations,
      clearBookLesson,
      openLessonPopup,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(SchedulePage)
