import Api from '../api/user_mail_chimp';

export const OPEN_MAILCHIMP = 'OPEN_MAILCHIMP';
export const DESELECT_MAILCHIMP = 'DESELECT_MAILCHIMP';
export const FETCH_MAILCHIMP = 'FETCH_MAILCHIMP';

export const fetchMailChimp = () => (dispatch) => {
  dispatch({ type: FETCH_MAILCHIMP });
  
    Api.fetchUserMailChimp()
    .then((res) =>{
      if (res.body.notFound){
        dispatch({
          type: OPEN_MAILCHIMP,
          openMailChimpPopup: res.body.notFound
        })
      }
      else{
        dispatch({
          type: OPEN_MAILCHIMP,
          openMailChimpPopup: res.body.notFound
        })
      }
    })
    .catch(err =>
      dispatch({
        type: DESELECT_MAILCHIMP,
        error: err
      })
    );
};

export const deselectMailChimp = () => (dispatch) => { dispatch({ type: DESELECT_MAILCHIMP })};