import React, { Component, Fragment } from "react";
import { injectStripe, CardElement } from "react-stripe-elements";
import play from "../../../../assets/images/icons/Play.svg";
import ButtonWithIcon from "../button_with_icon";
import GiftCardPayment from "./gift_card_payment";
import ComponentWithLoading from "../component_with_loading";

class StripeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      errorMessage: null,
      cardIsComplete: false
    };
  }

  onCardSubmit = ev => {
    ev.preventDefault();
    this.setState({ isSaving: true, errorMessage: null });

    this.props.stripe
      .createToken({
        name: `${this.props.user.first_name} ${this.props.user.last_name}`
      })
      .then(res => {
        if (res.token) {
          this.props.confirm(res.token.id);
          this.setState({ isSaving: false });
        } else if (res.error) {
          this.setState({ errorMessage: res.error.message, isSaving: false });
        } else {
          this.setState({
            errorMessage: "Something went wrong. Try again",
            isSaving: false
          });
        }
      });
  };

  onCardChange = event => {
    const { errorMessage, cardIsComplete } = this.state;
    if (errorMessage) {
      this.setState({ errorMessage: null });
    }

    if (event.complete !== cardIsComplete) {
      this.setState({ cardIsComplete: event.complete });
    }

    if (event.complete) {
      this.props.changeIdempKeys()
    }
  };

  render() {
    const { errorMessage, isSaving, cardIsComplete } = this.state;
    const {
      creatingCard,
      subscriptionError,
      disabled,
      showBtn,
      chooseGiftCard,
      giftCardIsChosen,
      coupon,
      couponPercentOff,
      availableBalance,
      subscriptionStatus
    } = this.props;

    return (
      <Fragment>
        <form onSubmit={this.onCardSubmit}>
          <div className="card-el-wrapper">
            <CardElement
              style={{
                base: {
                  backgroundColor: "#FFFFFF",
                  fontSize: window.innerWidth > 640 ? "13px" : "16px",
                  fontFamily: "'News Cycle', sans-serif",
                  letterSpacing: "2.95px",
                  fontWeight: "100",
                  textTransform: 'uppercase',
                  "::placeholder": {
                    color: "#111111",
                    font: window.innerWidth > 640 ? "13px News Cycle, sans-serif" : "16px News Cycle, sans-serif",
                  }
                }
              }}
              onChange={(e) => {
                this.onCardChange(e);
              }}
              onReady={(c) => {
                this._element = c;
                this._element.focus();
              }}
              hidePostalCode
            />
          </div>
          { subscriptionStatus !== 'pending' &&
            <GiftCardPayment
              chooseGiftCard={() => chooseGiftCard()}
              isChecked={giftCardIsChosen}
              disabled={!cardIsComplete}
              availableBalance={availableBalance}
            />
          }

          { showBtn &&
            <Fragment>
              <div className="mb-2 pt-1" />
              <ComponentWithLoading isLoading={isSaving || creatingCard}>
                { (errorMessage || subscriptionError) &&
                  <div className="input-error-red text-center mb-3">
                    {errorMessage || subscriptionError.message}
                  </div>
                }

                { coupon && couponPercentOff && cardIsComplete && !disabled &&
                <div className="coupon-applied">{couponPercentOff}% OFF COUPON CODE APPLIED!</div>
                }

                <ButtonWithIcon
                  classNamesProp="high-75 confirm-button ga-tv-subscribe-end ga-tv-step-3"
                  text="START WATCHING"
                  iconSrc={!disabled && cardIsComplete ? play : null}
                  disabled={disabled || !cardIsComplete}
                  type="submit"
                />
              </ComponentWithLoading>
            </Fragment>
          }
        </form>
      </Fragment>
    );
  }
}

export default injectStripe(StripeForm);
