import { SELECT_SERVICE, DESELECT_SERVICE } from '../actions/active_service';

const activeService = (state = null, action) => {
  switch (action.type) {
    case SELECT_SERVICE:
      return action.service;
    case DESELECT_SERVICE:
      return null;
    default:
      return state;
  }
};

export default activeService;
