import React from "react";

const colors = [
  { type: "Infrared Sauna", color: "#D80E18" },
  { type: "SKY TING Yoga Classes", color: "#2768BD" },
  { type: "SKY TING Workshops", color: "#FAA338" },
  { type: "SKY TING Donation Classes", color: "#31B022" },
  { type: "SKY TING Teacher Training", color: "#92172F" },
  { type: "Discounts", color: "#B0DD92" },
  { type: "SKY TING Pilates", color: "#E87868" },
  { type: "SKY TING Pre/Post-Natal", color: "#F4BABA" },
  { type: "SKY TING Membership", color: "#2768BD" },
  { type: "SKY TING Master Classes", color: "#E1B724" },
  { type: "PYRO TINGS", color: "#D80E18" },
  { type: "Members Only", color: "#2768BD" },
  { type: "Work Study", color: "#75AEDE" },
  { type: "Special Donation Class", color: "#31B022" }
];

export const renderStar = (programName) => {
  const color = colors.find(c => c.type === programName);

  return (
    <svg width="20" height="20" fill={color ? color.color : '#111111'}>
      <g id="Icon-/-ST-/-Star" transform="translate(-2.000000, -2.000000)">
        <path
          d="M12.340311,2.08333333 L9.25894101,8.67295692 L2.08333333,9.87062444 L7.39819139,14.8378548 L6.32019254,22.0324918 L12.6867616,18.5132522 L19.1959521,21.7625507 L17.8154466,14.6197048 L22.9166667,9.43177395 L15.6975074,8.53798636 L12.340311,2.08333333 Z M8.7781084,14.3578069 L4.87965648,10.7151713 L10.1425273,9.8368818 L12.4024995,5.00403342 L14.863358,9.73722331 L20.1580097,10.3934392 L16.417285,14.1969409 L17.4287795,19.4348971 L12.6557654,17.0519213 L7.98711816,19.6336256 L8.7781084,14.3578069 Z"
          id="Fill-1"
        ></path>
      </g>
      Sorry, your browser does not support inline SVG.
    </svg>
  );
};
