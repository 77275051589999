import {
  BOOK_LESSON,
  BOOK_LESSON_UNAUTHORIZED,
  BOOK_LESSON_AUTHORIZED,
  BOOK_LESSON_OVERDRAFT,
  BOOK_LESSON_SUCCESS,
  BOOK_LESSON_ERROR,
  BOOK_LESSON_CLEAR,
  SEND_BOOKING_REQUEST,
  CANCEL_LESSON,
  CANCEL_LESSON_SUCCESS,
  CANCEL_LESSON_ERROR
} from '../actions/lesson';


const INITIAL_STATE = {
  bookingLessonId: null,
  waitingForFunds: false,
  waitingForAuthentication: false,
  bookingRequestInProgress: false,
  error: null,
  cancelInProgress: false,
  cancelError: false,
  cancellingLesson: null,
  success: null,
};

const lesson = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BOOK_LESSON:
      return {
        ...state,
        bookingLessonId: action.lessonId,
      }
    case BOOK_LESSON_UNAUTHORIZED:
      return {
        ...state,
        waitingForFunds: false,
        waitingForAuthentication: true,
      }
    case BOOK_LESSON_AUTHORIZED:
      return {
        ...state,
        waitingForAuthentication: false,
      }

    case BOOK_LESSON_OVERDRAFT:
      return {
        ...state,
        waitingForFunds: true,
        waitingForAuthentication: false,
      }
    case BOOK_LESSON_ERROR:
      return {
        ...state,
        bookingLessonId: null,
        error: action.error,
        bookingRequestInProgress: false
      }
    case BOOK_LESSON_SUCCESS:
      return {
        ...state,
        bookingLessonId: null,
        error: null,
        waitingForFunds: false,
        waitingForAuthentication: false,
        bookingRequestInProgress: false,
        success: true,
      }
    case SEND_BOOKING_REQUEST:
      return {
        ...state,
        bookingRequestInProgress: true,
      }
    case CANCEL_LESSON:
      return {
        ...state,
        cancelInProgress: true,
        cancellingLesson: action.lessonId,
      }
    case CANCEL_LESSON_SUCCESS:
      return {
        ...state,
        cancelInProgress: false,
        cancelError: false,
        cancellingLesson: null,
      }
    case CANCEL_LESSON_ERROR:
      return {
        ...state,
        cancelInProgress: false,
        cancelError: true,
        cancellingLesson: null,
      }
    case BOOK_LESSON_CLEAR:
      return INITIAL_STATE
    default:
      return state;
  }
}

export const isBookingInProgress = (lessonId, state) =>
  (state.lesson.bookingRequestInProgress) && (state.lesson.bookingLessonId === lessonId)

export default lesson;
