import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { dropdownChange } from "../actions/dropdowns"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

class NavbarDropdownLinks extends Component {

  onClick = () => {
    const { dropdownChange, name } = this.props

    dropdownChange({
      [name]: false
    })
  }

  render() {

    return (
      <div onClick={this.onClick}>
        {this.props.rows.map((el, index) => (
          <Link
            to={el.url}
            key={el.id}
            title={`Go to ${el.name} section`}
            className="Navbar-dropdown-link"
          >
            {el.name}
          </Link>
        ))}
      </div>
    )
  }

}

NavbarDropdownLinks.propTypes = {
  rows: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
}

NavbarDropdownLinks.defaultProps = {
  rows: [],
  name: "default",
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      dropdownChange,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(NavbarDropdownLinks)
