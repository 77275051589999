import {
  FETCH_PRODUCT_ERROR,
  FETCH_PRODUCT_SUCCESS
} from '../actions/product';

const INITIAL_STATE = {
  products: [],
  giftCards: [],
  error: null,
};

const product = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_PRODUCT_ERROR:
      return {
        ...state,
        error: action.error,
        isFetching: false
      };
    case FETCH_PRODUCT_SUCCESS:
      return {
        ...state,
        products: action.products.products,
        giftCards: action.products.gift_cards,
        error: null,
        isFetching: false
      };
    default:
      return state;
  }
};

export default product;
