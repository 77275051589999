import React, { Fragment, Component } from "react";
import Popup from "../components/popup.jsx";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import superagent from "superagent";
import { deselectMailChimp } from "../actions/mail_chimp";

class MailChimpPopup extends Component {
  constructor() {
    super();
    this.state = {
      emailForSending: "",
      emailInputLoading: false,
      emailInputError: false,
      emailSaved: false,
      error: "",
      showConfirmationPopup: false,
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
  }

  onSubmitEmail = () => {
    if (/\S+@\S+\.\S+/.test(this.state.emailForSending)) {
      this.setState({ emailInputLoading: true });
      return superagent
        .post("/add_email")
        .set("Accept", "application/json")
        .send({ email: this.state.emailForSending })
        .then(() =>
          this.setState({
            showConfirmationPopup: true,
            emailForSending: "",
          })
        )
        .catch(() => {
          this.setState({
            emailInputLoading: false,
            emailInputError: true,
            error: "Please login to continue.",
          });
        });
    } else {
      this.setState({ emailInputError: true });
    }
  };

  handleEmailChange(event) {
    this.setState({
      emailForSending: event.target.value,
    });
  }

  resetError = () => {
    if (this.state.emailInputError) {
      this.setState({
        emailInputError: false,
      });
    }
  };

  close = () => {
    this.props.deselectMailChimp();
  };

  render() {
    const { emailInputError, showConfirmationPopup, error } = this.state;

    return showConfirmationPopup ? (
      <>
        <Popup
          isOpen={true}
          id="mailChimp"
          classNameProp="mailchimp-confirm"
          onClose={() => this.close()}
        >
          <div className="MailChimp confirm-popup">
            <div className="description">
              <p className="head">THANKS FOR SUBSCRIBING TO OUR NEWSLETTER!</p>
              <p className="sub-title">USE THE CODE</p>
            </div>

            <div className="code-description">
              <p></p>
              <button className="code-btn col-12 d-flex align-items-center justify-content-center">
                WELCOMETOTING
              </button>
              <p>
                TO RECEIVE YOUR 1 MONTH OF FREE SKY TING TV - THE SAME CODE WILL
                GET YOU 15% OFF ANYTHING IN OUR SHOP!
              </p>
            </div>
          </div>
        </Popup>
      </>
    ) : (
      <>
        <Popup
          isOpen={true}
          id="mailChimp"
          classNameProp="mailchimp-form"
          onClose={() => this.close()}
        >
          <div className="MailChimp">
            <div className="description">
              <p className="head">
                BECOME A PART OF NEW YORK’S BIGGEST YOGA COMMUNITY
              </p>
              <p className="sub-title">SIGN UP FOR OUR NEWSLETTER</p>
            </div>
            <div>
              <input
                className={`popup-email-input ${
                  emailInputError ? "error" : ""
                }`}
                value={this.state.emailForSending}
                onChange={this.handleEmailChange}
                onFocus={this.resetError}
                placeholder="ENTER YOUR EMAIL ADDRESS"
              />
              {!!error && <p className="error_msg">{error}</p>}
            </div>
            <div>
              <button
                className="add-email-btn col-12 d-flex align-items-center justify-content-center"
                onClick={this.onSubmitEmail}
              >
                SUBSCRIBE
              </button>
            </div>
            <div className="modal-footer-note">
              <p>
                GET FIRST ACCESS TO RETREATS, SPECIAL EVENTS, COURSES, AND MORE
              </p>
            </div>
          </div>
        </Popup>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deselectMailChimp,
    },
    dispatch
  );

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(MailChimpPopup)
);
