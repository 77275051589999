import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import { routerReducer } from 'react-router-redux'

import activeLesson from './active_lesson';
import activeProgram from './active_program';
import activeService from './active_service';
import activeAppointment from './active_appointment';
import balance from './balance';
import billing from './billing';
import blog from './blog';
import blogPageContent from './blog_page_content';
import cancelLessonPopup from './cancel_lesson_popup';
import cart from './cart';
import checkout from './checkout';
import checkoutShop from './checkout_shop';
import checkoutSuccessPopup from './checkout_success_popup';
import checkoutTotal from './checkout_total';
import countries from './countries';
import creditCard from './credit_card';
import dropdowns from './dropdowns';
import filters from './filters';
import lesson from './lesson';
import lessonPopup from './lesson_popup';
import liveSchedule from './live_schedule';
import liveSchedulePage from './live_schedule_page';
import loginPopup from './login_popup';
import locations from './locations';
import mentorship from './mentorship';
import order from './order';
import purchasePopup from "./purchase_popup";
import products from './product';
import productQuantitySelector from './product_quantity_selector';
import register_popup from './register_popup';
import retreats from './retreats';
import saunas from './saunas';
import schedule from './schedule';
import services from './services';
import shipping from './shipping';
import shipmentProducts from './shipment_products';
import shopPaymentPopup from './shop_payment_popup';
import shopPromoSpots from './shop_promo_spots';
import teachers from './teachers';
import trainings from './trainings';
import tvDashboard from './tv_dashboard';
import tvPlans from './tv_plans';
import unauthorizedCart from './unauthorized_cart';
import user from './user';
import userSchedule from './user_schedule';
import userMembership from './user_membership';
import user_register from './user_register';
import userPurchases from './user_purchases';
import userTvSubscription from './user_tv_subscription';
import userSubscriptions from './user_subscriptions';
import userPastClasses from './user_past_classes';
import workshops from './workshops';
import userSchedules from './user_schedules';
import mailChimp from './mail_chimp';
import redirectUrl from './redirect_url';

const rootReducer = combineReducers({
  activeAppointment,
  activeLesson,
  activeProgram,
  activeService,
  balance,
  billing,
  blog,
  blogPageContent,
  cancelLessonPopup,
  cart,
  checkout,
  checkoutShop,
  checkoutTotal,
  checkoutSuccessPopup,
  countries,
  creditCard,
  dropdowns,
  filters,
  lesson,
  lessonPopup,
  liveSchedule,
  liveSchedulePage,
  loginPopup,
  locations,
  mentorship,
  order,
  products,
  productQuantitySelector,
  form,
  purchasePopup,
  schedule,
  shipping,
  saunas,
  shipmentProducts,
  services,
  shopPaymentPopup,
  shopPromoSpots,
  register_popup,
  retreats,
  trainings,
  teachers,
  tvDashboard,
  tvPlans,
  unauthorizedCart,
  user,
  userMembership,
  userSchedule,
  userPurchases,
  userSubscriptions,
  userTvSubscription,
  user_register,
  workshops,
  router: routerReducer,
  userPastClasses,
  userSchedules,
  mailChimp,
  redirectUrl
});

export default rootReducer;
