import _ from 'lodash';
import { connect } from 'react-redux';
import React, { Component, Fragment } from 'react';
import Script from 'react-load-script';
import MetaTagsComponent from "../components/meta_tags_component";
import { bindActionCreators } from 'redux';

import { fetchLocations } from '../actions/locations';

import RenderLoading from '../components/render_loading.jsx';
import LocationCard from '../components/location_card';
import RenderError from '../components/render_error.jsx';
import scrollToTop from "../helpers/scrollToTop";

class LocationsPage extends Component {
  componentDidMount() {
    scrollToTop();
    this.props.fetchLocations();
  }

  render() {
    const { locations, isFetching, error } = this.props;
    if (error) return <RenderError error={error} />;

    return (
      <Fragment>
        <Script
          url="https://maps.googleapis.com/maps/api/js?key=AIzaSyBldOX1hRMSmYSHMJni79vJm7dYiVG0QbQ&libraries=places"
          attributes={{ type: 'text/javascript' }}
        />
        <MetaTagsComponent
          title="SKY TING | About | Locations"
          desc="SKY TING locations"
          url="locations"
        />
        <div className="Page Locations">
          <div className="Page-content">
            <h2 className="Page-header">LOCATIONS</h2>
            { isFetching ? (
              <RenderLoading />
            ) : (
              <Fragment>
                { _.isEmpty(locations) ? (
                  <div className="Empty-result">NO LOCATIONS</div>
                ) : (
                  <div>
                    { locations.map(location => (
                      <LocationCard
                        location={location}
                        key={location.id}
                      />
                    ))};
                  </div>
                )}
              </Fragment>
            )}
          </div>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  error: state.locations.error,
  locations: state.locations.locations,
  isFetching: state.locations.isFetching
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchLocations
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LocationsPage);