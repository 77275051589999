import {
	ADD_URL,
	REMOVE_URL,
} from '../actions/redirect_url';

const INITIAL_STATE = {
	comeFrom: '',
	page: ''
};

const redirectUrl = (state = INITIAL_STATE, action) => {
	switch ("Action",action.type) {
		case ADD_URL:
			return {
				...state,
				comeFrom: action.url,
				page: action.page
			};
		case REMOVE_URL:
			return {
				...state,
				comeFrom: '',
				page: ''
			};
		default:
			return state;
	}
};

export default redirectUrl;