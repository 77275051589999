import superagent from 'superagent';

const API_ENDPOINT = '/users';

const updateCurrentUser = (user) => (
  superagent.patch(API_ENDPOINT)
    .send(user)
    .set({
      'Accept': 'application/json',
      'X-CSRF-Token': document.getElementsByName('csrf-token')[0].getAttribute('content')
    })
);

export default {
  updateCurrentUser
};
