import {history} from "../store/configureStore";

export const SELECT_SERVICE = 'SELECT_SERVICE';
export const DESELECT_SERVICE = 'DESELECT_SERVICE';

export const selectService = service => (dispatch) => {
  dispatch({ type: SELECT_SERVICE, service })
}

export const deselectService = () => (dispatch) => {
  history.push({
    pathname: window.location.pathname,
    search: '',
  })

  dispatch({ type: DESELECT_SERVICE })
}