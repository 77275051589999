import {
  GET_BALANCE,
  GET_BALANCE_ERROR,
  GET_BALANCE_SUCCESS,
  SET_INNER_BALANCE,
} from '../actions/balances';

const INITIAL_STATE = {
  error: null,
  fetching: false,
  lessons_remaining: 0,
  services: [],
  inner_balance: 0,
};

const parsePayload = (payload) => {
  if (payload.remaining >= 0) return payload.remaining;

  return payload.reduce((total, { remaining }) => total + remaining, 0);
};

const getServices = (payload) => {
  if (payload.id) return [payload]
  if (Array.isArray(payload) && payload.length > 0) return payload
  return []
};

const balance = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_BALANCE:
      return {
        ...state,
        fetching: true,
      };
    case GET_BALANCE_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.error,
        services: [],
      };
    case GET_BALANCE_SUCCESS:
      return {
        ...state,
        fetching: false,
        lessons_remaining: parsePayload(action.payload.mbo_balance),
        services: getServices(action.payload.mbo_balance),
        inner_balance: action.payload.inner_balance
      };
    case SET_INNER_BALANCE:
      return {
        ...state,
        inner_balance: action.amount,
      }
    default:
      return state;
  }
};

export const isFetching = state => state.balance.fetching;

export const hasBalance = state =>
  state.balance && state.balance.lessons_remaining > 0;

export default balance;
