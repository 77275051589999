import React, { Component, Fragment } from "react";
import Slider from "react-slick";
import Popup from "./popup";
import closeIcon from "../../../assets/images/icons/close_icon.svg";
import arrow from "../../../assets/images/icons/arrow_long.png";
import ProductSizeSelector from "./product_size_selector";

const NextArrow = props => {
  const { className, onClick } = props;
  return <img src={arrow} className={className} onClick={onClick} alt="Next" />;
};

const PrevArrow = props => {
  const { className, onClick } = props;
  return <img src={arrow} className={className} onClick={onClick} alt="Prev" />;
};

class ProductCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLightbox: false,
      activeSlide: 0
    };
  }

  changeSlide = ind => {
    this.setState({ activeSlide: ind });
  };

  openLightbox = () => {
    if (this.props.disableFullScreen) return;

    this.setState({ showLightbox: true });
    document.body.classList.add("overflow-hidden");
  };

  closeLightbox = () => {
    this.setState({ showLightbox: false });
    document.body.classList.remove("overflow-hidden");
  };

  addProductToCart = (product) => {
    this.props.addProductToCart(product);
    this.closeLightbox();
  }

  render() {
    const { showLightbox, activeSlide } = this.state;
    const {
      images,
      productTitle,
      productPrice,
      product,
      selectedSize,
      onSizeSelect,
      disableFullScreen
    } = this.props;

    return (
      <Fragment>
        <div className={`product-carousel with-large-dots ${disableFullScreen ? 'no-full-screen' : ''}`}>
          <Slider
            dots
            infinite
            speed={1000}
            swipeToSlide
            arrows={false}
            beforeChange={(oldIndex, newIndex) => this.changeSlide(newIndex)}
          >
            {images.map(image => (
              <div className="product-carousel-inner" key={image.id}>
                <img
                  src={image.url}
                  className="product-image"
                  onClick={() => this.openLightbox()}
                />
              </div>
            ))}
          </Slider>
        </div>
        {showLightbox && (
          <Popup
            id="product-lightbox"
            isOpen
            classNameProp="product-lightbox"
            onClose={() => this.closeLightbox()}
            closeIcon={closeIcon}
          >
            <div className="product-lightbox-inner">
              <div className="product-lightbox-top">
                <div className="product-lightbox-size-select hide-for-mobile">
                  <ProductSizeSelector
                    product={product}
                    onSizeSelect={onSizeSelect}
                    selectedSize={selectedSize}
                    addProductToCart={product => this.addProductToCart(product)}
                  />
                </div>
                <div className="d-flex justify-content-center flex-column align-items-center position-relative">
                  <div className="product-lightbox-title">{productTitle}</div>
                  {productPrice && (
                    <div className="product-lightbox-price">
                      ${productPrice}
                    </div>
                  )}
                </div>
              </div>
              <div className="lightbox-slider-container">
                <div className="product-lightbox-size-select show-for-mobile pr-2 pl-2">
                  <ProductSizeSelector
                    product={product}
                    onSizeSelect={onSizeSelect}
                    selectedSize={selectedSize}
                    addProductToCart={product => this.addProductToCart(product)}
                  />
                </div>
                <div className="lightbox-slider-wrapper">
                  <Slider
                    dots={false}
                    infinite
                    speed={500}
                    fade
                    swipeToSlide
                    arrows
                    initialSlide={activeSlide}
                    nextArrow={<NextArrow />}
                    prevArrow={<PrevArrow />}
                    responsive={[
                      {
                        breakpoint: 899,
                        settings: {
                          arrows: false,
                          dots: true
                        }
                      }
                    ]}
                  >
                    {images.map(image => (
                      <div className="lightbox-slider-inner">
                        <img
                          src={image.url}
                          className="product-lightbox-image"
                          onClick={() => this.closeLightbox()}
                        />
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </Popup>
        )}
      </Fragment>
    );
  }
}

export default ProductCarousel;
