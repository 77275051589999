import superagent from "superagent";

const API_ENDPOINT = "/purchase_gift_card";

const buyGiftCard = (
  stripe_tok = null,
  amount,
  giftCardAttrs,
  idemp_key_customer,
  idemp_key_charge,
  is_for_skyting_tv = false
) => {
  return superagent
    .post(API_ENDPOINT)
    .set("Accept", "application/json")
    .send({
      stripe_tok,
      amount,
      gift_card_attrs: giftCardAttrs,
      idemp_key_customer,
      idemp_key_charge,
      is_for_skyting_tv
    });
};

export default { buyGiftCard };
