import Api from "../api/retreats";
import { getParamByName } from "../helpers/get_param_by_name";
import { animateScroll as scroll } from 'react-scroll'
import { selectProgram } from './active_program';

export const FETCH_RETREATS = 'FETCH_RETREATS';
export const FETCH_RETREATS_ERROR = 'FETCH_RETREATS_ERROR';
export const FETCH_RETREATS_SUCCESS = 'FETCH_RETREATS_SUCCESS';

  export const fetchRetreats = () => dispatch => {
  dispatch({ type: FETCH_RETREATS });

  Api.fetchRetreats()
    .then(res => {
      dispatch({
        type: FETCH_RETREATS_SUCCESS,
        retreats: res.body
      })

      const retreatId = getParamByName('program', 'string')
      const retreat = res.body.find(l => l.slug === retreatId);
      if (retreat) {
        dispatch(selectProgram(retreat));
      }

      if (retreatId) {
        const el = document.getElementById(retreatId);
        if (el) {
          const positionY = el.offsetTop;
          scroll.scrollTo(positionY - 87);
        }
      }
    })
    .catch(err =>
      dispatch({
        type: FETCH_RETREATS_ERROR,
        error: err
      })
    );
};
