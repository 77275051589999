import React, { Component } from "react";
import { connect } from "react-redux";
import {withRouter} from 'react-router-dom';
import { bindActionCreators } from "redux";
import { fetchCurrentUser } from "../actions/user";
import { fetchUserSchedule } from "../actions/user_schedule";
import { fetchUserMemberships } from "../actions/user_memberships";
import { fetchShoppingCart } from "../actions/cart";
import { getBalance } from "../actions/balances";
import { fetchCountries } from "../actions/countries";

import _ from "lodash";
import Navbar from "./navbar.jsx";
import ShippingPopup from "../components/shipping_popup";
import ShopPaymentPopup from "../components/shop_payment_popup";
import OrderPopup from "../components/order_popup";
import CheckoutSuccessPopup from "../components/checkout_success_popup";
import LoginPopup from "../components/login_popup";
import Register from "./register";

const themes = [
  { name: 'red-theme', background: '#f8f5f3', navbar: '#f3eae6', dark: '#c19c88' },
  { name: 'green-theme', background: '#f2f2e9', navbar: '#dedec7', dark: '#4f5047' },
  { name: 'blue-theme', background: '#f9fcfe', navbar: '#e7f3fc', dark: '#164b6a' },
  { name: 'yellow-theme', background: '#fffbf1', navbar: '#fff7df', dark: '#e8cf88' },
];

const pathesWithoutNavbar = [
  'login', 'signup', 'get-started', 'forgot-password', 'reset-password', 'edit-profile',
  'choose-plan', 'payment'
];

class PageLayout extends Component {
  constructor() {
    super();
    this.state = { theme: _.sample(themes) };
  }

  componentWillMount() {
    this.props.fetchCurrentUser();
  }

  componentWillReceiveProps(newProps) {
    if (
      (!this.props.user || !this.props.user.id) &&
      newProps.user &&
      newProps.user.id
    ) {
      this.props.fetchShoppingCart();
      this.props.fetchUserSchedule();
      this.props.getBalance();
      this.props.fetchUserMemberships();
    }

    if (this.shouldChangeNavbarColor(newProps)) {
      this.setState({
        theme: _.sample(themes.filter(c => c.name !== this.state.theme.name))
      });
    }
    this.onPathChange(newProps.location.pathname);
  }

  onPathChange = (newPath) => {
    const container = document.getElementsByClassName('Layout')[0]
    const items = newPath.split("/");
    const step = items[(items.length - 1)];

    if (pathesWithoutNavbar.includes(step)) {
      if (!container.classList.contains('without-navbar')) {
        container.classList.add('without-navbar')
      }
    } else {
      if (container.classList.contains('without-navbar')) {
        container.classList.remove('without-navbar')
      }
    }
  }

  shouldChangeNavbarColor = (newProps) => {
    return (
      newProps.location.pathname !== this.props.location.pathname &&
      (!(newProps.location.pathname.includes("/shop/") && this.props.location.pathname.includes("/shop/")))
    )
  };

  render() {
    const {
      children,
      location: { pathname },
      shippingIsOpen,
      showShopPaymentPopup,
      showOrderPopup,
      user,
      showRegisterPopup,
      showLoginPopup,
      showCheckoutSuccess
    } = this.props;
    const { theme } = this.state;
    return (
      <div className={`main-container ${theme.name}`}>
        <Navbar colorTheme={this.state.theme.name} pathname={pathname} />
        {children}
        {shippingIsOpen && <ShippingPopup />}
        {showShopPaymentPopup && <ShopPaymentPopup />}
        {showOrderPopup && <OrderPopup />}
        {showCheckoutSuccess && <CheckoutSuccessPopup />}
        {showLoginPopup && <LoginPopup />}
        {showRegisterPopup && <Register />}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user,
  shippingIsOpen: state.shipping.isOpen,
  showShopPaymentPopup: state.shopPaymentPopup.show,
  showOrderPopup: state.order.show,
  showRegisterPopup: state.register_popup.show,
  showLoginPopup: state.loginPopup.show,
  showCheckoutSuccess: state.checkoutSuccessPopup.show
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchCurrentUser,
      fetchShoppingCart,
      fetchUserSchedule,
      getBalance,
      fetchUserMemberships,
      fetchCountries
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PageLayout));
