import React, { Component } from "react";
import superagent from "superagent";
import { Link } from "react-router-dom";
import logo from "./../../../assets/images/icons/lady.svg";
import instagram from "./../../../assets/images/icons/insta.svg";
import facebook from "./../../../assets/images/icons/facebook.svg";
import youtube from "./../../../assets/images/icons/youtube.svg";
import footerBckg from "./../../../assets/images/footer_bckg.png";
import footerBckgSm from "./../../../assets/images/footer_bckg_sm.jpg";

class FooterNew extends Component {
  constructor() {
    super();
    this.state = {
      emailForSending: "",
      emailInputLoading: false,
      emailInputError: false,
      emailSaved: false,
      content: null
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.sendEmailOnKey = this.sendEmailOnKey.bind(this);
  }

  componentDidMount() {
    return superagent
      .get("/footer")
      .set("Accept", "application/json")
      .send()
      .then(res =>
        this.setState({
          content: res.body.content
        })
      )
      .catch(() => {
        console.log("fetching footer failed");
      });
  }

  sendEmail = () => {
    if (/\S+@\S+\.\S+/.test(this.state.emailForSending)) {
      this.setState({ emailInputLoading: true });
      return superagent
        .post("/mail_info")
        .set("Accept", "application/json")
        .send({ email: this.state.emailForSending })
        .then(() =>
          this.setState({
            emailInputLoading: false,
            emailSaved: true,
            emailForSending: ""
          })
        )
        .catch(() => {
          this.setState({
            emailInputLoading: false,
            emailInputError: true
          });
        });
    } else {
      this.setState({ emailInputError: true });
    }
  };

  sendEmailOnKey = e => {
    this.setState({ emailInputError: false });
    if (e.key === "Enter") {
      this.sendEmail();
    }
  };

  handleEmailChange(event) {
    this.setState({
      emailForSending: event.target.value
    });
  }

  resetError = () => {
    if (this.state.emailInputError) {
      this.setState({
        emailInputError: false
      });
    }
  };

  render() {
    const { emailInputError, content } = this.state;

    return (
      <div className="footer-sk">
        <div className="footer-sk-wrapper">
          <div className="footer-row padding-lr">
            <div className="footer-column first flex-column">
              <div className="footer-link-wrapper">
                <img src={logo} className="logo" />
                <Link
                  to="/shop"
                  className="footer-link large"
                  title="Visit shop section"
                >
                  SHOP
                </Link>
              </div>
              <div className="footer-link-wrapper">
                <Link
                  to="/account"
                  className="footer-link large"
                  title="Visit account"
                >
                  ACCOUNT
                </Link>
              </div>
              <div className="social-row">
                <a
                  className="social-link"
                  href="https://www.youtube.com/channel/UCJ4iDDOj0VvPtKkJ6hwFDIA"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Open Youtube"
                >
                  <img src={youtube} />
                </a>
                <a
                  className="social-link"
                  href="https://www.instagram.com/skyting"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Open Instagram"
                >
                  <img src={instagram} />
                </a>
                <a
                  className="social-link"
                  href="https://www.facebook.com/skytingyoga"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Open Facebook"
                >
                  <img src={facebook} />
                </a>
              </div>
            </div>
            <div className="footer-column second flex-column">
              <div className="footer-bold-text">
                {content && content.signup_text}
              </div>
              <div>
                <input
                  className={`footer-email-input ${
                    emailInputError ? "error" : ""
                  }`}
                  value={this.state.emailForSending}
                  onChange={this.handleEmailChange}
                  onKeyPress={this.sendEmailOnKey}
                  onFocus={this.resetError}
                  placeholder="ENTER YOUR EMAIL ADDRESS"
                />
                {this.state.emailSaved && (
                  <div className="email-success">
                    Email was successfully sent!
                  </div>
                )}
              </div>
            </div>
            <div className="footer-column third">
              <div className="w-50">
                <div className="footer-section-name">TV</div>
                <div className="footer-link-wrapper">
                  <Link to="/tv" className="footer-link" title="Visit Dashboard">
                    Dashboard
                  </Link>
                </div>
                <div className="footer-link-wrapper">
                  <Link
                    to="/tv/videos"
                    className="footer-link"
                    title="Visit Library"
                  >
                    Library
                  </Link>
                </div>
                <div className="footer-link-wrapper">
                  <Link
                    to="/tv/live"
                    className="footer-link"
                    title="Visit Zoom schedule"
                  >
                    Zoom schedule
                  </Link>
                </div>
                {/*<div className="footer-link-wrapper">*/}
                {/*  <Link*/}
                {/*    to="/tv/workshops"*/}
                {/*    className="footer-link"*/}
                {/*    title="Visit Workshops"*/}
                {/*  >*/}
                {/*    Workshops*/}
                {/*  </Link>*/}
                {/*</div>*/}
                <div className="footer-link-wrapper">
                  <Link
                    to="/courses"
                    className="footer-link"
                    title="Visit Trainings"
                  >
                    Courses
                  </Link>
                </div>
              </div>
              <div className="w-50">
                <div className="footer-section-name">ABOUT</div>
                <div className="footer-link-wrapper">
                  <Link
                    to="/teachers"
                    className="footer-link"
                    title="Visit teachers section"
                  >
                    Teachers
                  </Link>
                </div>
                <div className="footer-link-wrapper">
                  <Link
                    to="/press"
                    className="footer-link"
                    title="Visit press section"
                  >
                    Press
                  </Link>
                </div>
                <div className="footer-link-wrapper">
                  <Link
                    to="/faq"
                    className="footer-link"
                    title="Visit FAQ section"
                  >
                    FAQ
                  </Link>
                </div>
              </div>
            </div>
            <div className="footer-column forth flex-column">
              <div className="footer-section-name">Terms and privacy</div>
              <div className="footer-link-wrapper">
                <Link
                  to="/terms"
                  className="footer-link"
                  title="Terms and conditions"
                >
                  Terms and conditions
                </Link>
              </div>
              <div className="footer-link-wrapper">
                <Link
                  to="/privacy"
                  className="footer-link"
                  title="Privacy policy"
                >
                  Privacy policy
                </Link>
              </div>
              <div className="line" />
              <div className="footer-link-wrapper">
                <a
                  className="footer-link font-weight-bold"
                  title="Send email to info@skyting.com"
                  href="mailto:info@skyting.com"
                >
                  Contact
                </a>
              </div>
            </div>
          </div>
          <div className="footer-row bottom justify-content-center">
            Made with 💙 by And in memory of
            <br />
            our friend Greg Passuntino
            <br />
            17 Allen Street, 7th floor, New York NY 10002
          </div>
        </div>
        <img src={footerBckg} className="footer-img-lg" />
        <img src={footerBckgSm} className="footer-img-sm" />
      </div>
    );
  }
}

export default FooterNew;
