import { dropdownChange } from './dropdowns';
import { waitForAuth } from "./cart";

export const REGISTER_POPUP_CLOSE = 'REGISTER_POPUP_CLOSE'
export const REGISTER_POPUP_OPEN = 'REGISTER_POPUP_OPEN'

export const openRegisterPopup = () => (dispatch) => {
  dispatch({ type: REGISTER_POPUP_OPEN });
  dispatch(dropdownChange({ login: false }));
};

export const closeRegisterPopup = () => (dispatch, getState) => {
  dispatch({ type: REGISTER_POPUP_CLOSE });

  const state = getState();
  if (state.cart.waitingForAuth) {
    dispatch(waitForAuth(false));
  }
};