import { deselectService } from './active_service';
import { DESELECT_APPOINTMENT } from './active_appointment';
import { RESET_CHECKOUT_SERVICE_ERROR } from "./checkout";

export const OPEN_PURCHASE_POPUP = 'OPEN_PURCHASE_POPUP';
export const CLOSE_PURCHASE_POPUP = 'CLOSE_PURCHASE_POPUP';

export const openPurchasePopup = () => (dispatch,) => {
  dispatch({ type: OPEN_PURCHASE_POPUP })
}

export const closePurchasePopup = () => dispatch => {
  dispatch({type: CLOSE_PURCHASE_POPUP})
  dispatch({type: DESELECT_APPOINTMENT})
  dispatch({type: RESET_CHECKOUT_SERVICE_ERROR})
  deselectService()(dispatch)
}