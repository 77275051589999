import React  from 'react';

const discount = (product, membership) => {
  if (!product.membership_discount) return 0;

  if (membership && membership.shop_discount && Number(membership.shop_discount) > 0) {
    return Number(membership.shop_discount)/100
  } else {
    return 0
  }
}

export const productPriceWithDiscount = (product, membership) => {
  const price = Number(product.price)
  const price_with_discount = price - (price * discount(product, membership))

  if ((price_with_discount % 1) !== 0) return Number(price_with_discount).toFixed(2)

  return Number(price_with_discount).toFixed(0)
}

