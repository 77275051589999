import React, { Component } from 'react';
import PropTypes from 'prop-types'
import _ from 'lodash';
import moment from 'moment';
import LazyLoad from 'react-lazyload';

import Table from '../components/table.jsx';
import TableRow from '../components/table_row.jsx';
import ScheduleColumns from '../components/schedule_columns.jsx';

import RenderLoading from './render_loading.jsx';

export default class Schedules extends Component {
  lessonDays = () => {
    const { lessons } = this.props

    const sorted_lessons = _.chain(lessons)
      .sortBy('start_date_time')
      .groupBy(({ start_date_time }) =>
        moment.utc(start_date_time).format('dddd MMM DD YYYY')
      )
      .value()

    if (this.props.isToday) {
      const day = Object.keys(sorted_lessons)[0]
      const result = day ? _.pick(sorted_lessons, [day]) : sorted_lessons
      return result
    }

    return sorted_lessons
  }

  getRowsFromLessons(lessons) {
    const { selectLesson, openLessonPopup } = this.props;

    const onClickRow = lesson => () => {
      selectLesson(lesson);
      openLessonPopup();
    }

    return lessons.map(lesson => (
      <LazyLoad height={80} once key={lesson.id} offset={[300, 0]}>
        <TableRow
          key={lesson.id}
          onClick={onClickRow(lesson)}
          columns={ScheduleColumns(lesson, this.waitlistOnly(lesson))}
          isNew={this.lessonIsNew(lesson)}
          lessonId={lesson.id}
        />
      </LazyLoad>
    ));
  }

  tables = (lessonDays) => {
    return _.map(lessonDays, (lessons, day) => (
      <div key={day} className="Schedule-scheduleWrapper">
        <Table
          rows={this.getRowsFromLessons(lessons)}
          header={this.props.isToday ? "Upcoming classes" : day}
        />
      </div>
    ));
  }

  lessonIsNew = (lesson) => {
    if (lesson.data.class_description && lesson.data.class_description.last_updated) {
      return (moment().day(-7) < moment(lesson.data.class_description.last_updated))
    } else {
      return false
    }
  }

  waitlistOnly = (lesson) => {
    if (lesson.data.max_capacity !== null && lesson.data.total_booked !== null) {
      return (lesson.data.max_capacity <= lesson.data.total_booked)
    } else {
      return false
    }
  }

  render() {
    const lessonDays = this.lessonDays();

    if (this.props.isFetching) return <RenderLoading />;

    if (!lessonDays || _.isEmpty(lessonDays)) return <div className="Empty-result">NO LESSONS</div>;

    return (
      <div>
        {this.tables(lessonDays)}
      </div>
    );
  }
}

Schedules.propTypes = {
  lessons: PropTypes.array.isRequired,
  selectLesson: PropTypes.func.isRequired
};

Schedules.defaultProps = {
  lessons: []
};
