import { createStore, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import rootReducer from '../reducers/index'
import createHistory from 'history/createBrowserHistory'
import { routerMiddleware } from 'react-router-redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export const history = createHistory()
const middleware = routerMiddleware(history)

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['unauthorizedCart']
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

export default function configureStore() {
  let store = createStore(
    persistedReducer,
    composeEnhancers(
      applyMiddleware(thunkMiddleware, middleware)
    )
  );

  let persistor = persistStore(store);

  return { store, persistor, history };
}