import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DropdownSelect from '../dropdown_select.jsx';

class TvLivePurchaseList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedService: null,
      options: [{ name: 'Single Live Class Only: $15', id: '1' },
      { name: 'Subscribe + 1 Live Class: $25 ($20 + $5)', id: '2' },
        // { name: 'Subscribe + 5 Live Classes: $45 ($20 + $25) ', id: '3' },
        // { name: 'Subscribe +10 Live Classes: $70 ($20 + $50)', id: '4' }
      ],
    }
  }

  openBilling = (service) => {
    this.props.selectService(service)
    this.props.nextStep(service)
  }

  onFiltersChange = (event) => {
    const { value } = event.target

    if (value) {
      this.setState({ selectedService: this.findService(value) });
    } else {
      this.setState({ selectedService: null });
    }
  }

  findService = (service_id) => {
    return this.state.options.find(s => s.id === service_id)
  }

  render() {
    const { selectedService, options } = this.state;

    return (
      <Fragment>
        <div className="PaymentPopup">
          <h2 className="PaymentPopup-title">PURCHASE</h2>
          <div className="PaymentPopup-text">
            You're almost there!
          </div>
          <div className="PaymentPopup-input">
            <DropdownSelect
              options={options}
              onChange={this.onFiltersChange}
              optionLabel="name"
              optionId="id"
              placeholder="select option"
              name="service"
              filters={selectedService ? { service: selectedService.id } : {}}
              isCollapse
            />
          </div>
          <button
            onClick={() => this.openBilling(selectedService)}
            className="SmallButton"
            disabled={!selectedService}
          >
            Confirm
          </button>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userMembership: state.userMembership.membership,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {},
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(TvLivePurchaseList);
