import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import RenderError from "../components/render_error.jsx";
import RenderLoading from "../components/render_loading.jsx";
import { bindActionCreators } from "redux";
import { isAuthenticated } from "../reducers/user";
import { fetchTvPlans } from "../actions/tv_plans";
import scrollToTop from "../helpers/scrollToTop";
import MetaTagsComponent from "../components/meta_tags_component";
import TvSubscriptionSlider from "../components/tv_subscription_slider";
import { fetchUserTvSubscription } from "../actions/user_subscriptions";

class PricingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      loadingSub: true,
      error: null,
      subError: null,
    };
  }

  componentDidMount() {
    scrollToTop();
    this.getTvPlans();
    this.getSubscription();
  }

  getTvPlans = () => {
    return this.props
      .fetchTvPlans()
      .then(res => this.setState({ loading: false }))
      .catch(err => this.setState({ loading: false, error: err }));
  };

  getSubscription = () => {
    return this.props.fetchUserTvSubscription()
      .then(res => this.setState({ loadingSub: false }))
      .catch(err => this.setState({ loadingSub: false, subError: err }));
  };

  render() {
    const { error, subError, loading, loadingSub } = this.state;
    const { tvPlans, isAuthenticated, subscription } = this.props;

    if (loading || loadingSub) return <RenderLoading className="centered" />;

    return (
      <Fragment>
        <MetaTagsComponent
          title="SKY TING | Pricing"
          desc="SKY TING pricing"
          url="pricing"
        />
        <div className="tv-pricing padding-lr">
          { (error || subError) ? (
            <RenderError error={error || subError} />
          ) : (
            <>
              <h2 className="Page-header">PAY IT FORWARD WITH YOUR SUBSCRIPTION!</h2>
              <TvSubscriptionSlider
                tvPlans={tvPlans}
                isAuthenticated={isAuthenticated}
                subscription={subscription.subscription}
                subscriptionStatus={subscription.status}
              />
            </>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  tvPlans: state.tvPlans,
  subscription: state.userTvSubscription,
  isAuthenticated: isAuthenticated(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchTvPlans,
      fetchUserTvSubscription
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PricingPage);
