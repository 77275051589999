import React, { Component } from "react";
import Popup from "../popup.jsx";
import LoginForm from "./login_form";
import ServicePayment from "../service_payment";
import { history } from '../../store/configureStore';

class PricingPurchasePopup extends Component {
  componentWillMount() {
    document.body.classList.add("overflow-hidden");
  }

  componentWillUnmount() {
    document.body.classList.remove("overflow-hidden");
  }


  closePopup = () => {
    const { onClose } = this.props;

    onClose();
  }

  render() {
    const { activeService, isAuthenticated, pricingPage, onClose, blockPopupClose, user } = this.props;

    if (!isAuthenticated)
      return (
        <Popup id="pricing-purchase" isOpen onClose={onClose}>
          <LoginForm />
        </Popup>
      );

    return (
      <Popup id="pricing-purchase" isOpen onClose={() => this.closePopup()}>
        <ServicePayment
          activeService={activeService}
          pricingPage={pricingPage}
          afterCheckout={onClose}
          closePurchasePopup={onClose}
          blockPopupClose={blockPopupClose}
        />
      </Popup>
    );
  }
}

export default PricingPurchasePopup;
