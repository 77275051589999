import {
  OPEN_CANCEL_LESSON_POPUP,
  CLOSE_CANCEL_LESSON_POPUP,
} from '../actions/cancel_lesson_popup';

const INITIAL_STATE = {
  show: false,
  lesson: null,
};

const cancelLessonPopup = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OPEN_CANCEL_LESSON_POPUP:
      return {
        ...state,
        show: true,
        lesson: action.lesson,
      };
    case CLOSE_CANCEL_LESSON_POPUP:
      return {
        ...state,
        show: false,
        lesson: null,
      };
    default:
      return state;
  }
};

export default cancelLessonPopup;
