import React, {Component, Fragment} from 'react';
import moment from 'moment';

class Countdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      days: null,
      hours: null,
      min: null,
      sec: null,
      isLoading: true
    };
  }

  componentDidMount() {
    // update every second
    this.interval = setInterval(() => {
      const date = this.calculateCountdown(this.props.date);
      if (date) {
        this.setState(date);
        this.setState({ isLoading: false });
      } else {
        this.stop();
      }
    }, 1000);
  }

  componentWillUnmount() {
    this.stop();
  }

  calculateCountdown(endDate) {
    let diff = endDate.diff(moment.utc(), 'seconds');

    // clear countdown when date is reached
    if (diff <= 0) return false;

    const timeLeft = {
      years: 0,
      days: 0,
      hours: 0,
      min: 0,
      sec: 0,
      millisec: 0
    };

    // calculate time difference between now and expected date
    if (diff >= 365.25 * 86400) {
      // 365.25 * 24 * 60 * 60
      timeLeft.years = Math.floor(diff / (365.25 * 86400));
      diff -= timeLeft.years * 365.25 * 86400;
    }
    if (diff >= 86400) {
      // 24 * 60 * 60
      timeLeft.days = Math.floor(diff / 86400);
      diff -= timeLeft.days * 86400;
    }
    if (diff >= 3600) {
      // 60 * 60
      timeLeft.hours = Math.floor(diff / 3600);
      diff -= timeLeft.hours * 3600;
    }
    if (diff >= 60) {
      timeLeft.min = Math.floor(diff / 60);
      diff -= timeLeft.min * 60;
    }
    timeLeft.sec = diff;

    return timeLeft;
  }

  stop() {
    this.props.onComplete();
    clearInterval(this.interval);
  }

  render() {
    const countDown = this.state;

    const withoutDays = !countDown.days && countDown.days === 0;
    const withoutHours = !countDown.hours && countDown.hours === 0;
    const withoutMinutes = !countDown.min && countDown.min === 0;

    return (
      <div className="countdown">
        { this.state.isLoading ? (
          <div />
        ) : (
          <Fragment>
            <span className="text">CLASS STARTS IN&nbsp;</span>
            { !withoutDays && (
              <span className="countdown-col">
                <span className="countdown-col-element">
                  {countDown.days} { countDown.days > 1 ? 'DAYS' : 'DAY' }&nbsp;
                </span>
              </span>
            )}
            { !withoutHours && (
              <span className="countdown-col">
                <span className="countdown-col-element">
                  {countDown.hours} { countDown.hours > 1 ? 'HOURS' : 'HOUR' }&nbsp;
                </span>
              </span>
            )}
            { !withoutMinutes && (
              <span className="countdown-col">
              <span className="countdown-col-element">
                {countDown.min} { countDown.min > 1 ? 'MINUTES' : 'MINUTE' }
                <span>&nbsp;AND&nbsp;</span>
              </span>
            </span>
            )}
            <span className="countdown-col">
              <span className="countdown-col-element">
                {countDown.sec} { countDown.sec > 1 ? 'SECONDS' : 'SECOND' }
              </span>
            </span>
          </Fragment>
        )}
      </div>
    );
  }
}

export default Countdown;
