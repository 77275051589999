import React, { Component } from "react";
import GiftCardInner from "../../../../assets/images/gift_card_blue.png";

class GiftCardSuccess extends Component {
  render() {
    const {
      selectedCard,
      selectedTvCard,
      recipient_email,
      user: { email },
      onClose
    } = this.props;

    const amount = selectedTvCard ? selectedTvCard.amount : selectedCard.amount;

    return (
      <div className="gift-card-popup-success-container">
        <div className="gift-card-popup-success">
          <div className="gift-card-popup-header">
            ${amount} GIFT CARD SENT!
          </div>
          <div className="redeem-img-wrapper">
            <img src={GiftCardInner} width={230} alt="Gift card" />
            <div className="redeem-img-inner">${amount}</div>
          </div>

          <div className="text-center text-uppercase info">
            <div className="text-14 mb-1">FROM</div>
            <div className="font-weight-bold mb-3">{email}</div>
            <hr />
            <div className="text-14 mb-1 mt-3">TO</div>
            <div className="font-weight-bold">{recipient_email}</div>
          </div>
          <button
            className="Button mt-4 w-100 high-button rounded no-hover"
            onClick={() => {
              onClose();
              window.scrollTo(0, 0);
            }}
          >
            Continue shopping?
          </button>
        </div>
      </div>
    );
  }
}

export default GiftCardSuccess;
