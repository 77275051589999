import Api from "../api/mentorship";

export const FETCH_MENTORSHIP = 'FETCH_MENTORSHIP';
export const FETCH_MENTORSHIP_ERROR = 'FETCH_MENTORSHIP_ERROR';
export const FETCH_MENTORSHIP_SUCCESS = 'FETCH_MENTORSHIP_SUCCESS';

  export const fetchMentorship = () => dispatch => {
  dispatch({ type: FETCH_MENTORSHIP });

  Api.fetchMentorship()
    .then(res =>
      dispatch({
        type: FETCH_MENTORSHIP_SUCCESS,
        mentorship: res.body
      })
    )
    .catch(err =>
      dispatch({
        type: FETCH_MENTORSHIP_ERROR,
        error: err
      })
    );
};
