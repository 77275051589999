import superagent from 'superagent';

const API_ENDPOINT = '/products';

const fetchProducts = (params) => {
  return superagent
    .get(API_ENDPOINT)
    .set('Accept', 'application/json')
    .query(params);

}

export default { fetchProducts };
