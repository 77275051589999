import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import superagent from 'superagent';
import RenderLoading from '../components/render_loading.jsx';
import RenderError from '../components/render_error.jsx';
import JobCard from '../components/job-card';
import scrollToTop from "../helpers/scrollToTop";
import MetaTagsComponent from "../components/meta_tags_component";

export default class JobsPage extends Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: true };
  }

  componentDidMount() {
    scrollToTop();
    return superagent
      .get('/jobs')
      .set('Accept', 'application/json')
      .send().then(res =>
        this.setState({
          jobs: res.body,
          isLoading: false,
        }))
      .catch((error) => {
        this.setState({ error });
      });
  }

  render() {
    const { error, jobs, isLoading } = this.state;

    if (error) return <RenderError error={error} />;

    return (
      <div className="Page Jobs-page">
        <MetaTagsComponent
          title="SKY TING | About | Locations"
          desc="SKY TING locations"
          url="jobs"
        />
        <div className="Page-content">
          <h2 className="Page-header">JOBS</h2>
          { isLoading ? (
            <RenderLoading />
          ) : (
            <Fragment>
              { _.isEmpty(jobs) ? (
                <div className="Empty-result">THERE ARE CURRENTLY NO VACANCIES AVAILABLE</div>
              ) : (
                <Fragment>
                  { jobs.map(job => (
                    <JobCard job={job} key={job.id} />
                  ))}
                </Fragment>
              )}
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}
