import Api from '../api/services';

export const FETCH_SERVICES = 'FETCH_SERVICES';
export const FETCH_SERVICES_ERROR = 'FETCH_SERVICES_ERROR';
export const FETCH_SERVICES_SUCCESS = 'FETCH_SERVICES_SUCCESS';

export const fetchServicesFor = lessonId => dispatch => {
  dispatch({ type: FETCH_SERVICES });

  Api.fetchServicesFor(lessonId)
    .then(res => dispatch({ type: FETCH_SERVICES_SUCCESS, services: res.body }))
    .catch(error => dispatch({ type: FETCH_SERVICES_ERROR, error }));
};
