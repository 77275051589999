import superagent from 'superagent'

const API_ENDPOINT = '/checkout'

const checkoutService = (service_id, amount, appointment, is_contract, promoCode, gift_card_amount = null, recaptchaValue) => {
  return superagent
    .post(API_ENDPOINT)
    .set('Accept', 'application/json')
    .send({ service_id, amount, appointment, is_contract, promo_code: promoCode, gift_card_amount, recaptchaValue })
};

export default { checkoutService }
