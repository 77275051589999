import React, { Component, Fragment } from "react";
import _ from "lodash";
import superagent from "superagent";
import Slider from "react-slick";
import { isMobile } from "react-device-detect";
import skyTingTv from "../../../assets/images/icons/sky_ting_tv.png";

class HomePageSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      images: [],
      coverImage: null
    };
  }

  componentWillMount() {
    return superagent
      .get("/home_page_images")
      .set("Accept", "application/json")
      .send()
      .then(res =>
        this.setState({
          images: res.body.images.filter(i => !i.is_cover),
          coverImage: res.body.images.find(i => i.is_cover === true),
          isLoading: false
        })
      )
      .catch(error => {
        this.setState({ error: error });
      });
  }

  onVideoPlay = () => {
    this.slider.slickPause();
  }

  onVideoEnd = () => {
    this.slider.slickPlay();
  };

  pauseVideo = sliderIndex => {
    let el = document.getElementById(`videoRef-${sliderIndex}`);
    if (el) {
      el.pause();
    }
  };

  playVideo = sliderIndex => {
    let el = document.getElementById(`videoRef-${sliderIndex}`);
    if (el) {
      el.play();
    }
  };

  render() {
    const { images, error, isLoading, coverImage } = this.state;

    if (isLoading) return <div className="Homepage-slider" />;

    if (error || _.isEmpty(images)) return <div />;

    return (
      <Fragment>
        <div className="Homepage-slider with-large-dots dots-white dots-inside">
          <div className="cover-text padding-lr">
            <img src={skyTingTv} />
            <div className="subtext">
              YOUR FREE 7 DAY TRIAL TO SKY TING TV ONLINE YOGA AWAITS. NO RISK. CANCEL ANYTIME.
            </div>
            <div className="text">
              Stream yoga & techniques for wellbeing anytime, anywhere, for anyone.
            </div>
          </div>
          { images && images.length > 0 && (
            <Slider
              dots
              infinite
              speed={300}
              autoplay
              fade
              cssEase="ease-in"
              pauseOnHover={false}
              ref={slider => (this.slider = slider)}
              beforeChange={currentIndex => this.pauseVideo(currentIndex)}
              afterChange={currentIndex => this.playVideo(currentIndex)}
            >
              {images.map((image, index) => (
                <Fragment key={image.id}>
                  {/*{ image.link &&*/}
                  {/*  <a href={image.link} className="home-slider-link" target="_blank" title="Visit">&nbsp;</a>*/}
                  {/*}*/}
                  { isMobile ? (
                    <div className="img-wrapper">
                      <img src={image.image_for_mobile || image.image_url} alt="SKY TING" />
                    </div>
                  ) : (
                    <div className="img-wrapper">
                      {image.video_url ? (
                        <video
                          autoPlay={index === 0}
                          muted
                          poster={image.image_url}
                          playsInline
                          id={`videoRef-${index}`}
                          onPlay={() => this.onVideoPlay()}
                          onEnded={() => this.onVideoEnd()}
                        >
                          <source src={image.video_url} type={image.video_type === 'video/quicktime' ? 'video/mp4' : image.video_type} />
                          <p>
                            Your user agent does not support the HTMLs Video element
                          </p>
                        </video>
                      ) : (
                        <img key={image.id} src={image.image_url} alt="SKY TING" />
                      )}
                    </div>
                  )}
                </Fragment>
              ))}
            </Slider>
          )}
        </div>
      </Fragment>
    );
  }
}

export default HomePageSlider;
