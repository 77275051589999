import superagent from 'superagent';

const API_ENDPOINT = '/services';

const fetchServicesFor = lessonId =>
  superagent
    .get(API_ENDPOINT)
    .set('Accept', 'application/json')
    .query({ lesson_id: lessonId });

export default { fetchServicesFor };
