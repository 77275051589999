import React, {Component, Fragment} from 'react'
import SaunaForm from '../components/sauna/form.jsx'
import { Collapse } from 'react-collapse'
import superagent from "superagent"
import RenderError from '../components/render_error.jsx'
import { filterChange } from "../actions/filters"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { fetchSaunas } from "../actions/saunas"
import { selectService } from "../actions/active_service"
import PurchasePopup from '../components/purchase_popup.jsx'
import { selectAppointment } from "../actions/active_appointment"
import { checkoutTotal } from "../actions/checkout_total";
import { isAuthenticated } from "../reducers/user";
import { openLoginPopup } from "../actions/login_popup";
import { openPurchasePopup } from "../actions/purchase_popup";
import scrollToTop from "../helpers/scrollToTop";
import MetaTagsComponent from "../components/meta_tags_component";

class SaunaPage extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isLoadingInfo: true,
      isLoadingPhotos: true,
      saunaCards: [],
      saunaPhotos: [],
      openedCardId: null,
    }
  }

  getSaunaInfos = () => {
    return superagent
      .get('/sauna_infos')
      .set('Accept', 'application/json')
      .send().then(res =>
        this.setState({
          saunaCards: res.body,
          isLoadingInfo: false
        }))
      .catch(error => {
        this.setState({
          error: error,
          isLoadingInfo: false
        })
      })
  }

  getSaunaPhotos = () => {
    return superagent
      .get('/sauna_photos')
      .set('Accept', 'application/json')
      .send().then(res =>
        this.setState({
          saunaPhotos: res.body,
          isLoadingPhotos: false
        }))
      .catch(error => {
        this.setState({
          error: error,
          isLoadingPhotos: false
        });
      });
  }

  componentWillMount() {
    const { filterChange } = this.props

    this.getSaunaInfos()
    this.getSaunaPhotos()
    filterChange({ "day": new Date() })
  }

  componentDidMount() {
    scrollToTop();
  }

  compareArrays = (arr1, arr2) => {
    return ((arr1.length === arr2.length) && (arr1.every(el => arr2.includes(el))))
  }

  componentWillReceiveProps(nextProps) {
    const { fetchSaunas, filters, filterChange, hours_available, session_types } = this.props
    const { filters: nextFilters } = nextProps

    if (nextFilters && nextFilters.day) {
      if ((filters && filters.day) !== nextFilters.day) {
        fetchSaunas(nextProps.filters.day.toISOString())
      }
    }

    if (nextFilters && nextFilters.session_type) {
      if ((filters && filters.session_type) !== nextFilters.session_type) {
        filterChange({ "hours_available": null })
      }
    }

    if (nextProps && !this.compareArrays(nextProps.session_types, session_types) && filters.session_type) {
      if (!(nextProps.session_types).map(s => s.id).includes(filters.session_type)) {
        filterChange({ 'session_type': null })
      }
    }

    if (nextProps && !this.compareArrays(nextProps.hours_available, hours_available) && filters.hours_available) {
      if (!(nextProps.hours_available).map(h => h.id).includes(filters.hours_available)) {
        filterChange({ 'hours_available': null })
      }
    }
  }

  openCard = (id) => {
    if (this.state.openedCardId === id) {
      this.setState({ openedCardId: null })
    } else {
      this.setState({ openedCardId: id })
    }
  }

  onFiltersChange = (event) => {
    const { filterChange } = this.props
    const { name, value } = event.target

    filterChange({ [name]: value })
  }

  bookSauna = () => {
    const {
      filters,
      hours_available,
      services,
      selectService,
      selectAppointment,
      checkoutTotal,
      isAuthenticated,
      openLoginPopup,
      openPurchasePopup,
    } = this.props

    const appointment = hours_available.find(hour =>
      hour.id === filters.hours_available
    ).appointment

    const service = services.find(service =>
      service.type === filters.session_type
    )

    if (!isAuthenticated) {
      return openLoginPopup()
    }

    checkoutTotal([{
      mindbody_identifier: service.id,
      quantity: 1
    }], 'Service')

    selectService(service)
    selectAppointment(appointment)
    openPurchasePopup()
  }

  render() {

    const { error, saunaCards, isLoadingInfo, openedCardId } = this.state
    const { filters, hours_available, session_types, saunasIsFetching, activeService  } = this.props
    const { bookSauna } = this

    if (error) return <RenderError error={error} />;

    if ((!saunaCards || _.isEmpty(saunaCards)) && !isLoadingInfo )
      return (
        <div>
          <div className="Empty-result">NO SAUNAS</div>
        </div>
      )

    return (
      <Fragment>
        <MetaTagsComponent
          title="SKY TING | Programs | Sauna"
          desc="SKY TING sauna"
          url="sauna"
        />
        <div className="Page Saunas">
          <div className="Page-content">
            <h2 className="Page-header">INFRARED SAUNA</h2>
            <SaunaForm
              session_types={session_types}
              hours_available={hours_available}
              onFiltersChange={this.onFiltersChange}
              filters={filters}
              bookSauna={bookSauna}
              saunasIsFetching={saunasIsFetching}
            />
            <div className="Page-header Sauna-faq-header">
              SAUNA FAQ
            </div>

            <div className="Saunas-cards">
              { (!isLoadingInfo && saunaCards.length > 0) &&
                <Fragment>
                  { saunaCards.map(el => (
                    <div
                      onClick={() => this.openCard(el.id)}
                      className={openedCardId === el.id ? 'Sauna-card' : 'Sauna-card hidden'}
                      key={el.id}
                    >
                      <div className="Sauna-card-header">
                        {el.header}
                        <div className={openedCardId === el.id ? 'arrow-down open' : 'arrow-down'} />
                      </div>
                      <Collapse isOpened={openedCardId === el.id}>
                        <div className="Sauna-card-bottom">
                          {el.text &&
                          <div dangerouslySetInnerHTML={{ __html: el.text }} />
                          }
                        </div>
                      </Collapse>
                    </div>
                  ))}
                </Fragment>
              }
            </div>
          </div>
          { activeService &&
            <PurchasePopup />
          }
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  filters: state.filters,
  session_types: state.saunas.session_types,
  hours_available: state.saunas.hours_available,
  saunasIsFetching: state.saunas.isFetching,
  services: state.saunas.services,
  activeService: state.activeService,
  isAuthenticated: isAuthenticated(state)
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      filterChange,
      fetchSaunas,
      selectService,
      selectAppointment,
      checkoutTotal,
      openLoginPopup,
      openPurchasePopup
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(SaunaPage)