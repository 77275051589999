import moment from 'moment';

import Api from '../api/schedule';
import ScheduleSerializer from '../services/schedule_serializer';

export const FETCH_SCHEDULE = 'FETCH_SCHEDULE';
export const FETCH_SCHEDULE_ERROR = 'FETCH_SCHEDULE_ERROR';
export const FETCH_SCHEDULE_SUCCESS = 'FETCH_SCHEDULE_SUCCESS';

const fetchScheduleSuccess = lessons => dispatch => {
  const schedule = ScheduleSerializer(lessons);

  dispatch({ type: FETCH_SCHEDULE_SUCCESS, schedule });
};

export const fetchSchedule = (params = {}) => dispatch => {
  dispatch({ type: FETCH_SCHEDULE })

  params.time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone

  Api.fetchSchedule(params)
    .then(res => dispatch(fetchScheduleSuccess(res.body.lessons)))
    .catch(error => dispatch({ type: FETCH_SCHEDULE_ERROR, error }))
}

export const fetchTodaySchedule = () => dispatch => {
  const now = moment().utc();
  const end_date_time = now.endOf('day').toISOString();
  const start_date_time = now.startOf('day').toISOString();

  dispatch(fetchSchedule({ end_date_time, start_date_time }));
};
