import Api from '../api/user_past_classes';

export const FETCH_USER_PAST_CLASSES = 'FETCH_USER_PAST_CLASSES';
export const FETCH_USER_PAST_CLASSES_ERROR = 'FETCH_USER_PAST_CLASSES_ERROR';
export const FETCH_USER_PAST_CLASSES_SUCCESS = 'FETCH_USER_PAST_CLASSES_SUCCESS';


export const fetchUserPastClasses = () => dispatch => {
  dispatch({ type: FETCH_USER_PAST_CLASSES });

  Api.fetchUserPastClasses()
    .then(res => 
      dispatch({
        type: FETCH_USER_PAST_CLASSES_SUCCESS,
        past_classes: res.body
      }),
    )
    .catch(err =>
      dispatch({
        type: FETCH_USER_PAST_CLASSES_ERROR,
        error: err
      })
    );
};