import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import { history } from "../store/configureStore";
import MetaTagsComponent from "../components/meta_tags_component";
import RenderLoading from "../components/render_loading.jsx";
import RenderError from "../components/render_error.jsx";
import scrollToTop from "../helpers/scrollToTop";
import { getParamByName } from "../helpers/get_param_by_name";
import arrow from "../../../assets/images/icons/arrow_right_blue.svg";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchBlogPage } from "../actions/blog_page";
import { fetchBlog } from "../actions/blog";
import star from "../../../assets/images/icons/star_in_circle.svg";

class BlogPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      categoryFilterOptions: [],
      categoryFilter: { id: "all", name: "ALL" },
      selectedAuthor: null,
      selectedTag: null,
    };
  }

  componentDidMount() {
    scrollToTop();
    if (this.props.posts.length === 0) {
      this.getPosts(1);
    } else {
      this.prepareCategoryFilter(this.props.posts);
      this.prepareAuthorFilter(this.props.posts);
      this.prepareTagFilter(this.props.posts);
    }
    this.props.fetchBlogPage();
  }

  loadPosts = () => {
    const { page } = this.props;
    const nextPage = page + 1;

    this.getPosts(nextPage);
  }

  getPosts = (page = null) => {
    this.setState({ isLoading: true })
    this.props.fetchBlog(page)
      .then(res => {
        const allPosts = res;
        this.setState({
          isLoading: false,
        });
        this.prepareCategoryFilter(allPosts);
        this.prepareAuthorFilter(allPosts);
        this.prepareTagFilter(allPosts);
      })
      .catch(error => {
        this.setState({ error: error, isLoading: false });
      });
  };

  prepareCategoryFilter = posts => {
    const options = [{ id: "all", name: "ALL" }];
    const blogCategories = _.flatten(
      posts.map(p =>
        p.blog_categories.map(c => {
          return { id: c.slug, name: c.name };
        })
      )
    );

    const uniqCategories = _.uniqBy(blogCategories, "id");

    this.setCategoryFilterByParams(uniqCategories);

    if (uniqCategories.length > 0) {
      this.setState({
        categoryFilterOptions: options.concat(uniqCategories)
      });
    }
  };

  prepareAuthorFilter = posts => {
    const authorParam = getParamByName("author", "string");

    if (authorParam && posts.length > 0) {
      const postWithAuthor = posts.find(
        p => p.blog_author && p.blog_author.slug === authorParam
      );
      if (postWithAuthor) {
        this.setState({ selectedAuthor: postWithAuthor.blog_author });
      }
    }
  };

  prepareTagFilter = posts => {
    const tagParam = getParamByName("tag", "string");

    if (tagParam && posts.length > 0) {
      const postWithTag = posts.find(
        p =>
          p.blog_tags.length > 0 && p.blog_tags.find(t => t.slug === tagParam)
      );

      if (postWithTag) {
        this.setState({
          selectedTag: postWithTag.blog_tags.find(t => t.slug === tagParam)
        });
      }
    }
  };

  setCategoryFilterByParams = blogCategories => {
    const category_name = getParamByName("category", "string");
    if (category_name && blogCategories.length > 0) {
      const selectedCategory = blogCategories.find(
        c => c.id === category_name.toLowerCase()
      );
      if (!selectedCategory) return;

      this.setState({ categoryFilter: selectedCategory });
    }
  };

  renderFilter = () => {
    const { categoryFilterOptions, categoryFilter } = this.state;

    if (categoryFilterOptions.length === 0) return;

    return (
      <div className="category-filter-container">
        <select
          className="category-filter-select"
          id="product-sizes"
          onChange={event => this.onCategoryFilterChange(event)}
          value={categoryFilter.id}
        >
          {categoryFilterOptions.map(option => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </select>
      </div>
    );
  };

  filteredPosts = posts => {
    if (posts.length === 0) return [];

    const { categoryFilter, selectedAuthor, selectedTag } = this.state;

    if (selectedAuthor) {
      return posts.filter(
        p => p.blog_author && p.blog_author.id === selectedAuthor.id
      );
    } else if (selectedTag) {
      return posts.filter(p =>
        p.blog_tags.map(t => t.slug).includes(selectedTag.slug)
      );
    } else {
      if (categoryFilter.id !== "all") {
        return posts.filter(p =>
          p.blog_categories.map(c => c.slug).includes(categoryFilter.id)
        );
      } else {
        return posts;
      }
    }
  };

  onCategoryFilterChange = event => {
    const { categoryFilterOptions } = this.state;

    this.setState({
      categoryFilter: categoryFilterOptions.find(
        i => i.id === event.target.value
      ),
      selectedAuuthor: null
    });
    if (event.target.value === "all") {
      history.push({
        pathname: window.location.pathname,
        search: ""
      });
    } else {
      history.push(`?category=${event.target.value}`);
    }
  };

  onAuthorSelect = author => {
    this.setState({ selectedAuthor: author });
    history.push(`?author=${author.slug}`);
    scrollToTop();
  };

  renderAuthor = author => {
    if (!author) return;

    return (
      <div
        onClick={() => this.onAuthorSelect(author)}
        className="blog-author cursor-pointer"
      >
        <div className="blog-author-img">
          <img src={author.image_url} />
        </div>
        <div className="blog-author-name">
          {author.first_name} {author.last_name}
        </div>
      </div>
    );
  };

  renderPosts = filteredPosts => {
    return filteredPosts.map(post => (
      <div key={post.id} className="blog-list-el">
        <div className="left-column">
          <Link to={`/tribune/${post.slug}`} className="blog-list-el-image">
            {/*<img src="http://placekitten.com/200/300" />*/}
            <img src={post.thumbnail_url} alt={post.title} />
          </Link>
        </div>
        <div className="right-column">
          <div className="blog-list-el-info">
            <div className="hide-for-mobile">
              {this.renderAuthor(post.blog_author)}
            </div>
            <Link to={`/tribune/${post.slug}`} className="blog-list-el-title">
              {post.title}
            </Link>
            {post.publication_date && (
              <div className="blog-list-el-date hide-for-mobile">
                {moment(post.publication_date).format("MMMM D, YYYY")}
              </div>
            )}

            <div className="blog-list-el-author-date show-for-mobile">
              {this.renderAuthor(post.blog_author)}
              {post.publication_date && (
                <span className="date">
                  {moment(post.publication_date).format("M/DD/YYYY")}
                </span>
              )}
            </div>
            <span className="black-line hide-for-mobile" />
            <Link to={`/tribune/${post.slug}`} className="blog-list-el-text-wrapper">
              <p className="blog-list-el-text">{post.short_text}</p>
            </Link>
            <Link
              to={`/tribune/${post.slug}`}
              className="Button with-icon blog-continue-btn"
            >
              <span>CONTINUE READING</span>
              <img src={arrow} />
            </Link>
          </div>
        </div>
      </div>
    ));
  };

  clearFilters = () => {
    this.setState({
      categoryFilter: { id: "all", name: "ALL" },
      selectedAuthor: null,
      selectedTag: null
    });
    history.push({ pathname: window.location.pathname, search: "" });
  };

  render() {
    const {
      error,
      isLoading,
      selectedAuthor,
      categoryFilter,
      selectedTag,
      // isLastPage
    } = this.state;
    const { posts, blogPage, isLastPage } = this.props;

    if (error) return <RenderError error={error} />;

    const filteredPosts = this.filteredPosts(posts);

    return (
      <Fragment>
        <MetaTagsComponent
          title="SKY TING | Tribune"
          desc="SKY TING Tribune"
          url="tribune"
        />
        <div className="Page blog">
          <div className="Page-content">
            { (isLoading && posts.length === 0) ? (
              <RenderLoading className="absolute" />
            ) : (
              <Fragment>
                {(selectedAuthor ||
                  selectedTag ||
                  (categoryFilter && categoryFilter.id !== "all")) && (
                  <div
                    className="clear-filter-btn"
                    onClick={() => this.clearFilters()}
                  >
                    &#9668;&nbsp;{blogPage ? blogPage.all_post_link_text : 'ALL POSTS'}
                  </div>
                )}
                <h2 className="Page-header padding-lr">
                  {blogPage ? blogPage.title : 'TING TRIBUNE'}
                </h2>
                {selectedAuthor ? (
                  <div className="text-center">
                    <div className="selected-author-container">
                      <div className="selected-author-img">
                        {/*<img src="http://placekitten.com/200/300" />*/}
                        <img src={selectedAuthor.image_url} />
                      </div>
                      <div className="selected-author-name">
                        {selectedAuthor.first_name} {selectedAuthor.last_name}
                      </div>
                    </div>
                  </div>
                ) : (
                  <Fragment>
                    {selectedTag && posts.length > 0 ? (
                      <div className="selected-tag-container padding-lr">
                        {filteredPosts.length}{" "}
                        {filteredPosts.length === 1 ? "post" : "posts"} tagged
                        with{" "}
                        <span className="selected-tag-name">
                          {selectedTag.name}
                        </span>
                      </div>
                    ) : (
                      <Fragment>
                        {blogPage && blogPage.subtitle && (
                          <h3 className="page-subtitle padding-lr">
                            {blogPage.subtitle}
                          </h3>
                        )}
                        {posts.length > 0 && this.renderFilter()}
                      </Fragment>
                    )}
                  </Fragment>
                )}
                {posts.length > 0 ? (
                  <div className="blog-list">
                    {this.renderPosts(filteredPosts)}
                  </div>
                ) : (
                  <div className="Empty-result">NO POSTS</div>
                )}
                { !isLastPage &&
                  <Fragment>
                    { (posts.length > 0 && isLoading) ? (
                      <RenderLoading className="mt-4 mb-5" />
                    ) : (
                      <div onClick={() => this.loadPosts()} className="browse-btn cursor-pointer mt-0">
                        SHOW MORE ARTICLES
                        <img src={star} alt="blog" />
                      </div>
                    )}
                  </Fragment>
                }
              </Fragment>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  blogPage: state.blogPageContent,
  posts: state.blog.posts,
  page: state.blog.page,
  isLastPage: state.blog.isLastPage,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    fetchBlogPage,
    fetchBlog,
  },
  dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BlogPage);
