import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import superagent from "superagent";
import { Collapse } from "react-collapse";
import RenderLoading from "../components/render_loading.jsx";
import RenderError from "../components/render_error.jsx";
import scrollToTop from "../helpers/scrollToTop";
import MetaTagsComponent from "../components/meta_tags_component";
import ProductCarouselNew from "../components/product_carousel_new";
import CategoryPageLayout from "./tv_categories/category_page_layout";

class TeacherPage extends Component {
  constructor(props) {
    super(props);
    const teacher = this.findTeacher(props.match.params.id);

    // on initial load find product from products props if they are present
    this.state = {
      isLoading: false,
      error: null,
      teacher,
      showDescription: false,
      screenWidth: window.innerWidth,
      videos: [],
      videosLoading: false,
      videosError: null,
    };
  }

  // if teacher wasn't set on initial load, get teacher from server
  componentDidMount() {
    scrollToTop();
    window.addEventListener("resize", this.updateDimensions);

    const { id } = this.props.match.params;
    if (!this.state.teacher) {
      this.getTeacher(id);
    }
    this.getTeacherVideos(id);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({ screenWidth: window.innerWidth });
  };

  findTeacher = id => {
    const { teachers } = this.props;

    if (teachers.length === 0) return null;

    const teacher =
      teachers.find(t => t.slug === id) ||
      teachers.find(t => t.id.toString() === id);
    if (!teacher) return null;

    return teacher;
  };

  getTeacherVideos = id => {
    this.setState({ videosLoading: true });

    return superagent
      .get("/teacher_videos")
      .set("Accept", "application/json")
      .query({ teacher_id: id })
      .then(res =>
        this.setState({
          videos: res.body,
          videosLoading: false
        })
      )
      .catch(error => {
        this.setState({
          videosLoading: false,
          videosError: error.response.body
        });
      });
  };

  getTeacher = id => {
    this.setState({ isLoading: true, error: null });

    return superagent
      .get(`/teachers/${id}`)
      .set("Accept", "application/json")
      .send()
      .then(res => {
        this.setState({
          isLoading: false,
          teacher: res.body,
        });
      })
      .catch(error => {
        this.setState({ error: error.response.body, isLoading: false });
      });
  };

  teacherDetails = teacher => {
    return (
      <div className="teacher-details">
        {teacher.preferred_pronoun && (
          <div className="teacher-details-el">
            <span className="font-weight-bold">Preferred Pronoun:&nbsp;</span>
            <span>{teacher.preferred_pronoun}</span>
          </div>
        )}
        {teacher.hometown && (
          <div className="teacher-details-el">
            <span className="font-weight-bold">Hometown:&nbsp;</span>
            <span>{teacher.hometown}</span>
          </div>
        )}
        {teacher.birthday && (
          <div className="teacher-details-el">
            <span className="font-weight-bold">Birthday:&nbsp;</span>
            <span>{teacher.birthday}</span>
          </div>
        )}
        {teacher.training_info && (
          <div className="teacher-details-el">
            <span className="font-weight-bold">Training:&nbsp;</span>
            <span>{teacher.training_info}</span>
          </div>
        )}
        {teacher.describe_your_class && (
          <div className="teacher-details-el">
            <span className="font-weight-bold">
              Describe your class in one sentence:&nbsp;
            </span>
            <span>{teacher.describe_your_class}</span>
          </div>
        )}
        {teacher.music && (
          <div className="teacher-details-el">
            <span className="font-weight-bold">Music:&nbsp;</span>
            <span>{teacher.music}</span>
          </div>
        )}

        {teacher.special_talent && (
          <div className="teacher-details-el">
            <span className="font-weight-bold">Special talent:&nbsp;</span>
            <span>{teacher.special_talent}</span>
          </div>
        )}
      </div>
    );
  };

  renderTeacherImages = (teacher) => {
    const images = teacher.photo_url ? [{ id: teacher.id, url: teacher.photo_url }] : [];

    return (
      <ProductCarouselNew
        images={images.concat(teacher.images)}
        disableFullScreen
      />
    )
  }

  render() {
    const {
      teacher,
      videos,
      videosLoading,
      showDescription,
      error,
      screenWidth,
      isLoading
    } = this.state;

    if (error) return <RenderError error={error} />;
    if (isLoading) return <RenderLoading className="centered" />;

    return (
      <div className="Page teacher-page">
        {teacher && (
          <Fragment>
            <MetaTagsComponent
              title={`SKY TING | Teachers | ${teacher.name}`}
              desc={teacher.bio || teacher.name}
              url={`teachers/${teacher.slug}`}
              image={teacher.photo_url || teacher.image_url}
            />
            <div className="teacher-info for-small-only">
              <h2 className="Page-header padding-lr">{teacher.name}</h2>
            </div>
            <div className="teacher-page-top max-width-1800 m-auto padding-lr">
              <div className="teacher-page-column">
                {this.renderTeacherImages(teacher)}
              </div>
              <div className="teacher-page-column">
                <div className="teacher-info">
                  <h2 className="header hide-for-small">
                    {teacher.name}
                  </h2>
                  {/*<div className="subheader">{teacher.email}</div>*/}

                  <div className="line-bottom hide-for-small" />

                  {teacher.bio && (
                    <div
                      className="desc rich-text mt-4"
                      dangerouslySetInnerHTML={{
                        __html: teacher.bio
                      }}
                    />
                  )}

                  {teacher.bio && screenWidth <= 640 && (
                    <div
                      className={`description-collapse-btn ${
                        showDescription ? "opened" : ""
                      }`}
                      onClick={() =>
                        this.setState({ showDescription: !showDescription })
                      }
                    >
                      <span className="line" />
                      <div className="text">
                        {showDescription ? "LESS INFO" : "MORE INFO"}
                      </div>
                    </div>
                  )}

                  <Collapse
                    isOpened={
                      !teacher.bio || (teacher.bio && (showDescription || screenWidth > 640))
                    }
                  >
                    {this.teacherDetails(teacher)}
                  </Collapse>
                </div>
              </div>
            </div>

            <div className="teacher-videos">
              { videosLoading ? (
                <div className="mb-5 pb-5">
                  <RenderLoading />
                </div>
              ) : (
                <Fragment>
                  { videos.length > 0 &&
                    <Fragment>
                      <h3 className="teacher-page-subtitle padding-lr">
                        {videos.length === 1 ? '1 video' : `${videos.length} videos`}
                      </h3>
                      <CategoryPageLayout
                        videos={videos}
                        columnsCount={3}
                        hideTeacher
                        filtersNames={["level", "duration", "props", "intensity"]}
                      />
                    </Fragment>
                  }
                </Fragment>
              )}
            </div>

          </Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  teachers: state.teachers.teachers
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeacherPage);
