import React from "react";
import calcPriceWithCoupon from "../../helpers/calc_price_with_coupon";

const couponDescription = (
  couponDuration,
  couponDurationInMonths,
  couponPercentOff,
  couponAmountOff,
) => {
  const months =
    couponDurationInMonths && couponDurationInMonths === 1 ? "month" : "months";

  const terms = ["once", "forever"].includes(couponDuration)
    ? couponDuration
    : `for ${couponDurationInMonths} ${months}`;

  if (couponPercentOff) {
    return `${couponPercentOff}% OFF ${terms}`;
  } else {
    return `$${couponAmountOff / 100} OFF ${terms}`;
  }
};

const RenderPlanPrice = props => {
  const {
    selectedPlan,
    couponPercentOff,
    couponAmountOff,
    couponDuration,
    couponDurationInMonths
  } = props;

  const newPrice = selectedPlan ? calcPriceWithCoupon(couponPercentOff, couponAmountOff, selectedPlan.amount_in_cents) : null;

  return (
    <div className="render-plan-price">
      <div>
        {selectedPlan.amount_in_cents / 100 !== newPrice && (
          <span className="old-price">
            ${selectedPlan.amount_in_cents / 100} /{selectedPlan.interval}
          </span>
        )}
        <span className="new-price">
          {" "}
          ${newPrice} {newPrice === 0 ? "" : `/ ${selectedPlan.interval}`}
        </span>
      </div>
      {(couponPercentOff || couponAmountOff) && (
        <div className="coupon-desc">
          {couponDescription(
            couponDuration,
            couponDurationInMonths,
            couponPercentOff,
            couponAmountOff
          )}
        </div>
      )}
    </div>
  );
};

export default RenderPlanPrice;
