import {
  FETCH_LIVE_SCHEDULE_PAGE,
} from '../actions/live_schedule_page';

const INITIAL_STATE = {
  content: null,
  updated: null,
  subscribed: false,
};

const liveSchedulePage = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_LIVE_SCHEDULE_PAGE:
      return {
        ...state,
        content: action.content,
        subscribed: action.subscribed,
        updated: new Date(),
      };
    default:
      return state;
  }
};

export default liveSchedulePage;
