import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { closeCancelLessonPopup } from '../actions/cancel_lesson_popup';
import { cancelLesson } from '../actions/lesson';

import Popup from './popup.jsx';
import Loading from './../../../assets/images/icons/yinyang_icon.png';

class CancelLessonPopup extends Component {
  componentWillMount() {
    document.body.classList.add("overflow-hidden");
  }

  componentWillUnmount() {
    document.body.classList.remove("overflow-hidden");
  }

  renderCancelResponse = () => {
    const { cancelInProgress, cancelError } = this.props;

    if (cancelInProgress) return (
      <Fragment>
        <div className="Title">CANCEL LESSON</div>
        <div className="inline-loading">
          <img src={Loading} height="21" width="21" alt="Loading" />
          <div>LOADING...</div>
        </div>
      </Fragment>
    )

    if (cancelError) return (
      <Fragment>
        <div className="Title">CANCEL LESSON</div>
        <p>Something went wrong, try again please</p>
      </Fragment>
    )
  }

  renderPopupText = (lesson) => {
    // const type = lesson.type ? lesson.type : lesson.lessonType

    // if (type === 'SKY TING Workshops' || type === 'workshops' || type === 'Workshops') {
      return (
        <Fragment>
          <p>
            You are cancelling less than <u>12 hours before class.</u>
          </p>
          <p>You will be charged for this class. </p>
          <p>Need more flexibility? Become an <a href="/in-person-pricing">Unlimited Member</a> and avoid all cancelation fees.</p>
        </Fragment>
      )
    // }

    // return (
    //   <Fragment>
    //     <p>
    //       You are cancelling less than <u>12 hours before class:</u>
    //     </p>
    //     <p>
    //       UNLIMITED MEMBERS: $10 LATE CANCEL FEE <span style={{ whiteSpace: 'nowrap' }}>PAC-HOLDERS:</span> CHARGED FOR CLASS
    //     </p>
    //   </Fragment>
    // )
  }

  render() {
    const {
      isOpen,
      lesson,
      closeCancelLessonPopup,
      cancelLesson,
      cancelInProgress,
      cancelError,
    } = this.props

    if (!lesson) return <div />

    const cancelResult = (cancelInProgress || cancelError)
    const lessonId = lesson.class_id ? lesson.class_id : lesson.id

    return (
      <Popup
        key={lessonId}
        id={lessonId}
        isOpen={isOpen}
        onClose={() => closeCancelLessonPopup()}
      >
        <Fragment>
          <div className="Cancellation-policy-warning">
            { cancelResult ? (
              this.renderCancelResponse()
            ) : (
              <Fragment>
                <div className="Title">CANCELLATION POLICY</div>
                {this.renderPopupText(lesson)}

                <button
                  className="Button"
                  onClick={() => cancelLesson(lesson)}
                  disabled={cancelInProgress}
                >
                  CONFIRM
                </button>
              </Fragment>
            )}
          </div>
        </Fragment>
      </Popup>
    )
  }
}

CancelLessonPopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({
  isOpen: state.cancelLessonPopup.show,
  lesson: state.cancelLessonPopup.lesson,
  cancelInProgress: state.lesson.cancelInProgress,
  cancelError: state.lesson.cancelError,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      closeCancelLessonPopup,
      cancelLesson,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(CancelLessonPopup);
