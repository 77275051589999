import React, { Component } from "react";
import VideoThumb from "./video_thumb";

class VideosGrid extends Component {
  render() {
    const { videos, columnsCount, hideTeacher } = this.props;

    return (
      <div className="video-grid">
        {videos.map(v => (
          <div className={`video-grid-column ${columnsCount === 3 ? 'third' : 'forth'}`}>
            <VideoThumb video={v} hideTeacher={hideTeacher} />
          </div>
        ))}
      </div>
    );
  }
}

export default VideosGrid;
