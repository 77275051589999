import {
  FETCH_USER_SUBSCRIPTIONS,
  FETCH_USER_SUBSCRIPTIONS_ERROR,
  FETCH_USER_SUBSCRIPTIONS_SUCCESS,
} from '../actions/user_subscriptions';

const INITIAL_STATE = {
  subscriptions: [],
  error: null,
  isFetching: false,
};

const userSubscriptions = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_USER_SUBSCRIPTIONS:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_USER_SUBSCRIPTIONS_ERROR:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    case FETCH_USER_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        subscriptions: action.subscriptions,
        error: null,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default userSubscriptions;