import Api from "../api/checkout";

import { getBalance } from "./balances";
import { closePurchasePopup } from "./purchase_popup";
import { deselectService } from "./active_service";
import { dropCheckoutTotalCart } from "./checkout_total";
import { bookLesson } from "./lesson";

import { CLOSE_BILLING_POPUP, DROP_BILLING_LAST_4 } from "./billing";
import { OPEN_CHECKOUT_SUCCESS_POPUP } from "./checkout_success_popup";

export const CHECKOUT_SERVICE = "CHECKOUT_SERVICE";
export const CHECKOUT_SERVICE_ERROR = "CHECKOUT_SERVICE_ERROR";
export const CHECKOUT_SERVICE_SUCCESS = "CHECKOUT_SERVICE_SUCCESS";
export const CHECKOUT_SERVICE_INITIAL = "CHECKOUT_SERVICE_INITIAL";
export const RESET_CHECKOUT_SERVICE_ERROR = "RESET_CHECKOUT_SERVICE_ERROR";

const checkoutSuccess = hideCheckoutSuccessPopup => (dispatch, getState) => {
  dispatch({ type: CHECKOUT_SERVICE_SUCCESS });
  dispatch({ type: CLOSE_BILLING_POPUP });
  dispatch({ type: DROP_BILLING_LAST_4 });

  closePurchasePopup()(dispatch);
  deselectService()(dispatch);
  if (!hideCheckoutSuccessPopup) {
    dropCheckoutTotalCart()(dispatch);
  }

  const state = getState();

  if (state.lesson.waitingForFunds) {
    dispatch(bookLesson(state.lesson.bookingLessonId));
  } else {
    if (!hideCheckoutSuccessPopup) {
      dispatch({ type: OPEN_CHECKOUT_SUCCESS_POPUP });
    }
    dispatch(getBalance());
  }
};

export const checkoutService = (
  serviceId,
  amount,
  isContract = false,
  promoCode = null,
  gift_card_amount = null,
  hideCheckoutSuccessPopup = false,
  recaptchaValue
) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    const { activeAppointment } = getState();
    dispatch({ type: CHECKOUT_SERVICE });

    await Api.checkoutService(
      serviceId,
      amount,
      activeAppointment,
      isContract,
      promoCode,
      gift_card_amount,
      recaptchaValue
    )
      .then(() => {
        dispatch(checkoutSuccess(hideCheckoutSuccessPopup));
        resolve(true);
      })
      .catch(error => {
        dispatch({ type: CHECKOUT_SERVICE_ERROR, error: error.response.body });
        reject(error.response.body);
      });
  });
};
