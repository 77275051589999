import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Popup from "../popup";

class PriceInfoPopup extends Component {
  pricingTitle = title => {
    switch (title) {
      case "memberships":
        return "Unlimited Membership";
      case "specials":
        return "FIRST TIME";
      default:
        return title;
    }
  };

  period = type => {
    switch (type) {
      case "class packages":
        return "class";
      case "specials":
        return "3 classes";
      default:
        return "month";
    }
  };

  render() {
    const { priceCategory, close, styleAttrs } = this.props;

    const style = styleAttrs.find(i => i.type === priceCategory.type);

    return (
      <Popup
        isOpen
        id="price-info-popup"
        onClose={close}
        classNameProp={`price-info-popup ${style.color}`}
      >
        <div className={`pricing-card ${style.color}`}>
          <div className="pricing-card-top colored-border">
            {priceCategory.background_url ? (
              <Fragment>
                {["video/mp4", "video/webm"].includes(
                  priceCategory.background_type
                ) ? (
                  <div className="video-wrapper">
                    <video autoPlay muted loop>
                      <source
                        src={priceCategory.background_url}
                        type={priceCategory.background_type}
                      />
                    </video>
                  </div>
                ) : (
                  <img
                    className="background-img"
                    src={priceCategory.background_url}
                    alt="SKY TING"
                  />
                )}
              </Fragment>
            ) : (
              <img className="background-img" src={style.background} alt="SKY TING" />
            )}

            <div className="pricing-card-info">
              <div className="pricing-card-title">
                {priceCategory.name_header || this.pricingTitle(priceCategory.type)}
              </div>
              { priceCategory.price_header ? (
                <div>
                  <div className="colored-text">Starting at</div>
                  <div
                    className="amount"
                    dangerouslySetInnerHTML={{ __html: priceCategory.price_header }}
                  />
                </div>
              ) : (
                <Fragment>
                  { priceCategory.starting_at &&
                  <div>
                    <div className="colored-text">Starting at</div>
                    <div className="amount"><span>${priceCategory.starting_at}</span> / { this.period(priceCategory.type)}</div>
                  </div>
                  }
                </Fragment>
              )}
            </div>
          </div>
          <div className="pricing-card-bottom">
            <div className="content">
              <div
                className="long-desc mb-5"
                dangerouslySetInnerHTML={{ __html: priceCategory.long_description }}
              />
              { priceCategory.image_url &&
                <div>
                  <img src={priceCategory.image_url} alt="SKY TING" />
                </div>
              }
            </div>
          </div>
        </div>
      </Popup>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(PriceInfoPopup);
