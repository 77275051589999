import { FETCH_BLOG_PAGE_SUCCESS } from '../actions/blog_page';

const blogPageContent = (state = null, action) => {
  switch (action.type) {
    case FETCH_BLOG_PAGE_SUCCESS:
      return action.content;
    default:
      return state;
  }
};

export default blogPageContent;
