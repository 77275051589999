import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import Popup from "./popup.jsx";
import Hoverable from "./hoverable";

class AccountLessonPopup extends Component {
  componentWillMount() {
    document.body.classList.add("overflow-hidden");
  }

  componentWillUnmount() {
    document.body.classList.remove("overflow-hidden");
  }

  renderLesson = () => {
    const { lesson } = this.props;

    return (
      <div className="Lesson">
        <div className="Lesson-title">{lesson.name}</div>
        <div className="Lesson-subtitle">
          {`${moment
            .utc(lesson.start_date_time)
            .format("MMM D YYYY")} @ ${moment
            .utc(lesson.start_date_time)
            .format("hh:mm")} - ${moment
            .utc(lesson.end_date_time)
            .format("hh:mm A")}`}
        </div>

        {lesson.description && (
          <div
            className="Lesson-description"
            dangerouslySetInnerHTML={{ __html: lesson.description }}
          />
        )}

        { lesson.teacher_img &&
          <Fragment>
            { lesson.teacher_img && lesson.teacher_second_img ? (
              <div className="Lesson-staff-two-images">
                <div className="lesson-staff-img-wrapper mr-2">
                  <img src={lesson.teacher_img} alt={lesson.staff_name} />
                </div>
                <div className="lesson-staff-img-wrapper ml-2">
                  <img src={lesson.teacher_second_img} alt={lesson.staff_name} />
                </div>
              </div>
            ) : (
              <div className="Lesson-staff-image">
                <img src={lesson.teacher_img} alt={lesson.staff_name} />
              </div>
            )}
          </Fragment>
        }

        {lesson.teacher_is_active ? (

          <Link
            className="Button Hover-change"
            to={`/teachers?teacher=${lesson.teacher_slug}`}
            title="Go to teachers section"
          >
            <Hoverable hoverText="See teacher bio" key="1">
              {lesson.staff_name}
            </Hoverable>
          </Link>
        ) : (
          <div className="Button inactive-teacher">{lesson.staff_name}</div>
        )}

        {/*<Link to="/locations" title="Visit locations page" className="Button Hover-change">*/}
        {/*  <Hoverable hoverText="See other locations">*/}
        {/*    {lesson.location_name}*/}
        {/*  </Hoverable>*/}
        {/*</Link>*/}

        <div className="Lesson-locationAddress">
          {lesson.location_address1} {lesson.location_address2}
        </div>
        <div className="Lesson-locationAddress">{lesson.location_phone}</div>
      </div>
    );
  };

  render() {
    const { lesson, onClose } = this.props;

    return (
      <Popup key={lesson.id} id={lesson.id} isOpen onClose={() => onClose()}>
        {this.renderLesson()}
      </Popup>
    );
  }
}

export default AccountLessonPopup;
