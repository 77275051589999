import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import Popup from './popup.jsx';
import { closeCheckoutSuccessPopup } from '../actions/checkout_success_popup';

class CheckoutSuccessPopup extends Component {
  componentDidMount() {
    document.body.classList.add('overflow-hidden');
  }

  componentWillUnmount() {
    document.body.classList.remove('overflow-hidden');
  }

  render() {
    const { show, closeCheckoutSuccessPopup } = this.props

    return (
      <Popup
        isOpen={show}
        id="checkout-success-popup"
        onClose={() => closeCheckoutSuccessPopup()}
      >
        <div className="checkout-success-popup">
          <div className="text">
            Order confirmed. Hope you enjoy!
            <br />
            You can view details for all your purchases anytime on the My Account page.
          </div>
          <Link
            to="/account"
            onClick={() => closeCheckoutSuccessPopup()}
            className="Button"
          >
            MY ACCOUNT
          </Link>
        </div>
      </Popup>
    )
  }
}

const mapStateToProps = state => ({
  show: state.checkoutSuccessPopup.show,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      closeCheckoutSuccessPopup,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutSuccessPopup);
