import Api from '../api/user_purchases';

export const FETCH_USER_PURCHASES = 'FETCH_USER_PURCHASES';
export const FETCH_USER_PURCHASES_ERROR = 'FETCH_USER_PURCHASES_ERROR';
export const FETCH_USER_PURCHASES_SUCCESS = 'FETCH_USER_PURCHASES_SUCCESS';


export const fetchUserPurchases = period => dispatch => {
  dispatch({ type: FETCH_USER_PURCHASES });

  const time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone

  Api.fetchUserPurchases(period, time_zone)
    .then(res =>
      dispatch({
        type: FETCH_USER_PURCHASES_SUCCESS,
        purchases: res.body
      }),
    )
    .catch(err =>
      dispatch({
        type: FETCH_USER_PURCHASES_ERROR,
        error: err
      })
    );
};