import React, { Fragment } from 'react'
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import Avatars from "../data/Avatars";
import AvatarPicker from './avatar_picker'
import InputMask from 'react-input-mask';
import registerReasons from "../data/register_reasons";
import Checkbox from "./checkbox";
import Loading from "../../../assets/images/icons/yinyang_icon.png";
import ReactSelectComponent from "./react_select_component";

const randomAvatar = Avatars[Math.floor(Math.random() * Avatars.length)]

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const dateExp = /^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{2})*$/

const passwordRegExpDigit = /\d/
const passwordRegExpLetter = /[a-zA-Z]/

const RegistrationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Password must contain 8 to 15 characters and must include both letters and numbers')
    .max(15, 'Password must contain 8 to 15 characters and must include both letters and numbers')
    .matches(passwordRegExpDigit, 'Password must include both letters and numbers')
    .matches(passwordRegExpLetter, 'Password must include both letters and numbers')
    .required('Required'),
  first_name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  last_name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  mobile_phone: Yup.string()
    .min(9, 'Too Short!')
    .required('Required'),
  birth_date: Yup.string()
    .matches(dateExp, 'Birth date is not valid')
    .required('Required'),
  address_line1: Yup.string()
    .min(2, 'Too Short!')
    .required('Required'),
  city: Yup.string()
    .min(2, 'Too Short!')
    .required('Required'),
  state: Yup.string()
    .required('Required'),
  postal_code: Yup.string()
    .min(2, 'Too Short!')
    .required('Required'),
  country: Yup.string()
    .required('Required'),
  referred_by: Yup.mixed()
    .required('Required'),
  referred_by_others: Yup.string()
    .when('referred_by', (val) => ((val === 10 || val === '10') ? Yup.string().required('Required') : Yup.string())),
});


const RegisterFormStep1New = (props) => (
  <div>
    <Formik
      initialValues={{
        first_name: props.basicInfo ? props.basicInfo.first_name : '',
        last_name: props.basicInfo ? props.basicInfo.last_name : '',
        email: props.basicInfo ? props.basicInfo.email : '',
        mobile_phone: props.basicInfo ? props.basicInfo.mobile_phone : '',
        birth_date: props.basicInfo ? props.basicInfo.birth_date : '',
        password: props.basicInfo ? props.basicInfo.password : '',
        photo_url: props.basicInfo ? props.basicInfo.photo_url : randomAvatar.description,
        address_line1: props.basicInfo ? props.basicInfo.address_line1 : '',
        address_line2: props.basicInfo ? props.basicInfo.address_line2 : '',
        city: props.basicInfo ? props.basicInfo.city : '',
        state: props.basicInfo ? props.basicInfo.state : '',
        country: props.basicInfo ? props.basicInfo.country : '',
        postal_code: props.basicInfo ? props.basicInfo.postal_code : '',
        referred_by: props.basicInfo ? props.basicInfo.referred_by : '',
        referred_by_others: props.basicInfo ? props.basicInfo.referred_by_others : '',
        join_newsletter: props.basicInfo ? props.basicInfo.join_newsletter : true
      }}

      validationSchema={RegistrationSchema}

      onSubmit={( values, { setSubmitting, setErrors }) => {
        console.log(values)
        props.goToSecondStep(values);
      }}

      render={({
                 values,
                 errors,
                 touched,
                 handleChange,
                 handleBlur,
                 handleSubmit,
                 isSubmitting,
                 setFieldValue
               }) => (

        <div className="Registration">
          <div className="Registration-content-popup">
            <div className="Registration-title">
              { props.title }
            </div>
            <div className="Registration-subtitle">
              Welcome to SKY TING! Please&nbsp;choose your avatar:
            </div>


            <form onSubmit={handleSubmit} className="Registration-form-popup">
              <div className="RegistrationAvatarWrapper mb-4">
                <AvatarPicker onChange={handleChange} value={values.photo_url} />
              </div>
              <div className="Registration-field">
                <Field type="email" name="email" placeholder="Email" className="TextField" />
                {touched.email && errors.email && <div className="Registration-error">{errors.email}</div>}
                { (props.backendErrors && props.backendErrors.email) &&
                <Fragment>
                  { props.backendErrors.email.map( msg =>
                    <div className="Registration-error">{ msg }</div>
                  )}
                </Fragment>
                }
              </div>
              <div className="Registration-field">
                <Field type="password" name="password" placeholder="PASSWORD" className="TextField" />
                {touched.password && errors.password && <div className="Registration-error">{errors.password}</div>}
                { (props.backendErrors && props.backendErrors.password) &&
                <Fragment>
                  { props.backendErrors.password.map( msg =>
                    <div className="Registration-error">{ msg }</div>
                  )}
                </Fragment>
                }
              </div>
              <div className="Registration-twoFields">
                <div className="Registration-field">
                  <Field name="first_name" placeholder="First name" className="TextField" />
                  {touched.first_name && errors.first_name && <div className="Registration-error">{errors.first_name}</div>}
                  { (props.backendErrors && props.backendErrors.first_name) &&
                  <Fragment>
                    { props.backendErrors.first_name.map( msg =>
                      <div className="Registration-error">{ msg }</div>
                    )}
                  </Fragment>
                  }
                </div>
                <div className="Registration-field">
                  <Field name="last_name" placeholder="Last name" className="TextField" />
                  {touched.last_name && errors.last_name && <div className="Registration-error">{errors.last_name}</div>}
                  { (props.backendErrors && props.backendErrors.last_name) &&
                  <Fragment>
                    { props.backendErrors.last_name.map( msg =>
                      <div className="Registration-error">{ msg }</div>
                    )}
                  </Fragment>
                  }
                </div>
              </div>
              <div className="Registration-twoFields">
                <div className="Registration-field">
                  <Field name="mobile_phone" placeholder="Phone" className="TextField" />
                  {touched.mobile_phone && errors.mobile_phone && <div className="Registration-error">{errors.mobile_phone}</div>}
                  { (props.backendErrors && props.backendErrors.mobile_phone) &&
                  <Fragment>
                    { props.backendErrors.mobile_phone.map( msg =>
                      <div className="Registration-error">{ msg }</div>
                    )}
                  </Fragment>
                  }
                </div>
                <div className="Registration-field">
                  <InputMask
                    name="birth_date"
                    mask="99/99/9999"
                    onChange={handleChange}
                    value={values.birth_date}
                    className="TextField"
                    placeholder="MM/DD/YYYY"
                  />
                  <span className="birthday-field-title">BIRTH DATE</span>
                  {touched.birth_date && errors.birth_date && <div className="Registration-error">{errors.birth_date}</div>}
                  { (props.backendErrors && props.backendErrors.birth_date) &&
                  <Fragment>
                    { props.backendErrors.birth_date.map( msg =>
                      <div className="Registration-error">{ msg }</div>
                    )}
                  </Fragment>
                  }
                </div>
              </div>

              <div className="Registration-field">
                <Field name="address_line1" placeholder="Address 1" className="TextField" />
                {touched.address_line1 && errors.address_line1 && <div className="Registration-error">{errors.address_line1}</div>}
                { (props.backendErrors && props.backendErrors.address_line1) &&
                <Fragment>
                  { props.backendErrors.address_line1.map( msg =>
                    <div className="Registration-error">{ msg }</div>
                  )}
                </Fragment>
                }
              </div>
              <div className="Registration-field">
                <Field name="address_line2" placeholder="Address 2" className="TextField" />
                {touched.address_line2 && errors.address_line2 && <div className="Registration-error">{errors.address_line2}</div>}
                { (props.backendErrors && props.backendErrors.address_line2) &&
                <Fragment>
                  { props.backendErrors.address_line2.map( msg =>
                    <div className="Registration-error">{ msg }</div>
                  )}
                </Fragment>
                }
              </div>
              <div className="Registration-twoFields">
                <div className="Registration-field">
                  <Field name="city" placeholder="City" className="TextField" />
                  {touched.city && errors.city && <div className="Registration-error">{errors.city}</div>}
                  { (props.backendErrors && props.backendErrors.city) &&
                  <Fragment>
                    { props.backendErrors.city.map( msg =>
                      <div className="Registration-error">{ msg }</div>
                    )}
                  </Fragment>
                  }
                </div>
                <div className="Registration-field">
                  <Field name="postal_code" placeholder="Postal code" className="TextField" />
                  {touched.postal_code && errors.postal_code && <div className="Registration-error">{errors.postal_code}</div>}
                  { (props.backendErrors && props.backendErrors.postal_code) &&
                  <Fragment>
                    { props.backendErrors.postal_code.map( msg =>
                      <div className="Registration-error">{ msg }</div>
                    )}
                  </Fragment>
                  }
                </div>
              </div>
              <div className="Registration-twoFields">
                <div className="Registration-field">
                  <ReactSelectComponent
                    options={props.countries}
                    placeholder="COUNTRY"
                    optionLabel="countryName"
                    optionValue="countryShortCode"
                    setValue={val => setFieldValue('country', val.countryShortCode)}
                    selectedValue={props.countries.find(c => c.countryShortCode === values.country)}
                  />
                  {touched.country && errors.country && <div className="Registration-error">{errors.country}</div>}
                  { (props.backendErrors && props.backendErrors.country) &&
                  <Fragment>
                    { props.backendErrors.country.map(msg =>
                      <div className="Registration-error">{ msg }</div>
                    )}
                  </Fragment>
                  }
                </div>
                <div className="Registration-field">
                  <ReactSelectComponent
                    disabled={!values.country}
                    placeholder="STATE"
                    options={values.country ? props.countries.find(c => c.countryShortCode === values.country).regions : []}
                    optionLabel="name"
                    optionValue="shortCode"
                    setValue={val => setFieldValue('state', val.shortCode)}
                    selectedValue={values.country ? props.countries.find(c => c.countryShortCode === values.country).regions.find(r => r.shortCode === values.state) : null}
                  />
                  {touched.state && errors.state && <div className="Registration-error">{errors.state}</div>}
                  { (props.backendErrors && props.backendErrors.state) &&
                  <Fragment>
                    { props.backendErrors.state.map(msg =>
                      <div className="Registration-error">{ msg }</div>
                    )}
                  </Fragment>
                  }
                </div>
              </div>

              <br />

              <div className="Registration-form-subheader">HOW DID YOU HEAR ABOUT SKY TING?</div>
              <ReactSelectComponent
                placeholder="SELECT"
                options={registerReasons}
                optionLabel="value"
                optionValue="id"
                setValue={val => setFieldValue('referred_by', val.id)}
                selectedValue={values.referred_by ? registerReasons.find(v => v.id === values.referred_by) : ''}
              />
              {touched.referred_by && errors.referred_by && <div className="Registration-error">{errors.referred_by}</div>}
              { (props.backendErrors && props.backendErrors.referred_by ) &&
              <Fragment>
                { props.backendErrors.referred_by.map( msg =>
                  <div className="Registration-error">{ msg }</div>
                )}
              </Fragment>
              }

              { values.referred_by === 10 &&
              <Fragment>
                <br />
                <div className="Registration-field">
                  <Field name="referred_by_others" placeholder="Please explain ..." className="TextField" />
                  {touched.referred_by_others && errors.referred_by_others && <div className="Registration-error">{errors.referred_by_others}</div>}
                  { (props.backendErrors && props.backendErrors.referred_by_others) &&
                  <Fragment>
                    { props.backendErrors.referred_by_others.map( msg =>
                      <div className="Registration-error">{ msg }</div>
                    )}
                  </Fragment>
                  }
                </div>
              </Fragment>
              }

              <br />
              <br />
              <Checkbox
                label="Sign up for weekly newsletter to hear about deals, classes, workshops, courses & more!"
                checked={values.join_newsletter}
                onClick={() => setFieldValue('join_newsletter', !values.join_newsletter)}
              />
              <br />
              <br />

              <div className="Registration-action-popup">
                { props.loading ? (
                  <div className="inline-loading mt-4">
                    <img src={Loading} height="21" width="21" alt="Loading" />
                  </div>
                ) : (
                  <button type="submit" value="Continue" className="Button">Continue</button>
                )}
              </div>
            </form>
          </div>
        </div>
      )}
    />
  </div>
)

export default RegisterFormStep1New;