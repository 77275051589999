import superagent from "superagent";
export const FETCH_LIVE_SCHEDULE_PAGE = "FETCH_LIVE_SCHEDULE_PAGE";

// if was updated more than 10 minutes ago
const shouldUpdateLiveSchedulePage = updated_at => {
  if (!updated_at) return true;
  const seconds = (new Date().getTime() - updated_at.getTime()) / 1000;
  return seconds > 600;
};

export const fetchLiveSchedulePage = () => (dispatch, getState) => {
  const state = getState();

  return new Promise(async (resolve, reject) => {
    if (
      state.liveSchedulePage.content ||
      shouldUpdateLiveSchedulePage(state.liveSchedulePage.updated)
    ) {
      await superagent
        .get("/live_schedule_page")
        .set("Accept", "application/json")
        .send()
        .then(res => {
          dispatch({
            type: FETCH_LIVE_SCHEDULE_PAGE,
            content: res.body.content,
            subscribed: res.body.subscribed
          });
          resolve(true);
        })
        .catch(error => {
          reject(error.response.body);
        });
    } else {
      resolve(true);
    }
  });
};
