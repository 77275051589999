import React, { Component } from "react";
import classNames from "classnames";
import onClickOutside from "react-onclickoutside";
import arrow from "./../../../../assets/images/icons/arrow-bottom.svg";
import arrowWhite from "./../../../../assets/images/icons/arrow-bottom_white.svg";

class ServicesDropdown extends Component {
  state = {
    showDropdown: false
  };

  optionLabel = (price) => {
    return price.name;
  };

  handleClickOutside = () => {
    this.setState({ showDropdown: false });
  };

  selectedOption = () => {
    const { services, selectedPrice, selectedCategory, priceCategory } = this.props;

    if (priceCategory.type !== selectedCategory) return null;
    if (!selectedPrice) return null;

    return services.find(s => s.id === selectedPrice.id);
  }

  render() {
    const { services, selectPrice, white } = this.props;
    const { showDropdown } = this.state;

    const DropdownClass = classNames({
      "Dropdown-select-container": true,
      "is-open": showDropdown
    });

    const option = this.selectedOption()

    return (
      <div className="Dropdown-select">
        <div
          className={DropdownClass}
          onClick={() => this.setState({ showDropdown: !showDropdown })}
        >
          <span
            className="Dropdown-select-text"
            dangerouslySetInnerHTML={{ __html: option ? this.optionLabel(option) : "Select type" }}
          />
          <div className="Select-arrow-container">
            <img src={white ? arrowWhite : arrow} className="arrow-down" alt="&#9662;" />
          </div>
        </div>

        <div
          className={`Dropdown-list ${showDropdown ? "d-block" : "d-none"}`}
        >
          {services.map(option => (
            <div
              key={option.id}
              className="Dropdown-option"
              onClick={() => {
                selectPrice(option);
                this.setState({ showDropdown: false });
              }}
            >
              { option.info_banner &&
                <div className="info-banner">
                  {option.info_banner}
                </div>
              }
              <div dangerouslySetInnerHTML={{ __html: this.optionLabel(option) }} />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default onClickOutside(ServicesDropdown);
