import React, { Component, Fragment } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchUserSubscriptions } from "../actions/user_subscriptions";
import LadyBlue from "./../../../assets/images/icons/lady-blue-icon.svg";
import TvBlue from "./../../../assets/images/icons/tv-blue-icon.svg";
import visa from "../../../assets/images/icons/visa.svg";
import {history} from "../store/configureStore";

class AccountSubscriptionCard extends Component {
  gotopricing = () => {
    history.push('/pricing')
  }

  render() {
    const { subscription, openStripePortal, border } = this.props;

    const showCancelBtn =
      !subscription.is_membership &&
      (subscription.status &&
        ["active", "trialing"].includes(subscription.status)) &&
      !subscription.cancel_at_period_end;
    const iconSrc = subscription.is_membership ? LadyBlue : TvBlue;

    const status =
      subscription.status || (subscription.current ? "active" : "inactive");
    const isActive = ["trialing", "active"].includes(status);

    const statusClass = (isActive && subscription.cancel_at_period_end) ? "status-pending-cancel" : (isActive ? "status-active" : "status-inactive")

    return (
      <Fragment>
        <div
          className={`account-card row pt-3 pt-md-0 ${
            isActive ? "" : "inactive"
          }`}
        >
          <div className="col-2 col-md-1 d-flex align-items-center mb-1 mb-md-0">
            <img src={iconSrc} width={50} height={50} alt="SKY TING" />
          </div>
          <div className="col-10 col-md-2 d-block d-md-flex justify-content-center mb-1 mb-md-0 flex-column">
            <b>{subscription.name}</b>
            <div className="pt-2 mb-1 bordered d-md-none" />
          </div>
          <div className="col-2 d-flex d-md-none" />
          <div className="col-10 col-md-2 d-flex align-items-center mb-2 mb-md-0">
            {subscription.start ? (
              <div className="d-flex flex-column">
                <div>
                  <b>STARTED:</b>{" "}
                  {moment.unix(subscription.start).format("MM/DD/YY")}
                </div>
                { (subscription.status === 'trialing' && subscription.trial_end) &&
                  <div className="small-text">
                    Trial active till {moment.unix(subscription.trial_end).format("MM/DD/YY")}
                  </div>
                }
              </div>
            ) : (
              <Fragment>
                {subscription.active_date &&
                  <div>
                    <b>STARTED:</b>{" "}
                    {moment.utc(subscription.active_date).format("MM/DD/YY")}
                  </div>
                }
              </Fragment>
            )}
          </div>
          <div className="col-2 d-flex d-md-none" />
          <div className="col-10 col-md-2 d-flex align-items-center mb-2 mb-md-0">
            {subscription.expiration_date ? (
              <div>
                <b>EXPIRES:</b>{" "}
                {moment.utc(subscription.expiration_date).format("MM/DD/YY")}
              </div>
            ) : (
              <div>
                { subscription.status === 'trialing' && !subscription.cancel_at ? (
                  <span>You will be charged on {moment.unix(subscription.trial_end).format("MM/DD/YY")}</span>
                ) : (
                  <span></span>
                )}
              </div>
            )}
          </div>
          <div className="col-2 d-flex d-md-none" />
          <div className="col-10 col-md-2 d-flex justify-content-start justify-content-md-center align-items-center mb-2 mb-md-0">
            {subscription.card &&
              <div>
                {subscription.card.brand &&
                  subscription.card.brand.toLowerCase() === "visa" && (
                    <img
                      src={visa}
                      width={50}
                      className="credit-card-brand mr-3"
                      alt="Visa"
                    />
                  )}
                {subscription.card.card_last_four}
              </div>
            }
          </div>
          <div className="col-2 d-flex d-md-none" />
          <div className="col-10 col-md-2 d-flex flex-column justify-content-center align-items-start align-items-md-center mb-3 mb-md-0">
            <div>
              <div className={statusClass} />
              &nbsp;
              <span>
                { isActive && subscription.cancel_at ? `ACTIVE TILL ${moment.unix(subscription.cancel_at).format("MM/DD/YY")}` : status}
              </span>
            </div>
          </div>
          {showCancelBtn ? (
            <div
              className="cancel-lesson-btn col-12 col-md-1 d-flex align-items-center justify-content-center"
              onClick={() => openStripePortal()}
            >
              Edit Subscription
            </div>
          ) : (
            <div className="cancel-lesson-btn col-12 col-md-1 d-flex align-items-center justify-content-center"
            onClick={() => this.gotopricing()}
            >
              Renew Subscription
            </div>
          )}
        </div>
        {border && <div className="bordered-bottom d-none d-md-block" />}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchUserSubscriptions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountSubscriptionCard);
