import {
  FETCH_TRAININGS,
  FETCH_TRAININGS_ERROR,
  FETCH_TRAININGS_SUCCESS
} from '../actions/trainings';

const INITIAL_STATE = {
  trainings: [],
  error: null,
  isFetching: false
};

const trainings = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_TRAININGS:
      return {
        ...state,
        isFetching: true
      };
    case FETCH_TRAININGS_ERROR:
      return {
        ...state,
        error: action.error,
        isFetching: false
      };
    case FETCH_TRAININGS_SUCCESS:
      return {
        ...state,
        trainings: action.trainings,
        error: null,
        isFetching: false
      };
    default:
      return state;
  }
};

export const isFetching = state => state.trainings.isFetching;

export default trainings;
