import { SELECT_PROGRAM, DESELECT_PROGRAM } from '../actions/active_program';

const activeProgram = (state = null, action) => {
  switch (action.type) {
    case SELECT_PROGRAM:
      return action.program;
    case DESELECT_PROGRAM:
      return null;
    default:
      return state;
  }
}

export default activeProgram;
