import {
  FETCH_SAUNAS,
  FETCH_SAUNAS_ERROR,
  FETCH_SAUNAS_SUCCESS
} from '../actions/saunas';

const INITIAL_STATE = {
  saunas: [],
  error: null,
  isFetching: false,
  hours_available: [],
  session_types: [],
  services: [],
}

const saunas = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_SAUNAS:
      return {
        ...state,
        isFetching: true
      }
    case FETCH_SAUNAS_ERROR:
      return {
        ...state,
        error: action.error,
        isFetching: false
      }
    case FETCH_SAUNAS_SUCCESS:
      return {
        ...state,
        saunas: action.saunas,
        session_types: action.session_types,
        hours_available: action.hours_available,
        services: action.services,
        error: null,
        isFetching: false
      }
    default:
      return state
  }
}

export default saunas
