import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";

import { fetchUserSchedules } from "../actions/user_schedules";
import { fetchUserPurchases } from "../actions/user_purchases";
import { fetchUserSubscriptions } from "../actions/user_subscriptions";
import { fetchUserPastClasses } from "../actions/user_past_classes";
import {
  submitBillingForm,
  openBillingForm,
  closeBillingForm
} from "../actions/billing";
import { deleteCreditCard } from "../actions/credit_card"
// import PurchaseCard from "../components/purchase_card";
import AccountEditPopupNew from "../components/account_edit_popup_new";
import Popup from "../components/popup.jsx";
import Avatars from "../data/Avatars.js";
import AccountLessonCard from "../components/account_lesson_card";
import RenderError from "../components/render_error.jsx";
import RenderLoading from "../components/render_loading.jsx";
import CancelLessonPopup from "../components/cancel_lesson_popup.jsx";
import AccountSubscriptionCard from "../components/account_subscription_card";
import scrollToTop from "../helpers/scrollToTop";
import edit from "./../../../assets/images/icons/edit_icon_gray.svg";
import editWhite from "./../../../assets/images/icons/edit_white.svg";
import MetaTagsComponent from "../components/meta_tags_component";
import { updateCurrentUser } from "../actions/user_update";
import AccountHistoryFilter from "../components/account_history_filter";
import UploadPhoto from "../components/tv/upload_photo";
import AccountLessonPopup from "../components/account_lesson_popup";
import GiftCardInner from "../../../assets/images/gift_card_inner.svg";
import ReactSelectComponent from "../components/react_select_component";
import StripePortalApi from "../api/stripe_portal";
import PurchaseCard from "../components/Purchase_card_2";
import PastClassesCard from "../components/Past_classes_card";
import { history } from "../store/configureStore";
import { addUrl } from "../actions/redirect_url";

const linkOptions = [
  { label: 'Shop', href: '/shop', id: 'shop' },
  { label: 'TV', href: '/tv', id: 'tv' }
]

class Account extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      updating: false,
      userErrors: null,
      scheduleHistoryFilter: null,
      purchasesHistoryFilter: null,
      showAvatarEdit: false,
      deleteCardError: null,
      showLesson: null,
      showMore: false,
      showMorePurchases: false,
      showMorePastClasses: false,
      step: 'account'
    };
  }

  componentDidMount() {
    scrollToTop();
    this.props.fetchUserPurchases("year");
    this.props.fetchUserSubscriptions();
    this.props.fetchUserPastClasses();
    this.props.fetchUserSchedules();
  }

  closePopup = () => {
    this.setState({
      isOpen: false,
      updating: false,
      userErrors: null
    });
  };

  onCardSubmit = data => {
    this.props.submitBillingForm(data);
  };

  deleteCard = () => {
    this.setState({ deleteCardError: null });

    this.props.deleteCreditCard()
      .catch((err) => {
        this.setState({
          deleteCardError: err
        })
      })
  }

  openStripePortal = () => {
    StripePortalApi.createSession()
      .then(res => {
        window.location.href = res.body.url;
      });
  }

  renderPurchases() {
    const { user, userPurchases, balance } = this.props;
    const noPurchases = userPurchases && userPurchases.purchases.length === 0;
    const {showMorePurchases,step} = this.state;

    return (
      <div
        className={`account-section ${noPurchases ? "" : "no-borders mb-5"}`}
      >
        <div className="account-header mb-4">{step === 'purchasesHistories' ? "MY PURCHASES": "STUDIO PACKAGES"}</div>
        {/* <div className="account-history">
          {this.renderHistoryFilter("purchasesHistoryFilter")}
        </div> */}
        {userPurchases && userPurchases.isFetching ? (
          <Fragment>
            <RenderLoading />
            <div className="pb-5" />
          </Fragment>
        ) : (
          <Fragment>
            {noPurchases ? (
              <div className="Empty-result">
                <div className="empty-text">YOU DON'T HAVE ANY PURCHASES.</div>
                <Link to="/shop" title="Visit shop" className="Button">
                  BROWSE THE SHOP
                </Link>
                <div className="pb-2" />
              </div>
            ) : (
              <Fragment>
                {showMorePurchases ? (
                  userPurchases.purchases.map((purchase, index) => (
                    <PurchaseCard
                      key={`${index}-${purchase.id}`}
                      index={index}
                      purchase={purchase}
                      user={user}
                      balance={balance}
                      userPurchases={userPurchases}
                      border={true}
                      active={index===0 ? true : false}
                    />
                ))):(
                  <PurchaseCard
                      key={`${0}-${userPurchases.purchases[0].id}`}
                      index={0}
                      purchase={userPurchases.purchases[0]}
                      user={user}
                      balance={balance}
                      userPurchases={userPurchases}
                      active={true}
                    />
                )}
                <button className="Button" onClick={() => this.setState({showMorePurchases: !showMorePurchases})}>{showMorePurchases ? "Show Less": "Show More"}</button>
                <div className="bordered-bottom d-none d-md-block" />
              </Fragment>
            )}
          </Fragment>
        )}
      </div>
    );
  }

  // renderSchedule= () => {
  //   const { user, userSchedule } = this.props;
  //   const noLessons = userSchedule && userSchedule.bookedLessons.length === 0;

  //   return (
  //     <div className="blue-background">
  //       <div
  //         className={`account-section ${noLessons ? "" : "no-borders mb-5"}`}
  //       >
  //         <div className="account-header mb-4 mb-md-5 mb-lg-5">My Schedule</div>
  //         <div className="account-history">
  //           {this.renderHistoryFilter("scheduleHistoryFilter")}
  //         </div>

  //         {userSchedule.isFetching ? (
  //           <Fragment>
  //             <RenderLoading />
  //             <div className="pb-5" />
  //           </Fragment>
  //         ) : (
  //           <Fragment>
  //             {noLessons ? (
  //               <div className="Empty-result">
  //                 <div className="empty-text">
  //                   YOU DON'T HAVE ANY CLASSES SCHEDULED.
  //                 </div>
  //                 <Link to="/schedule" title="Visit schedule" className="Button">
  //                   Schedule a class
  //                 </Link>
  //                 <div className="pb-2" />
  //               </div>
  //             ) : (
  //               <Fragment>
  //                 <div className="Account-schedule">
  //                   {userSchedule.bookedLessons.map(lesson => (
  //                     <AccountLessonCard
  //                       key={lesson.id}
  //                       lesson={lesson}
  //                       user={user}
  //                       selectLesson={(l) => this.setState({ showLesson: l })}
  //                     />
  //                   ))}
  //                 </div>
  //               </Fragment>
  //             )}
  //           </Fragment>
  //         )}
  //       </div>
  //     </div>
  //   );
  // }


  renderCurrentStep = () => {
    switch(this.state.step) {
      case 'pastClasses':
        return this.renderPastClasses();
      case 'schedules':
        return this.renderSchedule();
      case 'subscriptions':
        return this.renderSubscriptions();
      case 'purchasesHistories':
        return this.renderPurchases();
      default:
        return (
          <>
            {this.renderSubscriptions()}
            {this.renderPurchases()}
          </>
        )
    }
  }

  header = () => {
    switch(this.state.step) {
      case 'pastClasses':
        return <h3 className="Page-header">PAST CLASSES &#128368;</h3>
      case 'schedules':
        return <h3 className="Page-header">UPCOMING SCHEDULE &#128198;</h3>
      case 'subscriptions':
        return <h3 className="Page-header">SUBSCRIPTIONS</h3>
      case 'purchasesHistories':
        return <h3 className="Page-header">PURCHASE HISTORIES</h3>
      default:
        return <h3 className="Page-header">WELCOME BACK! &#128075;</h3>
    }
  }

  changeStep = (step) => {
    this.setState({ step })
  }

  renderSchedule= () => {
    const { user, userSchedule } = this.props;
    const noLessons = userSchedule && Object.keys(userSchedule.bookedLessons).length === 0;
    
    return (
      <>
        <div
          className={`account-section ${noLessons ? "" : "no-borders mb-5"}`}
        >
          {userSchedule.isFetching ? (
              <Fragment>
                <RenderLoading />
                <div className="pb-5" />
              </Fragment>
            ) : (
              <>
              {noLessons? (
                <div className="Empty-result">
                  <div className="empty-text">
                    YOU DON'T HAVE ANY CLASSES SCHEDULED.
                  </div>
                </div>
              ):(
              <>
                {Object.keys(userSchedule.bookedLessons).map((lesson,index) => (
                  <Fragment key={index}>
                    <div className="account-header mb-4 mb-md-5 mb-lg-5 mt-5">Week of: {moment().week(parseInt(lesson)+1).weekday(1).format('MMM DD, YYYY')} - {moment().week(parseInt(lesson)+1).weekday(7).format('MMM DD, YYYY')}</div>
                    {userSchedule.bookedLessons[lesson].length === 0 ? (
                      <div className="Empty-result">
                        <div className="empty-text">
                          YOU DON'T HAVE SCHEDULED CLASSES.
                        </div>
                      </div>
                    ) : (
                      <div className="Account-schedule">
                          {userSchedule.bookedLessons[lesson].map(lesson=>
                            <AccountLessonCard
                            key={lesson.id}
                            lesson={lesson}
                            user={user}
                            selectLesson={(l) => this.setState({ showLesson: l })}
                          />)}
                      </div>
                    )}
                  </Fragment>
                ))}
              </>
              )}
            </>
          )}
        </div>
      </>
    );
  }

  onHistoryFilterChange = (name, value) => {
    this.setState({ [name]: value });

    if (name === "scheduleHistoryFilter") {
      this.props.fetchUserSchedule(value);
    } else if (name === "purchasesHistoryFilter") {
      this.props.fetchUserPurchases(value);
    }
  };

  renderHistoryFilter = name => {
    const value = this.state[name];

    return (
      <AccountHistoryFilter
        selected={value}
        selectOption={val => this.onHistoryFilterChange(name, val)}
      />
    );
  };

  getAvatar = () => {
    const {
      user: { photo_url }
    } = this.props;

    const avatarImg = Avatars.find(el => el.description === photo_url);
    if (avatarImg) return avatarImg.image;
    return photo_url;
  };

  renderSubscriptions = () => {
    const {showMore} = this.state;
    let {
      userSubscriptions: { subscriptions, isFetching },
      user
    } = this.props;

    let active_subscription = [subscriptions.find(el => el.status === 'active')];
    let len = !!active_subscription && active_subscription.length === 0;
    let userSub = showMore ? subscriptions : len ? active_subscription : [subscriptions[0]];
    let showBorder = !!userSub ? (userSub.length > 1 ? true : false) : false
    const noMemberships =
      !subscriptions || (!isFetching && subscriptions.length === 0);

    return (
      <div
        className={`account-section ${noMemberships ? "" : "no-borders mb-5"}`}
      >
        <div className="account-header mb-4 mb-md-5 mb-lg-5">
          Sky ting Tv Subscriptions
        </div>

        {isFetching ? (
          <Fragment>
            <RenderLoading />
            <div className="pb-5" />
          </Fragment>
        ) : (
          <Fragment>
            {noMemberships ? (
              <div className="Empty-result">
                <div className="empty-text">
                  YOU DON'T HAVE ANY MEMBERSHIPS.
                </div>
                <div title="Visit TV section" className="Button"
                  onClick={() => {
                    this.props.addUrl(window.location.pathname, 'AccountPage');
                    history.push('/tv-signup/get-started')
                  }}
                >
                  BECOME A MEMBER
                </div>
                <div className="pb-2" />
              </div>
            ) : (
              <div className="Account-schedule">
                {userSub.map((subscription, index) => ( 
                  <AccountSubscriptionCard
                    key={index}
                    subscription={subscription}
                    user={user}
                    index={index}
                    openStripePortal={() => this.openStripePortal()}
                    border={showBorder}
                  />
                ))}
                <button className="Button" onClick={() => this.setState({showMore: !showMore})}>{showMore ? "Show Less": "Show More"}</button>
                <div className="bordered-bottom d-none d-md-block" />
              </div>
            )}
          </Fragment>
        )}
      </div>
    );
  };

  renderGiftCardCredits = () => {
    const { innerBalance } = this.props;

    if (innerBalance === 0) return <div />;

    return (
      <div className="account-gift-card-balance mt-5 mb-5 flex-column flex-sm-row">
        <div className="redeem-img-wrapper">
          <img src={GiftCardInner} height={100} alt="Gift card" />
          <div className="redeem-img-inner small-text">${innerBalance}</div>
        </div>
        <div className="gift-card-balance-text text-center w-100">
          <div className="mb-3">YOU HAVE ${innerBalance} OF GIFT CARD CREDIT ON YOUR ACCOUNT!</div>
          <div className="select-gift-card color-black yellow-borders">
            <ReactSelectComponent
              placeholder="REDEEM NOW..."
              options={linkOptions}
              optionLabel="label"
              optionValue="id"
              setValue={val => this.props.history.push(val.href)}
              hideSearch
            />
          </div>
        </div>
      </div>
    );
  }

  renderButtons = () => {
    switch(this.state.step) {
      case 'pastClasses':
        return (
          <>
            <div className="account-btns">
              <button className="Button btn1" onClick={() => history.push({ pathname: "/schedule" })}>Browse Available Classes</button>
              <div className="row">
                <div className="col-sm">
                  <button className="Button" onClick={() => this.changeStep('subscriptions')}>Subscription</button>
                </div>
                <div className="col-sm">
                  <Link to="/shop" title="Visit shop" className="Button">
                    BROWSE THE SHOP
                  </Link>
                </div>
                <div className="col-sm">
                  <button className="Button" onClick={() => this.changeStep('purchasesHistories')}>Purchase History</button>
                </div>
              </div>
            </div>
          </>
        )
      case 'schedules':
        return (
          <>
            <div className="account-btns">
              <Link to="/schedule" title="Visit schedule" className="Button btn1">
                Browser Available Class
              </Link>
              <div className="row">
                <div className="col-sm">
                  <button className="Button" onClick={() => this.changeStep('pastClasses')}>Past Classes</button>
                </div>
                <div className="col-sm">
                  <button className="Button" onClick={() => this.changeStep('subscriptions')}>Subscription</button>
                </div>
                <div className="col-sm">
                  <Link to="/shop" title="Visit shop" className="Button">
                    BROWSE THE SHOP
                  </Link>
                </div>
                <div className="col-sm">
                  <button className="Button" onClick={() => this.changeStep('purchasesHistories')}>Purchase History</button>
                </div>
              </div>
            </div>
          </>
        )
      case 'subscriptions':
        return (
          <>
            <div className="account-btns">
              <Link to="/schedule" title="Visit schedule" className="Button btn1">
                Browser Available Class
              </Link>
              <div className="row">
                <div className="col-sm">
                  <button className="Button" onClick={() => this.changeStep('pastClasses')}>Past Classes</button>
                </div>
                <div className="col-sm">
                  <button className="Button" onClick={() => this.changeStep('schedules')}>Upcoming Schedule</button>
                </div>
                <div className="col-sm">
                  <Link to="/shop" title="Visit shop" className="Button">
                    BROWSE THE SHOP
                  </Link>
                </div>
                <div className="col-sm">
                  <button className="Button" onClick={() => this.changeStep('purchasesHistories')}>Purchase History</button>
                </div>
              </div>
            </div>
          </>
        )
      case 'purchasesHistories':
        return (
          <>
            <div className="account-btns">
              <Link to="/schedule" title="Visit schedule" className="Button btn1">
                Browser Available Class
              </Link>
              <div className="row">
                <div className="col-sm">
                  <button className="Button" onClick={() => this.changeStep('pastClasses')}>Past Classes</button>
                </div>
                <div className="col-sm">
                  <button className="Button" onClick={() => this.changeStep('schedules')}>Upcoming Schedule</button>
                </div>
                <div className="col-sm">
                  <Link to="/shop" title="Visit shop" className="Button">
                    BROWSE THE SHOP
                  </Link>
                </div>
                <div className="col-sm">
                  <button className="Button" onClick={() => this.changeStep('subscriptions')}>Subscription</button>
                </div>
              </div>
            </div>
          </>
        )
      default:
        return (
          <>
            <div className="account-btns">
            <div className="row">
              <div className="col-sm">
                <button className="Button" onClick={() => this.changeStep('schedules')}>Upcoming Schedule</button>
              </div>
              <div className="col-sm">
                <button className="Button" onClick={() => this.changeStep('pastClasses')}>Past Classes</button>
              </div>
              <div className="col-sm">
                <button className="Button" onClick={() => this.changeStep('purchasesHistories')}>Purchase History</button>
              </div>
            </div>
          </div>
          </>
        )
    }
  }

  renderPastClasses = () => {
    const { showMorePastClasses } = this.state;
    const { userPastClasses: { past_classes, isFetching, totalHoursVisited, totalVisitedClasses } } = this.props;
    const noClass = !past_classes || (!isFetching && past_classes.length === 0);
    
    const classes = !past_classes ? "zero classes" : showMorePastClasses ? past_classes : past_classes.slice(0,20)
    return (
      <div
        className={`account-section ${noClass ? "" : "no-borders mb-5"}`}
      >
        <div className="account-header mb-4 mb-md-5 mb-lg-5">
          All Classes
        </div>

        {isFetching ? (
          <Fragment>
            <RenderLoading />
            <div className="pb-5" />
          </Fragment>
        ) : (
          <Fragment>
            {noClass ? (
              <div className="Empty-result">
                <div className="empty-text">
                  YOU DIDN'T HAVE VISIT ANY CLASS.
                </div>
                <div className="pb-2" />
              </div>
            ) : (
              <div className="Account-schedule">
                {classes.map((pastClass, index) => ( 
                  <PastClassesCard
                    key={index}
                    pastClass={pastClass}
                    index={index}
                  />
                ))}
                <div className="row past_class">
                  <div className="col-sm">
                    TOTAL VISITS: {totalVisitedClasses}
                  </div>
                  <div className="col-sm">
                    TOTAL HOURS: {totalHoursVisited}                  
                  </div>
                </div>
                <button className="Button" onClick={() => this.setState({showMorePastClasses: !showMorePastClasses})}>{showMorePastClasses ? "Show Less": "Show More"}</button>
                <div className="bordered-bottom d-none d-md-block" />
              </div>
            )}
          </Fragment>
        )}
      </div>
    );
  }

  render() {
    const {
      user,
      error,
      openCancelPopup,
      userSubscriptions,
    } = this.props;
    const { showAvatarEdit, showLesson } = this.state;

    if (error) return <RenderError error={error} />;

    if (!user || !user.id) return <RenderLoading className="pt-100" />;

    return (
      <div className="Page Account">
        <MetaTagsComponent
          title="SKY TING | My account"
          desc="SKY TING account"
          url="account"
        />
        <div className="Page-content">
          {this.header()}
          <div className="account-user mb-3">
            <div className="mb-3 mb-sm-0">
              <div className="rounded-img account-credits">
                {/*<img src={CreditsImg} className="credits-background-img" />*/}
                {/*<div className="inner-container">*/}
                {/*  <LessonCreditIcon />*/}
                {/*  {!(Number(lessons_remaining) > 500) && <div>CREDITS</div>}*/}
                {/*</div>*/}
              </div>
            </div>
            <div className="mb-3 mb-sm-0">
              <div className="rounded-img account-avatar">
                <img src={this.getAvatar()} className="avatar-img" />
                <div
                  className="account-avatar-hover"
                  onClick={() => this.setState({ showAvatarEdit: true })}
                >
                  <img src={editWhite} width={50} />
                </div>
              </div>
            </div>
            <div className="mb-3 mb-sm-0">
              <div
                className="account-edit cursor-pointer"
                onClick={() => this.setState({ isOpen: true })}
              >
                <img src={edit} width={22} height={22} />
                <div>EDIT</div>
              </div>
            </div>
          </div>
          <div className="text-center text-uppercase pb-5 ">
            <div className="account-header mt-0">
              {user.first_name} {user.last_name}
            </div>
            <div className="account-email">{user.email}</div>
          </div>

          {this.renderGiftCardCredits()}
          {this.renderCurrentStep()}
          {this.renderButtons()}
          {/* {this.renderSubscriptions()}
          {this.renderPurchases()} */}
          

          {/*{user.card_last_four ? (*/}
            {/*<div className="account-section pb-5 pl-3 pr-3 pr-sm-0 pl-sm-0 no-borders">*/}
              {/*<div className="account-header mb-5">MY PAYMENTS</div>*/}
              {/*{ deleteCardError &&*/}
                {/*<div className="error text-center mb-4 color-red">*/}
                  {/*{deleteCardError.message}*/}
                {/*</div>*/}
              {/*}*/}

              {/*<div className="account-credit-card">*/}
                {/*{ user.credit_card_brand && user.credit_card_brand.toLowerCase() === "visa" &&*/}
                  {/*<img src={visa} width={50} className="credit-card-brand" />*/}
                {/*}*/}
                {/*<div>XXXX XXXX XXXX {user.card_last_four}</div>*/}
                {/*<span*/}
                  {/*className="delete-card"*/}
                  {/*onClick={() => this.deleteCard()}*/}
                {/*>*/}
                  {/*&#10005;*/}
                {/*</span>*/}
              {/*</div>*/}
            {/*</div>*/}
          {/*) : (*/}
            {/*<div className="account-section pb-5 pl-3 pr-3 pr-sm-0 pl-sm-0 no-borders">*/}
              {/*<div className="Empty-result">*/}
                {/*<div className="empty-text">*/}
                  {/*YOU DON'T HAVE ANY PAYMENT METHODS.*/}
                {/*</div>*/}
                {/*<div className="Button" onClick={() => openBillingForm()}>*/}
                  {/*ADD PAYMENT METHOD*/}
                {/*</div>*/}
              {/*</div>*/}
            {/*</div>*/}
          {/*)}*/}
          {this.state.isOpen && (
            <AccountEditPopupNew
              user={user}
              title="Edit Account"
              closePopup={this.closePopup}
              openStripePortal={() => this.openStripePortal()}
              userSubscriptions={userSubscriptions.subscriptions}
            />
          )}
          {showAvatarEdit && (
            <Popup onClose={() => this.setState({ showAvatarEdit: false })}>
              <div className="account-upload-photo">
                <UploadPhoto
                  hideSkip
                  onPhotoUpload={() => this.setState({ showAvatarEdit: false })}
                />
              </div>
            </Popup>
          )}
          { showLesson && (
            <AccountLessonPopup
              onClose={() => this.setState({ showLesson: null })}
              lesson={showLesson}
            />
          )}
        </div>
        { openCancelPopup &&
          <CancelLessonPopup />
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  error: state.error,
  user: state.user,
  userSchedule: state.userSchedules,
  userPurchases: state.userPurchases,
  balance: state.balance,
  userSubscriptions: state.userSubscriptions,
  userPastClasses: state.userPastClasses,
  lessons_remaining: state.balance.lessons_remaining,
  isLoadingCard: state.billing.loading,
  openCancelPopup: state.cancelLessonPopup.show,
  innerBalance: state.balance.inner_balance,

});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchUserPurchases,
      fetchUserSchedules,
      fetchUserSubscriptions,
      fetchUserPastClasses,
      updateCurrentUser,
      submitBillingForm,
      openBillingForm,
      closeBillingForm,
      deleteCreditCard,
      addUrl,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Account);
