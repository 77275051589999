import PropTypes from 'prop-types'

const teacherType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  mindbody_identifier: PropTypes.number.isRequired,
  bio: PropTypes.string,
  birthday: PropTypes.string,
  created_at: PropTypes.string.isRequired,
  training_info: PropTypes.string,
  email: PropTypes.string.isRequired,
  describe_your_class: PropTypes.string,
  music: PropTypes.string,
  first_name: PropTypes.string.isRequired,
  last_name: PropTypes.string.isRequired,
  hometown: PropTypes.string,
  image_url: PropTypes.string.isRequired,
  special_talent: PropTypes.string,
  mobile_phone: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  updated_at: PropTypes.string.isRequired,
  website: PropTypes.string,
}).isRequired

export default teacherType