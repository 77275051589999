import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { bindActionCreators } from "redux";
import { checkoutService } from "../actions/checkout";
import { dropCheckoutTotalCart } from "../actions/checkout_total";

import Popup from "./popup.jsx";
import Billing from "./billing";
import CreditCard from "./credit_card";
import PromoCodeInput from "./promo_code_input.jsx";
import { addBillingLastFour, dropBillingLastFour } from "../actions/billing";
import { closePurchasePopup } from "../actions/purchase_popup";

import Loading from "./../../../assets/images/icons/yinyang_icon.png";

class PurchasePopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardIsChosen: false,
      promoCode: null
    };
  }

  componentWillMount() {
    document.body.classList.add("overflow-hidden");
  }

  componentWillUnmount() {
    document.body.classList.remove("overflow-hidden");
  }

  buyService = () => {
    const { promoCode } = this.state;
    const { checkoutService, checkoutTotalCart, activeService } = this.props;

    checkoutService(
      activeService.id,
      checkoutTotalCart.grand_total,
      false,
      promoCode
    );
  };

  buyContract = amount => {
    const { promoCode } = this.state;

    const { checkoutService, activeService } = this.props;

    checkoutService(activeService.id, amount, true, promoCode);
  };

  deselectPurchasePopup = () => {
    this.props.closePurchasePopup();
    this.props.dropCheckoutTotalCart();
  };

  chooseCard = () => {
    const { cardIsChosen } = this.state;
    const {
      user: { card_last_four },
      addBillingLastFour,
      dropBillingLastFour,
      showBillingForm,
      closeBillingForm
    } = this.props;

    this.setState({ cardIsChosen: !cardIsChosen });

    if (cardIsChosen) {
      dropBillingLastFour();
    } else {
      addBillingLastFour(card_last_four);
    }

    if (showBillingForm) {
      closeBillingForm();
    }
  };

  serviceName = () => {
    const { activeService: { name_to_display, name }, checkoutTotalCart } = this.props;

    const checkoutTotalServiceName = _.result(
      checkoutTotalCart,
      "cart_items.cart_item.item.name",
      null
    );

    const serviceName = checkoutTotalServiceName
      ? `${checkoutTotalServiceName}: $${Number(
        checkoutTotalCart.grand_total
      ).toFixed(2)}`
      : name_to_display || name;

    return serviceName;
  }

  contentForService = () => {
    const {
      activeService,
      activeService: { name_to_display, name },
      checkoutTotalCart,
      checkoutTotalIsFetching,
      checkoutTotalError,
      waitingForCheckout,
      error
    } = this.props;

    return (
      <Fragment>
        <h2 className="Purchase-popup-title">COMPLETE YOUR PURCHASE</h2>
        {checkoutTotalError ? (
          <div className="Purchase-popup-error">
            {checkoutTotalError.message
              ? checkoutTotalError.message
              : "Something went wrong, try again please"}
          </div>
        ) : (
          <Fragment>
            {checkoutTotalIsFetching ? (
              <div className="inline-loading">
                <img src={Loading} height="21" width="21" alt="Loading" />
                <div>LOADING...</div>
              </div>
            ) : (
              <Fragment>
                {checkoutTotalCart && (
                  <div
                    className="Service-name"
                    dangerouslySetInnerHTML={{ __html: this.serviceName() }}
                  />
                )}
              </Fragment>
            )}

            <CreditCard
              chooseCard={this.chooseCard}
              isChecked={this.state.cardIsChosen}
            />
            <div className="text-center mt-4 mb-3">OR</div>
            <Billing
              toggleCard={this.chooseCard}
              cardIsChosen={this.state.cardIsChosen}
            />

            {activeService && (
              <PromoCodeInput
                setPromoCode={promoCode => this.setState({ promoCode })}
              />
            )}

            {waitingForCheckout ? (
              <div className="inline-loading">
                <img src={Loading} height="21" width="21" alt="Loading" />
                <div>PLEASE WAIT, CHECKOUT IN PROGRESS...</div>
              </div>
            ) : (
              <Fragment>
                {error && (
                  <div className="OrderPopup-error">
                    The payment was unsuccessful. Please try again.
                  </div>
                )}
                <button
                  className="SmallButton"
                  onClick={() => this.buyService()}
                  disabled={
                    !activeService ||
                    !this.state.cardIsChosen ||
                    checkoutTotalIsFetching
                  }
                >
                  Confirm
                </button>
              </Fragment>
            )}
          </Fragment>
        )}
      </Fragment>
    );
  };

  contentForContract = () => {
    const {
      activeService,
      activeService: { name_to_display, name, contract },
      checkoutTotalIsFetching,
      waitingForCheckout,
      error,
      pricingPage
    } = this.props;

    const { promoCode } = this.state;

    return (
      <Fragment>
        <h2 className="Purchase-popup-title">COMPLETE YOUR PURCHASE</h2>
        <div className="Service-name">
          <div
            dangerouslySetInnerHTML={{ __html: name }}
          />
          { (promoCode && pricingPage.coupon_code_message) &&
          <div className="coupon-code-message mt-3">
            {pricingPage.coupon_code_message}
          </div>
          }
        </div>

        {error ? (
          <div className="OrderPopup-error">
            {error.response.body.message
              ? error.response.body.message
              : "The payment was unsuccessful. Please try again."}
          </div>
        ) : (
          <Fragment>
            <CreditCard
              chooseCard={this.chooseCard}
              isChecked={this.state.cardIsChosen}
            />
            <div className="text-center mt-4 mb-3">OR</div>
            <Billing
              toggleCard={this.chooseCard}
              cardIsChosen={this.state.cardIsChosen}
            />

            {activeService && (
              <PromoCodeInput
                setPromoCode={promoCode => this.setState({ promoCode })}
                isContract
              />
            )}
            {waitingForCheckout ? (
              <div className="inline-loading">
                <img src={Loading} height="21" width="21" alt="Loading" />
                <div>PLEASE WAIT, CHECKOUT IN PROGRESS...</div>
              </div>
            ) : (
              <Fragment>
                <button
                  className="SmallButton"
                  onClick={() =>
                    this.buyContract(contract.first_payment_amount_subtotal)
                  }
                  disabled={
                    !activeService ||
                    !this.state.cardIsChosen ||
                    checkoutTotalIsFetching
                  }
                >
                  Confirm
                </button>
              </Fragment>
            )}
          </Fragment>
        )}
      </Fragment>
    );
  };

  render() {
    const { activeService, show } = this.props;

    const isContract = !!activeService.contract;

    return (
      <Popup id="purchase" isOpen={show} onClose={this.deselectPurchasePopup}>
        <div className="Purchase-popup">
          {isContract ? this.contentForContract() : this.contentForService()}
        </div>
      </Popup>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  activeService: state.activeService,
  checkoutTotalCart: state.checkoutTotal.cart,
  checkoutTotalIsFetching: state.checkoutTotal.isFetching,
  checkoutTotalError: state.checkoutTotal.error,
  waitingForCheckout: state.checkout.waitingForCheckout,
  error: state.checkout.error,
  show: state.purchasePopup.show
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      checkoutService,
      addBillingLastFour,
      dropBillingLastFour,
      closePurchasePopup,
      dropCheckoutTotalCart
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PurchasePopup);
