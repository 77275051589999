import React, { Fragment } from 'react'
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import InputMask from 'react-input-mask';
import moment from "moment";

import registerReasons from "../../data/register_reasons";
import ComponentWithLoading from "../component_with_loading";
import ButtonWithIcon from "../button_with_icon";
import arrowRightWhite from "../../../../assets/images/icons/arrow_right_white.svg";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const dateExp = /^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{2})*$/

const RegistrationSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  last_name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  mobile_phone: Yup.string()
    .min(9, 'Too Short!')
    .required('Required'),
  birth_date: Yup.string()
    .matches(dateExp, 'Birth date is not valid')
    .required('Required'),
  address_line1: Yup.string()
    .min(2, 'Too Short!')
    .required('Required'),
  city: Yup.string()
    .min(2, 'Too Short!')
    .required('Required'),
  state: Yup.string()
    .required('Required'),
  postal_code: Yup.string()
    .min(2, 'Too Short!')
    .required('Required'),
  country: Yup.string()
    .required('Required'),
  referred_by: Yup.mixed()
    .required('Required'),
  referred_by_others: Yup.string()
    .when('referred_by', (val) => ((val === 10 || val === '10') ? Yup.string().required('Required') : Yup.string())),
});

const findReferredBy = (val) => {
  if (!val) return '';
  const selected = registerReasons.find(r => r.value === val);
  if (selected) {
    return selected.id
  } else {
    return 10
  }
}

const findReferredByOthers = (val) => {
  if (!val) return '';

  const selected = registerReasons.find(r => r.value === val);
  if (selected) {
    return ''
  } else {
    return val
  }
}

const MboUserForm = (props) => (
  <div>
    <Formik
      initialValues={{
        first_name: (props.user && props.user.first_name) || '',
        last_name: (props.user && props.user.last_name) || '',
        mobile_phone: (props.user && props.user.mobile_phone) || '',
        birth_date: (props.user && props.user.birth_date) ? moment.utc(props.user.birth_date).format("MM/DD/YYYY") : '',
        address_line1: (props.user && props.user.address_line1) || '',
        address_line2: (props.user && props.user.address_line2) || '',
        city: (props.user && props.user.city) || '',
        state: (props.user && props.user.state) || '',
        country: (props.user && props.user.country) || 'US',
        postal_code: (props.user && props.user.postal_code) || '',
        referred_by: (props.user && props.user.referred_by) ? findReferredBy(props.user.referred_by) : '',
        referred_by_others: (props.user && findReferredByOthers(props.user.referred_by)) || '',
      }}

      validationSchema={RegistrationSchema}

      onSubmit={( values, { setSubmitting, setErrors }) => {
        props.updadteUser(values);
      }}

      render={({
                 values,
                 errors,
                 touched,
                 handleChange,
                 handleBlur,
                 handleSubmit,
                 isSubmitting,
                 setFieldValue,
                 dirty
               }) => (

        <form onSubmit={handleSubmit} className="mbo-user-form">
          <div className="two-inputs-row">
            <div className="form-input">
              <Field name="first_name" placeholder="First name" />
              {touched.first_name && errors.first_name && <div className="input-error-red">{errors.first_name}</div>}
              { (props.backendErrors && props.backendErrors.first_name) &&
              <Fragment>
                { props.backendErrors.first_name.map( msg =>
                  <div className="input-error-red">{ msg }</div>
                )}
              </Fragment>
              }
            </div>
            <div className="form-input">
              <Field name="last_name" placeholder="Last name" />
              {touched.last_name && errors.last_name && <div className="input-error-red">{errors.last_name}</div>}
              { (props.backendErrors && props.backendErrors.last_name) &&
              <Fragment>
                { props.backendErrors.last_name.map( msg =>
                  <div className="input-error-red">{ msg }</div>
                )}
              </Fragment>
              }
            </div>
          </div>
          <div className="two-inputs-row">
            <div className="form-input">
              <Field name="mobile_phone" placeholder="Phone" />
              {touched.mobile_phone && errors.mobile_phone && <div className="input-error-red">{errors.mobile_phone}</div>}
              { (props.backendErrors && props.backendErrors.mobile_phone) &&
              <Fragment>
                { props.backendErrors.mobile_phone.map( msg =>
                  <div className="input-error-red">{ msg }</div>
                )}
              </Fragment>
              }
            </div>
            <div className="form-input">
              <InputMask
                name="birth_date"
                mask="99/99/9999"
                onChange={handleChange}
                value={values.birth_date}
                placeholder="mm/dd/yy"
                // maskPlaceholder="dd/mm/yy"
              />
              {touched.birth_date && errors.birth_date && <div className="input-error-red">{errors.birth_date}</div>}
              { (props.backendErrors && props.backendErrors.birth_date) &&
              <Fragment>
                { props.backendErrors.birth_date.map( msg =>
                  <div className="input-error-red">{ msg }</div>
                )}
              </Fragment>
              }
            </div>
          </div>

          <div className="form-input">
            <Field name="address_line1" placeholder="Address 1" className="TextField" />
            {touched.address_line1 && errors.address_line1 && <div className="input-error-red">{errors.address_line1}</div>}
            { (props.backendErrors && props.backendErrors.address_line1) &&
            <Fragment>
              { props.backendErrors.address_line1.map( msg =>
                <div className="input-error-red">{ msg }</div>
              )}
            </Fragment>
            }
          </div>
          <div className="form-input">
            <Field name="address_line2" placeholder="Address 2" className="TextField" />
            {touched.address_line2 && errors.address_line2 && <div className="input-error-red">{errors.address_line2}</div>}
            { (props.backendErrors && props.backendErrors.address_line2) &&
            <Fragment>
              { props.backendErrors.address_line2.map( msg =>
                <div className="input-error-red">{ msg }</div>
              )}
            </Fragment>
            }
          </div>
          <div className="two-inputs-row">
            <div className="form-input">
              <Field name="city" placeholder="City" className="TextField" />
              {touched.city && errors.city && <div className="input-error-red">{errors.city}</div>}
              { (props.backendErrors && props.backendErrors.city) &&
              <Fragment>
                { props.backendErrors.city.map( msg =>
                  <div className="input-error-red">{ msg }</div>
                )}
              </Fragment>
              }
            </div>
            <div className="form-input">
              <Field name="postal_code" placeholder="Postal code" className="TextField" />
              {touched.postal_code && errors.postal_code && <div className="input-error-red">{errors.postal_code}</div>}
              { (props.backendErrors && props.backendErrors.postal_code) &&
              <Fragment>
                { props.backendErrors.postal_code.map( msg =>
                  <div className="input-error-red">{ msg }</div>
                )}
              </Fragment>
              }
            </div>
          </div>
          <div className="two-inputs-row">
            <div className="form-input">
              <select
                className=""
                onChange={event => setFieldValue('country', event.target.value)}
                value={values.country}
              >
                {/*<option value="" disabled>COUNTRY</option>*/}

                {props.countries.map(option => (
                  <option key={option.countryShortCode} value={option.countryShortCode}>
                    {option.countryName}
                  </option>
                ))}
              </select>
              {touched.country && errors.country && <div className="input-error-red">{errors.country}</div>}
              { (props.backendErrors && props.backendErrors.country) &&
              <Fragment>
                { props.backendErrors.country.map(msg =>
                  <div className="input-error-red">{ msg }</div>
                )}
              </Fragment>
              }
            </div>
            <div className="form-input">
              <select
                className=""
                onChange={event => setFieldValue('state', event.target.value)}
                value={values.state}
                disabled={!values.country}
              >
                <option value="" disabled>STATE</option>
                {
                  ( values.country && props.countries ?
                    props.countries.find(c => c.countryShortCode === values.country).regions :
                    []
                  ).map(option => (
                    <option key={option.shortCode} value={option.shortCode}>
                      {option.name}
                    </option>
                  ))}
                }
              </select>
              {touched.state && errors.state && <div className="input-error-red">{errors.state}</div>}
              { (props.backendErrors && props.backendErrors.state) &&
              <Fragment>
                { props.backendErrors.state.map(msg =>
                  <div className="input-error-red">{ msg }</div>
                )}
              </Fragment>
              }
            </div>
          </div>

          <br />

          { !props.user.referred_by &&
            <>
              <div className="Registration-form-subheader">HOW DID YOU HEAR ABOUT SKY TING?</div>
              <div className="form-input">
                <select
                  className=""
                  onChange={event => setFieldValue('referred_by', event.target.value)}
                  value={values.referred_by}
                >
                  <option value="" disabled>SELECT</option>
                  {registerReasons.map(option => (
                    <option key={option.id} value={option.id}>
                      {option.value}
                    </option>
                  ))}
                </select>
                {touched.referred_by && errors.referred_by && <div className="input-error-red">{errors.referred_by}</div>}
                { (props.backendErrors && props.backendErrors.referred_by ) &&
                <Fragment>
                  { props.backendErrors.referred_by.map( msg =>
                    <div className="input-error-red">{ msg }</div>
                  )}
                </Fragment>
                }
              </div>

              { [10, '10'].includes(values.referred_by) &&
              <Fragment>
                <div className="form-input">
                  <Field name="referred_by_others" placeholder="Please explain ..." />
                  {touched.referred_by_others && errors.referred_by_others && <div className="input-error-red">{errors.referred_by_others}</div>}
                  { (props.backendErrors && props.backendErrors.referred_by_others) &&
                  <Fragment>
                    { props.backendErrors.referred_by_others.map( msg =>
                      <div className="input-error-red">{ msg }</div>
                    )}
                  </Fragment>
                  }
                </div>
              </Fragment>
              }
            </>
          }

          <ComponentWithLoading isLoading={props.loading}>
            <ButtonWithIcon
              classNamesProp="high-75 confirm-button w-100"
              text="CONTINUE"
              iconSrc={!dirty ? null : arrowRightWhite}
              disabled={!dirty}
              type="submit"
            />
          </ComponentWithLoading>
        </form>

      )}
    />
  </div>
)

export default MboUserForm;