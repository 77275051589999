import React, {Component, Fragment} from "react";
import { connect } from "react-redux";
import { isAuthenticated } from "../reducers/user";
import { unauthorizedItemQuantity } from "../reducers/unauthorized_cart";
import cartWhite from './../../../assets/images/icons/cart_new.svg';

class BagItemsCounter extends Component {
  render() {
    const { itemQuantity, isAuthenticated, unauthorizedItemQuantity } = this.props;

    const bagIsEmpty = (isAuthenticated && itemQuantity === 0) || (!isAuthenticated && unauthorizedItemQuantity === 0)

    return (
      <Fragment>
        { bagIsEmpty ? (
          <div className="bag-wrapper ignore-react-onclickoutside empty">
            <img src={cartWhite} />
          </div>
        ) : (
          <div className="bag-wrapper ignore-react-onclickoutside">
            <img src={cartWhite} />
            <span className="bag-items-count">
              X{isAuthenticated ? itemQuantity : unauthorizedItemQuantity }
            </span>
          </div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  itemQuantity: state.cart.itemQuantity,
  unauthorizedItemQuantity: unauthorizedItemQuantity(state),
  isAuthenticated: isAuthenticated(state),
})

export default connect(mapStateToProps)(BagItemsCounter);

