import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import play from "../../../assets/images/icons/play_in_circle.svg";

class VideoThumb extends Component {
  render() {
    const { video, hideInfo, hideImage, hideTeacher, white } = this.props;

    return (
      <div className={`video-thumb ${white ? "with-white-title" : ""}`}>
        { !hideImage &&
          <Link to={`/tv/${video.slug}`} className="thumb-wrapper">
            <img src={video.thumbnail_url} className="thumb" />
            <img src={play} className="play-icon" />
            {video.duration_in_seconds && (
              <span className="duration">
                {video.duration_in_seconds}
              </span>
            )}
          </Link>
        }
        { !hideInfo &&
          <Fragment>
            <Link to={`/tv/${video.slug}`} className="title">
              {video.title}
            </Link>
            { !hideTeacher &&
              <div className="teacher">
                {video.teachers.map((t, index) => (
                  <span>{t.full_name}{video.teachers.length > index + 1 ? `, ` : ''}</span>
                ))}
              </div>
            }
            <div className="details">
              {video.duration} MINUTES / {video.level}
            </div>
          </Fragment>
        }
      </div>
    );
  }
}

export default VideoThumb;
