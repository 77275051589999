import {
  REGISTER_USER_SUCCESS, REGISTER_USER_ERROR, REGISTER_USER_LOADING,
} from '../actions/user_register'

const INITIAL_STATE = {
  error: null,
  loading: false,
};

const user = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REGISTER_USER_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case REGISTER_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    default:
      return state
  }
}

export default user
