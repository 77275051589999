import React, {Component, Fragment} from 'react';
import superagent from 'superagent';
import MetaTagsComponent from "../components/meta_tags_component";
import ToggleCard from '../components/toggle_card.jsx';
import RenderLoading from '../components/render_loading.jsx';
import RenderError from '../components/render_error.jsx';
import scrollToTop from "../helpers/scrollToTop";

export default class FaqPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    scrollToTop();
    return superagent
      .get('/faq')
      .set('Accept', 'application/json')
      .send().then(res =>
        this.setState({
          faq: res.body,
          isLoading: false,
        }))
      .catch((error) => {
        this.setState({ error: error });
      });
  }

  render() {
    const { faq, error, isLoading } = this.state;

    if (error) return <RenderError error={error} />;

    return (
      <Fragment>
        <MetaTagsComponent
          title="SKY TING | About | FAQ"
          desc="SKY TING FAQ"
          url="faq"
        />
        <div className="Page Faq">
          <div className="Page-content">
            <h2 className="Page-header">FAQ</h2>
            {isLoading ? (
              <RenderLoading />
            ) : (
              <div className="Faq-cards">
                { faq.map(q => (
                  <ToggleCard
                    key={q.id}
                    header={q.header}
                  >
                    <div dangerouslySetInnerHTML={{ __html: q.text }} />
                  </ToggleCard>
                ))}
              </div>
            )}
          </div>
        </div>
      </Fragment>
    )
  }
}
