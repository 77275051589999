import React, { Component } from "react";
import edit from "../../../../assets/images/icons/Edit.svg";

class EmailInput extends Component {
  onInputClick = () => {
    const { onInputClick, disabled } = this.props;

    if (onInputClick && disabled) {
      onInputClick()
    }
  }

  render() {
    const { handleChange, value, onSubmit, disabled } = this.props;

    return (
      <div
        onClick={() => this.onInputClick()}
        className={`input-with-icon ${disabled ? 'disabled' : ''}`}
      >
        <input
          value={value}
          onChange={handleChange}
          placeholder="EMAIL"
          name="email"
          onKeyPress={e => {
            if (e.key === "Enter") {
              onSubmit();
            }
          }}
          type="email"
          autoComplete="email"
          // onBlur={() => this.switchDisabled(true)}
          // onFocus={() => this.switchDisabled(false)}
        />
        <img src={edit} />
      </div>
    );
  }
}

export default EmailInput;
