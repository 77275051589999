import superagent from 'superagent';

const API_ENDPOINT = '/check_email';

const fetchUserMailChimp = () => {
  return superagent
    .get(API_ENDPOINT)
    .set('Accept', 'application/json');
};

export default { fetchUserMailChimp };