import Api from '../api/product';

export const FETCH_PRODUCT_ERROR = 'FETCH_PRODUCT_ERROR';
export const FETCH_PRODUCT_SUCCESS = 'FETCH_PRODUCT_SUCCESS';

export const fetchProducts = () => dispatch => {
  return new Promise(async(resolve, reject) => {
    await Api.fetchProducts({})
      .then(res => {
        dispatch({
          type: FETCH_PRODUCT_SUCCESS,
          products: res.body
        })
        resolve(res.body);
      })
      .catch(err => {
        dispatch({
          type: FETCH_PRODUCT_ERROR,
          error: err
        })
        reject(false);
      });
  })
};
