import TagManager from "react-gtm-module";

const addTagManagerEvent = (path, userStatus) => {
  TagManager.dataLayer({
    dataLayer: {
      event: "pageview",
      page: {
        path,
        "User-Status": userStatus
      }
    }
  });
};

export default addTagManagerEvent;
