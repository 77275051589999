import React, { Component } from 'react';
import { connect } from 'react-redux';
import superagent from "superagent"

import Popup from './popup.jsx';
import ShippingForm from './shipping_form';
import BagItemQuantitySelector from './bag_item_quantity_selector.jsx';
import { updateProductQuantity, addProductToCart } from "../actions/cart";
import { bindActionCreators } from "redux";
import { openShopPaymentPopup } from "../actions/shop_payment_popup";
import { checkoutTotal } from "../actions/checkout_total";
import { deselectShipping } from '../actions/shipping';
import { fetchShipmentProducts } from '../actions/shipment_products';
import { productPriceWithDiscount } from "../helpers/product_price_with_discount"

class ShippingPopup extends Component {
  constructor(props) {
    super(props)

    this.state = {
      policy: null
    }
  }

  componentWillMount() {
    document.body.classList.add('overflow-hidden');

    this.props.fetchShipmentProducts()

    return superagent
      .get('/policy?policy_type=shipping')
      .set('Accept', 'application/json')
      .send().then(res =>
        this.setState({
          policy: res.body,
        }));
  }

  componentWillUnmount() {
    document.body.classList.remove('overflow-hidden');
  }

  deselectShippingPopup = () => {
    this.props.deselectShipping()
  }

  deleteItemFromCart = (item) => {
    this.props.updateProductQuantity(item.id, 0)

    if (this.props.cartItems.length === 1) {
      this.props.deselectShipping()
    }
  }

  goToPrevStep = () => {
    this.props.deselectShipping()
  }

  goToBilling = () => {
    const {
      deselectShipping,
      openShopPaymentPopup,
    } = this.props;

    deselectShipping()
    openShopPaymentPopup()
  }

  colorAndSize = (item) => {
    const color = item.color && item.color.name !== 'None' ? item.color.name : null
    const size = item.size && item.size.name !== 'None' ? item.size.name : null

    if (color && size) return `(${color}, ${size})`;
    if (color && !size) return `(${color})`;
    if (!color && size) return `(${size})`;
    return '';
  }

  render() {
    const {
      cartItems,
      cartIsFetching,
      shippingIsOpen,
      activeProduct,
      userMembership
    } = this.props

    if ((cartItems.length === 0) && !cartIsFetching) return <div />

    const orderPrice = () => {
      const { cartItems } = this.props

      const allPrices = cartItems.map(item => productPriceWithDiscount(item, userMembership) * Number(item.quantity))

      return _.sum(allPrices)
    }

    return (
      <Popup
        isOpen={shippingIsOpen}
        id="shipping"
        onClose={() => this.deselectShippingPopup()}
      >
        <div className="Shipping-popup">
          { activeProduct &&
            <div className="arrow-left go-to-prev" onClick={this.goToPrevStep} />
          }
          <div className="ShippingPopup-title">ITEMS + SHIPPING</div>
          <div className="SubHeadline"> CART </div>
          <div className="Items-container">
            {cartItems.map(item => (
              <div className="Item-wrapper" key={item.id}>
                <div className="Item-image">
                  {item.image_url ? <img src={item.image_url} alt={item.name} /> : <div className="Item-image-empty" />}
                </div>
                <div className="Item-description">
                  <div>{item.name} {this.colorAndSize(item)} ${productPriceWithDiscount(item, userMembership)}</div>
                  { !(item.is_carbon || item.is_shipment) &&
                    <BagItemQuantitySelector item={item} />
                  }
                </div>
                <div className="Remove-button" onClick={() => this.deleteItemFromCart(item)}>&#10005;</div>
              </div>
            ))}

            <div className="Subtotal">
              SUBTOTAL = {orderPrice()}
            </div>
          </div>
          <ShippingForm
            cartItems={cartItems}
            onSubmit={this.goToBilling}
          />
        </div>
        {(this.state.policy && this.state.policy.text) &&
          <div className="Policy-text">
            <div className="SubHeadline">SHIPPING POLICY</div>
            <div className="SmallBodyText">{this.state.policy.text}</div>
          </div>
        }
      </Popup>
    )
  }
}

const mapStateToProps = state => ({
  activeProduct: state.activeProduct,
  cartItems: state.cart.items,
  cartIsFetching: state.cart.isFetching,
  shipping: state.shipping,
  shippingIsOpen: state.shipping.isOpen,
  userMembership: state.userMembership.membership,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateProductQuantity,
      deselectShipping,
      openShopPaymentPopup,
      checkoutTotal,
      addProductToCart,
      fetchShipmentProducts,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(ShippingPopup);
