import superagent from "superagent";
export const FETCH_BLOG_SUCCESS = "FETCH_BLOG_SUCCESS";

export const fetchBlog = page => (dispatch, getState) => {
  const state = getState()
  const url = page ? `/tribune?page=${page}` : '/tribune';

  return new Promise(async (resolve, reject) => {
    await superagent
      .get(url)
      .set("Accept", "application/json")
      .send()
      .then(res => {
        dispatch({
          type: FETCH_BLOG_SUCCESS,
          posts: res.body.posts,
          page,
          isLastPage: res.body.last_page,
        });
        resolve(state.blog.posts.concat(res.body.posts));
      })
      .catch(error => {
        reject(error.response.body);
      });
  });
};
