import { DROPDOWN_CHANGE } from '../actions/dropdowns'

const dropdowns = (state = {}, action) => {
  switch (action.type) {
    case DROPDOWN_CHANGE:
      return {
        ...state,
        ...action.dropdowns
      };
    default:
      return state
  }
}

export default dropdowns
