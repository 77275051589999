import {
  FETCH_WORKSHOPS,
  FETCH_WORKSHOPS_ERROR,
  FETCH_WORKSHOPS_SUCCESS
} from '../actions/workshops';

const INITIAL_STATE = {
  workshops: [],
  error: null,
  isFetching: false
};

const workshops = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_WORKSHOPS:
      return {
        ...state,
        isFetching: true
      };
    case FETCH_WORKSHOPS_ERROR:
      return {
        ...state,
        error: action.error,
        isFetching: false
      };
    case FETCH_WORKSHOPS_SUCCESS:
      return {
        ...state,
        workshops: action.workshops,
        error: null,
        isFetching: false
      };
    default:
      return state;
  }
};

export const isFetching = state => state.workshops.isFetching;

export default workshops;
