import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { checkoutService } from "../actions/checkout";
import Billing from "./billing";
import CreditCard from "./credit_card";
import Loading from "./../../../assets/images/icons/yinyang_icon.png";
import GiftCardSubtotal from "./../../../assets/images/gift_card_subtotal.svg";

class ShopRemainingBalance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardIsChosen: false
    };
  }

  chooseCard = () => {
    const { cardIsChosen } = this.state;
    const {
      user: { card_last_four },
      showBillingForm,
      closeBillingForm
    } = this.props;

    if (!card_last_four) return;

    this.setState({ cardIsChosen: !cardIsChosen });

    if (showBillingForm) {
      closeBillingForm();
    }
  };

  render() {
    const {
      user,
      remainingBalance,
      checkoutTotalIsFetching,
      error,
      goBack
    } = this.props;

    const { cardIsChosen } = this.state;

    return (
      <Fragment>
        <div className="arrow-left go-to-prev" onClick={goBack} />

        <h2 className="Purchase-popup-title mb-1">PAYMENT</h2>
        <div className="Purchase-popup-subtitle mb-4">REMAINING BALANCE</div>

        <div className="gift-card-subtotal-container">
          <img
            src={GiftCardSubtotal}
            width={138}
            alt="GiftCard"
            className="gift-card-subtotal"
          />
          <div className="font-weight-bold">
            GIFT CARD APPLIED: ${remainingBalance.gift_card_applied_amount}
          </div>
          <div className="font-weight-bold color-green">
            NEW SUBTOTAL: ${remainingBalance.remaining}
          </div>
        </div>

        <div className="CreditCard-title mb-3">SELECT A PAYMENT METHOD</div>
        <CreditCard
          chooseCard={this.chooseCard}
          isChecked={cardIsChosen}
          hideTitle
        />

        <div className="text-center mt-4 mb-3">
          {user.card_last_four ? "OR" : ""}
        </div>
        <Billing toggleCard={this.chooseCard} cardIsChosen={cardIsChosen} />

        {checkoutTotalIsFetching ? (
          <div className="inline-loading">
            <img src={Loading} height="21" width="21" />
            <div>PLEASE WAIT, CHECKOUT IN PROGRESS...</div>
          </div>
        ) : (
          <Fragment>
            {error && (
              <div className="OrderPopup-error">
                {error.message ||
                  "Something went wrong. Please try again."}
              </div>
            )}
            <button
              className="Button high-button w-100 rounded"
              onClick={() =>
                this.props.checkoutTotalWithGiftCard(
                  remainingBalance.gift_card_applied_amount
                )
              }
              disabled={!cardIsChosen}
            >
              Confirm
            </button>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  checkoutTotalCart: state.checkoutTotal.cart
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      checkoutService
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShopRemainingBalance);
