import {
  OPEN_SHOP_PAYMENT_POPUP,
  CLOSE_SHOP_PAYMENT_POPUP
} from '../actions/shop_payment_popup';

const INITIAL_STATE = {
  show: false
};

const shopPaymentPopup = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OPEN_SHOP_PAYMENT_POPUP:
      return {
        ...state,
        show: true
      };
    case CLOSE_SHOP_PAYMENT_POPUP:
      return {
        ...state,
        show: false
      };
    default:
      return state;
  }
};

export default shopPaymentPopup
