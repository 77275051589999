import {
  FETCH_USER_MEMBERSHIPS,
  FETCH_USER_MEMBERSHIPS_SUCCESS,
  FETCH_USER_MEMBERSHIPS_ERROR,
} from '../actions/user_memberships';

const INITIAL_STATE = {
  membership: null,
  error: null,
  isFetching: false,
};

const userMembership = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_USER_MEMBERSHIPS:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_USER_MEMBERSHIPS_SUCCESS:
      return {
        ...state,
        membership: action.membership,
        isFetching: false,
      };
    case FETCH_USER_MEMBERSHIPS_ERROR:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default userMembership;
