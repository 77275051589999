import superagent from 'superagent';

const API_ENDPOINT = '/users/cards';

const submitBillingForm = formData =>
  superagent
    .post(API_ENDPOINT)
    .set('Accept', 'application/json')
    .send({ card: formData });

export default { submitBillingForm };
