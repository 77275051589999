import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import superagent from "superagent";
import { bindActionCreators } from "redux";
import { checkoutService } from "../actions/checkout";
import {
  dropCheckoutTotalCart,
  checkoutTotal
} from "../actions/checkout_total";
import { addBillingLastFour, dropBillingLastFour } from "../actions/billing";

import Billing from "./billing";
import CreditCard from "./credit_card";
import PromoCodeInput from "./promo_code_input.jsx";
import Loading from "./../../../assets/images/icons/yinyang_icon.png";
import GiftCardPaymentMethod from "./gift_card_payment_method";
import RemainingBalance from "./remaining_balance";
import { history } from "../store/configureStore";
import Recaptcha from "./recaptcha";

class ServicePaymentPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardIsChosen: false,
      giftCardIsChosen: false,
      promoCode: null,
      checkingBalance: false,
      remainingBalance: null,
      checkGiftCardError: null,
      checkoutTotalError: null,
      purchaseInProgress: false,
      success: false,
      recaptchaValue: "",
      recaptcha: false
    };
    this.recaptchaRef = React.createRef();
  }
  componentWillMount() {
    document.body.classList.add("overflow-hidden");
  }
  componentWillUnmount() {
    document.body.classList.remove("overflow-hidden");
  }

  componentDidMount() {
    this.fetchCheckoutTotal();
  }
  fetchCheckoutTotal = (noDiscount = false) => {
    const { activeService, checkoutTotal } = this.props;
    this.setState({ checkoutTotalError: null });

    if (!activeService.contract) {
      checkoutTotal(
        [ { mindbody_identifier: activeService.id, quantity: 1 } ],
        "Service",
        null,
        null,
        noDiscount,
      )
        .catch(err => {
          if (err && err.code === "UNAUTHORIZED") {
            const queryStr = `?service=${activeService.slug ? activeService.slug : activeService.id}`;

            history.push({
              pathname: "/login",
              state: { cameFrom: window.location.pathname + queryStr }
            });
          } else {
            this.setState({ checkoutTotalError: err.message || "Something went wrong. Please try again" });
          }
        });
    }
  };

  onRecaptchaChange = (value) => {
    const { recaptcha } = this.state;
    this.setState({recaptchaValue: value, recaptcha: !recaptcha})
  }

  buyService = () => {
    const { promoCode, giftCardIsChosen, purchaseInProgress, recaptchaValue } = this.state;
    if (purchaseInProgress) return;
    const {
      checkoutService,
      checkoutTotalCart,
      activeService,
      afterCheckout,
      hideCheckoutSuccessPopup
    } = this.props;

    if (giftCardIsChosen) {
      this.checkGiftCardBalance(checkoutTotalCart.grand_total);
    } else {
      this.setState({ purchaseInProgress: true, success: false });

      checkoutService(
        activeService.id,
        checkoutTotalCart.grand_total,
        false,
        promoCode,
        null,
        hideCheckoutSuccessPopup,
        recaptchaValue
      )
        .then(res => {
          this.setState({ purchaseInProgress: false, success: true });
          afterCheckout();
        })
        .catch(err => {
          this.resetValue();
        });
    }
  };

  checkGiftCardBalance = amount => {
    this.setState({ checkingBalance: true, checkGiftCardError: null });
    return superagent
      .get("/check_gift_card_balance")
      .set("Accept", "application/json")
      .query({ amount })
      .then(res => {
        if (res.body.remaining === 0) {
          this.setState({
            checkGiftCardError: 'Your gift card balance is 0. Please choose another payment method',
            checkingBalance: false
          });
        } else {
          this.setState({
            remainingBalance: res.body,
            checkingBalance: false
          });
        }
      })
      .catch(error => {
        this.setState({
          checkGiftCardError: error.response.body,
          checkingBalance: false
        });
      });
  };

  buyContract = amount => {
    const {
      promoCode,
      giftCardIsChosen,
      purchaseInProgress,
      recaptchaValue,
    } = this.state;
    if (purchaseInProgress) return;

    const {
      checkoutService,
      activeService,
      afterCheckout,
      hideCheckoutSuccessPopup
    } = this.props;

    if (giftCardIsChosen) {
      this.checkBalanceForContract(amount);
    } else {
      this.setState({ purchaseInProgress: true, success: false });

      checkoutService(
        activeService.id,
        amount,
        true,
        promoCode,
        null,
        hideCheckoutSuccessPopup,
        recaptchaValue
      )
        .then(res => {
          this.setState({ purchaseInProgress: false, success: true });
          afterCheckout();
        })
        .catch(err => {
          this.resetValue();
        });
    }
  };

  resetValue = () => {
    this.setState({ purchaseInProgress: false });
    if (this.recaptchaRef.current) {
      const { recaptcha } = this.state;
      this.recaptchaRef.current.resetRecaptcha();
      this.setState({ recaptchaValue: "", recaptcha: !recaptcha });
    }
  };

  checkBalanceForContract = amount => {
    const { activeService } = this.props;

    this.setState({ checkingBalance: true, checkGiftCardError: null });

    return superagent
      .get("/check_gift_card_balance")
      .set("Accept", "application/json")
      .query({ contract_service_id: activeService.id })
      .then(res => {
        if (res.body.remaining === 0) {
          this.setState({
            checkGiftCardError: 'Your gift card balance is 0. Please choose another payment method',
            checkingBalance: false
          });
        } else {
          this.setState({
            remainingBalance: res.body,
            checkingBalance: false
          });
        }
      })
      .catch(error => {
        this.setState({
          checkGiftCardError: error.response.body,
          checkingBalance: false
        });
      });
  };

  chooseCard = () => {
    const { cardIsChosen, giftCardIsChosen } = this.state;
    const {
      user: { card_last_four },
      showBillingForm,
      closeBillingForm
    } = this.props;

    if (!card_last_four) return;

    if (giftCardIsChosen) {
      this.fetchCheckoutTotal()
    }

    this.setState({
      cardIsChosen: !cardIsChosen,
      giftCardIsChosen: false,
      checkGiftCardError: null
    });

    if (showBillingForm) {
      closeBillingForm();
    }
  };

  chooseGiftCard = () => {
    const { giftCardIsChosen } = this.state;

    this.setState({
      cardIsChosen: false,
      giftCardIsChosen: !giftCardIsChosen,
      promoCode: null
    });

    this.fetchCheckoutTotal(true);
  };

  serviceName = () => {
    const {
      activeService: { name_to_display, name },
      checkoutTotalCart
    } = this.props;

    const checkoutTotalServiceName = _.result(
      checkoutTotalCart,
      "cart_items.cart_item.item.name",
      null
    );

    const serviceName = checkoutTotalServiceName
      ? `${checkoutTotalServiceName}: $${Number(
          checkoutTotalCart.grand_total
        ).toFixed(2)}`
      : name_to_display || name;

    return serviceName;
  };

  contentForService = () => {
    const {
      user,
      activeService,
      activeService: { name_to_display, name },
      checkoutTotalCart,
      checkoutTotalIsFetching,
      waitingForCheckout,
      error,
      afterCheckout,
      hideCheckoutSuccessPopup
    } = this.props;

    const {
      giftCardIsChosen,
      cardIsChosen,
      checkingBalance,
      remainingBalance,
      checkGiftCardError,
      checkoutTotalError,
      purchaseInProgress,
      success,
      recaptcha
    } = this.state;

    if (remainingBalance)
      return (
        <RemainingBalance
          user={user}
          activeService={activeService}
          serviceName={this.serviceName()}
          grandTotal={checkoutTotalCart ? checkoutTotalCart.grand_total : null}
          remainingBalance={remainingBalance}
          waitingForCheckout={waitingForCheckout}
          error={error}
          closePurchasePopup={afterCheckout}
          goBack={() => this.setState({ remainingBalance: null })}
          hideCheckoutSuccessPopup={hideCheckoutSuccessPopup}
        />
      );

    return (
      <Fragment>
        <h2 className="Purchase-popup-title">PAYMENT</h2>
        {checkoutTotalError ? (
          <div className="Purchase-popup-error">
            {checkoutTotalError.message}
          </div>
        ) : (
          <Fragment>
            {checkoutTotalIsFetching ? (
              <div className="inline-loading mb-3">
                <img src={Loading} height="21" width="21" alt="Loading" />
              </div>
            ) : (
              <Fragment>
                {checkoutTotalCart && (
                  <div
                    className="Service-name"
                    dangerouslySetInnerHTML={{ __html: this.serviceName() }}
                  />
                )}
              </Fragment>
            )}

            <div className="CreditCard-title mb-3">SELECT A PAYMENT METHOD</div>
            <CreditCard
              chooseCard={this.chooseCard}
              isChecked={this.state.cardIsChosen}
              hideTitle
            />
            <GiftCardPaymentMethod
              chooseGiftCard={this.chooseGiftCard}
              isChecked={giftCardIsChosen}
              withoutTopBorder={!!user.card_last_four}
            />
            <div className="mb-3" />
            {!giftCardIsChosen && (
              <Fragment>
                <div className="text-center mt-4 mb-3">
                  {user.card_last_four ? "OR" : ""}
                </div>
                <Billing
                  toggleCard={this.chooseCard}
                  cardIsChosen={this.state.cardIsChosen}
                  addedCard={(cardIsAdded) => this.setState({ cardIsChosen: cardIsAdded })}
                />
                {activeService && (
                  <PromoCodeInput
                    setPromoCode={promoCode => this.setState({ promoCode })}
                    activePricingServise={activeService}
                  />
                )}
              </Fragment>
            )}
            <Recaptcha
              ref={this.recaptchaRef}
              onChange={this.onRecaptchaChange}
            />
            {waitingForCheckout || checkingBalance || purchaseInProgress ? (
              <div className="inline-loading mt-3">
                <img src={Loading} height="21" width="21" alt="Loading" />
                <div>PLEASE WAIT... DO NOT REFRESH YOUR BROWSER</div>
              </div>
            ) : (
              <Fragment>
                { success ? (
                  <div className="color-green text-uppercase">
                    Order confirmed
                  </div>
                ) : (
                  <>
                    {error && (
                      <div className="OrderPopup-error">
                        {error.message ||
                        "The payment was unsuccessful. Please try again."}
                      </div>
                    )}

                    {checkGiftCardError && (
                      <div className="OrderPopup-error">
                        {checkGiftCardError.message ||
                        "Wasn't able to check gift card balance"}
                      </div>
                    )}
                    <button
                      className="Button high-button w-100 rounded"
                      onClick={() => this.buyService()}
                      disabled={
                        !activeService ||
                        (!cardIsChosen && !giftCardIsChosen) ||
                        checkoutTotalIsFetching || purchaseInProgress || !recaptcha
                      }
                    >
                      Confirm
                    </button>
                  </>
                )}
              </Fragment>
            )}
          </Fragment>
        )}
      </Fragment>
    );
  };

  contentForContract = () => {
    const {
      user,
      activeService,
      activeService: { name_to_display, name, contract },
      checkoutTotalIsFetching,
      waitingForCheckout,
      error,
      pricingPage,
      afterCheckout,
      hideCheckoutSuccessPopup
    } = this.props;

    const {
      promoCode,
      giftCardIsChosen,
      cardIsChosen,
      checkingBalance,
      checkGiftCardError,
      remainingBalance,
      purchaseInProgress,
      success,
      recaptcha
    } = this.state;

    if (remainingBalance)
      return (
        <RemainingBalance
          user={user}
          activeService={activeService}
          serviceName={name}
          grandTotal={contract.first_payment_amount_subtotal}
          remainingBalance={remainingBalance}
          waitingForCheckout={waitingForCheckout}
          error={error}
          closePurchasePopup={afterCheckout}
          goBack={() => this.setState({ remainingBalance: null })}
          isContract
          hideCheckoutSuccessPopup={hideCheckoutSuccessPopup}
        />
      );

    return (
      <Fragment>
        <h2 className="Purchase-popup-title">PAYMENT</h2>
        <div className="Service-name">
          <div dangerouslySetInnerHTML={{ __html: name }} />
          {promoCode && pricingPage.coupon_code_message && (
            <div className="coupon-code-message mt-3">
              {pricingPage.coupon_code_message}
            </div>
          )}
        </div>

        <CreditCard
          chooseCard={this.chooseCard}
          isChecked={cardIsChosen}
          hideTitle
        />
        <GiftCardPaymentMethod
          chooseGiftCard={this.chooseGiftCard}
          isChecked={giftCardIsChosen}
          withoutTopBorder={!!user.card_last_four}
        />
        <div className="mb-3" />
        {!giftCardIsChosen && (
          <Fragment>
            <div className="text-center mt-4 mb-3">
              {user.card_last_four ? "OR" : ""}
            </div>
            <Billing toggleCard={this.chooseCard} cardIsChosen={cardIsChosen} />
            {activeService && (
              <PromoCodeInput
                setPromoCode={promoCode => this.setState({ promoCode })}
                activePricingServise={activeService}
                isContract
              />
            )}
          </Fragment>
        )}
        <Recaptcha ref={this.recaptchaRef} onChange={this.onRecaptchaChange} />
        {waitingForCheckout || checkingBalance || purchaseInProgress ? (
          <div className="inline-loading">
            <img src={Loading} height="21" width="21" alt="Loading" />
            <div>PLEASE WAIT...</div>
          </div>
        ) : (
          <Fragment>
            { success ? (
              <div className="color-green text-uppercase">
                Order confirmed
              </div>
            ) : (
              <>
                {error && (
                  <div className="OrderPopup-error">
                    {error.message ||
                      "The payment was unsuccessful. Please try again."}
                  </div>
                )}
                {checkGiftCardError && (
                  <div className="OrderPopup-error">
                    {checkGiftCardError.message ||
                      "Wasn't able to check gift card balance"}
                  </div>
                )}

                <button
                  className="Button high-button w-100 rounded"
                  onClick={() =>
                    this.buyContract(contract.first_payment_amount_subtotal)
                  }
                  disabled={
                    !activeService ||
                    (!cardIsChosen && !giftCardIsChosen) ||
                    checkoutTotalIsFetching || purchaseInProgress || !recaptcha
                  }
                >
                  Confirm
                </button>
              </>
            )}
          </Fragment>
        )}
      </Fragment>
    );
  };

  render() {
    const { activeService } = this.props;

    const isContract = !!activeService.contract;

    return (
      <div className="Purchase-popup">
        {isContract ? this.contentForContract() : this.contentForService()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  checkoutTotalCart: state.checkoutTotal.cart,
  checkoutTotalIsFetching: state.checkoutTotal.isFetching,
  waitingForCheckout: state.checkout.waitingForCheckout,
  error: state.checkout.error,
  show: state.purchasePopup.show
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      checkoutTotal,
      checkoutService,
      addBillingLastFour,
      dropBillingLastFour,
      dropCheckoutTotalCart
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServicePaymentPopup);
