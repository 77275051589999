import _ from 'lodash';

import {
  FETCH_USER_SCHEDULE,
  FETCH_USER_SCHEDULE_ERROR,
  FETCH_USER_SCHEDULE_SUCCESS,
  DELETE_FROM_USER_SCHEDULE,
} from '../actions/user_schedule';

const INITIAL_STATE = {
  error: null,
  bookedLessons: [],
  waitlist: [],
  isFetching: false,
};

const userSchedule = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_USER_SCHEDULE:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_USER_SCHEDULE_ERROR:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    case FETCH_USER_SCHEDULE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        bookedLessons: action.userSchedule.schedule,
        waitlist: action.userSchedule.waitlist,
      };
    case DELETE_FROM_USER_SCHEDULE:
      return {
        ...state,
        isFetching: false,
        bookedLessons: state.bookedLessons.filter(lesson => lesson.class_id !== action.lessonId )
      };
    default:
      return state;
  }
};

export const isFetching = state => state.userSchedule.isFetching;

export const isBooked = (lessonId, state) => {
  const lessonsIds = state.userSchedule.bookedLessons.map((l) => l.class_id)
  return _.includes(lessonsIds, lessonId)
}

export const waitlistEntry = (lessonId, state) => {
  const entry = state.userSchedule.waitlist.find((item) => {
    return item.class_id.toString() === lessonId.toString();
  })
  return entry || null;
}

export default userSchedule;
