import React, { Component } from 'react';
import moment from "moment/moment";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import { Link } from 'react-router-dom';

import {formattedLocationName} from "../../helpers/formatted_location_name";
import BookLessonButton from '../book_lesson_button.jsx';
import {isBooked, waitlistEntry} from "../../reducers/user_schedule";
import {isAuthenticated} from "../../reducers/user";
import {closeLessonPopup} from "../../actions/lesson_popup";
import {bookLesson, cancelLesson} from "../../actions/lesson";
import {fetchUserSchedule} from "../../actions/user_schedule";
import {getBalance} from "../../actions/balances";
import Popup from '../popup.jsx';
import LoginForm from '../lesson_booking/login_form';
import RegistrationForm from "../lesson_booking/register";
import PaymentForm from "../lesson_booking/payment_form";
import LessonApi from "../../api/lesson";
import WaitlistApi from '../../api/waitlist';
import Star from "../../../../assets/images/icons/credit.png";
import ServicePayment from "../service_payment";

const getReserveBtnLabel = (workshop, showWaitlist) => {
  if (showWaitlist) {
    return (
      <div>
        <div className="Button w-100">Join Waitlist</div>
      </div>
    )
  }

  const price = (workshop.additional && workshop.additional.price) ? workshop.additional.price : null;

  return (
    <div>
      <div className="Button w-100">RESERVE A SPOT</div>
      { price &&
        <div className="Button">${price}</div>
      }
    </div>
  )
}

class WorkshopPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      selectedService: null,
      step: props.step || 'lesson',
      error: props.bookingError || null,
      showWaitlist: props.showWaitlist || false,
      removingFromWaitlist: false,
    };
  }

  componentWillMount() {
    document.body.classList.add('overflow-hidden');
  }

  componentWillUnmount() {
    document.body.classList.remove('overflow-hidden');
  }

  renderCurrentStep = () => {
    const { workshop } = this.props

    switch(this.state.step) {
      case 'login':
        return <LoginForm
          changeStep={(step) => this.changeStep(step)}
          continueBooking={() => this.bookLesson(workshop)}
        />
      case 'register':
        return <RegistrationForm
          changeStep={(step) => this.changeStep(step)}
          continueBooking={() => this.bookLesson(workshop)}
        />
      case 'payment':
        return <PaymentForm
          lessonId={workshop.main.mindbody_identifier}
          changeStep={(step) => this.changeStep(step)}
          selectService={(selectedService) => this.setState({ selectedService })}
        />
      case 'billing':
        return <ServicePayment
          activeService={this.state.selectedService}
          afterCheckout={() => this.bookLesson(workshop)}
          hideCheckoutSuccessPopup
        />
      case 'success':
        return this.renderSuccess();
      case 'successWaitlist':
        return this.renderSuccessWaitlist();
      default:
        return this.renderLesson()
    }
  }

  bookLesson = (workshop, joinWaitlist = false) => {
    const { isAuthenticated, fetchUserSchedule, getBalance } = this.props;
    this.setState({ error: null });

    if (!isAuthenticated) {
      this.setState({ step: 'login' });
    } else {
      this.setState({ loading: true });
      LessonApi.bookLesson(workshop.main.mindbody_identifier, joinWaitlist)
        .then(res => {
          this.setState({
            step: joinWaitlist ? 'successWaitlist' : 'success',
            loading: false,
            showWaitlist: false,
          });
          fetchUserSchedule();
          getBalance();
        })
        .catch(error => {
          if (error.response.body && error.response.body.message === 'no balance') {
            this.setState({ step: 'payment', loading: false });
          } else if (error.response.body && error.response.body.message === "full class") {
            this.setState({
              step: "lesson",
              showWaitlist: true,
              loading: false,
            });
          } else {
            this.setState({
              error: error.response.body,
              loading: false,
              showWaitlist: false,
            });
          }
        });
    }
  }

  changeStep = (step) => {
    this.setState({ step })
  }

  renderSuccess = () => {
    return(
      <div className="Lesson">
        <div className="Lesson-title">{this.props.workshop.main.name}</div>
        <div className="mt-5 text-uppercase" style={{ fontSize: '20px' }}>
          Workshop was successfully booked
        </div>
      </div>
    )
  }

  renderSuccessWaitlist = () => {
    return (
      <div className="Lesson">
        <div className="Lesson-title">{this.props.workshop.main.name}</div>
        <div className="mt-5 text-uppercase" style={{ fontSize: '20px' }}>
          You are added to the waiting list!
        </div>
      </div>
    )
  }

  removeFromWaitlist = (waitlistId) => {
    const { fetchUserSchedule, getBalance } = this.props;

    this.setState({ removingFromWaitlist: true, error: null });
    WaitlistApi.removeFromWaitlist(waitlistId)
      .then((res) => {
        fetchUserSchedule();
        getBalance();
        this.setState({ removingFromWaitlist: false });
      })
      .catch((err) => {
        this.setState({
          removingFromWaitlist: false,
          error: err.response.body,
        });
      });
  }

  renderReserveButton = () => {
    const { workshop, isBooked, cancelLesson, waitlistEntry } = this.props;
    const { showWaitlist, loading, removingFromWaitlist } = this.state;

    const loadingText = removingFromWaitlist ? 'Wait...' : 'Booking...';

    if (loading || removingFromWaitlist) return (
      <button className="Button mb-4" disabled >
        <span>{loadingText}</span>
      </button>
    )

    if (waitlistEntry) return (
      <button
        className="Button Cancel-button"
        onClick={() => this.removeFromWaitlist(waitlistEntry.id)}
      >
        <span className="Overlay">
          Added to waitlist
        </span>
        <span className="Hover">REMOVE FROM WAITLIST</span>
      </button>
    )

    if (isBooked) return (
      <button
        className="Button Cancel-button"
        onClick={() => cancelLesson(workshop.main)}
      >
        <span className="Overlay">
          RESERVED
          <img className="Schedule-bookedImage" src={Star} />
        </span>
        <span className="Hover">CANCEL</span>
      </button>
    )

    return (
      <BookLessonButton
        label={getReserveBtnLabel(workshop, showWaitlist)}
        reservedLabelText={ showWaitlist ? "Joined Waitlist" : "Reserved" }
        onClick={() => this.bookLesson(workshop, showWaitlist)}
        lessonId={workshop.main.mindbody_identifier}
        className="Reserve-btn"
      />
    )
  };

  renderLesson = () => {
    const { showWaitlist, error } = this.state;
    const { workshop, policy } = this.props;

    return (
      <div className="Workshop-popup">
        {workshop.additional && workshop.additional.image.url &&
        <div className="Workshop-image-wrapper">
          <img src={workshop.additional.image.url} className="Workshop-image"/>
        </div>
        }
        <div className="Workshops-header">{workshop.main.class_description.name}</div>
        <div className="Info">
          <p>
            <span className="Bold">W/&nbsp;</span>
            <span>{workshop.main.staff.name}</span>
          </p>
          <p>
            <span className="Bold">{moment.utc(workshop.main.start_date_time).format('dddd, MMM D YYYY')}</span>
            <span className="Bold">
              &nbsp;@ {formattedLocationName(workshop.main.location.name)}
            </span>
          </p>
          <p>{moment.utc(workshop.main.start_date_time).format('h:mm A')} - {moment.utc(workshop.main.end_date_time).format('h:mm A')}</p>
        </div>
        { error &&
        <div className="error">
          { error.message ? error.message : "Something went wrong, please try later" }
        </div>
        }
        { showWaitlist &&
          <div className="error">The class is full</div>
        }
        {this.renderReserveButton()}

        {workshop.additional && workshop.additional.description &&
          <p dangerouslySetInnerHTML={{__html: workshop.additional.description}}></p>
        }
        <div className='text-large Bold'>{workshop.main.staff.name}</div>
        {workshop.additional && workshop.additional.teacher_description &&
          <p>{workshop.additional.teacher_description}</p>
        }
        {workshop.main.location.image_url &&
          <img src={workshop.main.location.image_url} style={{height: 300}} className="Workshop-image"/>
        }
        <Link to="/locations" title="Visit locations page">
          <div className="Button Button--secondary">
            {formattedLocationName(workshop.main.location.name)}
          </div>
        </Link>

        <div className="text-large">
          {workshop.main.location.address}, {workshop.main.location.city}, {workshop.main.location.state_prov_code}
        </div>
        <div className="text-large">{workshop.main.location.phone}</div>
        {(policy && policy.text) &&
        <div className="Policy-text">
          <div className="SubHeadline">CANCELLATION POLICY</div>
          <div className="SmallBodyText">{policy.text}</div>
        </div>
        }
      </div>
    )
  }

  render() {
    const { workshop, closeWorkshopPopup } = this.props

    if (!workshop) return <div />

    return (
      <Popup
        key={workshop.main.id}
        id={workshop.main.id}
        isOpen
        onClose={() => closeWorkshopPopup()}
      >
        {this.renderCurrentStep()}
      </Popup>
    )
  }
};

const mapStateToProps = (state, ownProps) => ({
  locations: state.locations.locations,
  isBooked: isBooked(ownProps.workshop.main.mindbody_identifier, state),
  waitlistEntry: waitlistEntry(ownProps.workshop.main.mindbody_identifier, state),
  isAuthenticated: isAuthenticated(state),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      closeLessonPopup,
      bookLesson,
      cancelLesson,
      fetchUserSchedule,
      getBalance,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(WorkshopPopup);