import PropTypes from 'prop-types'

import lessonType from './lesson_type'
import photoType from './photo_type'
import teacherType from './teacher_type'

const mentorshipType = PropTypes.shape({
  main: lessonType,
  additional: PropTypes.shape({
    id: PropTypes.number.isRequired,
    mindbody_identifier: PropTypes.number.isRequired,
    created_at: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
    dates: PropTypes.string,
    prerequisites: PropTypes.string,
    price: PropTypes.string,
  }).isRequired,
  mentors: PropTypes.arrayOf(teacherType).isRequired,
  photos: PropTypes.arrayOf(photoType).isRequired,
  price: PropTypes.object.isRequired,
}).isRequired

export default mentorshipType
