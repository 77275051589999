import { connect } from "react-redux";
import React, { Component, Fragment } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import superagent from "superagent";
import { bindActionCreators } from "redux";

import logo from "./../../../assets/images/icons/lady.svg";
import tv from "./../../../assets/images/icons/tv_black.svg";
import Bag from "../components/bag";
import NavbarDropdown from "../components/navbar_dropdown";
import NavbarDropdownLinks from "../components/navbar_dropdown_links";
import NavbarMobile from "../components/navbar_mobile";
import BagItemsCounter from "../components/bag_items_counter";
import { isAuthenticated } from "../reducers/user";
import { getUserAvatar } from "../helpers/get_user_avatar";
import { logout } from "../actions/authentication";

class NavbarNew extends Component {
  constructor(props) {
    super(props);
    this.state = { banner: null };
  }

  componentDidMount() {
    if (window.innerWidth > 830) {
      window.addEventListener("scroll", this.handleScroll, true);
    }

    return superagent
      .get("/banner")
      .set("Accept", "application/json")
      .send()
      .then(res => {
        this.setState({
          banner: res.body
        });
      })
      .catch(() => console.log("Banner error!"));
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const el = document.getElementById("navbar");
    if (document.documentElement.scrollTop > 100) {
      if (el) {
        el.classList.add("thin");
      }
    } else {
      if (el) {
        el.classList.remove("thin");
      }
    }
  };

  renderAccountButtons() {
    const { user } = this.props;

    return (
      <div className="Navbar-link-wrapper account-buttons">
        <NavbarDropdown
          name="account_buttons"
          className="navbar-account-dropdown"
          withArrow
        >
          <div className="navbar-account-btn">
            <div className="navbar-user-avatar">
              <img src={getUserAvatar(user)} alt={user.first_name} />
            </div>
          </div>
          <>
            <Link
              to="/account"
              title="Visit your account"
              className="Navbar-dropdown-link"
            >
              My account
            </Link>
            <Link
              to="/account"
              title="Visit your account"
              className="Navbar-dropdown-link"
            >
              Subscriptions
            </Link>
            <div
              className="Navbar-dropdown-link cursor-pointer"
              onClick={this.props.logout}
            >
              Log out
            </div>
          </>
        </NavbarDropdown>
      </div>
    );
  }

  renderUserButtons() {
    const { user } = this.props;

    if (user && user.id) return this.renderAccountButtons();
    return (
      <Fragment>
        <div className="Navbar-link-wrapper account-login-btn">
          <Link
            to={{
              pathname: "/login",
              state: { cameFrom: this.props.pathname }
            }}
            className="navbar-link-sk nav-login-btn"
          >
            LOG IN
          </Link>
        </div>
        <div className="Navbar-link-wrapper account-login-btn">
          <Link
            to={{
              pathname: "/signup",
              state: {
                cameFrom: this.props.pathname,
                isNavbarFlow: true,
                withLive: true
              }
            }}
            className="navbar-link-sk nav-login-btn signup"
          >
            SIGN UP!
          </Link>
        </div>
      </Fragment>
    );
  }

  isCurrentPath = path => {
    return path.includes(this.props.pathname);
  };

  render() {
    const {
      user,
      colorTheme,
      pathname,
      services,
      cartItems,
      unauthorizedCartItems,
      isAuthenticated
    } = this.props;
    const { banner } = this.state;

    const navbarClass = classNames(colorTheme, 'navbar-sk');

    const emptyCart = isAuthenticated
      ? cartItems.length === 0
      : unauthorizedCartItems.length === 0;

    return (
      <div className={navbarClass} id="navbar">
        {banner && (
          <div
            className="navbar-banner"
            dangerouslySetInnerHTML={{ __html: banner }}
          />
        )}
        <div
          className={`navbar-content-sk ${
            !isAuthenticated ? "unauthenticated" : ""
          }`}
        >
          <div className="Navbar-leftLinkContainer">
            <div className="Navbar-link-wrapper">
              <NavbarDropdown
                name="tv"
                withArrow
                borderOnHover
              >
                <div className="tv">
                  <img src={tv} width={40} alt="TV" />
                </div>
                <NavbarDropdownLinks
                  name="tv"
                  rows={[
                    { id: 1, url: "/tv", name: "Dashboard" },
                    { id: 2, url: "/tv/videos", name: "Library" },
                    { id: 3, url: "/tv/live", name: "Zoom schedule" },
                    { id: 4, url: "/tv/collections", name: "Collections" },
                    { id: 5, url: "/pricing", name: "Pricing" },
                  ]}
                />
              </NavbarDropdown>
            </div>
            <div className="Navbar-link-wrapper">
              {this.isCurrentPath([
                "/schedule",
                "/in-person-pricing"
              ]) && <div className="arrow-down" />}
              <NavbarDropdown
                name="In person"
                withArrow
                borderOnHover
                active={
                  this.isCurrentPath(["/schedule", "/in-person-pricing"]) ? "active" : ""
                }
              >
                In person
                <NavbarDropdownLinks
                  name="In person"
                  routes={[
                    "/schedule",
                    "/in-person-pricing"
                  ]}
                  rows={[
                    { id: 1, url: "/schedule", name: "Schedule" },
                    { id: 3, url: "/in-person-pricing", name: "Pricing" }
                  ]}
                />
              </NavbarDropdown>
            </div>

            {/*<div className="Navbar-link-wrapper">*/}
            {/*  <Link*/}
            {/*    to="/tv/workshops"*/}
            {/*    title="Go to workshops"*/}
            {/*    className={`navbar-link-sk with-hover-border ${*/}
            {/*      pathname === "/tv/workshops" ? "active" : ""*/}
            {/*    }`}*/}
            {/*  >*/}
            {/*    Workshops*/}
            {/*  </Link>*/}
            {/*</div>*/}
            <div className="Navbar-link-wrapper">
              <Link
                to="/courses"
                title="Go to courses"
                className={`navbar-link-sk with-hover-border ${
                  pathname === "/courses" ? "active" : ""
                }`}
              >
                Courses
              </Link>
            </div>
            <div className="Navbar-link-wrapper">
              <Link
                to="/retreats"
                title="Go to retreats"
                className={`navbar-link-sk with-hover-border ${
                  pathname === "/retreats" ? "active" : ""
                }`}
              >
                Retreats
              </Link>
            </div>

            {/*<div className="Navbar-link-wrapper">*/}
            {/*  <Link*/}
            {/*    to="/schedule"*/}
            {/*    title="Go to schedule"*/}
            {/*    className={`navbar-link-sk with-hover-border ${pathname === '/schedule' ? 'active' : ''}`}*/}
            {/*  >*/}
            {/*    Schedule*/}
            {/*  </Link>*/}
            {/*</div>*/}

            {/*<div className="Navbar-link-wrapper">*/}
            {/*  { this.isCurrentPath(['/workshops', '/retreats', '/trainings', '/mentorship', '/sauna']) &&*/}
            {/*  <div className="arrow-down" />*/}
            {/*  }*/}
            {/*  <NavbarDropdown */}
            {/*    name="programs" */}
            {/*    withArrow*/}
            {/*    borderOnHover*/}
            {/*    active={this.isCurrentPath(['/workshops', '/retreats', '/trainings', '/sauna']) ? 'active' : ''}*/}
            {/*  >*/}
            {/*    Programs*/}
            {/*    <NavbarDropdownLinks*/}
            {/*      name="programs"*/}
            {/*      routes={['/workshops', '/retreats', '/trainings', '/sauna']} // '/mentorship',*/}
            {/*      rows={[*/}
            {/*        { id: 1, url: '/workshops', name: 'Workshops' },*/}
            {/*        { id: 2, url: '/retreats', name: 'Retreats' },*/}
            {/*        { id: 3, url: '/trainings', name: 'Trainings' },*/}
            {/*        // { id: 4, url: '/mentorship', name: 'Mentorship' },*/}
            {/*        { id: 5, url: '/sauna', name: 'Sauna' },*/}
            {/*      ]}*/}
            {/*    />*/}
            {/*  </NavbarDropdown>*/}
            {/*</div>*/}
            {/*<div className="Navbar-link-wrapper">*/}
            {/*  <Link*/}
            {/*    to="/tribune"*/}
            {/*    title="Go to tribune"*/}
            {/*    className={`navbar-link-sk with-hover-border ${*/}
            {/*      pathname.includes('tribune') ? "active" : ""*/}
            {/*    }`}*/}
            {/*  >*/}
            {/*    Tribune*/}
            {/*  </Link>*/}
            {/*</div>*/}
          </div>
          <div>
            <Link to="/" title="Visit the main page" className="logo-wrapper">
              <img src={logo} alt="SKY TING" className="nav-logo" />
              <h1>SKY TING</h1>
            </Link>
          </div>
          <div className="Navbar-rightLinkContainer">
            <div className="Navbar-link-wrapper">
              <Link
                to="/shop"
                title="Go to shop"
                className={`navbar-link-sk with-hover-border ${
                  pathname === "/shop" ? "active" : ""
                }`}
              >
                Shop
              </Link>
            </div>
            {/*<div className="Navbar-link-wrapper">*/}
            {/*  <Link*/}
            {/*    to="/pricing"*/}
            {/*    title="Visit the pricing page"*/}
            {/*    className={`navbar-link-sk with-hover-border ${pathname === '/pricing' ? 'active' : ''}`}*/}
            {/*  >*/}
            {/*    Pricing*/}
            {/*  </Link>*/}
            {/*</div>*/}

            <div className="Navbar-link-wrapper">
              {this.isCurrentPath([
                "/teachers",
                "/locations",
                "/press",
                // "/friends",
                "/faq"
                // "/credits"
              ]) && <div className="arrow-down" />}
              <NavbarDropdown
                name="about"
                withArrow
                borderOnHover
                active={
                  this.isCurrentPath([
                    "/teachers",
                    // "/locations",
                    "/press",
                    // "/friends",
                    "/faq"
                    // "/credits"
                  ])
                    ? "active"
                    : ""
                }
              >
                About
                <NavbarDropdownLinks
                  name="about"
                  routes={[
                    "/teachers",
                    // "/locations",
                    "/press",
                    // "/friends",
                    "/faq"
                    // "/credits"
                  ]}
                  rows={[
                    { id: 1, url: "/teachers", name: "Teachers" },
                    // { id: 2, url: "/locations", name: "Locations" },
                    { id: 3, url: "/press", name: "Press" },
                    // { id: 4, url: "/friends", name: "Friends" },
                    { id: 5, url: "/faq", name: "FAQ" }
                    // { id: 6, url: "/credits", name: "Credits" }
                  ]}
                />
              </NavbarDropdown>
            </div>
            <div
              className={`Navbar-link-wrapper ${
                !(user && user.id) ? "cart-margin-right" : ""
              }`}
            >
              <NavbarDropdown
                name="cart_items"
                withArrow={false}
                className={`bag-dropdown-container ${
                  emptyCart ? "empty-cart" : ""
                }`}
              >
                <BagItemsCounter />
                <Bag />
              </NavbarDropdown>
            </div>
            {this.renderUserButtons()}
          </div>
        </div>

        <NavbarMobile
          user={user}
          colorTheme={colorTheme}
          emptyCart={emptyCart}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      logout
    },
    dispatch
  );

const mapStateToProps = state => ({
  user: state.user,
  services: state.balance.services,
  cartItems: state.cart.items,
  unauthorizedCartItems: state.unauthorizedCart.items,
  isAuthenticated: isAuthenticated(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavbarNew);
