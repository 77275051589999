import superagent from 'superagent';

const API_ENDPOINT = '/users/cards';

const getStoredCreditCard = () =>
  superagent.get(API_ENDPOINT).set('Accept', 'application/json');

const deleteCard = user_id =>
  superagent
    .delete(`${API_ENDPOINT}/${user_id}`)
    .set('Accept', 'application/json')
    .send();

export default { getStoredCreditCard, deleteCard };
