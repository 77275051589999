import React, {Component, Fragment} from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { openRegisterPopup, closeRegisterPopup } from '../../actions/register_popup';
import { userRegister } from '../../actions/user_register';
import RegisterFormStep1 from '../register_form_step1_new';
import RegisterFormStep2 from '../register_form_step2';

const basicFields = [
  'first_name',
  'last_name', 'email', 'mobile_phone', 'birth_date',
  'password',
  'photo_url',
  'address_line1',
  'address_line2',
  'city',
  'state',
  'country',
  'postal_code',
  'referred_by',
  'referred_by_others',
  'join_newsletter'
]

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 'basic',
      basicInfo: null,
      paymentInfo: null
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.error && typeof (newProps.error) === 'object') {
      if (Object.keys(newProps.error).some(r => basicFields.indexOf(r) >= 0)) {
        this.setState({ step: 'basic' });
      }
    }
  }

  goToSecondStep = (basicInfo) => {
    this.setState({ basicInfo, step: 'payment' });
  }

  goToFirstStep = (paymentInfo) => {
    this.setState({ paymentInfo, step: 'basic' });
  }

  registerUser = (userParams) => {
    this.props.userRegister(userParams)
      .then(res => {
        this.props.changeStep('lesson');
        this.props.continueBooking();
      })
      .catch(err => {
        console.log(err)
      })
  }

  render() {
    const { step, basicInfo, paymentInfo } = this.state;

    const { error, countries } = this.props;

    return (
      <div>
        <Fragment>
          { step === 'payment' ? (
            <Fragment>
              <RegisterFormStep2
                goBack={(paymentInfo) => this.goToFirstStep(paymentInfo)}
                title="register"
                registerUser={(userParams) => this.registerUser(userParams)}
                basicInfo={basicInfo}
                paymentInfo={paymentInfo}
              />
            </Fragment>
          ) : (
            <RegisterFormStep1
              basicInfo={basicInfo}
              title="register"
              goToSecondStep={(basicInfo) => this.goToSecondStep(basicInfo)}
              changeStep={(step) => this.setState({ step })}
              backendErrors={error}
              countries={countries}
            />
          )}
        </Fragment>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  error: state.user_register && state.user_register.error,
  countries: state.countries,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      openRegisterPopup,
      closeRegisterPopup,
      userRegister,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps)(Register)