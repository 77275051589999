import React, { Component, Fragment } from "react";
import { Collapse } from "react-collapse";
import queryString from "query-string";
import { Link } from "react-router-dom";
import superagent from "superagent";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { history } from "../../store/configureStore";
import scrollToTop from "../../helpers/scrollToTop";
import VideosGrid from "../../components/videos_grid";
import filterIcon from "../../../../assets/images/icons/filter_blue.svg";
import arrowLong from "../../../../assets/images/icons/arrow_long.png";
import RenderLoading from "../../components/render_loading";
import HomeTvSignupBanner from "../../components/home_tv_signup_banner";
import { fetchUserTvSubscription } from "../../actions/user_subscriptions";

class CategoryPageLayout extends Component {
  state = {
    selectedFilters: [], // [{ name: 'level', value: 'medium' }}]
    showFilters: false,
    screenWidth: window.innerWidth,
    filters: [],
    page: 0,
    isLoadingVideos: true,
    videos: [],
    videosError: null,
    loadingSubscription: false
  };

  componentDidMount() {
    scrollToTop();
    window.addEventListener("resize", this.updateDimensions);
    this.getFilters();
    this.initialVideosLoad();
    this.prepareFilters();
    this.getSubscription();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  getSubscription = () => {
    const { status } = this.props.subscription;
    if (status === null) {
      this.setState({ loadingSubscription: true});
      return this.props.fetchUserTvSubscription()
        .finally(() => this.setState({ loadingSubscription: false }));
    }
  };

  getFilters = () => {
    return superagent
      .get("/videos_filters")
      .set("Accept", "application/json")
      .query()
      .then(res => {
        this.setState({
          filters: res.body
        });
      });
  };

  initialVideosLoad = () => {
    const searchParams = queryString.parse(window.location.search);
    this.getVideos(
      Object.keys(searchParams).map(key => ({
        name: key,
        value: searchParams[key]
      })),
      0
    );
  };

  updateDimensions = () => {
    this.setState({ screenWidth: window.innerWidth });
  };

  prepareFilters = () => {
    const searchParams = queryString.parse(window.location.search);

    const selectedFilters = [];
    Object.keys(searchParams).map(key => {
      selectedFilters.push({ name: key, value: searchParams[key] });
    });

    this.setState({ selectedFilters });
  };

  getVideos = (filters = [], page = null) => {
    const { type } = this.props;

    this.setState({ isLoadingVideos: true });
    const nextPage = page || this.state.page + 1;

    const params = { video_type: type, page: nextPage };
    filters.forEach(f => {
      if (f.name === "most recent") {
        params.order = f.value;
      } else {
        params[f.name] = f.value;
      }
    });

    return superagent
      .get("/video_sources")
      .set("Accept", "application/json")
      .query(params)
      .then(res => {
        const vids = this.state.videos;
        const newArr =
          nextPage === 1 ? res.body.videos : vids.concat(res.body.videos);

        this.setState({
          page: nextPage,
          videos: newArr,
          isLoadingVideos: false
        });
      })
      .catch(error => {
        this.setState({
          videosError: error.response.body,
          isLoadingVideos: false
        });
      });
  };

  renderFilter = filter => {
    const { selectedFilters } = this.state;

    if (filter.options.length === 0) return;

    const selected = selectedFilters.find(
      selected => selected.name === filter.name
    );

    return (
      <div className="filter-container">
        <select
          className="filter-select"
          id={`${filter.name}Filter`}
          onChange={event => this.onFilterChange(event, filter.name)}
          value={selected ? selected.value : ""}
        >
          <option value="" hidden={selected && selected.value === ""}>
            {selected && selected.value !== "" && filter.name !== "most recent"
              ? "all" : filter.name}
          </option>
          {filter.options.map(option => (
            <option key={option.id} value={option.id}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    );
  };

  clearFilterValue = filterName => {
    const selectedFilters = [...this.state.selectedFilters];

    this.setState(
      {
        selectedFilters: selectedFilters.filter(f => f.name !== filterName)
      },
      () => {
        this.getVideos(this.state.selectedFilters, 1);
      }
    );

    const searchParams = queryString.parse(window.location.search);

    delete searchParams[filterName];
    const stringified = queryString.stringify(searchParams);
    history.push(`?${stringified}`);
  };

  addFilterValue = (filterName, value) => {
    const selectedFilters = [...this.state.selectedFilters];
    const selectedFilter = selectedFilters.find(f => f.name === filterName);

    if (selectedFilter) {
      selectedFilter.value = value;
    } else {
      selectedFilters.push({ name: filterName, value: value });
    }

    this.setState({ selectedFilters }, () => {
      this.getVideos(this.state.selectedFilters, 1);
    });

    const searchParams = queryString.parse(window.location.search);
    if (!searchParams[filterName] || searchParams[filterName] !== value) {
      searchParams[filterName] = value;
      const stringified = queryString.stringify(searchParams);
      history.push(`?${stringified}`);
    }
  };

  onFilterChange = (event, filterName) => {
    const { value } = event.target;
    if (!value || value === "") {
      this.clearFilterValue(filterName);
    } else {
      this.addFilterValue(filterName, value);
    }
  };

  onVideosScroll = () => {
    const { selectedFilters } = this.state;
    this.getVideos(selectedFilters, null);
  };

  checkVideoDuration = (video, filterOption) => {
    const { min } = filterOption;
    const { max } = filterOption;

    return video.duration >= min && video.duration <= max;
  };

  render() {
    const {
      showFilters,
      screenWidth,
      filters,
      videosError,
      isLoadingVideos,
      videos,
      loadingSubscription
    } = this.state;

    const {
      columnsCount,
      hideTeacher,
      category,
      header,
      subscription: { status }
    } = this.props;

    const subscribed = status === 'active';

    return (
      <Fragment>
        {!subscribed && !loadingSubscription && (
          <HomeTvSignupBanner
            header="Practice At Home With the World’s Top Yoga Teachers"
            subheader="Your free trial awaits. Cancel online anytime."
          />
        )}
        <div
          className="tv-page-top-block padding-lr"
        >
          {((category && category.header) || header) && (
            <h2 className="Page-header">
              <Link to="/tv">
                <img
                  src={arrowLong}
                  className="back-btn"
                />
              </Link>
              {category ? category.header : header}
            </h2>
          )}
          {category && category.subheader && (
            <h3 className="page-subtitle">{category.subheader}</h3>
          )}
          <div className="filters-block">
            {screenWidth <= 799 && (
              <div
                className={`filter-button ${showFilters ? "active" : ""}`}
                onClick={() => this.setState({ showFilters: !showFilters })}
              >
                FILTERS
                <img src={filterIcon} />
              </div>
            )}
            <Collapse isOpened={screenWidth > 799 ? true : showFilters}>
              <div className="filters-row">
                {filters.map(f => this.renderFilter(f))}
              </div>
            </Collapse>
          </div>
        </div>
        <div className="padding-lr mb-5">
          { !isLoadingVideos && videos.length === 0 ? (
            <div className="mt-5 mb-5 pt-5 pb-5 Empty-result">
              NO CLASSES
            </div>
          ) : (
            <>
              <InfiniteScroll
                dataLength={videos.length}
                next={() => this.onVideosScroll()}
                hasMore
              >
                <VideosGrid
                  videos={videos}
                  columnsCount={columnsCount}
                  hideTeacher={hideTeacher}
                />
              </InfiniteScroll>
              { isLoadingVideos &&
                <RenderLoading className="mt-5 mb-5" />
              }
              { videosError &&
                <div className="Empty-result mt-5 mb-5">
                  Can't load the videos :( Please try again later
                </div>
              }
            </>
          )}
        </div>
        {!subscribed && !loadingSubscription && (
          <HomeTvSignupBanner
            blue
            header="FREE YOGA"
            subheader="Cancel on or before your 7th day and we won’t charge you anything, why wouldn’t cha?"
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  subscription: state.userTvSubscription,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchUserTvSubscription
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoryPageLayout);
