import superagent from "superagent";
const { detect } = require('detect-browser');
import checkSpeed from "../services/check_speed_new";

export const saveUseragent = () => async () => {
  const speed = await checkSpeed();
  const browser = detect();

  const attributes = {
    name: browser.name,
    os: browser.os,
    type: browser.type,
    version: browser.version,
  }

  if (speed) {
    attributes['speed'] = speed
  }

  return superagent
    .post('/users/useragent')
    .set('Accept', 'application/json')
    .send({ useragent: attributes });
}
