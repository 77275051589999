import {
  FETCH_CREDIT_CARD,
  FETCH_CREDIT_CARD_ERROR,
  FETCH_CREDIT_CARD_SUCCESS
} from '../actions/credit_card';

const INITIAL_STATE = {
  error: null,
  creditCard: null
};

const creditCard = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CREDIT_CARD:
      return {
        ...state
      };
    case FETCH_CREDIT_CARD_ERROR:
      return {
        ...state,
        error: action.error
      };
    case FETCH_CREDIT_CARD_SUCCESS:
      return {
        ...state,
        creditCard: action.creditCard
      };
    default:
      return state;
  }
};

export default creditCard;
