import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import lady from './../../../assets/images/icons/lady_text.png';

export default class MetaTagsComponent extends Component {
  render() {
    const { title, desc, image, url } = this.props;

    return (
      <MetaTags>
        <title>{title}</title>
        <meta name="description" content={desc} />
        <meta name="url" content={url} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={desc} />
        <meta name="twitter:image" content={image || lady} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={desc} />
        <meta property="og:image" content={image || lady} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="SKY TING" />
        <meta property="og:url" content={url ? `https://www.skyting.com/${url}` : 'https://www.skyting.com'} />
      </MetaTags>
    );
  }
}
