import React, { Component } from "react";
import eye from "../../../../assets/images/icons/Eye.svg";

class PasswordInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPassword: true
    };
  }

  render() {
    const { showPassword } = this.state;
    const { handleChange, value, onSubmit, placeholder, name, error, onBlur } = this.props;

    return (
      <div className={`input-with-icon pwd ${error ? 'error' : ''}`}>
        <input
          value={value}
          onChange={handleChange}
          name={name || "password"}
          type={showPassword ? "password" : "text"}
          placeholder={placeholder || "PASSWORD"}
          onKeyPress={e => {
            if (onSubmit && e.key === "Enter") {
              onSubmit();
            }
          }}
          onBlur={onBlur}
        />
        <img
          src={eye}
          onClick={() => this.setState({ showPassword: !showPassword })}
        />
      </div>
    );
  }
}

export default PasswordInput;
