import superagent from "superagent";
export const FETCH_COUNTRIES_SUCCESS = "FETCH_COUNTRIES_SUCCESS";

export const fetchCountries = () => dispatch => {
  return new Promise(async (resolve, reject) => {
    await superagent
      .get("/countries_list")
      .set("Accept", "application/json")
      .send()
      .then(res => {
        dispatch({ type: FETCH_COUNTRIES_SUCCESS, countries: res.body });
        resolve(true);
      })
      .catch(error => {
        reject(error.response.body);
      });
  });
};
