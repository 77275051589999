import superagent from 'superagent';

const API_ENDPOINT = '/users/stripe_portal_sessions';

const createSession = () => {
  return superagent
    .post(API_ENDPOINT)
    .set('Accept', 'application/json')
    .send();
}

export default { createSession };
