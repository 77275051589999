import React, { Component, Fragment } from "react";
import moment from "moment";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchCountries } from "../../actions/countries";
import { updateCurrentUser } from "../../actions/user_update";
import MboUserForm from "./mbo_user_form";
import registerReasons from "../../data/register_reasons";
import ComponentWithLoading from "../component_with_loading";

class EditProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingCountries: true,
      loading: false,
      error: null,
      success: false
    };
  }

  componentDidMount() {
    this.props
      .fetchCountries()
      .then(res => this.setState({ loadingCountries: false }))
      .catch(err => this.setState({ loadingCountries: false }));
  }

  updateUserInfo = userParams => {
    const { updateCurrentUser, afterUserUpdate } = this.props;
    this.setState({ loading: true, error: null });

    const {
      first_name,
      last_name,
      mobile_phone,
      birth_date,
      address_line1,
      address_line2,
      city,
      state,
      country,
      postal_code,
      referred_by,
      referred_by_others
    } = userParams;

    const reason = registerReasons.find(el => el.id.toString() === referred_by);
    const birthStr = moment(birth_date).format("DD/MM/YYYY");

    const params = {
      first_name,
      last_name,
      mobile_phone,
      birth_date: birthStr,
      address_line1,
      address_line2,
      city,
      state,
      country,
      postal_code
    };

    if (reason || referred_by_others) {
      params.referred_by = reason && reason.id !== 10 ? reason.value : referred_by_others
    }

    updateCurrentUser({ user: params })
      .then(res => {
        this.setState({
          loading: false
        });
        afterUserUpdate();
      })
      .catch(err => {
        this.setState({
          loading: false,
          error: err
        });
      });
  };

  render() {
    const { loadingCountries, loading, error } = this.state;

    const { pageContent, countries, user } = this.props;

    if (!user || !user.id)
      return <div className="secondary-message">PLEASE LOG IN</div>;

    return (
      <Fragment>
        <div className="step">
          {pageContent.billing_address_step || "STEP 2"}
        </div>

        <div className="signup-page-subtitle">BILLING ADDRESS</div>
        <hr />
        <div className="mt-50 form-container">
          <ComponentWithLoading isLoading={loadingCountries}>
            <MboUserForm
              countries={countries}
              updadteUser={values => this.updateUserInfo(values)}
              user={user}
              loading={loading}
              backendErrors={error}
            />
          </ComponentWithLoading>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  countries: state.countries
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchCountries,
      updateCurrentUser
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditProfile);
