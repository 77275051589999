import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Popup from '../popup.jsx';
import { Link } from 'react-router-dom';

import LoginForm from '../lesson_booking/login_form';
import RegistrationForm from "../lesson_booking/register";
import PaymentForm from "../lesson_booking/payment_form";
import Billing from "../lesson_booking/billing";

import { isAuthenticated } from '../../reducers/user';
import { fetchUserSchedule } from "../../actions/user_schedule";
import { getBalance } from '../../actions/balances';

import Loading from "../../../../assets/images/icons/yinyang_icon.png";

class ProgramPopup extends Component {
  componentWillMount() {
    document.body.classList.add('overflow-hidden');
  }

  componentWillUnmount() {
    document.body.classList.remove('overflow-hidden');
  }

  renderCurrentStep = () => {
    const {
      lessonId,
      step,
      selectedService,
      changeStep,
      selectService,
      bookLesson,
    } = this.props

    switch(step) {
      case 'bookingProgress':
        return this.renderBookingProgress();
      case 'login':
        return <LoginForm
          changeStep={s => changeStep(s)}
          continueBooking={() => bookLesson(lessonId)}
        />
      case 'register':
        return <RegistrationForm
          changeStep={s => changeStep(s)}
          continueBooking={() => bookLesson(lessonId)}
        />
      case 'payment':
        return <PaymentForm
          lessonId={lessonId}
          changeStep={s => changeStep(s)}
          selectService={selectedService => selectService(selectedService)}
        />
      case 'billing':
        return <Billing
          continueBooking={() => bookLesson(lessonId)}
          activeService={selectedService}
        />
      case 'success':
        return this.renderSuccess();
      case 'successWaitlist':
        return this.renderSuccessWaitlist();
      case 'error':
        return this.renderError();
      default:
        return this.renderBookingProgress();
    }
  }

  changeStep = (step) => {
    this.setState({ step })
  }

  renderBookingProgress = () => {
    return(
      <div className="Lesson">
        <div className="Lesson-title">BOOKING</div>
        <div className="inline-loading mt-5 mb-5">
          <img src={Loading} height="21" width="21"/>
          <div>PLEASE WAIT...</div>
        </div>
      </div>
    )
  }

  renderError = () => {
    const { bookingError, lessonType, bookLesson, lessonId, showWaitlist } = this.props;

    return (
      <div className="Lesson">
        <div className="Lesson-title mb-5">BOOKING</div>
        { bookingError &&
          <div className="error mb-5 mt-3">
            { bookingError.message || "Something went wrong, please try again" }
          </div>
        }
        { showWaitlist &&
          <div className="error mb-5 mt-3">The class is full</div>
        }

        <button
          className="Button mb-4"
          onClick={() => bookLesson(lessonId, showWaitlist)}
        >
          { showWaitlist ? "Join Waitlist" : "Try again" }
        </button>
        <Link to={`/${lessonType}`} title={`Go to ${lessonType}`} className="Button Button--secondary">
          Back to {lessonType}
        </Link>
      </div>
    )
  }

  renderSuccess = () => {
    const { lessonType } = this.props;
    return(
      <div className="Lesson">
        <div className="Lesson-title">SUCCESS</div>
        <div className="mt-5 mb-5 text-uppercase">Lesson was successfully booked</div>
        <Link to={`/${lessonType}`} title={`Go to ${lessonType}`} className="Button Button--secondary">
          Back to {lessonType}
        </Link>
      </div>
    )
  }

  renderSuccessWaitlist = () => {
    const { lessonType } = this.props;
    return(
      <div className="Lesson">
        <div className="Lesson-title">SUCCESS</div>
        <div className="mt-5 mb-5 text-uppercase">You are added to the waiting list!</div>
        <Link to={`/${lessonType}`} title={`Go to ${lessonType}`} className="Button Button--secondary">
          Back to {lessonType}
        </Link>
      </div>
    )
  }

  render() {
    const { lessonId, closePopup } = this.props

    if (!lessonId) return <div />
    return (
      <Popup
        key={lessonId}
        id={lessonId}
        isOpen
        onClose={() => closePopup()}
      >
        {this.renderCurrentStep()}
      </Popup>
    )
  }
}

const mapStateToProps = state => ({
  isAuthenticated: isAuthenticated(state),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchUserSchedule,
      getBalance,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(ProgramPopup);
