import React from "react";

const ButtonWithIcon = (props) => {
  const { text, iconSrc, withTriangle, disabled, onClick, classNamesProp, type } = props;

  return (
    <button
      className={`Button with-icon ${classNamesProp || ''}`}
      disabled={disabled}
      onClick={onClick}
      type={ type || "button"}
    >
      <span>{text}</span>
      { iconSrc &&
        <img src={iconSrc} />
      }

      { withTriangle && <span className="white-triangle" /> }
    </button>
  );
};

export default ButtonWithIcon;
