import { SELECT_LESSON, DESELECT_LESSON } from '../actions/active_lesson';

const activeLesson = (state = null, action) => {
  switch (action.type) {
    case SELECT_LESSON:
      return action.lesson;
    case DESELECT_LESSON:
      return null;
    default:
      return state;
  }
}

export default activeLesson;
