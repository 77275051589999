import React  from 'react';

export const formattedLocationName = (name) => {
  switch(name) {
    case 'SKY TING - TRIBECA':
      return 'Tribeca'
    case 'SKY TING - DOMINO':
      return 'Domino'
    case 'SKY TING - CHINATOWN':
      return 'Chinatown'
    default:
      return name
  }
}
