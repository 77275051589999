import Api from "../api/user_subscriptions";
import superagent from "superagent";

export const FETCH_USER_SUBSCRIPTIONS = "FETCH_USER_SUBSCRIPTIONS";
export const FETCH_USER_SUBSCRIPTIONS_SUCCESS =
  "FETCH_USER_SUBSCRIPTIONS_SUCCESS";
export const FETCH_USER_SUBSCRIPTIONS_ERROR = "FETCH_USER_SUBSCRIPTIONS_ERROR";

export const fetchUserSubscriptions = () => dispatch => {
  dispatch({ type: FETCH_USER_SUBSCRIPTIONS });

  Api.fetchUserSubscriptions()
    .then(res =>
      dispatch({
        type: FETCH_USER_SUBSCRIPTIONS_SUCCESS,
        subscriptions: res.body
      })
    )
    .catch(error => dispatch({ type: FETCH_USER_SUBSCRIPTIONS_ERROR, error }));
};

export const fetchUserTvSubscription = () => dispatch => {
  dispatch({ type: "FETCH_TV_SUBSCRIPTION" });

  return new Promise(async (resolve, reject) => {
    await superagent
      .get("/users/tv_subscription")
      .set("Accept", "application/json")
      .send()
      .then(res => {
        dispatch({
          type: "SET_TV_SUBSCRIPTION",
          subscription: res.body.subscription,
          status: res.body.status,
        });
        resolve(res.body)
      })
      .catch(error => {
        reject(error.response.body);
      });
  })
};
