import {
  OPEN_BILLING_POPUP,
  CLOSE_BILLING_POPUP,
  OPEN_BILLING_FORM,
  CLOSE_BILLING_FORM,
  SUBMIT_BILLING_FORM,
  SUBMIT_BILLING_FORM_ERROR,
  SUBMIT_BILLING_FORM_SUCCESS,
  BILLING_STORED_CARD_LAST_4,
  DROP_BILLING_LAST_4,
} from '../actions/billing';

const INITIAL_STATE = {
  last_four: null,
  show: false,
  showBillingForm: false,
  error: null,
  loading: false,
  formData: {
    card_number: null,
    exp_year: null,
    exp_month: null,
    cvv: null,
    card_holder: null
  }
};

const billing = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OPEN_BILLING_POPUP:
      return {
        ...state,
        show: true
      };
    case CLOSE_BILLING_POPUP:
      return {
        ...state,
        show: false
      };
    case OPEN_BILLING_FORM:
      return {
        ...state,
        showBillingForm: true
      };
    case CLOSE_BILLING_FORM:
      return {
        ...state,
        showBillingForm: false
      };
    case SUBMIT_BILLING_FORM:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUBMIT_BILLING_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        formData: {
          card_number: null,
          exp_year: null,
          exp_month: null,
          cvv: null,
          card_holder: null
        }
      };
    case SUBMIT_BILLING_FORM_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case BILLING_STORED_CARD_LAST_4:
      return {
        ...state,
        last_four: action.last_four
      };
    case DROP_BILLING_LAST_4:
      return {
        ...state,
        last_four: null
      };
    default:
      return state;
  }
}

export default billing;
