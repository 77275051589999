import React, { Fragment, Component } from 'react'
import Popup from "../components/popup";
import MboUserForm from "./authentication/mbo_user_form";
import {bindActionCreators} from "redux";
import {fetchCountries} from "../actions/countries";
import {updateCurrentUser} from "../actions/user_update";
import {connect} from "react-redux";
import registerReasons from "../data/register_reasons";
import moment from "moment";
import ComponentWithLoading from "./component_with_loading";

class AccountEditNew extends Component {
  state = {
    loading: false,
    loadingCountries: true,
  }

  componentWillMount() {
    document.body.classList.add('overflow-hidden');
  }

  componentWillUnmount() {
    document.body.classList.remove('overflow-hidden');
  }

  componentDidMount() {
    this.props.fetchCountries()
      .then(res => this.setState({ loadingCountries: false }))
      .catch(err => this.setState({ loadingCountries: false }));
  }

  updateUserInfo = (userParams) => {
    const { updateCurrentUser, closePopup } = this.props;
    this.setState({ loading: true, error: null });

    const { referred_by, referred_by_others, birth_date } = userParams;

    const reason = registerReasons.find(el => el.id.toString() === referred_by)
    const birthStr = moment(birth_date).format("DD/MM/YYYY")

    userParams.birth_date = birthStr;
    userParams.referred_by = reason && reason.id !== 10 ? reason.value : referred_by_others

    updateCurrentUser({ user: userParams })
      .then(res => {
        this.setState({
          loading: false
        });
        closePopup();
      })
      .catch(err => {
        this.setState({
          loading: false,
          error: err
        });
      });
  }

  render() {
    const { loading, loadingCountries } = this.state;
    const { countries, user, closePopup, userSubscriptions, openStripePortal } = this.props;

    const showUpdateCard = !!(userSubscriptions.find(s => s.type === 'tv'))

    return (
      <Popup key={1} id={1} isOpen onClose={closePopup}>
        <div
          className="Registration-title mb-5"
        >
          EDIT ACCOUNT
        </div>
        <ComponentWithLoading isLoading={loadingCountries}>
          <MboUserForm
            countries={countries}
            updadteUser={values => this.updateUserInfo(values)}
            user={user}
            loading={loading}
            showUpdateCard
            openStripePortal={openStripePortal}
          />
        </ComponentWithLoading>

        { showUpdateCard &&
          <Fragment>
            <br />
            <br />
            <div className="Registration-form-subheader text-uppercase">
              Payment method
            </div>
            <button className="Button thin-button w-100" onClick={() => openStripePortal()}>
              UPDATE PAYMENT CARD
            </button>
          </Fragment>
        }
      </Popup>
    )
  }
}

const mapStateToProps = state => ({
  countries: state.countries,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchCountries,
      updateCurrentUser
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountEditNew);