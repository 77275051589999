import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import EmailInput from "../authentication/email_input";
import PasswordInput from "../authentication/password_input";
import ButtonWithIcon from "../button_with_icon";
import ComponentWithLoading from "../component_with_loading";
import validatePassword from "../../helpers/validate_password";
import { userRegister } from "../../actions/user_register";
import arrowRightWhite from "./../../../../assets/images/icons/arrow_right_white.svg";
import checkboxChecked from "../../../../assets/images/icons/Checkbox_checked.svg";

class LessonRegisterForm extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      mode: 'login',
      error: null,
      email: "",
      password: "",
      confirm_password: "",
      confirmPasswordError: false,
      invalidPassword: false,
      authError: null,
      cameFromGetStarted: false
    };
  }

  signup = () => {
    const { email, password } = this.state;
    const { changeStep } = this.props;

    this.setState({ loading: true, signupError: null });

    this.props
      .userRegister({
        user: { email: email.toLowerCase(), password },
      })
      .then(res => {
        this.setState({ loading: false });
        changeStep('purchase');
      })
      .catch(err => {
        if (err && err.message === "This email already exists") {
          this.setState({ loading: false });
          changeStep('login');
        } else {
          this.setState({ loading: false, signupError: err });
        }
      });
  };

  handleChange = event => {
    const { invalidPassword, confirmPasswordError, authError } = this.state;

    this.setState({ [event.target.name]: event.target.value });
    if (invalidPassword || confirmPasswordError || authError) {
      this.setState({
        invalidPassword: false,
        authError: false
      });
    }
  };

  checkEmail = () => {
    const { email } = this.state;
    this.setState({ loading: true });

    return superagent
      .get(`/users/check_email?email=${email.toLowerCase()}`)
      .set("Accept", "application/json")
      .then(res => {
        if (res.body.result === "Success") {
          this.props.changeStep("login");
          this.setState({ loading: false, cameFromGetStarted: true });
        } else {
          this.props.changeStep("register");
          this.setState({ loading: false, cameFromGetStarted: true });
        }
      })
      .catch(error => {
        this.setState({
          error: error.response.body,
          loading: false
        });
      });
  };

  matchPasswordConfirmation = () => {
    const { password, confirm_password, confirmPasswordError } = this.state;

    const validPassword = validatePassword(password);

    if (!validPassword) {
      this.setState({ invalidPassword: true });
    } else {
      if (confirm_password) {
        if (password !== confirm_password) {
          this.setState({ confirmPasswordError: true });
        } else {
          this.setState({ confirmPasswordError: false });
        }
      } else {
        if (!confirm_password && confirmPasswordError) {
          this.setState({ confirmPasswordError: false });
        }
      }
    }
  };

  renderSignup = () => {
    const {
      password,
      email,
      confirm_password,
      termsChecked,
      loading,
      confirmPasswordError,
      invalidPassword,
      signupError,
      cameFromGetStarted
    } = this.state;

    const buttonDisabled =
      !email ||
      !password ||
      !confirm_password ||
      !termsChecked ||
      confirmPasswordError ||
      invalidPassword;

    if (this.props.isTvSignup) {
      addTagManagerEvent(window.location.pathname, "New");
    }

    return (
      <div>
        <EmailInput
          handleChange={this.handleChange}
          value={email}
          onSubmit={() => this.checkEmail()}
          disabled={!!email && cameFromGetStarted}
          onInputClick={() => console.log('signup')}
        />
        <PasswordInput
          handleChange={this.handleChange}
          value={password}
          placeholder="SET PASSWORD"
          name="password"
          error={invalidPassword}
          onBlur={() => this.matchPasswordConfirmation()}
        />
        <PasswordInput
          handleChange={this.handleChange}
          value={confirm_password}
          placeholder="CONFIRM PASSWORD"
          name="confirm_password"
          error={confirmPasswordError}
          onBlur={() => this.matchPasswordConfirmation()}
        />
        {signupError && (
          <div className="input-error-red">{signupError.message}</div>
        )}

        {invalidPassword && (
          <div className="input-error-red">
            Password must contain at least 8 characters and must include both
            letters and numbers
          </div>
        )}

        {confirmPasswordError && (
          <div className="input-error-red">
            Password confirmation does not match
          </div>
        )}

        <ComponentWithLoading isLoading={loading}>
          <ButtonWithIcon
            classNamesProp="high-75 confirm-button ga-tv-step-1"
            text="CONTINUE"
            iconSrc={
              email && password && confirm_password && termsChecked
                ? arrowRightWhite
                : null
            }
            disabled={buttonDisabled}
            onClick={() => this.signup()}
          />
        </ComponentWithLoading>
      </div>
    );
  };

  render() {
    const { changeStep } = this.props;
    const { mode, termsChecked } = this.state;

    return (
      <Fragment>
        <Fragment>
          <div className="signup-page login-popup">
            <div className="step">Step 1</div>
            <div className="signup-page-subtitle">SIGN UP</div>
            <div className="secondary-message">
              JUST A FEW MORE STEPS UNTIL YOU'RE PRACTICING
            </div>
            <hr />
            <div className="mt-50 form-container">
              {this.renderSignup()}
            </div>
            <div>
              <div className="legal-text-container">
                <div
                  className="checkbox-container"
                  onClick={() =>
                    this.setState({ termsChecked: !termsChecked })
                  }
                >
                  {termsChecked ? (
                    <img src={checkboxChecked} className="checked" />
                  ) : (
                    <div className="unchecked">&nbsp;</div>
                  )}
                </div>
                <div
                  className="legal-text"
                >
                  <p>I acknowledge and agree that my purchase will be subject&nbsp;to Sky Ting’s&nbsp;
                    <Link to="/terms" target="_blank">Terms of Use</Link>
                    &nbsp;and&nbsp;
                    <Link to="/privacy" target="_blank">Privacy Notice</Link>.</p>
                </div>
              </div>

              <div className="bottom-links-row">
                <div
                  className="link underline"
                  onClick={() => changeStep("login")}
                >
                  Already have an account?
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      userRegister
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(LessonRegisterForm)