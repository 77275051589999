import Api from '../api/billing';

import { deselectService } from './active_service';
import { FETCH_CURRENT_USER_SUCCESS } from './user';
import { RESET_CHECKOUT_SERVICE_ERROR } from "./checkout";

export const OPEN_BILLING_POPUP = 'OPEN_BILLING_POPUP';
export const CLOSE_BILLING_POPUP = 'CLOSE_BILLING_POPUP';
export const OPEN_BILLING_FORM = 'OPEN_BILLING_FORM';
export const CLOSE_BILLING_FORM = 'CLOSE_BILLING_FORM';

export const SUBMIT_BILLING_FORM = 'SUBMIT_BILLING_FORM';
export const SUBMIT_BILLING_FORM_ERROR = 'SUBMIT_BILLING_FORM_ERROR';
export const SUBMIT_BILLING_FORM_SUCCESS = 'SUBMIT_BILLING_FORM_SUCCESS';
export const BILLING_STORED_CARD_LAST_4 = 'BILLING_STORED_CARD_LAST_4';
export const DROP_BILLING_LAST_4 = 'DROP_BILLING_LAST_4';

export const openBillingPopup = () => (dispatch) => {
  dispatch({ type: OPEN_BILLING_POPUP });
}
export const closeBillingPopup = () => (dispatch) => {
  dispatch({ type: CLOSE_BILLING_POPUP })
  dispatch({ type: DROP_BILLING_LAST_4 })
  dispatch({ type: RESET_CHECKOUT_SERVICE_ERROR })
  deselectService()(dispatch)
}

export const openBillingForm = () => dispatch => dispatch({ type: OPEN_BILLING_FORM });
export const closeBillingForm = () => dispatch => dispatch({ type: CLOSE_BILLING_FORM });
export const dropBillingLastFour = () => dispatch => dispatch({ type: DROP_BILLING_LAST_4 });

export const submitBillingForm = formData => (dispatch) => {
  dispatch({ type: SUBMIT_BILLING_FORM });

  Api.submitBillingForm(formData)
    .then((res) => {
      dispatch({ type: FETCH_CURRENT_USER_SUCCESS, user: res.body })
      dispatch({ type: SUBMIT_BILLING_FORM_SUCCESS, payload: res.body });
      dispatch({ type: CLOSE_BILLING_FORM });
    })
    .catch(error => dispatch({ type: SUBMIT_BILLING_FORM_ERROR, error: error.response.body }));
};

export const addBillingLastFour = last_four => (dispatch) => {
  dispatch({ type: BILLING_STORED_CARD_LAST_4, last_four: last_four });
};
