import React, { Component } from 'react';
import PropTypes from 'prop-types'
import onClickOutside from "react-onclickoutside";
import classNames from 'classnames';
import { getParamByName } from "../helpers/get_param_by_name"
import { Collapse } from 'react-collapse';

class DropdownSelect extends Component {

  constructor(props) {
    super(props);
    this.state = {
      listVisible: false,
      selected: null,
      isReadParams: false,
    };
  }

  componentWillReceiveProps(newProps) {
    const { name, filters, optionId, options } = newProps
    const { isReadParams } = this.state
    const { selectOption } = this

    if (!isReadParams) {
      const value = getParamByName(name)
      const option = options.find( option =>
        option[optionId] === value
      )
      if (option) {
        selectOption(option)
        this.setState({
          isReadParams: true
        })
      }
    }

    if ( filters && name && filters[name] ) {
      this.setState({
        selected: options.find( option =>
          option[optionId] === filters[name]
        )
      })
    } else {
      this.setState({
        selected: null
      })
    }
  }

  selectOption = (selectedOption) => {
    const { optionId, name } = this.props

    this.props.onChange(
      {
        target: {
          type: 'text',
          name: name,
          value: selectedOption[optionId]
        }
      }
    )
    this.handleClickOutside()
  }

  toggleList = () => {
    this.setState({
      listVisible: !this.state.listVisible
    });
  };

  resetSelected = (event) => {
    event.stopPropagation();
    this.resetFilter()
  }

  resetFilter = () => {
    const { onChange, name } = this.props

    onChange(
      {
        target: {
          type: 'text',
          name: name,
          value: null,
        }
      }
    )
  }

  handleClickOutside = evt => {
    this.setState({
      listVisible: false
    })
  };

  componentWillUnmount() {
    this.resetFilter()
  }

  optionClass = (option) => {
    const { optionId } = this.props;

    if (this.props.disabledOptions) {
      const ids = this.props.disabledOptions.map(o => o[optionId]);
      return (ids.includes(option[optionId]) ? 'Dropdown-option disabled' : 'Dropdown-option');
    } else {
      return 'Dropdown-option';
    }
  }

  render() {
    const { listVisible, selected } = this.state;
    const { options, icon, optionLabel, optionId, placeholder, hoverIcon, isCollapse, hideResetIcon } = this.props;

    const IconClass = classNames({
      'Dropdown-select-icon': true,
      'Hover-hide': (hoverIcon ? true : false)
    });

    const DropdownClass = classNames({
      'Dropdown-select-container': true,
      'is-open': (listVisible ? true : false)
    });

    return (
      <div className="Dropdown-select">
        <div className={DropdownClass} onClick={this.toggleList}>
          { icon &&
            <img src={icon} className={IconClass} height="19" alt="open"/>
          }
          { hoverIcon &&
            <img src={hoverIcon} className="Hover-icon" height="19" alt="open" />
          }
          <span className="Dropdown-select-text">{selected ? selected[optionLabel] : (placeholder ? placeholder : 'select')}</span>
          { selected && !hideResetIcon &&
            <span className="Reset-icon" onClick={this.resetSelected} >&#10005;</span>
          }
          <div className="Select-arrow-container">
            <span className="select-arrow-down"></span>
          </div>
        </div>

        { isCollapse ? (
          <Collapse isOpened={listVisible}>
            <div className="Dropdown-list" >
              {options.map((option, index) => (
                <div className="Dropdown-option"
                     onClick={() => this.selectOption(option)}
                     key={option[optionId]}
                >
                  {option[optionLabel]}
                </div>
              ))}
            </div>
          </Collapse>
        ) : (
          <div className={`Dropdown-list ${listVisible ? 'd-block' : 'd-none'}`}>
            {options.map((option) => (
              <div className={this.optionClass(option)}
                   onClick={() => this.selectOption(option)}
                   key={option[optionId]}
              >
                {option[optionLabel]}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}

DropdownSelect.propTypes = {
  options: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  optionId: PropTypes.string.isRequired,
  optionLabel: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
}

DropdownSelect.defaultProps = {
  options: [],
  optionId: 'id',
  optionLabel: 'name',
  name: '',
};

export default onClickOutside(DropdownSelect);