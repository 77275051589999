import {
  FETCH_MENTORSHIP,
  FETCH_MENTORSHIP_ERROR,
  FETCH_MENTORSHIP_SUCCESS
} from '../actions/mentorship';

const INITIAL_STATE = {
  mentorship: [],
  error: null,
  isFetching: false
};

const mentorship = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_MENTORSHIP:
      return {
        ...state,
        isFetching: true
      };
    case FETCH_MENTORSHIP_ERROR:
      return {
        ...state,
        error: action.error,
        isFetching: false
      };
    case FETCH_MENTORSHIP_SUCCESS:
      return {
        ...state,
        mentorship: action.mentorship,
        error: null,
        isFetching: false
      };
    default:
      return state;
  }
};
export const isFetching = state => state.mentorship.isFetching;

export default mentorship;
