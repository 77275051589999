import {
  FETCH_SCHEDULE,
  FETCH_SCHEDULE_ERROR,
  FETCH_SCHEDULE_SUCCESS
} from '../actions/schedule';

const INITIAL_STATE = {
  staff: [],
  error: null,
  filters: {},
  lessons: [],
  locations: [],
  session_types: [],
  isFetching: false
};

const schedule = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_SCHEDULE:
      return {
        ...state,
        isFetching: true
      };
    case FETCH_SCHEDULE_ERROR:
      return {
        ...state,
        error: action.error,
        isFetching: false
      };
    case FETCH_SCHEDULE_SUCCESS:
      return {
        ...state,
        ...action.schedule,
        error: null,
        isFetching: false
      };
    default:
      return state;
  }
};

export const isFetching = state => state.schedule.isFetching;

export default schedule;
