import {
  FETCH_SHIPMENT_PRODUCTS,
  FETCH_SHIPMENT_PRODUCTS_ERROR,
  FETCH_SHIPMENT_PRODUCTS_SUCCESS
} from '../actions/shipment_products';

const INITIAL_STATE = {
  shipmentProducts: [],
  carbon: null,
  error: null,
  isFetching: false
};

const shipmentProducts = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_SHIPMENT_PRODUCTS:
      return {
        ...state,
        isFetching: true
      };
    case FETCH_SHIPMENT_PRODUCTS_ERROR:
      return {
        ...state,
        error: action.error,
        isFetching: false
      };
    case FETCH_SHIPMENT_PRODUCTS_SUCCESS:
      return {
        ...state,
        shipmentProducts: action.res.shipment_products,
        carbon: action.res.carbon,
        error: null,
        isFetching: false
      };
    default:
      return state;
  }
};
export const isFetching = state => state.shipmentProducts.isFetching;

export default shipmentProducts
