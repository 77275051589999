import superagent from 'superagent';

const API_ENDPOINT = '/users/past_classes';

const fetchUserPastClasses = () => {
  return superagent
    .get(API_ENDPOINT)
    .set('Accept', 'application/json');
};

export default { fetchUserPastClasses };
