import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { fetchProducts } from "../actions/product";

import RenderLoading from "../components/render_loading.jsx";
import RenderError from "../components/render_error.jsx";
import Product from "../components/product_new.jsx";
import { productPriceWithDiscount } from "../helpers/product_price_with_discount";
import scrollToTop from "../helpers/scrollToTop";
import { getParamByName } from "../helpers/get_param_by_name";
import MetaTagsComponent from "../components/meta_tags_component";
import giftCardImg from "./../../../assets/images/gift_card.png";
import ShopPromoSpots from "../components/shop_promo_spots";
import { Link } from "react-router-dom";
import hand from "../../../assets/images/icons/hand.svg";
import {history} from "../store/configureStore";
import superagent from "superagent";

class ShopPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFetching: true,
      categoryFilterOptions: [{ id: 'all', name: 'ALL' }],
      categoryFilter: {id: 'all', name: 'ALL'},
      subtitle: null,
      promoSliderIsLoading: true
    };
  }

  getProducts = () => {
    this.props
      .fetchProducts()
      .then(res => {
        this.prepareCategoryFilter(res.products);
        this.fetchShopPageContent();
        this.setState({ isFetching: false });
      })
      .catch(err => this.setState({ isFetching: false }));
  };

  componentDidMount() {
    scrollToTop();
    this.getProducts();
  }

  fetchShopPageContent = () => {
    return superagent
      .get("/shop_pages")
      .set('Accept', 'application/json')
      .send()
      .then(res =>
        this.setState({
          subtitle: res.body.subtitle,
        }))
      .catch(error => {
        console.log('Shop page content load error')
      });
  }

  setCategoryFilterByParams = (shopCategories) => {
    const category_name = getParamByName("category", "string");
    if (category_name && shopCategories.length > 0) {
      const selectedCategory = shopCategories.find(c => c.id === category_name.toLowerCase())
      if (!selectedCategory) return;

      this.setState({ categoryFilter: selectedCategory })
    }
  }

  prepareCategoryFilter = (products) => {
    const options = [{ id: 'all', name: 'ALL' }];
    const shopCategories = _.flatten(products.map(p => p.shop_categories.map(c => {
      return { id: c.slug, name: c.name };
    })));

    const uniqCategories = _.uniqBy(shopCategories, 'id');

    this.setCategoryFilterByParams(uniqCategories)
    this.setState({
      categoryFilterOptions: options.concat(uniqCategories)
    })
  }

  filteredProducts = (products) => {
    const { categoryFilter } = this.state;

    if (categoryFilter.id !== 'all') {
      return products.filter((p) => p.shop_categories.map(c => c.slug).includes(categoryFilter.id));
    } else {
      return products;
    }
  }

  renderProducts = () => {
    const { products, userMembership } = this.props;
    const filteredProducts = this.filteredProducts(products);

    return filteredProducts.map(product => (
      <Fragment key={product.is_gift_card ? 'gift-card' : product.id}>
        {product.is_gift_card ? (
          <Fragment>{this.renderGiftCard()}</Fragment>
        ) : (
          <Product
            product={product}
            price={productPriceWithDiscount(product, userMembership)}
          />
        )}
      </Fragment>
    ));
  };

  renderGiftCard = () => {
    const { giftCards, products } = this.props;
    const { categoryId } = this.state;

    const giftCardProduct = products.find(p => p.is_gift_card);

    if (giftCards.length === 0 || !giftCardProduct) return <div />;

    return (
      <div className="column product-column">
        <Link to={`/shop/gift_card${categoryId ? `?category=${categoryId}` : ''}`} className="Product gift-card">
          <div className="Item">
            <div className="Product-images">
              <img src={giftCardProduct.thumbnail_url || giftCardImg} className="Product-image" />
            </div>
            <div className="Product-description">
              <h5 className="Product-text product-name">
                GIFT CARD
              </h5>
              <div>
                <div className="Product-text smaller-text">
                  &nbsp;
                </div>
              </div>
            </div>
            <div className="Product-description show-on-hover">
              <div className="Product-text product-name">
                GIFT CARD
              </div>
              <div>
                <div className="Product-text smaller-text">
                  &nbsp;
                </div>
                <div className="Button product-link" >
                  { giftCardProduct.button_text || 'SHOP' }
                  <img src={hand} width={21} height={19} alt="Shop" />
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    );
  };

  renderCategoryFilter = () => {
    const { categoryFilterOptions, categoryFilter } = this.state;

    return (
      <div className="filter-container">
        <select
          className="filter-select"
          id="product-sizes"
          onChange={event => this.onCategoryFilterChange(event)}
          value={categoryFilter.id}
        >
          { categoryFilterOptions.map(option => (
            <option key={option.id} value={option.id}>{option.name}</option>
          ))}
        </select>
      </div>
    )
  };

  onCategoryFilterChange = (event) => {
    const { categoryFilterOptions } = this.state;

    this.setState({ categoryFilter: categoryFilterOptions.find(i => i.id === event.target.value )});
    if (event.target.value === 'all') {
      history.push({
        pathname: window.location.pathname,
        search: '',
      })
    } else {
      history.push(`?category=${event.target.value}`)
    }
  }

  render() {
    const {
      products,
      giftCards,
      error,
    } = this.props;

    const { isFetching, subtitle, promoSliderIsLoading } = this.state;

    if (error) return <RenderError error={error} />;

    return (
      <div className="Page Shop">
        <MetaTagsComponent
          title="SKY TING | Shop"
          desc="SKY TING Shop"
          url="shop"
        />
        <ShopPromoSpots
          onLoad={() => this.setState({ promoSliderIsLoading: false })}
          show={!isFetching}
          pages={['both', 'listing']}

        />
        <div className="Page-content">
          { isFetching || promoSliderIsLoading ? (
            <RenderLoading />
          ) : (
            <Fragment>
              <h2 className="Page-header">Shop</h2>
              { subtitle &&
                <h3 className="page-subtitle">{subtitle}</h3>
              }
              {products.length > 0 || giftCards.length > 0 ? (
                <Fragment>
                  { products.length > 0 && this.renderCategoryFilter()}
                  <div className="Shop-grid row">
                    {this.renderProducts()}
                  </div>
                </Fragment>
              ) : (
                <div className="Empty-result">NO ITEMS</div>
              )}
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  products: state.products.products,
  giftCards: state.products.giftCards,
  error: state.products.error,
  userMembership: state.userMembership.membership
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchProducts
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShopPage);
