import { closeLessonPopup } from './lesson_popup'

export const OPEN_CANCEL_LESSON_POPUP = 'OPEN_CANCEL_LESSON_POPUP';
export const CLOSE_CANCEL_LESSON_POPUP = 'CLOSE_CANCEL_LESSON_POPUP';

export const openCancelLessonPopup = lesson => (dispatch) => {
  dispatch({ type: OPEN_CANCEL_LESSON_POPUP, lesson })
  dispatch(closeLessonPopup())
}

export const closeCancelLessonPopup = () => (dispatch) => {
  dispatch({ type: CLOSE_CANCEL_LESSON_POPUP })
}
