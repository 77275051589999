import Api from '../api/user_memberships';

export const FETCH_USER_MEMBERSHIPS = 'FETCH_USER_MEMBERSHIPS';
export const FETCH_USER_MEMBERSHIPS_SUCCESS = 'FETCH_USER_MEMBERSHIPS_SUCCESS';
export const FETCH_USER_MEMBERSHIPS_ERROR = 'FETCH_USER_MEMBERSHIPS_ERROR';

export const fetchUserMemberships = () => (dispatch) => {
  dispatch({ type: FETCH_USER_MEMBERSHIPS });

  Api.fetchUserMemberships()
    .then(res => dispatch({ type: FETCH_USER_MEMBERSHIPS_SUCCESS, membership: res.body }))
    .catch(error => dispatch({ type: FETCH_USER_MEMBERSHIPS_ERROR, error }));
};
