import React, {Component} from 'react'
import {Map, Marker} from 'google-maps-react'
import {formattedLocationName} from "../helpers/formatted_location_name";

export default class MapComponent extends Component {

  constructor(props) {
    super(props)
  }

  render() {
    const style = {
      width: '100%',
      height: '492px'
    }

    return (
      <div>
        <Map google={window.google}
             className="Map-component"
             style={style}
             zoom={18}
             initialCenter={{
               lat: this.props.location.latitude,
               lng: this.props.location.longitude
             }}
        >
          <Marker key={this.props.location.id}
                  name={formattedLocationName(this.props.location.name)}
                  position={{lat: this.props.location.latitude, lng: this.props.location.longitude}}
                  optimized={false}
          />
        </Map>
      </div>
    )
  }
}