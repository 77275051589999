import React  from 'react';

const discount = (hasDiscount, membership) => {
  if (!hasDiscount) return 0;

  if (membership && membership.services_discount && Number(membership.services_discount) > 0) {
    return Number(membership.services_discount)/100
  } else {
    return 0
  }
}

export const servicePriceWithDiscount = (price, hasDiscount, membership) => {
  const online_price = Number(price)
  const price_with_discount = online_price - (online_price * discount(hasDiscount, membership))

  if ((price_with_discount % 1) !== 0) return Number(price_with_discount).toFixed(2)

  return Number(price_with_discount).toFixed(0)
}

