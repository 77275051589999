import {
  FETCH_TEACHERS,
  FETCH_TEACHERS_ERROR,
  FETCH_TEACHERS_SUCCESS
} from '../actions/teachers';

const INITIAL_STATE = {
  teachers: [],
  pageContent: null,
  error: null,
  isFetching: false,
  lastUpdate: null,
};

const teachers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_TEACHERS:
      return {
        ...state,
        isFetching: true
      };
    case FETCH_TEACHERS_ERROR:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    case FETCH_TEACHERS_SUCCESS:
      return {
        ...state,
        teachers: action.teachers,
        pageContent: action.pageContent,
        error: null,
        isFetching: false,
        lastUpdate: new Date(),
      };
    default:
      return state;
  }
};

export const isFetching = state => state.teachers.isFetching;

export default teachers;
