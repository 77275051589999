import superagent from 'superagent';

const API_ENDPOINT = '/users';

const userRegister = (params) => (
  superagent.post(API_ENDPOINT)
    .send(params)
    .set({
      'Accept': 'application/json',
      'X-CSRF-Token': document.getElementsByName('csrf-token')[0].getAttribute('content')
    })
);

export default { userRegister };
