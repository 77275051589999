import superagent from "superagent";

const API_ENDPOINT = "/checkout_total";

const checkoutTotal = (
  products,
  promo_code,
  type,
  is_contract = false,
  gift_card_amount = null,
  no_discount = false,
) => {
  return superagent
    .post(API_ENDPOINT)
    .set("Accept", "application/json")
    .send({ products, promo_code, type, is_contract, gift_card_amount, no_discount });
};

export default { checkoutTotal };
