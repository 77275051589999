import React, { Fragment } from 'react';
import { Link } from "react-router-dom";

const ProductCategories = (props) => {
  const { product } = props;

  const categoriesCount = product ? product.shop_categories.length : 0;

  return (
    <Fragment>
      <Link
        to="/shop"
        className="product-category"
      >
        SHOP
      </Link>
      {categoriesCount > 0 &&
      <Fragment>
        <span>/</span>
        {product.shop_categories.map((c, index) => (
          <Fragment>
            <Link
              to={`/shop?category=${c.slug}`}
              className="product-category"
            >
              {c.name}
            </Link>
            { categoriesCount > index + 1 &&
            <span>+</span>
            }
          </Fragment>
        ))}
      </Fragment>
      }
    </Fragment>
  );
}

export default ProductCategories;
