const calcPriceWithCoupon = (couponPercentOff, couponAmountOff, amount) => {
  const priceInDollars = amount / 100;

  if (couponPercentOff) {
    return priceInDollars - ((priceInDollars * couponPercentOff) / 100);
  } else if (couponAmountOff) {
    return priceInDollars - (couponAmountOff / 100);
  } else {
    return priceInDollars;
  }
};

export default calcPriceWithCoupon;
