import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import arrowLong from "../../../assets/images/icons/arrow_long.png";

const preventClose = event => event.stopPropagation();

const Popup = ({ id, children, isOpen = true, onClose, classNameProp = '', closeIcon, showBackBtn, onBack }) => {
  const className = classNames({
    Popup,
    'is-open': isOpen,
  });

  return (
    <div className={`${className} ${classNameProp}`} id={`popup-${id}`} onClick={onClose}>
      <div className="Popup-wrapper">
        <div className="Popup-content" onClick={preventClose}>
          {showBackBtn && <div className="Popup-backButton" onClick={onBack}><img src={arrowLong} /></div>}
          <div className="Popup-closeButton" onClick={onClose}>
            { closeIcon ? (
              <img src={closeIcon} />
            ) : (
              <span>&#10005;</span>
            )}
          </div>
          {children}
        </div>
      </div>
    </div>
  )
};

Popup.propTypes = {
  id: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]).isRequired,
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired
}

export default Popup;
