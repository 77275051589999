import {
  FETCH_COUNTRIES_SUCCESS,
} from '../actions/countries';

const INITIAL_STATE = [];

const countries = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_COUNTRIES_SUCCESS:
      return action.countries;
    default:
      return state;
  }
};

export default countries;
