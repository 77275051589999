import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import MapComponent from '../components/map_component'
import { formattedLocationName } from '../helpers/formatted_location_name'

export default class LocationCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mapIsOpen: false
    };
  }

  openMap = () => {
    this.setState({ mapIsOpen: true })
  }

  hideMap = () => {
    this.setState({ mapIsOpen: false })
  }

  locationEmail() {
    const { location: { name } } = this.props

    switch (name) {
      case 'TRIBECA':
      case 'SKY TING - TRIBECA':
        return 'tribeca@skytingyoga.com'
      case 'DOMINO':
      case 'SKY TING - DOMINO':
        return 'domino@skytingyoga.com'
      case 'SKY TING - CHINATOWN':
      case 'CHINATOWN':
        return 'chinatown@skytingyoga.com'
      default:
        return ''
    }
  }

  getExtension() {
    const { location: { name } } = this.props

    switch (name) {
      case 'TRIBECA':
      case 'SKY TING - TRIBECA':
        return '2'
      case 'DOMINO':
      case 'SKY TING - DOMINO':
        return '3'
      case 'SKY TING - CHINATOWN':
      case 'CHINATOWN':
        return '1'
      default:
        return ''
    }
  }

  formatPhoneNumber = (phone) => {
    const phoneString = ("" + phone).replace(/\D/g, '')
    const m = phoneString.match(/^(\d{3})(\d{3})(\d{4})$/)

    return (!m) ? null : `(${m[1]}) ${m[2]}-${m[3]}`
  }

  render() {
    const { location, location: { name } } = this.props

    return (
      <div className="Program-card" key={location.id}>
        <div className="Left-block">
          <div className="Program-image-slider Image-slider">
            { location.location_images.length > 1 ? (
              <Slider
                dots={false}
                infinite
                speed={300}
                cssEase={'ease-in'}
                swipeToSlide
                arrows
                slidesToShow={1}
                slidesToScroll={1}
                useTransform={false}
              >
                {location.location_images.map((image, index) => (
                  <div key={`location-${location.id}-image-${index}`} className="Slider-item-image">
                    <img src={image.image_url} alt={name} />
                  </div>
                ))}
              </Slider>
            ) : (
              <Fragment>
                { (location.location_images.length === 1) ? (
                  <div className="Slider-item-image">
                    <img src={location.location_images[0].image_url} alt={name} />
                  </div>
                ) : (
                  <div />
                )}
              </Fragment>
            )}
          </div>
        </div>
        <div className="Right-block">
          <h3 className="Program-card-header">{formattedLocationName(name)}</h3>
          <div className="Address">{location.address}</div>
          <div className="Address">{location.city}, {location.state_prov_code}</div>
          <a href={`tel:${location.phone}`} className="Phone" title="Call us">
            {this.formatPhoneNumber(location.phone)}  EXT.{this.getExtension()}
          </a>
          <a href={`mailto:${this.locationEmail()}`} className="Email" title="Send email">
            {this.locationEmail()}
          </a>
          {(location.latitude && location.longitude) &&
            <div className="Button" onClick={() => this.openMap()}>MAP</div>
          }
          <Link to={`/schedule?location=${location.mindbody_identifier}`} title="Go to schedule section">
            <div className="Button">SCHEDULE</div>
          </Link>
        </div>

        { this.state.mapIsOpen &&
          <div className="Map-window">
            <div
              className="Popup-closeButton"
              onClick={() => this.hideMap()}
            >
              <span>&#10005;</span>
            </div>
            <MapComponent location={location} />
          </div>
        }
      </div>
    );
  }
}
