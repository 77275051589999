import React, { Component, Fragment } from "react";
import Slider from "react-slick";
import classNames from "classnames";
import moment from "moment/moment";
import { Link } from "react-router-dom";

import ToggleCard from "./toggle_card.jsx";
import Popup from "./popup.jsx";
import BookLessonButton from "../components/book_lesson_button.jsx";
import time from "./../../../assets/images/icons/clock_icon.png";
import { programTeachersNames } from "../helpers/program_teachers_names";
import {history} from "../store/configureStore";

export default class TrainingMobilePopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      descriptionIsOpen: false
    };
  }

  toggleFullDescription = () => {
    this.setState({ descriptionIsOpen: !this.state.descriptionIsOpen });
  };

  renderApplyButton = () => {
    const { training, isAuthenticated } = this.props;

    if (training.is_past || (!training.is_available && !training.type_form))
      return (
        <button className="Button" disabled>
          APPLY HERE
        </button>
      );

    const hasLink = training.type_form || training.mbo_link;

    if (!hasLink) return <div />;
    const link = (training.mbo_link && !training.type_form) ? training.mbo_link : `courses/${training.slug}`;
    const buttonLink = isAuthenticated && link ? link : null;

    return (
      <BookLessonButton
        label="APPLY HERE"
        reservedLabelText="Reserved"
        link={buttonLink}
        lessonId={training.mindbody_identifier}
        onClick={isAuthenticated ? null : () => this.redirectToAuth(link)}
        className="Button"
      />
    );
  };

  redirectToAuth = (link) => {
    history.push({ pathname: "/login", state: { cameFrom: link } })
  }

  render() {
    const {
      training,
      training: { photos, guests, teachers },
      policy,
      isOpen,
      onClose
    } = this.props;

    const deposit_policy =
      training.deposit_policy || (policy && policy.text ? policy.text : null);

    const DescriptionTextClass = classNames({
      "Description-text LargeBodyText": true,
      "is-open": this.state.descriptionIsOpen
    });

    return (
      <div className="Program-mobile-popup">
        <Popup
          key={training.id}
          id={training.slug}
          isOpen={isOpen}
          onClose={onClose}
        >
          <div className="Program-card Program-popup">
            <div className="Program-popup-header">
              {training.class_description.name}
            </div>
            <div className="Info Program-popup-info">
              {training.duration && (
                <p className="Bold">
                  <img src={time} height="14" width="14" alt="duration" />
                  <span>&nbsp;&nbsp;{training.duration} HR</span>
                </p>
              )}
              <p>
                <span className="Bold">WHEN&nbsp;</span>
                <span>
                  {moment.utc(training.start_date_time).format("MMM. D")} -{" "}
                  {moment.utc(training.end_date_time).format("MMM. D, YYYY")}
                </span>
              </p>
              <p>
                <span className="Bold">W/&nbsp;</span>
                {training && training.teacher_header ? (
                  <span>{training.teacher_header}</span>
                ) : (
                  <Fragment>
                    {teachers.length > 0 ? (
                      programTeachersNames(teachers).map(t => <span>{t}</span>)
                    ) : (
                      <span>{training.staff.name}</span>
                    )}
                  </Fragment>
                )}
              </p>
            </div>
            {this.renderApplyButton()}

            {photos && photos.length > 0 && (
              <div className="Program-image-slider Image-slider">
                <Slider
                  dots={false}
                  infinite
                  speed={500}
                  fade
                  cssEase={"ease-in"}
                  arrows
                >
                  {photos.map((photo, index) => (
                    <div
                      key={`slider-image-${index}`}
                      className="Slider-item-image"
                    >
                      <img src={photo.image_url} alt="Training image" />
                    </div>
                  ))}
                </Slider>
              </div>
            )}
            {training.description && (
              <Fragment>
                <div
                  className={DescriptionTextClass}
                  dangerouslySetInnerHTML={{ __html: training.description }}
                />
                {this.state.descriptionIsOpen ? (
                  <div
                    className="Toggle-description-btn"
                    onClick={this.toggleFullDescription}
                  >
                    LESS...
                  </div>
                ) : (
                  <div
                    className="Toggle-description-btn"
                    onClick={this.toggleFullDescription}
                  >
                    MORE...
                  </div>
                )}
              </Fragment>
            )}

            <div className="Expanded-cards">
              {training.includes && (
                <ToggleCard key={`includes-${training.id}`} header="INCLUDES">
                  <div
                    className="Includes-container"
                    dangerouslySetInnerHTML={{ __html: training.includes }}
                  />
                </ToggleCard>
              )}

              {teachers && teachers.length > 0 && (
                <ToggleCard
                  key={`mentors-${training.id}`}
                  header="SKY TING MENTORS"
                >
                  <Slider
                    dots={false}
                    infinite
                    speed={300}
                    cssEase="ease-in"
                    arrows={teachers.length > 1}
                    className="Program-block-slider Image-slider"
                    slidesToScroll={1}
                  >
                    {teachers.map(mentor => (
                      <div key={mentor.id} className="Card Mentor-card">
                        <div className="Card-image">
                          <div className="Image-wrapper">
                            <img
                              src={
                                mentor.photo_url
                                  ? mentor.photo_url
                                  : mentor.image_url
                              }
                              alt={mentor.name}
                            />
                          </div>
                        </div>
                        <div className="Mentor-card-info">
                          <a href={`/teachers?teacher=${mentor.slug}`}>
                            <div className="Button Button--secondary">
                              {mentor.name}
                            </div>
                          </a>
                          {mentor.short_info && <div>{mentor.short_info}</div>}
                          {mentor.instagram ? (
                            <a
                              href={`https://www.instagram.com/${mentor.instagram.replace(
                                "@",
                                ""
                              )}`}
                              target="_blank"
                            >
                              <div>@{mentor.instagram.replace("@", "")}</div>
                            </a>
                          ) : (
                            <Fragment>
                              {mentor.website && (
                                <div>
                                  <a href={mentor.website} target="_blank">
                                    {mentor.website}
                                  </a>
                                </div>
                              )}
                            </Fragment>
                          )}
                        </div>
                      </div>
                    ))}
                  </Slider>
                </ToggleCard>
              )}
              {guests && guests.length > 0 && (
                <ToggleCard
                  key={`mentors-${training.id}`}
                  header="GUEST LECTURERS"
                >
                  <Slider
                    dots={false}
                    infinite
                    speed={300}
                    cssEase="ease-in"
                    arrows={guests.length > 1}
                    className="Program-block-slider Image-slider"
                    slidesToScroll={1}
                  >
                    {guests.map(guest => (
                      <div key={guest.id} className="Card Mentor-card">
                        <div className="Card-image">
                          <div className="Image-wrapper">
                            <img
                              src={guest.photo_url ? guest.photo_url : ""}
                              alt={guest.name}
                            />
                          </div>
                        </div>
                        <div className="Mentor-card-info">
                          {guest.teacher ? (
                            <Link
                              to={`/teachers?teacher=${guest.teacher.slug}`}
                              title="Go to teachers page"
                            >
                              <div className="Button Button--secondary">
                                {guest.name}
                              </div>
                            </Link>
                          ) : (
                            <div className="Guest-name">{guest.name}</div>
                          )}
                          <div>{guest.info}</div>
                          {guest.instagram && (
                            <a
                              href={`https://www.instagram.com/${guest.instagram.replace(
                                "@",
                                ""
                              )}`}
                              target="_blank"
                              title="Go to Instagram"
                            >
                              <div>@{guest.instagram.replace("@", "")}</div>
                            </a>
                          )}
                        </div>
                      </div>
                    ))}
                  </Slider>
                </ToggleCard>
              )}
              {training.schedule && (
                <ToggleCard key={`schedule-${training.id}`} header="SCHEDULE">
                  <div
                    className="Left-align"
                    dangerouslySetInnerHTML={{ __html: training.schedule }}
                  />
                </ToggleCard>
              )}
              {training.requirements && (
                <ToggleCard
                  key={`requirements-${training.id}`}
                  header="REQUIREMENTS"
                >
                  <div
                    className="Left-align"
                    dangerouslySetInnerHTML={{ __html: training.requirements }}
                  />
                </ToggleCard>
              )}
              {training.tuition && (
                <ToggleCard key={`tuition-${training.id}`} header="TUITION">
                  <div
                    className="Left-align tuition-container"
                    dangerouslySetInnerHTML={{ __html: training.tuition }}
                  />
                </ToggleCard>
              )}
            </div>

            {/*<div className="Discount-container">*/}
            {/*<div className="Discount-inner-container">*/}
            {/*<div className="SubHeadline">Early-Bird Discount</div>*/}
            {/*<div className="BodyText">$100 off if registered by August 15, 2016</div>*/}
            {/*</div>*/}
            {/*</div>*/}
            {this.renderApplyButton()}

            {deposit_policy && (
              <div className="Policy-text">
                <div className="SubHeadline">DEPOSIT POLICY</div>
                <div className="SmallBodyText">{deposit_policy}</div>
              </div>
            )}
          </div>
        </Popup>
      </div>
    );
  }
}
