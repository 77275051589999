import { connect } from "react-redux";
import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import moment from "moment";
import { authenticate } from "../../actions/authentication";
import Loading from "./../../../../assets/images/icons/yinyang_icon.png";
import RegisterFormStep1 from "../register_form_step1_new";
import registerReasons from "../../data/register_reasons";
import { userRegister } from "../../actions/user_register";

class LoginForm extends Component {
  constructor() {
    super();
    this.state = {
      step: "login", // 'register
      "user[email]": "",
      "user[password]": "",
      loading: false,
      error: null
    };
  }

  get loginFormChange() {
    return event => this.setState({ [event.target.name]: event.target.value });
  }

  get login() {
    return event => {
      const { authenticate } = this.props;
      this.setState({ loading: true });

      event.preventDefault();
      const {
        "user[email]": { value: email },
        "user[password]": { value: password }
      } = event.target.elements;

      authenticate({ email, password })
        .then(res => {
          this.setState({ loading: false });
        })
        .catch(err => {
          this.setState({
            loading: false
          });
        });
    };
  }

  registerUser = basicInfo => {
    this.setState({ loading: true });

    const {
      first_name,
      last_name,
      email,
      mobile_phone,
      password,
      photo_url,
      address_line1,
      address_line2,
      city,
      state,
      postal_code,
      country,
      referred_by,
      referred_by_others,
      join_newsletter
    } = basicInfo;

    const reason = registerReasons.find(el => el.id === referred_by);
    const birthStr = moment(basicInfo.birth_date).format("DD/MM/YYYY");
    const params = {
      first_name,
      last_name,
      email: email.toLowerCase(),
      mobile_phone,
      birth_date: birthStr,
      password,
      photo_url,
      address_line1,
      address_line2,
      city,
      state,
      country,
      postal_code,
      referred_by:
        reason && reason.id !== 10 ? reason.value : referred_by_others,
      join_newsletter
    };

    this.props
      .userRegister({ user: params })
      .then(res => {
        this.setState({
          loading: false
        });
      })
      .catch(err => {
        this.setState({
          loading: false
        });
      });
  };

  renderLogin = () => {
    const { loading } = this.state;

    return (
      <div className="LoginForm" onClick={event => event.stopPropagation()}>
        <div className="Headline">LOGIN</div>
        <form
          className="LoginForm-content"
          onSubmit={this.login}
          onChange={this.loginFormChange}
        >
          <div className="LoginForm-field">
            <input
              type="email"
              name="user[email]"
              className="TextField"
              placeholder="EMAIL"
            />
          </div>
          <div className="LoginForm-field">
            <input
              type="password"
              name="user[password]"
              className="TextField"
              placeholder="PASSWORD"
            />
          </div>
          {loading ? (
            <div className="inline-loading mt-4">
              <img src={Loading} height="21" width="21" alt="Loading" />
            </div>
          ) : (
            <Fragment>
              <div className="LoginForm-field">
                <input type="submit" className="SmallButton" value="Login" />
              </div>
              <div className="LoginForm-field">
                <a
                  onClick={() => this.setState({ step: "register" })}
                  className="LoginForm-link"
                >
                  register!
                </a>
              </div>
              {/*<div className="LoginForm-field">*/}
              {/*<a onClick={() => openLoginPopup('password-recovery')} className="LoginForm-link">Forgot password?</a>*/}
              {/*</div>*/}
            </Fragment>
          )}
        </form>
      </div>
    );
  };

  renderRegister = () => {
    const { error, loading } = this.state;
    const { countries } = this.props;

    return (
      <RegisterFormStep1
        title="register"
        goToSecondStep={basicInfo => this.registerUser(basicInfo)}
        changeStep={step => this.setState({ step })}
        backendErrors={error}
        loading={loading}
        countries={countries}
      />
    );
  };

  render() {
    const { step } = this.state;

    if (step === "register") return this.renderRegister();
    return this.renderLogin();
  }
}

const mapStateToProps = state => ({
  countries: state.countries,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      authenticate,
      userRegister
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginForm);
