import React, { Fragment } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import moment from "moment";
import Loading from "../../../assets/images/icons/yinyang_icon.png";
import ReactSelectComponent from "./react_select_component";

const monthRegExp = /^(0?[1-9]|1[012])$/;

const PaymentCardVaildationSchema = user =>
  Yup.object().shape({
    exp_month: Yup.string()
      .length(2, "Month must contain 2 numbers")
      .matches(monthRegExp, "Invalid value")
      .test("month-validation", "Invalid month", function(value) {
        const { exp_year } = this.parent;
        if (exp_year && Number(exp_year) === moment().year()) {
          return value >= moment().month() + 1
        } else {
          return true
        }
      })
      .required("Required"),
    exp_year: Yup.string()
      .length(4, "Year must contain 4 numbers")
      .required("Required")
      .test("year-validation", "Invalid year", function(value) {
        return value >= moment().year();
      }),
    cvv: Yup.string().required("Required"),
    card_holder: Yup.string().required("Required"),
    card_number: Yup.string()
      .test("card-validation", "Invalid card number", function(value) {
        if (/[^0-9-\s]+/.test(value)) return false;

        // The Luhn Algorithm. It's so pretty.
        var nCheck = 0,
          nDigit = 0,
          bEven = false;
        value = value.replace(/\D/g, "");

        for (var n = value.length - 1; n >= 0; n--) {
          var cDigit = value.charAt(n),
            nDigit = parseInt(cDigit, 10);

          if (bEven) {
            if ((nDigit *= 2) > 9) nDigit -= 9;
          }

          nCheck += nDigit;
          bEven = !bEven;
        }

        return nCheck % 10 === 0;
      })
      .required("Required"),
    address_line1: Yup.string()
      .notRequired()
      .test("address-validation", "Required", function(value) {
        if (user.address_line1) return true;

        return value && value.length > 0;
      }),
    city: Yup.string()
      .notRequired()
      .test("city-validation", "Required", function(value) {
        if (user.city) return true;
        return value && value.length > 0;
      }),
    state: Yup.string()
      .notRequired()
      .test("state-validation", "Required", function(value) {
        if (user.state) return true;
        return value && value.length > 0;
      }),
    postal_code: Yup.string()
      .notRequired()
      .test("postal-code-validation", "Required", function(value) {
        if (user.postal_code) return true;
        return value && value.length > 0;
      }),
    mobile_phone: Yup.string()
      .notRequired()
      .test("mobile-phone-validation", "Required", function(value) {
        if (user.mobile_phone) return true;
        return value && value.length > 0;
      })
  });

const userInfoRequired = user => {
  return (
    !user.address_line1 ||
    !user.city ||
    !user.state ||
    !user.country ||
    !user.postal_code ||
    !user.mobile_phone
  );
};

const findRegions = (countryCode, countriesList) => {
  const selectedCountry = countriesList.find(c => c.countryShortCode === countryCode);
  if (!selectedCountry) return [];

  return selectedCountry.regions || [];
}

const selectedRegion = (countryCode, countriesList, regionCode) => {
  const selectedCountry = countriesList.find(c => c.countryShortCode === countryCode);
  if (!selectedCountry) return null;

  return (selectedCountry.regions ? selectedCountry.regions.find(r => r.shortCode === regionCode) : null);
}

const BillingFormNew = props => (
  <div>
    <Formik
      initialValues={{
        card_number: props.initialData ? props.initialData.card_number : "",
        exp_month: props.initialData ? props.initialData.exp_month : "",
        exp_year: props.initialData ? props.initialData.exp_year : "",
        cvv: props.initialData ? props.initialData.cvv : "",
        card_holder: props.initialData ? props.initialData.card_holder : "",
        address_line1: props.initialData ? props.initialData.address_line1 : "",
        city: props.initialData ? props.initialData.city : "",
        state: props.initialData ? props.initialData.state : "",
        postal_code: props.initialData ? props.initialData.postal_code : "",
        mobile_phone: props.initialData ? props.initialData.mobile_phone : "",
        country: props.initialData ? props.initialData.country : (props.user.country || ""),
      }}
      validationSchema={PaymentCardVaildationSchema(props.user)}
      onSubmit={(values, { setSubmitting, setErrors }) => {
        props.onSubmit(values);
      }}
      render={({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue
      }) => (
        <Fragment>
          <form onSubmit={handleSubmit} className="BillingForm">
            <div className="BillingForm-row row">
              <div className="BillingForm-col w-100">
                <div className="Input">
                  <Field
                    name="card_number"
                    placeholder="Credit Card #"
                    className="Input-input"
                  />
                  {touched.card_number && errors.card_number && (
                    <span className="Input-error">{errors.card_number}</span>
                  )}
                  {props.backendErrors && props.backendErrors.card_number && (
                    <Fragment>
                      {props.backendErrors.card_number.map(msg => (
                        <div>
                          <span className="Input-error">{msg}</span>
                        </div>
                      ))}
                    </Fragment>
                  )}
                </div>
              </div>
            </div>

            <div className="BillingForm-row row">
              <div className="small-12 medium-6 column">
                <div className="BillingForm-col">
                  <div className="Input">
                    <Field
                      name="exp_month"
                      placeholder="Month (MM)"
                      className="Input-input"
                    />
                    {touched.exp_month && errors.exp_month && (
                      <span className="Input-error">{errors.exp_month}</span>
                    )}
                    {props.backendErrors && props.backendErrors.exp_month && (
                      <Fragment>
                        {props.backendErrors.exp_month.map(msg => (
                          <div>
                            <span className="Input-error">{msg}</span>
                          </div>
                        ))}
                      </Fragment>
                    )}
                  </div>
                </div>
              </div>
              <div className="small-12 medium-6 column">
                <div className="BillingForm-col">
                  <div className="Input">
                    <Field
                      name="exp_year"
                      placeholder="Year (YYYY)"
                      className="Input-input"
                    />
                    {touched.exp_year && errors.exp_year && (
                      <span className="Input-error">{errors.exp_year}</span>
                    )}
                    {props.backendErrors && props.backendErrors.exp_year && (
                      <Fragment>
                        {props.backendErrors.exp_year.map(msg => (
                          <div>
                            <span className="Input-error">{msg}</span>
                          </div>
                        ))}
                      </Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="BillingForm-row row">
              <div className="BillingForm-col w-100">
                <div className="Input">
                  <Field
                    name="cvv"
                    placeholder="Security code"
                    className="Input-input"
                  />
                  {touched.cvv && errors.cvv && (
                    <span className="Input-error">{errors.cvv}</span>
                  )}
                  {props.backendErrors && props.backendErrors.cvv && (
                    <Fragment>
                      {props.backendErrors.cvv.map(msg => (
                        <div>
                          <span className="Input-error">{msg}</span>
                        </div>
                      ))}
                    </Fragment>
                  )}
                </div>
              </div>
            </div>

            <div className="BillingForm-row row">
              <div className="BillingForm-col w-100">
                <div className="Input">
                  <Field
                    name="card_holder"
                    placeholder="Full name"
                    className="Input-input"
                  />
                  {touched.card_holder && errors.card_holder && (
                    <span className="Input-error">{errors.card_holder}</span>
                  )}
                  {props.backendErrors && props.backendErrors.card_holder && (
                    <Fragment>
                      {props.backendErrors.card_holder.map(msg => (
                        <div>
                          <span className="Input-error">{msg}</span>
                        </div>
                      ))}
                    </Fragment>
                  )}
                </div>
              </div>
            </div>

            {userInfoRequired(props.user) && (
              <Fragment>
                <div className="mb-2">
                  Please fill in the missing information:
                </div>
                {!props.user.address_line1 && (
                  <div className="BillingForm-row row">
                    <div className="BillingForm-col w-100">
                      <div className="Input">
                        <Field
                          name="address_line1"
                          placeholder="Address 1"
                          className="Input-input"
                        />
                        {touched.address_line1 && errors.address_line1 && (
                          <span className="Input-error">
                            {errors.address_line1}
                          </span>
                        )}
                        {props.backendErrors &&
                          props.backendErrors.address_line1 && (
                            <Fragment>
                              {props.backendErrors.address_line1.map(msg => (
                                <div>
                                  <span className="Input-error">{msg}</span>
                                </div>
                              ))}
                            </Fragment>
                          )}
                      </div>
                    </div>
                  </div>
                )}

                {!props.user.city && (
                  <div className="BillingForm-row row">
                    <div className="BillingForm-col w-100">
                      <div className="Input">
                        <Field
                          name="city"
                          placeholder="City"
                          className="Input-input"
                        />
                        {touched.city && errors.city && (
                          <span className="Input-error">{errors.city}</span>
                        )}
                        {props.backendErrors && props.backendErrors.city && (
                          <Fragment>
                            {props.backendErrors.city.map(msg => (
                              <div>
                                <span className="Input-error">{msg}</span>
                              </div>
                            ))}
                          </Fragment>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {(!props.user.state || !props.user.country) && (
                  <Fragment>
                    <div className="BillingForm-row row">
                      <div className="BillingForm-col w-100">
                        <div className="Input">
                          <ReactSelectComponent
                            options={props.countries}
                            placeholder="COUNTRY"
                            optionLabel="countryName"
                            optionValue="countryShortCode"
                            setValue={val => setFieldValue("country", val.countryShortCode)}
                            selectedValue={props.countries.find(
                              c => c.countryShortCode === values.country
                            )}
                          />
                          {touched.country && errors.country && (
                            <span className="Input-error">
                              {errors.country}
                            </span>
                          )}
                          {props.backendErrors && props.backendErrors.country && (
                            <Fragment>
                              {props.backendErrors.country.map(msg => (
                                <div>
                                  <span className="Input-error">{msg}</span>
                                </div>
                              ))}
                            </Fragment>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="BillingForm-row row">
                      <div className="BillingForm-col w-100">
                        <div className="Input">
                          <ReactSelectComponent
                            disabled={!values.country}
                            placeholder="STATE"
                            options={findRegions(values.country, props.countries)}
                            optionLabel="name"
                            optionValue="shortCode"
                            setValue={val => setFieldValue("state", val.shortCode)}
                            selectedValue={selectedRegion(values.country, props.countries, values.state)}
                          />
                          {touched.state && errors.state && (
                            <span className="Input-error">{errors.state}</span>
                          )}
                          {props.backendErrors && props.backendErrors.state && (
                            <Fragment>
                              {props.backendErrors.state.map(msg => (
                                <div>
                                  <span className="Input-error">{msg}</span>
                                </div>
                              ))}
                            </Fragment>
                          )}
                        </div>
                      </div>
                    </div>
                  </Fragment>
                )}

                {!props.user.postal_code && (
                  <div className="BillingForm-row row">
                    <div className="BillingForm-col w-100">
                      <div className="Input">
                        <Field
                          name="postal_code"
                          placeholder="Postal code"
                          className="Input-input"
                        />
                        {touched.postal_code && errors.postal_code && (
                          <span className="Input-error">
                            {errors.postal_code}
                          </span>
                        )}
                        {props.backendErrors &&
                          props.backendErrors.postal_code && (
                            <Fragment>
                              {props.backendErrors.postal_code.map(msg => (
                                <div>
                                  <span className="Input-error">{msg}</span>
                                </div>
                              ))}
                            </Fragment>
                          )}
                      </div>
                    </div>
                  </div>
                )}

                {!props.user.mobile_phone && (
                  <div className="BillingForm-row row">
                    <div className="BillingForm-col w-100">
                      <div className="Input">
                        <Field
                          name="mobile_phone"
                          placeholder="Mobile phone"
                          className="Input-input"
                        />
                        {touched.mobile_phone && errors.mobile_phone && (
                          <span className="Input-error">
                            {errors.mobile_phone}
                          </span>
                        )}
                        {props.backendErrors &&
                          props.backendErrors.mobile_phone && (
                            <Fragment>
                              {props.backendErrors.mobile_phone.map(msg => (
                                <div>
                                  <span className="Input-error">{msg}</span>
                                </div>
                              ))}
                            </Fragment>
                          )}
                      </div>
                    </div>
                  </div>
                )}
              </Fragment>
            )}

            <div className="text-center">
              {props.isLoading ? (
                <div className="inline-loading d-inline-block mt-3">
                  <img src={Loading} height="21" width="21" />
                </div>
              ) : (
                <button
                  type="submit"
                  className="BillingForm-button SmallButton"
                  disabled={props.isLoading}
                >
                  {props.submitLabel || "Add new Card"}
                </button>
              )}
            </div>
          </form>
        </Fragment>
      )}
    />
  </div>
);

export default BillingFormNew;
