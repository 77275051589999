import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Popup from "../popup";
import closeIcon from "../../../../assets/images/icons/close_icon.svg";
import Payment from "./payment";
import { isAuthenticated } from "../../reducers/user";
import Api from "../../api/gift_card";
import { v4 as uuidv4 } from "uuid";
import { requestAvailable } from "../../helpers/request_available";
import GiftCardSuccess from "./gift_card_success";

class GiftCardPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: "",
      last_name: "",
      recipient_email: "",
      message: "",
      emailError: false,
      blockPopupClose: false,
      idemp_key_customer: null,
      idemp_key_charge: null,
      requestSentAt: null,
      loading: false,
      error: null,
      success: false,
    };
  }

  componentDidMount() {
    this.generateIdempKey();
  }

  generateIdempKey = () => {
    const idemp_key_customer = uuidv4();
    const idemp_key_charge = uuidv4();

    this.setState({ idemp_key_customer, idemp_key_charge });
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });

    if (event.target.name === "recipient_email" && this.state.emailError) {
      this.setState({ emailError: false });
    }
  };

  purchaseGiftCard = (stripe_tok = null) => {
    const {
      first_name,
      last_name,
      recipient_email,
      message,
      idemp_key_customer,
      idemp_key_charge,
      requestSentAt
    } = this.state;

    const { selectedCard, selectedTvCard } = this.props;

    if (!requestAvailable(requestSentAt)) return;

    this.setState({
      loading: true,
      error: null,
      blockPopupClose: true,
      requestSentAt: new Date().getTime()
    });

    const amount = selectedTvCard ? selectedTvCard.amount : selectedCard.amount;
    const is_for_skyting_tv = !!selectedTvCard;

    Api.buyGiftCard(
      stripe_tok,
      amount,
      {
        first_name,
        last_name,
        recipient_email,
        message
      },
      idemp_key_customer,
      idemp_key_charge,
      is_for_skyting_tv
    )
      .then(res => {
        this.setState({
          loading: false,
          success: true,
          blockPopupClose: false
        });
      })
      .catch(err => {
        this.setState({
          blockPopupClose: false,
          error: err.response.body,
          loading: false
        });
      });
  };

  renderForm = () => {
    const {
      first_name,
      last_name,
      recipient_email,
      emailError,
      message
    } = this.state;

    return (
      <form>
        <div className="position-relative">
          <div className="left-positioned-label">TO</div>
          <div className="Registration-twoFields">
            <div className="Registration-field">
              <input
                value={first_name}
                onChange={this.handleChange}
                placeholder="First name"
                className="TextField"
                name="first_name"
              />
            </div>
            <div className="Registration-field">
              <input
                value={last_name}
                onChange={this.handleChange}
                placeholder="Last name"
                className="TextField"
                name="last_name"
              />
            </div>
          </div>
        </div>

        <div className="Registration-field">
          <input
            value={recipient_email}
            onChange={this.handleChange}
            placeholder="Email"
            className={`TextField ${emailError ? "red" : ""}`}
            name="recipient_email"
          />
          {emailError && <div className="form-error">Invalid email</div>}
        </div>
        <div className="position-relative message-field">
          <div className="left-positioned-label">MESSAGE</div>
          <div className="Registration-field">
            <input
              value={message}
              onChange={this.handleChange}
              placeholder="Message"
              className="TextField"
              name="message"
            />
          </div>
        </div>
      </form>
    );
  };

  validateEmail = email => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const emailIsValid = re.test(String(email).toLowerCase());

    this.setState({ emailError: !emailIsValid });
    return emailIsValid;
  };

  closePopup = () => {
    if (!this.state.blockPopupClose) {
      this.props.onClose();
    }
  };

  render() {
    const {
      loading,
      error,
      recipient_email,
      first_name,
      last_name,
      success
    } = this.state;

    const { amountSelect, user, pageContent, selectedCard, selectedTvCard } = this.props;

    const disableSubmitBtn =
      (!selectedCard && !selectedTvCard) || !recipient_email || !first_name || !last_name;

    return (
      <Popup
        id="product-lightbox"
        isOpen
        classNameProp="product-lightbox gift-card-popup"
        onClose={() => this.closePopup()}
        closeIcon={closeIcon}
      >
        <div className="product-lightbox-inner">
          <div className="product-lightbox-top">
            <div className="d-flex justify-content-center flex-column align-items-center position-relative">
              <div className="product-lightbox-title">SEND A GIFT CARD</div>
            </div>
            {amountSelect && (
              <div className="product-lightbox-size-select">{amountSelect}</div>
            )}
          </div>
          <div className="gift-card-popup-content">
            { success ? (
              <GiftCardSuccess
                selectedCard={selectedCard}
                selectedTvCard={selectedTvCard}
                recipient_email={recipient_email}
                onClose={() => this.closePopup()}
                user={user}
              />
            ) : (
              <Fragment>
                <div className="gift-card-popup-headline">
                  { pageContent.popup_subtitle ||
                  "We'll directly email the recipient a link to open your greeting card and redeem your gift."}
                </div>
                <div className="gift-card-popup-container">
                  <div className="gift-card-popup-inner-container">
                    <div className="gift-card-popup-subheader">
                      WHO ARE YOU SENDING TO?
                    </div>
                    {this.renderForm()}
                    <div className="gift-card-popup-subheader">PAYMENT</div>
                    <Payment
                      user={user}
                      blockPopupClose={val => this.setState({ blockPopupClose: val })}
                      purchaseGiftCard={this.purchaseGiftCard}
                      loading={loading}
                      error={error}
                      disableSubmitBtn={disableSubmitBtn}
                      checkEmailIsValid={() => this.validateEmail(recipient_email)}
                      pageContent={pageContent}
                      changeIdempKeys={() => this.generateIdempKey()}
                    />
                  </div>
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </Popup>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  isAuthenticated: isAuthenticated(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GiftCardPopup);
