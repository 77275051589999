import superagent from "superagent";
export const FETCH_BLOG_PAGE_SUCCESS = "FETCH_BLOG_PAGE_SUCCESS";

export const fetchBlogPage = () => (dispatch, getState) => {
  const state = getState();
  if (state.blogPageContent) return;

  return new Promise(async (resolve, reject) => {
    await superagent
      .get("/blog_page_contents")
      .set("Accept", "application/json")
      .send()
      .then(res => {
        dispatch({ type: FETCH_BLOG_PAGE_SUCCESS, content: res.body });
        resolve(true);
      })
      .catch(error => {
        reject(error.response.body);
      });
  });
};
