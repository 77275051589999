import {
  FETCH_LOCATIONS,
  FETCH_LOCATIONS_ERROR,
  FETCH_LOCATIONS_SUCCESS
} from '../actions/locations';

const INITIAL_STATE = {
  locations: [],
  error: null,
  isFetching: false
};

const locations = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_LOCATIONS:
      return {
        ...state,
        isFetching: true
      };
    case FETCH_LOCATIONS_ERROR:
      return {
        ...state,
        error: action.error,
        isFetching: false
      };
    case FETCH_LOCATIONS_SUCCESS:
      return {
        ...state,
        locations: action.locations,
        error: null,
        isFetching: false
      };
    default:
      return state;
  }
};

export const isFetching = state => state.locations.isFetching;

export default locations;
