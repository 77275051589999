import React, { Component } from 'react';
import PropTypes from 'prop-types'
import moment from 'moment';
import { DateRange } from 'react-date-range';
import classNames from 'classnames';
import onClickOutside from "react-onclickoutside";

class Datepicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      startDate: props.endDate || moment(),
      endDate: props.endDate || moment()
    };
  }

  onChange = range => {
    this.setState(range);
    this.props.onChange(range);
  };

  handleToggle = () => {
    clearTimeout(this.timerId);
    this.setState({ open: !this.state.open });
  };

  handleClickOnPopup = event => {
    clearTimeout(this.timerId);
  };

  handleClickOutside = evt => {
    this.setState({ open: false });
  };

  renderLabel() {
    const format = 'MM/DD/YY';
    return `${this.state.startDate.format(format)} - ${this.state.endDate.format(format)}`;
  }

  render() {
    const { startDate, endDate } = this.state;
    const { icon, hoverIcon, minDate } = this.props;

    const classes = classNames({
      Datepicker: true,
      'is-open': this.state.open,
    });

    const iconClass = classNames({
      'Datepicker-icon': true,
      'Hover-hide': (!!hoverIcon)
    });

    return (
      <div className={classes}>
        <label className="Datepicker-label" htmlFor="Datepicker">
          {this.renderLabel()}
          { icon &&
            <img src={icon} className={iconClass} height="19" alt="Calendar" />
          }
          { hoverIcon &&
            <img src={hoverIcon} className="Hover-icon" height="19" alt="Calendar" />
          }
        </label>
        <input
          className="Datepicker-hiddenInput"
          type="checkbox"
          id="Datepicker"
          onClick={this.handleToggle}
        />

        <div className="Datepicker-popup" onClick={this.handleClickOnPopup}>
          <DateRange
            startDate={startDate}
            endDate={endDate}
            onChange={this.onChange}
            calendars={1}
            minDate={minDate}
          />
        </div>
      </div>
    );
  }
}

Datepicker.propTypes = {
  endDate: PropTypes.string,
  startDate: PropTypes.string,
  onChange: PropTypes.func
};

export default onClickOutside(Datepicker);
