import React, {Component, Fragment} from 'react';
import superagent from 'superagent';
import moment from "moment";
import {Link} from "react-router-dom";
import MetaTagsComponent from "../components/meta_tags_component";
import RenderLoading from '../components/render_loading.jsx';
import RenderError from '../components/render_error.jsx';
import scrollToTop from "../helpers/scrollToTop";
import Slider from "react-slick";
import hand from "../../../assets/images/icons/hand.svg";
import arrow from "../../../assets/images/icons/arrow_right_blue.svg";
import { fetchBlogPage } from "../actions/blog_page";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

class BlogPostPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      post: null,
      error: null,
      showShareButtons: false,
    };
  }

  componentDidMount() {
    scrollToTop();
    this.getPost(this.props.match.params.id);
    this.props.fetchBlogPage();
    this.loadAddthisScript();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.id !== nextProps.match.params.id) {
      scrollToTop()
      this.getPost(nextProps.match.params.id);
    }
  }

  onScriptLoad = () => {
    this.setState({showShareButtons: true})
    window.addthis.layers.refresh();
  };

  loadAddthisScript = () => {
    this.script = document.createElement("script");
    this.script.src =
      "//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5d555917d1ed00d2";
    this.script.async = true;
    this.script.onload = () => this.onScriptLoad();
    document.body.appendChild(this.script);
  };

  getPost = id => {
    this.setState({ isLoading: true, error: null });

    return superagent
      .get(`/tribune/${id}`)
      .set("Accept", "application/json")
      .send()
      .then(res => {
        this.setState({
          isLoading: false,
          post: res.body,
        });
      })
      .catch(error => {
        this.setState({ error: error.response.body, isLoading: false });
      });
  };

  renderTopPart = () => {
    const { post } = this.state;
    const { blogPage } = this.props;

    if (post.main_image_url && post.show_header_image) {
      return (
        <div className="blog-post-main-image-container">
          <img src={post.main_image_url} alt={post.title} />
          <div className="overlay" />
          <h2 className="blog-post-title padding-lr">
            {post.title}
          </h2>
        </div>
      )
    } else {
      return (
        <Fragment>
          <Link to="/tribune" className="clear-filter-btn">
            &#9668;&nbsp;{blogPage ? blogPage.all_post_link_text : 'ALL POSTS'}
          </Link>
          <h2 className="blog-post-title padding-lr">
            {post.title}
          </h2>
        </Fragment>
      )
    }
  }

  renderMeta = () => {
    const { post } = this.state;
    const { blogPage } = this.props;

    if (!post.show_author || (!post.blog_author && !post.publication_date)) return;

    const withBorder = post.main_image_url && post.show_header_image ? 'bordered' : ''
    const withAuthor = post.show_author && !!post.blog_author;

    return (
      <div className={`blog-post-meta padding-lr ${withBorder}`}>
        { withAuthor &&
          <div className="blog-post-author">
            { withBorder &&
              <Link to="/tribune" className="clear-filter-btn left">
                &#9668;&nbsp;{blogPage ? blogPage.all_post_link_text : 'ALL POSTS'}
              </Link>
            }
            <Link to={`/tribune?author=${post.blog_author.slug}`} className="blog-author">
              <div className="blog-author-img" >
                <img src={post.blog_author.image_url} />
              </div>
              <div className="blog-author-name">
                {post.blog_author.first_name} {post.blog_author.last_name}
              </div>
            </Link>
          </div>
        }
        { post.publication_date &&
          <div className={`blog-post-date ${withAuthor ? 'text-left' : ''}`}>
            {moment(post.publication_date).format('MMMM D, YYYY')}
          </div>
        }
      </div>
    )
  };

  relatedPost = (post) => {
    return (
      <Link to={`/tribune/${post.slug}`} className="related-post-column">
        <div className="related-post">
          <div className="related-post-image">
            <img src={post.thumbnail_url} />
          </div>
          <h5 className="title">{post.title}</h5>
          {post.show_author && post.blog_author &&
            <div className="blog-post-author">
              <div className="blog-author">
                <div className="blog-author-img">
                  <img src={post.blog_author.image_url} />
                </div>
                <div className="blog-author-name">
                  {post.blog_author.first_name} {post.blog_author.last_name}
                </div>
              </div>
            </div>
          }
          <div className="divider-line hide-for-mobile" />

          { post.publication_date &&
          <div className="date">
            {moment(post.publication_date).format('MMMM D, YYYY')}
          </div>
          }
          <div className="show-for-mobile">
            <div className="text">{post.short_text}</div>
            <Link to={`/tribune/${post.slug}`} className="Button with-icon blog-continue-btn">
              <span>CONTINUE READING</span>
              <img src={arrow} />
            </Link>
          </div>
        </div>
      </Link>
    )
  }

  render() {
    const { post, error, isLoading, showShareButtons } = this.state;
    const { blogPage } = this.props;

    if (error) return <RenderError error={error} />;

    return (
      <Fragment>
        { post &&
          <MetaTagsComponent
            title={`SKY TING | Tribune | ${post.title}`}
            desc={post.seo_description || post.short_text}
            url={`tribune/${post.slug}`}
            image={post.thumbnail_url}
          />
        }
        <div className="Page blog blog-post-page">
          <div className="Page-content">
            { isLoading ? (
              <RenderLoading className="absolute" />
            ) : (
              <Fragment>
                { post ? (
                  <Fragment>
                    {this.renderTopPart()}
                    {this.renderMeta()}

                    <div
                      className="blog-post-text padding-lr"
                      dangerouslySetInnerHTML={{ __html: post.text }}
                    />
                    { post.blog_tags.length > 0 &&
                      <Fragment>
                        <div className="blog-post-tags-top-border" />
                        <div className="blog-post-tags padding-lr">
                          {post.blog_tags.map(tag => (
                            <Link to={`/tribune?tag=${tag.slug}`} className="blog-post-tag-container">
                              {tag.name}
                            </Link>
                          ))}
                        </div>
                      </Fragment>
                    }
                    <div className="sky-share-buttons-container">
                      {showShareButtons && <div className="share-header">SHARE</div>}
                      <div className="addthis_inline_share_toolbox_6a6r" ></div>
                    </div>

                    { post.related_posts.length > 0 &&
                      <div>
                        <h4 className="Page-header padding-lr">
                          {blogPage ? blogPage.related_posts_title : 'RELATED TINGS'}
                        </h4>
                        <div className="page-subtitle padding-lr">
                          {blogPage ? blogPage.related_posts_subtitle : 'you may also like these tings!'}
                        </div>
                        <div className="related-posts-row">
                          <div className="relate-products-carousel">
                            <Slider
                              dots
                              infinite={post.related_posts.length > 3}
                              speed={500}
                              swipeToSlide
                              arrows={false}
                              slidesToShow={4}
                              slidesToScroll={1}
                              responsive={[
                                {
                                  breakpoint: 1258,
                                  settings: {
                                    slidesToShow: 3,
                                  }
                                },
                                {
                                  breakpoint: 640,
                                  settings: {
                                    infinite: true,
                                    slidesToShow: 1,
                                    centerPadding: "10%",
                                    centerMode: true
                                  }
                                }
                              ]}
                            >
                            {post.related_posts.map(p => this.relatedPost(p))}
                            </Slider>
                          </div>
                        </div>
                      </div>
                    }
                  </Fragment>
                ) : (
                  <Fragment>
                    NOT FOUND
                  </Fragment>
                )}
                <Link
                  to="/tribune"
                  className={`browse-btn ${post && post.related_posts.length === 0 ? 'mt-0' : ''}`}
                >
                  BROWSE OUR BLOG
                  <img src={hand} alt="blog" />
                </Link>
              </Fragment>
            )}
          </div>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  blogPage: state.blogPageContent,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  { fetchBlogPage },
  dispatch
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BlogPostPage);
