import React, { Component } from 'react';
import { Collapse } from 'react-collapse';

export default class JobCard extends Component {
  constructor(props) {
    super(props);

    this.state = { isExpanded: false };
  }

  toggleCard = () => {
    this.setState({ isExpanded: !this.state.isExpanded });
  }

  render() {
    const { job } = this.props
    const { isExpanded } = this.state

    return (
      <div className="Job-card">
        <div className="Job-card-visible">
          <div className="Job-title">{job.title}</div>
          <div className="Job-short-desc">{job.short_desc}</div>

          <div className="Job-buttons" >
            { job.apply_link ? (
              <a className="Button" href={job.apply_link} title="Apply" target="_blank" >APPLY</a>
            ) : (
              <a className="Button" title="Send email to info@skytingyoga.com" href="mailto:info@skytingyoga.com" >APPLY</a>
            )}
            {job.text &&
              <div className="row" >
                <div
                  className={`Button Button--secondary ${isExpanded ? 'active-btn' : ''}`}
                  onClick={() => this.toggleCard()}
                >
                  { isExpanded ? 'LEARN LESS' : 'LEARN MORE' }
                </div>
              </div>
            }
          </div>
        </div>
        <Collapse isOpened={isExpanded}>
          <div className="Job-card-expanded">
            <div className="Job-card-text" dangerouslySetInnerHTML={{ __html: job.text }} />
          </div>
        </Collapse>
      </div>
    );
  }
}
