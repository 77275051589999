import moment from 'moment';

const matchStaff = (filters, staff) =>
  !filters.staff || staff.id === filters.staff;

const matchLocation = (filters, location) =>
  !filters.location || location.id === filters.location;

const matchSessionType = (filters, session_type) =>
  !filters.session_type || session_type.id === filters.session_type;

const matchDate = (filters, end_date_time) => {
  const endDate = moment(end_date_time);

  return (
    !filters.end_date_time ||
    endDate.isSameOrBefore(filters.end_date_time, 'day')
  );
};

const lessonMatchesFilters = filters => lesson => {
  const {staff, location, session_type, end_date_time} = lesson;

  return (
    matchStaff(filters, staff) &&
    matchLocation(filters, location) &&
    matchDate(filters, end_date_time) &&
    matchSessionType(filters, session_type)
  );
};

export default lessonMatchesFilters;
