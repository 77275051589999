import superagent from "superagent";

const API_ENDPOINT = "/checkout_shop";

const checkoutShop = (
  products,
  amount,
  shipping_info,
  promo_code,
  gift_card_amount,
  recaptchaValue
) => {
  return superagent
    .post(API_ENDPOINT)
    .set("Accept", "application/json")
    .send({
      products,
      amount,
      shipping_info,
      promo_code,
      gift_card_amount,
      recaptchaValue
    });
};

export default { checkoutShop };
