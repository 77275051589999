const showResults = (downloadSize, startTime, endTime) => {
  const roundedDecimals = 2;
  const bytesInAKilobyte = 1024;

  const duration = (endTime - startTime) / 1000;

  const bitsLoaded = downloadSize * 8;
  const speedBps = ( bitsLoaded / duration ).toFixed(roundedDecimals);
  const speedKbps = (speedBps / bytesInAKilobyte).toFixed(roundedDecimals);
  const speedMbps = (speedKbps / bytesInAKilobyte).toFixed(roundedDecimals);
  // console.log("Your connection speed is: \n" + speedBps + " bps\n" + speedKbps + " kbps\n" + speedMbps + " Mbps\n")

  return speedMbps;
}

const checkSpeed = () => {
  return new Promise((resolve, reject) => {
    const downloadSize = 175000;
    const imageAddr = "https://skytingyoga.s3.us-east-2.amazonaws.com/test_speed_1.jpg" + "?n=" + Math.random();
    const download = new Image();
    const startTime = (new Date()).getTime();

    download.onload = () => {
      const endTime = (new Date()).getTime();
      const speed = showResults(downloadSize, startTime, endTime);
      resolve(speed);
    }

    download.onerror = reject;
    download.src = imageAddr;
  })
}

export default checkSpeed;
