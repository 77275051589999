import React, { Component } from "react";
import onClickOutside from "react-onclickoutside";
import classNames from "classnames";

const options = [
  { label: "view history", value: null },
  { label: "past week", value: "week" },
  { label: "past month", value: "month" },
  { label: "past year", value: "year" },
  { label: "view all", value: "all" },
];

class AccountHistoryFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  toggleList = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  handleClickOutside = evt => {
    this.setState({ isOpen: false });
  };

  render() {
    const { isOpen } = this.state;
    const { selected } = this.props;

    const historyOptions = options.filter(o => o.value !== selected);

    const DropdownClass = classNames({
      "Dropdown-select-container": true,
      "is-open": isOpen,
    });

    const selectedOption = options.find(o => o.value === selected)

    return (
      <div className="Dropdown-select">
        <div className={DropdownClass} onClick={this.toggleList}>
          <span className="Dropdown-select-text">
            {selectedOption.label}
          </span>

          <div className="Select-arrow-container">
            <span className="select-arrow-down" />
          </div>
        </div>

        <div
          className={`Dropdown-list ${isOpen ? 'd-block' : 'd-none'}`}
        >
          {historyOptions.map(option => (
            <div
              className="Dropdown-option"
              onClick={() => {
                this.props.selectOption(option.value);
                this.setState({ isOpen: false });
              }}
              key={option.value}
            >
              {option.label}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default onClickOutside(AccountHistoryFilter);
