import { connect } from "react-redux";
import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { fetchTeachers } from "../actions/teachers";
import RenderLoading from "../components/render_loading.jsx";
import RenderError from "../components/render_error.jsx";
import scrollToTop from "../helpers/scrollToTop";
import MetaTagsComponent from "../components/meta_tags_component";
import TeacherThumb from "../components/teacher_thumb";

class TeachersPage extends Component {
  componentDidMount() {
    scrollToTop();
    this.props.fetchTeachers();
  }

  render() {
    const { teachers, error, isFetching, pageContent } = this.props;

    if (error) return <RenderError error={error} />;
    if (isFetching) return <RenderLoading className="centered" />;

    return (
      <Fragment>
        <MetaTagsComponent
          title="SKY TING | About | Teachers"
          desc="SKY TING teachers"
          url="teachers"
        />
        <div className="Page teachers-page">
          <h2 className="Page-header">TEACHERS</h2>
          { pageContent && pageContent.subtitle &&
            <h3 className="page-subtitle">{pageContent.subtitle}</h3>
          }

          {teachers.length === 0 ? (
            <div className="Empty-result">NO TEACHERS</div>
          ) : (
            <div className="teachers-grid padding-lr">
              {teachers.map(teacher => (
                <TeacherThumb teacher={teacher} />
              ))}
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  error: state.teachers.error,
  teachers: state.teachers.teachers,
  pageContent: state.teachers.pageContent,
  isFetching: state.teachers.isFetching,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchTeachers
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeachersPage);
