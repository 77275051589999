import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { Collapse } from "react-collapse";
import VideoThumb from "../../components/video_thumb";
import arrow from "../../../../assets/images/icons/arrow_long.png";
import bookmark from "../../../../assets/images/icons/bookmark3.svg";
import bookmark_filled from "../../../../assets/images/icons/bookmark_filled.svg";
import star from "../../../../assets/images/icons/star.svg";

const NextArr = props => {
  const { className, onClick, disabled } = props;
  if (disabled) return <span />;
  return <img src={arrow} className={className} onClick={onClick} alt="Next" />;
};

const PrevArr = props => {
  const { className, onClick, disabled } = props;
  if (disabled) return <span />;
  return <img src={arrow} className={className} onClick={onClick} alt="Prev" />;
};

class VideoPageContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      videosCurrentSlide: 0,
      showDescription: false,
      nav1: null,
      nav2: null
    };
  }

  componentDidMount() {
    this.setSlider();
  }

  setSlider = async () => {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  };

  render() {
    const {
      video,
      userSubscribed,
      videoSaved,
      paidVideo,
      availableForUser,
      isLiveStream,
      propsString
    } = this.props;

    const { videosCurrentSlide, showDescription } = this.state;

    return (
      <Fragment>
        <div className="video-desc padding-lr">
          <h2 className="video-headline">{video.title}</h2>

          <div className="duration for-small">{video.duration} MINUTES</div>
          <div className="video-teachers-row">
            {video.teachers.map((teacher, index) => (
              <Fragment>
                <div className="teacher-container">
                  <div className="teacher-img-wrapper">
                    <img src={teacher.photo_url} />
                  </div>
                  <div className="teacher-name">
                    {teacher.first_name} {teacher.last_name}
                  </div>
                </div>
                {video.teachers.length > 1 &&
                  video.teachers.length > index + 1 && (
                    <span className="vertical-line" />
                  )}
              </Fragment>
            ))}
          </div>
          {!isLiveStream &&
            (userSubscribed || (paidVideo && availableForUser)) && (
              <Fragment>
                <div className="text-center">
                  <div
                    onClick={() => this.props.saveVideo()}
                    className={`save-btn for-small ${
                      videoSaved ? "saved" : ""
                    }`}
                  >
                    <img src={videoSaved ? bookmark_filled : bookmark} />
                    {videoSaved ? "SAVED" : "SAVE"}
                  </div>
                </div>
                <div className="horizontal-line for-small" />
              </Fragment>
            )}

          <div className="video-attrs-container for-small">
            <div className="video-attr level">
              <div className="column-name">level</div>
              <div className="column-value">{video.level}</div>
            </div>
            { video.intensity &&
              <div className="video-attr intensity">
                <div className="column-name">intensity</div>
                <div className="column-value">{video.intensity}</div>
              </div>
            }
            <div className="video-attr props">
              <div className="column-name">props</div>
              <div
                className="column-value"
                dangerouslySetInnerHTML={{ __html: propsString }}
              />
            </div>
          </div>

          <p
            className="video-description-text rich-text"
            dangerouslySetInnerHTML={{ __html: video.description }}
          />

          <div className="video-description-text-for-small">
            <div
              className="video-desc-block rich-text"
              dangerouslySetInnerHTML={{
                __html: video.description_for_small_1 || video.description
              }}
            />
            <Collapse isOpened={showDescription}>
              <div
                className="video-desc-block rich-text"
                dangerouslySetInnerHTML={{
                  __html: video.description_for_small_2
                }}
              />
            </Collapse>
            {video.description_for_small_2 && (
              <div
                className={`description-collapse-btn ${
                  showDescription ? "opened" : ""
                }`}
                onClick={() =>
                  this.setState({ showDescription: !showDescription })
                }
              >
                <div className="text">
                  {showDescription ? "LESS INFO" : "MORE INFO"}
                </div>
              </div>
            )}
          </div>
        </div>
        {!userSubscribed && (
          <div className="video-attrs-for-unsubscribed padding-lr">
            <div className="attr-names-row">
              <div className="attr-column">level</div>
              { video.intensity &&
                <div className="attr-column">intensity</div>
              }
              <div className="attr-column">props</div>
            </div>
            <div className="horizontal-line" />
            <div className="attr-values-row">
              <div className="attr-column">
                <img src={star} />
                {video.level}
              </div>
              { video.intensity &&
                <div className="attr-column">
                  <img src={star} />
                  {video.intensity}
                </div>
              }
              <div className="attr-column">
                <img src={star} />
                {video.props}
              </div>
            </div>
          </div>
        )}

        {video.related_videos.length > 0 && (
          <div className="related-videos padding-lr">
            <h3 className="video-page-subtitle">KEEP PRACTICING</h3>

            <div className="relate-videos-carousel">
              <Slider
                asNavFor={this.state.nav2}
                ref={slider => (this.slider1 = slider)}
                dots={false}
                infinite={video.related_videos.length > 3}
                speed={500}
                swipeToSlide
                slidesToShow={4}
                slidesToScroll={1}
                nextArrow={
                  <NextArr
                    disabled={
                      !(
                        videosCurrentSlide + 1 <=
                        video.related_videos.length - 4
                      )
                    }
                  />
                }
                prevArrow={<PrevArr disabled={videosCurrentSlide === 0} />}
                afterChange={index =>
                  this.setState({ videosCurrentSlide: index })
                }
                responsive={[
                  {
                    breakpoint: 1258,
                    settings: {
                      nextArrow: (
                        <NextArr
                          disabled={
                            !(
                              videosCurrentSlide + 1 <=
                              video.related_videos.length - 3
                            )
                          }
                        />
                      ),
                      slidesToShow: 3
                    }
                  }
                ]}
              >
                {video.related_videos.map(v => (
                  <VideoThumb video={v} hideInfo />
                ))}
              </Slider>
              <Slider
                asNavFor={this.state.nav1}
                ref={slider => (this.slider2 = slider)}
                dots={false}
                arrows={false}
                infinite={video.related_videos.length > 3}
                speed={500}
                swipeToSlide
                slidesToShow={4}
                slidesToScroll={1}
                // afterChange={index => this.setState({ videosCurrentSlide: index })}
                responsive={[
                  {
                    breakpoint: 1258,
                    settings: {
                      slidesToShow: 3
                    }
                  }
                ]}
              >
                {video.related_videos.map(v => (
                  <VideoThumb video={v} hideImage />
                ))}
              </Slider>
            </div>

            <div className="related-videos-row for-small">
              {video.related_videos.map(v => (
                <div className="related-video-el">
                  <VideoThumb video={v} />
                </div>
              ))}
            </div>
          </div>
        )}

        {video.suggested_props.length > 0 && (
          <div className="suggested-props padding-lr">
            <h3 className="video-page-subtitle">SUGGESTED PROPS</h3>
            <div className="suggested-props-row with-large-dots">
              <Slider
                dots
                infinite={video.suggested_props.length > 3}
                speed={500}
                swipeToSlide
                arrows={false}
                slidesToShow={4}
                slidesToScroll={1}
                responsive={[
                  {
                    breakpoint: 1258,
                    settings: {
                      slidesToShow: 3
                    }
                  },
                  {
                    breakpoint: 640,
                    settings: {
                      slidesToShow: 1,
                      centerPadding: "10%",
                      centerMode: true,
                      infinite: true
                    }
                  }
                ]}
              >
                {video.suggested_props.map(p => (
                  <div className="suggested-prop-wrapper">
                    <Link
                      to={p.active ? `/shop/${p.slug}` : "#"}
                      className={`suggested-prop ${p.active ? "" : "inactive"}`}
                    >
                      <div className="thumb-wrapper">
                        <img src={p.thumbnail_url} className="thumb" />
                      </div>
                      <h5 className="name">{p.name}</h5>
                      <div className="price">${p.price}</div>
                    </Link>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default VideoPageContent;
