import React  from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import classnames from 'classnames';
import { isBooked, waitlistEntry } from '../reducers/user_schedule';
import { isBookingInProgress } from '../reducers/lesson';

import Star from './../../../assets/images/icons/credit_blue.png';

const renderLabel = (props) => {
  if (props.isBooking) return <span>BOOKING...</span>

  if (props.isBooked)
    return (
      <span className="Schedule-actionLabel">
        { props.reservedLabelText &&
          props.reservedLabelText
        }
        <span className="Schedule-booked">RESERVED</span>
      </span>
    );

  if (props.waitlistEntry)
    return (
      <span className="Schedule-actionLabel">
        { props.reservedLabelText &&
          props.reservedLabelText
        }
        <span className="Schedule-booked">WAITLISTED</span>
      </span>
    );

  return props.label;
};

const BookLessonButton = (props) => {
  const waitlistEntry = props;
  const className = classnames({
    [props.className]: true,
    'is-booked': props.isBooked,
  });

  if (props.link) return (
    <a style={{ textDecoration: 'none' }} href={(props.isBooked || props.isBooking || props.loading) ? null : props.link}>
      <div
        className={className}
        disabled={props.isBooked || props.isBooking || props.loading || props.waitlistEntry}
      >
        {renderLabel(props)}
      </div>
    </a>
  )

  return (
    <button
      className={className}
      onClick={props.onClick}
      disabled={props.isBooked || props.isBooking || props.loading}
    >
      {renderLabel(props)}
    </button>
  );
};

BookLessonButton.defaultProps = {
  isBooked: false,
  isBooking: false,
  waitlistEntry: false
};

BookLessonButton.propTypes = {
  isBooked: PropTypes.bool.isRequired,
  isBooking: PropTypes.bool.isRequired,
  lessonId: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    isBooked: isBooked(ownProps.lessonId, state),
    isBooking: isBookingInProgress(ownProps.lessonId, state),
    waitlistEntry: waitlistEntry(ownProps.lessonId, state),
  };
};

export default connect(mapStateToProps)(BookLessonButton);
