import React, { Component, Fragment } from 'react';
import superagent from 'superagent';
import RenderLoading from './render_loading';

export default class PasswordRecovery extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      isLoading: false,
      error: null,
      sendSuccessfully: false,
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    this.setState({ email: event.target.value, error: null });
  }

  onSubmit = () => {
    this.setState({ isLoading: true })
    return superagent
      .post('/users/password/')
      .set({
        Accept: 'application/json',
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].getAttribute('content'),
      })
      .send({ email: this.state.email }).then(res =>
        this.setState({
          sendSuccessfully: true,
          isLoading: false,
          error: null,
        }))
      .catch((err) => {
        this.setState({
          isLoading: false,
          error: err.response.body.error,
        });
      });
  };

  render() {
    const { isLoading, error, sendSuccessfully } = this.state

    return (
      <div className="LoginForm">
        { sendSuccessfully ? (
          <Fragment>
            <br />
            <div className="reset-password-title">SUCCESS!</div>
            <br />
            <div className="success mb-5">
              We have sent you an email with reset instructions.
            </div>
            <br />
          </Fragment>
        ) : (
          <Fragment>
            <div className="reset-password-title">
              RESET PASSWORD
            </div>
            <div className="reset-password-text">
              Submit your email address and we’ll send you a link to reset your password
            </div>
            <div className="LoginForm-field mt-20 mb-4">
              <input
                type="email"
                name="email"
                className="TextField"
                placeholder="EMAIL"
                onChange={this.onChange}
              />
            </div>
            { error &&
            <div className="Registration-error" >
              {error}
            </div>
            }
            { isLoading ? (
              <RenderLoading />
            ) : (
              <div className="LoginForm-field">
                <button
                  className="SmallButton"
                  disabled={!(/\S+@\S+\.\S+/.test(this.state.email))}
                  onClick={() => this.onSubmit()}
                  style={{ height: '55px' }}
                >
                  RESET PASSWORD
                </button>
              </div>
            )}
          </Fragment>
        )}
      </div>
    );
  }
}
