export default [
  {
    id: 1,
    value: 'a friend'
  },
  {
    id: 2,
    value: 'search engines (google, yelp...)'
  },
  {
    id: 3,
    value: 'social media (facebook, insta...)'
  },
  {
    id: 4,
    value: 'ONLINE PRESS (MAGAZINE, BLOG, ...)'
  },
  {
    id: 5,
    value: 'PRINT PRESS (MAGAZINE, NEWSPAPER, ...)'
  },
  {
    id: 6,
    value: 'CLASSPASS'
  },
  {
    id: 7,
    value: 'AN EVENT'
  },
  {
    id: 8,
    value: 'SPECIAL SKY TING OFFER (GILT, NYP, ...)'
  },
  {
    id: 9,
    value: 'A SKY TING FRIEND (KATONAH, DIMES, ...)'
  },
  {
    id: 10,
    value: 'OTHER'
  },
]