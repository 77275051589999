const tvPlans = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_TV_PLANS':
      return action.plans;
    default:
      return state;
  }
};

export default tvPlans;
