import React, { Component } from "react";
import {
  submitBillingForm,
  openBillingForm,
  closeBillingForm
} from "../actions/billing";
import BillingForm from "./billing_form_new";
import { Collapse } from "react-collapse";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

class Billing extends Component {
  onSubmit = data => {
    this.props.submitBillingForm(data);
  };

  renderErrors() {
    const { error } = this.props;

    if (!error) return null;

    return <div className="BillingPopup-error mt-2">{error.message}</div>;
  }

  toggleBillingForm = () => {
    const { showBillingForm, openBillingForm, closeBillingForm } = this.props;

    if (showBillingForm) {
      closeBillingForm();
    } else {
      openBillingForm();
      if (this.props.cardIsChosen) {
        this.props.toggleCard();
        this.props.addedCard(this.props.cardIsChosen)
      }
    }
  };

  render() {
    const { showBillingForm, isLoading, user, countries } = this.props;

    return (
      <div className="Billing">
        <h2 onClick={this.toggleBillingForm} className="Billing-title mt-0">
          ENTER A NEW CARD
        </h2>
        <div
          onClick={this.toggleBillingForm}
          className={showBillingForm ? "Billing-arrow rotate" : "Billing-arrow"}
        />
        <Collapse isOpened={showBillingForm}>
          {this.renderErrors()}
          {showBillingForm && (
            <BillingForm
              onSubmit={this.onSubmit}
              isLoading={isLoading}
              user={user}
              countries={countries}
            />
          )}
        </Collapse>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    showBillingForm: state.billing.showBillingForm,
    error: state.billing.error,
    isLoading: state.billing.loading,
    user: state.user,
    countries: state.countries
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      submitBillingForm,
      openBillingForm,
      closeBillingForm
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Billing);
