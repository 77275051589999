import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { authenticationSuccess } from '../../actions/authentication';
import ForgotPassword from '../forgot_password.jsx';
import Loading from "../../../../assets/images/icons/yinyang_icon.png";
import Api from "../../api/authentication";

class LessonLoginForm extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      mode: 'login',
      error: null,
    };
  }

  onSubmit = (event) => {
    const { changeStep, authenticationSuccess } = this.props;
    event.preventDefault();

    const {
      email: { value: email },
      password: { value: password }
    } = event.target.elements

    this.setState({ loading: true, error: null });

    Api.authenticate({ email, password })
      .then((res) => {
        authenticationSuccess(res.body);
        this.setState({ loading: false })
        changeStep('lesson');
        this.props.continueBooking();
      })
      .catch((err) => {
        this.setState({
          loading: false,
          error: err.response.body.error
        })
      })
  }

  renderErrors() {
    if (!this.state.error) return null

    return (
      <div className="Registration-error">
        <Fragment>
          <span>{this.state.error}</span>
        </Fragment>
      </div>
    )
  }

  render() {
    const { changeStep } = this.props;
    const { mode } = this.state;

    return (
      <Fragment>
        { mode === 'password-recovery' ? (
          <ForgotPassword />
        ) : (
          <Fragment>
            <form className="LoginForm" onSubmit={this.onSubmit}>
              <div className="Headline">LOGIN</div>
              {this.renderErrors()}

              <div className="LoginForm-field">
                <input type="email" name="email" className="TextField" placeholder="Email" />
              </div>
              <div className="LoginForm-field">
                <input type="password" name="password" className="TextField" placeholder="Password" autoComplete="current-password" />
              </div>
              { this.state.loading ? (
                <div className="inline-loading mt-4">
                  <img src={Loading} height="21" width="21"/>
                </div>
              ) : (
                <div className="LoginForm-field">
                  <button type="submit" className="Button" value="Submit">SUBMIT</button>
                </div>
              )}
            </form>
            <br />
            {!this.state.loading &&
              <div className="LoginForm">
                <div className="LoginForm-field">
                  <button onClick={() => changeStep('register')} type="submit"
                          className="Button Register-button">REGISTER!
                  </button>
                </div>
                <div className="LoginForm-field">
                  <div
                    className="LoginForm-link"
                    onClick={() => this.setState({ mode: 'password-recovery'})}
                  >
                    Forgot password?
                  </div>
                </div>
              </div>
            }
          </Fragment>
        )}
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      authenticationSuccess
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(LessonLoginForm)