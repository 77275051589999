import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import teacherPlaceholder from "../../../assets/images/teacher_placeholder.png";

class TeacherThumb extends Component {
  renderTeacherPhoto = teacher => {
    const imgSrc = teacher.photo_url || teacher.hover_photo_url || teacher.image_url || teacherPlaceholder;

    return (
      <Fragment>
        <img className="main-image" src={imgSrc} />
        { teacher.hover_photo_url &&
          <img className="hover-image" src={teacher.hover_photo_url} />
        }
      </Fragment>
    );
  };

  render() {
    const { teacher } = this.props;

    return (
      <div className="teacher-thumb" key={teacher.id}>
        <Link to={`/teachers/${teacher.slug}`} className="teacher-photo">
          {this.renderTeacherPhoto(teacher)}
        </Link>
        <Link to={`/teachers/${teacher.slug}`} className="teacher-name">
          {teacher.name}
        </Link>
      </div>
    );
  }
}

export default TeacherThumb;
