import React, { Component, Fragment } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import teacherPlaceholder from "./../../../assets/images/teacher_placeholder.png";
import { isAuthenticated } from "../reducers/user";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import momentTimezone from "moment-timezone";
import { fetchLiveSchedule } from "../actions/live_schedule";
import instagram from "../../../assets/images/icons/instagram_black.svg";
import calendar from "../../../assets/images/icons/calendar_black.svg";

import RenderLoading from "./render_loading";
import _ from "lodash";
import Popup from "./popup";
import { history } from "../store/configureStore";
import Lesson from '../models/schedule';

import LoginForm from './lesson_booking/tv_live_login_form';
import RegistrationForm from "./lesson_booking/tv_live_register";
import ServicePayment from "../containers/tv_live_service_payment_login";
import Payment from "../containers/tv_live_payment_signup";
import { fetchTvPlans } from "../actions/tv_plans";
import Loading from './../../../assets/images/icons/yinyang_icon.png';
import Reserved from './../../../assets/images/icons/Check.png';
import Dollar from "../../../assets/images/dollar-coin.png";
import superagent from "superagent";
import PurchaseList from "./lesson_booking/purchase_list";

class LiveScheduleShort extends Component {
  state = {
    isLoading: true,
    openTvPopup: false,
    selectedLesson: [],
    error: '',
    step: 'TvLivePopup',
    selectedService: []
  };

  componentDidMount() {
    const { full } = this.props;
    this.getLiveStreamSchedule(full ? null : 5);
    this.getTvPlans();
  }

  getTvPlans = () => {
    return this.props
      .fetchTvPlans()
      .then(res => this.setState({ isLoading: false }))
      .catch(err => this.setState({ isLoading: false, error: err }));
  };

  getLiveStreamSchedule = (limit = null) => {
    this.setState({ isLoading: true });
    this.props
      .fetchLiveSchedule(limit)
      .then(() => {
        this.setState({
          isLoading: false
        });
      })
      .catch(err => {
        this.setState({
          isLoading: false,
          error: err.response.body
        });
      });
  };


  renderCurrentStep = () => {
    // const { lesson } = this.props
    // const { showWaitlist } = this.state;
    const monthPlans = this.props.tvPlans.filter(p => p.interval === "month");

    switch (this.state.step) {
      case 'login':
        return <LoginForm
          changeStep={(step) => this.changeStep(step)}
          continueBooking={() => this.getLiveSchedulePage()}
        />
      case 'register':
        return <RegistrationForm
          changeStep={(step) => this.changeStep(step)}
        />
      case 'purchase':
        return <PurchaseList
          nextStep={(service) => this.nextStep(service)}
          selectService={(service) => this.setState({
            selectedService: [service]
          })}
        />
      case 'payment':
        return <Payment
          selectedPlan={monthPlans[0]}
          continueBooking={() => this.getLiveSchedulePage()}
        />
      case 'billing':
        return <ServicePayment
          activeService={this.state.selectedService}
          continueBooking={() => this.getLiveSchedulePage()}
        />;
      case 'success':
        return this.renderSuccess();
      case 'successWaitlist':
        return this.renderSuccessJoinwaitlist();
      case 'loading':
        return this.renderLoading();
      default:
        return this.renderPopup()
    }
  }

  changeStep = (step) => {
    this.setState({ step })
  }

  nextStep = (service) => {
    if (service.id == '1') {
      this.changeStep('billing')
    }
    else {
      this.changeStep('payment')
    }
  };

  getLiveSchedulePage = () => {
    const pathname = window.location.pathname;
    const search = (window.location.search).replace("?", "/");
    const video_id = (window.location.search).replace("?", "");
    return superagent
      .get(`/live_schedule_page/${video_id}`)
      .set("Accept", "application/json")
      .send()
      .then(res => {
        if ((res.body.subscribed == true && res.body.paidForClass == true) || (res.body.subscribed != true && res.body.paidForClass == true)) {
          history.push({
            pathname: `${pathname + search}`,
            state: { cameFrom: (pathname) }
          })
        }
        else if (res.body.subscribed == true && res.body.paidForClass != true) {
          this.changeStep('billing')
        }
        else {
          this.changeStep('purchase')
        }
      });
  };

  renderPopup = () => {
    const { selectedLesson } = this.state;

    return (
      <div className="Lesson">
        <div className="Lesson-title">{selectedLesson.data.title}</div>
        <div className="Lesson-subtitle">
          {`${selectedLesson.startDay} @ ${selectedLesson.startTime} - ${selectedLesson.endTime}`}
        </div>

        {selectedLesson.data.premium ? (
          <>
            <div className="Tv-lesson-description">
              You're about to Live Stream straight from our studio in NYC.<br />
              These Live Stream classes are $5 for SKY TING TV subscribers.<br />
              Not a subscriber? You can still access this class for $15 a la carte.<br />
              Become a SKY TING TV subscriber and get access to hundreds of classes in our library, free zoom classes, and the $5 subscriber rate on Live Stream classes.
            </div>

            <button className="Button tv-signup-btn p-5" title="Sign up" onClick={this.signUpButtonHandler}>
              ACCESS THIS CLASS OR SUBSCRIBE TO SKY TING TV
            </button>

            <div className="Tv-lesson-login-btn" onClick={this.loginButtonHandler}>
              <u>AlREADY HAVE AN ACCOUNT? SIGN IN HERE</u>
            </div>
          </>
        ) : (
          <>
            <div className="Tv-lesson-description">
              YOU NEED TO BE A SKY TING TV SUBSCRIBER TO ACCESS THIS CLASS. PLEASE SIGN UP OR SIGN IN TO JOIN.
            </div>

            <button className="Button tv-signup-btn p-5" title="Sign up" onClick={this.basicSignUpButtonHandler}>
              SIGN UP
            </button>

            <div className="Tv-lesson-login-btn" onClick={this.basicLoginButtonHandler}>
              <u>AlREADY HAVE AN ACCOUNT?</u>
            </div>
          </>
        )
        }

        <Fragment>
          <div className="Lesson-staff-image">
            <img
              src={
                selectedLesson.data.teachers[0]
                  ? selectedLesson.data.teachers[0].photo_url ||
                  selectedLesson.data.teachers[0].image_url ||
                  teacherPlaceholder
                  : teacherPlaceholder
              }
            />
          </div>
        </Fragment>

        {selectedLesson.data.teachers[0] &&
          <div className="Button inactive-teacher" >
            {selectedLesson.data.teachers[0].name}
          </div>}
      </div >
    )
  }

  renderLoading = () => {
    return (
      <div>
        <div className='Loading-container centered'>
          <img src={Loading} height="21" width="21" alt="Loading" />
          <div>LOADING...</div>
        </div>
      </div>
    )
  }

  renderSuccess = lesson => {
    history.push(`/tv/live?${lesson.slug}`)
  }

  renderLessonBtn = lesson => {
    const btnText = moment.utc(lesson.start_date_time).format("h:mm A");

    return (
      <div className={`lesson-btn ${lesson.is_live_now ? "" : "inactive"}`}>

        {lesson.is_live_now ? "LIVE" : btnText}
        {lesson.is_instagram ? (
          <img src={instagram} className="btn-icon" />
        ) : (
          <>
            {lesson.is_live_now ? (
              <span className="btn-icon triangle" />
            ) : (
              <img src={calendar} className="btn-icon" />
            )}
          </>
        )}
      </div>
    );
  };

  renderClassContent = (lesson, purchasedlesson = false) => {
    return (
      <Fragment>
        <div className="lesson-column first-column">
          <div className="lesson-teacher">
            <img
              className="lesson-teacher-image"
              src={
                lesson.teachers[0]
                  ? lesson.teachers[0].photo_url ||
                  lesson.teachers[0].image_url ||
                  teacherPlaceholder
                  : teacherPlaceholder
              }
            />
            <div className="lesson-teacher-name">
              {lesson.teachers[0] ? lesson.teachers[0].name : ""}
            </div>
          </div>
        </div>
        <div className="lesson-column live-class-name pl-2 pr-2">
          {lesson.title}
        </div>
        {!this.props.showDates &&
          <div className="lesson-column live-class-name pl-2 pr-2">
            {moment.utc(lesson.start_date_time).format("MMMM D")}
          </div>
        }
        <div className="lesson-column last-column justify-content-end">
          {this.showIcon(lesson, purchasedlesson)}
          {this.renderLessonBtn(lesson)}
        </div>
      </Fragment>
    );
  };

  showIcon = (lesson, purchasedlesson) => {
    if (lesson.premium && purchasedlesson) {
      return <img src={Reserved} className="btn-icon mr-4" />
    }
    else if (lesson.premium && !purchasedlesson) {
      return <img src={Dollar} className="btn-icon" />
    }
  }

  closePopup = () => {
    this.setState({ openTvPopup: false, selectedLesson: [] });
    this.changeStep('TvLivePopup');
    history.push('/tv/live')
  }

  renderClasses = lesson => {
    const { subscribed, isAuthenticated } = this.props;

    return (
      <>
        {lesson.free || subscribed || isAuthenticated ? (
          <>
            {lesson.is_instagram ? (
              <a href={lesson.url} className="no-hover live-schedule-short-lesson" target="_blank">
                {this.renderClassContent(lesson)}
              </a>
            ) : (
              <>
                {this.renderClassDetails(lesson)}
              </>
            )}
          </>
        ) : (
          <div className="no-hover live-schedule-short-lesson"
            onClick={() => { this.setState({ openTvPopup: true, selectedLesson: new Lesson(lesson) }), history.push(`/tv/live?${lesson.slug}`) }}>
            {this.renderClassContent(lesson)}
          </div>
        )}
      </>
    );
  };

  renderClassDetails = lesson => {
    const { subscribed, purchasedVideos } = this.props;
    let purchasedlesson = false
    if (purchasedVideos.length > 0) {
      const found = purchasedVideos.findIndex(v => v.live_stream_video_id === lesson.id)
      purchasedlesson = found !== -1 ? true : false
    }

    if ((subscribed && purchasedlesson) || lesson.free || (!subscribed && purchasedlesson) || !lesson.premium) {
      return (
        <Link
          to={`/tv/live/${lesson.slug}`}
          className="no-hover live-schedule-short-lesson"
        >
          {this.renderClassContent(lesson, purchasedlesson)}
        </Link>
      )
    }
    else if (subscribed && !purchasedlesson) {
      return (
        <div className="no-hover live-schedule-short-lesson"
          onClick={() => { this.setState({ openTvPopup: true, selectedLesson: new Lesson(lesson), step: 'billing' }), history.push(`/tv/live?${lesson.slug}`) }}>
          {this.renderClassContent(lesson)}
        </div>
      )
    }
    else {
      return (
        <div className="no-hover live-schedule-short-lesson"
          onClick={() => { this.setState({ openTvPopup: true, selectedLesson: new Lesson(lesson), step: 'purchase' }), history.push(`/tv/live?${lesson.slug}`) }}>
          {this.renderClassContent(lesson)}
        </div>
      )
    }
  }

  groupedLessons = lessons => {
    const sorted_lessons = _.chain(lessons)
      .sortBy("start_date_time")
      .groupBy(({ start_date_time }) =>
        moment.utc(start_date_time).format("dddd, MMM DD YYYY")
      )
      .value();

    return sorted_lessons;
  };

  filterLessons = (lessons) => {
    const { excludedVideoId } = this.props;

    const currentTime = momentTimezone().tz('America/New_York')
    return lessons.filter(l => {
      if (excludedVideoId && excludedVideoId === l.slug) return false;

      const date = momentTimezone.tz(l.start_time_ny_tz, "America/New_York");
      const endOfDay = currentTime.endOf('day')
      return date.isBefore(endOfDay)
    });
  }

  signUpButtonHandler = () => {
    // const pathname = window.location.pathname;
    // const search = (window.location.search).replace("?", "/");
    // history.push({
    //   pathname: "/signup",
    //   state: { cameFrom: (pathname+search) }
    // })
    this.changeStep('register');
  }

  loginButtonHandler = () => {
    // const pathname = window.location.pathname;
    // const search = (window.location.search).replace("?", "/");
    // history.push({
    //   pathname: "/login",
    //   state: { cameFrom: (pathname+search) }
    // })
    this.changeStep('login');
  }

  basicSignUpButtonHandler = () => {
    const pathname = window.location.pathname;
    const search = (window.location.search).replace("?", "/");
    history.push({
      pathname: "/signup",
      state: { cameFrom: (pathname + search) }
    })
  }

  basicLoginButtonHandler = () => {
    const pathname = window.location.pathname;
    const search = (window.location.search).replace("?", "/");
    history.push({
      pathname: "/login",
      state: { cameFrom: (pathname + search) }
    })
  }
  render() {
    const { isLoading, openTvPopup, selectedLesson } = this.state;
    const {
      lessons,
      imageLeft,
      subheader,
      hideLoading,
      showDates,
      fullWidth,
      todayOnly
    } = this.props;

    let increment = 0;

    if (hideLoading && (isLoading || lessons.length === 0)) return <div />;
    if (!isLoading && lessons.length === 0) return <div />;

    const filteredLessons = todayOnly ? this.filterLessons(lessons) : lessons;
    const formattedLessons = showDates ? this.groupedLessons(filteredLessons) : filteredLessons;

    return (
      <Fragment>
        <div
          className={`live-schedule-short ${isLoading ? "loading" : ""} ${fullWidth ? "full-width" : ""
            }`}
        >
          {imageLeft && (
            <div className="image-left-container">
              <div className="image-left-wrapper">
                <img src={imageLeft} className="image-left" />
              </div>
            </div>
          )}
          <div className="live-schedule-short-wrapper tv-dashboard-section padding-lr">
            {subheader && (
              <div className="small-size-subheader">{subheader}</div>
            )}
            {showDates ? (
              <Fragment>
                {_.map(formattedLessons, (dayLessons, day) => (
                  <Fragment key={increment++}>
                    <div className="live-schedule-day-header">
                      {todayOnly ? "UPCOMING CLASSES" : day}
                    </div>
                    {dayLessons.map(v => (
                      <Fragment key={v.id}>{this.renderClasses(v)}</Fragment>
                    ))}
                  </Fragment>
                ))}
              </Fragment>
            ) : (
              <Fragment>
                {lessons.map(v => (
                  <Fragment key={v.id}>{this.renderClasses(v)}</Fragment>
                ))}
              </Fragment>
            )}

            {isLoading && !hideLoading && (
              <RenderLoading className={lessons.length > 0 ? "mt-5" : ""} />
            )}
            <div className="mt-5" />
          </div>
        </div>
        {openTvPopup && <Popup
          key={selectedLesson.data.id}
          id={selectedLesson.data.id}
          isOpen={openTvPopup}
          onClose={this.closePopup}
        >
          {this.renderCurrentStep()}
        </Popup>
        }
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: isAuthenticated(state),
  lessons: state.liveSchedule.lessons,
  isFullSchedule: state.liveSchedule.isFullSchedule,
  tvPlans: state.tvPlans
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchTvPlans,
      fetchLiveSchedule
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LiveScheduleShort);
