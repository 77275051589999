import React, { Component } from 'react';

export default class Hoverable extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className="Hoverable-container">
        <div className="Item">
          {this.props.children}
        </div>
        <div className="Overlay" style={this.props.style}>
          {this.props.hoverText}
        </div>
      </div>
    )
  }
}
