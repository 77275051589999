import React, { Component } from "react";
import Slider from "react-slick";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchShopPromoSpots } from "../actions/shop_promo_spots";

class ShopPromoSpots extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    this.props
      .fetchShopPromoSpots()
      .then(res => {
        this.setState({
          loading: false
        });
        if (this.props.onLoad) {
          this.props.onLoad();
        }
      })
      .catch(() => {
        this.setState({ loading: false });
        if (this.props.onLoad) {
          this.props.onLoad();
        }
      });
  }

  render() {
    const { show, slides, pages } = this.props;
    const { loading } = this.state;

    const filteredSlides = slides.filter(s => pages.includes(s.page));

    if (filteredSlides.length === 0 || loading || !show) return <div />;

    return (
      <div className="shop-promo-slider with-large-dots">
        <Slider
          dots
          infinite
          speed={1000}
          fade
          autoplay
          swipeToSlide
          arrows={false}
        >
          {filteredSlides.map(slide => (
            <a href={slide.url} className="shop-promo-slide" key={slide.id}>
              <div className="text" style={{ color: slide.text_color_hex ? slide.text_color_hex : 'white' }}>
                {slide.text}
              </div>
              { slide.overlay &&
              <div className="shop-promo-slider-overlay" />
              }
              <img src={slide.file_url} className="img-background" />
            </a>
          ))}
        </Slider>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  slides: state.shopPromoSpots.slides
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchShopPromoSpots
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShopPromoSpots);
