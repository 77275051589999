import React, {Component, Fragment} from 'react'
import teamCredits from '../data/teamCredits';
import scrollToTop from '../helpers/scrollToTop';
import MetaTagsComponent from "../components/meta_tags_component";

export default class Credits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    scrollToTop();
  }

  render() {
    return (
      <Fragment>
        <MetaTagsComponent
          title="SKY TING | About | Credits"
          desc="SKY TING credits"
          url="credits"
        />
        <div className="Page Credits">
          <div className="Page-content">
            <h2 className="Page-header">CREDITS</h2>
            <div className="Credits-content">
              <div className="Title">Founders</div>
              { teamCredits.find(t => t.type === 'founders').staff.map(staff => (
                <div className="Inner-wrapper" key={`founders-${staff.name}`}>
                  <div className="Position">{staff.position}</div>
                  <div className="Dotted">&nbsp;</div>
                  <div className="Name">{staff.name}</div>
                </div>
              ))}

              <div className="Title">Managers</div>
              { teamCredits.find(t => t.type === 'managers').staff.map(staff => (
                <div className="Inner-wrapper" key={`managers-${staff.name}`}>
                  <div className="Position">{staff.position}</div>
                  <div className="Dotted">&nbsp;</div>
                  <div className="Name">{staff.name}</div>
                </div>
              ))}
              <div className="Title">Designers</div>
              { teamCredits.find(t => t.type === 'designers').staff.map(staff => (
                <div className="Inner-wrapper" key={`designers-${staff.name}`}>
                  <div className="Position">{staff.position}</div>
                  <div className="Dotted">&nbsp;</div>
                  <div className="Name">{staff.name}</div>
                </div>
              ))}

              <div className="Title">MENTOR</div>
              <div className="Inner-wrapper">
                <div className="Position">MENTOR</div>
                <div className="Dotted">&nbsp;</div>
                <div className="Name">NEVINE MICHAAN</div>
              </div>

              <div className="Title">TEACHER TRAINING LECTURERS</div>
              { teamCredits.find(t => t.type === 'lecturers').staff.map(staff => (
                <div className="Inner-wrapper" key={`lecturers-${staff}`}>
                  <div className="Position">LECTURER</div>
                  <div className="Dotted">&nbsp;</div>
                  <div className="Name">{staff}</div>
                </div>
              ))}

              <div className="Title">TEACHER TRAINING MENTORS</div>
              { teamCredits.find(t => t.type === 'mentors').staff.map(staff => (
                <div className="Inner-wrapper" key={`mentors-${staff}`}>
                  <div className="Position">MENTOR</div>
                  <div className="Dotted">&nbsp;</div>
                  <div className="Name">{staff}</div>
                </div>
              ))}

              <div className="Title">TEACHER TRAINING ASSISTANT MENTORS</div>
              { teamCredits.find(t => t.type === 'assistant mentors').staff.map(staff => (
                <div className="Inner-wrapper" key={`assistant-${staff}`}>
                  <div className="Position">ASSISTANT MENTOR</div>
                  <div className="Dotted">&nbsp;</div>
                  <div className="Name">{staff}</div>
                </div>
              ))}

              <div className="Title">MENTORSHIP MENTORS</div>
              { teamCredits.find(t => t.type === 'mentorship mentors').staff.map(staff => (
                <div className="Inner-wrapper" key={`mentorship-${staff}`}>
                  <div className="Position">MENTOR</div>
                  <div className="Dotted">&nbsp;</div>
                  <div className="Name">{staff}</div>
                </div>
              ))}


              <div className="Title">TEACHERS</div>
              { teamCredits.find(t => t.type === 'teachers').staff.map(staff => (
                <div className="Inner-wrapper" key={`teachers-${staff}`}>
                  <div className="Position">TEACHER</div>
                  <div className="Dotted">&nbsp;</div>
                  <div className="Name">{staff}</div>
                </div>
              ))}

              <div className="Title">FRONT DESK STAFF</div>
              { teamCredits.find(t => t.type === 'front desk').staff.map(staff => (
                <div className="Inner-wrapper" key={`frontdesk-${staff}`}>
                  <div className="Position">FRONT DESK</div>
                  <div className="Dotted">&nbsp;</div>
                  <div className="Name">{staff}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
