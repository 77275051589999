import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import superagent from "superagent";
import { bindActionCreators } from "redux";
import { isAuthenticated } from "../reducers/user";
import scrollToTop from "../helpers/scrollToTop";
import MetaTagsComponent from "../components/meta_tags_component";
import GiftCardRedeem from "../components/gift_card_redeem/gift_card_redeem_popup";
import RenderLoading from "../components/render_loading";
import HomePageContent from "../components/home_page_content_new";
import { fetchUserTvSubscription } from "../actions/user_subscriptions";
import MailChimpPopup from "../containers/mail_chimp_popup";
import { fetchMailChimp } from "../actions/mail_chimp";

class HomePageNew extends Component {
  constructor(props) {
    super(props);

    const searchParams = queryString.parse(this.props.location.search);

    this.state = {
      content: null,
      isLoading: true,
      giftCardToken: searchParams ? searchParams.token : null,
      showGiftCardRedeem: !!searchParams.token,
      cascadeBlocks: [],
      latestVideos: [],
      videosCount: 0,
      subscribed: null,
      error: null,
    };
  }

  componentDidMount() {
    scrollToTop();
    this.getSubscription();
  }

  componentWillMount() {
    this.props.fetchMailChimp();
  }

  getSubscription = () => {
    this.props
      .fetchUserTvSubscription()
      .then((res) => {
        this.getHomePageContent();

        if (res.status === "active") {
          this.setState({
            subscribed: true,
          });
        }
      })
      .catch((err) => {
        this.setState({
          error: err,
        });
      });
  };

  getHomePageContent = () => {
    return superagent
      .get("/home_page_content")
      .set("Accept", "application/json")
      .send()
      .then((res) => {
        this.getCascadeBlocks();
        this.setState({
          content: res.body,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          error: error.response.body || "Something went wrong",
          isLoading: false,
        });
      });
  };

  getCascadeBlocks = () => {
    return superagent
      .get("/home_cascade_blocks")
      .set("Accept", "application/json")
      .query({ page_type: "home" })
      .then((res) => {
        this.getLatestVideos();
        this.setState({
          cascadeBlocks: res.body,
          cascadeBlocksLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          cascadeBlocksError: error.response.body,
          cascadeBlocksLoading: false,
        });
      });
  };

  getLatestVideos = () => {
    return superagent
      .get("/latest_videos")
      .set("Accept", "application/json")
      .query({ amount: 6 })
      .then((res) =>
        this.setState({
          latestVideos: res.body.latest_videos,
          videosCount: res.body.videos_count,
        })
      )
      .catch((error) => {
        this.setState({
          latestVideosError: error.response.body,
        });
      });
  };

  render() {
    const {
      showGiftCardRedeem,
      giftCardToken,
      content,
      cascadeBlocks,
      isLoading,
      latestVideos,
      videosCount,
      subscribed,
      error,
    } = this.state;

    if (error)
      return (
        <div className="home-page">
          <div className="home-page-content">
            <div className="text-center mt-5 mb-5 color-red text-uppercase">
              {error}
            </div>
          </div>
        </div>
      );

    return (
      <Fragment>
        <MetaTagsComponent
          title="SKY TING | Practice anytime. Anywhere."
          desc="SKY TING | Practice anytime. Anywhere."
        />
        {!this.props.isFetching &&
          this.props.showMailChimpPopup &&
          this.props.isAuthenticated && <MailChimpPopup />}
        {isLoading ? (
          <RenderLoading className="centered" />
        ) : (
          <HomePageContent
            content={content}
            cascadeBlocks={cascadeBlocks}
            latestVideos={latestVideos}
            videosCount={videosCount}
            subscribed={subscribed}
            isAuthenticated={this.props.isAuthenticated}
          />
        )}
        {showGiftCardRedeem && (
          <GiftCardRedeem
            onClose={() => {
              this.setState({ showGiftCardRedeem: false });
              this.props.history.push("/");
            }}
            giftCardToken={giftCardToken}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: isAuthenticated(state),
  subscription: state.userTvSubscription,
  showMailChimpPopup: state.mailChimp.isMailChimpPopupOpen,
  isFetching: state.mailChimp.isFetching,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchUserTvSubscription,
      fetchMailChimp,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomePageNew);
