import React, { Component } from 'react';
import ToggleCard from './toggle_card.jsx';
import { Collapse } from 'react-collapse';
import { programTeachersNames } from '../helpers/program_teachers_names';
import moment from 'moment/moment';

import Slider from 'react-slick';

export default class RetreatCard extends Component {
  renderApplyButton = () => {
    const { retreat } = this.props;
    if (retreat.sold_out) return(
      <button className="Button" disabled>
        SOLD OUT
      </button>
    )

    if (!retreat.wetravel_link) return <div />;

    return (
      <a href={retreat.wetravel_link} className="Button" target="_blank">
        RESERVE A SPOT
      </a>
    );
  };

  render() {
    const {
      policy,
      retreat: { photos, rooms, teachers },
      retreat,
      activeProgram,
      selectProgram,
      deselectProgram
    } = this.props;

    const isActive = activeProgram ? activeProgram.id === retreat.id : false;

    return (
      <div className="Program-card" key={retreat.id} id={retreat.slug}>
        <div className="Visible-part">
          <div className="Image-block For-mobile">
            {photos && photos.length > 0 && photos[0].image_url && (
              <div
                className="Program-card-image-wrapper cursor-pointer"
                style={{ backgroundImage: `url(${photos[0].image_url})` }}
                onClick={() => selectProgram(retreat)}
              />
            )}
          </div>
          <div className="Block-wrapper">
            <div className="Left-block">
              <div>
                <h3
                  className="Program-card-header cursor-pointer"
                  onClick={() => selectProgram(retreat)}
                >
                  {retreat.name}
                </h3>
                <div className="Info">
                  <p>
                    <span className="Bold">WHEN/&nbsp;</span>
                    <span>
                      {moment.utc(retreat.start_date_time).format('MMM. D')} -{' '}
                      {moment.utc(retreat.end_date_time).format('MMM. D, YYYY')}
                    </span>
                  </p>
                  {teachers.length > 0 && (
                    <p>
                      <span className="Bold">W/&nbsp;</span>
                      {programTeachersNames(teachers).map(t => (
                        <span>{t}</span>
                      ))}
                    </p>
                  )}
                </div>
                {this.renderApplyButton()}

                {isActive ? (
                  <div
                    className="Button Button--secondary active-btn For-large"
                    onClick={() => deselectProgram()}
                  >
                    LEARN LESS
                  </div>
                ) : (
                  <div
                    className="Button Button--secondary For-large"
                    onClick={() => selectProgram(retreat)}
                  >
                    LEARN MORE
                  </div>
                )}
                <div
                  className="Button Button--secondary For-small"
                  onClick={() => selectProgram(retreat)}
                >
                  LEARN MORE
                </div>
              </div>
            </div>
            <div className="Right-block">
              {photos && photos.length > 0 && photos[0].image_url ? (
                <div
                  className="Program-card-image-wrapper cursor-pointer"
                  style={{ backgroundImage: `url(${photos[0].image_url})` }}
                  onClick={() => selectProgram(retreat)}
                />
              ) : (
                <div className="Program-card-image-wrapper empty" />
              )}
            </div>
          </div>
        </div>
        <Collapse isOpened={isActive}>
          {isActive &&
            <div className="Expanded-part">
              {photos && photos.length > 0 && (
                <div className="Program-image-slider Image-slider">
                  <Slider
                    dots={false}
                    infinite={true}
                    speed={500}
                    fade={true}
                    cssEase={'ease-in'}
                    arrows={photos.length > 1}
                  >
                    {photos.map(photo => (
                      <div key={`${photo.id}-photo`} className="Slider-item-image">
                        <img src={photo.image_url} />
                      </div>
                    ))}
                    {retreat.video_url && (
                      <div key={`${retreat.id}-video`} className="Slider-item-video">
                        <iframe src={retreat.video_url}></iframe>
                      </div>
                    )}
                  </Slider>
                </div>
              )}
              <div
                className="Description-text LargeBodyText"
                dangerouslySetInnerHTML={{ __html: retreat.description }}
              />
              <div className="Expanded-cards">
                {retreat.includes && (
                  <ToggleCard key={'includes-' + retreat.id} header="INCLUDES">
                    <div
                      className="Retreat-includes"
                      dangerouslySetInnerHTML={{ __html: retreat.includes }}
                    />
                  </ToggleCard>
                )}
                {retreat.extras && (
                  <ToggleCard key={'extras-' + retreat.id} header="EXTRAS">
                    <div
                      className="Extras-container"
                      dangerouslySetInnerHTML={{ __html: retreat.extras }}
                    />
                  </ToggleCard>
                )}
                {retreat.transportation && (
                  <ToggleCard key={'transportation-' + retreat.id} header="TRANSPORTATION">
                    <div
                      dangerouslySetInnerHTML={{ __html: retreat.transportation }}
                      style={{ textAlign: 'left' }}
                    />
                  </ToggleCard>
                )}
                {rooms && rooms.length > 0 && (
                  <ToggleCard key={'rooms-' + retreat.id} header="ROOMS & PRICING">
                    <Slider
                      dots={false}
                      infinite
                      speed={300}
                      cssEase={'ease-in'}
                      arrows={rooms.length > 1}
                      className="Program-block-slider Image-slider"
                      slidesToScroll={1}
                    >
                      {rooms.map(room => (
                        <div key={`${room.id}-room`} className="Card">
                          <div className="Card-image">
                            <img src={room.photo.url} />
                          </div>
                          <div className="Rooms-card-bottom">
                            <div className="Price">
                              {room.description} - ${room.price}
                            </div>
                            {retreat.wetravel_link && (
                              <a
                                href={retreat.wetravel_link}
                                className="Button Button--secondary"
                                target="_blank"
                              >
                                RESERVE
                              </a>
                            )}
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </ToggleCard>
                )}
              </div>
              {/*<div className="Discount-container">*/}
              {/*<div className="Discount-inner-container">*/}
              {/*<div className="SubHeadline">Early-Bird Discount</div>*/}
              {/*<div className="BodyText">$100 off if registered by August 15, 2016</div>*/}
              {/*</div>*/}
              {/*</div>*/}
              {this.renderApplyButton()}

              {policy && (
                <div className="Policy-text">
                  <div className="SubHeadline">CANCELLATION POLICY</div>
                  <div className="SmallBodyText">{policy.text}</div>
                </div>
              )}
            </div>
          }
        </Collapse>
      </div>
    );
  }
}
