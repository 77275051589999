export default [
  {
    type: 'founders',
    staff: [
      { position: 'CO-FOUNDER/YOGA DIRECTOR', name: 'KRISSY JONES' },
      { position: 'CO-FOUNDER/PROGRAMS DIRECTOR', name: 'CHLOE KERNAGHAN' },
      { position: 'CO-FOUNDER/CREATIVE DIRECTOR', name: 'NICK POE' },
    ],
  },
  {
    type: 'managers',
    staff: [
      { position: 'COMMUNITY DIRECTOR', name: 'RICHELLE RICE' },
      { position: 'OPERATIONS DIRECTOR', name: 'EMILY FEINBERG' },
      { position: 'WEEKEND MANAGER', name: 'ZOE JOHNSTON' },
      { position: 'BUSINESS MANAGER', name: 'PATRICK FOLEY' },
    ],
  },
  {
    type: 'designers',
    staff: [
      { position: 'ARCHITECTURAL DESIGNER', name: 'NICK POE / SPIRULINA STUDIO' },
      { position: 'GRAPHIC DESIGNER', name: 'GREGORY PASSUNTINO / SPIRULINA STUDIO' },
      { position: 'WEB DEVELOPMENT', name: 'ILYAS DJEDDOU & KHALID MENIRI / SIX' },
    ],
  },
  {
    type: 'lecturers',
    staff: [
      'NEVINE MICHAAN', 'ALLY BOGARD', 'KUMI SAWYERS', 'ALEX SHARRY', 'CHRISTOPHER GOLDEN',
      'JENN TARDIF', 'DAGES JUVELIER-KEATES', 'NATE MARTINEZ',
      'EVA GIORGI', 'ABBY PALOMA', 'MONICA PIRANI', 'DANIELLE ROSATI', 'CAPRICE ABOWITT',
      'NOEL GRAUPNER', 'LINDSAY TYSON '
    ],
  },
  {
    type: 'mentors',
    staff: [
      'KRISSY JONES', 'CHLOE KERNAGHAN', 'JESS TANZER', 'BETH COOKE', 'KATE POSCH'
    ],
  },
  {
    type: 'assistant mentors',
    staff: [
      'ROSEMARY DORSETT', 'PATRICK FOLEY', 'KATHRYN CAMACHO', 'ANDREA ZUVELLA', 'SELENA MAISONPIERRE'
    ]
  },
  {
    type: 'mentorship mentors',
    staff: [
      'JENN TARDIF', 'PATRICK FOLEY', 'GRACE DUBERY', 'LINDSAY TYSON', 'KATE POSCH', 'ANDREA ZUVELLA', 'SOPHIE GREEN'
    ]
  },
  {
    type: 'teachers',
    staff: [
      'Abby Paloma',
      'Alex Cohen',
      'Alexandra Sharry',
      'Alice Kim',
      'Ally Bogard',
      'Amber Gregory',
      'Andrea Russell',
      'Andrea Zuvella',
      'Anton Brandt',
      'Beth Cooke',
      'Bonnie Croetzer',
      'Brian Nygard',
      'Cat Meyer',
      'Chloe Kernaghan',
      'Christopher Golden',
      'Dages Juvelier Keates',
      'Danny Johnston',
      'Elise Diamantini',
      'Elise Sokolow',
      'Emily Feinberg',
      'Emily Giovine',
      'Evelyn Horan',
      'Faye Rex',
      'Francesca Valarezo',
      'Grace Dubery',
      'Grace Pandola',
      'Jason Olson',
      'Jenn Tardif',
      'Jeré Davis',
      'Jessica Tanzer',
      'Joanna Ross-Tash',
      "Kate O'Connor",
      'Kate Posch',
      'Kathryn Camacho',
      'Krissy Jones',
      'Kristin Mitchell',
      'Laura Borinsky',
      'Lindsay Tyson (Buehler)',
      'Lip Orem',
      'Lydia Neubauer',
      'Maya Moverman',
      'Nikki Calonge',
      'Patrick Foley',
      'Rhoda Murphy',
      'Rita Murjani',
      'Rosemary Dorsett',
      'Sara Blumenkranz',
      'Selena Masionpierre',
      'Sophie Green',
      'Sophie Kos',
      'Theodora Mirane',
      'Tommy Lucas',
      'Vincent Bonanno',
      'Zoe Silverman',
    ],
  },
  {
    type: 'front desk',
    staff: [
      'Alice Fung',
      'Aurelia Pollier',
      'Bianca Khan',
      'Bryan Hogan',
      'Hannah Hultman',
      'Isabel Johnston',
      'Jillian Matluck',
      'Jillian Billard',
      'Kuvonn Richardson',
      'Kyla Walker',
      'Madison Boyce',
      'Margot Solmssen',
      'Roni Robas',
      'Sonja Liljegren',
    ],
  },
]