const INITIAL_STATE = {
  subscription: null,
  status: null,
  error: null,
  receivedAt: null,
  isFetching: false,
};

const userTvSubscription = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "FETCH_TV_SUBSCRIPTION":
      return {
        ...state,
        isFetching: true,
      };
    case "SET_TV_SUBSCRIPTION":
      return {
        subscription: action.subscription,
        status: action.status,
        error: null,
        receivedAt: new Date(),
        isFetching: false,
      };
    default:
      return state;
  }
};

export default userTvSubscription;

export const shouldUpdateSubscription = state => {
  if (!state.userTvSubscription.receivedAt) return true;
  const seconds = (new Date().getTime() - state.userTvSubscription.receivedAt.getTime()) / 1000;
  return seconds > 600;
};