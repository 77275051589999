import { SELECT_APPOINTMENT, DESELECT_APPOINTMENT } from '../actions/active_appointment'

const activeAppointment = (state = null, action) => {
  switch (action.type) {
    case SELECT_APPOINTMENT:
      return action.appointment
    case DESELECT_APPOINTMENT:
      return null
    default:
      return state
  }
}

export default activeAppointment
