export const SELECT_PRODUCT_QUANTITY = 'SELECT_PRODUCT_QUANTITY';
export const DESELECT_PRODUCT_QUANTITY = 'DESELECT_PRODUCT_QUANTITY';

export const selectProductQuantity = quantity => dispatch => {
  dispatch({
    type: SELECT_PRODUCT_QUANTITY,
    quantity: quantity.quantity
  });
};

export const deselectProductQuantity = () => ({
  type: DESELECT_PRODUCT_QUANTITY
});
