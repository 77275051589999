import React, {Component, Fragment} from 'react'
import superagent from "superagent"
import RenderError from '../components/render_error.jsx';
import RenderLoading from '../components/render_loading.jsx';
import scrollToTop from "../helpers/scrollToTop";
import MetaTagsComponent from "../components/meta_tags_component";

export default class PressPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    scrollToTop();
    return superagent
      .get('/press')
      .set('Accept', 'application/json')
      .send().then(res =>
        this.setState({
          press: res.body,
          isLoading: false
        }))
      .catch(error => {
        this.setState({error: error});
      });
  }

  render() {
    const { error, press, isLoading } = this.state;

    if (error) return <RenderError error={error} />;

    return (
      <Fragment>
        <MetaTagsComponent
          title="SKY TING | About | Press"
          desc="SKY TING press"
          url="press"
        />
        <div className="Page">
          <div className="Page-content">
            <h2 className="Page-header">PRESS</h2>
            <div>
              <div className="Press">
                {isLoading ? (
                  <RenderLoading/>
                ) : (
                  <div className="Press-cards row">
                    { press.map((item, index) => (
                      <div className="Press-card-wrapper column  small-12 medium-6 large-4" key={item.id}>
                        <a className="Press-card" href={item.url} target="_blank">
                          <div className="Press-card-image-wrapper">
                            <img src={item.cover.url} />
                          </div>
                          <div className="Press-description">
                            <div className="Press-name"> {item.title}</div>
                            <div className="Press-text"> {item.desc}</div>
                          </div>
                        </a>
                        <div className="Press-card for-small">
                          <div className="Press-card-image-wrapper">
                            <img src={item.cover.url} />
                          </div>
                          <a className="Press-description" href={item.url} target="_blank">
                            <div className="Press-name"> {item.title}</div>
                            <div className="Press-text"> {item.desc}</div>
                          </a>
                        </div>
                      </div>
                    ))};
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}
