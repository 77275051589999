import {
  UPDATE_SHOPPING_CART,
  UPDATE_SHOPPING_CART_ERROR,
  UPDATE_SHOPPING_CART_SUCCESS,
  // OPEN_CART,
  // CLOSE_CART,
  WAIT_FOR_AUTHENTICATION,
} from '../actions/cart';

const INITIAL_STATE = {
  items: [],
  itemQuantity: 0,
  error: null,
  isFetching: false,
  show: false,
  waitingForAuth: false,
};

const cart = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_SHOPPING_CART:
      return {
        ...state,
        isFetching: true
      };
    case UPDATE_SHOPPING_CART_ERROR:
      return {
        ...state,
        error: action.error,
        isFetching: false
      };
    case UPDATE_SHOPPING_CART_SUCCESS:
      return {
        ...state,
        items: action.items,
        itemQuantity: action.items.reduce((sum, currentItem) => (sum + currentItem.quantity), 0),
        error: null,
        isFetching: false,
        // waitingForAuthentication: false,
      };
    // case OPEN_CART:
    //   return {
    //     ...state,
    //     show: true
    //   };
    // case CLOSE_CART:
    //   return {
    //     ...state,
    //     show: false
    //   };
    case WAIT_FOR_AUTHENTICATION:
      return {
        ...state,
        waitingForAuth: action.value,
      };
    default:
      return state;
  }
};

export default cart;