import React, { Component, Fragment } from "react";
import { Collapse } from "react-collapse";
import _ from "lodash";
import queryString from "query-string";
import { Link } from "react-router-dom";
import { history } from "../../store/configureStore";
import scrollToTop from "../../helpers/scrollToTop";
import VideosGrid from "../../components/videos_grid";
import filterIcon from "../../../../assets/images/icons/filter_blue.svg";
import arrowLong from "../../../../assets/images/icons/arrow_long.png";

const durationFilterOptions = [
  { id: "15", label: "0 - 15", min: 0, max: 15 },
  { id: "30", label: "15 - 30", min: 15, max: 30 },
  { id: "45", label: "30 - 45", min: 30, max: 45 },
  { id: "60", label: "45 - 60", min: 45, max: 60 },
  { id: "60+", label: "60+", min: 60, max: 1000 }
];

const orderFilterOptions = [
  { id: "alphabetically", label: "Alphabetically" }
];

class CategoryPageLayout extends Component {
  state = {
    filters: [],
    selectedFilters: [], // [{ name: 'level', value: 'medium' }}]
    showFilters: false,
    screenWidth: window.innerWidth
  };

  componentDidMount() {
    scrollToTop();
    window.addEventListener("resize", this.updateDimensions);
    this.prepareFilters();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({ screenWidth: window.innerWidth });
  };

  prepareFilters = () => {
    const { filtersNames } = this.props;

    const filters = filtersNames.map(filter => {
      return {
        name: filter,
        options:
          filter === "duration"
            ? durationFilterOptions
            : this.getFilterOptions(filter)
      };
    });

    const searchParams = queryString.parse(window.location.search);

    const preselectedFilters = [];
    Object.keys(searchParams).map(key => {
      preselectedFilters.push({ name: key, value: searchParams[key] });
    });

    this.setState({
      filters,
      selectedFilters: preselectedFilters
    });
  };

  getFilterOptions = filterName => {
    const { videos } = this.props;

    if (filterName === "most recent") return orderFilterOptions;

    const options = [];
    videos.forEach(v => {
      if (filterName === "teacher") {
        v.teachers.forEach(t => {
          if (!options.find(o => o.id === t.id)) {
            options.push({ id: t.id, label: t.full_name });
          }
        });
      } else {
        if (v[filterName] && !options.find(o => o.id === v[filterName])) {
          options.push({ id: v[filterName], label: v[filterName] });
        }
      }
    });

    if (filterName === "teacher") {
      return _.orderBy(options, [option => option.label.toLowerCase()], ['asc']);
    } else {
      return options;
    }
  };

  renderFilter = filter => {
    const { selectedFilters } = this.state;

    if (filter.options.length === 0) return;

    const selected = selectedFilters.find(
      selected => selected.name === filter.name
    );

    return (
      <div className="filter-container">
        <select
          className="filter-select"
          id={`${filter.name}Filter`}
          onChange={event => this.onFilterChange(event, filter.name)}
          value={selected ? selected.value : ""}
        >
          <option value="" hidden={selected && selected.value === ""}>
            {selected && selected.value !== "" && filter.name !== "most recent"
              ? "all"
              : filter.name === "category"
              ? "type"
              : filter.name}
          </option>
          {/*<option value="" disabled hidden>*/}
          {/*  {f.name}*/}
          {/*</option>*/}
          {filter.options.map(option => (
            <option key={option.id} value={option.id}>
              {option.label}
            </option>
          ))}
        </select>
        {/*{ selected && selected.value !== "" &&*/}
        {/*  <span onClick={() => this.clearFilter(f.name)}>x</span>*/}
        {/*}*/}
      </div>
    );
  };

  clearFilterValue = filterName => {
    const selectedFilters = [...this.state.selectedFilters];

    this.setState({
      selectedFilters: selectedFilters.filter(f => f.name !== filterName)
    });
    const searchParams = queryString.parse(window.location.search);

    delete searchParams[filterName];
    const stringified = queryString.stringify(searchParams);
    history.push(`?${stringified}`);
  };

  addFilterValue = (filterName, value) => {
    const selectedFilters = [...this.state.selectedFilters];
    const selectedFilter = selectedFilters.find(f => f.name === filterName);

    if (selectedFilter) {
      selectedFilter.value = value;
    } else {
      selectedFilters.push({ name: filterName, value: value });
    }

    this.setState({ selectedFilters });

    const searchParams = queryString.parse(window.location.search);
    if (!searchParams[filterName] || searchParams[filterName] !== value) {
      searchParams[filterName] = value;
      const stringified = queryString.stringify(searchParams);
      history.push(`?${stringified}`);
    }
  };

  onFilterChange = (event, filterName) => {
    const { value } = event.target;
    if (!value || value === "") {
      this.clearFilterValue(filterName);
    } else {
      this.addFilterValue(filterName, value);
    }
  };

  filterArray = (array, filtersArr) => {
    const filters = {};
    filtersArr.map(el => {
      if (el.name !== 'most recent') {
        filters[el.name] = el.value;
      }
    });

    const filterKeys = Object.keys(filters);

    const filtered = array.filter(item => {
      return filterKeys.every(key => {
        if (key === "teacher") {
          return item.teachers.map(t => t.id.toString()).includes(filters[key]);
        } else if (key === "duration") {
          const option = durationFilterOptions.find(o => o.id === filters[key]);
          return this.checkVideoDuration(item, option);
        } else {
          return filters[key] === item[key];
        }
      });
    });
    const orderFilter = filtersArr.find(f => f.name === 'most recent');
    if (orderFilter && orderFilter.value === 'alphabetically') {
      return _.sortBy(filtered, ['title']);
    } else {
      return filtered;
    }
  };

  checkVideoDuration = (video, filterOption) => {
    const { min } = filterOption;
    const { max } = filterOption;

    return video.duration >= min && video.duration <= max;
  };

  render() {
    const { selectedFilters, filters, showFilters, screenWidth } = this.state;
    const {
      videos,
      columnsCount,
      blendingColor,
      hideTeacher,
      category,
      header
    } = this.props;

    const filteredVideos = this.filterArray(videos, selectedFilters);

    const withTopBlockBckg =
      category &&
      category.type !== "library" &&
      category.file_url &&
      screenWidth > 799;

    return (
      <Fragment>
        <div
          className={`tv-page-top-block padding-lr ${
            withTopBlockBckg ? "with-bckg" : ""
          }`}
        >
          {withTopBlockBckg && (
            <Fragment>
              <img src={category.file_url} className="bckg" />
              <div className={`blended-layer ${blendingColor}`} />
            </Fragment>
          )}
          {((category && category.header) || header) && (
            <h2 className="Page-header">
              <Link to="/tv">
                <img
                  src={arrowLong}
                  className={`back-btn ${withTopBlockBckg ? "white" : ""}`}
                />
              </Link>
              {category ? category.header : header}
            </h2>
          )}
          {category && category.subheader && (
            <h3 className="page-subtitle">{category.subheader}</h3>
          )}
          { videos.length > 0 &&
            <div className="filters-block">
              {screenWidth <= 799 && (
                <div
                  className={`filter-button ${showFilters ? "active" : ""}`}
                  onClick={() => this.setState({ showFilters: !showFilters })}
                >
                  FILTERS
                  <img src={filterIcon} />
                </div>
              )}
              <Collapse isOpened={screenWidth > 799 ? true : showFilters}>
                <div className="filters-row">
                  {filters.map(f => this.renderFilter(f))}
                </div>
              </Collapse>
            </div>
          }
        </div>
        <div className="padding-lr">
          { videos.length > 0 ? (
            <VideosGrid
              videos={filteredVideos}
              columnsCount={columnsCount}
              hideTeacher={hideTeacher}
            />
          ) : (
            <div className="mt-5 pt-5 pb-5 Empty-result">
              { category ? "COMING SOON" : "NO CLASSES" }
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

export default CategoryPageLayout;
