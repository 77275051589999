export const OPEN_SHIPPING = 'OPEN_SHIPPING';
export const DESELECT_SHIPPING = 'DESELECT_SHIPPING';
export const CHANGE_SHIPPING_FORM = 'CHANGE_SHIPPING_FORM';
export const DROP_SHIPPING_FORM = 'DROP_SHIPPING_FORM'

export const openShipping = () => (dispatch) => {
  dispatch({
    type: OPEN_SHIPPING
  });
};

export const deselectShipping = () => (dispatch) => { dispatch({ type: DESELECT_SHIPPING })};

export const changeShippingForm = formData => (dispatch) => {
  dispatch({ type: CHANGE_SHIPPING_FORM, formData });
};