import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";
const CAPTCHA_KEY = "6Lc10UMkAAAAAGC6FJlr5YeT0lJujK1by_f0V9uG";

export default class Recaptcha extends Component {
  constructor(props) {
    super(props);
    this.recaptchaRef = React.createRef();
  }

  resetRecaptcha = () => {
    if (this.recaptchaRef.current) {
      this.recaptchaRef.current.reset();
    }
  };

  render() {
    return (
      <ReCAPTCHA
        ref={this.recaptchaRef}
        sitekey={CAPTCHA_KEY}
        onChange={this.props.onChange}
        className="d-flex justify-content-center mb-5"
      />
    );
  }
}
