export const ADD_URL = 'ADD_URL';
export const REMOVE_URL = 'REMOVE_URL';

export const addUrl = (url, page) => (dispatch) => {
	dispatch({
		type: ADD_URL,
		url: url,
		page: page
	})
};

export const removeUrl = () => (dispatch) => { dispatch({ type: REMOVE_URL })};