import superagent from "superagent";

export const fetchTvPlans = () => dispatch => {
  return new Promise(async (resolve, reject) => {
    await superagent
      .get("/tv_plans")
      .set("Accept", "application/json")
      .send()
      .then(res => {
        dispatch({
          type: 'FETCH_TV_PLANS',
          plans: res.body,
        });
        resolve(res.body);
      })
      .catch(error => {
        reject(error.response.body);
      });
  });
};
