import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { isBooked } from '../reducers/user_schedule';

import NewIcon from './../../../assets/images/icons/new_icon_1.png';
import {connect} from "react-redux";
import classNames from 'classnames';

class TableRow extends Component {

  renderColumns() {
    const { columns, id } = this.props;

    return columns.map((column, index) => (
      <div key={index} className="Table-column">
        {column}
      </div>
    ));
  }

  render() {
    const rowClass = classNames({
      'Table-row': true,
      'is-booked': this.props.isBooked
    });

    return (
      <div className={rowClass} onClick={this.props.onClick}>
        { this.props.isNew &&
          <img className="New-icon" src={NewIcon} height='56' width='52' alt="New"/>
        }
        {this.renderColumns()}
      </div>
    );
  }
}

TableRow.propTypes = {
  columns: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => {
  return {
    isBooked: isBooked(ownProps.lessonId, state),
  };
};

export default connect(mapStateToProps)(TableRow);
