import Api from "../api/trainings";

export const FETCH_TRAININGS = 'FETCH_TRAININGS';
export const FETCH_TRAININGS_ERROR = 'FETCH_TRAININGS_ERROR';
export const FETCH_TRAININGS_SUCCESS = 'FETCH_TRAININGS_SUCCESS';

  export const fetchTrainings = () => dispatch => {
  dispatch({ type: FETCH_TRAININGS });

  Api.fetchTrainings()
    .then(res =>
      dispatch({
        type: FETCH_TRAININGS_SUCCESS,
        trainings: res.body
      })
    )
    .catch(err =>
      dispatch({
        type: FETCH_TRAININGS_ERROR,
        error: err
      })
    );
};
