import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import superagent from "superagent";
import { bindActionCreators } from "redux";
import scrollToTop from "../helpers/scrollToTop";
import MetaTagsComponent from "../components/meta_tags_component";
import AuthComponent from "../components/authentication/auth_component";
import { isAuthenticated } from "../reducers/user";
import AuthPageHeader from "../components/authentication/auth_page_header";
import { logout } from "../actions/authentication";
import Payment from "../components/tv_signup/payment_new";
import sky from "../../../assets/images/sky_gray.jpg";
import { fetchUserTvSubscription } from "../actions/user_subscriptions";
import RenderLoading from "../components/render_loading";
import { shouldUpdateSubscription } from "../reducers/user_tv_subscription";

class TvSignup extends Component {
  constructor(props) {
    super(props);

    const { location: { state } } = props;
    this.state = {
      selectedPlan: state && state.selectedPlan,
      pageContent: null,
      loadingPageContent: null,
      pageError: null
    };
  }

  componentDidMount() {
    scrollToTop();
    if (this.props.shouldUpdateSubscription) {
      this.props.fetchUserTvSubscription();
    }
    this.getSignupPageContent();
  }

  componentWillReceiveProps(newProps) {
    if (!this.props.isAuthenticated && newProps.isAuthenticated) {
      this.props.fetchUserTvSubscription();
    }
  }

  getSignupPageContent = () => {
    return superagent
      .get("/signup_page")
      .set("Accept", "application/json")
      .send()
      .then(res =>
        this.setState({
          pageContent: res.body,
          loadingPageContent: false
        })
      )
      .catch(error => {
        this.setState({
          pageError: error.response.body,
          loadingPageContent: false
        });
      });
  };

  getModeByPath = () => {
    const {
      location: { pathname }
    } = this.props;

    switch (pathname) {
      case "/tv-signup/login":
        return "login";
      case "/tv-signup/signup":
        return "signup";
      case "/tv-signup/forgot-password":
        return "forgot-password";
      case "/tv-signup/reset-password":
        return "reset-password";
      case "/tv-signup/payment":
        return "payment";
      default:
        return "get-started";
    }
  };

  switchMode = mode => {
    this.props.history.push(`/tv-signup/${mode}`);
  };

  redirectAfterAuth = () => {
    const { selectedPlan } = this.state;

    if (selectedPlan) {
      this.switchMode("payment");
      scrollToTop();
    }
  }

  redirectPricing = () => {
    const { selectedPlan } = this.state;
    const { comeFrom, page } = this.props;

    this.props.fetchUserTvSubscription().then((res) => {
      if (page == "TvVideos" && ["active", "trialing"].includes(res.status)) {
        this.props.history.push(`${comeFrom}`);
      } else if (!selectedPlan) {
        this.props.history.push("/pricing");
      }
    });
  }

  render() {
    const {
      selectedPlan,
      pageContent,
      loadingPageContent,
    } = this.state;
    const { isAuthenticated, logout, subscription } = this.props;

    const mode = this.getModeByPath();

    if (mode === "payment" && !selectedPlan) {
      this.switchMode("choose-plan");
    }

    return (
      <Fragment>
        <MetaTagsComponent
          title="SKY TING | Practice anytime. Anywhere."
          desc="SKY TING | Practice anytime. Anywhere."
        />
        <div
          className="signup-page"
          style={{
            backgroundImage: `url(${
              pageContent && pageContent.background_url
                ? pageContent.background_url
                : sky
            })`
          }}
        >
          {!isAuthenticated ? (
            <AuthComponent
              isTvSignup
              mode={mode}
              pageContent={pageContent}
              loadingPageContent={loadingPageContent}
              afterLogin={() => this.redirectAfterAuth()}
              afterSignup={() => this.redirectAfterAuth()}
            />
          ) : (
            <Fragment>
              <AuthPageHeader
                showTvIcon
                onButtonClick={() => logout()}
                buttonText="LOG OUT"
                pathname={this.props.history.location.pathname}
              />
              <div className="signup-page-content">
                { subscription.isFetching ? (
                  <RenderLoading className="centered" />
                ) : (
                  <Fragment>
                  { selectedPlan == undefined ? (
                    this.redirectPricing()
                  ):(
                    <Fragment>
                      <Payment
                        selectedPlan={selectedPlan}
                        pageContent={pageContent}
                        subscription={subscription}
                      />
                    </Fragment>
                  )}
                  </Fragment>
                )}
              </div>
            </Fragment>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: isAuthenticated(state),
  user: state.user,
  subscription: state.userTvSubscription,
  shouldUpdateSubscription: shouldUpdateSubscription(state),
  comeFrom: state.redirectUrl.comeFrom,
  page: state.redirectUrl.page
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchUserTvSubscription,
      logout
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TvSignup);
