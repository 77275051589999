import {
  CHECKOUT_TOTAL,
  CHECKOUT_TOTAL_SUCCESS,
  CHECKOUT_TOTAL_ERROR,
  DROP_CHECKOUT_TOTAL_CART
} from '../actions/checkout_total';

const INITIAL_STATE = {
  cart: null,
  promoCodeError: null,
  error: null,
  isFetching: false
};

const checkoutTotal = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHECKOUT_TOTAL:
      return {
        ...state,
        isFetching: true
      };
    case CHECKOUT_TOTAL_ERROR:
      return {
        ...state,
        error: action.error,
        isFetching: false
      };
    case CHECKOUT_TOTAL_SUCCESS:
      return {
        ...state,
        cart: action.response.shopping_cart,
        isFetching: false
      };
    case DROP_CHECKOUT_TOTAL_CART:
      return INITIAL_STATE
    default:
      return state;
  }
};

export default checkoutTotal;
