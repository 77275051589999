import {
  OPEN_ORDER_POPUP,
  CLOSE_ORDER_POPUP
} from '../actions/order';

const INITIAL_STATE = {
  show: false,
  items: [],
};

const order = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OPEN_ORDER_POPUP:
      return {
        ...state,
        show: true,
      };
    case CLOSE_ORDER_POPUP:
      return {
        ...state,
        show: false,
      };
    default:
      return state;
  }
};

export default order;
