import React, { Component, Fragment } from 'react';
import queryString from 'query-string';
import superagent from "superagent";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isAuthenticated } from "../reducers/user";
import RenderLoading from '../components/render_loading.jsx';

class PasswordRecovery extends Component {
  constructor(props) {
    super(props);

    const searchParams = queryString.parse(this.props.location.search)

    this.state = {
      isLoading: false,
      password: '',
      passwordConfirmation: '',
      error: null,
      success: null,
      token: (searchParams ? searchParams.reset_password_token : null),
    };
  }

  componentDidUpdate() {
    if (this.props.user && this.props.user.id) {
      this.props.history.push('/');
    }
  }

  onPasswordChange = (event) => {
    this.setState({ password: event.target.value })
  }

  onPasswordConfirmChange = (event) => {
    this.setState({ passwordConfirmation: event.target.value })
  }

  changePassword = () => {
    const { password, passwordConfirmation, token } = this.state;

    this.setState({ isLoading: true, error: null })
    return superagent
      .put('/users/password/')
      .set({
        Accept: 'application/json',
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].getAttribute('content'),
      })
      .send({
        user: { password, password_confirmation: passwordConfirmation, reset_password_token: token }
      }).then(res =>
        this.setState({
          success: true,
          isLoading: false,
          error: null,
        }))
      .catch((err) => {
        this.setState({
          isLoading: false,
          error: (err.response.body && err.response.body.password) ? err.response.body.password : 'Something went wrong, try again',
        });
      });
  }

  render() {
    const {
      isLoading,
      password,
      passwordConfirmation,
      error,
      success,
      token,
    } = this.state

    const { isAuthenticated } = this.props

    if (isAuthenticated) return (
      <div className="Page Credits">
        <div className="Page-content">
          <div className="Page-header">PLEASE, SIGN OUT TO CHANGE YOUR PASSWORD</div>
        </div>
      </div>
    )

    if (!token) return (
      <div className="Page Credits">
        <div className="Page-content">
          <div className="Page-header">
            The link is invalid, please check link in your email
          </div>
        </div>
      </div>
    )

    const passwordValid = password.length >= 8 && (/\d/.test(password))
    const valid = passwordValid && (password === passwordConfirmation)

    return (
      <div className="Page Credits">
        <div className="Page-content">
          <div className="Page-header">CHANGE YOUR PASSWORD</div>
          <div className="LoginForm change-password">
            { success ? (
              <Fragment>
                <br />
                <div className="success">SUCCESS!</div>
                <br />
                <div className="success">
                  Your password has been successfully changed. Please sign in.
                </div>
                <br />
              </Fragment>
            ) : (
              <Fragment>
                <div className="LoginForm-field">
                  <div className="comment">
                    The password must contain 8 to 15 characters and must include both letters and numbers
                  </div>
                  <input
                    type="password"
                    name="password"
                    className="TextField"
                    placeholder="NEW PASSWORD"
                    onChange={this.onPasswordChange}
                    value={password}
                  />
                </div>
                <div className="LoginForm-field">
                  <input
                    type="password"
                    name="passwordConfirmation"
                    className="TextField"
                    placeholder="CONFIRM NEW PASSWORD"
                    onChange={this.onPasswordConfirmChange}
                    value={passwordConfirmation}
                  />
                </div>

                { error &&
                <div className="Registration-error text-center mb-3 text-uppercase">
                  {error}
                </div>
                }
                { isLoading ? (
                  <RenderLoading />
                ) : (
                  <div className="LoginForm-field">
                    <button
                      className="SmallButton"
                      disabled={!valid}
                      onClick={() => this.changePassword()}
                      style={{ height: '55px' }}
                    >
                      CHANGE PASSWORD
                    </button>
                  </div>
                )}
              </Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: isAuthenticated(state),
  user: state.user
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {},
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PasswordRecovery);
