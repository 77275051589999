import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { isMobile } from "react-device-detect";
import PricingCardDropdown from "./pricing_card_dropdown";
import tv from "./../../../../assets/images/icons/tv.png";

class PricingCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  period = type => {
    switch (type) {
      case "class packages":
        return "class";
      case "specials":
        return "3 classes";
      default:
        return "month";
    }
  };

  pricingTitle = title => {
    switch (title) {
      case "memberships":
        return "Unlimited Membership";
      case "specials":
        return "FIRST TIME";
      default:
        return title;
    }
  };

  renderTvBlock = style => {
    const { priceCategory, selectPrice } = this.props;

    const tvPrice = priceCategory.services.find(c => c.is_tv_plan);

    const pricingCardClass = classNames({
      "pricing-card": true,
      [style.color]: true,
      "tv-card": true
    });

    const categoryClass = classNames({
      "Category-price": true,
      "highlighted-card": !!priceCategory.highlight_text
    });

    const trialPeriodDays = Number(gon.tv_trial_period_days) > 0 ? Number(gon.tv_trial_period_days) - 1 : gon.tv_trial_period_days;

    return (
      <div className={categoryClass}>
        <div className={pricingCardClass}>
          {priceCategory.highlight_text && (
            <div className="highlight-text">{priceCategory.highlight_text}</div>
          )}
          {priceCategory.background_url && !isMobile ? (
            <Fragment>
              {["video/mp4", "video/webm"].includes(
                priceCategory.background_type
              ) ? (
                <div className="video-wrapper">
                  <video autoPlay muted loop playsInline>
                    <source
                      src={priceCategory.background_url}
                      type={priceCategory.background_type}
                    />
                  </video>
                </div>
              ) : (
                <img
                  className="background-img"
                  src={priceCategory.background_url}
                  alt="SKY TING"
                />
              )}
            </Fragment>
          ) : (
            <Fragment>
              {priceCategory.background_image_url ? (
                <img
                  className="background-img"
                  src={priceCategory.background_image_url}
                  alt="SKY TING"
                />
              ) : (
                <img className="background-img" src={style.background} alt="SKY TING" />
              )}
            </Fragment>
          )}

          <div className="tv-card-background" />
          <div className="pricing-card-top d-flex flex-column justify-content-between">
            <div className="pricing-card-title">
              SKY TING &nbsp;
              <img src={tv} height={30} alt="TV" />
            </div>
            {priceCategory.price_header ? (
              <div>
                <div className="colored-text">Starting at</div>
                <div
                  className="amount"
                  dangerouslySetInnerHTML={{
                    __html: priceCategory.price_header
                  }}
                />
              </div>
            ) : (
              <Fragment>
                {priceCategory.starting_at && (
                  <div>
                    <div className="colored-text">Starting at</div>
                    <div className="amount">
                      <span>${priceCategory.starting_at}</span> /{" "}
                      {this.period(priceCategory.type)}
                    </div>
                  </div>
                )}
              </Fragment>
            )}
          </div>
          <div className="pricing-card-bottom">
            <div
              className="mb-5 short-desc"
              dangerouslySetInnerHTML={{
                __html: priceCategory.short_description
              }}
            />
          </div>
          <div
            className="Button full-width subscr-btn ga-tv-subscribe-start"
            onClick={() => selectPrice(tvPrice, priceCategory.type)}
          >
            Try {trialPeriodDays} days free
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { showDropdown } = this.state;
    const {
      priceCategory,
      showInfo,
      selectedPrice,
      selectPrice,
      selectedCategory,
      styleAttrs
    } = this.props;

    const style = styleAttrs.find(i => i.type === priceCategory.type);

    if (priceCategory.type === "tv") return this.renderTvBlock(style);

    const pricingCardClass = classNames({
      "pricing-card": true,
      [style.color]: true
    });

    const categoryClass = classNames({
      "Category-price": true,
      "highlighted-card": priceCategory.highlight_text
    });

    return (
      <div className={categoryClass}>
        <div className={pricingCardClass}>
          {priceCategory.highlight_text && (
            <div className="highlight-text">{priceCategory.highlight_text}</div>
          )}
          <div className="pricing-card-top colored-border">
            {priceCategory.background_url && !isMobile ? (
              <Fragment>
                {["video/mp4", "video/webm"].includes(
                  priceCategory.background_type
                ) ? (
                  <div className="video-wrapper">
                    <video autoPlay muted loop playsInline>
                      <source
                        src={priceCategory.background_url}
                        type={priceCategory.background_type}
                      />
                    </video>
                  </div>
                ) : (
                  <img
                    className="background-img"
                    src={priceCategory.background_url}
                    alt="SKY TING"
                  />
                )}
              </Fragment>
            ) : (
              <Fragment>
                {priceCategory.background_image_url ? (
                  <img
                    className="background-img"
                    src={priceCategory.background_image_url}
                    alt="SKY TING"
                  />
                ) : (
                  <img className="background-img" src={style.background} alt="SKY TING" />
                )}
              </Fragment>
            )}

            <div className="pricing-card-info">
              <div className="pricing-card-title">
                {priceCategory.name_header ||
                  this.pricingTitle(priceCategory.type)}
              </div>
              {priceCategory.price_header ? (
                <div>
                  <div className="colored-text">Starting at</div>
                  <div
                    className="amount"
                    dangerouslySetInnerHTML={{
                      __html: priceCategory.price_header
                    }}
                  />
                </div>
              ) : (
                <Fragment>
                  {priceCategory.starting_at && (
                    <div>
                      <div className="colored-text">Starting at</div>
                      <div className="amount">
                        <span>${priceCategory.starting_at}</span> /{" "}
                        {this.period(priceCategory.type)}
                      </div>
                    </div>
                  )}
                </Fragment>
              )}
            </div>
          </div>
          <div className="pricing-card-bottom">
            <div
              className="mb-5 short-desc"
              dangerouslySetInnerHTML={{
                __html: priceCategory.short_description
              }}
            />
            <div className="pricing-card-select">
              <PricingCardDropdown
                showDropdown={showDropdown}
                services={priceCategory.services}
                selectedPrice={selectedPrice}
                selectPrice={p => selectPrice(p, priceCategory.type)}
                selectedCategory={selectedCategory}
                priceCategory={priceCategory}
              />
            </div>
          </div>
          {/*<div className="Button Button--secondary full-width" onClick={() => showInfo(priceCategory)}>*/}
          {/*LEARN MORE*/}
          {/*</div>*/}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(PricingCard);
