import {
  FETCH_TV_DASHBOARD,
} from '../actions/tv_dashboard';

const INITIAL_STATE = null;

const tvDashboard = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_TV_DASHBOARD:
      return action.content;
    default:
      return state;
  }
};

export default tvDashboard;
