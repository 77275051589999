import Api from '../api/product';

export const FETCH_SHIPMENT_PRODUCTS = 'FETCH_SHIPMENT_PRODUCTS';
export const FETCH_SHIPMENT_PRODUCTS_ERROR = 'FETCH_SHIPMENT_PRODUCTS_ERROR';
export const FETCH_SHIPMENT_PRODUCTS_SUCCESS = 'FETCH_SHIPMENT_PRODUCTS_SUCCESS';

export const fetchShipmentProducts = () => dispatch => {
  dispatch({ type: FETCH_SHIPMENT_PRODUCTS });

  Api.fetchProducts({ 'shipment': true })
    .then(res =>
      dispatch({
        type: FETCH_SHIPMENT_PRODUCTS_SUCCESS,
        res: res.body
      })
    )
    .catch(err =>
      dispatch({
        type: FETCH_SHIPMENT_PRODUCTS_ERROR,
        error: err
      })
    );
};
