import React, { Component } from "react";
import PropTypes from "prop-types";
import onClickOutside from "react-onclickoutside";
import { bindActionCreators } from "redux";
import { dropdownChange } from "../actions/dropdowns";
import { connect } from "react-redux";

class NavbarDropdown extends Component {
  componentWillMount() {
    const { dropdownChange, name } = this.props;

    dropdownChange({
      [name]: false
    });
  }

  changeDropdown = () => {
    const {
      dropdownChange,
      name,
      dropdowns,
      disabled
    } = this.props;
    if (disabled) return;

    const condition = dropdowns[name];
    dropdownChange({
      [name]: !condition
    });
  };

  handleClickOutside = evt => {
    const { dropdownChange, name } = this.props;
    dropdownChange({
      [name]: false
    });
  };

  render() {
    const { name, dropdowns, className, active, borderOnHover } = this.props;
    const condition = dropdowns[name];

    return (
      <div className={`Navbar-dropdown-content ${className ? className : ""}`}>
        <div
          onClick={this.changeDropdown}
          className={`navbar-link-sk ${borderOnHover ? 'with-hover-border' : ''} ${active ? "active" : ""}`}
        >
          {this.props.children[0]}
          {this.props.withArrow && <div className="arrow-up" />}
        </div>
        <div className={`dropdown-content ignore-react-onclickoutside ${condition ? "" : "d-none"}`}>
          <div className="arrow-down" id={`arrow-down-${name}`} />
          {this.props.children[1]}
        </div>
      </div>
    );
  }
}

NavbarDropdown.propTypes = {
  name: PropTypes.string.isRequired
};

NavbarDropdown.defaultProps = {
  name: "default"
};

const mapStateToProps = state => ({
  dropdowns: state.dropdowns
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      dropdownChange
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(onClickOutside(NavbarDropdown));
