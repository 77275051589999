import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isAuthenticated } from "../reducers/user";
import scrollToTop from "../helpers/scrollToTop";
import RenderError from "../components/render_error.jsx";
import MetaTagsComponent from "../components/meta_tags_component";
import ConfirmationBanner from "../components/tv/confirmation_banner";
import LiveScheduleShort from "../components/live_schedule_short";
import { Link } from "react-router-dom";
import play from "../../../assets/images/icons/Play.svg";
import TeacherThumb from "../components/teacher_thumb";
import { fetchTvDashboard } from "../actions/tv_dashboard";
import RenderLoading from "../components/render_loading";
import Slider from "react-slick";
import ButtonWithIcon from "../components/button_with_icon";
import star from "../../../assets/images/icons/star_circle.svg";
import TvCollectionsGrid from "../components/tv_collections_grid";
import { fetchUserTvSubscription } from "../actions/user_subscriptions";

class TvDashboard extends Component {
  state = {
    loadingDashboard: true,
    error: null,
    subscribed: false,
    purchaseVideos: []
  };

  getPurchaseVideoList = () => {
    this.setState({ isLoading: true })
    return superagent
      .get(`/purchased_video`)
      .set("Accept", "application/json")
      .send()
      .then(res => {
        this.setState({ isLoading: false, purchaseVideos: res.body.purchased })
      })
      .catch(error => {
        console.log(error)
      });
  }

  componentWillMount() {
    scrollToTop();
  }

  componentDidMount() {
    this.getSubscription();
    this.getTvDashboard();
  }

  getTvDashboard = () => {
    const { tvDashboard, fetchTvDashboard } = this.props;
    if (!tvDashboard) {
      this.setState({ loadingDashboard: true });
      fetchTvDashboard()
        .then(() => this.setState({ loadingDashboard: false }))
        .catch(err => this.setState({ loadingDashboard: false, error: err }));
    } else {
      this.setState({ loadingDashboard: false });
    }
  };

  getSubscription = () => {
    this.props.fetchUserTvSubscription().then(res => {
      if (res.status === "active") {
        this.setState({ subscribed: true });
      }
    });
  };

  render() {
    const {
      loadingDashboard,
      error,
      subscribed,
      purchaseVideos
    } = this.state;

    const { tvDashboard } = this.props;

    if (loadingDashboard) return <RenderLoading className="centered" />;

    if (tvDashboard === 0 || error) return <RenderError />;

    const { dashboard } = tvDashboard;

    return (
      <div className="Page tv-dashboard">
        <MetaTagsComponent title="SKY TING | TV" desc="SKY TING TV" url="tv" />
        <ConfirmationBanner subscribed={subscribed} />

        {dashboard.slides.length > 0 && (
          <div className="tv-dashboard-slider with-large-dots dots-white dots-inside">
            <Slider
              dots
              infinite
              autoplay
              speed={500}
              fade
              cssEase="ease-in"
              swipeToSlide
              slidesToShow={1}
              slidesToScroll={1}
              arrows={false}
            >
              {dashboard.slides.map(slide => (
                <div className="tv-dashboard-slider-inner" key={slide.id}>
                  <img
                    src={slide.image_url}
                    className="tv-dashboard-slider-background"
                  />
                  <div className="bckg-overlay" />
                  <div className="tv-dashboard-slider-info padding-lr">
                    <div className="subheader">{slide.subheader}</div>
                    <h4 className="header">{slide.header}</h4>
                    <Link to={slide.link}>
                      <ButtonWithIcon
                        classNamesProp="tv-dashboard-slider-btn"
                        text={slide.button_text || "PLAY NOW"}
                        iconSrc={play}
                      />
                    </Link>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        )}

        <div className="tv-dashboard-section theme-dark-bkg padding-lr">
          <h3 className="small-size-subheader padding-lr">
            SKY TING COLLECTIONS
          </h3>
          <TvCollectionsGrid limit={3} />
          <Link
            to="/tv/collections"
            className="Button transparent-button ml-auto mr-auto mb-4"
          >
            SEE ALL COLLECTIONS
          </Link>
        </div>

        <div className="live-schedule-section bordered">
          <LiveScheduleShort
            imageLeft={dashboard.live_schedule_image_url}
            subheader="ZOOM SCHEDULE"
            subscribed={subscribed}
            hideLoading
            purchasedVideos={purchaseVideos}
          />
        </div>
        <div className="tv-dashboard-section">
          <h3 className="small-size-subheader padding-lr">
            Browse by instructor
          </h3>
          <div className="teachers-grid mb-0 mt-4 ml-0 mr-0 padding-lr">
            {dashboard.teachers.map(teacher => (
              <TeacherThumb teacher={teacher} />
            ))}
          </div>
        </div>
        <Link to="/teachers" className="browse-btn theme-dark-bkg">
          VIEW ALL TEACHERS
          <img src={star} alt="teachers" />
        </Link>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: isAuthenticated(state),
  user: state.user,
  tvDashboard: state.tvDashboard,
  subscription: state.userTvSubscription
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchTvDashboard,
      fetchUserTvSubscription
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TvDashboard);
