import React, { Component } from 'react';
import {Collapse} from 'react-collapse';
import classNames from 'classnames';

export default class ToggleCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };
  }

  openCard() {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  render() {

    const ToggleClass = classNames({
      'Toggle-item': true,
      'is-open': (this.state.isOpen ? true : false)
    });

    return (
      <div>
        <div className={ToggleClass} onClick={() => this.openCard()} >
          <div>{this.props.header}</div>
          <div className={this.state.isOpen ? 'arrow-down open' : 'arrow-down'}></div>
        </div>
        <Collapse isOpened={this.state.isOpen}>
          <div className='Toggle-item-content' >
            <div className="children">{this.props.children}</div>
          </div>
        </Collapse>
      </div>
    )
  }
}
