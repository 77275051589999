import { FETCH_PROMO_SPOTS_SUCCESS } from "../actions/shop_promo_spots";

const INITIAL_STATE = {
  slides: [],
  updated_at: null
};

const shopPromoSpots = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_PROMO_SPOTS_SUCCESS:
      return {
        ...state,
        slides: action.slides,
        updated_at: new Date()
      };
    default:
      return state;
  }
};

export default shopPromoSpots;
