import React  from 'react';

export const getParamByName = (name, type = null) => {
  let params = new URLSearchParams(document.location.search.substring(1))
  if (type === null || type === 'string') {
    return params.get(name)
  } else if (type === 'integer') {
    return parseInt(params.get(name))
  }
}

