import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DatePicker from './datepicker.jsx';
import apple from './../../../assets/images/icons/filter_apple.png';
import appleWhite from './../../../assets/images/icons/filter_apple_white.png';
import star from './../../../assets/images/icons/filter_star.png';
import starWhite from './../../../assets/images/icons/filter_star_white.png';
import calendar from './../../../assets/images/icons/filter_calendar.png';
import calendarWhite from './../../../assets/images/icons/filter_calendar_white.png';
import DropdownSelect from './dropdown_select.jsx';

class ScheduleFilters extends Component {
  render() {
    const { staff, session_types, onDateChange, onFiltersChange, filters } = this.props

    return (
      <form
        className="ScheduleFilters"
        onSubmit={(event) => event.preventDefault()}
      >

        <div className="ScheduleFilters-filter">
          <DatePicker
            name="end_date_time"
            onChange={onDateChange}
            icon={calendar}
            hoverIcon={calendarWhite}
            minDate={moment()}
          />
        </div>
        <div className="ScheduleFilters-filter">
          <DropdownSelect
            name="staff"
            optionId="id"
            optionLabel="name"
            options={_.orderBy(staff, ['name'])}
            icon={apple}
            hoverIcon={appleWhite}
            onChange={onFiltersChange}
            placeholder="Teacher"
            filters={filters}
          />
        </div>
        <div className="ScheduleFilters-filter">
          <DropdownSelect
            name="session_type"
            optionId="id"
            optionLabel="name"
            options={_.orderBy(session_types, ['name'])}
            icon={star}
            hoverIcon={starWhite}
            onChange={onFiltersChange}
            placeholder="Class"
            filters={filters}
          />
        </div>
      </form>
    );
  }
}

ScheduleFilters.propTypes = {
  staff: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  session_types: PropTypes.array.isRequired,
  onFiltersChange: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
};

ScheduleFilters.defaultProps = {
  staff: [],
  locations: [],
  session_types: []
};

export default ScheduleFilters;
