import React, {Component, Fragment} from "react";
import Loading from "../../../assets/images/icons/yinyang_icon.png";

class ComponentWithLoading extends Component {
  render() {
    const { isLoading, centered, children } = this.props;

    return (
      <Fragment>
        { isLoading ? (
          <div className={`with-loading inline-loading ${centered ? 'centered' : ''}`}>
            <img src={Loading} height="21" width="21" alt="Loading" />
          </div>
        ) : (
          children
        )}

      </Fragment>

    );
  }
}

export default ComponentWithLoading;
