import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { authenticate } from '../actions/authentication';
import {openLoginPopup, closeLoginPopup, openLoginPopupWithError } from '../actions/login_popup';
import { openRegisterPopup } from '../actions/register_popup';

import Popup from './popup.jsx';
import ForgotPassword from './forgot_password.jsx';
import Loading from "../../../assets/images/icons/yinyang_icon.png";

class LoginPopup extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
    };
  }

  componentWillMount() {
    document.body.classList.add('overflow-hidden');
  }

  componentWillUnmount() {
    document.body.classList.remove('overflow-hidden');
  }

  onSubmit = (event) => {
    const { authenticate } = this.props;

    event.preventDefault()
    this.setState({ loading: true });

    const {
      email: { value: email },
      password: { value: password }
    } = event.target.elements

    authenticate({ email, password })
      .then(res => this.setState({ loading: false }))
      .catch(err => {
        this.setState({ loading: false });
        this.props.openLoginPopupWithError(err);
      })
  }

  renderErrors() {
    if (!this.props.error) return null;

    return (
      <div className="Registration-error">
        <Fragment>
          <span>{this.props.error}</span>
        </Fragment>
      </div>
    )
  }

  openRegister = () => {
    const { openRegisterPopup, closeLoginPopup } = this.props
    openRegisterPopup()
    closeLoginPopup()
  }

  render() {
    const {
      mode,
      openLoginPopup,
      closeLoginPopup,
    } = this.props;

    return (
      <Popup
        id="LoginPopup"
        isOpen
        key="LoginPopup"
        onClose={() => closeLoginPopup()}
      >
        { mode === 'password-recovery' ? (
          <ForgotPassword />
        ) : (
          <Fragment>
            <form className="LoginForm" onSubmit={this.onSubmit}>
              <div className="Headline">LOGIN</div>
              {this.renderErrors()}

              <div className="LoginForm-field">
                <input type="email" name="email" className="TextField" placeholder="Email" />
              </div>
              <div className="LoginForm-field">
                <input type="password" name="password" className="TextField" placeholder="Password" autoComplete="current-password" />
              </div>
              { this.state.loading ? (
                <div className="inline-loading mt-4">
                  <img src={Loading} height="21" width="21" alt="Loading" />
                </div>
              ) : (
                <div className="LoginForm-field">
                  <button type="submit" className="Button" value="Submit">SUBMIT</button>
                </div>
              )}
            </form>
            <br />
            { !this.state.loading &&
              <div className="LoginForm">
                <div className="LoginForm-field">
                  <button onClick={this.openRegister} type="submit" className="Button Register-button">REGISTER!</button>
                </div>
                <div className="LoginForm-field">
                  <div
                    className="LoginForm-link"
                    onClick={() => openLoginPopup('password-recovery')}
                  >
                    Forgot password?
                  </div>
                </div>
              </div>
            }
          </Fragment>
        )}
      </Popup>
    )
  }
}

const mapStateToProps = state => ({
  show: state.loginPopup.show,
  error: state.loginPopup.error,
  mode: state.loginPopup.mode,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      closeLoginPopup,
      openLoginPopup,
      openLoginPopupWithError,
      openRegisterPopup,
      authenticate,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(LoginPopup)