import {
  ADD_TO_CART_UNAUTHORIZED,
  CLEAR_UNAUTHORIZED_CART,
  UPDATE_UNAUTHORIZED_CART
} from "../actions/cart";

const INITIAL_STATE = {
  items: [] // structure: [{ group_identifier: 1, color: 'black', size: 's', quantity: 1, image: {}}, {}]
};

const unauthorizedCart = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_TO_CART_UNAUTHORIZED:
      return {
        ...state,
        items: [...state.items, action.product]
      };
    case UPDATE_UNAUTHORIZED_CART:
      return {
        items: action.items
      };
    case CLEAR_UNAUTHORIZED_CART:
      return {
        items: []
      };
    default:
      return state;
  }
};

export const unauthorizedItemQuantity = (state) => {
  const { unauthorizedCart: { items } } = state;

  const qtyArray = items.map(i => Number(i.quantity));
  if (qtyArray.length > 0) {
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    return qtyArray.reduce(reducer);
  } else {
    return 0;
  }
};

export default unauthorizedCart;
