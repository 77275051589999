import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import DropdownSelect from '../dropdown_select.jsx'
import DatePicker from './datepicker.jsx'

import session from './../../../../assets/images/icons/sauna_session.png'
import sessionWhite from './../../../../assets/images/icons/sauna_icon_white.png'
import clock from './../../../../assets/images/icons/clock_icon.png'
import clockWhite from './../../../../assets/images/icons/clock_white.png'
import calendar from './../../../../assets/images/icons/filter_calendar.png'
import calendarWhite from './../../../../assets/images/icons/filter_calendar_white.png'

const sauna_day = "day"

const get_sauna_day = (filters) =>
  filters[sauna_day] && moment(filters[sauna_day]) || moment()

const filter_hours_available = (filters, hours_available) => {
  const { session_type } = filters
  if (!session_type) return hours_available

  if (session_type === '30 single' || session_type === '30 double') {
    return hours_available.filter(hour => hour.type === '40')
  } else if (session_type === '45 single' || session_type === '45 double') {
    return hours_available.filter(hour => hour.type === '41')
  } else {
    return hours_available
  }
}

const isReserveDisable = (filters, saunasIsFetching) =>
  !(filters['hours_available'] && filters['session_type']) || saunasIsFetching


const SaunaForm = ({ session_types, hours_available, onFiltersChange, filters, bookSauna, saunasIsFetching }) => {

  return (
    <form
      className="SaunaFilters"
      onChange={onFiltersChange}
      onSubmit={(event) => event.preventDefault()}
    >
      <div className="ScheduleFilters-filter">
        <DropdownSelect
          name="session_type"
          optionId='id'
          optionLabel='name'
          options={session_types}
          icon={session}
          hoverIcon={sessionWhite}
          onChange={onFiltersChange}
          placeholder='Session type'
          filters={filters}
        />
      </div>
      <div className="SaunaFilters-filter">
        <DatePicker
          name={sauna_day}
          icon={calendar}
          hoverIcon={calendarWhite}
          onChange={onFiltersChange}
          value={get_sauna_day(filters)}
          blankOption="Date"
        />
      </div>
      <div className="ScheduleFilters-filter">
        <DropdownSelect
          name="hours_available"
          optionId='id'
          optionLabel='name'
          options={filter_hours_available(filters, hours_available)}
          icon={clock}
          hoverIcon={clockWhite}
          onChange={onFiltersChange}
          placeholder='Time'
          filters={filters}
          hideResetIcon
        />
      </div>
      <div className="SaunaFilters-filter">
        <button
          disabled={isReserveDisable(filters, saunasIsFetching)}
          className="Button Sauna-action"
          onClick={bookSauna}
        >
          Reserve
        </button>
      </div>
    </form>
  )
}

SaunaForm.propTypes = {
  hours_available: PropTypes.array.isRequired,
  session_types: PropTypes.array.isRequired,
  onFiltersChange: PropTypes.func.isRequired,
  bookSauna: PropTypes.func.isRequired,
}

SaunaForm.defaultProps = {
  hours_available: [],
  session_types: []
}

export default SaunaForm
