import React from 'react';
import { Link } from 'react-router-dom';
import hand from '../../../assets/images/icons/hand.svg';

const Product = ({ product, price }) => {
  return (
    <div className="column product-column">
      <Link to={`/shop/${product.slug}`} className="Product">
        <div className="Item">
          <div className="Product-images">
            {product.thumbnail_url ? (
              <img
                src={product.thumbnail_url}
                alt={product.name}
                className="Product-image"
              />
            ) : (
              <div className="empty-image h-100 d-flex justify-content-center align-items-center">
                {product.name}
              </div>
            )}
          </div>
          <div className="Product-description">
            <h5 className="Product-text product-name">
              {product.name}
            </h5>
            <div>
              { (product.soldout && !product.is_donation) ? (
                <div className="Product-text smaller-text sold-out">SOLD OUT</div>
              ) : (
                <div className="Product-text smaller-text">
                  {product.is_donation ? '' : `$${price}`}
                </div>
              )}
            </div>
          </div>
          <div className="Product-description show-on-hover">
            <div className="Product-text product-name">
              {product.name}
            </div>
            <div>
              { (product.soldout && !product.is_donation) ? (
                <div className="Product-text smaller-text sold-out">SOLD OUT</div>
              ) : (
                <div className="Product-text smaller-text">
                  {product.is_donation ? '' : `$${price}`}
                </div>
              )}
              <div className="Button product-link" >
                {product.button_text || 'SHOP'}
                <img src={hand} width={21} height={19} alt="Shop" />
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default Product;
