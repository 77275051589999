import Api from "../api/checkout_total";

export const CHECKOUT_TOTAL_SUCCESS = 'CHECKOUT_TOTAL_SUCCESS'

export const checkPromoCode = (promoCode, products, type, isContract) => (dispatch) => {
  return new Promise(async(resolve, reject) => {
    await Api.checkoutTotal(products, promoCode, type, isContract)
      .then(res => {
        dispatch({ type: CHECKOUT_TOTAL_SUCCESS, response: res.body });
        resolve(true);
      })

      .catch(err => {
        reject(false);
      })
  });
}