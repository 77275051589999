import {
  FETCH_SERVICES,
  FETCH_SERVICES_ERROR,
  FETCH_SERVICES_SUCCESS
} from '../actions/services';

const INITIAL_STATE = {
  services: [],
  error: null,
  isFetching: false
};

const services = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_SERVICES:
      return {
        ...state,
        isFetching: true
      };
    case FETCH_SERVICES_ERROR:
      return {
        ...state,
        error: action.error,
        isFetching: false
      };
    case FETCH_SERVICES_SUCCESS:
      return {
        ...state,
        services: action.services,
        error: null,
        isFetching: false
      };
    default:
      return state;
  }
};

export const isFetching = state => state.services.isFetching;

export default services;
