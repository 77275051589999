import React, { Component } from "react";
import {Link} from "react-router-dom";
import superagent from "superagent";

export default class TvCollectionsGrid extends Component {
  state = {
    isLoading: true,
    error: null,
    collections: []
  };

  componentDidMount() {
    this.getTvCollections();
  }

  getTvCollections = () => {
    this.setState({ isLoading: true });

    return superagent
      .get("/tv/collections")
      .set("Accept", "application/json")
      .query()
      .then(res => {
        this.setState({
          collections: res.body.collections,
          isLoading: false
        });
      })
      .catch(error => {
        this.setState({ error: error.response.body, isLoading: false });
      });
  };

  renderCollection = (collection) => {
    const count = collection.videos_count;
    if (count === 0) return <div />;

    const linkTo = collection.is_favorite ? "/tv/favorited_videos" : `/tv/collections/${collection.id}`;

    return (
      <Link to={linkTo} className="tv-collection-card">
        <div
          className="tv-collection-image"
          style={{ backgroundImage: collection.image ? `url(${collection.image.image_url})` : null }}
        />
        <div className="tv-collection-info">
          <div>{collection.name}</div>
          <div className="vid-count">{count} {count === 1 ? 'video' : 'videos'}</div>
        </div>
      </Link>
    );
  }

  render() {
    const { limit } = this.props;
    const { collections } = this.state;

    // if (isLoading) return <RenderLoading className="centered" />;

    const limitNumber = limit || collections.length;
    const filteredCollections = collections.filter(c => c.videos_count > 0).slice(0, limitNumber);

    return (
      <div className="tv-collections-grid">
        { filteredCollections.length > 0 &&
        <>
          { filteredCollections.map(collection => (
            this.renderCollection(collection)
          ))}
        </>
        }
      </div>
    );
  }
}
