import React, { Component } from 'react';
import {connect} from "react-redux";
import plyr from 'plyr'
import 'plyr/dist/plyr.css'

class PlyrComponent extends Component {
  constructor(props) {
    super(props);
    this.startVideo = this.startVideo.bind(this);

    this.state = {
      showDefaultBrowser: false
    }
  }
  componentDidMount() {
    this.props.setClick(this.startVideo);
    // this.detectOldSafari();
    const opts = this.props.options;
    opts.controls = ['play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'settings', 'pip', 'airplay', 'fullscreen']

    this.player = new plyr('.js-plyr', opts)
    this.player.source = this.props.sources;
    this.player.on('play', event => {
      this.props.showPlayBtn(false);
    });
    this.player.on('ended', event => {
      this.props.showPlayBtn(true);
    });
  }

  // componentWillReceiveProps(newProps) {
  //   if (this.props.showPlayBtn !== newProps.showPlayBtn) {
  //     this.player.togglePlay();
  //   }
  // }

  disableContextMenu = () => {
    const noContext = document.getElementById('noContextMenu');
    if (noContext) {
      noContext.addEventListener('contextmenu', e => {
        e.preventDefault();
      });
    }
  }

  // detectOldSafari = () => {
  //   const browser = detect();
  //   if (browser && browser.version && browser.name === 'safari') {
  //     const version = browser.version.split('.')[0];
  //     if (version && Number(version)) {
  //       if (Number(version) < 11) {
  //         this.setState({ showDefaultBrowser: true });
  //       }
  //     }
  //   }
  // }

  componentWillUnmount() {
    this.player.destroy()
  }

  renderDefaultPlayer = () => {
    const { sources: { poster, sources } } = this.props;

    return (
      <video
        onContextMenu={(e) => e.preventDefault()}
        width="100%"
        controls
        controlsList="nodownload"
        poster={poster}
        playsInline
        className="video-el"
        onPlay={() => this.props.showPlayBtn(false)}
      >
        <source src={sources[0].src} type={sources[0].type} />
           Your browser does not support HTML5 video.
      </video>
    )
  }

  startVideo() {
    this.player.play()
  }

  render() {
    const { user } = this.props;

    // if (user && user.email === 'irene.m.mcglone@gmail.com') return this.renderDefaultPlayer();

    return (
      <div className="video-el">
        <video className='js-plyr plyr'>
        </video>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user,
})

export default connect(mapStateToProps)(PlyrComponent);
