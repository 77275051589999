import { deselectLesson } from './active_lesson';
import { history } from '../store/configureStore';
import { dropCheckoutTotalCart } from "./checkout_total";

export const OPEN_LESSON_POPUP = 'OPEN_LESSON_POPUP';
export const CLOSE_LESSON_POPUP = 'CLOSE_LESSON_POPUP';

export const openLessonPopup = () => (dispatch, getState) => {
  const state = getState();

  if (state.activeLesson && state.activeLesson.slug) {
    history.push(`?lesson=${state.activeLesson.slug}`)
  }
  dispatch({ type: OPEN_LESSON_POPUP })
};

export const closeLessonPopup = () => (dispatch, getState) => {
  dispatch({ type: CLOSE_LESSON_POPUP })

  history.push({
    pathname: window.location.pathname,
    search: '',
  })
  dropCheckoutTotalCart()(dispatch);

  const state = getState()
  if (!state.lesson.bookingLessonId) {
    dispatch(deselectLesson());
  }
}