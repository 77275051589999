import superagent from "superagent";
export const FETCH_LIVE_SCHEDULE = "FETCH_LIVE_SCHEDULE";

// if was updated more than 10 minutes ago
const shouldUpdateLiveSchedule = updated_at => {
  if (!updated_at) return true;
  const seconds = (new Date().getTime() - updated_at.getTime()) / 1000;
  return seconds > 600;
};

export const fetchLiveSchedule = (limit = null) => (dispatch, getState) => {
  const state = getState();

  return new Promise(async (resolve, reject) => {
    if (
      state.liveSchedule.lessons.length === 0 ||
      shouldUpdateLiveSchedule(state.liveSchedule.updated) ||
      (!limit && !state.liveSchedule.isFullSchedule)
    ) {
      const params = {};
      if (limit) {
        params.limit = limit;
      }

      await superagent
        .get("/tv/live")
        .set("Accept", "application/json")
        .query(params)
        .then(res => {
          dispatch({
            type: FETCH_LIVE_SCHEDULE,
            lessons: res.body,
            isFullSchedule: !limit
          });
          resolve(true);
        })
        .catch(error => {
          reject(error.response.body);
        });
    } else {
      resolve(true);
    }
  });
};
