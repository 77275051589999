import React  from 'react';

export const programTeachersNames = (teachers) => {
  const names = teachers.map(t => t.name)
  const count = names.length

  if (count > 1) {
    return names.map((n, index) => {
      if ((index + 1) === count) {
        return ` & ${n}`
      } else if (index === 0) {
        return n
      } else {
        return `, ${n}`
      }
    })
  } else {
    return names
  }
}