import {
    FETCH_USER_PAST_CLASSES,
    FETCH_USER_PAST_CLASSES_ERROR,
    FETCH_USER_PAST_CLASSES_SUCCESS,
  } from '../actions/user_past_classes';
  
  const INITIAL_STATE = {
    past_classes: [],
    error: null,
    isFetching: false,
    totalHoursVisited: null,
    totalVisitedClasses: null
  };
  
  const userPastClasses = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case FETCH_USER_PAST_CLASSES:
        return {
          ...state,
          isFetching: true,
        };
      case FETCH_USER_PAST_CLASSES_ERROR:
        return {
          ...state,
          error: action.error,
          isFetching: false,
        };
      case FETCH_USER_PAST_CLASSES_SUCCESS:
        return {
          ...state,
          past_classes: action.past_classes.visits,
          totalHoursVisited: action.past_classes.total_hours_visited,
          totalVisitedClasses: action.past_classes.total_visited,
          error: null,
          isFetching: false,
        };
      default:
        return state;
    }
  };
  
  export const isFetching = state => state.userPastClasses.isFetching;
  
  export default userPastClasses;