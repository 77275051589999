import { FILTER_CHANGE } from '../actions/filters';

const filters = (state = {}, action) => {
  switch (action.type) {
    case FILTER_CHANGE:
      return {
        ...state,
        ...action.filters
      };
    default:
      return state;
  }
}

export default filters;
