import Api from "../api/teachers";
export const FETCH_TEACHERS = "FETCH_TEACHERS";
export const FETCH_TEACHERS_ERROR = "FETCH_TEACHERS_ERROR";
export const FETCH_TEACHERS_SUCCESS = "FETCH_TEACHERS_SUCCESS";

export const fetchTeachers = () => (dispatch, getState) => {
  const state = getState();

  const shouldUpdate =
    !state.teachers.lastUpdate ||
    Math.ceil(
      Math.abs(new Date() - state.teachers.lastUpdate) / (1000 * 60 * 60 * 24)
    ) > 1;

  if ((state.teachers.teachers.length === 0 || shouldUpdate) && !state.teachers.isFetching) {
    dispatch({ type: FETCH_TEACHERS });

    Api.fetchTeachers()
      .then(res =>
        dispatch({
          type: FETCH_TEACHERS_SUCCESS,
          teachers: res.body.teachers,
          pageContent: res.body.page_content,
        })
      )
      .catch(err =>
        dispatch({
          type: FETCH_TEACHERS_ERROR,
          error: err
        })
      );
  }
};
