import PropTypes from 'prop-types'

const lessonType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  mindbody_identifier: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  sale_service_id: PropTypes.number, // TODO need research for sales services
  start_date_time: PropTypes.string.isRequired,
  updated_at: PropTypes.string.isRequired,
  location: PropTypes.shape({
    id: PropTypes.number.isRequired,
    address: PropTypes.string.isRequired,
    address2: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    postal_code: PropTypes.string.isRequired,
    state_prov_code: PropTypes.string.isRequired,
  }).isRequired,
  program: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  session_type: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  staff: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    bio: PropTypes.string,
    image_url: PropTypes.string,
  }).isRequired,
}).isRequired

export default lessonType