import {
  FETCH_LIVE_SCHEDULE,
} from '../actions/live_schedule';

const INITIAL_STATE = {
  lessons: [],
  updated: null,
  isFullSchedule: false,
};

const liveSchedule = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_LIVE_SCHEDULE:
      return {
        ...state,
        lessons: action.lessons,
        isFullSchedule: action.isFullSchedule,
        updated: new Date(),
      };
    default:
      return state;
  }
};

export default liveSchedule;
