import Api from "../api/saunas"
import moment from 'moment'
import _ from 'lodash'

export const FETCH_SAUNAS = 'FETCH_SAUNAS'
export const FETCH_SAUNAS_ERROR = 'FETCH_SAUNAS_ERROR'
export const FETCH_SAUNAS_SUCCESS = 'FETCH_SAUNAS_SUCCESS'

const createSession = (id) => {
  if (id === '40') {
    return [
      { id: '30 single', name: '30-MIN SINGLE', session_id: '40' },
      { id: '30 double', name: '30-MIN DOUBLE', session_id: '40' },
    ]
  }
  if (id === '41') {
    return [
      { id: '45 single', name: '45-MIN SINGLE', session_id: '41' },
      { id: '45 double', name: '45-MIN DOUBLE', session_id: '41' },
    ]
  }
  return []
}

export const fetchSaunas = (date) => (dispatch) => {
  dispatch({ type: FETCH_SAUNAS })

  const time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone

  Api.fetchSaunas(date, time_zone)
    .then(res => {
      // id 40 - 30 minutes
      // id 41 - 45 minutes
      const session_types = []
      let hours_available = []
      res.body.saunas.forEach(sauna => {
        const { start_date_time, end_date_time, session_type_id, session_type_name } = sauna
        if (!session_types.find(el => el.session_id === session_type_id)) {
          Array.prototype.push.apply(session_types, createSession(session_type_id))
        }
        const format = 'hh:mm A'
        // we need the show time as in mindbody

        const duration = session_type_id === '40' ? 30 : 45
        const start_time = moment.utc(start_date_time)
        const end_time = moment.utc(end_date_time)
        const formattedStartTime = start_time.format(format)
        const formattedEndTime = end_time.diff(start_time, 'minutes') > duration ? start_time.add(duration, 'minutes').format(format) : end_time.format(format)

        const time = `${formattedStartTime} - ${formattedEndTime}`
        if (!hours_available.find(el => el.id === `${time}-${session_type_name}`)) {
          hours_available.push({
            name: time,
            id: `${time}-${session_type_name}`,
            type: session_type_id,
            start_date_time: start_date_time,
            appointment: sauna,
          })
        }
      })

      hours_available = _.sortBy(hours_available, hour => {
        return moment(hour.start_date_time).format('YYYYMMDDHHmm')
      })

      dispatch({
        type: FETCH_SAUNAS_SUCCESS,
        saunas: res.body.saunas,
        services: res.body.services,
        session_types: session_types,
        hours_available: hours_available,
      })
    })
    .catch(err =>
      dispatch({
        type: FETCH_SAUNAS_ERROR,
        error: err
      })
    )
}