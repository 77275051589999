import {
  SELECT_PRODUCT_QUANTITY,
  DESELECT_PRODUCT_QUANTITY
} from '../actions/product_quantity_selector';

const INITIAL_STATE = {
  quantity: 1
};

const ProductQuantitySelector = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SELECT_PRODUCT_QUANTITY:
      return {
        ...state,
        quantity: action.quantity
      };
    case DESELECT_PRODUCT_QUANTITY:
      return {
        ...state,
        quantity: 1
      };
    default:
      return state;
  }
};

export default ProductQuantitySelector;
