import Api from '../api/user_schedule';

export const FETCH_USER_SCHEDULE = 'FETCH_USER_SCHEDULE';
export const FETCH_USER_SCHEDULE_ERROR = 'FETCH_USER_SCHEDULE_ERROR';
export const FETCH_USER_SCHEDULE_SUCCESS = 'FETCH_USER_SCHEDULE_SUCCESS';
export const DELETE_FROM_USER_SCHEDULE = 'DELETE_FROM_USER_SCHEDULE';

const fetchUserScheduleSuccess = userSchedule => (dispatch) => {
  dispatch({ type: FETCH_USER_SCHEDULE_SUCCESS, userSchedule });
};

const fetchUserScheduleError = error => (dispatch) => {
  dispatch({ type: FETCH_USER_SCHEDULE_ERROR, error });
};

export const fetchUserSchedule = period => (dispatch) => {
  dispatch({ type: FETCH_USER_SCHEDULE });

  const time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone

  Api.fetchUserSchedule(period, time_zone)
    .then(res => dispatch(fetchUserScheduleSuccess(res.body)))
    .catch(error => dispatch(fetchUserScheduleError(error)));
};

export const deleteFromUserSchedule = lessonId => (dispatch) => {
  dispatch({ type: DELETE_FROM_USER_SCHEDULE, lessonId });
}