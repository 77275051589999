import React, {Component, Fragment} from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import Dropzone from 'react-dropzone';
import AvatarEditor from 'react-avatar-editor'
import superagent from "superagent";

import selfie from './../../../../assets/images/icons/selfie.svg';
import upload from './../../../../assets/images/upload_photo.svg';
import Loading from "../../../../assets/images/icons/yinyang_icon.png";
import { updateUserAvatar } from '../../actions/user_update'

class UploadPhoto extends Component {
  state = {
    image: null,
    loading: false,
    scale: 1,
    error: null,
  }

  onDrop = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length === 1) {
      this.setState({ error: 'Something went wrong. Choose another file' })
      return;
    };

    const file = acceptedFiles[0];
    this.setState({ image: file });
  };


  upload = () => {
    const canvas = this.editor.getImageScaledToCanvas()

    canvas.toBlob((blob) => {
      this.uploadPhoto(blob);
    }, 'image/jpeg');
  }

  uploadPhoto = (image) => {
    const { onPhotoUpload, updateUserAvatar } = this.props;
    this.setState({ loading: true, error: null })

    return superagent
      .post('/users/avatar')
      .set('Accept', 'application/json')
      .attach('avatar', image)
      .then(res => {
        this.setState({ loading: false });
        updateUserAvatar(res.body.avatar);
        onPhotoUpload();
      })
      .catch((error) => {
        const message = error.response.body.message || "Something went wrong";
        this.setState({ loading: false, error: message });
      });
  }

  setEditorRef = editor => {
    if (editor) this.editor = editor
  }

  handleScale = e => {
    const scale = parseFloat(e.target.value)
    this.setState({ scale })
  }

  render() {
    const { goToStep, isNewUser, hideSkip, title, photo_promt, photo_submit_btn } = this.props;
    const { image, loading, scale, error } = this.state;

    return (
      <div className="photo-uploader">
        { isNewUser &&
          <div className="step-counter">Step 3 of 3 </div>
        }
        { image ? (
          <Fragment>
            <div className="subscribe-modal-header" >
              { title || "Upload a selfie"}
            </div>
            <div className="uploaded-image">
              <div className="text-center">
                <AvatarEditor
                  ref={this.setEditorRef}
                  image={image}
                  width={300}
                  height={300}
                  border={0}
                  scale={parseFloat(scale)}
                  borderRadius={150}
                />
              </div>
              <div className="text-center mt-3 mb-3">
                <input
                  name="scale"
                  type="range"
                  onChange={this.handleScale}
                  min="0.1"
                  max="2"
                  step="0.01"
                  defaultValue="1"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div className="subscribe-modal-header" >
              { title || "Upload a selfie"}
            </div>
            <Dropzone
              onDrop={this.onDrop}
              maxSize={4194304}
              accept="image/png, image/jpeg"
              multiple={false}
            >
              {({getRootProps, getInputProps, isDragActive}) => {
                return (
                  <div
                    {...getRootProps()}
                    className="dropzone"
                  >
                    <input {...getInputProps()} />
                    <div className="text-center pt-lg-4 pt-md-3 pt-sm-2 text-uppercase">
                      <img src={upload} className="background" />
                      <img src={selfie} width={47} height={49} className="mb-4" />
                      <p>
                        { photo_promt || "CHOOSE PHOTO"}
                      </p>
                    </div>

                  </div>
                )
              }}
            </Dropzone>
          </Fragment>
        )}
        { error &&
          <div className="text-center mt-4 pr-3 pl-3">
            <div className="text-center upload-error">
              {error}
            </div>
            <div
              className="choose-another-image"
              onClick={() => this.setState({ image: null, error: null })}
            >
              CHOOSE ANOTHER PHOTO
            </div>
          </div>
        }

        { image ? (
          <div className="pr-3 pl-3">
            { loading ? (
              <div className="inline-loading subscribe-modal-loading" style={{ padding: "10px 0"}}>
                <img src={Loading} height="21" width="21" />
              </div>
            ) : (
              <button
                className="Button subscribe-modal-btn"
                onClick={this.upload}
              >
                { photo_submit_btn || "Crop and continue"}
              </button>
            )}
          </div>
        ) : (
          <div className="text-center" style={{ marginTop: '55px' }}>
            { !hideSkip &&
              <div
                className="skip-btn subscribe-modal-text d-inline-block cursor-pointer"
                onClick={() => goToStep('confirmation')}
              >
                SKIP
              </div>
            }
          </div>
        )}
      </div>
    );
  }
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateUserAvatar,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(UploadPhoto);