import React, { Component, Fragment } from "react";
import MetaTagsComponent from "../../components/meta_tags_component";
import scrollToTop from "../../helpers/scrollToTop";
import CategoryPageLayout from "./category_page_layout_new";

class FavoritedVideos extends Component {
  componentWillMount() {
    scrollToTop();
  }

  render() {
    return (
      <Fragment>
        <MetaTagsComponent
          title="SKY TING | TV | Favorited videos"
          desc="SKY TING TV Favorited videos"
          url="tv/favorited_videos"
        />
        <div className="Page m-0 tv-category-page">
          <CategoryPageLayout
            header="Your favorited videos"
            type="favorite"
            columnsCount={4}
            filtersNames={[
              "category",
              "level",
              "teacher",
              "duration",
            ]}
          />
        </div>
      </Fragment>
    );
  }
}

export default FavoritedVideos;
