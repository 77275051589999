import _ from "lodash";
import React, { Component } from "react";
import superagent from "superagent";
import RenderLoading from "./render_loading";
import RenderError from "./render_error";

class TypeForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      error: false,
      lessonId: props.match.params.id
    };
  }

  componentDidMount() {
    const id = this.state.lessonId;

    if (_.isEmpty(id)) {
      this.setState({
        isLoading: false,
        error: true
      });
    } else {
      return superagent
        .get(`/trainings/scholarship/${id}`)
        .set("Accept", "application/json")
        .send()
        .then(res => {
          if (res.body && res.body.is_past) {
            this.props.history.push(`/trainings/scholarship/${id}`);
          }

          this.setState({
            isLoading: false,
            lesson: res.body
          });
          if (!res.body.error) {
            this.renderTypeform(res.body);
          }
        });
    }
  }

  renderTypeform = lesson => {
    const typeform_id = lesson.type_form;
    let type_form = "";

    if (_.isEmpty(typeform_id)) {
      type_form = "nz8wV6";
    } else {
      type_form = typeform_id;
    }

    const elm = this.typeformElm;

    window.typeformEmbed.makeWidget(
      elm,
      `https://skytingyoga.typeform.com/to/${type_form}`
    );
  };

  render() {
    const { isLoading, error, lesson } = this.state;

    if (isLoading)
      return (
        <div className="mt-5">
          <div className="pt-5" />
          <RenderLoading />
        </div>
      );
    if (error) return <RenderError />;

    if (!isLoading && lesson && lesson.error) return <div>{lesson.error}</div>;
    return (
      <div>
        <div style={{ height: "500px" }} ref={tf => (this.typeformElm = tf)} />
      </div>
    );
  }
}

export default TypeForm;
